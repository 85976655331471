import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { fadeInOut } from 'src/app/shared/services/app.animation';
import { AdhocInvoiceService } from '../shared/adhoc-invoice.service';

@Component({
  selector: 'app-adhoc-payment-options',
  templateUrl: './adhoc-payment-options.component.html',
  styleUrls: ['./adhoc-payment-options.component.scss'],
  animations: [fadeInOut],
})
export class AdhocPaymentOptionsComponent {
  activeTab: 'cvv' | 'creditCard' = 'cvv';

  constructor(public activeModal: NgbActiveModal, private adhocInvoiceService:AdhocInvoiceService) {}

  switchTab(tab: 'cvv' | 'creditCard') {
    this.activeTab = tab;
  }

  closeModal() {
    this.activeModal.dismiss('Modal closed');
  }

  handlePaymentSuccess() {
    this.activeModal.close('Payment successful'); // Close modal on payment success
  }
  processPayment() {
     // Simulate payment processing
     const isPaymentSuccessful = true; // Replace with actual payment logic

     if (isPaymentSuccessful) {
       // Notify subscribers about the payment status
       this.adhocInvoiceService.updatePaymentStatus(true);
       this.activeModal.close('Payment successful');
     } else {
       this.activeModal.dismiss('Payment failed');
     }
  }

  
}
