<div class="tab-content container custom-cad" id="c-pills-tabContentAgent">
    <div class="customizer-header sticky-header px-0">
        <i class="fa-solid fa-arrow-left" (click)="back()" style="font-size: 20px; cursor: pointer;"></i>
        <div class="heading col-md-3">
            <h5 class="mb-0 text-nowrap ">
                Subscription Details
                <!-- {{ existingData?.id ? 'Subscription Details' : 'Add Subscription' }} -->
            </h5>
        </div>
        <div class="d-flex flex-row col-md-9 header-btn justify-content-end gap-2 align-items-center pr-2">
            <div>
                <button class="custom-btn" (click)="openHistoryPage('Payment')"  matTooltip="Payment History"  matTooltipPosition="above"><i class="fa-solid fa-file-invoice-dollar"></i></button>
            </div>
            <div>
                <button class="custom-btn" (click)="openHistoryPage('Invoice')" matTooltip="Invoice History" matTooltipPosition="above"><i class="fa-solid fa-receipt"></i></button>
                </div>
            <div>
                <button class="custom-button" (click)="downloadPDF()">
                    <div class="d-flex gap-2 align-items-center">
                      <i class="fa fa-cloud-upload"></i>
                      <span class="text">Export</span>
                    </div>
                  </button>
            </div>
            <!-- <i class="fa fa-times text-success" style="font-size: 20px;" (click)="hideStepper()"></i> -->
        </div>

    </div>
    <div class="scrollable-content">
        <div class="container-fluid" >
            <div class="row mb-2">
                <div class=" px-0">
                    <div class="job-description row " id="content">
                        <div class="col-lg-4 pl-0 d-flex flex-column">
                            <div class="custom-card mb-3">
                                <h4 class="mb-4">Plan</h4>
                                <div class="subscription-card">
                                    <div class="subscription-card-header pt-2 pb-0">
                                        <img [src]="subscriptionDetail?.subscriptionDetail?.plan?.ImagePath || 'https://api.aqsatel.com/get-image/20Free.png'"
                                            alt="Subscription Icon">
                                        <div class="ms-3">
                                            <h6 class="mb-0">{{
                                                this.subscriptionDetail?.subscriptionDetail?.plan?.DisplayName }}</h6>
                                            <small class="fs-12">{{
                                                this.subscriptionDetail?.subscriptionDetail?.plan?.Description
                                                }}</small>
                                        </div>
                                        <div class="ms-auto">
                                            <h6 class="mb-0">${{
                                                this.subscriptionDetail?.authorizeSubscriptionDetail?.amount }}</h6>
                                            <!-- <small>/ month</small> -->
                                        </div>
                                    </div>
                                    <div class="subscription-card-body pt-0 ms-5">
                                        <!-- <p class="mb-2">Amount: $15</p> -->
                                        <p class="mb-2 fs-12 ">Status: <span class="ms-2"
                                                [ngClass]="getStatusBadgeClass(this.subscriptionDetail?.subscriptionDetail?.Status)"
                                                class="custom-badge">
                                                {{this.subscriptionDetail?.subscriptionDetail?.Status}}
                                            </span></p>

                                        <div class="subscription-card-foo mt-3">
                                            <button class="btn btn-night  py-1 px-2 fs-11 cursor-pointer"
                                                (click)="onUpgradePlanClicked()">Upgrade Plan</button>
                                            <a *ngIf="this.subscriptionDetail?.subscriptionDetail?.Status !== 'Cancelled'"
                                                (click)="withConfirmation()"
                                                class=" mx-2 cursor-pointer fs-11 text-bg fw-bold text-nowrap">Cancel
                                                Subscription</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="custom-card mb-3">
                                <h4 class="mb-4">Payment Methods</h4>
                                <div class="subscription-card">
                                    <div class="subscription-card-header pt-2 pb-0">
                                        <img src="https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcQ8QNoSicbKcSutYJMwMhCVgfsPcNsIkkQog4Qx0DlmN3rqOuFG"
                                            alt="Subscription Icon">
                                        <div class="ms-3">
                                            <small class="fs-12"> {{subscriptionDetail?.subscriptionDetail?.AccountType
                                                }}</small>
                                            <h6 class="mb-0"> {{
                                                subscriptionDetail?.authorizeSubscriptionDetail?.profile?.paymentProfile?.payment?.creditCard?.cardNumber
                                                }}</h6>
                                        </div>
                                        <div class="ms-auto">
                                            <h6 class="mb-0">
                                                <div class="mt-0">
                                                    <p class="mt-0 mb-0 fs-11">Auto Renew</p>
                                                    <div class="media-body  icon-state">
                                                        <label class="switch">
                                                            <input type="checkbox" [(ngModel)]="AutoRenew"
                                                                (change)="autoRenew($event)">
                                                            <span class="switch-state"
                                                                [ngClass]="AutoRenew ? 'bg-secondary' : 'bg-renew'"></span>
                                                        </label>
                                                    </div>

                                                </div>
                                            </h6>
                                        </div>
                                    </div>
                                    <div class="subscription-card-body pt-0 ms-5">
                                        <!-- <p class="mb-2">Amount: $15</p> -->
                                        <p class="mb-2 fs-12 "> {{
                                            subscriptionDetail?.subscriptionDetail?.customer?.FirstName
                                            }} {{
                                            subscriptionDetail?.subscriptionDetail?.customer?.LastName
                                            }}</p>

                                        <div class="subscription-card-foo mt-2">
                                            <a class=" cursor-pointer text-bg fs-11 fw-bold text-nowrap "
                                                (click)="updatePaymentMethod()">Update Payment method</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                            <div class="custom-card mt-2" style="
                            overflow: auto;
                            scrollbar-width: none;">
                                <div class="d-flex justify-content-between">
                                    <h4 class="mb-3">Upcoming Plans</h4>
                                    <!-- <a (click)="openHistoryPage('Invoice')"
                                        class=" mx-2 cursor-pointer fs-11 text-bg fw-bold text-nowrap">View more</a> -->
                                </div>
                                <div>
                                    <app-payment-his-two [id]="CustomerId"></app-payment-his-two>
                                    <!-- <app-invoices-history [id]="CustomerId"></app-invoices-history> -->
                                </div>
                            </div>

                        </div>
                        <div class="col-lg-5" style="max-height: fit-content;">
                            <div class="custom-card info-card-usage">
                                <div class="card-header py-0 px-3">
                                    <h5 class="mb-0">Usage</h5>
                                </div>
                                <div class="card-body mt-0 p-0">
                                    <app-usage-info [height]="true" [id]="CustomerId"></app-usage-info>
                                </div>
                            </div>
                        
                            <!-- <div class="custom-card mb-3"style="height: 280px;" >
                                <div class="d-flex justify-content-between">
                                    <h4 class="mb-3">Invoices History</h4>
                                    <a (click)="openHistoryPage('Invoice')"
                                        class=" mx-2 cursor-pointer fs-11 text-bg fw-bold text-nowrap ">View more</a>
                                </div>
                                <div>
                                    <small class="text-dark fw-bold ml-1" style="font-size: 8px;">LATEST
                                        INVOICES</small>
                                    <app-invoices-history [id]="CustomerId"></app-invoices-history>
                                </div>
                            </div>
                            <div class="custom-card" style="height: 280px;">
                                <div class="d-flex justify-content-between">
                                    <h4 class="mb-3">Payment History</h4>
                                    <a (click)="openHistoryPage('Payment')"
                                        class=" mx-2 cursor-pointer fs-11 text-bg fw-bold text-nowrap ">View more</a>
                                </div>
                                <div>
                                    <small class="text-dark fw-bold ml-1" style="font-size: 8px;">LATEST PAYMENT</small>
                                    <app-payment-his-card [id]="CustomerId"></app-payment-his-card>
                                </div>
                            </div> -->
                          
    
                        </div>
                         
                        <div class="col-md-3 pr-0 info-card-cust" style="max-height: fit-content;">

                            <div class="custom-card p-3 mb-3">
                                <div class=" p-3 mb-3">
                                    <!-- Customer Information -->
                                    <div class="d-flex justify-content-between  info-wrapper">
                                        <h4 class="mb-2 ">Customer Information</h4>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-between mb-2">
                                        <div class="d-flex align-items-center gap-3">
                                          <i class="fas fa-user text-primary"></i>
                                          <span class="text-info cursor-pointer" (click)="sendToCustomerDashboard()" style="text-decoration: underline;">
                                            {{ this.subscriptionDetail?.subscriptionDetail?.customer?.FirstName + ' ' + this.subscriptionDetail?.subscriptionDetail?.customer?.LastName }}
                                          </span>
                                        </div>
                                        <span class="custom-badge">
                                          <!-- #{{ this.subscriptionDetail?.subscriptionDetail?.customer?.id }} -->
                                          <i class="fas fa-hashtag  text-primary" matTooltip="Customer ID"></i>
                                          <span class="text-muted">
                                            {{ this.subscriptionDetail?.subscriptionDetail?.customer?.id }}
                                          </span>
                                        </span>
                                      </div>
                                  
                                    <!-- Contact Information -->
                                    <div class="d-flex align-items-center gap-3 mb-2">
                                      <i class="fas fa-envelope text-primary"></i>
                                      <span class="text-muted">
                                        {{ this.subscriptionDetail?.subscriptionDetail?.Email }}
                                      </span>

                                    </div>
                                    <div class="d-flex align-items-center gap-3 mb-2" *ngIf="customerDetails?.personal_info?.PrimaryPhone">
                                        <i class="fas fa-phone text-primary" ></i>
                                        <span class="text-muted">
                                          {{ customerDetails?.personal_info?.PrimaryPhone }}
                                        </span>
                                    </div>
                                    <!-- Subscription ID -->
                                    <div class="d-flex align-items-center gap-3 mb-2">
                                      <i class="fas fa-hashtag  text-primary" matTooltip="Subscription ID"></i>
                                      <span class="text-muted">
                                        {{ this.subscriptionDetail?.subscriptionDetail?.SubscriptionId }}
                                      </span>
                                    </div>
                                  
                                    <!-- Address -->
                                    <div class="d-flex flex-column align-items-start gap-3">
                                        <div class="d-flex align-items-center gap-2">
                                          <i class="fas fa-map-marker-alt text-primary"></i>
                                          <span class="text-muted" style="word-break: break-word;">
                                            {{ addressData?.address ? addressData?.address : '-' }}, {{
                                            addressData?.city ? addressData?.city : '-' }},
                                            {{ addressData?.state ? addressData?.state : '-' }},
                                            {{ addressData?.zip ? addressData?.zip : '-' }},
                                            {{ addressData?.country ? addressData?.country : '-' }}
                                          </span>
                                        </div>
                                        <button class="custom-btn-2" (click)="updatePaymentMethod(true)">
                                          Update Address
                                        </button>
                                      </div>
                                      
                                      
                                      
                                  </div>
                                  
                                  
                              
                                <div class=" custom-card d-flex flex-column mb-3 gap-1">
                                  <div class="d-flex justify-content-between align-items-center">
                                    <h6>
                                      <i class="fas fa-dollar-sign me-2 text-primary"></i> Plan Amount
                                    </h6>
                                    <span class="text-muted">
                                      ${{ data?.SubscriptionAmount ? data?.SubscriptionAmount : '-' }}
                                    </span>
                                  </div>
                                  <div class="d-flex justify-content-between align-items-center">
                                    <h6>
                                      <i class="fas fa-calendar-day me-2 text-primary"></i> Billing Period
                                    </h6>
                                    <span class="text-muted">
                                      {{ data?.BillingPeriod ? data?.BillingPeriod : '-' }} days
                                    </span>
                                  </div>
                                  <div class="d-flex justify-content-between align-items-center">
                                    <h6>
                                      <i class="fas fa-calendar-alt me-2 text-primary"></i> Next Billing Date
                                    </h6>
                                    <span class="text-muted">
                                      {{
                                        commonService.formatToLocalDateTime(this.subscriptionDetail?.subscriptionDetail?.NextBillingDate, 'date')
                                      }}
                                    </span>
                                  </div>
                                  <div class="d-flex justify-content-between align-items-center">
                                    <h6>
                                      <i class="fas fa-redo me-2 text-primary"></i> Next Renew Date
                                    </h6>
                                    <span class="text-muted">
                                      {{
                                        commonService.formatToLocalDateTime(this.subscriptionDetail?.subscriptionDetail?.NextRenewalDate, 'date')
                                      }}
                                    </span>
                                  </div>
                                  <div class="d-flex justify-content-between align-items-center">
                                    <h6>
                                      <i class="fas fa-calendar-check me-2 text-primary"></i> Start Date
                                    </h6>
                                    <span class="text-muted">
                                      {{
                                        commonService.formatToLocalDateTime(this.subscriptionDetail?.subscriptionDetail?.StartDate, 'date')
                                      }}
                                    </span>
                                  </div>
                                  <div class="d-flex justify-content-between align-items-center">
                                    <h6>
                                      <i class="fas fa-calendar-times me-2 text-primary"></i> End Date
                                    </h6>
                                    <span class="text-muted">
                                      {{
                                        commonService.formatToLocalDateTime(this.subscriptionDetail?.subscriptionDetail?.EndDate, 'date')
                                      }}
                                    </span>
                                  </div>
                                  <div class="d-flex justify-content-between align-items-center">
                                    <h6>
                                      <i class="fas fa-history me-2 text-primary"></i> Updated Date
                                    </h6>
                                    <span class="text-muted">
                                      {{
                                        commonService.formatToLocalDateTime(this.subscriptionDetail?.subscriptionDetail?.UpdatedDate, 'date')
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                        </div>
                        <div class="custom-card col-md-12 p-2 mt-2">
                            <div class="d-flex justify-content-between  info-wrapper">
                                <h4 class="mb-2 ">Invoice</h4>
                            </div>
                            <app-dynamic-invoice-template [CustomerId]="CustomerId"></app-dynamic-invoice-template>
                        </div>

                        <div class="col-md-11 mb-3">
                            <div class="d-flex align-items-center justify-content-between mt-4 mx-0">
                                <h5 class="mb-0" *ngIf="activity">Activity</h5>
                                <h5 class="mb-0" *ngIf="!activity"> History</h5>
                                <div>
                                <button class="tab btn-xs mx-2 px-3" *ngFor="let type of activityTypes"
                                [class.active]="selectedActivityType === type" (click)="filterTimeline(type)">
                                {{ type }}
                            </button>
                             </div>
                            </div>
                            <div class="timeline" *ngIf="activity">
                                <div class="timeline-item align-items-center" *ngFor="let event of timelineData">
                                    <div class="timeline-line">
                                        <div class="timeline-icon">
                                            <!-- Add a placeholder for icons/avatars -->
                                            <i class="fa fa-file-invoice" *ngIf="event.activity_type=='PO'"></i>
                                            <i class="fa fa-dollar-sign"></i>
                                            <!-- <img *ngIf="event.icon" [src]="event.icon" alt="icon" class="icon-img" /> -->
                                            <!-- <div *ngIf="!event.icon" class="default-icon"></div> -->
                                        </div>
                                    </div>
                                    <div class="timeline-content">
                                        <div class="timeline-header">
                                            <strong class="person-name text-nowrap">{{ event.name }}</strong>
                                            <span class="action">{{ event.ActivityDetails }}</span>
                                            <small class="person-name text-nowrap">{{ event.CreatedDate |date:'MMM d, y,
                                                HH:mm'

                                                }}</small>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="timeline" *ngIf="!activity">
                                <div class="timeline-item align-items-center" *ngFor="let event of subcriptionHistoryData">
                                    <div class="timeline-line">
                                        <div class="timeline-icon">
                                            <!-- Add a placeholder for icons/avatars -->
                                            <i class="fa fa-file-invoice" *ngIf="event.activity_type=='PO'"></i>
                                            <i class="fa-solid fa-money-check-dollar"></i>
                                            <!-- <img *ngIf="event.icon" [src]="event.icon" alt="icon" class="icon-img" /> -->
                                            <!-- <div *ngIf="!event.icon" class="default-icon"></div> -->
                                        </div>
                                    </div>
                                    <div class="timeline-content">
                                        <div class="timeline-header">
                                            <strong class="person-name text-nowrap">Subcription ID {{ event.id }}</strong>
                                            <span class="action">{{ event.Remarks }}</span>
                                            <small class="person-name text-nowrap">{{ event.CreatedDate |date:'MMM d, y,
                                                HH:mm'

                                                }}</small>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="isSkeletonLoader;" @fadeInOut style="max-width: 100%;">
                            <strong class="mb-2 mt-2">Address Info</strong>
                            <app-skeleton-loader shape="address-info"></app-skeleton-loader>
                        </div>
                        <!-- <div class="fixed-buttons" *ngIf="sticyFooter">
                <div class="d-flex flex-row justify-content-evenly align-items-center m-2">
                  <div class="d-flex justify-content-center align-items-center" >
                    <button class="btn btn-success" (click)="openHistoryPage('Payment')">Payment History</button>
                  </div>
                    <div class="d-flex justify-content-center align-items-center ">
                        <button class="btn btn-secondary" (click)="openHistoryPage('Invoice')">Invoice History</button>
                     </div> 
                    
                </div>
                
              </div> -->
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="showHisotoryPage">
    <app-payment-history [subscriptionDetail]="subscriptionDetail"
        [displayPaymentHistory]="showHistoryDetail==='Payment'? true :false"></app-payment-history>
</div>
<ng-container *ngIf="isAutoRenewModelOpen">
    <app-plan-change-payment #childComponent [isAutoRenewModelOpen]="isAutoRenewModelOpen"
        (isAutoRenewModelOpenChange)="onIsAutoRenewModelOpenChange($event)"
        (submitCVVautoRenew)="getsubmitCVVautoRenew($event)"
        (submitCreditCardAutoRenew)="getsubmitCreditCardAutoRenew($event)"
        (isModalClosedByX)="handleModalClosedByX($event)">
    </app-plan-change-payment>
</ng-container>
<app-customizer #popup></app-customizer>