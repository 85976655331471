import { Component, Input } from '@angular/core';
import { HttpService } from '../../services/httpService.service';
import { DynamicInvoiceTemplateService } from '../dynamic-invoice-template/dynamic-invoice-template.service';
import { CommonService } from '../../services/common.service';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
@Component({
  selector: 'app-invoice-template',
  templateUrl: './invoice-template.component.html',
  styleUrls: ['./invoice-template.component.scss']
})
export class InvoiceTemplateComponent {

  @Input() invoiceData: any
  @Input() customerId: any
  selectedCompanyLogo: any
  customerDetails: any;
  isSkeletonLoader: any;
  @Input() invoiceItem: any
  constructor(private http: HttpService, private dynamicInvoiceTemplateService: DynamicInvoiceTemplateService, private commonService: CommonService) {

  }
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.getLogoSrc()
    this.getCustomerDetais()
  }

  sendInvoice(customerId: any) {
    const data = {
      source: this.invoiceData?.Source
    }
    try {
      this.dynamicInvoiceTemplateService.sendInvoice(customerId, data).then((res: any) => {
        if (res.Status) {
          this.commonService.showToast('success', 'success', res?.message);
        }
      }).catch(err => {
        this.commonService.showToast('error', 'Error', err?.message);
      })
    } catch (error) {
      this.commonService.showToast('error', 'Error', error?.message);
    }
  }
  getLogoSrc() {
    const currentCompany = localStorage.getItem('currentCompany');
    currentCompany === 'mingle' ? this.selectedCompanyLogo = 'Mingle' : 'Aqsatel';
  }

  async getCustomerDetais() {
    const data = {
      CustomerId: this.customerId,
    };

    const res: any = await this.http
      .get("api/Customer/getCustomerPersonalInfo", data, false)
      .toPromise();
    if (res.Status) {
      this.customerDetails = res.data;
    }
  }

  downloadPDF(): void {
    const element = document.getElementById('content');
    const btnWrapper = document.getElementById('btn-wrapper');

    if (element && btnWrapper) {
        // Add a class for styling the PDF export and hide the button wrapper
        element.classList.add('pdf-export');
        btnWrapper.classList.add('hide-for-pdf');

        setTimeout(() => {
            html2canvas(element, { scale: 1, useCORS: true, logging: false }).then((canvas) => {
                const imgData = canvas.toDataURL('image/jpeg', 0.8); // JPEG is faster and smaller than PNG
                const pdf = new jsPDF('p', 'mm', 'a4');
                const pdfWidth = 190; // A4 width in mm
                const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

                pdf.addImage(imgData, 'JPEG', 10, 10, pdfWidth, pdfHeight);
                pdf.save('SubscriptionDetails.pdf');

                // Restore the original state
                btnWrapper.classList.remove('hide-for-pdf');
                element.classList.remove('pdf-export');
            }).catch((error) => {
                console.error('Error generating PDF:', error);

                // Restore the original state in case of an error
                btnWrapper.classList.remove('hide-for-pdf');
                element.classList.remove('pdf-export');
            });
        }, 100); // Slight delay ensures DOM updates are rendered
    } else {
        console.error('Element or button wrapper not found!');
    }
}



}
