import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DetailedSubscriptionComponent } from './detailed-subscription/detailed-subscription.component';
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { SubscriptionDetailsComponent } from './subscription-details/subscription-details.component';
import { TransectionDetailComponent } from './transection-details/transection-details.component';

const routes: Routes = [
  
  {
    path: '',
    component: SubscriptionsComponent
  },
  {
    path:'subscription-details',
    component:SubscriptionDetailsComponent
  },
  {
    path:'transection-details',
    component:TransectionDetailComponent
  }
 
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SubscriptionsRoutingModule { }
