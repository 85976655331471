<div class="page" *ngIf="!isSkeletonLoader" @fadeInOutSlow>
  <!-- <div class="p-3" style="margin-top: -10px;">
  <div class="row">
      <div class="col-12">
          <app-breadcrumb></app-breadcrumb>
      </div>
  </div>
</div> -->
  <div class="container">
    <div class="row">
      <h2 *ngIf="settingDetails?.[0]">{{settingDetails[0]?.DisplayName}}</h2>
      <small *ngIf="settingDetails?.[0]">{{settingDetails[0]?.Description}}</small>

      <p class="mt-0 mb-1" *ngIf="settingInfo?.orderDetails?.[0]?.hasPermission == 1">System Configuration</p>
      <div class="col-sm-10" *ngIf="settingInfo?.orderDetails?.[0]?.hasPermission == 1">
        <div class="custom-card">
          <div class="card-body">
            <h4>New Order</h4>
            <small>{{settingInfo?.orderDetails[0]?.Description}} </small>
            <div class="row mt-4">
              <ng-container *ngFor="let orderDetail of settingInfo?.orderDetails[0]?.children">
                <div class="col-md-6 d-flex justify-content-between"
                  *ngIf="orderDetail?.resource_permission[0]?.CanView === 1">
                  <p *ngIf="orderDetail?.DisplayName === 'Service Area'">
                    <i class="fa-solid fa-building-circle-xmark"></i>
                    &nbsp;{{ orderDetail?.DisplayName }}
                  </p>
                  <p *ngIf="orderDetail?.DisplayName === 'Plan Configuration'">
                    <i class="fa-sharp fa-solid fa-arrows-rotate"></i>
                    &nbsp;{{ orderDetail?.DisplayName }}
                  </p>
                  <p>
                    <i class="fa-regular fa-circle-question hover-effect" title="{{ orderDetail?.Description }}"
                      data-bs-toggle="popover" data-bs-trigger="hover" data-bs-class="custom-popover"
                      data-bs-placement="top">
                    </i>
                  </p>
                </div>
              </ng-container>




            </div>
          </div>
        </div>
      </div>


      <p *ngIf="settingInfo?.usermanagementDetails?.[0]?.hasPermission == 1" class="mt-3 mb-1">User Management</p>
      <div class="col-sm-10" *ngIf="settingInfo?.usermanagementDetails?.[0]?.hasPermission == 1">
        <div class="row">
          <div class="col-md-12">
            <div class="custom-card">
              <div class="card-body">
                <h4>User Management</h4>children
                <small>{{settingInfo?.usermanagementDetails?.[0]?.Description}} </small>
                <div class="row mt-4">
                  <ng-container *ngFor="let child of settingInfo?.usermanagementDetails[0]?.children">
                    <!-- Manage User -->
                    <div class="d-flex align-items-center justify-content-between mb-2 col-md-6"
                      style="font-size: 19px;"
                      *ngIf="hasPermission(child) && child?.DisplayName === manageUserDetails?.DisplayName">
                      <a class="text-dark" style="font-size: 19px;" routerLink="/manage-user">
                        <i class="fa-sharp fa-solid fa-window-restore"></i>
                        <span class="mx-3">{{child?.DisplayName}}</span>
                      </a>
                      <i class="fa-regular fa-circle-question hover-effect"></i>
                    </div>

                    <!-- Manage Agent -->
                    <div class="d-flex align-items-center justify-content-between mb-2 col-md-6"
                      style="font-size: 19px;"
                      *ngIf="hasPermission(child) && child?.DisplayName === manageAgentDetails?.DisplayName">
                      <a class="text-dark" style="font-size: 19px;" routerLink="/manage-agent">
                        <i class="fa-sharp fa-solid fa-window-restore"></i>
                        <span class="mx-3">{{child?.DisplayName}}</span>
                      </a>
                      <i class="fa-regular fa-circle-question hover-effect"></i>
                    </div>

                    <!-- Account Change History -->
                    <div class="d-flex justify-content-between col-md-6"
                      *ngIf="hasPermission(child) && child?.DisplayName === accountChangeHistory?.DisplayName">
                      <p class="accountChange">
                        <i class="fa-sharp fa-solid fa-window-restore"></i> &nbsp;&nbsp;{{child?.DisplayName}}
                      </p>
                      <p>
                        <i #popoverElement class="fa-regular fa-circle-question hover-effect"
                          [attr.title]="child?.Description" data-bs-toggle="popover" data-bs-trigger="hover"
                          data-bs-class="custom-popover" data-bs-placement="top">
                        </i>
                      </p>
                    </div>
                  </ng-container>
                </div>



              </div>
            </div>
          </div>
          <div class="col-md-12 mt-4" *ngIf="settingInfo?.customerSettingDetails?.[0]?.hasPermission == 1">
            <div class="custom-card">
              <div class="card-body">
                <h4>Customer Settings</h4>
                <small>{{settingInfo?.customerSettingDetails [0]?.Description}}
                </small>
                <div class="row mt-4 col-md-12">
                  <ng-container *ngFor="let customerDetail of settingInfo?.customerSettingDetails[0]?.children">
                    <div class="col-md-6 d-flex justify-content-between"
                      *ngIf="customerDetail?.resource_permission[0]?.CanView === 1">

                      <p class="mb-0 "><i class="fa-sharp fa-solid fa-window-restore"></i>
                        &nbsp;&nbsp;{{customerDetail?.DisplayName}}
                      </p>
                      <p><i class="fa-regular fa-circle-question hover-effect" title="{{customerDetail?.Description}}"
                          data-bs-toggle="popover" data-bs-trigger="hover" data-bs-class="custom-popover"
                          data-bs-placement="top"></i></p>
                    </div>
                  </ng-container>

                  <!-- <app-manage-note-types></app-manage-note-types> -->
                  <!--  <div  class="d-flex justify-content-between">
                    <p><i class="fa-sharp fa-solid fa-window-restore"></i> &nbsp;&nbsp;Note Types
                    </p>
                    <p><i class="fa-regular fa-circle-question"></i></p>
                    </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <!-- ///////////////////////////////////////Group Management /////////////////////////////////// -->

      <p *ngIf="settingInfo?.groupManagementDetails?.[0]?.hasPermission == 1" class="mt-3 mb-1">Group Management</p>
      <div class="col-sm-10" *ngIf="settingInfo?.groupManagementDetails?.[0]?.hasPermission == 1">
        <!-- <div class="row">
      <div class="col-md-10"> -->
        <div class="custom-card">
          <div class="card-body">
            <h4>Group Management</h4>
            <small>{{settingInfo?.groupManagementDetails?.[0]?.Description}}</small>

            <!-- Loop through groupManagementDetails -->
            <div class="row mt-4">
              <ng-container *ngFor="let child of settingInfo?.groupManagementDetails[0]?.children">
                <div class="d-flex align-items-center justify-content-between mb-2" style="font-size: 19px;"
                  *ngIf="hasPermission1(child)">
                  <!-- Display the link for the child -->
                  <a class="text-dark" style="font-size: 19px;" routerLink="/group-user">
                    <i class="fa-sharp fa-solid fa-window-restore"></i>
                    <span class="mx-3">{{child?.DisplayName}}</span>
                  </a>
                  <i class="fa-regular fa-circle-question hover-effect" [attr.title]="child?.Description"
                    data-bs-toggle="popover" data-bs-trigger="hover" data-bs-class="custom-popover"
                    data-bs-placement="top">
                  </i>
                </div>
              </ng-container>

            </div>
          </div>
        </div>

      </div>

      <p class="mt-4" *ngIf="settingInfo?.comboManagementDetails?.[0]?.hasPermission == 1" class="mt-3 mb-1">Combos
        Settings</p>
      <div class="col-sm-10" *ngIf="settingInfo?.comboManagementDetails?.[0]?.hasPermission == 1">
        <div class="row">
          <div class="col-12">
            <div class="custom-card">
              <div class="card-body">
                <h4>Combos Management</h4>
                <small>{{settingInfo?.comboManagementDetails?.[0]?.Description}}</small>
                <div class="row mt-4">
                  <ng-container *ngFor="let child of settingInfo?.comboManagementDetails?.[0]?.children; let i = index">
                    <div class="col-md-4" *ngIf="hasPermission(child)">
                      <div class="d-flex align-items-center justify-content-between mb-2" style="font-size: 19px;">
                        <a class="text-dark" style="font-size: 19px;" [routerLink]="getRouterLink(i)">
                          <i class="fa-sharp fa-solid fa-window-restore"></i>
                          <span class="mx-3">{{child?.DisplayName}}</span>
                        </a>
                      </div>
                    </div>
                  </ng-container>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- //////////////////////////////////// other Settings ////////////////////////////////// -->
      <p class="mt-4" *ngIf="settingInfo?.otherSettingsDetails?.[0]?.hasPermission == 1" class="mt-3 mb-1">Manage
        Partner Integration</p>
      <div class="col-sm-10" *ngIf="settingInfo?.otherSettingsDetails?.[0]?.hasPermission == 1">
        <div class="custom-card">
          <div class="card-body">
            <h4>Other Settings</h4>
            <small>{{settingInfo?.otherSettingsDetails?.[0]?.Description}}</small>
            <div class="row mt-4">
              <ng-container *ngFor="let child of settingInfo?.otherSettingsDetails?.[0]?.children; let i = index">
                <div class="col-md-4 d-flex justify-content-between" *ngIf="hasPermission(child)"
                  [routerLink]="getRouterLinkForOtherSettings(i)">
                  <!-- Displaying child information -->
                  <p>
                    <i [ngClass]="getIconClass(i)"></i>
                    &nbsp;{{child?.DisplayName}}
                  </p>

                  <!-- Tooltip for description -->
                  <p>
                    <i class="fa-regular fa-circle-question hover-effect" [attr.title]="child?.Description"
                      data-bs-toggle="popover" data-bs-trigger="hover" data-bs-class="custom-popover"
                      data-bs-placement="top">
                    </i>
                  </p>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <p class="mt-4" *ngIf="settingInfo?.manageResourceSetting?.[0]?.hasPermission == 1" class="mt-3 mb-1">Manage
        Resources</p>
      <div class="col-sm-10" *ngIf="settingInfo?.manageResourceSetting?.[0]?.hasPermission == 1">
        <div class="custom-card">
          <div class="card-body">
            <h4>Manage Resources</h4>
            <small>{{settingInfo?.manageResourceSetting?.[0]?.Description}}</small>
            <div class="row mt-4">
              <ng-container *ngFor="let child of settingInfo?.manageResourceSetting; let i = index">
                <div class="col-md-6 d-flex justify-content-between cursor-pointer" (click)="detail()"
                  *ngIf="hasPermission(child)">
                  <p>
                    <i [ngClass]="getIconClass(i)"></i> &nbsp;
                    {{child?.DisplayName}}
                  </p>
                  <p>
                    <i class="fa-regular fa-circle-question hover-effect" [attr.title]="child?.Description"
                      data-bs-toggle="popover" data-bs-trigger="hover" data-bs-class="custom-popover"
                      data-bs-placement="top">
                    </i>
                  </p>
                </div>
              </ng-container>
            </div>

          </div>
        </div>
      </div>
      <div class="col-md-10 text-center">
        <p>© 2024 {{companyName}} Terms of Service | Privacy Policy | </p>
      </div>
    </div>

  </div>


</div>
<div *ngIf="isSkeletonLoader" @fadeInOutSlow class=" container d-flex flex-column gap-4 mx-8">
  <app-skeleton-loader [cardCount]="1" [width]="'80vw'" [height]="'10vh'" [alignment]="'column'"
    shape="normal-card"></app-skeleton-loader>
  <app-skeleton-loader [cardCount]="2" [width]="'45%'" [height]="'20vh'" [alignment]="'row'"
    shape="normal-card"></app-skeleton-loader>
  <app-skeleton-loader [cardCount]="5" [width]="'80vw'" [height]="'10vh'" [alignment]="'column'"
    shape="normal-card"></app-skeleton-loader>

</div>


<div *ngIf="showManageUser">
  <app-manage-resources (close)="closeModal()"></app-manage-resources>
</div>