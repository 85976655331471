interface BillingInfo {
    firstName: string;
    lastName: string;
    address: string;
    city: string;
    phone: string;
    state: string;
    zip: string;
    country: string;
}

interface UserPaymentDetails {
    cardNumber: string;
    cardExpiryDate: string;
    cardCode: string;
    billingInfo: BillingInfo;
}
export class AddressDTO {
    firstName: string;
    lastName: string;
    address: string;
    city: string;
    phone: string;
    state: string;
    zip: string;
    country: string;
}

export interface SearchFilter {
    DisplayName: string;
    Value: string;
  }