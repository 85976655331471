<div class="tab-content" id="c-pills-tabContentAgent">
    <div class=" d-flex align-items-center justify-content-between p-3">
        <h5 class="mb-0 ">
            {{ justUpdateAddress ? 'Update Address Detail' : 'UPDATE CARD DETAIL' }}
        </h5>
        <i class="icofont-close icon-close" (click)="hideStepper()"></i>
    </div>
</div>
<div class="container-fluid ">

    <form class="form theme-form" [formGroup]="subscriptionInfo">

   
            <div class="subscription-card" *ngIf="justUpdateAddress !== true">
                <h5 class="mb-3">Payment Details</h5>
                <div class="row">
                    <div class="col-md-6" >
                        <div class="mb-3">
                            <label class="form-label" for="cardName">Card Name:</label>
                            <input class="form-control" type="text" id="cardName" placeholder="Enter Name"
                                formControlName="cardName" />
                        </div>
                    </div>
                    <div class="col-md-6" >
                        <div class="mb-3">
                            <label class="form-label" for="cardNumber">Card Number:</label>
                            <input class="form-control" type="text" id="cardNumber" placeholder="Enter Card Number"
                                formControlName="cardNumber" maxlength="16" />
                        </div>
                    </div>
                    <div class="col-md-6" >
                        <div class="mb-3">
                            <label class="form-label" for="expireDate">Expire Date:</label>
                            <div class="input-group custom-datepicker">
                                <input class="datepicker-here form-control digits" id="expireDate" placeholder="MM/YY"
                                    name="dp" [(ngModel)]="model5" [ngModelOptions]="{ standalone: true }" ngbDatepicker
                                    #ed="ngbDatepicker" (click)="ed.toggle()" disableKeyPress />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6" >
                        <div class="mb-3">
                            <label class="form-label" for="cvcNumber">CVC:</label>
                            <input class="form-control" type="text" id="cvcNumber" placeholder="Enter CVC Number"
                                formControlName="cvcNumber" maxlength="5" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="subscription-card mt-3">
                <h5 class="mb-3">Address Details</h5>
                <!-- <div class="col-md-6">
                <div class="mb-3">
                    <label class="form-label" for="Country">Country:</label>
                    <input class="form-control" id="Country" type="text" formControlName="country" placeholder="Enter Country" />
                </div>
            </div> -->
                <div class="row">
                    <div class="col-md-12 row pr-0">
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label class="form-label" for="address">Address 1:</label>
                            <input class="form-control" id="address" type="text" formControlName="address"
                                placeholder="Address 1" />
                        </div>
                    </div>
                    <div class="col-md-6 pr-0">
                        <div class="mb-3">
                            <label class="form-label" for="address2">Address 2:</label>
                            <input class="form-control" id="address2" type="text" formControlName="address2"
                                placeholder="Address 2" />
                        </div>
                    </div>
                    </div>
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label class="form-label" for="city">City:</label>
                            <ng-select id="city" formControlName="city" (change)="onCityChange($event)">
                                <ng-option value="">Select City</ng-option>
                                <ng-option *ngFor="let city of cities" [value]="city.CityName">{{ city.CityName
                                    }}</ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label class="form-label" for="state">State:</label>
                            <ng-select id="state" formControlName="state" (change)="onStateChange($event)">
                                <ng-option value="">Select State</ng-option>
                                <ng-option *ngFor="let state of states" [value]="state.StateCode">{{ state.StateName
                                    }}</ng-option>
                            </ng-select>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="mb-3">
                            <label class="form-label" for="postalcode">Postal Code:</label>
                            <ng-select id="postalcode" formControlName="zip">
                                <ng-option value="">Select Postal Code</ng-option>
                                <ng-option *ngFor="let postalCode of postalCodes" [value]="postalCode.ZipCode">{{
                                    postalCode.ZipCode }}</ng-option>
                            </ng-select>
                        </div>
                    </div>
                </div>


            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-success" (click)="updatepayment()"
                    [disabled]="subscriptionInfo.invalid">Submit</button>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                    (click)="hideStepper()">Cancel</button>
            </div>
     
    </form>


</div>