import { ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EquipmentPurchaseService } from 'src/app/customer-dashboard/quick-links/equipment-purchase/services/equipment-purchase.service';
import { fadeInOut, fadeInOutSlow } from 'src/app/shared/services/app.animation';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomerProfileService } from 'src/app/shared/services/customer-profile.service';
import { SearchService } from 'src/app/shared/services/search.service';
// import jsPDF from 'jspdf';
// import 'jspdf-autotable';
import { CustomerEmailModule } from '../../customer-email/customer-email.module';
import { filter, Subscription } from 'rxjs';
import { AdhocInvoiceService } from '../shared/adhoc-invoice.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { SafeHtml } from '@angular/platform-browser';
import { AdhocStateService } from '../shared/adhoc-state.service';
import { AdhocBreakupService } from '../shared/adhoc-breakup.service';
@Component({
  selector: 'app-adhoc-form',
  templateUrl: './adhoc-form.component.html',
  styleUrls: ['./adhoc-form.component.scss'],
  animations:[fadeInOut, fadeInOutSlow]
})
export class AdhocFormComponent implements OnInit, OnDestroy, OnChanges{
  paymentSuccessful = false;
  private paymentStatusSubscription!: Subscription;
  summaryitems: any;
  routerSubscription: Subscription;
  isGoBack: boolean;
  customerData: any;
  isCustomerInfoVisible: boolean = false; // Controls the visibility of the customer info container
  isInfoIconVisible: boolean = false; // Controls the visibility of the info icon
  customerPersonalData: any = null; 
  adhocDetailsSubscription: Subscription; 
  adhocDetails: any;
  isValidAddItems: boolean;
  @Input() currentCustomerId:any;
  invoiceSummary = {
    amount: 0, // Default amount
    description: 'No description provided.', // Default description
    isValid:false
  };
calculateDiscountAmount() {

}
calculateDueAmount() {

}
  activeTab: number = 0;
  selectedInvoiceType: string | null = null;

  @ViewChild('customNav', { static: true }) customNav: ElementRef; // Reference for tab navigation

  // Address information
  ServiceAddress: any;
  shippingAddress: any;

  // Order summary and calculations
  public orderSummary = [
    {
      productImage: 'assets/images/products/img-8.png',
      productName: 'Sweatshirt for Men (Pink)',
      pricePerUnit: 119.99,
      quantity: 2,
      totalPrice: 239.98
    },
    {
      productImage: 'assets/images/products/img-7.png',
      productName: 'Noise Evolve Smartwatch',
      pricePerUnit: 94.99,
      quantity: 1,
      totalPrice: 94.99
    },
    {
      productImage: 'assets/images/products/img-3.png',
      productName: '350 ml Glass Grocery Container',
      pricePerUnit: 24.99,
      quantity: 1,
      totalPrice: 24.99
    }
  ];
  public subTotal: number = 0;
  public discount: number = 50.0;
  public shippingCharge: number = 24.99;
  public taxRate: number = 0.12;
  public estimatedTax: number = 0;
  public grandTotal: number = 0;
  invoiceNumber: string = 'INV-001'; // Static example; you can generate dynamically
  currentDate: Date = new Date(); // Capture the current date
  // Invoice types centralized configuration
  invoiceTypes = [
    // { id: 'Equipment', label: 'Equipment', icon: 'fas fa-cogs' },
    // { id: 'Plan Upgrade', label: 'Plan Upgrade', icon: 'fas fa-arrow-up' },
    // { id: 'Plan Downgrade', label: 'Plan Downgrade', icon: 'fas fa-arrow-down' },
    { id: 'Plan Change', label: 'Plan Change', icon: 'fas fa-exchange-alt' },
    { id: 'Plan Renewal', label: 'Plan Renewal', icon: 'fas fa-sync' },
    // { id: 'Custom', label: 'Custom', icon: 'fas fa-pencil-alt' }
  ];

  // Items and new item structure
  items: Array<{
    comboId?: number | null;
    name: string;
    description: string;
    price: number;
    quantity: number;
    discountPrice?: number;
    comboTitle?: string;
    comboType?: string;
    allowMultiOrder?: boolean;
    banners?: Array<any>;
    imagePath?: string;
    navIconClass?: string;
  }> = [];
  
  newItem = { name: '', description: '', price: null, quantity: 1 };

  // Reactive form for invoice
  invoiceForm: FormGroup;
  personalInfo: any;
  planInfo:any;
  res: any;
  companyName:any

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private customerProfileService: CustomerProfileService,
    private equipmentPurchaseService: EquipmentPurchaseService, private searchService:SearchService,  
    private route: ActivatedRoute, private router: Router, private commonService : CommonService,
    private adhocInvoiceService:AdhocInvoiceService, private cdr:ChangeDetectorRef, private authService:AuthService,private stateService: AdhocStateService,
    private adhocBreakupService:AdhocBreakupService
  ) {
    this.invoiceForm = this.fb.group({
      customerId: ['', Validators.required],
      invoiceType: ['', Validators.required],
      billingInfo: this.fb.group({
        firstName: [''],
        lastName: [''],
        email: [''],
        phone: [''],
        address: ['']
      })
    });
  }
  private subscriptions: Subscription = new Subscription();

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes['currentCustomerId'] && changes['currentCustomerId'].currentValue) {
    //   this.invoiceForm.patchValue({ customerId: changes['currentCustomerId'].currentValue });
    //   this.cdr.detectChanges(); // Ensure the change is reflected in the UI
    // }
  }

 async  ngOnInit() {
  if(this.currentCustomerId){
    setTimeout(async () => {
      this.invoiceForm.get('customerId')?.patchValue(this.currentCustomerId);
      const customerIdEvent = { target: { value: this.currentCustomerId } };
      await this.onCustomerIdChange(customerIdEvent);
      console.log('Form after patchValue:', this.invoiceForm.value);
    }, 1000);
  }
  this.adhocBreakupService.getAdhocBreakupDetails$.subscribe({
    next: (details) => {
      if (details) {
        console.log('Received adhoc details:', details);
        this.receivedPlanDetails = details;
      } else {
        console.warn('No details received from the service.');
      }
      // Data is loaded, stop the skeleton loader
    }
  });
  console.log('customerId: ', this.currentCustomerId);

    this.subscribeToAdhocDetails();
    this.getCompanyName();
    this.calculateOrderSummary();
    this.paymentStatusSubscription = this.adhocInvoiceService.paymentStatus$.subscribe((status) => {
      console.log('Payment status received:', status); // Debugging log
      this.paymentSuccessful = status;
      if (this.paymentSuccessful) {
        console.log('Setting activeTab to 4'); // Debugging log
        this.cdr.detectChanges();
        this.activeTab = 4;
        this.goToNextTab();
      }
    });
    this.stateService.isValid$.subscribe((valid) => {
      console.log('isValidAddItems updated:', valid);
      this.isValidAddItems = valid;
    });
    const tabTransitionSubscription = this.stateService.isTransitionToFinish$.subscribe(
      (shouldTransition) => {
        if (shouldTransition && this.activeTab === 3) {
          this.activeTab = 4;
        }
      }
    );
    this.stateService.activeTab$.subscribe((tab) => {
      this.activeTab = tab; // Update the local state when activeTab changes
      this.resetToInitialState()
      console.log('Active Tab:', tab);
    });

    this.subscriptions.add(tabTransitionSubscription);
  }

    // Update the summary dynamically
    updateSummary(updatedSummary: any) {
      this.invoiceSummary = updatedSummary;
    }

    customInvoiceGeneration(){
      try {
        if(this.invoiceSummary?.isValid){
          console.log('All fields are valid. Proceeding to the next step.');
        }else{
          this.commonService.showToast('info','Custom Invoice validation','Please input all required fields.');
          console.log('All fields are not valid. no Proceeding to the next step.');
          return;
        }
      } catch (error) {
        
      }
    }
    equipmentInvoiceGeneration(){
      
    }
  resetToInitialState(){
    this.invoiceForm.reset();
    this.selectedInvoiceType=null;
    this.isCustomerInfoVisible = false;
    this.isInfoIconVisible = false;
    // this.isValidAddItems=false
  }
  ngOnDestroy(): void {
    // Unsubscribe from the router events to avoid memory leaks
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
    if (this.paymentStatusSubscription) {
      this.paymentStatusSubscription.unsubscribe();
    }
    if (this.adhocDetailsSubscription) {
      this.adhocDetailsSubscription.unsubscribe();
    }
  }
  async onCustomerIdChange(event: any) {
    const customerId = event.target.value.trim();
    if (customerId) {
      
      await this.getCustomerTooltipInfo(customerId);
      this.isInfoIconVisible = true;
    } else {
      this.isInfoIconVisible = false; // Hide the info icon when input is empty
      this.isCustomerInfoVisible = false; // Hide the customer info container
    }
  }
  /**
   * Subscribe to adhoc details emitted by AdhocInvoiceService.
   */
  private subscribeToAdhocDetails(): void {
    this.adhocDetailsSubscription = this.adhocInvoiceService.adhocData$.subscribe({
      next: (details) => {
        this.adhocDetails = details || [];
        console.log('Received adhoc details:', this.adhocDetails);
      },
      error: (err) => {
        console.error('Error fetching adhoc details:', err);
      },
    });
  }
  async getCustomerTooltipInfo(customerId: any) {
    try {
      const res: any = await this.customerProfileService.getCustomerMinimalProfileData(customerId);
      if (res?.Status) {
       
        this.personalInfo = this.extractPersonalInfo(res);
        this.customerPersonalData = this.personalInfo; // Ensure this triggers the container display
        console.log('customer personal data: ', this.customerPersonalData);
        this.isCustomerInfoVisible = true; // Show the customer info container
        this.isInfoIconVisible = true; // Hide the info icon since info is displayed
      } else {
        const errorMessage =
          res?.error?.CustomerId?.[0] || 'An unknown error occurred while fetching customer data.';
        this.commonService.showToast('error', 'Error', errorMessage);
        this.isCustomerInfoVisible = false; // Hide the customer info container
        this.isInfoIconVisible = true; // Show the info icon since no valid data is fetched
      }
    } catch (error) {
      console.log('error: ', error);
      const errorMessage =
        error?.error?.error?.CustomerId?.[0] ||
        error?.message ||
        'An unexpected error occurred.';
      this.commonService.showToast('error', 'Error', errorMessage);
      this.isCustomerInfoVisible = false; // Hide the customer info container
      this.isInfoIconVisible = true; // Show the info icon
    }
  }
  toggleCustomerInfo(isVisible: boolean): void {
    this.isCustomerInfoVisible = isVisible;
    this.isInfoIconVisible = true; // Toggle the visibility of the info ic
  }
  getCompanyName(){
    this.companyName= this.authService.getCompanyName()
  }
  
  async getEquipments() {
    try {
      const res: any = await this.equipmentPurchaseService.getDeviceCombos();
      if (res?.Status && Array.isArray(res?.data)) {
        // Map response to match items structure
        this.items = res.data.map((combo: any) => ({
          comboId: combo?.comboId || null,
          name: combo?.comboName || '-',
          description: combo?.comboDesc || 'No description available',
          price: parseFloat(combo?.comboPrice) || 0,
          quantity:  0,
          discountPrice:0,
          comboTitle: combo?.comboTitle || '-',
          comboType: combo?.comboType || '-',
          allowMultiOrder: combo?.allowMultiOrder || false,
          banners: combo?.banners || [],
          imagePath: combo?.imagePath || '',
          navIconClass: combo?.navIconClass || ''
        }));
      } else {
        console.error('Invalid response structure or no data received');
      }
    } catch (error) {
      console.error('Error fetching equipment combos:', error);
    }
  }
  
  
  // Select invoice type
  selectInvoiceType(type: string): void {
    this.invoiceForm.patchValue({ invoiceType: type });
    this.selectedInvoiceType = type;
    if(type== 'Equipment'){
      this.getEquipments();
    }
    console.log('Selected Invoice Type:', type);
  }

  onInvoiceTypeSelection(type:string){

  }

  receivedPlanDetails: any; // To store the plan details received from the child

  handlePlanDetails(planDetails:any): void {
    this.receivedPlanDetails = planDetails; // Store the received plan details
    console.log('Plan details received in parent:', this.receivedPlanDetails);
    // You can now use `receivedPlanDetails` for further logic
  }
  async submitInvoice() {
    try {
      const payload = {
        CustomerId: this.invoiceForm.get('customerId')?.value, // Example customer ID
        invoiceType: this.selectedInvoiceType === 'Plan Change'
          ? 'planChange'
          : this.selectedInvoiceType === 'Plan Renewal'
          ? 'renewal'
          : this.selectedInvoiceType === 'Equipment'
          ? 'equipment'
          : this.selectedInvoiceType, // Default to the original value if no match
          ...(this.receivedPlanDetails?.isAdvancePlan
            ? { advancePlanId: this.receivedPlanDetails?.planDetails?.plan?.id }
            : { planId: this.receivedPlanDetails?.planDetails?.plan?.id }), // Use a property from receivedPlanDetails
      };
      console.log('advancePlanId:', this.receivedPlanDetails?.planDetails);

      console.log('payload', payload);

      // Call the createInvoice method from AdhocInvoiceService
      const response = await this.adhocInvoiceService.createInvoice(payload);
      if (response?.Status) {
        const enrichedResponse = { ...response, customerData: this.customerData, invoiceType:this.selectedInvoiceType };
      await this.adhocInvoiceService.emitInvoiceData(enrichedResponse);
      this.commonService.showToast('info', 'Adhoc Generation Status', 'Adhoc Generated Successfully!');
        this.activeTab = 3; // Proceed to the next tab
      } else {
        this.commonService.showToast('error', 'Adhoc Generation Status', response?.message);
      }
      console.log('Invoice created successfully:', response);
      // Handle the response as needed (e.g., show a success message or navigate)
    } catch (error) {
      console.error('Error creating invoice:', error);
      this.commonService.showToast('error', 'Adhoc Generation Status', error?.error?.error);
      // Handle the error as needed (e.g., show an error message)
    }
  }
  // Tab navigation logic

  async generateInvoiceForPlanChangeOrPlanRenewal() {
    try {
      console.log('invoice type : ', this.selectedInvoiceType);
  
      if (this.selectedInvoiceType === 'Equipment') {
        const subtotal = this.calculateSubTotal();
        if (subtotal > 0) {
          console.log("Subtotal is valid:", subtotal);
          this.activeTab = 3; // Proceed to the next tab
        } else {
          console.log("Subtotal validation failed:", subtotal);
          this.commonService.showToast('error', 'Validation Failed', 'Subtotal must be greater than zero.');
        }
      } else if (this.selectedInvoiceType === 'Plan Change' || this.selectedInvoiceType === 'Plan Renewal') {
        console.log("Validating plan change or renewal...");
        
        if (!this.isValidAddItems) {
          this.commonService.showToast('info', 'Plan Change', 'Select a valid plan before proceeding.');
          return;
        }
  
        try {
          const response:any = await this.submitInvoice();
          console.log("Invoice submitted:", response);
  
          // Check if the response indicates success
          // if (response?.Status) {
          //   this.activeTab = 3; // Proceed to the next tab
          // } else {
          //   this.commonService.showToast('error', 'Submission Failed', 'Unable to proceed. Please check your inputs.');
          // }
        } catch (error) {
          console.error('Error during invoice submission:', error);
          this.commonService.showToast('error', 'Submission Error', 'An error occurred while submitting the invoice.');
        }
      }
    } catch (error) {
      console.error('Error in generateInvoiceForPlanChangeOrPlanRenewal:', error);
      this.commonService.showToast('error', 'Unexpected Error', 'An unexpected error occurred. Please try again.');
    }
  }
  async goToNextTab() {
    this.isGoBack = false;
    if (this.activeTab === 0) {
      if (!this.validateCustomerId()) {
        return; // Exit if validation fails
      }
      const customerId = this.invoiceForm.get('customerId')?.value;
      try {
        await this.getCustomerInfo(customerId);
        this.activeTab = 1;
      } catch (error) {
        console.error('Error fetching customer profile:', error);
      }
    } else if (this.activeTab === 1 && this.invoiceForm.get('billingInfo')?.valid) {
      this.activeTab = 2;
    } else if (this.activeTab === 2 ) {
      await this.generateInvoiceForPlanChangeOrPlanRenewal();
    } else if(this.activeTab==3){
      // if(this.paymentSuccessful){
        this.activeTab=0;
        this.resetToInitialState();
      // }
    } else{
      this.invoiceForm.markAllAsTouched();
    }
  }

  async goToPreviousTab() {
    this.isGoBack = true;
    try {
      if (this.activeTab > 0) {
        this.activeTab--;
        this.toggleCustomerInfo(false)
        if(this.activeTab=== 3){
          this.isValidAddItems=false
        }
      }
      if (this.activeTab === 0) {
        // Call resetTabStates when at the first tab
        console.log('Resetting tab states at activeTab 0');
        await this.resetTabStates();
      }

    } catch (error) {
      console.error('Error navigating to the previous tab:', error);
    }
  }
  
  private validateCustomerId(): boolean {
    const customerIdControl = this.invoiceForm.get('customerId');
    const InvoiceTypeControl = this.invoiceForm.get('invoiceType');
    if (!customerIdControl?.value || customerIdControl.invalid || !InvoiceTypeControl?.value || InvoiceTypeControl.invalid ) {
      // Show error message if Customer ID is invalid or empty
      customerIdControl?.markAsTouched();
      if(!customerIdControl?.value || customerIdControl.invalid ){
        this.commonService.showToast('info', 'Validation Info', 'Please provide a valid Customer ID.');
        return false;
      }
      if(!InvoiceTypeControl?.value || InvoiceTypeControl.invalid ){
        this.commonService.showToast('info', 'Validation Info', 'Please Choose a Invoice Type.');
        return false;
      }
 // Validation failed
    }
    return true; // Validation succeeded
  }
  
  resetTabStates() {
    console.log('resetTabStates triggered'); // Debugging log
  
    // Reset only necessary states
    this.summaryItems = []; // Clear summary items
    this.subTotal = 0; // Reset subtotal
    this.grandTotal = 0; // Reset grand total
  
    // Additional states to reset, if required
    // this.personalInfo = null;
    // this.shippingAddress = null;
  }
  
 // Method to Download Invoice as PDF
 downloadPDF(): void {
let doc:any;
  doc.setFontSize(16);
  doc.text('Invoice', 105, 20, { align: 'center' });
  doc.setFontSize(12);
  doc.text(`Invoice Number: ${this.invoiceNumber}`, 20, 30);
  doc.text(`Date: ${this.currentDate.toLocaleDateString()}`, 20, 40);
  doc.text('Billed To:', 20, 50);
  doc.text(`${this.personalInfo?.firstName} ${this.personalInfo?.lastName}`, 20, 60);
  doc.text(this.shippingAddress, 20, 70);

  // Table for Items
  const tableColumn = ['#', 'Item', 'Price', 'Quantity', 'Total'];
  const tableRows = this.items.map((item, index) => [
    index + 1,
    item.name,
    `$${item.price.toFixed(2)}`,
    item.quantity,
    `$${(item.price * item.quantity).toFixed(2)}`,
  ]);

  // Add Totals
  tableRows.push(
    ['', '', '', 'Sub Total', `$${this.calculateSubTotal().toFixed(2)}`],
    ['', '', '', 'Discount', `-$${this.discount.toFixed(2)}`],
    ['', '', '', 'Shipping', `$${this.shippingCharge.toFixed(2)}`],
    ['', '', '', 'Tax (12%)', `$${this.calculateTax().toFixed(2)}`],
    ['', '', '', 'Total', `$${this.calculateTotal().toFixed(2)}`]
  );

  doc.autoTable({
    head: [tableColumn],
    body: tableRows,
    startY: 80,
  });

  // Save PDF
  doc.save(`Invoice_${this.invoiceNumber}.pdf`);
}
  changeTab(nextTabId: number): void {
    this.activeTab = nextTabId;
  }

  // Fetch customer info
  async getCustomerInfo(customerId: any) {
    try {
      const res: any = await this.customerProfileService.getCustomerMinimalProfileData(customerId);
      if (res?.Status) {
        this.res = res;
        this.ServiceAddress = this.formatAddress(res?.data?.personalInfo?.address?.value);
        this.shippingAddress = this.formatAddress(res?.data?.personalInfo?.mailingAddress?.value);
        this.personalInfo = this.extractPersonalInfo(res);
        this.customerData= res?.data;
        this.planInfo = this.extractPlanInfo(res);
      } else {
      const errorMessage = res?.error?.CustomerId?.[0] || 'An unknown error occurred while fetching customer data.';
      this.commonService.showToast('error', 'Error', errorMessage);
      }
    } catch (error) {
      console.log('error: ', error);
    const errorMessage = error?.error?.error?.CustomerId?.[0] || error?.message || 'An unexpected error occurred.';
    this.commonService.showToast('error', 'Error', errorMessage);
    this.invoiceForm.markAsTouched();
    throw error;

    }
  }

  formatAddress(address: any): string {
    if (!address) return '-';
    const { address1 = '', address2 = '', city = '', state = '', zipCode = '' } = address;
    return `${address1} ${address2}, ${city}, ${state}, ${zipCode}`.trim();
  }

  extractPersonalInfo(res: any): any {
    return {
      customerId: res?.data?.personalInfo?.customerId || '-',
      firstName: res?.data?.personalInfo?.firstName?.value || '-',
      lastName: res?.data?.personalInfo?.lastName?.value || '-',
      email: res?.data?.personalInfo?.email?.value || '-',
      accountStatus: res?.data?.personalInfo?.accountStatus?.value || '-'
    };
  }

  extractPlanInfo(res: any): any {
    return {
        plan: res?.data?.plan
    };
  }

  // Order summary calculations
  calculateOrderSummary(): void {
    this.subTotal = this.orderSummary.reduce((total, item) => total + item.totalPrice, 0);
    this.estimatedTax = this.subTotal * this.taxRate;
    this.grandTotal = this.subTotal - this.discount + this.shippingCharge + this.estimatedTax;
  }

  deleteOrderItem(index: number): void {
    // Get the item to delete
    const itemToDelete = this.items[index];
  
    // Remove the item from the items list
    this.items.splice(index, 1);
  
    // Find and remove the item from summaryItems, if it exists
    this.summaryItems = this.summaryItems.filter(summaryItem => summaryItem.name !== itemToDelete.name);
  }
  
  // Item manipulation methods
  increaseQuantity(index: number): void {
    this.items[index].quantity++;

    // Check if the item exists in summaryItems
    const existingIndex = this.summaryItems.findIndex(item => item.name === this.items[index].name);

    if (this.items[index].quantity > 0) {
      if (existingIndex === -1) {
        // Add item to summaryItems if it doesn't already exist
        this.summaryItems.push(this.items[index]);
      } else {
        // Update the quantity in summaryItems
        this.summaryItems[existingIndex] = this.items[index];
      }
    }
  }

  decreaseQuantity(index: number): void {
    if (this.items[index].quantity > 0) {
      this.items[index].quantity--;
  
      // Check if the item exists in summaryItems
      const existingIndex = this.summaryItems.findIndex(item => item.name === this.items[index].name);
  
      if (this.items[index].quantity === 0 && existingIndex !== -1) {
        // Remove item from summaryItems if quantity is 0
        this.summaryItems.splice(existingIndex, 1);
      } else if (existingIndex !== -1) {
        // Update the quantity in summaryItems
        this.summaryItems[existingIndex].quantity = this.items[index].quantity;
      }
    }
  }
  
  get validItemsCount(): number {
    return this.items.filter(item => item.quantity > 1).length;
  }
  
  summaryItems: any[] = [];


  addItem(): void {
    if (this.newItem.name && this.newItem.description && this.newItem.price > 0) {
      this.items.push({ ...this.newItem });
      this.newItem = { name: '', description: '', price: null, quantity: 1 };
    } else {
      alert('Please provide valid inputs for all fields.');
    }
  }

  removeItem(index: number): void {
    const itemToRemove = this.items[index];
    this.items.splice(index, 1);
    this.summaryItems = this.summaryItems.filter(summaryItem => summaryItem.name !== itemToRemove.name);
  }
  



  async sendToCustomerDashboard(CustomerID: string, activeTab: number) {
    await this.customerProfileService.setCustomerId(CustomerID)
    this.customerProfileService.getMinimalProfileData()
    this.adhocInvoiceService.setState({ showBack: true, activeTab: this.activeTab, customerId: CustomerID });
    await this.router.navigate(['/customer-dashboard/customer-profile'], {
      queryParams: { showBack: true, activeTab: this.activeTab },
    });
  }
  calculateSubTotal(): number {
    return this.summaryItems.reduce((total, item) => total + item.price * item.quantity, 0);
  }
  
  calculateTax(): number {
    return 0;
  }
  
  calculateTotal(): number {
    return this.calculateSubTotal() + this.calculateTax();
  }
  
}

