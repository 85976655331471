<!-- <app-tap-to-top></app-tap-to-top> -->
<router-outlet></router-outlet>
<!-- <ng-container *ngIf="commonService.loader">
    <app-loader></app-loader>
</ng-container> -->
<ng-container>
    <app-global-loader ></app-global-loader>
    <app-global-notification *ngIf="refreshLogin == '1'" style="visibility: hidden; display: none;"></app-global-notification> 
</ng-container>
<!-- <p-toast [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}"></p-toast> -->
<app-toast></app-toast>