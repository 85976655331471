<div class="container-fluid">
    <div class="row widget-grid" >
        <div class="col-xxl-9 col-sm-9 box-col-7">
            <div class="row widget-grid" style="height:fit-content;">
                <div class="col-xxl-6 col-sm-6 box-col-6">
                    <div class="appointment">
                        <div class="card mb-3 track-card" *ngIf="!isSkeletonLoader" @fadeInOut>
                            <div class="card-header py-2 card-no-border">
                                <div class="header-top">
                                    <h5 class="m-0 card-title">Personal Information</h5>
                                    
                                    <app-feather-icons [icon]="'maximize'"
                                        (click)="toggleMoreInf(); moreInfoSelectedTab('Personal Information')"
                                        class="ms-2 text-primary cursor-pointer track-expand "></app-feather-icons>
                                </div>
                            </div>
                            <div class="card-body pt-0">
                                <div class="appointment-table table-responsive">
                                    <table class="table table-bordernone">
                                        <tbody>
                                            <tr>
                                                <td class="img-content-box">
                                                    <a class="d-block f-w-500">Customer ID</a>
                                                </td>
                                                <td class="text-end">
                                                    <p class="m-0 font-success">
                                                        {{customerProfileService.minimalProfileData?.personalInfo?.customerId}}
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr
                                                *ngIf="customerProfileService.minimalProfileData?.personalInfo?.name?.visible=='Y'">
                                                <td class="img-content-box">
                                                    <a class="d-block f-w-500">Name</a>
                                                </td>
                                                <td class="text-end">
                                                    <p class="m-0 font-success">
                                                        {{customerProfileService.minimalProfileData?.personalInfo?.name?.value}}
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr
                                                *ngIf="customerProfileService.minimalProfileData?.personalInfo?.phone?.visible=='Y'">
                                                <td class="img-content-box">
                                                    <a class="d-block f-w-500">Alternate Number</a>
                                                </td>
                                                <td class="text-end">
                                                    <p class="m-0 font-success">
                                                        {{customerProfileService.minimalProfileData?.personalInfo?.phone?.value}}
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr
                                                *ngIf="customerProfileService.minimalProfileData?.personalInfo?.email?.visible=='Y'">
                                                <td class="img-content-box">
                                                    <a class="d-block f-w-500">Email</a>
                                                </td>
                                                <td class="text-end" style="float: right;">
                                                    <p class="m-0 font-success text-wrap cursor-pointer"
                                                        [title]="customerProfileService.minimalProfileData?.personalInfo?.email?.value">
                                                        {{customerProfileService.minimalProfileData?.personalInfo?.email?.value}}
                                                    </p>
                                                </td>
                                            </tr>

                                            <tr
                                                *ngIf="customerProfileService.minimalProfileData?.personalInfo?.address?.visible=='Y'">
                                                <td class="img-content-box">
                                                    <a class="d-block f-w-500">Service Address (Permanent)</a>
                                                </td>
                                                <td class="text-end">
                                                    <p class="m-0 p-0 font-success">
                                                        <ng-container
                                                            *ngIf="customerProfileService.minimalProfileData?.personalInfo?.address?.value?.address1">
                                                            {{customerProfileService.minimalProfileData?.personalInfo?.address?.value?.address1}},
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="customerProfileService.minimalProfileData?.personalInfo?.address?.value?.address2">
                                                            {{customerProfileService.minimalProfileData?.personalInfo?.address?.value?.address2}},
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="customerProfileService.minimalProfileData?.personalInfo?.address?.value?.city">
                                                            {{customerProfileService.minimalProfileData?.personalInfo?.address?.value?.city}},
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="customerProfileService.minimalProfileData?.personalInfo?.address?.value?.state">
                                                            {{customerProfileService.minimalProfileData?.personalInfo?.address?.value?.state}},
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="customerProfileService.minimalProfileData?.personalInfo?.address?.value?.zipCode">
                                                            {{customerProfileService.minimalProfileData?.personalInfo?.address?.value?.zipCode}}
                                                        </ng-container>
                                                    </p>
                                                </td>
                                            </tr>

                                            <!--<tr
                                                *ngIf="customerProfileService.minimalProfileData?.personalInfo?.dateOfBirth?.visible=='Y'">-->

                                            <tr
                                                *ngIf="customerProfileService.minimalProfileData?.personalInfo?.mailingAddress?.visible=='Y'">
                                                <td class="img-content-box">
                                                    <a class="d-block f-w-500">Shipping Address</a>
                                                </td>
                                                <td class="text-end">
                                                    <p class="m-0 p-0 font-success">
                                                        <ng-container
                                                            *ngIf="customerProfileService.minimalProfileData?.personalInfo?.mailingAddress?.value?.address1">
                                                            {{customerProfileService.minimalProfileData?.personalInfo?.mailingAddress?.value?.address1}},
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="customerProfileService.minimalProfileData?.personalInfo?.mailingAddress?.value?.address2">
                                                            {{customerProfileService.minimalProfileData?.personalInfo?.mailingAddress?.value?.address2}},
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="customerProfileService.minimalProfileData?.personalInfo?.mailingAddress?.value?.city">
                                                            {{customerProfileService.minimalProfileData?.personalInfo?.mailingAddress?.value?.city}},
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="customerProfileService.minimalProfileData?.personalInfo?.mailingAddress?.value?.state">
                                                            {{customerProfileService.minimalProfileData?.personalInfo?.mailingAddress?.value?.state}},
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="customerProfileService.minimalProfileData?.personalInfo?.mailingAddress?.value?.zipCode">
                                                            {{customerProfileService.minimalProfileData?.personalInfo?.mailingAddress?.value?.zipCode}}
                                                        </ng-container>
                                                    </p>
                                                </td>
                                            </tr>
                                            <!-- <tr *ngIf="customerProfileService.minimalProfileData?.personalInfo?.dateOfBirth?.visible=='Y'">

                                                <td class="img-content-box">
                                                    <a class="d-block f-w-500">Date of Birth</a>
                                                </td>
                                                <td class="text-end">
                                                    <p class="m-0 font-success">
                                                        {{customerProfileService.minimalProfileData?.personalInfo?.dateOfBirth?.value}}
                                                    </p>
                                                </td>

                                            </tr>
                                            <tr
                                                *ngIf="customerProfileService.minimalProfileData?.personalInfo?.ssn?.visible=='Y'">

                                            </tr> 
                                            <tr *ngIf="customerProfileService.minimalProfileData?.personalInfo?.ssn?.visible=='Y'">

                                                <td class="img-content-box">
                                                    <a class="d-block f-w-500">SSN</a>
                                                </td>
                                                <td class="text-end">
                                                    <p class="m-0 font-success">
                                                        {{customerProfileService.minimalProfileData?.personalInfo?.ssn?.value}}
                                                    </p>
                                                </td>
                                            </tr> -->
                                            <tr>
                                                <td class="img-content-box">
                                                    <a class="d-block f-w-500 mb-3">Account Password</a>
                                                </td>
                                                <td class="text-end">
                                                    <p class="m-0 font-success">

                                                        {{customerProfileService.minimalProfileData?.personalInfo?.accountPassword
                                                        ?.value}}

                                                    </p>
                                                </td>
                                            </tr>


                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                        <div *ngIf="isSkeletonLoader" @fadeInOut style="max-width: 100%;">
                            <app-skeleton-loader [alignment]="'row'" [cardCount]="1" shape="medium-card"></app-skeleton-loader>
                        </div>
                    </div>
                </div>
                <div class="col-xxl-6 col-sm-6 box-col-6">
                    <div class="appointment">
                        <div class="card mb-3 track-card" *ngIf="!isSkeletonLoader && !isLineInfoRefreshed" @fadeInOut>
                            <div class="card-header py-2 card-no-border">
                                <div class="header-top">
                                    <h5 class="m-0 card-title">Line Information</h5>
                                    <div>
                                        <app-feather-icons [icon]="'refresh-cw'" (click)="refreshAllCards()"
                                            class="ms-2 text-primary cursor-pointer"></app-feather-icons>
                                        <!-- <app-feather-icons [icon]="'refresh-cw'"
                                            (click)="moreInfoSelectedTab('Line Information')"
                                            class="ms-2 text-primary cursor-pointer"></app-feather-icons> -->
                                        <app-feather-icons [icon]="'maximize'"
                                            (click)="toggleMoreInf();moreInfoSelectedTab('Line Information')"
                                            class="ms-2 text-primary cursor-pointer track-expand"></app-feather-icons>
                                    </div>

                                </div>
                            </div>
                            <div class="card-body pt-0">
                                <!-- <ng-container *ngIf="!cardLoader"> -->
                                <div class="appointment-table table-responsive">
                                    <table class="table table-bordernone">
                                        <tbody>
                                            <tr
                                                *ngIf="customerProfileService.minimalProfileData?.lineInfo?.subscriberStatus?.visible=='Y'">
                                                <td class="img-content-box">
                                                    <a class="d-block f-w-500">Line Status </a>
                                                </td>
                                                <td class="text-end">
                                                    <p class="m-0 font-success">
                                                        {{customerProfileService.minimalProfileData?.lineInfo?.subscriberStatus?.value}}
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr
                                                *ngIf="customerProfileService.minimalProfileData?.lineInfo?.MDN?.visible=='Y'">
                                                <td class="img-content-box">
                                                    <a class="d-block f-w-500" >MSISDN [{{signUpType || '-'}}]</a>
                                                </td>
                                                <td class="text-end">
                                                    <p class="m-0 font-success">
                                                        {{customerProfileService.minimalProfileData?.lineInfo.MDN.value}}
                                                    </p>

                                                </td>
                                            </tr>

                                            <tr
                                                *ngIf="customerProfileService.minimalProfileData?.lineInfo?.simNumber?.visible=='Y'">
                                                <td class="img-content-box">
                                                    <a class="d-block f-w-500 text-nowrap" *ngIf="!simType">SIM Number
                                                        [{{simType || '-'}}]
                                                        <!-- <app-feather-icons [icon]="'refresh-cw'" (click)="refreshSimType()"
                                            class="ms-2 small-icon"></app-feather-icons> -->
                                                        <i class="fa fa-refresh text-primary cursor-pointer"
                                                            aria-hidden="true" (click)="refreshSimType()"></i>
                                                    </a>
                                                    <a class="d-block f-w-500 text-nowrap" *ngIf="simType">SIM Number [{{simType ||
                                                        '-'}}]

                                                    </a>
                                                </td>
                                                <td class="text-end">
                                                    <p class="m-0 font-success">
                                                        {{customerProfileService.minimalProfileData?.lineInfo?.simNumber?.value}}
                                                    </p>
                                                </td>
                                            </tr>

                                            <tr
                                                *ngIf="customerProfileService.minimalProfileData?.plan?.customerPlan?.visible=='Y'">
                                                <td class="img-content-box">
                                                    <a class="d-block f-w-500">Customer Plan</a>
                                                </td>
                                                <td class="text-end">
                                                    <p class="m-0 font-success">
                                                        {{customerProfileService.minimalProfileData?.plan?.customerPlan?.value}}
                                                    </p>
                                                </td>
                                            </tr>
                                            <!-- <tr
                                                *ngIf="customerProfileService.minimalProfileData?.lineInfo?.simCarrierStatus?.visible=='Y'">
                                                <td class="img-content-box">
                                                    <a class="d-block f-w-500">IccidStatus</a>
                                                </td>
                                                <td class="text-end">
                                                    <p class="m-0 font-success">
                                                        {{customerProfileService.minimalProfileData?.lineInfo?.simCarrierStatus?.value}}
                                                    </p>

                                                </td>
                                            </tr> -->
                                            <tr
                                                *ngIf="customerProfileService.minimalProfileData?.lineInfo?.puk1?.visible=='Y'">
                                                <td class="img-content-box">
                                                    <a class="d-block f-w-500">Puk1</a>
                                                </td>
                                                <td class="text-end">
                                                    <p class="m-0 font-success">
                                                        {{customerProfileService.minimalProfileData?.lineInfo?.puk1?.value}}
                                                    </p>

                                                </td>
                                            </tr>
                                            <tr
                                                *ngIf="customerProfileService.minimalProfileData?.lineInfo?.puk2?.visible=='Y'">
                                                <td class="img-content-box">
                                                    <a class="d-block f-w-500">Puk2</a>
                                                </td>
                                                <td class="text-end">
                                                    <p class="m-0 font-success">
                                                        {{customerProfileService.minimalProfileData?.lineInfo?.puk2?.value}}
                                                    </p>

                                                </td>
                                            </tr>
                                            <tr
                                                *ngIf="customerProfileService.minimalProfileData?.plan?.currentAps?.visible=='Y'">
                                                <td class="img-content-box">
                                                    <a class="d-block f-w-500">Current APS</a>
                                                </td>
                                                <td class="text-end">
                                                    <p class="m-0 font-success">
                                                        {{customerProfileService.minimalProfileData?.plan?.currentAps?.value}}
                                                    </p>

                                                </td>
                                            </tr>
                                            <tr
                                                *ngIf="customerProfileService.minimalProfileData?.plan?.carrierCode?.visible=='Y'">
                                                <td class="img-content-box">
                                                    <a class="d-block f-w-500">Product Id</a>
                                                </td>
                                                <td class="text-end">
                                                    <p class="m-0 font-success">
                                                        {{customerProfileService.minimalProfileData?.plan?.carrierCode?.value}}
                                                    </p>

                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>

                                </div>
                                <!-- </ng-container> -->
                                <!-- <ng-container *ngIf="cardLoader">
                                    <app-loader [isChildLoader]="true"></app-loader>
                                </ng-container> -->
                            </div>
                        </div>
                        <div *ngIf="isSkeletonLoader " @fadeInOut style="max-width: 100%;">
                            <app-skeleton-loader [alignment]="'row'" [cardCount]="1" shape="medium-card"></app-skeleton-loader>
                        </div>
                        <div *ngIf="isLineInfoRefreshed" @fadeInOut style="max-width: 100%;">
                            <div class="card m-2">
                                <app-skeleton-loader [animatedRowCount]="10" shape="animated-rows"></app-skeleton-loader>
                            </div>
                        </div>
                       
                    </div>
                </div>
             
                   



           

                <div class="col-12 m-0  box-col-12">
                    <app-customer-notes [isCalledFromCustomerProfile]="true"
                        *ngIf="showNotesComponent"></app-customer-notes>
                </div>
                <!-- <div class="col-12 m-0" style="overflow-y:hidden"> -->
                <!-- <div class="col-12 m-0 p-0" style="overflow-y:hidden" *ngIf="isNoNotes">
                            <ng-container *ngTemplateOutlet="customerEventTemplate"></ng-container>
                          </div> -->
                <!-- </div> -->

            </div>
        </div>

        <div class="col-xxl-3 col-sm-3 box-col-5">
            <div class="div" *ngIf="isAlert">
                <app-alert-card></app-alert-card>

            </div>
            <div class="row">

                <ng-container *ngIf="isSubscription">
                    <div class="col-12">
                        <div class="card crypto-main-card track-card" *ngIf="!isSkeletonLoader " @fadeInOut [ngClass]="{
                            'subscriptionIsFakeTrue': subscriptionIsFake, 
                            'subscriptionIsFakeFalse': !subscriptionIsFake
                          }">
                            <div class="card-body py-3 mb-2">
                                <div class="deposit-wrap mb-1">
                                    <div class="w-100">
                                        <div class="header-top  ">
                                            <h5 class="f-w-500 card-title">Subscription Information</h5>
                                            <!-- <ng-container *ngIf="isSubscription"> -->
                                            <app-feather-icons [icon]="'maximize'" (click)="toggleMoreInf()"
                                                (click)="moreInfoSelectedTab('Subscription Information')"
                                                class="ms-2 text-primary cursor-pointer track-expand"></app-feather-icons>
                                            <!-- </ng-container> -->
                                        </div>
                                        <br>

                                        <div class="row">
                                            <div class="col-6">
                                                <p class="f-w-500"> First Name</p>
                                            </div>
                                            <div class="col-6 font-success">
                                                <p class="f-w-500 ">
                                                    {{customerProfileService.minimalProfileData?.subscription[0]?.FirstName
                                                    || '-'}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <p class="f-w-500"> Last Name</p>
                                            </div>
                                            <div class="col-6 font-success">
                                                <p class="f-w-500">
                                                    {{customerProfileService.minimalProfileData?.subscription[0]?.LastName
                                                    || '-'}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <p class="f-w-500"> Email Address</p>
                                            </div>
                                            <div class="col-6 font-success">
                                                <p class="f-w-500">
                                                    {{customerProfileService.minimalProfileData?.subscription[0]?.EmailAddress
                                                    || '-'}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <p class="f-w-500"> Subscription Status</p>
                                            </div>
                                            <div class="col-6 font-success">
                                                <p class="f-w-500 ">
                                                    {{customerProfileService.minimalProfileData?.subscription[0]?.Status
                                                    || '-'}}
                                                </p>
                                            </div>
                                        </div>

                                        <div class="mt-2  mb-2 row">
                                            <div class="col-6">
                                                <p class="f-w-500">Auto Renew</p>
                                            </div>
                                            <div class="col-6 font-success">
                                                <div class="media">
                                                    <div class=" text-end icon-state">
                                                        <label class="switch">
                                                            <input type="checkbox" checked="" [(ngModel)]="autoRenew"
                                                                (change)="autoRenewSub($event)"><span
                                                                class="switch-state bg-primary"
                                                                [ngClass]="{'bg-primary': autoRenew}"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-6 font-success">
                                                <p class="f-w-500"> SubscriptionId </p>
                                            </div>
                                            <div class="col-6">
                                                <p class="f-w-500">
                                                    {{customerProfileService.minimalProfileData?.subscription[0]?.SubscriptionId
                                                    || '-'}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6 font-success">
                                                <p class="f-w-500"> Subscription Type </p>
                                            </div>
                                            <div class="col-6">
                                                <p class="f-w-500">
                                                    {{customerProfileService.minimalProfileData?.subscription[0]?.SubscriptionType
                                                    || '-'}}
                                                </p>
                                            </div>
                                        </div>
                                        <!-- <div *ngIf="customerProfileService.minimalProfileData?.subscription[0]?.StartDate"
                                            class="row">
                                            <div class="col-6">
                                                <p class="f-w-500"> Start Date </p>
                                            </div>
                                            <div class="col-6 font-success">
                                                <p class="f-w-500">
                                                    {{
                                                    commonService.formatDate(customerProfileService.minimalProfileData?.subscription[0]?.StartDate)
                                                    }}
                                                </p>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-6 font-success">
                                                <p class="f-w-500"> Profile Id </p>
                                            </div>
                                            <div class="col-6">
                                                <p class="f-w-500">
                                                    {{customerProfileService.minimalProfileData?.subscription[0]?.ProfileId
                                                    || '-'}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6 font-success">
                                                <p class="f-w-500"> Payment Profile Id </p>
                                            </div>
                                            <div class="col-6">
                                                <p class="f-w-500">
                                                    {{customerProfileService.minimalProfileData?.subscription[0]?.PaymentProfileId
                                                    || '-'}}
                                                </p>
                                            </div>
                                        </div>



                                        <div class="row">
                                            <div class="col-6 ">
                                                <p class="f-w-500"> Created Date</p>
                                            </div>
                                            <div class="col-6 font-success">
                                                <p class="f-w-500">
                                                    {{commonService.formatDate(customerProfileService.minimalProfileData?.subscription[0]?.CreatedDate)
                                                    }} </p>
                                            </div>
                                        </div>

                                        <div *ngIf="customerProfileService.minimalProfileData?.subscription"
                                            class="row">
                                            <div class="col-6">
                                                <p class="f-w-500"> Updated Date </p>
                                            </div>
                                            <div class="col-6 font-success">
                                                <p class="f-w-500">
                                                    {{commonService.formatDate(customerProfileService.minimalProfileData?.subscription[0]?.UpdatedDate)
                                                    }}</p>
                                            </div>
                                        </div> -->
                                        <div *ngIf="customerProfileService.minimalProfileData?.subscription"
                                            class="row">
                                            <div class="col-6">
                                                <p class="f-w-500">Next Billing Date </p>
                                            </div>
                                            <div class="col-6 font-success">
                                                <p class="f-w-500">
                                                    {{
                                                    commonService.formatDate(customerProfileService.minimalProfileData?.subscription[0]?.NextBillingDate)
                                                    }}
                                                </p>
                                            </div>
                                        </div>
                                        <div *ngIf="customerProfileService.minimalProfileData?.subscription"
                                        class="row">
                                        <div class="col-6">
                                            <p class="f-w-500">Next Renewal Date </p>
                                        </div>
                                        <div class="col-6 font-success">
                                            <p class="f-w-500">
                                                {{
                                                commonService.formatDate(customerProfileService.minimalProfileData?.subscription[0]?.NextRenewalDate)
                                                }}
                                            </p>
                                        </div>
                                    </div>

                                        <!-- <p>Lorem Ipsum is simply dummy text</p>
                                        <button class="btn btn-white f-w-500">Deposit Now</button> -->
                                    </div>
                                </div>
                                <!-- <div class="text-center mt-3">
                                    <button class="btn btn-primary" (click)="toggleMoreInf()">More info</button>
                                </div> -->
                            </div>
                        </div>
                        <div *ngIf="isSkeletonLoader" @fadeInOut style="max-width: 100%;">
                            <app-skeleton-loader [alignment]="'row'" [cardCount]="1" shape="medium-card"></app-skeleton-loader>
                        </div>
                    </div>
                </ng-container>
          
                <div class="col-md-12">
                    <div class="card  track-card" *ngIf="!isSkeletonLoader && !isusageRefreshed" @fadeInOut>

                        <div class="car">
                            <div class="media">
                                <div class="media-body">
                                    <div class="greeting-user">
                                        <div class="header-top m-3">
                                            <h5 class="f-w-600 text-dark card-title">Usage</h5>
                                            <div>
                                                <app-feather-icons [icon]="'refresh-cw'" (click)="refreshUsageCard()"
                                                    class="ms-2 text-primary cursor-pointer"></app-feather-icons>

                                                <app-feather-icons [icon]="'maximize'"
                                                    (click)="toggleMoreInf();moreInfoSelectedTab('Usage')"
                                                    class="ms-2 text-primary cursor-pointer track-expand"></app-feather-icons>
                                            </div>

                                        </div>
                                        <div class="div" *ngIf="isUsage">
                                            <app-usage-info></app-usage-info>
                                            <div *ngIf="customerProfileService.minimalProfileData?.usages.length>0">
                                                <div class="text-white"
                                                    *ngFor="let usage of customerProfileService.minimalProfileData?.usages;">
                                                    <ng-container *ngFor="let it of usage | keyvalue;let i=index"> <span
                                                            *ngIf="getVisibility(usage,it.key)=='Y'">{{it.key}} :
                                                            {{getValue(usage,it.key)}} <br> </span></ng-container>
                                                    <br>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <ng-container *ngIf="usageInfoData">
                                            <ng-container *ngIf="usageInfoData?.liveUsage?.voice?.limit>0">
                                                <p class="mb-0">Call ({{usageInfoData?.liveUsage?.voice?.used}} /
                                                    {{usageInfoData?.liveUsage?.voice?.limit}})</p>
                                                <div class="progress mb-3">
                                                    <div class="progress-bar" role="progressbar"
                                                        [style.width]="(usageInfoData?.liveUsage?.voice?.used/usageInfoData?.liveUsage?.voice?.limit )*100 + '%'">
                                                        {{(usageInfoData?.liveUsage?.voice?.used/usageInfoData?.liveUsage?.voice?.limit
                                                        )*100 | number : '1.2-2'}} %</div>
                                                </div>
                                            </ng-container>

                                            <ng-container *ngIf="usageInfoData?.liveUsage?.sms?.limit>0">
                                                <p class="mb-0">SMS ({{usageInfoData?.liveUsage?.sms?.used}} /
                                                    {{usageInfoData?.liveUsage?.sms?.limit}})</p>
                                                <div class="progress mb-3">
                                                    <div class="progress-bar" role="progressbar"
                                                        [style.width]="(usageInfoData?.liveUsage?.sms?.used/usageInfoData?.liveUsage?.sms?.limit )*100 + '%'">
                                                        {{(usageInfoData?.liveUsage?.sms?.used/usageInfoData?.liveUsage?.sms?.limit
                                                        )*100 | number : '1.2-2'}} %
                                                    </div>
                                                </div>
                                            </ng-container>



                                            <p class="mb-0">DATA ({{ (usageInfoData?.liveUsage?.planData?.full?.used /
                                                1024) | number:'1.2-2' }} GB
                                                /
                                                {{(usageInfoData?.liveUsage?.planData?.full?.limit / 1024) |
                                                number:'1.2-2' }} GB)</p>
                                            <div class="progress mb-3">
                                                <div class="progress-bar" role="progressbar"
                                                    [style.width]="(usageInfoData?.liveUsage?.planData?.full?.used/usageInfoData?.liveUsage?.planData?.full?.limit )*100 + '%'">
                                                    {{(usageInfoData?.liveUsage?.planData?.full?.used/usageInfoData?.liveUsage?.planData?.full?.limit
                                                    )*100 | number : '1.2-2'}} %
                                                </div>
                                            </div>
                                            <ng-container *ngIf="usageInfoData?.liveUsage?.boltonData?.full?.limit>0">
                                                <p class="mb-0">TOPUP DATA
                                                    ({{(usageInfoData?.liveUsage?.boltonData?.full?.used / 1024) |
                                                    number:'1.2-2' }} GB /
                                                    {{(usageInfoData?.liveUsage?.boltonData?.full?.limit/ 1024) |
                                                    number:'1.2-2' }} GB )</p>
                                                <div class="progress mb-3">
                                                    <div class="progress-bar" role="progressbar" [style.width]="(usageInfoData?.liveUsage?.boltonData?.full?.used/usageInfoData?.liveUsage?.boltonData?.full?.limit
                                                        )*100 + '%'">
                                                        {{(usageInfoData?.liveUsage?.boltonData?.full?.used/usageInfoData?.liveUsage?.boltonData?.full?.limit
                                                        )*100 | number : '1.2-2'}} %
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="usageInfoData?.liveUsage?.tetherData?.full?.limit>0">
                                                <p class="mb-0">Hotspot
                                                    ({{ (usageInfoData?.liveUsage?.tetherData?.full?.used / 1024) |
                                                    number:'1.2-2' }} GB /
                                                    {{ (usageInfoData?.liveUsage?.tetherData?.full?.limit / 1024) |
                                                    number:'1.2-2' }} GB)
                                                </p>
                                                <div class="progress mb-3">
                                                    <div class="progress-bar" role="progressbar" [style.width]="(usageInfoData?.liveUsage?.tetherData?.full?.used/usageInfoData?.liveUsage?.tetherData?.full?.limit
                                                        )*100 + '%'">
                                                        {{(
                                                        usageInfoData?.liveUsage?.tetherData?.full?.used/usageInfoData?.liveUsage?.tetherData?.full?.limit
                                                        )*100 | number : '1.2-2'}} %
                                                    </div>
                                                </div>
                                            </ng-container>

                                        </ng-container> -->
<!-- 
                                        <ng-container *ngIf="cardLoader">
                                            <app-loader [isChildLoader]="true"></app-loader>
                                        </ng-container> -->
                                        <!-- <div class="whatsnew-btn"><a class="btn btn-outline-white">Whats New !</a></div> -->
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="text-center mt-3">
                                <button class="btn btn-primary" (click)="toggleMoreInf()">More info</button>
                            </div> -->
                        </div>
                    </div>
                    <div *ngIf="isSkeletonLoader" @fadeInOut  style="max-width: 100%;">
                        <app-skeleton-loader [alignment]="'row'" [cardCount]="1" shape="medium-card"></app-skeleton-loader>
                    </div>
                    <div *ngIf="isusageRefreshed" @fadeInOut style="max-width: 100%;">
                        <div class="card m-2">
                            <app-skeleton-loader [animatedRowCount]="10" shape="animated-rows"></app-skeleton-loader>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="card track-card " *ngIf="!isSkeletonLoader" @fadeInOut>
                        <div class="card-body py-3">
                            <div class="deposit-wrap">
                                <div class="w-100">
                                    <div class="header-top ">
                                        <h5 class="f-w-500 card-title">Application Information</h5>
                                        <app-feather-icons [icon]="'maximize'"
                                            (click)="toggleMoreInf();moreInfoSelectedTab('Customer Information')"
                                            class="ms-2 text-primary cursor-pointer track-expand"></app-feather-icons>
                                    </div>
                                    <div *ngIf="customerProfileService?.minimalProfileData?.accountInfo?.enrollId?.visible=='Y'"
                                        class="row">
                                        <div class="col-6">
                                            <p class="f-w-500"> Enroll ID </p>
                                        </div>
                                        <div class="col-6 font-success">
                                            <p class="f-w-500">
                                                {{customerProfileService?.minimalProfileData?.accountInfo?.enrollId?.value}}
                                            </p>
                                        </div>
                                    </div>
                                    <div *ngIf="customerProfileService?.minimalProfileData?.accountInfo?.orderType?.visible=='Y'"
                                        class="row">
                                        <div class="col-6 ">
                                            <p class="f-w-500"> Order Type </p>
                                        </div>
                                        <div class="col-6 font-success">
                                            <p class="f-w-500">
                                                {{customerProfileService?.minimalProfileData?.accountInfo?.orderType?.value}}
                                            </p>
                                        </div>
                                    </div>

                                    <div *ngIf="customerProfileService?.minimalProfileData?.accountInfo?.approvalDate?.visible=='Y'"
                                        class="row">
                                        <div class="col-6">
                                            <p class="f-w-500"> Approval Date </p>
                                        </div>
                                        <div class="col-6 font-success">
                                            <p class="f-w-500">
                                                {{commonService.formatDate(customerProfileService?.minimalProfileData?.accountInfo?.approvalDate?.value)
                                                }}</p>
                                        </div>
                                    </div>
                                    <div *ngIf="customerProfileService?.minimalProfileData?.accountInfo?.disconnectionDate?.visible=='Y'"
                                        class="row">
                                        <div class="col-6">
                                            <p class="f-w-500"> Disconnection Date </p>
                                        </div>
                                        <div class="col-6 font-success">

                                            <p class="f-w-500">
                                                {{
                                                customerProfileService.minimalProfileData?.accountInfo?.disconnectionDate?.value
                                                ?
                                                (commonService.formatDate(customerProfileService.minimalProfileData?.accountInfo?.disconnectionDate?.value)
                                                ) :
                                                commonService.formatDate(customerProfileService.minimalProfileData?.accountInfo?.disconnectionDate?.value)
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                    <div *ngIf="customerProfileService?.minimalProfileData?.accountInfo?.approvedBy?.visible=='Y'"
                                        class="row">
                                        <div class="col-6">
                                            <p class="f-w-500"> Approved by </p>
                                        </div>
                                        <div class="col-6 font-success">
                                            <p class="f-w-500">
                                                {{customerProfileService?.minimalProfileData?.accountInfo?.approvedBy?.value}}
                                            </p>
                                        </div>
                                    </div>

                                    <div *ngIf="customerProfileService.minimalProfileData?.accountInfo?.acpStartDate?.visible=='Y'"
                                        class="row">
                                        <div class="col-6">
                                            <p class="f-w-500"> ACP Start Date</p>
                                        </div>
                                        <div class="col-6 font-success">
                                            <p class="f-w-500">
                                                {{commonService.formatDate(customerProfileService.minimalProfileData?.accountInfo?.acpStartDate?.value)
                                                }}</p>
                                        </div>
                                    </div>


                                    <div *ngIf="customerProfileService?.minimalProfileData?.accountInfo?.shipmentStatus?.visible=='Y'"
                                        class="row">
                                        <div class="col-6">
                                            <p class="f-w-500"> Shipment Status</p>
                                        </div>
                                        <div class="col-6 font-success">
                                            <p class="f-w-500">
                                                {{customerProfileService?.minimalProfileData?.accountInfo?.shipmentStatus?.value}}
                                            </p>
                                        </div>
                                    </div>
                                    <div *ngIf="customerProfileService?.minimalProfileData?.accountInfo?.activationCode?.visible=='Y'"
                                        class="row">
                                        <div class="col-6">
                                            <p class="f-w-500"> Activation Code</p>
                                        </div>
                                        <div class="col-6 font-success">
                                            <p class="f-w-500">
                                                {{customerProfileService?.minimalProfileData?.accountInfo?.activationCode?.value
                                                || '-'}}
                                            </p>
                                        </div>
                                    </div>

                                    <div *ngIf="customerProfileService?.minimalProfileData?.accountInfo?.shiprackingNo?.visible=='Y'"
                                        class="row">
                                        <div class="col-6">
                                            <p class="f-w-500"> Tracking Number</p>
                                        </div>
                                        <div class="col-6 font-success">
                                            <p class="f-w-500">
                                                {{customerProfileService?.minimalProfileData?.accountInfo?.shiprackingNo?.value}}
                                            </p>
                                        </div>
                                    </div>
                                    <div *ngIf="customerProfileService?.minimalProfileData?.accountInfo?.programs?.visible=='Y'"
                                        class="row">
                                        <div class="col-12 d-flex flex-row flex-wrap"
                                            *ngIf="customerProfileService?.minimalProfileData?.accountInfo?.programs?.value.length>0">
                                            <div class="col-6">
                                                <p class="f-w-500"> Program
                                                </p>
                                            </div>
                                            <div class="col-6 font-success">
                                                <p class="f-w-500 col-12"
                                                    *ngFor="let program of customerProfileService?.minimalProfileData?.accountInfo?.programs?.value">
                                                    {{program?.name}}</p>


                                            </div>
                                        </div>

                                    </div>
                                    <div *ngIf="customerProfileService?.minimalProfileData?.accountInfo?.source?.visible=='Y'"
                                        class="row">
                                        <div class="col-6">
                                            <p class="f-w-500"> Source</p>
                                        </div>
                                        <div class="col-6 font-success font-bold">
                                            <p class=" d-flex f-w-500">
                                                {{customerProfileService?.minimalProfileData?.accountInfo?.source?.value}}
                                            </p>
                                        </div>
                                    </div>
                                    <div *ngIf="customerProfileService.minimalProfileData?.orderItems[0]?.product_combo_item?.devicemakemodel?.Make"
                                        class="row">
                                        <div class="col-6">
                                            <p class="f-w-500"> Device model</p>
                                        </div>
                                        <div class="col-6 font-success font-bold">
                                            <p class=" d-flex f-w-500">
                                                {{customerProfileService.minimalProfileData?.orderItems[0]?.product_combo_item?.devicemakemodel?.Make}}
                                            </p>
                                        </div>
                                    </div>
                                    <!-- <p>Lorem Ipsum is simply dummy text</p>
                                    <button class="btn btn-white f-w-500">Deposit Now</button> -->
                                </div>
                            </div>
                            <!-- <div class="text-center mt-3">
                                <button class="btn btn-primary" (click)="toggleMoreInf()">More info</button>
                            </div> -->
                        </div>
                    </div>
                    <div *ngIf="isSkeletonLoader" @fadeInOut  style="max-width: 100%;">
                        <app-skeleton-loader [alignment]="'row'" [cardCount]="1" shape="medium-card"></app-skeleton-loader>
                    </div>
                </div>
                <div class="col-12">
                    <div class="card height-equal track-card" *ngIf="!isSkeletonLoader" @fadeInOut>
                        <div class="card-header py-2 card-no-border">
                            <div class="header-top">
                                <h5 class="m-0 card-title">Upcoming Plans</h5>
                                <app-feather-icons [icon]="'maximize'"
                                    (click)="toggleMoreInf();moreInfoSelectedTab('Upcoming Plans')"
                                    class="ms-2 text-primary cursor-pointer  track-expand"></app-feather-icons>

                            </div>
                        </div>
                        <div class="card-body pt-0 " *ngIf="isShowScheduleData">
                            <div class="deposit-wrap">
                                <div class="w-100">
                                    <div class="row" *ngIf="!isNonPrepaid">
                                        <div class="col-6">
                                            <p class="f-w-500">Customer Id</p>
                                        </div>
                                        <div class="col-6 font-success">
                                            <p class="f-w-500">
                                                {{
                                                customerProfileService.minimalProfileData?.schedulededPlan?.CustomerID
                                                || '-' }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <p class="f-w-500">Current Plan</p>
                                        </div>
                                        <div class="col-6 font-success">
                                            <p class="f-w-500">
                                                {{ currentPlanName || '-' }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <p class="f-w-500">Upcoming Plans</p>
                                        </div>
                                        <div class="col-6 font-success">
                                            <p class="f-w-500">
                                                {{ schedulePlanName || '-' }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <p class="f-w-500">Schedule Date</p>
                                        </div>
                                        <div class="col-6 font-success">
                                            <p class="f-w-500">
                                                {{
                                                commonService.formatDate(customerProfileService.minimalProfileData?.schedulededPlan.ScheduleDate)
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <p class="f-w-500">Is Immediate</p>
                                        </div>
                                        <div class="col-6 font-success">
                                            <p class="f-w-500">
                                                {{
                                                customerProfileService.minimalProfileData?.schedulededPlan.IsImmediate ?
                                                'Yes' : 'No' }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <p class="f-w-500">Is Subscribed</p>
                                        </div>
                                        <div class="col-6 font-success">
                                            <p class="f-w-500">
                                                {{
                                                customerProfileService.minimalProfileData?.schedulededPlan.IsSubscribed
                                                ? 'Yes' : 'No' }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <p class="f-w-500">Status ID</p>
                                        </div>
                                        <div class="col-6 font-success">
                                            <p class="f-w-500">
                                                {{ customerProfileService.minimalProfileData?.schedulededPlan.StatusId
                                                || '-' }}
                                            </p>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-6">
                                            <p class="f-w-500">Status</p>
                                        </div>
                                        <div class="col-6 font-success">
                                            <p class="f-w-500">
                                                {{ customerProfileService.minimalProfileData?.schedulededPlan.Status ||
                                                '-' }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="!isNonPrepaid">
                                        <div class="col-6">
                                            <p class="f-w-500">Created Date</p>
                                        </div>
                                        <div class="col-6 font-success">
                                            <p class="f-w-500">
                                                {{
                                                commonService.formatDate(customerProfileService.minimalProfileData?.schedulededPlan?.CreatedDate
                                                ) }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="!isNonPrepaid">
                                        <div class="col-6">
                                            <p class="f-w-500">Created By</p>
                                        </div>
                                        <div class="col-6 font-success">
                                            <p class="f-w-500">
                                                {{ customerProfileService.minimalProfileData?.schedulededPlan?.CreatedBy
                                                || '-' }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="!isNonPrepaid">
                                        <div class="col-6">
                                            <p class="f-w-500">Cancelled By</p>
                                        </div>
                                        <div class="col-6 font-success">
                                            <p class="f-w-500">
                                                {{
                                                customerProfileService.minimalProfileData?.schedulededPlan?.CancelledBy
                                                || '-' }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="!isNonPrepaid">
                                        <div class="col-6">
                                            <p class="f-w-500">Is Subscription Sync</p>
                                        </div>
                                        <div class="col-6 font-success">
                                            <p class="f-w-500">
                                                {{
                                                customerProfileService.minimalProfileData?.schedulededPlan?.IsSubscriptionSynced
                                                ? 'Yes':'No' }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="!isNonPrepaid">
                                        <div class="col-6">
                                            <p class="f-w-500">Is Email Scheduled</p>
                                        </div>
                                        <div class="col-6 font-success">
                                            <p class="f-w-500">
                                                {{
                                                customerProfileService.minimalProfileData?.schedulededPlan?.IsScheduledEmail
                                                ? 'Yes':'No' }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="!isNonPrepaid">
                                        <div class="col-6">
                                            <p class="f-w-500">Cancel date</p>
                                        </div>
                                        <div class="col-6 font-success">
                                            <p class="f-w-500">
                                                {{commonService.formatDate(
                                                customerProfileService.minimalProfileData?.schedulededPlan?.CancelDate)
                                                }}
                                            </p>
                                        </div>
                                    </div>




                                </div>
                            </div>
                            <!-- <div class="text-center mt-3">
                                <button class="btn btn-primary" (click)="toggleMoreInf()">More info</button>
                            </div> -->
                        </div>
                        <div class="card-body d-flex justify-content-center pt-0" *ngIf="!isShowScheduleData">
                            <span class="text-danger f-w-500">No Plan Found</span>
                        </div>
                    </div>
                    <div *ngIf="isSkeletonLoader" @fadeInOut style="max-width: 100%;">
                        <app-skeleton-loader [alignment]="'row'" [cardCount]="1" shape="medium-card"></app-skeleton-loader>
                    </div>
                </div>
                <ng-container *ngIf="isNonPrepaid">
                <div class="col-12">
                    <div class="appointment">
                        <div class="card track-card" *ngIf="!isSkeletonLoader"@fadeInOut >
                            <div class="card-header py-2 card-no-border">
                                <div class="header-top">
                                    <h5 class="m-0 card-title">Eligibility Information</h5>
                                    <app-feather-icons [icon]="'maximize'"
                                        (click)="toggleMoreInf();moreInfoSelectedTab('Eligibility Information')"
                                        class="ms-2 text-primary cursor-pointer track-expand"></app-feather-icons>
                                </div>
                                <div class="card-body pt-0">
                                    <div class="appointment-table2 table-responsive">
                                        <table class="table table-bordernone">
                                            <tbody>
                                                <tr
                                                    *ngIf="customerProfileService.minimalProfileData?.eligibilyInfo?.acpQualifyDate?.visible=='Y'">
                                                    <td class="img-content-box">
                                                        <a class="d-block f-w-500">ACP Qualify Date</a>
                                                    </td>
                                                    <td class="text-end">
                                                        <p class="m-0 font-success">
                                                            {{commonService.formatDate(customerProfileService.minimalProfileData?.eligibilyInfo?.acpQualifyDate?.value)
                                                            }}
                                                        </p>
                                                    </td>
                                                </tr>

                                                <tr
                                                    *ngIf="customerProfileService.minimalProfileData?.eligibilyInfo?.nvApplicationId?.visible=='Y'">
                                                    <td class="img-content-box">
                                                        <a class="d-block f-w-500">NV Application ID</a>
                                                    </td>
                                                    <td class="text-end">
                                                        <p class="m-0 font-success">
                                                            {{customerProfileService.minimalProfileData?.eligibilyInfo?.nvApplicationId?.value}}
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr
                                                    *ngIf="customerProfileService.minimalProfileData?.eligibilyInfo?.subscriberId?.visible=='Y'">
                                                    <td class="img-content-box">
                                                        <a class="d-block f-w-500">NLAD Subscriber ID</a>
                                                    </td>
                                                    <td class="text-end">
                                                        <p class="m-0 font-success">
                                                            {{customerProfileService.minimalProfileData?.eligibilyInfo?.subscriberId?.value}}
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="img-content-box">
                                                        <a class="d-block f-w-500">Device Qualification
                                                        </a>
                                                    </td>
                                                    <td class="text-end">
                                                        <p class="m-0 font-success">

                                                            {{customerProfileService.minimalProfileData?.eligibilyInfo?.deviceQualification?.value}}
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="img-content-box">
                                                        <a class="d-block f-w-500">ACP Device Order Type
                                                        </a>
                                                    </td>
                                                    <td class="text-end">
                                                        <p class="m-0 font-success">
                                                            {{customerProfileService.minimalProfileData?.eligibilyInfo?.acpDeviceOrderType?.value}}
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div *ngIf="isSkeletonLoader" @fadeInOut style="max-width: 100%;">
                            <app-skeleton-loader [alignment]="'row'" [cardCount]="1" shape="medium-card"></app-skeleton-loader>
                        </div>
                    </div>

                    </div>
                </ng-container>
            </div>
        </div>



    </div>

    <ng-container *ngIf="isAutoRenewModelOpen">
        <app-plan-change-payment #childComponent [isAutoRenewModelOpen]="isAutoRenewModelOpen"
            (isAutoRenewModelOpenChange)="onIsAutoRenewModelOpenChange($event)"
            (submitCVVautoRenew)="getsubmitCVVautoRenew($event)"
            (submitCreditCardAutoRenew)="getsubmitCreditCardAutoRenew($event)"
            (isModalClosedByX)="handleModalClosedByX($event)">
        </app-plan-change-payment>
    </ng-container>

    <div class="customer-profile">
    </div>
    <ng-container *ngIf="isCustomerProfileViewEnabled">
        <app-customer-profile-view
                    (isCustomerProfileViewEnabled)="isCustomerProfileViewAllowed($event)"></app-customer-profile-view>
    </ng-container>