import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PlanChangeService } from 'src/app/customer-dashboard/quick-links/plan-change/services/plan-change.service';
import { HttpService } from 'src/app/shared/services/httpService.service';
import { CommonService } from '../../shared/services/common.service';

@Injectable({
  providedIn: 'root',
})
export class AdhocBreakupService {
  adhocType = ['Equipment', 'Plan Change', 'Plan Renewal', 'Custom'];
  private breakupDetailsSubject = new BehaviorSubject<any>(null); // Observable for sharing data
  getAdhocBreakupDetails$ = this.breakupDetailsSubject.asObservable();
  private calculatedDetails: any = {}; // Store the calculated details globally

  constructor(
    private httpService: HttpService,
    private planChangeService: PlanChangeService, private commonService:CommonService
  ) {}

  /**
   * Validates if the adhocType exists in the predefined array.
   * @param adhocType - The adhocType to validate.
   * @returns true if valid, false otherwise.
   */
  private isValidAdhocType(adhocType: any): boolean {
    return this.adhocType.includes(adhocType);
  }

  /**
   * Handles calculations for different adhoc types.
   * @param adhocBreakupDetails - Object containing adhocType and adhocTypeDetails.
   */
  private calculateBreakup(adhocBreakupDetails: {
    adhocType: any;
    adhocTypeDetails: any;
  }): any {
    const { adhocType, adhocTypeDetails } = adhocBreakupDetails;

    if (!this.isValidAdhocType(adhocType)) {
      throw new Error(`Invalid adhocType: ${adhocType}`);
    }

    switch (adhocType) {
      case 'Equipment':
        return this.calculateEquipmentBreakup(adhocTypeDetails);
      case 'Plan Change':
        return this.calculatePlanChangeBreakup(adhocTypeDetails);
      case 'Plan Renewal':
        return this.calculatePlanRenewalBreakup(adhocTypeDetails);
      case 'Custom':
        return this.calculateCustomBreakup(adhocTypeDetails);
      default:
        throw new Error(`Unhandled adhocType: ${adhocType}`);
    }
  }

  /**
   * Calculates breakup for 'Equipment' type.
   */
  private calculateEquipmentBreakup(adhocTypeDetails: any): any {
    return {
      totalAmount: adhocTypeDetails?.amount || 0,
      equipmentFee: adhocTypeDetails?.fee || 0,
      finalAmount:
        (adhocTypeDetails?.amount || 0) + (adhocTypeDetails?.fee || 0),
      description: 'Equipment Fee Included',
    };
  }

  /**
   * Calculates breakup for 'Plan Change' type.
   */
  private calculatePlanChangeBreakup(adhocTypeDetails: any): any {
    return {
      totalAmount: adhocTypeDetails?.amount || 0,
      changeFee: adhocTypeDetails?.fee || 0,
      finalAmount:
        (adhocTypeDetails?.amount || 0) + (adhocTypeDetails?.fee || 0),
      description: 'Plan Change Fee Included',
    };
  }

  /**
   * Calculates breakup for 'Plan Renewal' type.
   */
  private calculatePlanRenewalBreakup(adhocTypeDetails: any): any {
    return {
      totalAmount: adhocTypeDetails?.amount || 0,
      renewalDiscount: adhocTypeDetails?.discount || 0,
      finalAmount:
        (adhocTypeDetails?.amount || 0) - (adhocTypeDetails?.discount || 0),
      description: 'Plan Renewal with Discount',
    };
  }

  /**
   * Calculates breakup for 'Custom' type.
   */
  private calculateCustomBreakup(adhocTypeDetails: any): any {
    return {
      totalAmount: adhocTypeDetails?.amount || 0,
      customCharge: adhocTypeDetails?.charge || 0,
      finalAmount:
        (adhocTypeDetails?.amount || 0) + (adhocTypeDetails?.charge || 0),
      description: 'Custom Charges Applied',
    };
  }

  /**
   * Calculates tax breakup for 'Plan Change'.
   */
  private calculateTaxBreakup(breakupDetails: any): any {
    const taxRate = 0.18; // 18% tax rate
    const taxAmount = (breakupDetails.totalAmount || 0) * taxRate;
    return {
      taxRate: taxRate,
      taxAmount: taxAmount,
      totalWithTax: (breakupDetails.totalAmount || 0) + taxAmount,
    };
  }

  /**
   * Sets breakup details and fetches additional information for 'Plan Change'.
   */
  async setAdhocDetailsForBreakupInputs(
    adhocBreakupDetails: { adhocType: any; adhocTypeDetails: any },
    isAdvancePlan?: boolean,
    customerId?: any
  ): Promise<void> {
    try {
      console.log('adhocBreakupDetails: ', adhocBreakupDetails);
      // Validate the adhocType
      if (!this.isValidAdhocType(adhocBreakupDetails.adhocType)) {
        throw new Error(`Invalid adhocType: ${adhocBreakupDetails.adhocType}`);
      }
  
      // Calculate base breakup details
      this.calculatedDetails = this.calculateBreakup(adhocBreakupDetails);
  
      // Add tax breakup if 'Plan Change'
      if (adhocBreakupDetails.adhocType === 'Plan Change' || adhocBreakupDetails.adhocType === 'Plan Renewal') {
        this.calculatedDetails = {
          ...this.calculatedDetails,
          isAdvancePlan: isAdvancePlan || false,
        };
        await this.getPlanDetails(
          adhocBreakupDetails.adhocTypeDetails.PlanId,
          customerId
        );
      } else {
        // Emit the final result immediately for other types
        console.log('Final Adhoc Breakup Details:', this.calculatedDetails);
        this.breakupDetailsSubject.next(this.calculatedDetails);
      }
    } catch (error) {
      console.error('Error calculating breakup details:', error.message);
      this.breakupDetailsSubject.next(null); // Emit null on error
      throw error; // Propagate the error
    }
  }
  

  /**
   * Fetches plan details and updates `calculatedDetails`.
   */
/**
 * Fetches plan details and updates `calculatedDetails`.
 * Calls `getPlanTaxes` only if TaxIncluded is 0.
 */
async getPlanDetails(planId: number, customerId: any): Promise<void> {
  try {
    const params = { planId: planId, CustomerId: customerId };
    const planDetailsResponse: any = await this.planChangeService.getPlansDetails(params);

    if (planDetailsResponse?.Status) {
      this.calculatedDetails = {
        ...this.calculatedDetails,
        planDetails: planDetailsResponse.data,
      };

      const taxIncluded = planDetailsResponse?.data?.plan?.TaxIncluded;

      // If TaxIncluded is 0, fetch plan taxes
      if (taxIncluded === 0) {
        await this.getPlanTaxes(planId, customerId);
      } else {
        console.log("Tax is already included. Skipping getPlanTaxes.");
      }

      console.log("Final Adhoc Breakup Details with Plan Details:", this.calculatedDetails);
      this.breakupDetailsSubject.next(this.calculatedDetails); // Emit the updated details
    } else {
      throw new Error('Plan details not found');
    }
  } catch (error) {
    console.error("Error fetching plan details:", error.message);
    throw error; // Propagate the error
  }
}



  /**
   * Fetches plan taxes and updates `calculatedDetails`.
   */
  async getPlanTaxes(planId: number, customerId: any): Promise<void> {
    try {
      const params = { planId: planId, CustomerId: customerId };
      const planTaxResponse: any = await this.planChangeService.getPlanTax(params);
  
      if (planTaxResponse?.Status) {
        this.calculatedDetails = {
          ...this.calculatedDetails,
          planTaxes: planTaxResponse.data,
        };
  
        console.log('Final Adhoc Breakup Details with Plan Details and Taxes:', this.calculatedDetails);
        this.breakupDetailsSubject.next(this.calculatedDetails); // Emit the updated details
      } else {
        throw new Error('Plan taxes not found');
      }
    } catch (error) {
      console.error("Error fetching plan taxes:", error.message);
      throw error; // Propagate the error
    }
  }
  
}
