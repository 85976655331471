import { Component, EventEmitter, Output, Renderer2 } from '@angular/core';
import { ManagePermissionService } from '../../services/manage-permission.service';
import { ManageAgentService } from '../../services/manage-agent.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocalStorage } from 'ngx-webstorage';
import { CommonService } from 'src/app/shared/services/common.service';
import { fadeInOut } from 'src/app/shared/services/app.animation';
import { Router } from '@angular/router';

@Component({
  selector: 'app-manage-resources',
  templateUrl: './manage-resources.component.html',
  styleUrls: ['./manage-resources.component.scss'],
  animations:[fadeInOut]
})
export class ManageResourcesComponent {
  moreInfo: boolean = false;
  popup1Width;
  popup2Width;
  public check=true;
  selectedResource: any;
  selectedCategory: any;
  categoryList:any
  resourcesTypeList:any
  resourcesList:any
  resourcesListDrop:any
  resourceName: string;
  showExtraInputs:boolean = false
  navIconClass: string;
  resourceDescription: string;
  urlRoute: string;
  @LocalStorage()
  private userDetails;
  columnDefs:any[]=[]
  showresource_types:boolean = false
  form:FormGroup;
  itemsPerPage: number = 200;
  totalItems: number;
  currentPage: number = 1;
  showupdate:boolean = false;
  showSearch:boolean = false;
  totalPages: number = 1;
  updateID:any;
  private badgeColorMap: { [key: string]: string } = {};
  private colorPalette: string[] = ['#FF5733', '#33B5FF', '#33FF57', '#FF33A6', '#FFA533',
    '#8E44AD', // Purple
  '#2ECC71', // Bright Green
  '#E74C3C', // Red
  '#3498DB', // Light Blue
  '#F39C12', // Yellow-Orange
  '#D35400', // Dark Orange
  '#1ABC9C', // Teal
  '#9B59B6', // Violet
  '#F1C40F', // Yellow
  '#E67E22'  // Caramel
  ];
  private colorIndex: number = 0;
  @Output() close = new EventEmitter<any>();
isSkeletonLoader: boolean=true;
  constructor(private manageAgentService:ManageAgentService,private fb: FormBuilder,private commonService:CommonService,private renderer: Renderer2,  private router: Router,){

  }

categories = [
  { id: 1, DisplayName: 'Category 1' },
  { id: 2, DisplayName: 'Category 2' },
  // Add more categories as needed
];


ngOnInit(){
  this.form = this.fb.group({
    resourceSelect: [null, Validators.required],
    resourceDescription: ['', Validators.required],
    categorySelect: [null, Validators.required],
    status: [1, Validators.required],
    parentId:[],
    resourceName: [''],
    navIconClass: [''],
    urlRoute: ['']
  });
  this.getAllAgentList()
  this.getResourcesList('')
  this.getResourcesListType()
  this.getResourcesListNOpage('')
  this.columnDefs = [
    { headerName: 'Sr.No', field: '', },
    { headerName: 'Resource Name', field: 'DisplayName', },
    { headerName: 'Description', field: 'Description', cellRenderer: this.defaultRenderer },
    { headerName: 'Resources Type    ', field: 'resource_type.DisplayName', cellRenderer: this.badgeRenderer.bind(this) },
    { headerName: 'Action', cellRenderer: this.actionRenderer.bind(this), }
  ];
}
defaultColDef = {
  resizable: true,
  sortable: true,
  filter: true
};


onCellClicked(event: any) {
  const target = event.event.target.closest('.icon');
  if (target) {
    const action = target.getAttribute('data-action');
    const id = target.getAttribute('data-id');

    if (action === 'edit') {
      this.ViewOneRes(id);
    } else if (action === 'delete') {
      this.deleteResouce(id);
    }
  }
}
showSearch1(){
  this.showSearch = !this.showSearch
}
// Custom renderers
boldRenderer(params: any) {
  const iconClass = params.data?.navIconClass ? params.data.navIconClass : 'feather icon-alert-circle'; // Default icon if navIconClass is not provided
  const displayName = params.value ? `<strong>${params.value}</strong>` : '-';
  
  return `
    <span class="icon-with-text">
      <i class="${iconClass} me-2"></i>
      
      </span>
    `;
  
}

defaultRenderer(params: any) {
  return params.value ? params.value : '-';
}





 getBadgeColor(resourceTypeName: string): string {
  if (!this.badgeColorMap[resourceTypeName]) {
    // Assign a unique color if it's not already mapped
    if (this.colorIndex >= this.colorPalette.length) {
      // Reset the index if we run out of colors, or handle this case as needed
      this.colorIndex = 0;
    }
    this.badgeColorMap[resourceTypeName] = this.colorPalette[this.colorIndex];
    this.colorIndex++;
  }
  return this.badgeColorMap[resourceTypeName];
}

badgeRenderer(params: any): string {
  const resourceTypeName = params.data?.resource_type?.DisplayName || '-';
  
  const badgeColor = this.getBadgeColor(resourceTypeName);

  return `<span class="badge" style="background-color: ${badgeColor}; color: white;">${resourceTypeName}</span>`;
}


actionRenderer(params: any) {
  const container = document.createElement('div');
  container.innerHTML = `
    <span class="icon me-2 cursor-pointer" data-action="edit" data-id="${params.data.id}">
      <i class="fas fa-edit text-success"></i>
    </span>
    <span class="icon cursor-pointer" data-action="delete" data-id="${params.data.id}">
      <i class="fas fa-trash text-danger"></i>
    </span>
  `;

  // Attach click event listener to the edit icon
  const editIcon = container.querySelector('[data-action="edit"]');
  if (editIcon) {
    editIcon.addEventListener('click', () => {
      // Scroll the .main container to the top
      const mainContainer = document.querySelector('.main');
      if (mainContainer) {
        mainContainer.scrollTo({ top: 0, behavior: 'smooth' });
      }
      console.log('Edit icon clicked for ID:', params.data.id);
    });
  }

  return container;
}

editItem(id: number) {
  const mainContainer = document.querySelector('.main');
  if (mainContainer) {
    mainContainer.scrollTo({ top: 0, behavior: 'smooth' });
  }
  console.log('Edit button clicked for ID:', id);
  // Add further logic for editing the item with the given ID
}

  closeModal(): void {
    this.close.emit(); // Emit close event
  }
 openResourcesModal() {
    this.showresource_types = true;

  }
  

closeModal1() {
  this.showresource_types = false; // Hides the modal
}
  onSelectResource() {
    const selectedValue = this.form.get('resourceSelect').value;
    console.log('Selected Resource:', selectedValue);
    // Handle resource selection
}

onClearResource() {
    this.selectedResource = null;
}
onClearCategory() {
  this.selectedCategory = null;
}
onSelectCategory(event:any) {
  if (event == -1) {
    this.openResourcesModal();
    this.form.get('categorySelect').setValue(null); 
  } else {

  }
 
}
onAddNewResource(name: string) {
  const formattedName = this.formatInput(name);
  this.resourcesListDrop.push({ DisplayName: formattedName });
  this.form.get('resourceSelect').setValue(formattedName);
}
formatInput(name: string): string {
  return name.toUpperCase().replace(/\s+/g, '_');
}
// Add new category
onAddNewCategory(name: string) {
  const newId = this.categories.length + 1;
  this.categories.push({ id: newId, DisplayName: name });
  this.selectedCategory = newId;
}

editResource(resource: any) {
  console.log('Edit resource:', resource);
  // Implement logic to edit the resource
}

// Delete resource method
// deleteResource(resourceId: string) {
//   console.log('Delete resource with ID:', resourceId);
//   // Implement logic to delete the resource
//   // this.resources = this.resources.filter(r => r.id !== resourceId);
// }
getAllAgentList(){
  this.manageAgentService.getAllCategory().subscribe((res:any)=>{
   this.categoryList=res?.data;
  
  })
  
}
filterdData(event:any){
  let data=event.target.value
  this.getResourcesList(data);
  // console.log('getUser data: ',this.getUserList(data))
}
getResourcesList(search){
  let obj: any = {
    "per_page": this.itemsPerPage,
    "page": this.currentPage,
    "searchTerm":search
  }
  this.manageAgentService.getAllResources(obj).subscribe((res:any)=>{
   this.resourcesList=res?.data?.data;
   this.totalItems = res?.data?.total;
  //  this.isSkeletonLoader=false
  
  })
  setTimeout(()=>{
    this.isSkeletonLoader=false
  },500)

  
}
getResourcesListNOpage(search){
  let obj: any = {
    "searchTerm":search,
    per_page: 200
  }
  this.manageAgentService.getAllResourcesNOpage(obj).subscribe((res:any)=>{
   this.resourcesListDrop=res?.data?.data;
  
   this.totalItems = res?.data?.total;
  
  })
  
}
getResourcesListType(){
  this.manageAgentService.getAllResourcesType().subscribe((res:any)=>{
   this.resourcesTypeList=res?.data;
  
  })
  
}
onStatusChange(status: number): void {
  console.log('Status changed to:', status);
}
getUser() {
  return this.userDetails;
  
}
submitForm() {
  if (this.form.valid) {
    const formData = {
      DisplayName: this.form.value.resourceSelect.label,
      Description: this.form.value.resourceDescription,
      ResourceTypeID: this.form.value.categorySelect,
      Name: this.formatInput(this.form.value.resourceName), 
      NavIconClass: this.form.value.navIconClass,
      UrlRoute: this.form.value.urlRoute,
      "HideMenu":"0",
      "SortOrder":1,
      "IsActive":this.form.value.status,
      "ParentId":this.form.value.parentId,
      "Params":"",
      "CreatedBy":this.getUser().userID
    };
    this.manageAgentService.createNewRes(formData).subscribe((res:any)=>{
      this.commonService.showToast("success", "Success", 'Resource Add Sucessfully');
      setTimeout(() => {
        this.resetForm()
      }, 2000);
    })
  } else {
    this.commonService.showToast("error", "Error", 'Something went wrong');
    this.form.markAllAsTouched(); 
  }

}
toggleExtraInputs() {
  this.showExtraInputs = !this.showExtraInputs;
}
pageChange(event: any) {
  this.currentPage = event;
  this.getResourcesList('');
}
async deleteResouce(id: any) {
console.log("delete is works..")
try {
  let res = await this.manageAgentService.deleteResource(id) as any;
  if (res?.Status === true) {
    const successMessage = res?.data ?? "Resource  deleted successfully!";
    this.commonService.showToast("success", "Success", successMessage);
    this.getResourcesList('');
  } else {
    const errorMessage = res?.error?.Name[0] ?? "Deletion failed.";
    this.commonService.showToast('error', "Error", errorMessage);
  }
} catch (err) {
  const errorMessage = err?.error?.message ?? "Some error occurred";
  this.commonService.showToast('error', "Error", errorMessage);
}
}

ViewOneRes(id:any) {
  const mainContainer = document.querySelector('.main');
  if (mainContainer) {
    mainContainer.scrollTo({ top: 0, behavior: 'smooth' });
  }

  this.updateID = id
  this.commonService.startLoader()
  this.manageAgentService.oneResouces(id).subscribe(
    (res: any) => {
      if (res?.Status) {
        this.form.patchValue({
          resourceSelect: res?.data?.DisplayName ?? null,
          resourceDescription: res?.data?.Description ?? '',
          categorySelect:res?.data?.ResourceTypeID ?? '',
          status: res?.data?.IsActive ?? null,
          resourceName: res?.data?.Name ?? '',
        });
        this.showExtraInputs = true
        this.showupdate = true
        const collapseElement = document.getElementById('collapseExample');
        if (collapseElement) {
          this.renderer.addClass(collapseElement, 'show');
        }
        
        // this.commonService.showToast("success", "Success", "Data loaded successfully!");
      } else {
        this.commonService.showToast('error', "Error", res?.error?.message ?? "Failed to load data.");
      }

  }, err => {

    this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    this.commonService.stopLoader()
  })
}
resetForm() {
  this.form.reset();
}
update() {
  if (this.form.valid) {
    let formData = {
      DisplayName: this.form.value.resourceSelect.label,
      Description: this.form.value.resourceDescription,
      ResourceTypeID: this.form.value.categorySelect,
      Name:  this.formatInput(this.form.value.resourceName),
      NavIconClass: this.form.value.navIconClass,
      UrlRoute: this.form.value.urlRoute,
      // "HideMenu":"",
      // "SortOrder":1,
      "IsActive":this.form.value.status,
      "ParentId":this.form.value.parentId,
      // "Params":"",
      "UpdatedBy":this.getUser().userID
    }
   
    this.manageAgentService.updateResoure(this.updateID,formData).subscribe(res => {
      this.getResourcesList('')
    this.commonService.showToast("success", "Success", "Updated successfully!")

  }, err => {

    this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    this.commonService.stopLoader()
  })
}
}
back(){
  this.router.navigateByUrl(`/user-settings`)
}
}
