export const data =[
    {
        "destination": "Afghanistan - Landline",
        "wholeSaleRate": "$0.1829 ",
        "retailRate": "$0.21 "
    },
    {
        "destination": "Afghanistan - Mobile",
        "wholeSaleRate": "$0.1450 ",
        "retailRate": "$0.17 "
    },
    {
        "destination": "Albania - Landline",
        "wholeSaleRate": "$0.1444 ",
        "retailRate": "$0.17 "
    },
    {
        "destination": "Albania - Mobile",
        "wholeSaleRate": "$0.3589 ",
        "retailRate": "$0.41 "
    },
    {
        "destination": "Algeria - Landline",
        "wholeSaleRate": "$0.0479 ",
        "retailRate": "$0.06 "
    },
    {
        "destination": "Algeria - Mobile",
        "wholeSaleRate": "$0.6844 ",
        "retailRate": "$0.79 "
    },
    {
        "destination": "American Samoa - Landline",
        "wholeSaleRate": "$0.0508 ",
        "retailRate": "$0.06 "
    },
    {
        "destination": "American Samoa - Mobile",
        "wholeSaleRate": "$0.0520 ",
        "retailRate": "$0.06 "
    },
    {
        "destination": "Andorra - Landline",
        "wholeSaleRate": "$0.0206 ",
        "retailRate": "$0.02 "
    },
    {
        "destination": "Andorra - Mobile",
        "wholeSaleRate": "$0.2185 ",
        "retailRate": "$0.25 "
    },
    {
        "destination": "Angola - Landline",
        "wholeSaleRate": "$0.0581 ",
        "retailRate": "$0.07 "
    },
    {
        "destination": "Angola - Mobile",
        "wholeSaleRate": "$0.3025 ",
        "retailRate": "$0.35 "
    },
    {
        "destination": "Anguilla - Landline",
        "wholeSaleRate": "$0.1917 ",
        "retailRate": "$0.22 "
    },
    {
        "destination": "Anguilla - Mobile",
        "wholeSaleRate": "$0.2137 ",
        "retailRate": "$0.25 "
    },
    {
        "destination": "Antigua and Barbuda - Landline",
        "wholeSaleRate": "$0.2095 ",
        "retailRate": "$0.24 "
    },
    {
        "destination": "Antigua and Barbuda - Mobile",
        "wholeSaleRate": "$0.2034 ",
        "retailRate": "$0.23 "
    },
    {
        "destination": "Argentina - Landline",
        "wholeSaleRate": "$0.0040 ",
        "retailRate": "$0.00 "
    },
    {
        "destination": "Argentina - Mobile",
        "wholeSaleRate": "$0.0493 ",
        "retailRate": "$0.06 "
    },
    {
        "destination": "Armenia - Landline",
        "wholeSaleRate": "$0.1959 ",
        "retailRate": "$0.23 "
    },
    {
        "destination": "Armenia - Mobile",
        "wholeSaleRate": "$0.2136 ",
        "retailRate": "$0.25 "
    },
    {
        "destination": "Aruba - Landline",
        "wholeSaleRate": "$0.0830 ",
        "retailRate": "$0.10 "
    },
    {
        "destination": "Aruba - Mobile",
        "wholeSaleRate": "$0.1863 ",
        "retailRate": "$0.21 "
    },
    {
        "destination": "Australia - Landline",
        "wholeSaleRate": "$0.0059 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "Australia - Mobile",
        "wholeSaleRate": "$0.0111 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "Austria - Landline",
        "wholeSaleRate": "$0.0196 ",
        "retailRate": "$0.02 "
    },
    {
        "destination": "Austria - Mobile",
        "wholeSaleRate": "$0.0385 ",
        "retailRate": "$0.04 "
    },
    {
        "destination": "Azerbaijan - Landline",
        "wholeSaleRate": "$0.1767 ",
        "retailRate": "$0.20 "
    },
    {
        "destination": "Azerbaijan - Mobile",
        "wholeSaleRate": "$0.2519 ",
        "retailRate": "$0.29 "
    },
    {
        "destination": "Bahamas - Landline",
        "wholeSaleRate": "$0.2059 ",
        "retailRate": "$0.24 "
    },
    {
        "destination": "Bahamas - Mobile",
        "wholeSaleRate": "$0.2043 ",
        "retailRate": "$0.23 "
    },
    {
        "destination": "Bahrain - Landline",
        "wholeSaleRate": "$0.0954 ",
        "retailRate": "$0.11 "
    },
    {
        "destination": "Bahrain - Mobile",
        "wholeSaleRate": "$0.1146 ",
        "retailRate": "$0.13 "
    },
    {
        "destination": "Bangladesh - Landline",
        "wholeSaleRate": "$0.0112 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "Bangladesh - Mobile",
        "wholeSaleRate": "$0.0111 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "Barbados - Landline",
        "wholeSaleRate": "$0.1724 ",
        "retailRate": "$0.20 "
    },
    {
        "destination": "Barbados - Mobile",
        "wholeSaleRate": "$0.2111 ",
        "retailRate": "$0.24 "
    },
    {
        "destination": "Belarus - Landline",
        "wholeSaleRate": "$0.3455 ",
        "retailRate": "$0.40 "
    },
    {
        "destination": "Belarus - Mobile",
        "wholeSaleRate": "$0.3375 ",
        "retailRate": "$0.39 "
    },
    {
        "destination": "Belgium - Landline",
        "wholeSaleRate": "$0.0244 ",
        "retailRate": "$0.03 "
    },
    {
        "destination": "Belgium - Mobile",
        "wholeSaleRate": "$0.0442 ",
        "retailRate": "$0.05 "
    },
    {
        "destination": "Belize - Landline",
        "wholeSaleRate": "$0.1885 ",
        "retailRate": "$0.22 "
    },
    {
        "destination": "Belize - Mobile",
        "wholeSaleRate": "$0.1927 ",
        "retailRate": "$0.22 "
    },
    {
        "destination": "Benin - Landline",
        "wholeSaleRate": "$0.2535 ",
        "retailRate": "$0.29 "
    },
    {
        "destination": "Benin - Mobile",
        "wholeSaleRate": "$0.2486 ",
        "retailRate": "$0.29 "
    },
    {
        "destination": "Bermuda - Landline",
        "wholeSaleRate": "$0.0195 ",
        "retailRate": "$0.02 "
    },
    {
        "destination": "Bermuda - Mobile",
        "wholeSaleRate": "$0.0187 ",
        "retailRate": "$0.02 "
    },
    {
        "destination": "Bhutan - Landline",
        "wholeSaleRate": "$0.0770 ",
        "retailRate": "$0.09 "
    },
    {
        "destination": "Bhutan - Mobile",
        "wholeSaleRate": "$0.0486 ",
        "retailRate": "$0.06 "
    },
    {
        "destination": "Bolivia - Landline",
        "wholeSaleRate": "$0.1104 ",
        "retailRate": "$0.13 "
    },
    {
        "destination": "Bolivia - Mobile",
        "wholeSaleRate": "$0.1659 ",
        "retailRate": "$0.19 "
    },
    {
        "destination": "Bosnia and Herzegovina - Landline",
        "wholeSaleRate": "$0.1356 ",
        "retailRate": "$0.16 "
    },
    {
        "destination": "Bosnia and Herzegovina - Mobile",
        "wholeSaleRate": "$0.3546 ",
        "retailRate": "$0.41 "
    },
    {
        "destination": "Botswana - Landline",
        "wholeSaleRate": "$0.1671 ",
        "retailRate": "$0.19 "
    },
    {
        "destination": "Botswana - Mobile",
        "wholeSaleRate": "$0.2094 ",
        "retailRate": "$0.24 "
    },
    {
        "destination": "Brazil - Landline",
        "wholeSaleRate": "$0.0017 ",
        "retailRate": "$0.00 "
    },
    {
        "destination": "Brazil - Mobile",
        "wholeSaleRate": "$0.0057 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "British Virgin Islands - Landline",
        "wholeSaleRate": "$0.2092 ",
        "retailRate": "$0.24 "
    },
    {
        "destination": "British Virgin Islands - Mobile",
        "wholeSaleRate": "$0.2019 ",
        "retailRate": "$0.23 "
    },
    {
        "destination": "Brunei - Landline",
        "wholeSaleRate": "$0.0621 ",
        "retailRate": "$0.07 "
    },
    {
        "destination": "Brunei - Mobile",
        "wholeSaleRate": "$0.0718 ",
        "retailRate": "$0.08 "
    },
    {
        "destination": "Bulgaria - Landline",
        "wholeSaleRate": "$0.0133 ",
        "retailRate": "$0.02 "
    },
    {
        "destination": "Bulgaria - Mobile",
        "wholeSaleRate": "$0.0483 ",
        "retailRate": "$0.06 "
    },
    {
        "destination": "Burkina Faso - Landline",
        "wholeSaleRate": "$0.3484 ",
        "retailRate": "$0.40 "
    },
    {
        "destination": "Burkina Faso - Mobile",
        "wholeSaleRate": "$0.3380 ",
        "retailRate": "$0.39 "
    },
    {
        "destination": "Burundi - Landline",
        "wholeSaleRate": "$0.4181 ",
        "retailRate": "$0.48 "
    },
    {
        "destination": "Burundi - Mobile",
        "wholeSaleRate": "$0.4217 ",
        "retailRate": "$0.48 "
    },
    {
        "destination": "Cambodia - Landline",
        "wholeSaleRate": "$0.0540 ",
        "retailRate": "$0.06 "
    },
    {
        "destination": "Cambodia - Mobile",
        "wholeSaleRate": "$0.0463 ",
        "retailRate": "$0.05 "
    },
    {
        "destination": "Cameroon - Landline",
        "wholeSaleRate": "$0.1052 ",
        "retailRate": "$0.12 "
    },
    {
        "destination": "Cameroon - Mobile",
        "wholeSaleRate": "$0.2573 ",
        "retailRate": "$0.30 "
    },
    {
        "destination": "Canada",
        "wholeSaleRate": "$0.0108 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "Cape Verde Islands - Landline",
        "wholeSaleRate": "$0.1270 ",
        "retailRate": "$0.15 "
    },
    {
        "destination": "Cape Verde Islands - Mobile",
        "wholeSaleRate": "$0.2356 ",
        "retailRate": "$0.27 "
    },
    {
        "destination": "Cayman Islands - Landline",
        "wholeSaleRate": "$0.0369 ",
        "retailRate": "$0.04 "
    },
    {
        "destination": "Cayman Islands - Mobile",
        "wholeSaleRate": "$0.1702 ",
        "retailRate": "$0.20 "
    },
    {
        "destination": "Central African Republic - Landline",
        "wholeSaleRate": "$0.5594 ",
        "retailRate": "$0.64 "
    },
    {
        "destination": "Central African Republic - Mobile",
        "wholeSaleRate": "$0.5229 ",
        "retailRate": "$0.60 "
    },
    {
        "destination": "Chad - Landline",
        "wholeSaleRate": "$0.5914 ",
        "retailRate": "$0.68 "
    },
    {
        "destination": "Chad - Mobile",
        "wholeSaleRate": "$0.4621 ",
        "retailRate": "$0.53 "
    },
    {
        "destination": "Chile - Landline",
        "wholeSaleRate": "$0.0117 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "Chile - Mobile",
        "wholeSaleRate": "$0.0047 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "China - Landline",
        "wholeSaleRate": "$0.0218 ",
        "retailRate": "$0.03 "
    },
    {
        "destination": "China - Mobile",
        "wholeSaleRate": "$0.0218 ",
        "retailRate": "$0.03 "
    },
    {
        "destination": "Colombia - Landline",
        "wholeSaleRate": "$0.0048 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "Colombia - Mobile",
        "wholeSaleRate": "$0.0023 ",
        "retailRate": "$0.00 "
    },
    {
        "destination": "Comoros",
        "wholeSaleRate": "$0.3807 ",
        "retailRate": "$0.44 "
    },
    {
        "destination": "Comoros - Mobile",
        "wholeSaleRate": "$0.8374 ",
        "retailRate": "$0.96 "
    },
    {
        "destination": "Congo - Landline",
        "wholeSaleRate": "$0.5582 ",
        "retailRate": "$0.64 "
    },
    {
        "destination": "Congo - Mobile",
        "wholeSaleRate": "$0.4321 ",
        "retailRate": "$0.50 "
    },
    {
        "destination": "Costa Rica - Landline",
        "wholeSaleRate": "$0.0119 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "Costa Rica - Mobile",
        "wholeSaleRate": "$0.0461 ",
        "retailRate": "$0.05 "
    },
    {
        "destination": "Croatia - Landline",
        "wholeSaleRate": "$0.0032 ",
        "retailRate": "$0.00 "
    },
    {
        "destination": "Croatia - Mobile",
        "wholeSaleRate": "$0.0149 ",
        "retailRate": "$0.02 "
    },
    {
        "destination": "Cuba - Landline",
        "wholeSaleRate": "$0.6387 ",
        "retailRate": "$0.73 "
    },
    {
        "destination": "Cuba - Mobile",
        "wholeSaleRate": "$0.6016 ",
        "retailRate": "$0.69 "
    },
    {
        "destination": "Cyprus - Landline",
        "wholeSaleRate": "$0.1143 ",
        "retailRate": "$0.13 "
    },
    {
        "destination": "Cyprus - Mobile",
        "wholeSaleRate": "$0.0995 ",
        "retailRate": "$0.11 "
    },
    {
        "destination": "Czech Republic - Landline",
        "wholeSaleRate": "$0.0129 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "Czech Republic - Mobile",
        "wholeSaleRate": "$0.0129 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "Dem Rep Congo - Landline",
        "wholeSaleRate": "$0.4065 ",
        "retailRate": "$0.47 "
    },
    {
        "destination": "Dem Rep Congo - Mobile",
        "wholeSaleRate": "$0.3808 ",
        "retailRate": "$0.44 "
    },
    {
        "destination": "Denmark - Landline",
        "wholeSaleRate": "$0.0094 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "Denmark - Mobile",
        "wholeSaleRate": "$0.0087 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "Djibouti - Landline",
        "wholeSaleRate": "$0.2883 ",
        "retailRate": "$0.33 "
    },
    {
        "destination": "Djibouti - Mobile",
        "wholeSaleRate": "$0.2710 ",
        "retailRate": "$0.31 "
    },
    {
        "destination": "Dominica - Landline",
        "wholeSaleRate": "$0.2059 ",
        "retailRate": "$0.24 "
    },
    {
        "destination": "Dominica - Mobile",
        "wholeSaleRate": "$0.1834 ",
        "retailRate": "$0.21 "
    },
    {
        "destination": "Dominican Republic - Landline",
        "wholeSaleRate": "$0.0272 ",
        "retailRate": "$0.03 "
    },
    {
        "destination": "Dominican Republic - Mobile",
        "wholeSaleRate": "$0.0863 ",
        "retailRate": "$0.10 "
    },
    {
        "destination": "Ecuador - Landline",
        "wholeSaleRate": "$0.1151 ",
        "retailRate": "$0.13 "
    },
    {
        "destination": "Ecuador - Mobile",
        "wholeSaleRate": "$0.1733 ",
        "retailRate": "$0.20 "
    },
    {
        "destination": "Egypt - Landline",
        "wholeSaleRate": "$0.0738 ",
        "retailRate": "$0.08 "
    },
    {
        "destination": "Egypt - Mobile",
        "wholeSaleRate": "$0.1098 ",
        "retailRate": "$0.13 "
    },
    {
        "destination": "El Salvador - Landline",
        "wholeSaleRate": "$0.0693 ",
        "retailRate": "$0.08 "
    },
    {
        "destination": "El Salvador - Mobile",
        "wholeSaleRate": "$0.0883 ",
        "retailRate": "$0.10 "
    },
    {
        "destination": "Eritrea - Landline",
        "wholeSaleRate": "$0.2006 ",
        "retailRate": "$0.23 "
    },
    {
        "destination": "Eritrea - Mobile",
        "wholeSaleRate": "$0.1948 ",
        "retailRate": "$0.22 "
    },
    {
        "destination": "Estonia - Landline",
        "wholeSaleRate": "$0.0117 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "Estonia - Mobile",
        "wholeSaleRate": "$0.3255 ",
        "retailRate": "$0.37 "
    },
    {
        "destination": "Ethiopia - Landline",
        "wholeSaleRate": "$0.2094 ",
        "retailRate": "$0.24 "
    },
    {
        "destination": "Ethiopia - Mobile",
        "wholeSaleRate": "$0.1905 ",
        "retailRate": "$0.22 "
    },
    {
        "destination": "Fiji Islands - Landline",
        "wholeSaleRate": "$0.2337 ",
        "retailRate": "$0.27 "
    },
    {
        "destination": "Fiji Islands - Mobile",
        "wholeSaleRate": "$0.2250 ",
        "retailRate": "$0.26 "
    },
    {
        "destination": "Finland - Landline",
        "wholeSaleRate": "$0.0067 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "Finland - Mobile",
        "wholeSaleRate": "$0.0090 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "France - Landline",
        "wholeSaleRate": "$0.0057 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "France - Mobile",
        "wholeSaleRate": "$0.0099 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "French Guiana - Landline",
        "wholeSaleRate": "$0.0000 ",
        "retailRate": "$0.00 "
    },
    {
        "destination": "French Guiana - Mobile",
        "wholeSaleRate": "$0.0810 ",
        "retailRate": "$0.09 "
    },
    {
        "destination": "French Polynesia - Landline",
        "wholeSaleRate": "$0.2227 ",
        "retailRate": "$0.26 "
    },
    {
        "destination": "French Polynesia - Mobile",
        "wholeSaleRate": "$0.2502 ",
        "retailRate": "$0.29 "
    },
    {
        "destination": "Gabon Republic - Landline",
        "wholeSaleRate": "$0.3688 ",
        "retailRate": "$0.42 "
    },
    {
        "destination": "Gabon Republic - Mobile",
        "wholeSaleRate": "$0.3726 ",
        "retailRate": "$0.43 "
    },
    {
        "destination": "Gambia - Landline",
        "wholeSaleRate": "$0.3782 ",
        "retailRate": "$0.43 "
    },
    {
        "destination": "Gambia - Mobile",
        "wholeSaleRate": "$0.3842 ",
        "retailRate": "$0.44 "
    },
    {
        "destination": "Georgia - Landline",
        "wholeSaleRate": "$0.1385 ",
        "retailRate": "$0.16 "
    },
    {
        "destination": "Georgia - Mobile",
        "wholeSaleRate": "$0.2593 ",
        "retailRate": "$0.30 "
    },
    {
        "destination": "Germany - Landline",
        "wholeSaleRate": "$0.0017 ",
        "retailRate": "$0.00 "
    },
    {
        "destination": "Germany - Mobile",
        "wholeSaleRate": "$0.0062 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "Ghana - Landline",
        "wholeSaleRate": "$0.1947 ",
        "retailRate": "$0.22 "
    },
    {
        "destination": "Ghana - Mobile",
        "wholeSaleRate": "$0.1785 ",
        "retailRate": "$0.21 "
    },
    {
        "destination": "Gibraltar - Landline",
        "wholeSaleRate": "$0.0348 ",
        "retailRate": "$0.04 "
    },
    {
        "destination": "Gibraltar - Mobile",
        "wholeSaleRate": "$0.1221 ",
        "retailRate": "$0.14 "
    },
    {
        "destination": "Greece - Landline",
        "wholeSaleRate": "$0.0035 ",
        "retailRate": "$0.00 "
    },
    {
        "destination": "Greece - Mobile",
        "wholeSaleRate": "$0.0047 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "Greenland - Landline",
        "wholeSaleRate": "$0.0383 ",
        "retailRate": "$0.04 "
    },
    {
        "destination": "Grenada - Landline",
        "wholeSaleRate": "$0.2135 ",
        "retailRate": "$0.25 "
    },
    {
        "destination": "Grenada - Mobile",
        "wholeSaleRate": "$0.2047 ",
        "retailRate": "$0.24 "
    },
    {
        "destination": "Guadeloupe - Landline",
        "wholeSaleRate": "$0.0089 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "Guadeloupe - Mobile",
        "wholeSaleRate": "$0.0836 ",
        "retailRate": "$0.10 "
    },
    {
        "destination": "Guam",
        "wholeSaleRate": "$0.0148 ",
        "retailRate": "$0.02 "
    },
    {
        "destination": "Guatemala - Landline",
        "wholeSaleRate": "$0.1122 ",
        "retailRate": "$0.13 "
    },
    {
        "destination": "Guatemala - Mobile",
        "wholeSaleRate": "$0.1245 ",
        "retailRate": "$0.14 "
    },
    {
        "destination": "Guinea - Landline",
        "wholeSaleRate": "$0.4093 ",
        "retailRate": "$0.47 "
    },
    {
        "destination": "Guinea - Mobile",
        "wholeSaleRate": "$0.3859 ",
        "retailRate": "$0.44 "
    },
    {
        "destination": "Guinea-Bissau - Landline",
        "wholeSaleRate": "$0.5589 ",
        "retailRate": "$0.64 "
    },
    {
        "destination": "Guyana - Landline",
        "wholeSaleRate": "$0.1996 ",
        "retailRate": "$0.23 "
    },
    {
        "destination": "Guyana - Mobile",
        "wholeSaleRate": "$0.1886 ",
        "retailRate": "$0.22 "
    },
    {
        "destination": "Haiti - Landline",
        "wholeSaleRate": "$0.2487 ",
        "retailRate": "$0.29 "
    },
    {
        "destination": "Haiti - Mobile",
        "wholeSaleRate": "$0.2087 ",
        "retailRate": "$0.24 "
    },
    {
        "destination": "Honduras - Landline",
        "wholeSaleRate": "$0.1057 ",
        "retailRate": "$0.12 "
    },
    {
        "destination": "Honduras - Mobile",
        "wholeSaleRate": "$0.1480 ",
        "retailRate": "$0.17 "
    },
    {
        "destination": "Hong Kong - Landline",
        "wholeSaleRate": "$0.0174 ",
        "retailRate": "$0.02 "
    },
    {
        "destination": "Hong Kong - Mobile",
        "wholeSaleRate": "$0.0237 ",
        "retailRate": "$0.03 "
    },
    {
        "destination": "Hungary - Landline",
        "wholeSaleRate": "$0.0049 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "Hungary - Mobile",
        "wholeSaleRate": "$0.0081 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "Iceland - Landline",
        "wholeSaleRate": "$0.0020 ",
        "retailRate": "$0.00 "
    },
    {
        "destination": "Iceland - Mobile",
        "wholeSaleRate": "$0.0059 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "India - Landline",
        "wholeSaleRate": "$0.0177 ",
        "retailRate": "$0.02 "
    },
    {
        "destination": "India - Mobile",
        "wholeSaleRate": "$0.0179 ",
        "retailRate": "$0.02 "
    },
    {
        "destination": "Indonesia - Landline",
        "wholeSaleRate": "$0.0315 ",
        "retailRate": "$0.04 "
    },
    {
        "destination": "Indonesia - Mobile",
        "wholeSaleRate": "$0.0344 ",
        "retailRate": "$0.04 "
    },
    {
        "destination": "Iran - Landline",
        "wholeSaleRate": "$0.1626 ",
        "retailRate": "$0.19 "
    },
    {
        "destination": "Iran - Mobile",
        "wholeSaleRate": "$0.1851 ",
        "retailRate": "$0.21 "
    },
    {
        "destination": "Iraq - Landline",
        "wholeSaleRate": "$0.1293 ",
        "retailRate": "$0.15 "
    },
    {
        "destination": "Iraq - Mobile",
        "wholeSaleRate": "$0.1833 ",
        "retailRate": "$0.21 "
    },
    {
        "destination": "Ireland - Landline",
        "wholeSaleRate": "$0.0075 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "Ireland - Mobile",
        "wholeSaleRate": "$0.0060 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "Israel - Landline",
        "wholeSaleRate": "$0.0198 ",
        "retailRate": "$0.02 "
    },
    {
        "destination": "Israel - Mobile",
        "wholeSaleRate": "$0.0695 ",
        "retailRate": "$0.08 "
    },
    {
        "destination": "Italy - Landline",
        "wholeSaleRate": "$0.0013 ",
        "retailRate": "$0.00 "
    },
    {
        "destination": "Italy - Mobile",
        "wholeSaleRate": "$0.0065 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "Ivory Coast - Landline",
        "wholeSaleRate": "$0.3175 ",
        "retailRate": "$0.37 "
    },
    {
        "destination": "Ivory Coast - Mobile",
        "wholeSaleRate": "$0.2836 ",
        "retailRate": "$0.33 "
    },
    {
        "destination": "Jamaica - Landline",
        "wholeSaleRate": "$0.2031 ",
        "retailRate": "$0.23 "
    },
    {
        "destination": "Jamaica - Mobile",
        "wholeSaleRate": "$0.2064 ",
        "retailRate": "$0.24 "
    },
    {
        "destination": "Japan - Landline",
        "wholeSaleRate": "$0.0107 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "Japan - Mobile",
        "wholeSaleRate": "$0.0270 ",
        "retailRate": "$0.03 "
    },
    {
        "destination": "Jordan - Landline",
        "wholeSaleRate": "$0.1375 ",
        "retailRate": "$0.16 "
    },
    {
        "destination": "Jordan - Mobile",
        "wholeSaleRate": "$0.1559 ",
        "retailRate": "$0.18 "
    },
    {
        "destination": "Kazakhstan - Landline",
        "wholeSaleRate": "$0.0346 ",
        "retailRate": "$0.04 "
    },
    {
        "destination": "Kazakhstan - Mobile",
        "wholeSaleRate": "$0.1768 ",
        "retailRate": "$0.20 "
    },
    {
        "destination": "Kenya - Landline",
        "wholeSaleRate": "$0.0867 ",
        "retailRate": "$0.10 "
    },
    {
        "destination": "Kenya - Mobile",
        "wholeSaleRate": "$0.0472 ",
        "retailRate": "$0.05 "
    },
    {
        "destination": "Kuwait - Landline",
        "wholeSaleRate": "$0.0361 ",
        "retailRate": "$0.04 "
    },
    {
        "destination": "Kuwait - Mobile",
        "wholeSaleRate": "$0.0318 ",
        "retailRate": "$0.04 "
    },
    {
        "destination": "Kyrgyzstan - Landline",
        "wholeSaleRate": "$0.1211 ",
        "retailRate": "$0.14 "
    },
    {
        "destination": "Kyrgyzstan - Mobile",
        "wholeSaleRate": "$0.2081 ",
        "retailRate": "$0.24 "
    },
    {
        "destination": "Laos - Landline",
        "wholeSaleRate": "$0.0745 ",
        "retailRate": "$0.09 "
    },
    {
        "destination": "Laos - Mobile",
        "wholeSaleRate": "$0.0719 ",
        "retailRate": "$0.08 "
    },
    {
        "destination": "Latvia - Landline",
        "wholeSaleRate": "$0.0416 ",
        "retailRate": "$0.05 "
    },
    {
        "destination": "Latvia - Mobile",
        "wholeSaleRate": "$0.2927 ",
        "retailRate": "$0.34 "
    },
    {
        "destination": "Lebanon - Landline",
        "wholeSaleRate": "$0.1682 ",
        "retailRate": "$0.19 "
    },
    {
        "destination": "Lebanon - Mobile",
        "wholeSaleRate": "$0.1378 ",
        "retailRate": "$0.16 "
    },
    {
        "destination": "Liberia - Landline",
        "wholeSaleRate": "$0.3640 ",
        "retailRate": "$0.42 "
    },
    {
        "destination": "Liberia - Mobile",
        "wholeSaleRate": "$0.3110 ",
        "retailRate": "$0.36 "
    },
    {
        "destination": "Libya - Landline",
        "wholeSaleRate": "$0.2782 ",
        "retailRate": "$0.32 "
    },
    {
        "destination": "Libya - Mobile",
        "wholeSaleRate": "$0.2786 ",
        "retailRate": "$0.32 "
    },
    {
        "destination": "Liechtenstein - Landline",
        "wholeSaleRate": "$0.0854 ",
        "retailRate": "$0.10 "
    },
    {
        "destination": "Liechtenstein - Mobile",
        "wholeSaleRate": "$0.0000 ",
        "retailRate": "$0.00 "
    },
    {
        "destination": "Lithuania - Landline",
        "wholeSaleRate": "$0.0553 ",
        "retailRate": "$0.06 "
    },
    {
        "destination": "Luxembourg - Landline",
        "wholeSaleRate": "$0.1825 ",
        "retailRate": "$0.21 "
    },
    {
        "destination": "Luxembourg - Mobile",
        "wholeSaleRate": "$0.1670 ",
        "retailRate": "$0.19 "
    },
    {
        "destination": "Macau - Landline",
        "wholeSaleRate": "$0.1513 ",
        "retailRate": "$0.17 "
    },
    {
        "destination": "Macau - Mobile",
        "wholeSaleRate": "$0.1528 ",
        "retailRate": "$0.18 "
    },
    {
        "destination": "Macedonia - Landline",
        "wholeSaleRate": "$0.1362 ",
        "retailRate": "$0.16 "
    },
    {
        "destination": "Madagascar - Landline",
        "wholeSaleRate": "$0.6252 ",
        "retailRate": "$0.72 "
    },
    {
        "destination": "Madagascar - Mobile",
        "wholeSaleRate": "$0.5234 ",
        "retailRate": "$0.60 "
    },
    {
        "destination": "Malawi - Landline",
        "wholeSaleRate": "$0.3577 ",
        "retailRate": "$0.41 "
    },
    {
        "destination": "Malawi - Mobile",
        "wholeSaleRate": "$0.3342 ",
        "retailRate": "$0.38 "
    },
    {
        "destination": "Malaysia - Landline",
        "wholeSaleRate": "$0.0034 ",
        "retailRate": "$0.00 "
    },
    {
        "destination": "Malaysia - Mobile",
        "wholeSaleRate": "$0.0034 ",
        "retailRate": "$0.00 "
    },
    {
        "destination": "Maldives - Landline",
        "wholeSaleRate": "$0.8318 ",
        "retailRate": "$0.96 "
    },
    {
        "destination": "Maldives - Mobile",
        "wholeSaleRate": "$0.8318 ",
        "retailRate": "$0.96 "
    },
    {
        "destination": "Mali - Landline",
        "wholeSaleRate": "$0.3769 ",
        "retailRate": "$0.43 "
    },
    {
        "destination": "Mali - Mobile",
        "wholeSaleRate": "$0.3364 ",
        "retailRate": "$0.39 "
    },
    {
        "destination": "Malta - Landline",
        "wholeSaleRate": "$0.0251 ",
        "retailRate": "$0.03 "
    },
    {
        "destination": "Malta - Mobile",
        "wholeSaleRate": "$0.2599 ",
        "retailRate": "$0.30 "
    },
    {
        "destination": "Martinique - Landline",
        "wholeSaleRate": "$0.0073 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "Martinique - Mobile",
        "wholeSaleRate": "$0.1308 ",
        "retailRate": "$0.15 "
    },
    {
        "destination": "Mauritania - Landline",
        "wholeSaleRate": "$0.5402 ",
        "retailRate": "$0.62 "
    },
    {
        "destination": "Mauritania - Mobile",
        "wholeSaleRate": "$0.5368 ",
        "retailRate": "$0.62 "
    },
    {
        "destination": "Mauritius - Landline",
        "wholeSaleRate": "$0.1090 ",
        "retailRate": "$0.13 "
    },
    {
        "destination": "Mauritius - Mobile",
        "wholeSaleRate": "$0.0542 ",
        "retailRate": "$0.06 "
    },
    {
        "destination": "Mexico - Landline",
        "wholeSaleRate": "$0.0117 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "Mexico - Mobile",
        "wholeSaleRate": "$0.0112 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "Micronesia",
        "wholeSaleRate": "$0.4717 ",
        "retailRate": "$0.54 "
    },
    {
        "destination": "Moldova - Landline",
        "wholeSaleRate": "$0.1943 ",
        "retailRate": "$0.22 "
    },
    {
        "destination": "Moldova - Mobile",
        "wholeSaleRate": "$0.2202 ",
        "retailRate": "$0.25 "
    },
    {
        "destination": "Monaco - Kosovo - Mobile",
        "wholeSaleRate": "$0.4717 ",
        "retailRate": "$0.54 "
    },
    {
        "destination": "Monaco - Landline",
        "wholeSaleRate": "$0.0897 ",
        "retailRate": "$0.10 "
    },
    {
        "destination": "Monaco - Mobile",
        "wholeSaleRate": "$0.4409 ",
        "retailRate": "$0.51 "
    },
    {
        "destination": "Mongolia - Landline",
        "wholeSaleRate": "$0.0122 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "Mongolia - Mobile",
        "wholeSaleRate": "$0.0122 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "Montenegro - Landline",
        "wholeSaleRate": "$0.1589 ",
        "retailRate": "$0.18 "
    },
    {
        "destination": "Montenegro - Mobile",
        "wholeSaleRate": "$0.3632 ",
        "retailRate": "$0.42 "
    },
    {
        "destination": "Montserrat - Landline",
        "wholeSaleRate": "$0.2193 ",
        "retailRate": "$0.25 "
    },
    {
        "destination": "Montserrat - Mobile",
        "wholeSaleRate": "$0.2104 ",
        "retailRate": "$0.24 "
    },
    {
        "destination": "Morocco - Landline",
        "wholeSaleRate": "$0.0404 ",
        "retailRate": "$0.05 "
    },
    {
        "destination": "Morocco - Mobile",
        "wholeSaleRate": "$0.5110 ",
        "retailRate": "$0.59 "
    },
    {
        "destination": "Mozambique - Landline",
        "wholeSaleRate": "$0.2317 ",
        "retailRate": "$0.27 "
    },
    {
        "destination": "Mozambique - Mobile",
        "wholeSaleRate": "$0.2718 ",
        "retailRate": "$0.31 "
    },
    {
        "destination": "Myanmar - Landline",
        "wholeSaleRate": "$0.0793 ",
        "retailRate": "$0.09 "
    },
    {
        "destination": "Myanmar - Mobile",
        "wholeSaleRate": "$0.0592 ",
        "retailRate": "$0.07 "
    },
    {
        "destination": "N/A",
        "wholeSaleRate": "$0.0008 ",
        "retailRate": "$0.00 "
    },
    {
        "destination": "Namibia - Landline",
        "wholeSaleRate": "$0.0906 ",
        "retailRate": "$0.10 "
    },
    {
        "destination": "Namibia - Mobile",
        "wholeSaleRate": "$0.1012 ",
        "retailRate": "$0.12 "
    },
    {
        "destination": "Nepal - Landline",
        "wholeSaleRate": "$0.1410 ",
        "retailRate": "$0.16 "
    },
    {
        "destination": "Nepal - Mobile",
        "wholeSaleRate": "$0.1510 ",
        "retailRate": "$0.17 "
    },
    {
        "destination": "Netherlands - Landline",
        "wholeSaleRate": "$0.0147 ",
        "retailRate": "$0.02 "
    },
    {
        "destination": "Netherlands - Mobile",
        "wholeSaleRate": "$0.0144 ",
        "retailRate": "$0.02 "
    },
    {
        "destination": "Netherlands Antilles - Landline",
        "wholeSaleRate": "$0.0849 ",
        "retailRate": "$0.10 "
    },
    {
        "destination": "Netherlands Antilles - Mobile",
        "wholeSaleRate": "$0.1116 ",
        "retailRate": "$0.13 "
    },
    {
        "destination": "New Zealand - Landline",
        "wholeSaleRate": "$0.0097 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "New Zealand - Mobile",
        "wholeSaleRate": "$0.0401 ",
        "retailRate": "$0.05 "
    },
    {
        "destination": "Nicaragua - Landline",
        "wholeSaleRate": "$0.1113 ",
        "retailRate": "$0.13 "
    },
    {
        "destination": "Nicaragua - Mobile",
        "wholeSaleRate": "$0.1880 ",
        "retailRate": "$0.22 "
    },
    {
        "destination": "Niger - Landline",
        "wholeSaleRate": "$0.3204 ",
        "retailRate": "$0.37 "
    },
    {
        "destination": "Niger - Mobile",
        "wholeSaleRate": "$0.3004 ",
        "retailRate": "$0.35 "
    },
    {
        "destination": "Nigeria - Landline",
        "wholeSaleRate": "$0.0958 ",
        "retailRate": "$0.11 "
    },
    {
        "destination": "Nigeria - Mobile",
        "wholeSaleRate": "$0.0943 ",
        "retailRate": "$0.11 "
    },
    {
        "destination": "Northen Marianas Islands",
        "wholeSaleRate": "$0.0359 ",
        "retailRate": "$0.04 "
    },
    {
        "destination": "Norway - Landline",
        "wholeSaleRate": "$0.0028 ",
        "retailRate": "$0.00 "
    },
    {
        "destination": "Norway - Mobile",
        "wholeSaleRate": "$0.0049 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "Oman - Landline",
        "wholeSaleRate": "$0.1280 ",
        "retailRate": "$0.15 "
    },
    {
        "destination": "Oman - Mobile",
        "wholeSaleRate": "$0.1187 ",
        "retailRate": "$0.14 "
    },
    {
        "destination": "Pakistan - Landline",
        "wholeSaleRate": "$0.0369 ",
        "retailRate": "$0.04 "
    },
    {
        "destination": "Pakistan - Mobile",
        "wholeSaleRate": "$0.0415 ",
        "retailRate": "$0.05 "
    },
    {
        "destination": "Palau - Landline",
        "wholeSaleRate": "$0.2210 ",
        "retailRate": "$0.25 "
    },
    {
        "destination": "Palestine - Landline",
        "wholeSaleRate": "$0.1822 ",
        "retailRate": "$0.21 "
    },
    {
        "destination": "Palestine - Mobile",
        "wholeSaleRate": "$0.1940 ",
        "retailRate": "$0.22 "
    },
    {
        "destination": "Panama - Landline",
        "wholeSaleRate": "$0.0305 ",
        "retailRate": "$0.04 "
    },
    {
        "destination": "Panama - Mobile",
        "wholeSaleRate": "$0.1105 ",
        "retailRate": "$0.13 "
    },
    {
        "destination": "Papua New Guinea - Landline",
        "wholeSaleRate": "$0.8797 ",
        "retailRate": "$1.01 "
    },
    {
        "destination": "Paraguay - Landline",
        "wholeSaleRate": "$0.0173 ",
        "retailRate": "$0.02 "
    },
    {
        "destination": "Paraguay - Mobile",
        "wholeSaleRate": "$0.0198 ",
        "retailRate": "$0.02 "
    },
    {
        "destination": "Peru - Landline",
        "wholeSaleRate": "$0.0012 ",
        "retailRate": "$0.00 "
    },
    {
        "destination": "Peru - Mobile",
        "wholeSaleRate": "$0.0018 ",
        "retailRate": "$0.00 "
    },
    {
        "destination": "Philippines - Landline",
        "wholeSaleRate": "$0.1285 ",
        "retailRate": "$0.15 "
    },
    {
        "destination": "Philippines - Mobile",
        "wholeSaleRate": "$0.0901 ",
        "retailRate": "$0.10 "
    },
    {
        "destination": "Poland - Landline",
        "wholeSaleRate": "$0.0048 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "Poland - Mobile",
        "wholeSaleRate": "$0.0084 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "Portugal - Landline",
        "wholeSaleRate": "$0.0105 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "Portugal - Mobile",
        "wholeSaleRate": "$0.0100 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "Qatar - Landline",
        "wholeSaleRate": "$0.1530 ",
        "retailRate": "$0.18 "
    },
    {
        "destination": "Qatar - Mobile",
        "wholeSaleRate": "$0.1483 ",
        "retailRate": "$0.17 "
    },
    {
        "destination": "Romania - Landline",
        "wholeSaleRate": "$0.0012 ",
        "retailRate": "$0.00 "
    },
    {
        "destination": "Romania - Mobile",
        "wholeSaleRate": "$0.0044 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "Russia - Landline",
        "wholeSaleRate": "$0.0268 ",
        "retailRate": "$0.03 "
    },
    {
        "destination": "Russia - Mobile",
        "wholeSaleRate": "$0.1763 ",
        "retailRate": "$0.20 "
    },
    {
        "destination": "Rwanda - Landline",
        "wholeSaleRate": "$0.2474 ",
        "retailRate": "$0.28 "
    },
    {
        "destination": "Rwanda - Mobile",
        "wholeSaleRate": "$0.2335 ",
        "retailRate": "$0.27 "
    },
    {
        "destination": "Saint Kitts and Nevis - Landline",
        "wholeSaleRate": "$0.2064 ",
        "retailRate": "$0.24 "
    },
    {
        "destination": "Saint Kitts and Nevis - Mobile",
        "wholeSaleRate": "$0.1993 ",
        "retailRate": "$0.23 "
    },
    {
        "destination": "Saint Lucia - Landline",
        "wholeSaleRate": "$0.2175 ",
        "retailRate": "$0.25 "
    },
    {
        "destination": "Saint Lucia - Mobile",
        "wholeSaleRate": "$0.2030 ",
        "retailRate": "$0.23 "
    },
    {
        "destination": "Saint Marten - Landline",
        "wholeSaleRate": "$0.0870 ",
        "retailRate": "$0.10 "
    },
    {
        "destination": "Saint Marten - Mobile",
        "wholeSaleRate": "$0.1069 ",
        "retailRate": "$0.12 "
    },
    {
        "destination": "Saint Vincent - Landline",
        "wholeSaleRate": "$0.2034 ",
        "retailRate": "$0.23 "
    },
    {
        "destination": "Saint Vincent - Mobile",
        "wholeSaleRate": "$0.1884 ",
        "retailRate": "$0.22 "
    },
    {
        "destination": "San Marino - Landline",
        "wholeSaleRate": "$0.0000 ",
        "retailRate": "$0.00 "
    },
    {
        "destination": "Saudi Arabia - Landline",
        "wholeSaleRate": "$0.0744 ",
        "retailRate": "$0.09 "
    },
    {
        "destination": "Saudi Arabia - Mobile",
        "wholeSaleRate": "$0.1161 ",
        "retailRate": "$0.13 "
    },
    {
        "destination": "Senegal - Landline",
        "wholeSaleRate": "$0.2987 ",
        "retailRate": "$0.34 "
    },
    {
        "destination": "Senegal - Mobile",
        "wholeSaleRate": "$0.1697 ",
        "retailRate": "$0.20 "
    },
    {
        "destination": "Serbia - Landline",
        "wholeSaleRate": "$0.1409 ",
        "retailRate": "$0.16 "
    },
    {
        "destination": "Serbia - Mobile",
        "wholeSaleRate": "$0.3716 ",
        "retailRate": "$0.43 "
    },
    {
        "destination": "Sierra Leone - Landline",
        "wholeSaleRate": "$0.3154 ",
        "retailRate": "$0.36 "
    },
    {
        "destination": "Sierra Leone - Mobile",
        "wholeSaleRate": "$0.3645 ",
        "retailRate": "$0.42 "
    },
    {
        "destination": "Singapore - Landline",
        "wholeSaleRate": "$0.0128 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "Singapore - Mobile",
        "wholeSaleRate": "$0.0113 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "Slovakia - Landline",
        "wholeSaleRate": "$0.0020 ",
        "retailRate": "$0.00 "
    },
    {
        "destination": "Slovakia - Mobile",
        "wholeSaleRate": "$0.1129 ",
        "retailRate": "$0.13 "
    },
    {
        "destination": "Slovenia - Landline",
        "wholeSaleRate": "$0.1578 ",
        "retailRate": "$0.18 "
    },
    {
        "destination": "Slovenia - Mobile",
        "wholeSaleRate": "$0.0541 ",
        "retailRate": "$0.06 "
    },
    {
        "destination": "Somalia - Landline",
        "wholeSaleRate": "$0.2916 ",
        "retailRate": "$0.34 "
    },
    {
        "destination": "Somalia - Mobile",
        "wholeSaleRate": "$0.3895 ",
        "retailRate": "$0.45 "
    },
    {
        "destination": "South Africa - Landline",
        "wholeSaleRate": "$0.0386 ",
        "retailRate": "$0.04 "
    },
    {
        "destination": "South Africa - Mobile",
        "wholeSaleRate": "$0.0779 ",
        "retailRate": "$0.09 "
    },
    {
        "destination": "South Korea - Landline",
        "wholeSaleRate": "$0.0082 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "South Korea - Mobile",
        "wholeSaleRate": "$0.0083 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "South Sudan - Landline",
        "wholeSaleRate": "$0.4569 ",
        "retailRate": "$0.53 "
    },
    {
        "destination": "South Sudan - Mobile",
        "wholeSaleRate": "$0.4462 ",
        "retailRate": "$0.51 "
    },
    {
        "destination": "Spain - Landline",
        "wholeSaleRate": "$0.0028 ",
        "retailRate": "$0.00 "
    },
    {
        "destination": "Spain - Mobile",
        "wholeSaleRate": "$0.0065 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "Sri Lanka - Landline",
        "wholeSaleRate": "$0.1302 ",
        "retailRate": "$0.15 "
    },
    {
        "destination": "Sri Lanka - Mobile",
        "wholeSaleRate": "$0.1332 ",
        "retailRate": "$0.15 "
    },
    {
        "destination": "Sudan - Landline",
        "wholeSaleRate": "$0.1547 ",
        "retailRate": "$0.18 "
    },
    {
        "destination": "Sudan - Mobile",
        "wholeSaleRate": "$0.2163 ",
        "retailRate": "$0.25 "
    },
    {
        "destination": "Suriname - Landline",
        "wholeSaleRate": "$0.2587 ",
        "retailRate": "$0.30 "
    },
    {
        "destination": "Suriname - Mobile",
        "wholeSaleRate": "$0.2571 ",
        "retailRate": "$0.30 "
    },
    {
        "destination": "Swaziland - Landline",
        "wholeSaleRate": "$0.1248 ",
        "retailRate": "$0.14 "
    },
    {
        "destination": "Swaziland - Mobile",
        "wholeSaleRate": "$0.1265 ",
        "retailRate": "$0.15 "
    },
    {
        "destination": "Sweden - Landline",
        "wholeSaleRate": "$0.0032 ",
        "retailRate": "$0.00 "
    },
    {
        "destination": "Sweden - Mobile",
        "wholeSaleRate": "$0.0041 ",
        "retailRate": "$0.00 "
    },
    {
        "destination": "Switzerland - Landline",
        "wholeSaleRate": "$0.0196 ",
        "retailRate": "$0.02 "
    },
    {
        "destination": "Switzerland - Mobile",
        "wholeSaleRate": "$0.0841 ",
        "retailRate": "$0.10 "
    },
    {
        "destination": "Syria - Landline",
        "wholeSaleRate": "$0.2240 ",
        "retailRate": "$0.26 "
    },
    {
        "destination": "Syria - Mobile",
        "wholeSaleRate": "$0.2012 ",
        "retailRate": "$0.23 "
    },
    {
        "destination": "Taiwan - Landline",
        "wholeSaleRate": "$0.0168 ",
        "retailRate": "$0.02 "
    },
    {
        "destination": "Taiwan - Mobile",
        "wholeSaleRate": "$0.0848 ",
        "retailRate": "$0.10 "
    },
    {
        "destination": "Tajikistan - Landline",
        "wholeSaleRate": "$0.1508 ",
        "retailRate": "$0.17 "
    },
    {
        "destination": "Tajikistan - Mobile",
        "wholeSaleRate": "$0.1575 ",
        "retailRate": "$0.18 "
    },
    {
        "destination": "Tanzania - Landline",
        "wholeSaleRate": "$0.2654 ",
        "retailRate": "$0.31 "
    },
    {
        "destination": "Tanzania - Mobile",
        "wholeSaleRate": "$0.2515 ",
        "retailRate": "$0.29 "
    },
    {
        "destination": "Thailand - Landline",
        "wholeSaleRate": "$0.0074 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "Thailand - Mobile",
        "wholeSaleRate": "$0.0073 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "Togo - Landline",
        "wholeSaleRate": "$0.2231 ",
        "retailRate": "$0.26 "
    },
    {
        "destination": "Togo - Mobile",
        "wholeSaleRate": "$0.2253 ",
        "retailRate": "$0.26 "
    },
    {
        "destination": "Tonga - Landline",
        "wholeSaleRate": "$1.1165 ",
        "retailRate": "$1.28 "
    },
    {
        "destination": "Trinidad and Tobago - Landline",
        "wholeSaleRate": "$0.0486 ",
        "retailRate": "$0.06 "
    },
    {
        "destination": "Trinidad and Tobago - Mobile",
        "wholeSaleRate": "$0.1227 ",
        "retailRate": "$0.14 "
    },
    {
        "destination": "Tunisia - Landline",
        "wholeSaleRate": "$0.6656 ",
        "retailRate": "$0.77 "
    },
    {
        "destination": "Tunisia - Mobile",
        "wholeSaleRate": "$0.6949 ",
        "retailRate": "$0.80 "
    },
    {
        "destination": "Turkey - Landline",
        "wholeSaleRate": "$0.0221 ",
        "retailRate": "$0.03 "
    },
    {
        "destination": "Turkey - Mobile",
        "wholeSaleRate": "$0.1641 ",
        "retailRate": "$0.19 "
    },
    {
        "destination": "Turkmenistan - Landline",
        "wholeSaleRate": "$0.1340 ",
        "retailRate": "$0.15 "
    },
    {
        "destination": "Turks And Caicos - Landline",
        "wholeSaleRate": "$0.2085 ",
        "retailRate": "$0.24 "
    },
    {
        "destination": "Turks And Caicos - Mobile",
        "wholeSaleRate": "$0.2006 ",
        "retailRate": "$0.23 "
    },
    {
        "destination": "Uganda - Landline",
        "wholeSaleRate": "$0.3206 ",
        "retailRate": "$0.37 "
    },
    {
        "destination": "Uganda - Mobile",
        "wholeSaleRate": "$0.2518 ",
        "retailRate": "$0.29 "
    },
    {
        "destination": "Ukraine - Landline",
        "wholeSaleRate": "$0.1044 ",
        "retailRate": "$0.12 "
    },
    {
        "destination": "Ukraine - Mobile",
        "wholeSaleRate": "$0.3015 ",
        "retailRate": "$0.35 "
    },
    {
        "destination": "United Arab Emirates - Landline",
        "wholeSaleRate": "$0.1405 ",
        "retailRate": "$0.16 "
    },
    {
        "destination": "United Arab Emirates - Mobile",
        "wholeSaleRate": "$0.1363 ",
        "retailRate": "$0.16 "
    },
    {
        "destination": "United Kingdom - Landline",
        "wholeSaleRate": "$0.0038 ",
        "retailRate": "$0.00 "
    },
    {
        "destination": "United Kingdom - Mobile",
        "wholeSaleRate": "$0.0096 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "United Kingdom - Special Services",
        "wholeSaleRate": "$0.0000 ",
        "retailRate": "$0.00 "
    },
    {
        "destination": "Uruguay - Landline",
        "wholeSaleRate": "$0.0371 ",
        "retailRate": "$0.04 "
    },
    {
        "destination": "Uruguay - Mobile",
        "wholeSaleRate": "$0.0946 ",
        "retailRate": "$0.11 "
    },
    {
        "destination": "Uzbekistan - Landline",
        "wholeSaleRate": "$0.0912 ",
        "retailRate": "$0.10 "
    },
    {
        "destination": "Uzbekistan - Mobile",
        "wholeSaleRate": "$0.0847 ",
        "retailRate": "$0.10 "
    },
    {
        "destination": "Venezuela - Landline",
        "wholeSaleRate": "$0.0044 ",
        "retailRate": "$0.01 "
    },
    {
        "destination": "Venezuela - Mobile",
        "wholeSaleRate": "$0.0520 ",
        "retailRate": "$0.06 "
    },
    {
        "destination": "Vietnam - Landline",
        "wholeSaleRate": "$0.0511 ",
        "retailRate": "$0.06 "
    },
    {
        "destination": "Vietnam - Mobile",
        "wholeSaleRate": "$0.0514 ",
        "retailRate": "$0.06 "
    },
    {
        "destination": "Voxbone - iNum",
        "wholeSaleRate": "$0.0000 ",
        "retailRate": "$0.00 "
    },
    {
        "destination": "Western Samoa - Landline",
        "wholeSaleRate": "$0.0000 ",
        "retailRate": "$0.00 "
    },
    {
        "destination": "Western Samoa - Mobile",
        "wholeSaleRate": "$0.8428 ",
        "retailRate": "$0.97 "
    },
    {
        "destination": "Yemen - Landline",
        "wholeSaleRate": "$0.1202 ",
        "retailRate": "$0.14 "
    },
    {
        "destination": "Yemen - Mobile",
        "wholeSaleRate": "$0.1286 ",
        "retailRate": "$0.15 "
    },
    {
        "destination": "Zambia - Landline",
        "wholeSaleRate": "$0.3870 ",
        "retailRate": "$0.45 "
    },
    {
        "destination": "Zambia - Mobile",
        "wholeSaleRate": "$0.3627 ",
        "retailRate": "$0.42 "
    },
    {
        "destination": "Zimbabwe - Landline",
        "wholeSaleRate": "$0.1117 ",
        "retailRate": "$0.13 "
    },
    {
        "destination": "Zimbabwe - Mobile",
        "wholeSaleRate": "$0.4654 ",
        "retailRate": "$0.54 "
    }
]