import { Component, Input } from '@angular/core';
import { ExeDashboardService } from '../exe-dashboard.service';
import { Router } from '@angular/router';
// import * as chartData from '../../../../../shared/data/dashboard/ecommerce'

@Component({
  selector: 'app-order-overview',
  templateUrl: './order-overview.component.html',
  styleUrls: ['./order-overview.component.scss']
})
export class OrderOverviewComponent {
  showDropdown: boolean = false;
  linechartGraphList: any
  barChartdata: any
  formattedDates: any
  counts: any
  bsRangeValue: Date[];
  public chartData = [44, 55, 35, 50, 67, 50, 55, 45, 32, 38, 45];
  public chartCategories = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov"];
  @Input() interval: any
  @Input() advanceSearchValues1: { fromDate: string; toDate: string };
  constructor(private exedashboarservice: ExeDashboardService, private router: Router) {
    this.setDates()
  }


  public orderOverview: any;

  // Initialize with the default line chart
  ngOnInit() {
    this.getSubscriptionChart(this.advanceSearchValues1)
  }

  // Handle chart type change event
  onChartTypeChange(event: Event) {
    const selectedType = (event.target as HTMLSelectElement).value;

    if (selectedType === 'bar') {
      this.orderOverview = this.getBarChartConfig();
    } else if (selectedType === 'line') {
      this.orderOverview = this.getLineChartConfig();
    } else if (selectedType === 'area') {
      // this.orderOverview = this.getAreaChartConfig();
    }
  }

  // Bar chart configuration
  getBarChartConfig() {
    return {
      series: [
        {
          name: "Counts",
          data: this.counts,  // Use shared chartData variable
        }
      ],
      chart: {
        type: "bar",
        height: 300,
        toolbar: {
          show: false
        },
        events: {
          dataPointSelection: (event: any, chartContext: any, config: any) => {
            this.onBarClick(config.dataPointIndex);
          },
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",  // Bar width
        },
      },
      xaxis: {
        categories: this.formattedDates,  // Use shared chartCategories variable
      },
      colors: ["#7064F5"],
    };
  }

  // Line chart configuration
  getLineChartConfig() {
    return {
      series: [
        {
          name: "Counts",
          data: this.counts,  // Use shared chartData variable
        }
      ],
      chart: {
        type: "line",
        height: 245,
        toolbar: {
          show: false
        },
        events: {
          mounted: (chartContext) => {
            this.addClickEventToMarkers(chartContext);  // Add click events after the chart is mounted
          },
        },
      },
      stroke: {
        curve: "straight",
        width: 2,
      },
      xaxis: {
        categories: this.formattedDates,  // Use shared chartCategories variable
        labels: {
          show: true,
          rotate: -45, // Rotate labels by -45 degrees
          style: {
            fontSize: "10px", // Reduce font size for better fit
            fontFamily: "Arial, sans-serif",
          },

        },
        tickAmount: Math.ceil(this.formattedDates.length / 6),
      },
      colors: ["#7064F5"],
      grid: {

        show: false,

      }
    }

    // Area chart configuration

    // getAreaChartConfig() {
    //   return {
    //     series: [
    //       {
    //         name: "Counts",
    //         data: this.chartData,  // Use shared chartData variable
    //       }
    //       toolbar: {
    //         show: false
    //       },
    //       events: {
    //         dataPointSelection: (event: any, chartContext: any, config: any) => {
    //           this.onBarClick(config.dataPointIndex);
    //         },
    //       },

    //     },
    //     stroke: {
    //       curve: "smooth",
    //       width: 2,
    //     },
    //     fill: {
    //       type: "gradient",
    //       gradient: {
    //         opacityFrom: 0.4,
    //         opacityTo: 0,
    //       },
    //     },
    //     xaxis: {
    //       categories: this.chartCategories,  // Use shared chartCategories variable
    //     },
    //     colors: ["#7064F5"],
    //   };
  }



  // getSubscriptionChart() {
  //   const data = {
  //     startDate: this.bsRangeValue[0].toISOString().split('T')[0],
  //     endDate: this.bsRangeValue[1].toISOString().split('T')[0],
  //   };
  //   this.exedashboarservice.getChart(data).subscribe((res: any) => {
  //     this.linechartGraphList = res?.data;
  //     this.counts = this.linechartGraphList.map(item => item.count);
  //     this.formattedDates = this.linechartGraphList.map(item => {
  //       const date = new Date(item.date);
  //       return `${date.getDate()} ${this.getMonthName(date.getMonth())}`; // Format: 'DD MMM'
  //     });
  //     this.orderOverview = this.getLineChartConfig();
  //   });
  // }

  // Helper function to convert month index to month name
  // getMonthName(monthIndex: number): string {
  //   const monthNames = [
  //     'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
  //     'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  //   ];
  //   return monthNames[monthIndex];
  // }

  // onBarClick(index) {
  //   this.router.navigateByUrl("/subscriptions")
  // }
  // setDates() {
  //   const today = new Date();
  //   const fifteenDaysAgo = new Date();
  //   fifteenDaysAgo.setDate(fifteenDaysAgo.getDate() - 15);
  //   this.bsRangeValue = [fifteenDaysAgo, today];
  // }
  // onDateRangeChange() {
  //   this.getSubscriptionChart()
  // }
  // stop(event: Event) {
  //   event.stopPropagation();
  // }


  getAreaChartConfig() {
    return {
      series: [
        {
          name: "Counts",
          data: this.counts,  // Use shared chartData variable
        }
      ],
      chart: {
        type: "area",
        height: 300,
        toolbar: {
          show: false
        },
        events: {
          dataPointSelection: (event: any, chartContext: any, config: any) => {
            this.onBarClick(config.dataPointIndex);
          },
        },
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      fill: {
        type: "gradient",
        gradient: {
          opacityFrom: 0.4,
          opacityTo: 0,
        },
      },
      xaxis: {
        categories: this.formattedDates,  // Use shared chartCategories variable
      },
      colors: ["#7064F5"],
    };


  }
  ngOnChanges() {
    if (this.advanceSearchValues1) {
    this.getSubscriptionChart(this.advanceSearchValues1)
    }
  }
 
  getSubscriptionChart(date) {
    const data = {
      "startDate": date?.fromDate,
      "endDate": date?.toDate,
      interval:this.interval
    };
    this.exedashboarservice.getChart(data).subscribe((res: any) => {
      this.linechartGraphList = res?.data;
      this.counts = this.linechartGraphList.map(item => item.count);
      this.formattedDates = this.linechartGraphList.map(item => {
        const date = new Date(item.date);
        return `${date.getDate()} ${this.getMonthName(date.getMonth())}`; // Format: 'DD MMM'
      });
      this.orderOverview = this.getLineChartConfig();
    });
  }

  // Helper function to convert month index to month name
  getMonthName(monthIndex: number): string {
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    return monthNames[monthIndex];
  }
  addClickEventToMarkers(chartContext: any) {
    const chartElement = chartContext.el;
    const markers = chartElement.querySelectorAll('.apexcharts-marker');

    // Loop over each marker and add click event listener
    markers.forEach((marker: any, index: number) => {
      marker.addEventListener('click', () => {
        this.onBarClick(index);  // Call click handler with the index
      });
    });
  }
  onBarClick(index) {
    this.router.navigateByUrl("/subscriptions")
  }
  setDates() {
    const today = new Date();
    const fifteenDaysAgo = new Date();
    fifteenDaysAgo.setDate(fifteenDaysAgo.getDate() - 15);
    this.bsRangeValue = [fifteenDaysAgo, today];
  }
  // onDateRangeChange() {
  //   this.getSubscriptionChart()
  // }
  stop(event: Event) {
    event.stopPropagation();
  }
}

