<!-- <div class="container-fluid tab-form-wrapper">
  <form [formGroup]="formData">

   
    <div class="row form-group">
      <div class="col-md-3">
        <label>Sim Type</label>
      </div>
      <div class="col-md-9">
        <ng-select id="simType" formControlName="simType" (change)="isPhysicalStatus($event)">
          <ng-option value="ESIM">ESIM
          </ng-option>
          <ng-option value="PHYSICAL" >PHYSICAL
          </ng-option>
        </ng-select>
        <div class="text-danger"
          *ngIf="formData.get('simType').hasError('required') && formData.get('simType').touched">
          Sim Type is required
        </div>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>Change Type</label>
      </div>
      <div class="col-md-9">
        <ng-select id="changeType" formControlName="changeType">
          <ng-option value="Electronic">Electronic
          </ng-option>
          <ng-option value="NonElectronic"   >Non Electronic
          </ng-option>
        </ng-select>
        <div class="text-danger"
          *ngIf="formData.get('changeType').hasError('required') && formData.get('changeType').touched">
          Change Type is required
        </div>
      </div>
      
    </div>
    <div class="row form-group" *ngIf="formData.get('simType').value === 'PHYSICAL' && formData.get('changeType').value === 'Electronic'">
      <div class="col-md-3">
        <label>Store Id</label>
      </div>
      <div class="col-md-9">
        <ng-select id="storeId" formControlName="storeId">
          <ng-option *ngFor="let shipper of shipperList" [value]="shipper?.StoreId">
            {{ shipper.Name }}
          </ng-option>
        </ng-select>
        <div class="text-danger" *ngIf="formData.get('storeId').hasError('required') && formData.get('storeId').touched">
          Store Id is required
        </div>
      </div>
    </div>
    
    <div class="row form-group">
      <div class="col-md-3">
        <label for="simNo">Sim NO</label>
      </div>
      <div class="col-md-9">
        <input type="text" formControlName="simNo" class="form-control">
 
        <div *ngIf="formData.get('simNo').hasError('minlength')" class="text-danger">
          Sim NO must be exactly 19 digits.
        </div>
        <div *ngIf="formData.get('simNo').hasError('maxlength')" class="text-danger">
          Sim NO must be exactly 19 digits.
        </div>
      </div>
    </div>

    <div class="row form-group">
      <div class="col-md-12 text-end">
        <button type="button" class="btn btn-primary" (click)="updateSimNo()"
          [disabled]="this.formData.invalid">Submit</button>
      </div>
    </div>

  </form>
</div>
 -->


<div class="modal-body" *ngIf="!isSkeletonLoader">
  <div class="tab-content mt-3">

    <div class="container mt-4">
      <div class="row">

        <div class="col-md-12">
          <div class="col-md-12 jsutify-content-center">

            <div class="custom-card p-4">
              <div class=" p-3 pb-2 rounded">
                <form [formGroup]="formData">
                  <div class="row mb-3">
                    <div class="col-md-9">
                      <label for="simType" class="form-label">Sim Type</label>
                      <ng-select id="simType" formControlName="simType" (change)="isPhysicalStatus($event)">
                        <ng-option value="ESIM">ESIM</ng-option>
                        <ng-option value="PHYSICAL">PHYSICAL</ng-option>
                      </ng-select>
                      <div class="text-danger mt-1"
                        *ngIf="formData.get('simType').hasError('required') && formData.get('simType').touched">
                        Sim Type is required
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-md-9">
                      <label for="changeType" class="form-label">Change Type</label>
                      <ng-select id="changeType" formControlName="changeType">
                        <ng-option value="Electronic">Electronic</ng-option>
                        <ng-option value="NonElectronic">Non Electronic</ng-option>
                      </ng-select>
                      <div class="text-danger mt-1"
                        *ngIf="formData.get('changeType').hasError('required') && formData.get('changeType').touched">
                        Change Type is required
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3"
                    *ngIf="formData.get('simType').value === 'PHYSICAL' && formData.get('changeType').value === 'Electronic'">

                    <div class="col-md-9">
                      <label for="storeId" class="form-label">Store Id</label>
                      <ng-select id="storeId" formControlName="storeId">
                        <ng-option *ngFor="let shipper of shipperList" [value]="shipper?.StoreId">
                          {{ shipper.Name }}
                        </ng-option>
                      </ng-select>
                      <div class="text-danger mt-1"
                        *ngIf="formData.get('storeId').hasError('required') && formData.get('storeId').touched">
                        Store Id is required
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3"
                    *ngIf="formData.get('simType').value === 'PHYSICAL' && formData.get('changeType').value === 'NonElectronic'">

                    <div class="col-md-6 d-flex align-items-center gap-5 text-dark">
                      <div class="d-flex align-items-center switch-sm gap-3">
                      
                        <div class="form-check form-switch">

                          <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" formControlName="addPlan" title="This action will renew the plan without retaining current balance">
                          <label class="form-check-label mx-2" for="flexSwitchCheckDefault" >Add
                            Product</label>
                        </div>
                      </div>
                      <div class="d-flex align-items-center switch-sm gap-3">

                        <div class="form-check form-switch">
                        
                          <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault"  formControlName="disConnectPrevNumber" title="This action will renew the plan without retaining current balance">
                          <label class="form-check-label mx-2" for="flexSwitchCheckDefault" title="Current ICCID on customer profile will be deactivated when toggle is ON">Disconnect
                            Current SIM</label>
                        </div>
                      
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 my-3 px-0 " *ngIf="formData.get('disConnectPrevNumber').value">
                    <label for="disconnectReason">Reason for Disconnect</label>
                    <select class="form-control" formControlName="rejectionResasonID">
                      <option value="">Select Rejection Reason</option>
                      <option *ngFor="let reason of disconnectReasons" [value]="reason.id">{{reason.Name}}</option>
                    </select>
                  </div>
                  <div class="row mb-3">
                    <div class="col-md-9">
                      <label for="simNo" class="form-label">Sim NO</label>
                      <input type="text" formControlName="simNo" class="form-control" id="simNo">
                      <div class="text-danger mt-1" *ngIf="formData.get('simNo').hasError('minlength')">
                        Sim NO must be exactly 19 digits.
                      </div>
                      <div class="text-danger mt-1" *ngIf="formData.get('simNo').hasError('maxlength')">
                        Sim NO must be exactly 19 digits.
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3 ml-3 align-items-center"
                  *ngIf="formData.get('simType').value === 'ESIM' && formData.get('changeType').value === 'Electronic'">
                 <input
                   type="checkbox"
                   id="freeEsim"
                   formControlName="freeEsim"
                   class="form-check-input"
                 />
                 <label for="freeEsim" class="form-label fw-normal   text-primary m-1">Forcefully assign new eSIM</label>
             </div>
             
                </form>
              </div>
              <div class="col-md-12 mx-0 px-0 text-end mt-2">
                <div>
                  <button type="submit" class="btn btn-primary " (click)="updateSimNo()" [disabled]="formData.invalid">
                    <h5>Submit</h5>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isSkeletonLoader" style="max-width: 100%;">
  <app-skeleton-loader shape="payment-form"></app-skeleton-loader>

</div>