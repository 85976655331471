import { Component, Input, OnInit } from '@angular/core';
import { fadeInOut } from '../../services/app.animation';

@Component({
  selector: 'app-card-stats',
  templateUrl: './card-stats.component.html',
  styleUrls: ['./card-stats.component.scss'],
  animations:[fadeInOut]
})
export class CardStatsComponent implements OnInit {

@Input() data:any;
@Input() color: string = '#ffffff';
@Input() activeCardId:any;
isSkeletonLoader: boolean=true;
ngOnInit(): void {
  console.log(this.data)
  setTimeout(()=>{
    this.isSkeletonLoader=false
  },100)
}
}
