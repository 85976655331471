import { Component } from '@angular/core';
import { CommonService } from './shared/services/common.service';
import { MessageService } from 'primeng/api';
import { PermissionService } from './shared/services/permission-service.service';
import { SearchHistoryService } from "./shared/services/search-history.service";
import { loadNotifications } from './store/notifications/notification.actions';
import { Store } from '@ngrx/store';
import { AppState } from './store/app.state';
import { Notification } from './store/notifications/notification.model';
import { NavigationEnd, Router } from '@angular/router';
import { NotificationService } from './shared/services/notification.service';
// global.d.ts
// global.d.ts
export {};

declare global {
  interface Window {
    dataLayer: any[];
  }
}

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  providers: [MessageService],
})
export class AppComponent {
  refreshLogin:any
  constructor(
    public commonService: CommonService,
    private permissionService: PermissionService,
    private store: Store<AppState>,
    private searchHistoryService: SearchHistoryService,
    private router: Router,
    private notificationService:NotificationService
  ) {
    const location = window.location.href;
    const urlArray = location.split(":");
    if (localStorage.getItem('isCustomerLogin')) {
      if (!localStorage.getItem('refreshCounter')) {
 
        localStorage.setItem('refreshCounter', '1');
      } else {

        const currentCounter = parseInt(localStorage.getItem('refreshCounter') || '0', 10);
        const newCounter = currentCounter + 1;
        localStorage.setItem('refreshCounter', newCounter.toString());
      }
    } else {

      localStorage.removeItem('refreshCounter');
    }
  }

  async ngOnInit() {
    window.dataLayer = window.dataLayer || [];
    this.setupGtmTracking();
    await this.permissionService.updatePermission();
    this.searchHistoryService.initializeSearchHistory();
    const storedState = localStorage.getItem("state");
    if (storedState) {
      const parsedState = JSON.parse(storedState);
      const notifications: Notification[] =
        parsedState.notifications?.notifications || [];
      console.log("on load", notifications);
      this.store.dispatch(loadNotifications({ notifications }));
    }
    const currentCompany = localStorage.getItem("currentCompany") || "unity";
    this.changeClass(currentCompany);
    let notifications=[]
    await this.notificationService.fetchNotification().then((res: any) => {
      if(res.Status){
        notifications = res?.data?.data
      }
    })
    if(notifications){
      this.store.dispatch(loadNotifications({ notifications }));
    }
    // const storedState = localStorage.getItem('state');
    // if (storedState) {
    //   const parsedState = JSON.parse(storedState);
    //   const notifications: Notification[] = parsedState.notifications?.notifications || [];
    //   console.log('on load',notifications);
    //   this.store.dispatch(loadNotifications({ notifications }));
    // }
   
  
    // Optional: Store the value in a component property for binding
    this.refreshLogin = localStorage.getItem('refreshCounter');

    this.requestNotificationPermission()
  }
  
  requestNotificationPermission(): void {
    console.log("notification method call ")
    if ('Notification' in window && Notification.permission === 'default') {
      console.log(Notification.permission);
      Notification.requestPermission().then((permission) => {
        console.log(`Notification permission: ${permission}`);
      });
    }
  }

   /**
   * Method to set up Google Tag Manager (GTM) tracking
   */
   private setupGtmTracking(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        console.log('Current dataLayer:', window.dataLayer); // Add this line
        (window as any).dataLayer.push({
          event: 'pageview',
          pagePath: event.urlAfterRedirects,
        });
        
      }
    });
  }
  changeClass(company) {
    console.log(company);
    const companyNameClass = company?.replace(/\s+/g, "-").toLowerCase();
    const bodyElement = document.body;
    bodyElement.classList.forEach((cls) => {
      if (cls.startsWith("company-")) {
        bodyElement.classList.remove(cls);
      }
    });
    if (companyNameClass) {
      bodyElement.classList.add(`company-${companyNameClass}`);
    }
  }
}
