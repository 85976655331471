import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/store/app.state';
import { loadNotifications, markAllAsRead, markAsRead } from 'src/app/store/notifications/notification.actions';
import { selectAllNotifications, selectUnreadCount } from 'src/app/store/notifications/notification.selectors';
import { Notification } from 'src/app/store/notifications/notification.model';
import { EchoService } from './echo.service';
import { HttpService } from './httpService.service';
@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private store: Store<AppState>, private echoService: EchoService,private http: HttpService) { }
  /**
   * Load initial notifications
   * @param notifications Array of notifications
   */
  loadInitialNotifications(notifications: Notification[]): void {
    this.store.dispatch(loadNotifications({ notifications }));
  }
  /**
   * Get all notifications as an observable
   */
  getAllNotifications(): Observable<Notification[]> {
    return this.store.select(selectAllNotifications);
  }
  /**
   * Get unread notifications count as an observable
   */
  getUnreadCount(): Observable<number> {
    this.store.subscribe((state) => {
      console.log('Full State:', state);
    });
    return this.store.select(selectUnreadCount);
  }
  /**
   * Mark a specific notification as read
   * @param id Notification ID
   */
  markAsRead(id: number): void {
    this.store.dispatch(markAsRead({ id }));
  }
  /**
   * Mark all notifications as read
   */
  markAllAsRead(): void {
    this.store.dispatch(markAllAsRead());
  }
  subscribeTheStore() {
  }
  async fetchNotification() {
    try {
      let data = {
        CustomerId: "2004745",
        companyId: "UNITYCRM0021",
        perPage:100,
      };
      return this.http
        .get("api/Pre-paid/NotificationList", data, false, true)
        .toPromise();
    } catch (error) {
      console.log(error);
      return "";
    }
  }
  async fetchNotification2(page: number) {
    try {
      let data = {
        CustomerId: "2004745",
        companyId: "UNITYCRM0021",
        perPage:20,
        page:page
        // userID: "1",
      };
      return this.http
        .get("api/Pre-paid/NotificationList", data, false, true)
        .toPromise();
    } catch (error) {
      console.log(error);
      return "";
    }
  }
  getmarAsRead(id) {
    try {
      let url = "api/notification/update/" + id
      let data = {
      "IsRead": true
      }
      this.store.dispatch(markAsRead({ id }));
      return this.http.post(url, data, false).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
}