<div class="container-fluid 
 mt-5" *ngIf="!isSkeletonLoader" @fadeInOut>

 <div class="d-flex gap-3 col-md-12 px-0">

</div>
  <div class="custom-card mt-4" style="overflow: visible;">
  

      <!-- Card Header with Collapse Button -->
      <div class="card-header py-1 border-bottom d-flex justify-content-between align-items-center">
        <!-- <h4>Upload CSV</h4> -->
        <h4 class="card-header py-2 ">Email Status</h4>
        <div class="">
        <button class="custom-btn mx-2" (click)="downloadTemplate()">Downlaod template</button>
        <button class="custom-btn text-white" (click)="isCollapsed = !isCollapsed">
          {{ isCollapsed ? 'Upload CSV ▼' : 'Upload CSV ▲' }}
        </button>
        </div>
      </div>
  
      <!-- Collapsible Content -->
      <div [ngbCollapse]="isCollapsed">
        <div class="upload-card p-4 text-center">
          <div
            class="upload-area border border-dashed rounded p-4 mb-3"
            (drop)="onFileDrop($event)"
            (dragover)="onDragOver($event)"
            (dragleave)="onDragLeave()"
            [ngClass]="{ 'drag-over': isDragging }"
          >
            <ng-container *ngIf="!file">
              <i class="upload-icon bi bi-cloud-arrow-up-fill"></i>
              <h4>Drag & drop CSV ,txt file here</h4>
              <p>
                or <span class="browse-text" (click)="browseFile()">browse file</span> from device
              </p>
            </ng-container>
            <ng-container *ngIf="file">
              <i class="fa-regular fa-circle-check fs-2"></i>
              <h4>File Dropped Successfully!</h4>
              <p class="">drag & drop or <span class="browse-text" (click)="browseFile()">browse</span></p>
              <div class="file-info d-flex align-items-center justify-content-center border rounded p-2 mt-2">
                <i class="fa-regular fa-file-lines"></i>
                <span class="ms-2">{{ file.name }}</span>
                <span class="ms-auto">{{ file.size / 1024 | number: '1.0-2' }} KB</span>
        
                <i class="fa-solid fa-trash-can  ms-3 cursor-pointer" (click)="removeFile()"></i>
              </div>
            </ng-container>
            <input type="file" #fileInput (change)="onFileSelect($event)" hidden />
          </div>
     
          <button
            class="custom-btn"
            [disabled]="!file"
            (click)="uploadCSV()"
          >
            Upload
          </button>
          
        </div>
      </div>

    <div class="row mt-3">
      <div class="col-md-12">
        <!-- KPIs -->
        <div class="d-flex flex-wrap w-100">
            <app-card-stats class="col-lg-2 col-sm-4 col-12 mb-1 " *ngFor="let item of summaryData"
                [data]="item">
            </app-card-stats>
        </div>    
      </div>
    </div>
     <div class="card ">
    <div class="date-picker mx-2" (click)="stop($event)">

      <div class="date-picker hiringCal round-border d-flex mb-2"
          style="justify-content: end;">
          <input type="text" class="form-control ps-2 col-md-2 mx-2 py-1 mt-1" placeholder="Search..." style="height: fit-content;" *ngIf="openSearchBox"
          (keyup.enter)="searchChange($event)" />
          <div class="f-row d-inline-block position-relative reports-datePicker mr-2" *ngIf="OpenCalender">
              <button
                  class="f-row f-a-center px-3 filter-btn round hover-light py-1 mt-1 date-button">
                  <i class="fa fa-calendar" (click)="drp.toggle()"
                      [attr.aria-expanded]="drp.isOpen"></i>
                  <input [(ngModel)]="bsRangeValue"
                      (ngModelChange)="onDateRangeSelected($event)"
                      [bsConfig]="{ rangeInputFormat: 'MMM DD, YYYY', adaptivePosition: true, showWeekNumbers: false }"
                      bsDaterangepicker
                      class="pl-2 py-0 text border-0 bg-transparent cursor-pointer"
                      placeholder="Daterangepicker" type="text" readonly
                      #drp="bsDaterangepicker" />
                  <i class="fa fa-chevron-down" (click)="drp.toggle()"
                      [attr.aria-expanded]="drp.isOpen"></i>
              </button>
          </div>
 
          <app-filter-btn [icon]="'fa fa-search'" class=" py-1 mr-2" [isBtn]="true" (click)="openSearch()"></app-filter-btn>
          <app-filter-btn [icon]="'fa-solid fa-calendar-days'" class=" py-1" [isBtn]="true" (click)="openCalender()"></app-filter-btn>
          <app-filter-btn [icon]="'fa-solid fa-arrows-rotate '" class="ml-2 py-1" [isBtn]="true" (click)="refresh()"></app-filter-btn>
      </div>
  </div>

      <div class="table-responsive table-hover table-striped table-card">
        <table class="table table-hover " >
          <thead class="table-light">
            <tr style="background-color: #e1e1e1;">
              <th *ngFor="let header of headers" [style.width.px]="header.width || null">
                {{ header.display }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of rows; let i = index">
              <td *ngFor="let header of headers">
                <ng-container [ngSwitch]="header.key">
                  <ng-container *ngSwitchCase="'action'">
                <div class="position-relative">
                  <i class="fa fa-ellipsis-h py-2 px-4"     [id]="'trigger-' + i" (click)="toggleDropdown(i)"></i>
                  <div class="dropdown-content1 text-start" *ngIf="dropdownVisible.has(i)"   [id]="'dropdown-' + i">
                    <div class="dropdown-item1 text-nowrap" *ngIf="row.EmailStatus !=='Pending'" (click)="sendStatus(row,'pending')" title="Status update">
                      <span><i class="fa-solid fa-hourglass-end mr-1"></i> Pending</span>
                    </div>
                  
                    <div class="dropdown-item1 text-nowrap" *ngIf="row.EmailStatus !=='Sent'" (click)="sendStatus(row,'sent')" title="Status update">
                      <span><i class="fa-solid fa-paper-plane mr-1" ></i> Sent</span>
                    </div>
                    <div class="dropdown-item1 text-nowrap" *ngIf="row.EmailStatus !=='Failed'" (click)="sendStatus(row,'failed')" title="Status update">
                  
                      <span><i class="fa-solid fa-triangle-exclamation  mr-1"></i>Failed</span>
                    </div>
                
                    <div class="dropdown-item1 text-nowrap" (click)="sendEmail(row)" title="Send email to customer">
                  
                      <span><i class="fa-solid fa-envelope  mr-1"></i>Send Email</span>
                    </div>
  
                  </div>
                </div>
                </ng-container>
                <ng-container *ngSwitchCase="'ErrorDetails'">
                 <button class="custom-btn" *ngIf="row?.ErrorDetails" (click)="openErrorModal(row.ErrorDetails)">View </button>
                </ng-container>
                <ng-container *ngSwitchCase="'CustomerId'" >
                  <span class="text-nowrap underline-text" (click)="viewDetails(row.CustomerId)">{{row[header.key]}}</span>
                </ng-container>
                <ng-container *ngSwitchCase="'CreatedAT'">
                  <span class="text-nowrap">{{ row[header.key] | date }}</span>
                </ng-container>
                <ng-container *ngSwitchCase="'UpdatedAt'">
                  <span class="text-nowrap">{{ row[header.key] | date }}</span>
                </ng-container>
                <ng-container *ngSwitchCase ="'EmailStatus'">
                  <span class="custom-badge" [ngClass]="{
                    'badge-warning': row[header.key] === 'Pending',
                    'badge-danger': row[header.key] === 'Failed',
                    'badge-success': row[header.key] === 'Sent'
                  }">
                    {{ row[header.key] || '-' }}
                  </span>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  {{ row[header.key] || '-' }}
                </ng-container>
          
                </ng-container>
              
              </td>
            </tr>
          </tbody>
        </table>
        <!-- <div *ngIf="isSkeletonLoader" @fadeInOut style="max-width: 100%;">
          <app-skeleton-loader [tableRow]="8" [tableColumn]="6" shape="table"></app-skeleton-loader>
        </div> -->
        <div class="text-center py-3 text-muted" *ngIf="rows?.length === 0">
          No data found
        </div>
      </div>
      <div class="  d-flex justify-content-end m-2" *ngIf="totalItems">
        <ngb-pagination class="pagination-block" [directionLinks]="true" [boundaryLinks]="true"
            [collectionSize]="totalItems" [(page)]="currentPage"
            (pageChange)="onPaginationChanged($event)" [maxSize]="3" [pageSize]="itemsPerPage"
            [ellipses]="true">
            <ng-template ngbPaginationPrevious>Previous</ng-template>
            <ng-template ngbPaginationNext>Next</ng-template>
        </ngb-pagination>
    </div>
  </div>
  </div>

</div>
<!-- Bootstrap Modal -->
<div class="modal fade" id="errorDetailsModal" tabindex="-1" aria-labelledby="errorDetailsModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="errorDetailsModalLabel">Error Details</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <!-- Display Error Details Here -->
        <pre class="badge-danger px-2">{{ errorDetails }}</pre>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isSkeletonLoader" @fadeInOut style="max-width: 100%;">
  <app-skeleton-loader [tableRow]="8" [tableColumn]="6" shape="table"></app-skeleton-loader>
</div>