import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuickLinksRoutingModule } from './quick-links-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { QuickLinksComponent } from './quick-links.component';
import { AutopayComponent } from './autopay/autopay.component';
import { ExistingComponent } from './existing/existing.component';
import { FeaturesComponent } from './features/features.component';
import { ViewPlansComponent } from './view-plans/view-plans.component';
import { UsageComponent } from './usage/usage.component';
import { EquipmentPurchaseComponent } from './equipment-purchase/equipment-purchase.component';
import { AddTopupComponent } from './add-topup/add-topup.component';
import { AddDataComponent } from './add-data/add-data.component';
import { PlanChangeComponent } from './plan-change/plan-change.component';
import { ChangeAccountTypeComponent } from './change-account-type/change-account-type.component';
import { UpgradeFamilyPlanComponent } from './upgrade-family-plan/upgrade-family-plan.component';
import { PlanRenewComponent } from './plan-renew/plan-renew.component';
import { ChangeApsComponent } from './change-aps/change-aps.component';
import { AddProductComponent } from './add-product/add-product.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FreeTopupComponent } from './add-topup/free-topup/free-topup.component';
import { DealerWalletComponent } from './add-topup/dealer-wallet/dealer-wallet.component';
import { WalletComponent } from './add-topup/wallet/wallet.component';
import { MoneyGramComponent } from './add-topup/money-gram/money-gram.component';
import { MoneyOrderComponent } from './add-topup/money-order/money-order.component';
import { CashComponent } from './add-topup/cash/cash.component';
import { CreditCardComponent } from './add-topup/credit-card/credit-card.component';
import { CreditCardAddDataComponent } from './add-data/credit-card-add-data/credit-card-add-data.component';
import { CashAddDataComponent } from './add-data/cash-add-data/cash-add-data.component';
import { MoneyOrderAddDataComponent } from './add-data/money-order-add-data/money-order-add-data.component';
import { MoneyGramAddDataComponent } from './add-data/money-gram-add-data/money-gram-add-data.component';
import { WalletAddDataComponent } from './add-data/wallet-add-data/wallet-add-data.component';
import { DealerWalletAddDataComponent } from './add-data/dealer-wallet-add-data/dealer-wallet-add-data.component';
import { FreeTopupAddDataComponent } from './add-data/free-topup-add-data/free-topup-add-data.component';
import { CreditCardPlanChangeComponent } from './plan-change/credit-card-plan-change/credit-card-plan-change.component';
import { MoneyOrderPlanChangeComponent } from './plan-change/money-order-plan-change/money-order-plan-change.component';
import { MoneyGramPlanChangeComponent } from './plan-change/money-gram-plan-change/money-gram-plan-change.component';
import { WalletPlanChangeComponent } from './plan-change/wallet-plan-change/wallet-plan-change.component';
import { DealerWalletPlanChangeComponent } from './plan-change/dealer-wallet-plan-change/dealer-wallet-plan-change.component';
import { IvrCreditCardComponent } from './plan-change/ivr-credit-card/ivr-credit-card.component';
import { CreditCardRenewComponent } from './plan-renew/credit-card-renew/credit-card-renew.component';
import { MoneyGramRenewComponent } from './plan-renew/money-gram-renew/money-gram-renew.component';
import { MoneyOrderRenewComponent } from './plan-renew/money-order-renew/money-order-renew.component';
import { WalletRenewComponent } from './plan-renew/wallet-renew/wallet-renew.component';
import { DealerWalletRenewComponent } from './plan-renew/dealer-wallet-renew/dealer-wallet-renew.component';
import { IvrCreditCardRenewComponent } from './plan-renew/ivr-credit-card-renew/ivr-credit-card-renew.component';
import { IvrCreditCardPurchaseComponent } from './equipment-purchase/ivr-credit-card-purchase/ivr-credit-card-purchase.component';
import { CreditCardPurchaseComponent } from './equipment-purchase/credit-card-purchase/credit-card-purchase.component';
import { MoneyGramPurchaseComponent } from './equipment-purchase/money-gram-purchase/money-gram-purchase.component';
import { MoneyOrderPurchaseComponent } from './equipment-purchase/money-order-purchase/money-order-purchase.component';
import { WalletPurchaseComponent } from './equipment-purchase/wallet-purchase/wallet-purchase.component';
import { DealerWalletPurchaseComponent } from './equipment-purchase/dealer-wallet-purchase/dealer-wallet-purchase.component';
import { FeaturesServicesComponent } from './features/features-services/features-services.component';
import { ChangeAddressComponent } from './features/change-address/change-address.component';
import { EditDeviceComponent } from './features/edit-device/edit-device.component';
import { CustomerInfoComponent } from './features/customer-info/customer-info.component';
import { TagComponent } from './features/tag/tag.component';
import { CreditCardAutopayComponent } from './autopay/credit-card-autopay/credit-card-autopay.component';
import { PlanTopupComponent } from './add-topup/new-topup-plan/plan-topup/plan-topup.component';
import { NewTopupPlanComponent } from './add-topup/new-topup-plan/new-topup-plan.component';
import { CarrierDetailsComponent } from './add-topup/new-topup-plan/carrier-details/carrier-details.component';
import { LinkedTopupComponent } from './add-topup/new-topup-plan/linked-topup/linked-topup.component';
import { TopupComponent } from './add-topup/new-topup-plan/topup/topup.component';
import { AddTopupLinkComponent } from './add-topup/new-topup-plan/linked-topup/add-topup-link/add-topup-link.component';
import { TopupAddComponent } from './add-topup/new-topup-plan/topup/topup-add/topup-add.component';
import { CarrierSetupModalComponent } from './add-topup/new-topup-plan/carrier-details/carrier-setup-modal/carrier-setup-modal.component';
import { PlanSetupModalComponent } from './add-topup/new-topup-plan/plan-topup/plan-setup-modal/plan-setup-modal.component';
import { AddonTopupListModalComponent } from './add-topup/new-topup-plan/plan-topup/addon-topup-list-modal/addon-topup-list-modal.component';
import { ChangeMdnComponent } from './features/change-mdn/change-mdn.component';
import { ChangeImeiComponent } from './features/change-imei/change-imei.component';
import { SimChangeComponent } from './features/sim-change/sim-change.component';
import { ChangePlanToComponent } from './plan-change/change-plan-to/change-plan-to.component';
import { PlanChangePaymentComponent } from './plan-change/plan-change-payment/plan-change-payment.component';
import { NewPlanChangeComponent } from './new-plan-change/new-plan-change.component';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [
    AddTopupComponent,
    AddDataComponent,
    PlanChangeComponent,
    ChangeAccountTypeComponent,
    UpgradeFamilyPlanComponent,
    PlanRenewComponent,
    ChangeApsComponent,
    EquipmentPurchaseComponent,
    UsageComponent,
    ViewPlansComponent,
    FeaturesComponent,
    ExistingComponent,
    AutopayComponent,
    AddProductComponent,
    CreditCardComponent,
    CashComponent,
    MoneyOrderComponent,
    MoneyGramComponent,
    WalletComponent,
    DealerWalletComponent,
    FreeTopupComponent,
    QuickLinksComponent,
    CreditCardAddDataComponent,
    CashAddDataComponent,
    MoneyOrderAddDataComponent,
    MoneyGramAddDataComponent,
    WalletAddDataComponent,
    DealerWalletAddDataComponent,
    FreeTopupAddDataComponent,
    CreditCardPlanChangeComponent,
    MoneyOrderPlanChangeComponent,
    MoneyGramPlanChangeComponent,
    WalletPlanChangeComponent,
    DealerWalletPlanChangeComponent,
    IvrCreditCardComponent,
    CreditCardRenewComponent,
    MoneyGramRenewComponent,
    MoneyOrderRenewComponent,
    WalletRenewComponent,
    DealerWalletRenewComponent,
    IvrCreditCardRenewComponent,
    IvrCreditCardPurchaseComponent,
    CreditCardPurchaseComponent,
    MoneyGramPurchaseComponent,
    MoneyOrderPurchaseComponent,
    WalletPurchaseComponent,
    DealerWalletPurchaseComponent,
    FeaturesServicesComponent,
    ChangeAddressComponent,
    EditDeviceComponent,
    CustomerInfoComponent,
    TagComponent,
    CreditCardAutopayComponent,
    PlanTopupComponent,
    NewTopupPlanComponent,
    CarrierDetailsComponent,
    LinkedTopupComponent,
    TopupComponent,
    AddTopupLinkComponent,
    TopupAddComponent,
    CarrierSetupModalComponent,
    PlanSetupModalComponent,
    AddonTopupListModalComponent,
    ChangeMdnComponent,
    SimChangeComponent,
    ChangeImeiComponent,
    ChangePlanToComponent,
    PlanChangePaymentComponent,
    NewPlanChangeComponent
    
  ],
  imports: [
    CommonModule,
    QuickLinksRoutingModule,
    SharedModule,
    NgbNavModule,
    NgbModule,
    MatTooltipModule
  ],
  exports:[
    AddTopupComponent,
    AddDataComponent,
    PlanChangeComponent,
    ChangeAccountTypeComponent,
    UpgradeFamilyPlanComponent,
    PlanRenewComponent,
    ChangeApsComponent,
    EquipmentPurchaseComponent,
    UsageComponent,
    ViewPlansComponent,
    FeaturesComponent,
    ExistingComponent,
    AutopayComponent,
    AddProductComponent,
    CreditCardComponent,
    CashComponent,
    MoneyOrderComponent,
    MoneyGramComponent,
    WalletComponent,
    DealerWalletComponent,
    FreeTopupComponent,
    QuickLinksComponent,
    CreditCardAddDataComponent,
    CashAddDataComponent,
    MoneyOrderAddDataComponent,
    MoneyGramAddDataComponent,
    WalletAddDataComponent,
    DealerWalletAddDataComponent,
    FreeTopupAddDataComponent,
    CreditCardPlanChangeComponent,
    MoneyOrderPlanChangeComponent,
    MoneyGramPlanChangeComponent,
    WalletPlanChangeComponent,
    DealerWalletPlanChangeComponent,
    IvrCreditCardComponent,
    ChangeMdnComponent,
    SimChangeComponent,
    ChangeImeiComponent,
    SharedModule,
    PlanChangePaymentComponent,
    
  ]
})
export class QuickLinksModule { }
