<div class="container-fluid p-4">
    <div class="d-flex justify-content-end">
        <div class="sideHeader">
            <div class="d-flex  justify-content-end">
                <div class="date-picker" (click)="stop($event)">
                  <div class="date-picker hiringCal round-border d-flex mx-2" style="justify-content: end;"  >
                      <div class="f-row d-inline-block position-relative reports-datePicker">
                          <button class="f-row f-a-center px-3 filter-btn round hover-light py-1 date-button" >
                              <i class="fa fa-calendar" (click)="drp.toggle()" [attr.aria-expanded]="drp.isOpen"></i>
                              <input [(ngModel)]="bsRangeValue" (ngModelChange)="dataChanged()"
                                  [bsConfig]="{ rangeInputFormat: 'MMM DD, YYYY', adaptivePosition: true, showWeekNumbers: false }"
                                  bsDaterangepicker class="p-2 py-0 text border-0 bg-transparent cursor-pointer"
                                  placeholder="Daterangepicker" type="text" readonly #drp="bsDaterangepicker" />
                              <i class="fa fa-chevron-down" (click)="drp.toggle()" [attr.aria-expanded]="drp.isOpen"></i>
                          </button>
                      </div>
                  </div>
                </div>
            </div>
            <div>
                <button class="custom-button" (click)="exportOrder()">
                    <div class="d-flex gap-2 align-items-center">
                        <i class="fa fa-cloud-upload"></i>
                        <span class="text">Export</span>
                    </div>
                </button>
            </div>
            <div>
                <button class="custom-btn dark" (click)="openUnitywireless()">
                    Create Order
                </button>
            </div>
        </div>
    </div>

    <div class="d-flex gap-2 mt-3 overflow-auto" *ngIf="!isHideCard">
        <!-- <div class="col-xxl-2 col-md-4" *ngFor="let item of dataArray">
            <div class="card mb-0 small-widget">
                <app-sale-status [data]="item"></app-sale-status>

            </div>
        </div> -->
        <div class="d-flex flex-wrap w-100">
            <app-card-stats class="col-lg-2 col-sm-4 col-12 mb-3" *ngFor="let item of dataArray" [data]="item">
            </app-card-stats>
        </div>
    </div>

    <app-awaiting-shipment-order [isDropshipOrder]="isDropshipOrder" [advanceSearchValues1]="advanceSearchValues1" (orderedEmitted)="handleOrderedEmitted($event)"
        class="container-fluid" style="padding-left: 0"></app-awaiting-shipment-order>
</div>