import { ChangeDetectorRef, Component, Input, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { TransactionService } from '../transaction.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CommonService } from 'src/app/shared/services/common.service';
import { ReportSystemService } from 'src/app/reports/services/report-system.service';
import { AwaitingShipmentService } from 'src/app/awaiting-shipment/services/awaiting-shipment.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { fadeInOut } from 'src/app/shared/services/app.animation';
import { DynamicInvoiceTemplateService } from 'src/app/shared/components/dynamic-invoice-template/dynamic-invoice-template.service';

@Component({
  selector: 'app-transaction-list',
  templateUrl: './transaction-list.component.html',
  styleUrls: ['./transaction-list.component.scss'],
  animations: [fadeInOut]
})

export class TransactionListComponent {
  @Input() selectedCard: any;  // Receive the selected card from the parent


  rowDatas = [];

  dropdownVisible = new Set<number>();
  showFilterRow: boolean;
  isDraggable: boolean;
  searchTerm: string;
  amount: number = 0;
  headers: any = [
    { key: 'Amount', display: 'Amount' },
    { key: 'AccountNumber', display: 'Payment Method' },
    { key: 'MessageCode', display: 'Description' },
    { key: 'EmailAddress', display: 'Customer' },
    { key: 'CreatedDate', display: 'Date' },
    { key: 'RefundedDate', display: 'Refunded Date' },
    { key: 'DisputeAmount', display: 'Dispute Amount' },
    { key: 'DisputeReason', display: 'Dispute Reason' },
    { key: 'DisputedOn', display: 'Disputed On' },
    { key: 'EvidenceDueBy', display: 'Evidence Due By' },
    { key: 'EvidenceSubmittedAt', display: 'Evidence Submitted At' },
    { key: 'DeclineReason', display: 'Decline Reason' },
    { key: 'Actions', display: 'Actions' }
  ];

  // {
  //   "PaymentId": 1,
  //   "id": 1,
  //   "invoice_id": 2,
  //   "po_id": 2,
  //   "customer_id": 62,
  //   "method": "Auto",
  //   "amount": "30.00",
  //   "status": "Paid",
  //   "txn_id": "120035098212",
  //   "response": null,
  //   "txn_response": null,
  //   "created_at": "2024-12-12T00:30:33.000000Z",
  //   "updated_at": null,
  //   "CustomerId": 62,
  //   "FirstName": "SHOLANDA",
  //   "MiddleName": "S",
  //   "LastName": "GOLSON",
  //   "Email": "SHOLANDAGOLSON74@GMAIL.COM",
  //   "refunds": []
  // }

  filterList: any = [
    { "key": "customer_id", "value": "Customer ID" },
    { "key": "email", "value": "Email" },
    { "key": "card_brand", "value": "Card brand" },
    { "key": "card_last_4_digits", "value": "Card last 4 digits" },
    { "key": "dispute_amount", "value": "Dispute amount" },
    { "key": "disputed_on", "value": "Disputed on" },
    { "key": "dispute_reason", "value": "Dispute reason" },
    { "key": "evidence_due_by", "value": "Evidence due by" },
    { "key": "evidence_submitted_at", "value": "Evidence submitted at" }
  ]


  paymentMethodList: any = [{
    key: "visa",
    title: "Visa"
  }]
  rangeIds: any;
  selectedRange: any;
  bsRangeValue: any;
  statusList: any[];
  canceldata: any[];
  selectedStatus: any = {};
  isAmountDropdownOpen: any;
  selectedFilter: any = [];
  totalOrders: any;
  refundReason: any;
  refundAmount: any;
  itemsPerPage: number = 50
  currentPage: number = 1
  refundReasonList: any;
  selectedTransaction: any;
  customStartDate: any;
  customEndDate: any;
  isSkeletonLoader: boolean = true;

  async toggleDropdown(index: number) {
    if (this.dropdownVisible.has(index)) {
      this.dropdownVisible.delete(index); // Hide if already visible
    } else {
      this.dropdownVisible.clear();
      this.dropdownVisible.add(index); // Show if not visible
    }
  }
  constructor(private router: Router, private dynamicInvoiceTemplateService: DynamicInvoiceTemplateService, private transactionService: TransactionService, private commonService: CommonService,
    private reportSystemService: ReportSystemService, private shipmentService: AwaitingShipmentService, private cd: ChangeDetectorRef, private modalService: NgbModal,
  ) {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedCard'] && this.selectedCard) {

      if (this.selectedCard.title == "All") {
        this.selectedStatus = {}
      } else {
        if (this.selectedStatus) {
          this.selectedStatus.Status = this.selectedCard.title;
        }
      }
      this.getTransactionList();
    }
  }

  ngOnInit(): void {

    this.getTableHeader()
    this.getRefundReason()
    this.getTransactionList()
    this.getReportRanges()
    this.getTransactionStats()
  }
  goToDetails(item) {
    console.log(item);
    this.router.navigate([`/transaction/detail`], {
      state: { transaction: item }
    });
  }



  getTransactionList() {
    console.log(this.customStartDate);
    console.log(this.customEndDate);
    let data = {
      searchTerm: this.searchTerm,
      status: this.selectedStatus?.Status,
      amount: this.amount,
      range: this.selectedRange?.Code,
      page: this.currentPage,
      per_page: this.itemsPerPage,
      customStartDate: this.customStartDate,
      customEndDate: this.customEndDate
    }
    this.transactionService.getTransactionList(data).subscribe(
      (response: any) => {
        this.rowDatas = response?.data?.data || [];
        this.totalOrders = response?.data?.total
        this.rowDatas.map(val => {
          // if (val.refunds.length > 0) {
          //   console.log(val);
          // }
        })
        this.isSkeletonLoader = false
      },
      (error) => {
        console.error('Error fetching data:', error);
        this.isSkeletonLoader = false
      }
    );
  }


  getTableHeader() {
    this.transactionService.getTableHeader().subscribe(
      (response: any) => {
        if (response?.data?.data) {
          this.headers = response?.data?.data;
        }
      },
      (error) => {
        console.error('Error fetching data:', error);
      }
    );
  }

  getRefundReason() {
    this.transactionService.getRefundReason().subscribe(
      (response: any) => {
        // this.headers = response?.data?.data || [];
        this.refundReasonList = response?.data
      },
      (error) => {
        console.error('Error fetching data:', error);
      }
    );
  }

  onSearchClick() {
    this.showFilterRow = true
  }

  closeFilter() {
    this.showFilterRow = false
    this.searchTerm = ""
  }

  searchChange(e) {
    this.searchTerm = e.target.value
    this.getTransactionList()
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.headers, event.previousIndex, event.currentIndex);
  }

  async getReportRanges() {
    try {
      const res: any = await this.reportSystemService.getReportRanges()
      if (res.Status) {
        res.data.forEach((item) => {
          item["value"] = item.id,
            item["label"] = item.Code
        })
        this.rangeIds = res.data;
      } else {
        this.commonService.showToast('error', "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
  }

  handleDateChange(e) {
    this.selectedRange = e
    if (e.Name != 'Custom') {
      this.getTransactionList()
    }
  }
  handleStatusChange(e) {
    console.log(e)
    this.selectedStatus = e
    this.getTransactionList()
  }

  dateChanged() {
    this.customStartDate = this.bsRangeValue[0].toISOString().split('T')[0]
    this.customEndDate = this.bsRangeValue[1].toISOString().split('T')[0]
    if (this.customStartDate && this.customEndDate) {
      this.getTransactionList()
    }
  }

  stop(event: Event) {
    event.stopPropagation();
  }

  getTransactionStats() {
    this.transactionService.getTransactionStat().subscribe(
      (response: any) => {
        this.statusList = response?.data?.statusCounts || []
      },
      (error) => {
        console.error('Error fetching data:', error);
      }
    );
  }

  changeDraggable() {
    this.isDraggable = true;
    this.canceldata = []
    this.canceldata = [...this.headers]
  }

  closeDragDrop() {
    this.isDraggable = false;
    this.headers = []
    this.headers = [...this.canceldata]
    this.cd.detectChanges();

  }

  async saveColumnArrangement() {
    try {
      const res: any = await this.shipmentService.saveTableHeaders(this.headers, 'transaction');
      if (res.Status) {
        this.commonService.showToast("success", "Success", "Updated successfully!");
        this.isDraggable = false
        this.getTableHeader()
      } else {
        this.commonService.showToast("error", "Error", res.message);
      }

    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"));
    }
  }


  openDropdown(item) {
    item.isDropdownShow = !item.isDropdownShow
  }


  handleMoreFilterChange(e: any) {
    const isFilterExist = this.selectedFilter.some(filter => filter.key === e.key);
    if (!isFilterExist) {
      this.selectedFilter.push({ isDropdownShow: false, ...e });
    } else {
      console.log('Filter already exists');
    }
  }

  openAmoutDropdown() {
    this.isAmountDropdownOpen = !this.isAmountDropdownOpen
  }

  changeFilterValue(e, item) {
    console.log(e, item);
    this.searchTerm = e.target.value
    this.getTransactionList()
  }

  clearAll() {
    this.searchTerm = null
    this.selectedFilter = []
    this.selectedStatus = {}
    this.amount = null
    this.selectedRange = null
    this.currentPage = 1
    this.getTransactionList()
  }


  onAmountChange() {
    this.getTransactionList()
  }


  onPaginationChanged(e) {
    this.currentPage = e
    this.getTransactionList()
  }


  onRefundClick(modal, item) {
    console.log('item: ', item);
    this.selectedTransaction = item
    console.log(this.selectedTransaction);
    this.modalService.open(modal, { size: 'md', centered: true })
  }

  confirmRefund() {
    let data = {
      paymentId: this.selectedTransaction?.PaymentId,
      CustomerId: this.selectedTransaction?.CustomerId,
      refundReasonId: parseInt(this.refundReason),
      transactionId: this.selectedTransaction?.TracID,
      refundAmount: this.refundAmount,
      lastFourDigits: this.selectedTransaction?.AccountNumber?.slice(-4)
    }
    this.transactionService.refundTransaction(data).subscribe(
      (response: any) => {
        this.statusList = response?.data?.statusCounts || []
      },
      (error) => {
        console.error('Error fetching data:', error);
      }
    );
  }

  // onCopyTransaction(item){
  //   this.clipboard.copy
  // }

  onCopyTransaction(item: any) {
    const transactionId = item.TracID;  // Only copy the transactionId

    // Check if the clipboard API is available
    if (navigator.clipboard) {
      navigator.clipboard.writeText(transactionId).then(() => {
        console.log('Transaction ID copied to clipboard:', transactionId);
        alert('Transaction ID copied successfully!');
      }).catch(err => {
        console.error('Failed to copy transaction ID:', err);
      });
    } else {
      console.error('Clipboard API not supported in this browser.');
    }
  }

  async sendInvoice(item: any): Promise<void> {
    try {
      // if (!item?.invoice_id) {
      //   this.commonService.showToast('info', 'Info', 'Invoice ID is missing');
      //   return;
      // }
      const data = {
        source: item?.Source
      }
      const response: any = await this.dynamicInvoiceTemplateService.sendInvoice(item.invoice_id, data); // Await the Promise
      if (response?.Status) { // Check Status with capital S
        this.commonService.showToast('success', 'Success', 'Invoice sent successfully');
      } else {
        this.commonService.showToast('error', 'Error', response?.message || 'Unexpected error occurred');
      }
    } catch (error) {
      this.commonService.showToast('error', 'Error', error.message || 'An error occurred');
    }
  }

}
