<div class="row m-1">
    <div class="col-md-12 d-flex justify-content-end">

        <div class="">
            <select [(ngModel)]="selectedInterval" (change)="onDropdownChange($event)"
                class=" primary mx-xl-2 filter-btn">
                <option value="quarterly">Quarterly</option>
                <option value="semi-annually">Semi-Annually</option>
                <option value="annually">Annually </option>
                <option value="custom">Custom Date Range</option>
            </select>
        </div>
        <div class="" *ngIf="selectedInterval === 'custom'">
            <div class="d-flex  justify-content-end">
                <div class="date-picker" (click)="stop($event)">
                    <div class="date-picker hiringCal round-border d-flex mx-2" style="justify-content: end;"
                        >
                        <div class="f-row d-inline-block position-relative reports-datePicker">
                            <button class="f-row f-a-center px-3 filter-btn round hover-light py-2 date-button">
                                <i class="fa fa-calendar" (click)="drp.toggle()" [attr.aria-expanded]="drp.isOpen"></i>
                                <input [(ngModel)]="bsRangeValue" (ngModelChange)="dataChanged()"
                                    [bsConfig]="{ rangeInputFormat: 'MMM DD, YYYY', adaptivePosition: true, showWeekNumbers: false }"
                                    bsDaterangepicker class="p-2 py-0 text border-0 bg-transparent cursor-pointer"
                                    placeholder="Daterangepicker" type="text" readonly #drp="bsDaterangepicker" />
                                <i class="fa fa-chevron-down" (click)="drp.toggle()"
                                    [attr.aria-expanded]="drp.isOpen"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mb-3">
            <button class="custom-btn-transaction" type="button" data-bs-toggle="tooltip" data-bs-placement="top"
                data-bs-trigger="hover focus" title="View Transactions" (click)="transectionDetail($event)">
                <i class="fas fa-exchange-alt"></i>
            </button>


        </div>
    </div>
    <div class="d-flex flex-wrap w-100">
        <app-card-stats class="col-lg-2 col-sm-4 col-12 mb-0 pl-0" *ngFor="let item of dataArray"
            [data]="item ? item:'-'">
        </app-card-stats>
    </div>
    <div class="col-md-6">
        <app-today-progress [interval]="selectedInterval"
            [advanceSearchValues1]="advanceSearchValues1"></app-today-progress>
        <!-- <app-subscriptions-graph [interval]="selectedInterval"></app-subscriptions-graph> -->
    </div>
    <div class="col-md-6">
        <app-order-overview [interval]="selectedInterval"
            [advanceSearchValues1]="advanceSearchValues1"></app-order-overview>
        <!-- <app-subscription-piechart [interval]="selectedInterval"></app-subscription-piechart> -->
    </div>
</div>
<div class="row m-1">
    <app-subscriptions-list [interval]="selectedInterval"></app-subscriptions-list>
</div>
<router-outlet></router-outlet>