import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { PlanChangeService } from '../plan-change/services/plan-change.service';
import { HttpService } from 'src/app/shared/services/httpService.service';
import { FormBuilder } from '@angular/forms';
import { CommonService } from 'src/app/shared/services/common.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/auth/services/auth.service';
import { fadeInOut } from 'src/app/shared/services/app.animation';

@Component({
  selector: 'app-new-plan-change',
  templateUrl: './new-plan-change.component.html',
  styleUrls: ['./new-plan-change.component.scss'],
  animations:[fadeInOut]
})
export class NewPlanChangeComponent {
  plans: any
  selectedPlanId: any;
  planSelected: boolean;
  isOldNewPlan: boolean;
  isCvvSelected: boolean;
  oldPlanDetails: any;
  paymentType: string
  proratedDetails: any;
  newPlanDetails: any
  isCreditCardSelected: boolean;
  isScheduledDateToday: boolean;
  selectedPlanName: any;
  isPaymentModelOpen: boolean;
  ispaymentProcessable: boolean
  planID:any
  isOpen = false;
  @Input() currentPlanId: any
  curentPlanIdDetailsInSystem:any
isPlanSkeleton: boolean=true;
isPlanDetailSkeleton:boolean=true
  plansList: any=[];
  constructor(
    private planChangeService: PlanChangeService,
    private httpService: HttpService,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private commonService: CommonService,
    private elRef: ElementRef,

    private modalService: NgbModal, private authService: AuthService
  ) {
    // this.footer = true;
  }
  @ViewChild('tabsContainer', { static: false }) tabsContainer: ElementRef;
  @ViewChild('cardsContainer', { static: false }) cardsContainer!: ElementRef;
  @ViewChild('modalTemplate', { static: true }) modalTemplate: ElementRef;
  tabs = [
    // { label: 'Recommended Packs' },
    // { label: 'Data' },
    // { label: 'Unlimited 5G Plans' },
    // { label: 'Entertainment' },
    // { label: 'Truly Unlimited' },
    // { label: 'App exclusive benefits' },
    // { label: 'Talktime (top up voucher)' },
    // { label: 'International Roaming' },
    // { label: 'Other Packs' },
    // { label: 'Plan Vouchers' },
    // { label: 'Inflight Roaming packs' }
  ];
  cards = [];


  activeTabIndex = 0; // Default active tab index
  activeTabIndicatorLeft = 0; // Position of the active tab indicator
  activeTabIndicatorWidth = 0; // Width of the active tab indicator
  billingAddress: any;
  currentPlan: any;
  async ngOnInit() {
    let data = {
      CustomerId: this.httpService.getCustomerId(),
    };
    this.getPlanHEaders()
    const resposne: any =
      await this.planChangeService.getMinimalprofileData(data);
    if (resposne?.Status) {
      this.billingAddress = resposne?.data?.personalInfo?.mailingAddress?.value;
      // this.currentPlan = resposne?.data?.plan?.planDetail?.value
    }
    await this.getPlansDetails();
    this.getPlanTypes();
    await this.getCustomerOtherInfo();

  }



  ngAfterViewInit() {
    this.setActiveTab(this.activeTabIndex); // Set initial active tab
    // this.updateActiveTabOnScroll(); // Update tab on scroll
  }

  setActiveTab(index: number) {
    this.activeTabIndex = index;

    const activeTab = document.querySelectorAll('.wt-tab')[index] as HTMLElement;
    if (activeTab) {
      this.activeTabIndicatorLeft = activeTab.offsetLeft;
      this.activeTabIndicatorWidth = activeTab.offsetWidth;
    }
  }

  goToNextTab() {
    // console.log('next id',id)
    if (this.activeTabIndex < this.tabs.length - 1) {
      // this.setActiveTab(this.activeTabIndex + 1);
      // this.scrollTabs('right');
      // this.sendplanId(id)
    }
  }

  goToPrevTab() {
    if (this.activeTabIndex > 0) {
      // this.setActiveTab(this.activeTabIndex - 1);
      this.scrollTabs('left');
      // this.sendplanId(id)
    }
  }

  scrollTabs(direction: string) {
    const container = this.tabsContainer.nativeElement;
    const scrollAmount = 150;

    if (direction === 'right') {
      container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    } else if (direction === 'left') {
      container.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
    }
  }

  updateActiveTabOnScroll() {
    const cards = this.cardsContainer.nativeElement.getElementsByClassName('recharge-card');
    const containerRect = this.cardsContainer.nativeElement.getBoundingClientRect();

    let currentIndex = -1;

    // Loop through each card to find the one in the viewport
    for (let i = 0; i < cards.length; i++) {
      const cardRect = cards[i].getBoundingClientRect();

      // Check if the card is in the visible area of the cards container
      if (cardRect.top >= containerRect.top && cardRect.bottom <= containerRect.bottom) {
        // Find the corresponding tab index
        currentIndex = this.tabs.findIndex(tab => tab.label === cards[i].getAttribute('data-category'));
        break;
      }
    }

    // If a valid tab index was found and it's different from the current active index, update the active tab
    if (currentIndex !== -1 && currentIndex !== this.activeTabIndex) {
      this.setActiveTab(currentIndex);
    }
  }
  sendplanId(item){
    this.planID = item || this.tabs[0].id;
    console.log('planList before: ', this.plansList);
    if(item == 1){
      this.plans = this.plansList;
      return;
    }
    const filteredPlans = this.plansList.filter((val: any) => 
      val?.planTags?.length > 0 && val?.planTags?.some(tag => tag == item)
    );
    console.log('tabId: ',item);
    console.log('filtered plans: ',filteredPlans);
    console.log('planList after: ', this.plansList);
    this.plans = filteredPlans
  }

  isImageLoaded: boolean = false;
  onImageLoad(card: any) {
    card.isImageLoaded = true; // Mark this specific card's image as loaded
    this.cd.detectChanges();   // Trigger Angular change detection
  }
  
  onImageError(card: any) {
    card.isImageLoaded = false; // Handle failed image loading
    this.cd.detectChanges();
  }
  
  async getPlansDetails() {

    this.commonService.loader = true
    try {
      const data = {
        planType: "Prepaid",
        stateAbbr: this.billingAddress?.state,
        'planAreas[]': ["CSR"],
        // "planTagId":this.planID || this.tabs[0].id,
      };
      // const companyName = this.authService.getCompanyName();
      // if (companyName === "unity") {
      //   delete data['planAreas[]'];
      // }
      const response: any = await this.planChangeService.getPrepaidPlans(data);
      if (response?.Status) {
        this.plansList = response.data;
        this.plansList.forEach(plan => {
          plan.isImageLoaded = false;
        });
        if (this.plansList.length > 0) {
          this.plansList.map(val => {
            if (val?.PlanData == 'Unlimited') {
              val.savingAmount = this.calculateSavings(val)
              val.monthlyAmount = this.calculateMonthlyCost(val)
            }
          })
          console.log(this.plans?.length);
          this.plans = this.plansList
        console.log('planList: ', this.plansList);

        }
        // this.plansDetected = !this.plansDetected;
        // this.loader=false
        this.isPlanSkeleton=false
      } else {
        this.commonService.showToast("error", "Error", response?.message);
        this.isPlanSkeleton=false

      }
      this.commonService.loader = false
    } catch (error) {
      this.commonService.showToast("error", "Error", error?.message);
      this.commonService.loader = false;
      this.isPlanSkeleton=false
    } finally {
      // this.loader = false;
      this.commonService.loader = false
      this.isPlanSkeleton=false
    }
  }

  getPlanTypes() {
    this.planChangeService.getPlanTypes().then((res: any) => {
      if (res.Status) {

      }
    }).catch(err => {

    })
  }
  getPlanHEaders() {
    this.planChangeService.getplanHeaders().subscribe((res: any) => {
      if (res.Status) {
        // this.tabs = res.data
        const descriptionsArray = res.data.data.map((item: any) => item);
     console.log("tabs data ",descriptionsArray)
        this.tabs = descriptionsArray
      }
    })
  }
  getSelectedPlanId(planId: any) {
    this.planSelected = true
    this.selectedPlanId = planId
  }

  async getCustomerOtherInfo(){
    try {
      const customerdetails:any = await this.planChangeService.getCustomerOtherInfo();
      if(customerdetails?.Status){
        const planId = customerdetails?.data[0]?.PlanID;
        console.log('planId: ', planId);
        await this.getCurentPlanIdDetailsInSystem(planId);
      }
    } catch (error) {
      
    }
  }

  handleCardClick(planId: number, event: any) {
    this.isPlanDetailSkeleton=true;
    if (this.currentPlan?.PlanId !== planId) {
      this.openDiv();
      this.getSelectedPlanId(planId);
      this.onPlanSelected(event);
    } else {
      // Add blinking effect
      const cardElement = event.currentTarget;
      cardElement.classList.add('blink-border');
      setTimeout(() => {
        cardElement.classList.remove('blink-border');
      }, 2000); // Remove after 2 seconds (2 blinks)
  
      this.commonService.showToast('info', 'Info', 'You are already on the current plan');
    }
  }
  
  
  async getCurentPlanIdDetailsInSystem(planId){
    try {
      const params = {
        planId: planId,
        CustomerId: this.httpService.getCustomerId()
      }
      
      console.log('params: ', params);
      const planDetailsResponse: any = await this.planChangeService.getPlansDetails(params);
      if (planDetailsResponse?.Status) {
        this.curentPlanIdDetailsInSystem = planDetailsResponse?.data;
        console.log('curentPlanIdDetailsInSystem: ',this.curentPlanIdDetailsInSystem);
        const currentPlanDetail = {
          PlanId: planId,
          Name: this.curentPlanIdDetailsInSystem?.plan?.DisplayName || "N/A",
          Price: this.curentPlanIdDetailsInSystem?.planDetail?.MonthlyPaymentAmount || "0.00",
          PlanData: this.curentPlanIdDetailsInSystem?.planDetail?.PlanData || "N/A",
          ExpirationDays: this.curentPlanIdDetailsInSystem?.planDetail?.ExpirationDays || 0,
          VoiceText: this.curentPlanIdDetailsInSystem?.planDetail?.VoiceText || "N/A",
          savingAmount: '', // Default as empty
          ImapgePath: this.curentPlanIdDetailsInSystem?.planDetail?.ImapgePath || "N/A",
          isImageLoaded: true 
        };
        // const currentPlanDetail = this.curentPlanIdDetailsInSystem?.planDetail;
        const isPlanInList = this.plansList.some(plan => plan?.PlanId === planId);
        const planIndex = this.plansList.findIndex(plan => plan?.PlanId === planId);
      // if (!isPlanInList && currentPlanDetail) {
      //   // Add planId to the currentPlanDetail
      //   currentPlanDetail.PlanId = planId; // Add planId to the planDetail object
      //   this.plansList.push(currentPlanDetail); // Push to plansList
      // }
      if (planIndex > -1) {
        // If plan exists, mark it as currentPlan
        this.currentPlan = this.plansList[planIndex];
      } else {
        // If plan doesn't exist, add it to the top
        this.plansList.unshift(currentPlanDetail);
        this.currentPlan = currentPlanDetail;
      }


      // Set the current plan
      this.currentPlan = currentPlanDetail;
      }else{
        this.commonService.showToast('error','Error',planDetailsResponse?.message)
      }
    } catch (error) {
      this.commonService.showToast('error','Error',error?.message)
    }
  }
  async onPlanSelected(event: any) {
 
    this.planSelected = true;
    this.isOldNewPlan = false
    this.commonService.loader = true
    this.isCreditCardSelected = false
    this.isCvvSelected = false
    try {
      const params = {
        planId: this.currentPlanId,
        CustomerId: this.httpService.getCustomerId()
      }
      const planDetailsResponse: any = await this.planChangeService.getPlansDetails(params);

      if (planDetailsResponse?.Status) {
        this.oldPlanDetails = planDetailsResponse?.data;
        const proratedParams = {
          CustomerId: this.httpService.getCustomerId(),
          planId: this.selectedPlanId
        };
        const proratedResponse: any = await this.planChangeService.getProratedPlanCost(proratedParams);
        
        if (proratedResponse?.Status) {
          this.proratedDetails = proratedResponse?.data;
          this.getPaymentMethod()
          const newPlanParams = { planId: this.proratedDetails?.newPlanId };
          const newPlanDetailsResponse: any = await this.planChangeService.getPlansDetails(newPlanParams);
          this.isEffectiveDateToday()
          if (newPlanDetailsResponse?.Status) {
            this.newPlanDetails = newPlanDetailsResponse?.data;
            this.selectedPlanName = newPlanDetailsResponse?.data?.plan?.Name
          }
          // this.loader = false;
          if (proratedResponse?.Status && proratedResponse?.data?.changeType == 'SamePlan') {
            this.commonService.showToast('info', 'Info', 'You Already Have This Plan')
          }
        this.isPlanDetailSkeleton=false;
        }
        this.commonService.loader = false
        this.isPlanDetailSkeleton=false
      }
    } catch (error) {
      this.commonService.showToast("error", "Error", error?.message);
      this.commonService.loader = false
      this.isPlanDetailSkeleton=false
    } finally {
      // this.loader = false;
      this.commonService.loader = false
      this.isPlanDetailSkeleton=false
    }
  }
  async getPaymentMethod() {
    await this.isEffectiveDateToday()
    if (this.proratedDetails?.changeType == 'Upgrade' || this.proratedDetails?.changeType == "SamePlan" && this.isEffectiveDateToday) {
      this.paymentType = 'Immediate'
    } else {
      this.paymentType = 'Scheduled'
    }
    return ''
  }
  isEffectiveDateToday(): boolean {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (this.proratedDetails?.effectiveDate) {
      const effectiveDate = new Date(this.proratedDetails.effectiveDate);
      effectiveDate.setHours(0, 0, 0, 0);
      this.isScheduledDateToday = effectiveDate.getTime() === today.getTime();
      return this.isScheduledDateToday;
    }
    this.isScheduledDateToday = false;
    return this.isScheduledDateToday;
  }

  openDiv() {
    this.isOpen = true;
  }
  closeDiv() {
    this.isOpen = false;
  }
  handleCheckOut(modalTemplate: any) {
    this.isPaymentModelOpen = true
    this.ispaymentProcessable = true
  }

  calculateMonthlyCost(item): number {
    const totalPrice = parseFloat(item?.Price);
    if (item?.ExpirationDays > 0) {
      const averageMonthlyCost = (totalPrice / (item?.ExpirationDays == 365 ? 360 : item?.ExpirationDays)) * 30;  // Assume 30 days per month
      return parseFloat(averageMonthlyCost.toFixed(2));
    }
    return 0;
  }

  // Calculate the savings
  calculateSavings(item): any {
    const totalPrice = parseFloat(item?.Price);
    const baseAmount = parseFloat(item?.MonthlyPaymentAmount);

    // const saving = item?.MonthlyPaymentAmount / 30 * (item?.ExpirationDays == 365 ? 360 : item?.ExpirationDays) - item?.Price;

    if (item?.ExpirationDays > 0) {
      const averageMonthlyCost = ((item?.ExpirationDays == 365 ? 360 : item?.ExpirationDays) / 30) * baseAmount - totalPrice;  // Assume 30 days per month
      return parseFloat(averageMonthlyCost.toFixed(2));
    }
    return 0
  }

  onIsPaymentModelOpenChange(newValue: boolean) {
    this.isPaymentModelOpen = newValue;
  }

  async isTransactionStatus(){
    this.isOpen = false;   // Close the slide-in div
    this.isPaymentModelOpen = false;
    let data = {
      CustomerId: this.httpService.getCustomerId(),
    };
    
    const resposne: any =await this.planChangeService.getMinimalprofileData(data);
      if(resposne?.Status){
        // this.currentPlan=resposne?.data?.plan?.planDetail?.value
      }
  }
}
