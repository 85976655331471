import { Component } from '@angular/core';

@Component({
  selector: 'app-adhoc-invoice',
  templateUrl: './adhoc-invoice.component.html',
  styleUrls: ['./adhoc-invoice.component.scss']
})
export class AdhocInvoiceComponent {

}
