<div class="adhoc-breakup-container animate-fade-in mt-2">
  <div class="d-flex flex-column justify-content-center align-items-center" *ngIf="isSkeletonLoader" @fadeInOut>
    <div class="div" style="width: 100%;">
      <app-skeleton-loader shape="credit-card"></app-skeleton-loader>

    </div>
    <div class="div" style="width: 100%;">
    <app-skeleton-loader [tableRow]="7" [tableColumn]="1" shape="table"></app-skeleton-loader>
      
    </div>
  </div>
  <!-- Image Section -->
  <div class="plan-image-container"  *ngIf="!isSkeletonLoader" @fadeInOut>
    <img
      [src]="adhocDetails?.planDetails?.planDetail?.ImapgePath || 'assets/default-image.png'"
      alt="Plan Image"
      class="plan-image"
    />
  </div>

  <!-- Toggle Icon and Section Title -->
  <div class="toggle-section"  *ngIf="!isSkeletonLoader" @fadeInOut>
    <h3 class="section-title text-primary">
      {{ showPlanDetails ? 'Plan Details' : 'Price Breakup' }}
    </h3>
    <div class="toggle-icon" (click)="toggleDetails()" matTooltip="Toggle between plan details and price breakup">
      <i [class]="showPlanDetails ? 'fas fa-tags' : 'fas fa-info-circle'"></i>
    </div>
  </div>

  <!-- Combined Content -->
  <div class="combined-details-section"  *ngIf="!isSkeletonLoader" @fadeInOut>
    <!-- Plan Details Section -->
    <div class="plan-details-section" *ngIf="showPlanDetails">
      <ul class="details-list">
        <li>
          <i class="fas fa-tag icon" matTooltip="Plan Name"></i>
          <strong>{{ adhocDetails?.planDetails?.plan?.Name || '-' }}</strong>
        </li>
        <li>
          <i class="fas fa-calendar icon" matTooltip="Expiration Period"></i>
          <strong>{{ adhocDetails?.planDetails?.planDetail?.ExpirationDays || '-' }} days</strong>
        </li>
        <li>
          <i class="fas fa-wifi icon" matTooltip="Hotspot Availability"></i>
          <strong>{{ adhocDetails?.planDetails?.planDetail?.MobileHotspot || '-' }}</strong>
        </li>
        <li>
          <i class="fas fa-phone-alt icon" matTooltip="Phone Compatibility"></i>
          <strong>{{ adhocDetails?.planDetails?.planDetail?.PhoneCompatibility || '-' }}</strong>
        </li>
        <li>
          <i class="fas fa-database icon" matTooltip="Data Plan"></i>
          <strong>{{ adhocDetails?.planDetails?.planDetail?.PlanData || '-' }}</strong>
        </li>
        <li>
          <i class="fas fa-globe icon" matTooltip="Supported Countries"></i>
          <strong>{{ adhocDetails?.planDetails?.planDetail?.SupportedCountries || '-' }}</strong>
        </li>
        <li>
          <i class="fas fa-comment-dollar icon" matTooltip="Activation Fee Details"></i>
          <strong>{{ adhocDetails?.planDetails?.planDetail?.ActivationFeeMsg || '-' }}</strong>
        </li>
      </ul>
    </div>

    <!-- Price Breakup Section -->
    <div class="price-breakup-section" *ngIf="!showPlanDetails">
      <ul class="details-list">
        <li>
          <i class="fas fa-dollar-sign icon" matTooltip="Plan Price"></i>
          <strong>{{ adhocDetails?.planDetails?.plan?.Price || '-' }}</strong>
        </li>
        
        <li>
          <i class="fas fa-percent icon" matTooltip="Tax Details"></i>
          <strong>{{ adhocDetails?.planDetails?.plan?.TaxIncluded ? 'Tax Included' : 'Tax Excluded' }}</strong>
        </li>

        <li>
          <i class="fas fa-info-circle icon" matTooltip="Additional Details"></i>
          <strong>{{ adhocDetails?.planDetails?.planDetail?.ActivationFeeMsg || '-' }}</strong>
        </li>
      </ul>
    </div>
  </div>
  
</div>


