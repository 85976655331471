import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AdhocStateService } from '../shared/adhoc-state.service';

@Component({
  selector: 'app-adhoc-generated',
  templateUrl: './adhoc-generated.component.html',
  styleUrls: ['./adhoc-generated.component.scss']
})
export class AdhocGeneratedComponent {
  constructor(public activeModal: NgbActiveModal, private stateService:AdhocStateService) {}

  closeSuccess(): void {
    this.activeModal.close();
    this.stateService.setActiveTab(0);;
  }
}
