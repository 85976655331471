<div class="col-sm-12 px-0">
    <div class="card p-10">
        <div class="filter-head">
            <div class="row align-items-center">
                <div class="col-auto d-flex overflow-auto">
                    <div class="d-lg-none d-flex ">
                        <button type="button" class="custom-btn text-dark  mr-2" [class.active]="selectedFilter === 'All'"
                            (click)="selectFilter('All')">All <span class="count ml-1 px-2">({{ getTotalForFilter('All')
                                || ''}})</span></button>
                        <button type="button" class="custom-btn  mx-2 text-dark" [class.active]="selectedFilter === 'Active'"
                            (click)="selectFilter('Active')">Scheduled <span class="count ml-1 px-2">( {{
                                getTotalForFilter('Active') || '0'}})</span></button>
                        <button type="button" class="custom-btn  mx-2" [class.active]="selectedFilter === 'Pending'"
                            (click)="selectFilter('Pending')">Pending <span class="count ml-1 px-2"> ({{
                                getTotalForFilter('Pending') || '0'}})</span></button>
                        <button type="button" class="custom-btn  mx-2" [class.active]="selectedFilter === 'Cancelled'"
                            (click)="selectFilter('Cancelled')">Cancelled <span class="count ml-1 px-2">( {{
                                getTotalForFilter('Cancelled') || '0'}})</span></button>

                        <button type="button" class="custom-btn " [class.active]="selectedFilter === 'noSubscription'"
                            (click)="noSubscriptions('Plan')" (click)="selectFilter('Plan')">No Subscriptions <span
                                class="count ml-1 px-2">( {{ getTotalForFilter('Plan') || '0'}})</span></button>
                        <button type="button" class="custom-btn text-dark   ml-2  bg-muted loadLive "
                            [class.active]="selectedFilter === 'LoadLive'" (click)="loadLiveSubscriptions()">Load Live
                            Subscriptions <span class="count ml-1 px-2"> {{ getTotalForFilter('LoadLive') ||
                                ''}}  <span class="live-icon"></span></span> </button>

                    </div>
                </div>
                <div class="col d-flex search-section justify-content-end px-0">
                    <div class="d-flex search-filter align-items-center">
                        <input #searchInput type="text" placeholder="Search..." [(ngModel)]="searchQuery"
                            (input)="filterData($event)" (blur)="onFocusOut()" (keypress)="onSearchKeyPress($event)"
                            *ngIf="isSearchOpen" class="form-control">
                        <i class="fa fa-search " (click)="toggleSearch()"></i>
                    </div>
                    <!-- <div class="ml-2 margin-r mt-2 ">
                        <ng-select [(ngModel)]="selectedTypeFilter" (change)="selectFilter(selectedTypeFilter)"
                            placeholder="Search Filter Type" class="searchFilter mx-2" >
                            <ng-option *ngFor="let item of subscriptionTypeFilter" [value]="item.value" class="mx-2">
                                {{ item.DisplayName }}
                            </ng-option>
                        </ng-select>
              
                    </div>
                    <div class="ml-2 mt-2" *ngIf="isloadLiveSubscription">
                        <ng-select [(ngModel)]="selectedSearchFilter" (change)="filterReportMapping()"
                            (clear)="filterReportMapping()" placeholder="Search Filter Type" class="searchFilter"
                            style="width: 300px;">
                            <ng-option *ngFor="let item of searchFilter" [value]="item">{{item.DisplayName}}</ng-option>
                        </ng-select>
                    </div> -->
                    <div>

                        

                        <button class="custom-button" (click)="downloadcsv(1)">
                            <div class="d-flex gap-2 align-items-center">
                              <i class="fa fa-cloud-upload"></i>
                              <span class="text">Export</span>
                            </div>
                          </button>

                    </div>

                </div>
            </div>

        </div>
        <div class="table-responsive table-hover table-striped table-card"
            *ngIf="!isloadLiveSubscription && !isSubscription && !isSkeletonLoader" @fadeInOut>
            <table class="table table-nowrap mb-0">
                <thead class="table-light">
                    <tr>
                        <th *ngFor="let header of headers">
                            {{ header.display }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of subscriptionList; let i = index" class="cursor-pointer">
                        <td *ngFor="let header of headers"
                            (click)="header.key == 'SubscriptionId' ? detail(item) : null">
                            <ng-container [ngSwitch]="header.key">
                                <ng-container *ngSwitchCase="'action'">
                                    <div class="position-relative">
                                        <i class="fa fa-ellipsis-h py-2 px-4" (click)="toggleDropdown(i)"></i>
                                        <div class="dropdown-content1 text-start" *ngIf="dropdownVisible.has(i)">
                                            <div class="dropdown-item1" (click)="detail(item)">
                                                <span> <i class="fas fa-info-circle text-info mr-2"></i> Details</span>
                                            </div>
                                            <div class="dropdown-item1" *ngIf="item.Status !=='Cancelled'"
                                                (click)="withConfirmation(item)">
                                                <span> <i class="fas fa-times text-danger mr-2"></i>Cancel</span>
                                            </div>

                                        </div>
                                    </div>

                                </ng-container>
                                <ng-container *ngSwitchCase="'Status'">
                                    <!-- Add badge for status -->
                                    <span class="custom-badge" [ngClass]="{
                                        ' badge-success': item.Status == 'Active',
                                        ' badge-danger': item.Status == 'Cancelled',
                                        ' badge-warning': item.Status == 'Pending'
                                    }">
                                        {{ item.Status }}
                                    </span>
                                </ng-container>
                                <div *ngSwitchCase="'CustomerId'" class="position-relative">
                                    <div class="d-flex align-items-center gap-2 customer-cell"
                                        (click)="toggleDropdown1(i, item)">
                                        <span>{{ item?.FirstName + " " + item?.LastName }}</span>
                                        <i class="fa fa-chevron-down chevron-icon"></i>
                                    </div>
                                    <div class="dropdown-content"
                                        [ngClass]="{'d-block': dropdownVisible1.has(i), 'd-none': !dropdownVisible1.has(i)}">
                                        <div class="customer-card d-flex flex-column Polaris-Box gap-2">
                                            <span class="text-end">
                                                <i class="fa fa-times" aria-hidden="true" style="cursor: pointer;" (click)="closeDropdown(i, 'dropdownVisible1')"></i>
                                              </span>
                                            <div class="d-flex justify-content-between align-item-center">
                                                <span class="text-dark fw-semibold customerinfo-text">{{
                                                    customerDetails?.personalInfo?.firstName?.value + " " +
                                                    customerDetails?.personalInfo?.lastName?.value }}</span>
                                                <span class="text-success custom-badge">{{
                                                    customerDetails?.personalInfo?.accountStatus?.value
                                                    }}</span>
                                            </div>
                                            <span class="plan-price customerinfo-text">{{
                                                customerDetails?.personalInfo?.serviceAddress?.value?.city + ", " +
                                                customerDetails?.personalInfo?.serviceAddress?.value?.state }}</span>

                                            <div class="customer-address">
                                                <span class="customerinfo-text">{{
                                                    customerDetails?.personalInfo?.serviceAddress?.value?.address1
                                                    }}</span>
                                                <span class="customerinfo-text">{{
                                                    customerDetails?.personalInfo?.serviceAddress?.value?.city
                                                    + ", " +
                                                    customerDetails?.personalInfo?.serviceAddress?.value?.state + ", " +
                                                    customerDetails?.personalInfo?.serviceAddress?.value?.zipCode
                                                    }}</span>
                                            </div>

                                            <div class="customer-plan d-flex flex-column">
                                                <span class="plan-name customerinfo-text">{{
                                                    customerDetails?.plan?.planName?.value }}</span>
                                                <span class="plan-price customerinfo-text">${{
                                                    customerDetails?.plan?.planPrice?.value }} per
                                                    month</span>
                                            </div>

                                            <div class="customer-contact">
                                                <span class="customer-email customerinfo-text">{{
                                                    customerDetails?.personalInfo?.email?.value
                                                    }}</span>
                                                <span class="plan-price customerinfo-text">{{
                                                    customerDetails?.personalInfo?.phone?.value
                                                    }}</span>
                                            </div>

                                            <button class="view-company-btn"
                                                (click)="sendToCustomerDashboard(item.CustomerId)">View
                                                Details</button>
                                        </div>
                                    </div>
                                </div>
                                <span *ngSwitchDefault [ngClass]="{'underline-text': header.key == 'SubscriptionId'}">
                                    {{ getItemValue(item, header.key) }}
                                </span>
                                <span *ngSwitchCase="'StartDate'" class="text-nowrap">
                                    {{ item[header.key] | date }}
                                </span>

                                <span *ngSwitchCase="'plan.Price'" class="text-nowrap">
                                    ${{ item?.plan?.Price }}
                                </span>
                            </ng-container>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="d-flex justify-content-center p-3" *ngIf="subscriptionList?.length == 0">
                <span class="text-muted">
                    No data found
                </span>
            </div>
        </div>
        <div *ngIf="isSkeletonLoader" @fadeInOut>
            <app-skeleton-loader shape="table" [tableColumn]="8" [tableRow]="10"></app-skeleton-loader>

        </div>
        <div class="table-responsive table-hover table-striped table-card" *ngIf="isloadLiveSubscription">
            <table class="table table-nowrap mb-0">
                <thead class="table-light">
                    <tr>
                        <th *ngFor="let header of liveLoadcolumnDefs">
                            {{ header.headerName }} <!-- Adjust according to your header property -->
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of loadLiveSubscriptionList; let i = index" class="cursor-pointer">
                        <td *ngFor="let header of liveLoadcolumnDefs">
                            <ng-container [ngSwitch]="header.field">
                                <ng-container *ngSwitchCase="'status'">
                                    <!-- Add badge for status -->
                                    <span [ngClass]="{
                                        'badge badge-light-success': item.status === 'active',
                                        'badge badge-light-danger': item.Status === 'Cancelled',
                                        'badge badge-light-warning': item.Status === 'Pending'
                                    }">
                                        {{ item.status }}
                                    </span>
                                </ng-container>
                                <span *ngSwitchDefault
                                    [ngClass]="{'underline-text': header.field === 'SubscriptionId'}">
                                    {{ getItemValue(item, header.field) }}
                                    <!-- Adjust based on how you retrieve item values -->
                                </span>
                                <span *ngSwitchCase="'createTimeStampUTC'" class="text-nowrap">
                                    {{ item[header.field] | date: 'MMM d, y, h:mm a' }}
                                </span>
                            </ng-container>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="d-flex justify-content-center p-3" *ngIf="loadLiveSubscriptionList?.length == 0">
                <span class="text-muted">
                    No data found
                </span>
            </div>
        </div>
        <div class="table-responsive table-hover table-striped table-card" *ngIf="isSubscription">
            <table class="table table-nowrap mb-0">
                <thead class="table-light">
                    <tr>
                        <th *ngFor="let header of columnDefs2">
                            {{ header.headerName }} <!-- Adjust according to your header property -->
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of nosubscriptionList; let i = index" class="cursor-pointer">
                        <td *ngFor="let header of columnDefs2">
                            <ng-container [ngSwitch]="header.field">

                                <ng-container *ngSwitchCase="'CustomerStatus'">
                                    <!-- Add badge for status -->
                                    <span [ngClass]="{
                                        'badge badge-light-success': item.CustomerStatus === 'Active',
                                        'badge badge-light-danger': item.CustomerStatus === 'Deactivated',
                                        'badge badge-light-warning': item.CustomerStatus === 'New'
                                    }">
                                        {{ item.CustomerStatus }}
                                    </span>
                                </ng-container>
                                <span *ngSwitchDefault
                                    [ngClass]="{'underline-text': header.field === 'SubscriptionId'}">
                                    {{ getItemValue(item, header.field) }}
                                    <!-- Adjust based on how you retrieve item values -->
                                </span>
                                <span *ngSwitchCase="'CreatedDate'" class="text-nowrap">
                                    {{ item[header.field] | date }}
                                </span>

                            </ng-container>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="d-flex justify-content-center p-3" *ngIf="nosubscriptionList?.length == 0">
                <span class="text-muted">
                    No data found
                </span>
            </div>
        </div>
        <div class="pagination-container" [class.hidepagi]="selectedFilter === 'noSubscription'" *ngIf="!isloadLiveSubscription ">
            <ngb-pagination 
                [directionLinks]="true" 
                [boundaryLinks]="true" 
                [collectionSize]="total"
                [(page)]="currentPage" 
                (pageChange)="pageChange($event)"
                [pageSize]="itemsPerPage"
                [maxSize]="maxPaginationSize"
                class="custom-pagination">
                <ng-template ngbPaginationPrevious>Previous</ng-template>
                <ng-template ngbPaginationNext>Next</ng-template>
            </ngb-pagination>
        </div>
        <div class="pagination-container" *ngIf="selectedFilter =='noSubscription'">
            <ngb-pagination 
                [directionLinks]="true" 
                [boundaryLinks]="true" 
                [collectionSize]="totalItems"
                [(page)]="currentPage" 
                (pageChange)="pageChange($event)"
                [pageSize]="itemsPerPage"
                [maxSize]="maxPaginationSize"
                class="custom-pagination">
                <ng-template ngbPaginationPrevious>Previous</ng-template>
                <ng-template ngbPaginationNext>Next</ng-template>
            </ngb-pagination>
        </div>
        
    </div>
</div>

<!-- <div class="w-100" *ngIf="showManageUser && editData">
    <app-detailed-subscription [existingData]="editData"></app-detailed-subscription>
</div> -->
<div class="w-100" *ngIf="editData">
    <app-subscription-details [existingData]="editData"></app-subscription-details>
</div>

<div class="w-100" *ngIf="isDisplayTrasection">
    <app-transection-details></app-transection-details>
</div>