<div class="container">
  <div class="row custom-card">
    <div class="col card-header">
      <div class="d-grid ">
        <h4 routerLink="/manage-agent" style="cursor: pointer;"><strong> <i
              class="fa-solid fa-arrow-left mt-1 mx-2"></i>Agent Permission</strong></h4>
      </div>
    </div>

    <div class="col-md-12 mt-3">
      <label>Agents</label>
      <ng-select [(ngModel)]="seletedAgent" (change)="getAllPermissions($event)">
        <ng-option *ngFor="let item of filteredAgentList" [value]="item.UserID">
          {{ item?.name }} (User Id - {{ item.UserID }} - {{ item.email }})
        </ng-option>
      </ng-select>
    </div>
    <div class="col-md-6 my-2">
      <label>Categeory</label>
      <ng-select [(ngModel)]="selectedCar1" (change)="onSelectCar1()" (clear)="onClearSelection1()">
        <ng-option *ngFor="let item of profileData" [value]="item.id">{{item.DisplayName}}</ng-option>
      </ng-select>
    </div>
    <div class="col-md-6 my-2" *ngIf="selectedCar1">
      <label>Type</label>
      <ng-select [(ngModel)]="selectedCar2" (change)="onSelectCar2()" (clear)="onClearSelection1()">
        <ng-option *ngFor="let item of resource_types" [value]="item.id">{{item.DisplayName}}</ng-option>
      </ng-select>
    </div>
  </div>
  <div class="row custom-card mt-3">
    <div class="col-md-12">
      <div class="mt-5">
        <h4 class="mt-4 mx-4">Permissions</h4>
        <div class="card-body" *ngIf="resources.length > 0">
          <div class="table-responsive tableFixHead">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Permissions</th>
                  <th scope="col">View</th>
                  <th scope="col">Edit</th>
                  <th scope="col">Delete</th>
                </tr>
              </thead>
              <tbody>
                <!-- Parent Row -->
                <ng-container *ngFor="let item of resources;">
                  <tr>
                    <td class="cursor-pointer" data-toggle="tooltip" data-placement="top" title="{{item?.Description}}"
                      (click)="toggleChildren(item)">
                      <span [class]="item.children?.length ? 'expand-icon' : ''">
                        {{ item.children?.length ? (item.expanded ? '▼' : '▶') : '' }}
                      </span>
                      {{ item?.DisplayName }}
                    </td>
                    <td>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox"
                          [checked]="item.resource_permission[0]?.CanView || false"
                          (change)="onCheckboxChange(item, 'CanView', $event)" id="readCheckbox">
                      </div>
                    </td>
                    <td>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox"
                          [checked]="item.resource_permission[0]?.CanEdit || false"
                          (change)="onCheckboxChange(item, 'CanEdit', $event)" id="editCheckbox">
                      </div>
                    </td>
                    <td>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox"
                          [checked]="item.resource_permission[0]?.CanDelete || false"
                          (change)="onCheckboxChange(item, 'CanDelete', $event)" id="deleteCheckbox">
                      </div>
                    </td>
                  </tr>
            
                  <!-- Child Rows -->
                  <ng-container *ngIf="item.expanded">
                    <tr *ngFor="let child of item.children">
                      <td class="pl-4 cursor-pointer" data-toggle="tooltip" data-placement="top"
                        title="{{child?.Description}}">
                        -- {{child?.DisplayName}}
                      </td>
                      <td>
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox"
                            [checked]="child.resource_permission[0]?.CanView || false"
                            (change)="onCheckboxChange(child, 'CanView', $event)" id="readCheckboxChild">
                        </div>
                      </td>
                      <td>
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox"
                            [checked]="child.resource_permission[0]?.CanEdit || false"
                            (change)="onCheckboxChange(child, 'CanEdit', $event)" id="editCheckboxChild">
                        </div>
                      </td>
                      <td>
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox"
                            [checked]="child.resource_permission[0]?.CanDelete || false"
                            (change)="onCheckboxChange(child, 'CanDelete', $event)" id="deleteCheckboxChild">
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                </ng-container>
              </tbody>
            </table>
            
            
            
          </div>
        </div>
        <div class="text-danger text-center" *ngIf="resources?.length == 0">
          <img src="https://cdn.pixabay.com/photo/2022/08/30/09/15/permission-7420699_1280.png" alt="" width="180px">
        </div>
      </div>
    </div>
  </div>

</div>