<div class="container-fluid p-0 m-0  " style="max-width:100% !important;" *ngIf="!isSkeletonLoader" @fadeInOut>
  <div class="row px-0 mx-0 w-100 container-fluid" style="max-width:100%;">
    <div class="col-12 col-md-5 order-md-2 p-0 section m-0">
      <div class="container mt-6">
        <div class="plan-card mt-4" style="background-color: transparent">


          <!-- Display Form Data in Divs -->
          <div class=" p-4" style="background-color: transparent">
            <div class="d-flex justify-content-between align-items-center mb-3 ml-0">
              <div>
                <p class="plan-title text-white" style="white-space:nowrap" *ngIf="planChange">{{
                  selectedPlanOrTopUp?.planName || 'Select a Plan'}}</p>
                <p class="plan-title text-white" style="white-space:nowrap" *ngIf="!planChange">{{
                  selectedPlanOrTopUp?.planName || 'Select add-top-up'}}</p>
                <p class="text-muted">Order  and Details</p>
              </div>
              <!-- Plan Price -->
              <div class="ml-auto d-flex align-items-center flex-wrap justify-content-end">
                <small class="plan-price mb-0">
                  ${{ formData.get('amount')?.value || '0' }}
                </small>
                <span *ngIf="taxIncluded" class="text-muted ml-1 text-nowrap" style="font-size: 0.9rem;">(Tax
                  Included)</span>
              </div>

            </div>
            <ul class="list-group">
              <!-- Processing Fee Item -->
              <li class="list-group-item d-flex justify-content-between align-items-center">
                Processing Fee
                <span>${{ formData.get('processingFee')?.value || '0' }}</span>
              </li>

              <!-- Tax Amount Item -->
              <li class="list-group-item d-flex justify-content-between align-items-center">
                Tax Amount
                <span>${{ formData.get('taxAmount')?.value || '0' }}</span>
              </li>

              <!-- Total Amount Item -->
              <li class="list-group-item d-flex justify-content-between align-items-center total-summary">
                <strong> Amount</strong>
                <strong>${{ formData.get('amount')?.value || '0' }}</strong>
              </li>
            </ul>
          </div>





          <hr class="text-white">


          <div class="d-flex justify-content-between font-weight-bold mt-3 mx-5 text-dark">
            <div class="text-white">Payable Amount:</div>
            <div class="text-white"> ${{ totalAmount | number:'1.2-2' || '0.00' }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-7 order-md-1 section p-0">
      <div class="tab-content mt-3">

        <div class="container mt-4">
          <div class="row">

            <div class="col-md-12">
              <div class="col-md-12 justify-content-center">

                <div class="col-md-12 p-2 d-flex justify-content-between">

                  <div class="col-6">
                    <ng-container *ngIf="!planChange">
                      <h4 class="text-white mb-1">Add Top Up</h4>
                    </ng-container>
                    <ng-container *ngIf="planChange">
                      <h4 class="text-white mb-1">Plans</h4>
                    </ng-container>
                    <p class="text-muted">All transactions are secure and encrypted.</p>
                  </div>
                  <div class="col-6">
                    <form [formGroup]="formData" class="text-center w-100">
                      <div class="form-group row d-flex justify-content-center align-items-center">
                        <div class="col-md-9">
                          <!-- Dropdown with dynamic placeholder -->
                          <select class="form-select" formControlName="topUpID" (change)="onSelectAmount()"
                            [style.color]="formData.get('topUpID').value ? 'white' : 'white'"
                            [style.backgroundColor]="formData.get('topUpID').value ? 'transparent' : 'transparent'"
                            style=" height: 3em;">
                            <!-- Placeholder based on planChange -->
                            <ng-container *ngIf="!planChange">
                              <option value="" disabled selected class="text-white text-start mb-3 text-nowrap px-1">
                                <strong>
                                  <h6>Select Top Up</h6>
                                </strong></option>
                            </ng-container>
                            <ng-container *ngIf="planChange">
                              <option value="" disabled selected class="text-white text-start mb-3 text-nowrap px-1">
                                <strong>Select Plan</strong></option>
                            </ng-container>

                            <!-- Dropdown options for when planChange is true -->
                            <ng-container *ngIf="planChange">
                              <option *ngFor="let plan of prepaidPlans" [value]="plan.id"
                                [selected]="formData.get('topUpID').value === plan.id">
                                {{ plan?.Name }}
                              </option>
                            </ng-container>

                            <!-- Dropdown options for when planChange is false -->
                            <ng-container *ngIf="!planChange">
                              <ng-container *ngFor="let group of categorizedTopUps">
                                <!-- Category Title -->
                                <option disabled class="category-title bg-primary text-center">
                                  {{ group.category }}
                                </option>
                                <!-- Top-ups in this Category -->
                                <option *ngFor="let topUp of group.topUps" [value]="topUp.planId"
                                  [selected]="formData.get('topUpID').value === topUp.planId">
                                  {{ topUp.planName }}
                                </option>
                              </ng-container>
                            </ng-container>
                          </select>
                        </div>
                      </div>
                    </form>

                  </div>
                </div>

                <div class=" p-0 pb-2 rounded d-flex flex-column justify-content-center">


                  <form [formGroup]="formData" class="mt-3 custom-form-width d-flex flex-md-column flex-sm-column ">

                    <div class="col-md-12 ">




                      <ng-container *ngIf="toupTypeData ==='Y'">
                        <div class="form-group">
                          <div class="d-flex justify-content-between align-items-center  rounded p-3  mb-3">
                            <h5 class="m-0 text-white" style="letter-spacing: 2px;">Address Details</h5>
                            <div class="credit-card-icons d-flex">
                              <!-- <img src="../../../../../assets/images/credit-cards/card.png" alt="master-card" class="credit-card-icon" [ngClass]="{'disabled': !isCardTypeValid('MasterCard')}">
                                                          <img src="../../../../../assets/images/credit-cards/american-express.png" alt="amex" class="credit-card-icon" [ngClass]="{'disabled': !isCardTypeValid('Amex')}">
                                                          <img src="../../../../../assets/images/credit-cards/visa.png" alt="visa" class="credit-card-icon" [ngClass]="{'disabled': !isCardTypeValid('Visa')}"> -->
                            </div>
                          </div>
                          <input id="addressInput" formControlName="address1" class="form-control"
                            [placeholder]="getPlaceholder('address1')" [ngClass]="{
                                                      'is-invalid': formData.get('address1')?.touched && formData.get('address1')?.invalid,
                                                      'error-placeholder': formData.get('address1')?.touched && formData.get('address1')?.invalid
                                                    }">
                        </div>
                        <div class="form-group">
                          <input id="address2" formControlName="address2" class="form-control"
                            placeholder="Apartment, suite, etc. (optional)">
                        </div>
                        <div class="form-group row ">
                          <div class="col-md-6">
                            <input id="city" formControlName="city" class="form-control"
                              [placeholder]="getPlaceholder('city')" [ngClass]="{
                                                      'is-invalid': formData.get('city')?.touched && formData.get('city')?.invalid,
                                                      'error-placeholder': formData.get('city')?.touched && formData.get('city')?.invalid
                                                    }">
                          </div>
                          <div class="col-md-6">
                            <input id="state" formControlName="state" class="form-control"
                              [placeholder]="getPlaceholder('state')" [ngClass]="{
                                                      'is-invalid': formData.get('state')?.touched && formData.get('state')?.invalid,
                                                      'error-placeholder': formData.get('state')?.touched && formData.get('state')?.invalid
                                                    }">

                          </div>
                        </div>
                        <div class="form-group">
                          <input id="zip" formControlName="zipCode" class="form-control"
                            [placeholder]="getPlaceholder('zipCode')" [ngClass]="{
                                                      'is-invalid': formData.get('zipCode')?.touched && formData.get('zipCode')?.invalid,
                                                      'error-placeholder': formData.get('zipCode')?.touched && formData.get('zipCode')?.invalid
                                                    }">
                        </div>
                        <div class="d-flex justify-content-between align-items-center  rounded px-4 py-2  mb-3 ">
                          <h5 class="m-0 text-white" style="letter-spacing: 2px;">Credit card</h5>
                          <div class="credit-card-icons d-flex">
                            <img src="../../../../../assets/images/credit-cards/card.png" alt="master-card"
                              class="credit-card-icon" [ngClass]="{'disabled': !isCardTypeValid('MasterCard')}">
                            <img src="../../../../../assets/images/credit-cards/american-express.png" alt="amex"
                              class="credit-card-icon" [ngClass]="{'disabled': !isCardTypeValid('Amex')}">
                            <img src="../../../../../assets/images/credit-cards/visa.png" alt="visa"
                              class="credit-card-icon" [ngClass]="{'disabled': !isCardTypeValid('Visa')}">
                          </div>
                        </div>
                        <div class="form-group mb-3">
                          <input type="text" formControlName="nameOnCard" class="form-control"
                            [placeholder]="getPlaceholder('nameOnCard')" [ngClass]="{
                                                      'is-invalid': formData.get('nameOnCard')?.touched && formData.get('nameOnCard')?.invalid, 
                                                      'error-placeholder': formData.get('nameOnCard')?.touched && formData.get('nameOnCard')?.invalid
                                                    }">
                        </div>
                        <div class="form-group position-relative">

                          <!-- Card Number Input -->
                          <input type="text" formControlName="cardNumber" maxlength="16" class="form-control pe-5"
                            [placeholder]="getPlaceholder('cardNumber')" [ngClass]="{
                                                        'is-invalid': formData.get('cardNumber')?.touched && formData.get('cardNumber')?.invalid, 
                                                        'error-placeholder': formData.get('cardNumber')?.touched && formData.get('cardNumber')?.invalid
                                                      }" (input)="onCardNumberChange($event)" style="height: 45px;">
                          <!-- Fixed height for input -->

                          <!-- Credit Card Icons -->

                        </div>



                        <div class="form-group row mb-3">
                          <div class="col-md-6">
                            <select formControlName="expireMonth" class="form-control form-select" [ngClass]="{
                                                          'is-invalid': formData.get('expireMonth')?.touched && formData.get('expireMonth')?.invalid,
                                                        'error-placeholder': formData.get('expireMonth')?.touched && formData.get('expireMonth')?.invalid
                                                        }">
                              <option [value]="''">{{ getExpireMonthPlaceholder() }}</option>
                              <!-- Placeholder updates with error -->
                              <option *ngFor="let month of expireMonthDropdown" [value]="month">{{ month }}</option>
                            </select>
                          </div>


                          <div class="col-md-6">
                            <select formControlName="expireYear" class="form-control form-select" [ngClass]="{
                                                          'is-invalid': formData.get('expireYear')?.touched && formData.get('expireYear')?.invalid,
                                                        'error-placeholder': formData.get('expireYear')?.touched && formData.get('expireYear')?.invalid
                                                        }">
                              <option [value]="''">{{ getExpireYearPlaceholder() }}</option>
                              <!-- Placeholder updates with error -->
                              <option *ngFor="let year of expireYearDropdown" [value]="year">{{ year }}</option>
                            </select>
                          </div>
                        </div>

                        <div class="form-group mb-3">
                          <input type="password" maxlength="4" formControlName="cardCode" class="form-control"
                            [placeholder]="getPlaceholder('cardCode')" [ngClass]="{
                                                        'is-invalid': formData.get('cardCode')?.touched && formData.get('cardCode')?.invalid, 
                                                        'error-placeholder': formData.get('cardCode')?.touched && formData.get('cardCode')?.invalid
                                                        }">
                        </div>

                        <!-- </ng-container> -->

                        <!-- <ng-container *ngIf="toupTypeData ==='Y'"> -->

                      </ng-container>
                    </div>
                    <div class="col-md-12">
                      <button type="submit"
                        class="btn custom-form-width submit-button btn-primary w-100 mt-3 d-flex align-items-center justify-content-center"
                        (click)="onSubmit()">
                        <h5>Submit</h5>
                      </button>
                    </div>
                  </form>




                </div>


              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div *ngIf="isSkeletonLoader" @fadeInOut  style="max-width: 100%;">
    <app-skeleton-loader [isAddressSummary]="true" [isPaymentForm]="true" shape="address-form"></app-skeleton-loader>
</div> -->