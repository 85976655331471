import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/shared/services/common.service';
import Swal from 'sweetalert2';
import { AdhocStateService } from '../shared/adhoc-state.service';

@Component({
  selector: 'app-send-invoice',
  templateUrl: './send-invoice.component.html',
  styleUrls: ['./send-invoice.component.scss']
})
export class SendInvoiceComponent implements OnInit {

    constructor(private commonService: CommonService, private adhocStateService:AdhocStateService){
    
      }
      
      ngOnInit(): void {
          // this.sendInvoice()
      }
    // sendInvoice() {
    //   console.log('Invoice download triggered');
    //   this.commonService.showToast('success','Success','Ad-hoc invoice payment successful')
    //   // Logic to download invoice goes here
    // }


      async takeInvoiceConfirmation(email: any) {
        try {
          // First confirmation dialog with two options
          const result = await Swal.fire({
            title: "Choose Email Option",
            text: "Would you like to send the ad-hoc invoice to the existing email or provide a new one?",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Manual Email",
            cancelButtonText: "Existing Email",
            showCloseButton: true,
            allowOutsideClick: false,
          });
      
          if (result.isConfirmed) {
            // User chose Manual Email
            const manualEmailResult = await Swal.fire({
              title: "Enter Email Address",
              input: "email",
              inputPlaceholder: "Enter recipient email",
              showCancelButton: true,
              confirmButtonText: "Send",
              cancelButtonText: "Cancel",
              allowOutsideClick: false,
              showCloseButton: true,
            });
      
            if (manualEmailResult.isConfirmed && manualEmailResult.value) {
              const manualEmail = manualEmailResult.value;
      
              // Final confirmation for manual email
              const confirmManualResult = await Swal.fire({
                title: "Are you sure?",
                text: `Do you want to send this ad-hoc invoice to ${manualEmail}?`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, send it!",
                cancelButtonText: "No, cancel",
                allowOutsideClick: false,
                showCloseButton: true,
              });
      
              if (confirmManualResult.isConfirmed) {
                // Send email to manual address
                const payload = {
                  emailId: '2',
                  toemail: manualEmail,
                };
                await this.sendInvoice(payload);
              }
            }
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            // User chose Existing Email
            const confirmExistingResult = await Swal.fire({
              title: "Are you sure?",
              text: `Do you want to send this ad-hoc invoice to ${email?.receiverEmail}?`,
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Yes, resend it!",
              cancelButtonText: "No, cancel",
              allowOutsideClick: false,
              showCloseButton: true,
            });
      
            if (confirmExistingResult.isConfirmed) {
              // Send email to existing address
              const payload = {
                emailId: email?.id,
                toemail: email?.receiverEmail,
              };
              await this.sendInvoice(payload);
            }
          }
        } catch (error) {
          this.commonService.showToast("error", "Error", error?.message);
        }
      }
      
      // Helper function to handle sending email and showing success or error message
      private async sendInvoice(payload: { emailId: string; toemail: string }) {
        try {
          const res: any = true;
          if (res) {
            // Show success message
            let timerInterval: any;
            const stopTimer = () => {
              if (this.adhocStateService.isMailSentSubject.getValue()) {
                Swal.close(); // Close the SweetAlert if mail is sent
              }
            };
            Swal.fire({
              title: "Success",
              html: "Ad-hoc invoice sent successfully! Closing in <b>10</b> seconds.",
              icon: "success",
              timer: 10000,
              timerProgressBar: true,
              allowOutsideClick: false,
              didOpen: () => {
                const content = Swal.getHtmlContainer();
                const timerElement = content?.querySelector("b");
                timerInterval = setInterval(() => {
                  stopTimer(); 
                  if (timerElement) {
                    const timeLeft = Swal.getTimerLeft();
                    timerElement.textContent = Math.ceil(timeLeft! / 1000).toString();
                  }
                }, 1000);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            });
          this.adhocStateService.setMailSentStatus(true);

          } else {
            // Show error message
            Swal.fire("Error", "Failed to send the ad-hoc invoice.", "error");
          this.adhocStateService.setMailSentStatus(false);
          }
        } catch (error) {
          console.error("Error sending invoice: ", error);
          Swal.fire("Error", "An error occurred while sending the ad-hoc invoice.", "error");
          this.adhocStateService.setMailSentStatus(false);
        }
      }
}
