import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { fadeInOut } from "src/app/shared/services/app.animation";
import { CommonService } from "src/app/shared/services/common.service";
import { HttpService } from "src/app/shared/services/httpService.service";
import { AwaitingShipmentService } from "../services/awaiting-shipment.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LocalStorage } from "ngx-webstorage";
import Swal from 'sweetalert2';

@Component({
  selector: "app-order-details",
  templateUrl: "./order-details.component.html",
  styleUrls: ["./order-details.component.scss"],
  animations: [fadeInOut],
})
export class OrderDetailsComponent {
  @LocalStorage()
  private userDetails;
  id: any;
  customerId: any;
  customerDetails: any;
  order: any;
  paidTotal: any;
  isSkeletonLoader: boolean = true;
  timelineData: any = []
  commentText: string;
  orderType: any = 'physical'
  orderTypeList: any = [
    { key: "Physical Sim", value: "physical" },
    { key: "Esim", value: "esim" },
  ]
  list: any = []
  showEmojiDropdown: boolean;
  showStaffDropdown: boolean;
  agentList: any;
  selectedStaff: any;
  searchQuery: any;
  filteredStaffList: any;
  alternateType: string;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private orderService: CommonService,
    private http: HttpService,
    private awaitingShipmentService: AwaitingShipmentService,
    private commonService: CommonService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.order = history.state.order;
    this.list = history.state.list;
    this.getSubTotal();
    this.route.params.subscribe((params) => {
      this.id = params["id"];
    });
    this.route.queryParams.subscribe((params) => {
      this.customerId = params["customerid"];
      if (this.customerId) {
        this.getCustomerDetais();
      }
    });
    console.log(this.id)
    if (this.id) {
      this.getOrderTimeline()
    }

    this.getAgents()
  }

  back() {
    window.history.back();
    // this.router.navigateByUrl("/awaiting-shipment")
  }

  async restoreOrder() {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'Do you want to restore this order?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, restore it!',
        cancelButtonText: 'No, cancel',
      });
  
      if (result.isConfirmed) {
        const res: any = await this.orderService.restoreOrder(this.customerId);
        if (res) {
          this.orderService.showToast('success', 'Success', res?.message);
        } else {
          this.orderService.showToast('info', 'Info', res?.message);
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.orderService.showToast('info', 'Cancelled', 'Order restoration was cancelled.');
      }
    } catch (error) {
      this.orderService.showToast('error', 'Error', error?.message);
    }
  }
  printTable() { }

  getOrderDetails() {
    const data = {
      per_page: 10,
      page: 1,
      searchterm: this.id,
    };
    this.orderService.getAwaitingShipmentOrder(data).subscribe(
      (response) => {
        console.log(response);
        if (response.Status) {
          this.order = response.data.Orders.data[0]

        }
      },
      (error) => {
        console.error("Error fetching data:", error);
      }
    );
  }

  async getCustomerDetais() {
    const data = {
      CustomerId: this.customerId,
    };

    const res: any = await this.http
      .get("api/Customer/getCustomerPersonalInfo", data, false)
      .toPromise();
    if (res.Status) {
      this.customerDetails = res.data;
      console.log(this.customerDetails);
      this.isSkeletonLoader = false;
    }
    this.isSkeletonLoader = false;
  }

  openMap() {
    const address = this.customerDetails?.personalInfo?.billingAddress?.value;
    if (address) {
      const fullAddress = `${address.address1}, ${address.city}, ${address.state}, ${address.zipCode}`;
      const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
        fullAddress
      )}`;
      window.open(googleMapsUrl, "_blank");
    }
  }

  getSubTotal() {
    const total = this.order.order_item?.reduce((sum, item) => {
      // Multiply the price by the quantity for each item
      const itemPrice = item.TotalPrice || 0;
      const itemQuantity = item.Quantity || 1;
      return sum + itemPrice * itemQuantity;
    }, 0);
    // console.log('total', total);
    this.paidTotal = total + total;
    return total?.toFixed(2) || 0.00; // Ensures the total has 2 decimal places
  }

  refundOrder() {
    this.router.navigate([`/awaiting-shipment/refund/${this.order.OrderId}`], {
      state: { order: this.order },
    });
  }

  editOrder() {
    this.router.navigate(
      [`/awaiting-shipment/edit-order/${this.order.OrderId}`],
      {
        state: { order: this.order },
      }
    );
  }

  calculateTotalPrice(item: any): number {
    return item.TotalPrice * item.Quantity;
  }

  async getOrderTimeline() {
    const data = {
      orderId: this.id,
    };

    const res: any = await this.http
      .get("api/orders/orderActivityLogTimeline", data, false)
      .toPromise();
    if (res.Status) {
      console.log(res);
      this.timelineData = res?.data
    }
  }

  onAddCommentClick() {
    const data = {
      "orderId": parseInt(this.id),
      "remarks": this.commentText

    }
    this.awaitingShipmentService.addCommentClick(data).then((res: any) => {
      if (res.Status) {
        this.commonService.showToast('success', 'Comment added successfully', res?.data)
        this.getOrderTimeline()
      }
    }).catch(err => {

    })

  }

  cancelOrder() {
    const data = {
      "orderId": parseInt(this.id)
    }
    this.awaitingShipmentService.cancelOrder(data).then((res: any) => {
      if (res.Status) {
        this.commonService.showToast('success', "Order Cancelled successfully", "")
      }
    }).catch(err => {

    })
  }


  confirmRefund(OrderType:any)  {
    const orderType = this.order?.OrderType;
    const alternateType = this.alternateType;
    if (this.isEligibleForConversion(orderType)) {
      // Set alternate type based on the current order type
      if (orderType.includes('ESIM')) {
        this.alternateType = 'Physical SIM';
      } else if (orderType.includes('SIM')) {
        this.alternateType = 'eSIM';
      }
    }
    Swal.fire({
      title: 'Are you sure?',
      html: `<p>Are you sure you want to convert this ${orderType} to ${this.alternateType}?</p>`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, confirm it!',
      cancelButtonText: 'No, cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        let data = {
          orderType: this.orderType,
          userID: this.customerId,
          CustomerId: this.customerId
        };
  
        this.awaitingShipmentService.convertOrder(data).then(res => {
          if (res) {
            Swal.fire('Confirmed!', 'The refund has been processed successfully.', 'success');
          } else {
            Swal.fire('Info', 'Refund processing encountered an issue.', 'info');
          }
        }).catch(error => {
          Swal.fire('Error', 'An error occurred while processing the refund.', 'error');
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Refund confirmation was cancelled.', 'info');
      }
    });
  }

  onClick(isLeftClick: boolean) {
    // Find the current index of the order based on this.id
    console.log(this.list)
    const currentIndex = this.list.findIndex(val => val.OrderId == this.id);

    if (currentIndex === -1) {
      // If the order with this.id is not found, return
      console.log('Order not found!');
      return;
    }

    // Calculate the index of the previous or next order
    let targetIndex = isLeftClick ? currentIndex - 1 : currentIndex + 1;

    // Check if the target index is within valid bounds (not out of range)
    if (targetIndex >= 0 && targetIndex < this.list.length) {
      this.id = this.list[targetIndex].OrderId;
      this.order = this.list[targetIndex];
      this.customerId = this.list[targetIndex].CustomerId
      if (this.customerId) {
        this.getCustomerDetais();
      }
      this.getOrderTimeline()
      console.log(isLeftClick ? 'Previous Order ID:' : 'Next Order ID:', this.id);
    } else {
      // Handle out-of-bounds cases (no previous or next order)
      console.log(isLeftClick ? 'No previous order' : 'No next order');
    }
  }

  addEmoji(e: any) {
    if (this.commentText) {
      this.commentText += e.emoji.native; // Correct way to append emoji to the string
    } else {
      this.commentText = e.emoji.native; // Correct way to append emoji to the string
    }
    this.showEmojiDropdown = false;
  }

  openEmojiDropdown() {
    this.showEmojiDropdown = !this.showEmojiDropdown
  }

  getAgents() {
    this.awaitingShipmentService.getAgents().then((res: any) => {
      if (res.Status) {
        this.agentList = res?.data?.data
        this.filteredStaffList = res?.data?.data
      }
    })
  }

  openMentionDropdown() {
    this.showStaffDropdown = !this.showStaffDropdown
  }

  filterStaff(): void {
    const query = this.searchQuery.toLowerCase();
    this.filteredStaffList = this.agentList.filter((staff) =>
      staff.user.name.toLowerCase().includes(query)
    );
  }

  selectStaff(staff: any): void {
    this.selectedStaff = staff;
    if (this.commentText) {
      this.commentText += staff.user.name; // Correct way to append emoji to the string
    } else {
      this.commentText = staff.user.name; // Correct way to append emoji to the string
    }
  }

  displayFn(staff: any): string {
    return staff && staff.name ? staff.name : '';
  }

  getInitials(name: string): string {
    if (!name) return ''; // Handle empty or undefined names

    const nameParts = name.split(' '); // Split the name by spaces
    const initials = nameParts.map(part => part.charAt(0).toUpperCase()); // Get the first letter of each part
    return initials.slice(0, 2).join(''); // Join the first two letters
  }

  isEligibleForConversion(orderType: string): boolean {
    const eligibleTypes = ['PREPAID_ESIM', 'PREPAID_SIM', 'PREPAID_MINGLE_ESIM', 'PREPAID_MINGLE_SIM'];
    return eligibleTypes.includes(orderType);
  }

  convertOrder(modal: any, orderType: string): void {
    if (this.isEligibleForConversion(orderType)) {
      // Set alternate type based on the current order type
      if (orderType.includes('ESIM')) {
        this.alternateType = 'Physical SIM';
      } else if (orderType.includes('SIM')) {
        this.alternateType = 'eSIM';
      }
      // Open the confirmation modal
      this.modalService.open(modal, { centered: true });
    } else {
      console.log('This order type is not eligible for conversion.');
    }
  }


  getStatusBadgeClass(status: string): any {
    
    switch (status) {
      case 'PAID':
        return 'badge-success';
      case 'RECEIVED':
        return 'badge-success';
      case 'New':
        return 'badge-primary';
      case 'DECLINED':
        return 'badge-warning';
      case 'FAILED':
        return 'badge-danger';
      case 'PENDING':
        return 'badge-secondary';
      default:
        return '';
    }
  } 

  getStatusBadgeClass2(status: string): any {
    
    switch (status) {
      case 'Dispatched':
        return 'badge-success';
      case 'RECEIVED':
        return 'badge-success';
      case 'Approved':
        return 'bg-yellow';
      case "New Order CSR":
        return 'badge-primary';
      case 'Pending Review':
        return 'badge-warning';
      case 'Cancelled':
        return 'badge-danger';
      default:
        return '';
    }
  }
}
