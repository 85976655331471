import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
} from "@angular/core";
import { NavService, Menu } from "../../../../services/nav.service";
import { SearchService } from "src/app/shared/services/search.service";
import { Router } from "@angular/router";
import { CustomerProfileService } from "src/app/shared/services/customer-profile.service";
import { CommonService } from "src/app/shared/services/common.service";
import { SearchHistoryService } from "src/app/shared/services/search-history.service";

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnInit {
  public menuItems: Menu[];
  public items: Menu[];

  public searchResult: boolean = false;
  public searchResultEmpty: boolean = false;
  public text: string;
  public isShowAdvanceSearch: boolean = false;
  public isShowSearch: boolean = false;
  private typingTimer: any;
  private enterKeyPressTimer: any;
  private enterKeyPressDelay: number = 800;
  private typingDelay: number = 500;
  @Output() searchClosed = new EventEmitter<void>();

  constructor(
    public navServices: NavService,
    public searchService: SearchService,
    public router: Router,
    public customerProfileService: CustomerProfileService,
    private commonService: CommonService,
    private eRef: ElementRef,
    private searchHistoryService: SearchHistoryService
  ) {
    this.navServices.items.subscribe((menuItems) => (this.items = menuItems));
  }

  ngOnInit() {}

  searchTerms(event: Event) {
    const target = event.target as HTMLInputElement;
    this.text = target.value;
    clearTimeout(this.typingTimer);
    clearTimeout(this.enterKeyPressTimer);

    this.toggleGlobalSearch();

    if (this.text.trim().length > 0) {
      this.typingTimer = setTimeout(() => {
        this.searchService.updateSearchTerm(this.text);
      }, this.typingDelay);
    } else {
      this.searchService.updateSearchTerm("");
    }
  }
  keepSearchOpen() {
    this.isShowSearch = true;
    this.isShowAdvanceSearch = false;
  }

  searchToggle() {
    this.navServices.search = false;
    document.getElementsByTagName("body")[0].classList.remove("offcanvas");
  }

  async searchTerm(event) {
    console.log("seacrh Term executed");
    if (!this.text) {
      return;
    }
    if (event.keyCode == 13) {
      clearTimeout(this.enterKeyPressTimer);
      this.isShowSearch = false;
      let data = {
        search: this.text,
        page: "1",
      };
      try {
        this.customerProfileService.isShowAlert = false;
        const res: any = await this.searchService.searchCustomer(data);
        const searchcount: any = await this.searchService.getAppSearchCount(
          this.text
        );

        if (res?.Status) {
          this.searchService.setSearchData(res.data);
          this.searchService.setSearchCount(searchcount.data);
          if (res.data.data.length > 1) {
            this.router.navigateByUrl("/search-result");
          } else {
            await this.customerProfileService.setCustomerId(
              res.data.data[0]?.CustomerID
            );
            this.customerProfileService.getMinimalProfileData();
            this.searchService.updateSearchTerm(res.data.data[0]?.CustomerID);
            this.router.navigateByUrl("/customer-dashboard/customer-profile");
            this.searchHistoryService.saveSearchRecord(
              res.data.data[0]?.CustomerID,
              "customers",
              res.data.data[0]
            );
          }
          this.removeFix();
          this.searchToggle();
        } else {
          this.commonService.showToast("error", "Error", res.message);
        }
      } catch (err) {
        this.commonService.showToast(
          "error",
          "Error",
          err?.error?.message ?? "Some Error Occurred"
        );
      }
    }
  }

  checkSearchResultEmpty(items) {
    if (!items.length) this.searchResultEmpty = true;
    else this.searchResultEmpty = false;
  }

  addFix() {
    this.searchResult = true;
    document.getElementsByTagName("body")[0].classList.add("offcanvas");
  }

  removeFix() {
    this.searchResult = false;
    this.text = "";
    document.getElementsByTagName("body")[0].classList.remove("offcanvas");
  }

  toggleAdvanceSearch() {
    if (this.isShowSearch) {
      this.isShowSearch = false;
    }
    this.isShowAdvanceSearch = !this.isShowAdvanceSearch;
  }

  closeAdvanceSearch() {
    this.toggleAdvanceSearch();
    this.removeFix();
    this.searchToggle();
  }

  async onSubmitAdvanceSearch(event: any) {
    if (event.data.length > 1) {
      this.router.navigateByUrl("/search-result");
    } else {
      await this.customerProfileService.setCustomerId(
        event.data[0]?.customerId
      );
      this.customerProfileService.getMinimalProfileData();
      this.router.navigateByUrl("/customer-dashboard/customer-profile");
    }
  }

  closeSearch() {
    this.isShowSearch = false;
    this.isShowAdvanceSearch = false;
    this.searchClosed.emit();
  }

  async toggleGlobalSearch() {
    if (this.isShowAdvanceSearch) {
      this.isShowAdvanceSearch = false;
    }
    if (this.enterKeyPressTimer) {
      clearTimeout(this.enterKeyPressTimer);
    }
    this.enterKeyPressTimer = setTimeout(() => {
      this.isShowSearch = true;
    }, this.enterKeyPressDelay);
  }

  @HostListener("document:click", ["$event"])
  handleClickOutside(event?: MouseEvent) {
    const target = event.target as HTMLElement;
    const clickedInside = this.eRef.nativeElement.contains(target);
    if (!clickedInside && this.isShowSearch) {
      this.isShowSearch = false;
      this.text = "";
      this.searchService.updateSearchTerm("");
    }
  }
  closeGlobalSearch() {
    if (this.isShowSearch) {
      this.isShowSearch = false;
      this.text = "";
      this.searchService.updateSearchTerm("");
    }
  }
}
