import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomerProfileService } from 'src/app/shared/services/customer-profile.service';
import { SubscriptionService } from '../service/subscription.service';

@Component({
  selector: 'app-payment-his-two',
  templateUrl: './payment-his-two.component.html',
  styleUrls: ['./payment-his-two.component.scss']
})
export class PaymentHisTwoComponent {
  isNonPrepaid: boolean = false;
  isShowScheduleData: boolean;
  customerProfileService1:any;
  currentPlanName: string;
  schedulePlanName: string;
  customerProfileData;
  @Input() id:any

  constructor(
    public customerProfileService: CustomerProfileService,
    public commonService: CommonService,
    private http: HttpClient,
    private subscriptionService: SubscriptionService,
  ){

    }
    ngOnInit() {
     this.fetchCustomerProfile()
      
    }
    async fetchCustomerProfile() {
      let data = {
        'CustomerId':this.id
      }
     await this.subscriptionService.getminmal(data).subscribe(
        (res: any) => {
          if (res.Status) {
            this.customerProfileData = res?.data
      
            // if (res?.data?.data?.length === 0) {
            //   // this.showmsg = true
            //   this.commonService.showToast('success', "Success", 'No Record Found');
            // }
            // this.isInvoiceSkeletonLoader=false;
            // this.isHistorySkeletonLoader=false
  
          }
          
        },
        (error: any) => {
          console.error("Error fetching payment history:", error);
          this.commonService.showToast('error', "Error", 'Failed to fetch payment history');
          // this.isInvoiceSkeletonLoader=false
          // this.isHistorySkeletonLoader=false
          
        }
      );
    }
}
