import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GoogleTranslateService {

  public translateElement: any; // Store reference to the TranslateElement

  constructor() {
    this.loadTranslateApi()
  }

  loadTranslateApi(): void {
    if (!(window as any).google || !(window as any).google.translate) {
      const script = document.createElement('script');
      script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
      (window as any).googleTranslateElementInit = this.googleTranslateElementInit.bind(this);
    } else {
      this.googleTranslateElementInit('en');
    }
  }

  googleTranslateElementInit(lang): void {
    let eleTimeout = setTimeout(() => {
      this.translateElement = new (window as any).google.translate.TranslateElement(
        {
          pageLanguage: lang,
          autoDisplay: false,
        },
        'google_translate_element'
      );
      clearTimeout(eleTimeout);
    }, 1000);
  }


  changeLanguage(languageCode: string): void {
    const translateCookieValue = `/${languageCode}`;
    const baseDomain = this.getBaseDomain(window.location.hostname);
    this.setCookie('googtrans', translateCookieValue, 1, '/', baseDomain, window.location.protocol === 'https:', 'Lax');
    let langTimeout = setTimeout(() => {
      console.log('Reloading page...');
      window.location.reload();
      clearTimeout(langTimeout);
    }, 1000); // Increase the delay to 500ms for testing
  }

  setCookie(name: string, value: string, days: number, path: string = '/', domain: string = '', secure: boolean = false, sameSite: string = 'Lax'): void {
    // Delete the existing cookie if it exists
    if (document.cookie.split('; ').some(cookie => cookie.startsWith(name + '='))) {
      this.deleteCookie(name, path, domain);
    }

    // Set the new cookie
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = `; expires=${date.toUTCString()}`;
    }
    const cookieDomain = domain ? `; domain=${domain}` : '';
    const cookieSecure = secure ? '; secure' : '';
    const cookieSameSite = sameSite ? `; SameSite=${sameSite}` : '';
    document.cookie = `${name}=${(value || '')}${expires}; path=${path}${cookieDomain}${cookieSecure}${cookieSameSite}`;
  }

  deleteCookie(name: string, path: string = '/', domain: string = ''): void {
    const cookieDomain = domain ? `; domain=${domain}` : '';
    document.cookie = `${name}=; Max-Age=-99999999; path=${path}${cookieDomain}`;
    console.log(`Cookie ${name} deleted`);
  }

  getBaseDomain(hostname: string): string {
    const parts = hostname.split('.');
    return parts.length > 2 ? parts.slice(-2).join('.') : hostname;
  }

}
