import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpService } from 'src/app/shared/services/httpService.service';

@Injectable({
  providedIn: 'root'
})
export class TicketSystemService {
  getTableData(Name: any) {
    throw new Error('Method not implemented.');
  }

  public statusCode: BehaviorSubject<any> = new BehaviorSubject(1);
  public refreshCardandCount: BehaviorSubject<any> = new BehaviorSubject(false);


  constructor(
    private http: HttpService,
  ) { }

  updateStatusCode(newStatusCode: any, item: any) {
    if (newStatusCode !== null && newStatusCode !== '') {
      this.statusCode.next(newStatusCode);
    } else {
      throw new Error('No ' + item?.DisplayName + ' to show')
    }
  }

  getStatusCount() {
    try {
      let url = ""
      return this.http.get(url).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  addTicketType(data) {
    try {
      let url = "api/tickets/store-ticket-type"
      let body = {
        "userID": this.http.getUser()?.userID,
        "companyId": "UNITYCRM0021",
        ...data
      }
      return this.http.post(url, body, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  updateTicketType(id, data) {
    try {
      let url = "api/tickets/update-ticket-type/" + id
      let body = {
        "userID": this.http.getUser()?.userID,
        "companyId": "UNITYCRM0021",
        ...data
      }
      return this.http.put(url, body, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  getTicketTypes() {
    try {
      let url = "api/tickets/ticket-types"
      let body = {
        "companyId": "UNITYCRM0021",
        "userID": this.http.getUser()?.userID,
      }
      return this.http.get(url, body).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }


  addTicket(data) {
    try {
      let url = "api/tickets/create"
      let body = {
        "userID": this.http.getUser()?.userID,
        "companyId": "UNITYCRM0021",
        ...data
      }
      return this.http.post(url, body, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  updateTicket(id, data) {
    try {
      let url = "api/tickets/update/" + id
      return this.http.put(url, data).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  getAllTickets(data) {
    try {
      let url = "api/tickets/get-tickets"
      let body = {
        "companyId": "UNITYCRM0021",
        "userID": this.http.getUser()?.userID,
        ...data
      }
      return this.http.get(url, body).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  getTicketsStatus() {
    try {
      let url = "api/tickets/get-ticket-statuses"
      let body = {
        "companyId": "UNITYCRM0021",
      }
      return this.http.get(url, body).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
  getticketPriorityOption() {
    try {
      let url = "api/tickets/ticketPriority"
      let body = {
        "companyId": "UNITYCRM0021",
      }
      return this.http.get(url, body).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  getTicketById(id) {
    try {
      let url = "api/tickets/single-ticket/" + id
      let data = {
        "companyId": "UNITYCRM0021",
        "userID": this.http.getUser()?.userID
      }
      return this.http.get(url, data, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  attachFile(data) {
    try {
      let url = "api/tickets/upload-attachments";
      return this.http.post(url, data, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  deleteattachFile(id) {
    try {
      let url = "api/tickets/delete-attachment/" + id
      let data = {
        "companyId": "UNITYCRM0021",
        "userID": this.http.getUser()?.userID
      }
      return this.http.delete(url, data, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  addComment(ticketId, data) {
    try {
      let url = "api/tickets/add-ticket-comment/" + ticketId
      let body = {
        "companyId": "UNITYCRM0021",
        "userID": this.http.getUser()?.userID,
        ...data
      }
      return this.http.post(url, body, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  getCommentsByTicketId(ticketId) {
    try {
      let url = "api/tickets/single-ticket/" + ticketId
      return this.http.get(url).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  updateComment(id, body) {
    try {
      let url = "api/tickets/update-ticket-comment/" + id
      return this.http.put(url, body).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  deleteComment(id) {
    try {
      let url = "api/tickets/delete-ticket-comment/" + id
      return this.http.delete(url).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  ticketAnalytics(data) {
    try {
      let url = "api/analytics/tickets"
      let body = {
        "companyId": "UNITYCRM0021",
        "userID": this.http.getUser()?.userID,
        ...data
      }
      return this.http.get(url, body, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  async getSource() {
    try {
      let url = "api/system/resource-permissions"
      let body = {
        "companyId": "UNITYCRM0021",
        "userID": this.http.getUser()?.userID,
        "resourceType[]": [
          "TABS_TICKETS"
        ]
      }
      return this.http.get(url, body, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }


  async updateMultipleTicketsStatus(ticketIds, ticketStatusID) {
    try {
      let url = "api/tickets/update-multiple-tickets-status"
      let body = {
        "companyId": "UNITYCRM0021",
        "userID": this.http.getUser()?.userID,
        "TicketIDs": ticketIds,
        "TicketStatusID": ticketStatusID
      }
      return this.http.post(url, body, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  async getTicketCards() {
    try {
      let url = "api/system/resource-permissions"
      let body = {
        "companyId": "UNITYCRM0021",
        "userID": this.http.getUser()?.userID,
        "resourceType[]": [
          "TS_DASHBOARD_CARD"
        ]
      }
      return this.http.get(url, body, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  async getButtons() {
    try {
      let url = "api/system/resource-permissions"
      let body = {
        "companyId": "UNITYCRM0021",
        "userID": this.http.getUser()?.userID,
        "resourceType[]": [
          "ACTION_BUTTON_TICKETS",
        ]
      }
      return this.http.get(url, body, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  async getAllTicketsForKanban(data) {
    try {
      let url = "api/tickets/getTicketsByStatus"
      let body: any = {
        "companyId": "UNITYCRM0021",
        "userID": this.http.getUser()?.userID,
        "limit": 100,
        "page": 1,
      }

      if (data['searchTerm']) {
        body.searchTerm = data['searchTerm']
      }
      if (data['fromDate']) {
        body.fromDate = data['fromDate']
      }
      if (data['toDate']) {
        body.toDate = data['toDate']
      }
      if (data['CustomerId']) {
        body.customerID = data['CustomerId']
      }
      return this.http.get(url, body, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  getAgents() {
    try {
      let url = "api/system/agents-list-no-page"
      let body = {
        "companyId": "UNITYCRM0021",
      }
      return this.http.get(url, body, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }


  downloadTickets(data: any) {
    try {
      let url = "api/tickets/exportTickets"
      return this.http.get(url, data, false).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  getGroups() {
    try {
      let url = "api/system/groups-list"
      let body = {
        "groupTypeName": "TICKET_SYSTEM"
      }
      return this.http.get(url, body, false).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  addGroup(data) {
    try {
      let url = "api/system/create-group"
      let body = {
        "userID": this.http.getUser()?.userID,
        "companyId": "UNITYCRM0021",
        ...data
      }
      return this.http.post(url, body).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  updateGroup(id, data) {
    try {
      let url = "api/system/update-group/" + id;
      let body = {
        "companyId": "UNITYCRM0021",
        "userID": this.http.getUser()?.userID,
        ...data
      }
      return this.http.put(url, body, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }


  getActivityById(id) {
    try {
      let url = "api/tickets/getTicketWithCommentsAndActivities"
      let data = {
        "companyId": "UNITYCRM0021",
        "userID": this.http.getUser()?.userID,
        "ticketId": id
      }
      return this.http.get(url, data, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
  getReportingDashKpi(data) {
    try {
      let url = "api/tickets/ticketsReport"
      let body = {
        "companyId": "UNITYCRM0021",
        "userID": this.http.getUser()?.userID,
        ...data
     
      }
      return this.http.get(url, body).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
  
  getAgentList() {
    try {
      let url = "api/system/agents-list-no-page"
      let body = {
        "companyId": "UNITYCRM0021",
     
      }
      return this.http.get(url, body).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
}
