import { ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/auth/services/auth.service';
import { CustomerProfileService } from 'src/app/shared/services/customer-profile.service';
import { PlanChangeService } from '../../customer-dashboard/customer-pages/new-dashboard/plan-change/services/plan-change.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { AdhocInvoiceService } from '../shared/adhoc-invoice.service';
import { PersonalInfo } from '../shared/invoicePayload';
import { HttpService } from 'src/app/shared/services/httpService.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AdhocBreakupService } from '../shared/adhoc-breakup.service';
import { fadeInOut } from 'src/app/shared/services/app.animation';
import { AdhocStateService } from '../shared/adhoc-state.service';

@Component({
  selector: 'app-adhoc-plan-change',
  templateUrl: './adhoc-plan-change.component.html',
  styleUrls: ['./adhoc-plan-change.component.scss'],
  animations:[fadeInOut]
})
export class AdhocPlanChangeComponent implements OnInit {
  @Input() customerData:any
  @Output() emittedPlanInfo:any
  adhocType:string='Plan Change'
  plans: any;
  currentPlanName: any;
  schedulePlanName: string;
  currentPlanId: any;
  selectedPlanId: number;
  curentPlanIdDetailsInSystem:any
  currentPlan: any;
  currentIndex = 0; 
  itemsPerSlide = 3; 
  visibleScheduledPlans: any[] = []; 
  currentPlanIndex = 0; // Index for the current visible plan
  visiblePlans: any[] = []; // Stores the visible plans for the plans carousel
  selectedScheduledPlanId: number | null = null; // Selected scheduled plan ID
  selectedAvailablePlanId: number | null = null; // Selected available plan ID
  previousSelectedPlanId: number | null = null; // Previous selected plan ID for fallback
  activePlanId: number | null = null; // Active Plan ID (previously `currentPlanId`)
  newSelectedPlanId: number | null = null; // Newly selected plan ID (previously `selectedPlanId`)
  lastSelectedPlanId: number | null = null;
  tabs:any[]=[]
  activeTabIndex = 0; // Default active tab index
  activeTabIndicatorLeft = 0; // Position of the active tab indicator
  activeTabIndicatorWidth = 0;
  planID:any
    @ViewChild('tabsContainer', { static: false }) tabsContainer: ElementRef;
    @ViewChild('cardsContainer', { static: false }) cardsContainer!: ElementRef;
    @ViewChild('modalTemplate', { static: true }) modalTemplate: ElementRef;
  scheduledPlans = [
    // { PlanId: 1, ImapgePath: '../../../assets/images/new-dashboard/mobile-plan.jpg', Price: 49.0, PlanData: '35 GB', ExpirationDays: 30, monthlyAmount: null, VoiceText: '10000', Name: 'Unlimited Plan', savingAmount: null, category: 'standard' },
    // { PlanId: 2, ImapgePath: '../../../assets/images/new-dashboard/mobile-plan.jpg', Price: 360.0, PlanData: '35 GB', ExpirationDays: 365, monthlyAmount: 30.0, VoiceText: 'Unlimited', Name: 'Unlimited Plan Pro', savingAmount: '50', category: 'premium' },
    // { PlanId: 3, ImapgePath: '../../../assets/images/new-dashboard/mobile-plan.jpg', Price: 210.0, PlanData: 'Unlimited', ExpirationDays: 180, monthlyAmount: 20.0, VoiceText: '5000', Name: 'Family Plan', savingAmount: '15', category: 'family' },
    // { PlanId: 4, ImapgePath: '../../../assets/images/new-dashboard/mobile-plan.jpg', Price: 120.0, PlanData: '35 GB', ExpirationDays: 90, monthlyAmount: 10.0, VoiceText: '2000', Name: 'Basic Plan', savingAmount: '5', category: 'basic' },
    // { PlanId: 5, ImapgePath: '../../../assets/images/new-dashboard/mobile-plan.jpg', Price: 9.0, PlanData: '1 GB', ExpirationDays: 7, monthlyAmount: null, VoiceText: '500', Name: 'Talk & Text', savingAmount: null, category: 'starter' },
    // { PlanId: 6, ImapgePath: '../../../assets/images/new-dashboard/mobile-plan.jpg', Price: 49.0, PlanData: '35 GB', ExpirationDays: 30, monthlyAmount: null, VoiceText: '10000', Name: 'Unlimited Plan', savingAmount: null, category: 'standard' },
    // { PlanId: 7, ImapgePath: '../../../assets/images/new-dashboard/mobile-plan.jpg', Price: 360.0, PlanData: '35 GB', ExpirationDays: 365, monthlyAmount: 30.0, VoiceText: 'Unlimited', Name: 'Unlimited Plan Pro', savingAmount: '50', category: 'premium' },
    // { PlanId: 8, ImapgePath: '../../../assets/images/new-dashboard/mobile-plan.jpg', Price: 210.0, PlanData: 'Unlimited', ExpirationDays: 180, monthlyAmount: 20.0, VoiceText: '5000', Name: 'Family Plan', savingAmount: '15', category: 'family' },
    // { PlanId: 9, ImapgePath: '../../../assets/images/new-dashboard/mobile-plan.jpg', Price: 120.0, PlanData: '35 GB', ExpirationDays: 90, monthlyAmount: 10.0, VoiceText: '2000', Name: 'Basic Plan', savingAmount: '5', category: 'basic' },
    // { PlanId: 10, ImapgePath: '../../../assets/images/new-dashboard/mobile-plan.jpg', Price: 9.0, PlanData: '1 GB', ExpirationDays: 7, monthlyAmount: null, VoiceText: '500', Name: 'Talk & Text', savingAmount: null, category: 'starter' },
    // { PlanId: 11, ImapgePath: '../../../assets/images/new-dashboard/mobile-plan.jpg', Price: 49.0, PlanData: '35 GB', ExpirationDays: 30, monthlyAmount: null, VoiceText: '10000', Name: 'Unlimited Plan', savingAmount: null, category: 'standard' },
    // { PlanId: 12, ImapgePath: '../../../assets/images/new-dashboard/mobile-plan.jpg', Price: 360.0, PlanData: '35 GB', ExpirationDays: 365, monthlyAmount: 30.0, VoiceText: 'Unlimited', Name: 'Unlimited Plan Pro', savingAmount: '50', category: 'premium' },
    // { PlanId: 13, ImapgePath: '../../../assets/images/new-dashboard/mobile-plan.jpg', Price: 210.0, PlanData: 'Unlimited', ExpirationDays: 180, monthlyAmount: 20.0, VoiceText: '5000', Name: 'Family Plan', savingAmount: '15', category: 'family' },
    // { PlanId: 14, ImapgePath: '../../../assets/images/new-dashboard/mobile-plan.jpg', Price: 120.0, PlanData: '35 GB', ExpirationDays: 90, monthlyAmount: 10.0, VoiceText: '2000', Name: 'Basic Plan', savingAmount: '5', category: 'basic' },
    // { PlanId: 15, ImapgePath: '../../../assets/images/new-dashboard/mobile-plan.jpg', Price: 9.0, PlanData: '1 GB', ExpirationDays: 7, monthlyAmount: null, VoiceText: '500', Name: 'Talk & Text', savingAmount: null, category: 'starter' },
  ];
isSkeletonLoader: boolean=true;
isPlanSkeleton: boolean=true;
  plansList: any;
  advancePlanId:any;
  advancePlan:any
  advancePlanDetails: any;
constructor(
  private breakpointObserver: BreakpointObserver,
  private customerProfileService: CustomerProfileService,
  private authService: AuthService,
  private planChangeService: PlanChangeService, 
  private commonService:CommonService, 
  private adhocInvoiceService: AdhocInvoiceService,
  private httpService:HttpService,
  private cdr: ChangeDetectorRef,
  private adhocBreakupService:AdhocBreakupService,private stateService: AdhocStateService
){

}


async ngOnInit() {
  await this.getPlanHEaders();
  console.log('scheduledPlan: ', this.scheduledPlans);
  const itemsPerSlide = this.calculateItemsPerSlide();
  await this.getCurrentplanId( this?.customerData?.personalInfo?.customerId); 
  await this.getPrepaidPlans();
  await this.getCustomerAdvancePlan(this?.customerData?.personalInfo?.customerId);
  await this.getCustomerOtherInfo(this?.customerData?.personalInfo?.customerId);
  await this.initializeVisiblePlans(); 
  this.initializevisibleScheduledPlans();
   // Set breakup details for the current plan by default
   if (this.currentPlan) {
    this.activePlanId = this.currentPlan.PlanId || null;
    this.lastSelectedPlanId = this.activePlanId;
    this.selectedAvailablePlanId = this.activePlanId
    this.selectedPlanId = this.currentPlan.PlanId;
    this.previousSelectedPlanId=this.currentPlan.PlanId;
    this.setBreakupDetailsForCurrentPlan(this.currentPlan);
  }else if(this.advancePlan){
    this.activePlanId = this.advancePlan.PlanId || null;
    this.lastSelectedPlanId = this.activePlanId;
    this.selectedAvailablePlanId = this.activePlanId
    this.selectedPlanId = this.advancePlan.PlanId;
    this.previousSelectedPlanId=this.advancePlan.PlanId;
    this.setBreakupDetailsForCurrentPlan(this.advancePlan);
  }

  this.isSkeletonLoader=false
}

async getCustomerAdvancePlan(customerId:any){
  try {
    console.log('customerId: ', customerId);

    const response:any = await this.adhocInvoiceService.getCustomerAdvancePlan(customerId);
    if(response?.Status){
      this.advancePlanId = response?.data?.data[0]?.PlanId;
      if(this.advancePlanId){
        const params = {
          planId: this.advancePlanId,
          CustomerId: customerId,
        };
    
        console.log('params: ', params);
    
        const planDetailsResponse: any = await this.planChangeService.getPlansDetails(params);
        this.advancePlanDetails = planDetailsResponse?.data;

        if(planDetailsResponse?.Status){
          const advancePlanDetail = {
            PlanId: this.advancePlanId,
            Name: this.advancePlanDetails?.plan?.DisplayName || "N/A",
            Price: this.advancePlanDetails?.planDetail?.MonthlyPaymentAmount || "0.00",
            PlanData: this.advancePlanDetails?.planDetail?.PlanData || "N/A",
            ExpirationDays: this.advancePlanDetails?.planDetail?.ExpirationDays || 0,
            VoiceText: this.advancePlanDetails?.planDetail?.VoiceText || "N/A",
            savingAmount: '', // Default as empty
            ImapgePath: this.advancePlanDetails?.planDetail?.ImapgePath || "N/A",
            isImageLoaded: true,
            isAdvanceplan:true,
          };
          this.advancePlan = advancePlanDetail;

          // Add advancePlan to plansList after the current plan
          if (this.currentPlan?.PlanId) {
            // If currentPlan exists, insert advancePlan at index 1
            const currentPlanIndex = this.plans.findIndex(
              (plan: any) => plan.PlanId === this.currentPlan.PlanId
            );
            
            if (currentPlanIndex >= 0) {
              this.plans.splice(currentPlanIndex + 1, 0, this.advancePlan);
              console.log('Advance plan inserted after current plan at index 1.');
            } else {
              // Fallback: If current plan is not found in the list
              this.plans.unshift(this.currentPlan); // Add the currentPlan to the start
              this.plans.splice(1, 0, this.advancePlan); // Add the advancePlan at index 1
              console.log('Current plan was not in the list. Added both plans.');
            }
          } else {
            // If currentPlan does not exist, add advancePlan at index 0
            this.plans.unshift(this.advancePlan);
            console.log('No current plan. Advance plan added at index 0.');
          }
          console.log('plans: ', this.plans);
        }
    this.isSkeletonLoader=false
      }
    this.isSkeletonLoader=false

    }else{
    this.isSkeletonLoader=false


    }
  } catch (error) {
    // this.commonService.showToast('error','error','api called successfully')
    
  }
}
getPlanHEaders() {
  this.planChangeService.getplanHeaders().subscribe((res: any) => {
    if (res.Status) {
      console.log("Tabs Data: ", res.data?.data); // Log the response
      this.tabs = res.data?.data;
      console.log('tabs: ', this.tabs);
      this.cdr.detectChanges();
    } else {
      console.error("Failed to fetch tabs.");
    }
  });
}

setActiveTab(index: number) {
  this.activeTabIndex = index;

  const activeTab = document.querySelectorAll('.wt-tab')[index] as HTMLElement;
  if (activeTab) {
    this.activeTabIndicatorLeft = activeTab.offsetLeft;
    this.activeTabIndicatorWidth = activeTab.offsetWidth;
  }
}

goToNextTab() {
  // console.log('next id',id)
  if (this.activeTabIndex < this.tabs.length - 1) {
    // this.setActiveTab(this.activeTabIndex + 1);
    // this.scrollTabs('right');
    // this.sendplanId(id)
  }
}

goToPrevTab() {
  if (this.activeTabIndex > 0) {
    // this.setActiveTab(this.activeTabIndex - 1);
    this.scrollTabs('left');
    // this.sendplanId(id)
  }
}
scrollTabs(direction: string) {
  const container = this.tabsContainer.nativeElement;
  const scrollAmount = 150;

  if (direction === 'right') {
    container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
  } else if (direction === 'left') {
    container.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
  }
}
sendplanId(item: any, tabName: string): void {
  if (tabName === 'All') {
    // Display all plans without filtering
    this.plans = [...this.plansList]; // Reset to the full list of plans
  } else {
    // Apply filtering based on the selected tab
    this.planID = item || this.tabs[0]?.id;
    const filteredPlans = this.plansList.filter((plan: any) => 
      plan?.planTags?.length > 0 && plan?.planTags?.some((tag: any) => tag === item)
    );
    this.plans = filteredPlans; // Set filtered plans
  }

  // Debugging logs for clarity
  console.log('Tab Name:', tabName);
  console.log('Filtered Plans:', this.plans);
}

/**
 * Method to set breakup details for the current plan during initialization.
 */
setBreakupDetailsForCurrentPlan(plan: any): void {
  console.log('Setting breakup details for current plan:', plan);
  const customerId = this?.customerData?.personalInfo?.customerId;
  if (!customerId) {
    console.error('Customer ID is missing in setBreakupDetailsForCurrentPlan.');
    return;
  }
  this.sendBreakupDetails(plan, false, customerId); // Send breakup details for the current plan
}

initializevisibleScheduledPlans(): void {
  this.visibleScheduledPlans = this.scheduledPlans.slice(0, this.itemsPerSlide);
}
initializeVisiblePlans(): void {
  // Initialize the first set of visible plans
  this.visiblePlans = this.plans?.slice(0, this.itemsPerSlide);
}


scrollNext(): void {
  this.currentIndex = (this.currentIndex + 1) % this.scheduledPlans.length;
  this.updatevisibleScheduledPlans();
}

scrollPrev(): void {
  this.currentIndex =
    (this.currentIndex - 1 + this.scheduledPlans.length) %
    this.scheduledPlans.length;
  this.updatevisibleScheduledPlans();
}
scrollPlansNext(): void {
  // Increment the current plan index and wrap around using modulo
  this.currentPlanIndex = (this.currentPlanIndex + 1) % this.plans.length;
  this.updateVisiblePlans();
}
scrollPlansPrev(): void {
  // Decrement the current plan index and wrap around using modulo
  this.currentPlanIndex =
    (this.currentPlanIndex - 1 + this.plans.length) % this.plans.length;
  this.updateVisiblePlans();
}
updateVisiblePlans(): void {
  // Update the visible plans dynamically based on the current index
  this.visiblePlans = [];
  for (let i = 0; i < this.itemsPerSlide; i++) {
    const index = (this.currentPlanIndex + i) % this.plans.length;
    this.visiblePlans.push(this.plans[index]);
  }
}


updatevisibleScheduledPlans(): void {
  this.visibleScheduledPlans = [];
  for (let i = 0; i < this.itemsPerSlide; i++) {
    const index = (this.currentIndex + i) % this.scheduledPlans.length;
    this.visibleScheduledPlans.push(this.scheduledPlans[index]);
  }
}





calculateItemsPerSlide(): number {
  if (this.breakpointObserver.isMatched(Breakpoints.XSmall)) return 1;
  if (this.breakpointObserver.isMatched(Breakpoints.Small)) return 2;
  if (this.breakpointObserver.isMatched(Breakpoints.Medium)) return 3;
  if (this.breakpointObserver.isMatched(Breakpoints.Large)) return 4;
  return 5; // Default case
}



async getCustomerOtherInfo(customerId){
  try {
    const customerdetails:any = await  this.adhocInvoiceService.getCustomerOtherInfo(customerId);
    if(customerdetails?.Status && customerdetails?.data){
      const planId = customerdetails?.data[0]?.PlanID;
      console.log('planId: ', planId);
      await this.getCurentPlanIdDetailsInSystem(planId);
    }else{
      return
    }
  } catch (error) {
    
  }
}

async getCurentPlanIdDetailsInSystem(planId: string) {
  try {
    const isPlanInList = this.plans?.some(plan => plan?.PlanId === planId);
    const planIndex = this.plans?.findIndex(plan => plan?.PlanId === planId);

    if (isPlanInList && planIndex > -1) {
      // Plan exists, move it to the 0th index and set it as the current plan
      this.currentPlan = this.plans[planIndex];
      this.plans.splice(planIndex, 1); // Remove the current plan from its original position
      this.plans.unshift(this.currentPlan); // Add it to the 0th index
      return;
    }

    // Plan does not exist, fetch its details
    const params = {
      planId: planId,
      CustomerId: this.httpService.getCustomerId()
    };

    console.log('params: ', params);

    const planDetailsResponse: any = await this.planChangeService.getPlansDetails(params);

    if (planDetailsResponse?.Status) {
      this.curentPlanIdDetailsInSystem = planDetailsResponse?.data;
      console.log('curentPlanIdDetailsInSystem: ', this.curentPlanIdDetailsInSystem);

      const currentPlanDetail = {
        PlanId: planId,
        Name: this.curentPlanIdDetailsInSystem?.plan?.DisplayName || "N/A",
        Price: this.curentPlanIdDetailsInSystem?.planDetail?.MonthlyPaymentAmount || "0.00",
        PlanData: this.curentPlanIdDetailsInSystem?.planDetail?.PlanData || "N/A",
        ExpirationDays: this.curentPlanIdDetailsInSystem?.planDetail?.ExpirationDays || 0,
        VoiceText: this.curentPlanIdDetailsInSystem?.planDetail?.VoiceText || "N/A",
        savingAmount: '', // Default as empty
        ImapgePath: this.curentPlanIdDetailsInSystem?.planDetail?.ImapgePath || "N/A",
        isImageLoaded: true
      };

      // Add the fetched plan to the 0th index
      this.plans.unshift(currentPlanDetail);
      this.currentPlan = currentPlanDetail; // Set it as the current plan
    } else {
      this.commonService.showToast('error', 'Error', planDetailsResponse?.message);
    }
  } catch (error) {
    this.commonService.showToast('error', 'Error', error?.message);
  }
}

  async getPrepaidPlans() {
    try {
      console.log('personalInfo: ', this.customerData);
      const data = {
        planType: "Prepaid",
        stateAbbr:
          this?.customerData?.personalInfo?.mailingAddress?.value?.state,
        "planAreas[]": ["CSR"],
      };

      const response: any = await this.planChangeService.getPrepaidPlans(data);
      if (response?.Status) {
        this.plans = response.data; // Set plans from API response
        this.plansList=response.data
        console.log('plans: ', this.plans);
        this.isPlanSkeleton=false
              // Check current plan ID or set default to the first plan
      this.currentPlanId = this.currentPlanId && this.plans?.some(p => p.id === this.currentPlanId)|| null;
      } else {
        this.commonService.showToast("error", "Error", response?.message);
        this.isPlanSkeleton=false
      }
    } catch (error) {
      this.commonService.showToast("error", "Error", error?.message);
      this.isPlanSkeleton=false
    } finally {
      this.isPlanSkeleton=false
    }
  }

  getCurrentPlanName(){
      if(this.customerData){
        this.currentPlanName = this.customerData?.plan?.planDetail?.value;
        if(!this.currentPlanName){
        this.commonService.showToast('info','Info','No active plan ')
        }
      }
  }

  onPlanSelect(planId: number): void {
    // this.currentPlanId = planId;
    this.selectedPlanId = planId;
    this.acquirePlanInformation(this.selectedPlanId)
  }

  acquirePlanInformation(planId:any){
    try {
      const data={
        planId : planId
      }
      const res:any = this.planChangeService.getPlansDetails(data);
      if(res?.Status){
        console.log('getPlansDetails: ', res);
      }
    } catch (error) {
      
    }
  }
  async getCurrentplanId(customerId:any){
    try {
      const response: any = await this.adhocInvoiceService.getCustomerOtherInfo(customerId);
      if (response.Status) {
        this.currentPlanId = response?.data[0]?.PlanID;
        console.log('current plan id: ', this.currentPlanId);
      }else{
        this.commonService.showToast('error','Error',response?.message)
      }
    } catch (error) {
      this.commonService.showToast('error','Error',error?.message)
    }
  }
  

  getPlanNameById(id: number): string {
    const plan = this.plans.find((val) => val.id === id);
    return plan ? plan.Name : "No Plan Found";
  }

  handleCardClick(planId: number, event: any) {

  }

  /**
   * Highlight the carousel item on hover.
   * @param event The mouse event.
   * @param highlight Whether to add or remove the border.
   */
  highlightPlan(event: MouseEvent, highlight: boolean): void {
    const target = event.currentTarget as HTMLElement;
    if (highlight) {
      target.style.backgroundColor = '#e6f7ff'; // Light blue background
      target.style.transition = 'background-color 0.3s ease-in-out, transform 0.3s ease-in-out';
      target.style.transform = 'scale(1.05)'; // Slight zoom effect
    } else {
      target.style.backgroundColor = 'transparent'; // Reset background
      target.style.transform = 'scale(1)'; // Reset scale
    }
  }
  highlightPlanChange(event: MouseEvent, highlight: boolean): void {
    const target = event.currentTarget as HTMLElement;
  
    // Apply hover styles
    if (highlight) {
      target.style.border = '2px solid #cccccc'; // Light grey border on hover
      target.style.transition = 'border-color 0.3s ease-in-out'; // Smooth border color transition
    } else {
      // Reset styles on mouse leave
      target.style.border = '2px solid transparent'; // Reset border
    }
  }
  
  
  
/**
 * Handle plan selection on click.
 * @param plan The clicked plan object.
 * @param planType The type of plan ('scheduled' or 'available').
 */
/**
 * Handle plan selection on click.
 * @param plan The clicked plan object.
 * @param planType The type of plan ('scheduled' or 'available').
 */
selectPlan(plan: any, planType: string): void {
  // Check if the plan is inactive
  if (plan?.PlanId !== 1 && planType === "scheduled") {
    this.commonService.showToast('info', 'Scheduled Future Plans', `Can't generate ad-hoc invoice for future scheduled plans.`);
    return; // Exit early for inactive plans
  }
  if (this.isCurrentPlan(plan)) {
    return;
  }
  this.selectedPlanId = plan.PlanId; 
  console.log('selectedplanId: ', this.selectedPlanId);
  // Update the last selected plan ID before changing to the new selection
  this.updateLastSelectedPlanId();

  // Set the new selected plan ID
  this.newSelectedPlanId = plan?.PlanId;

  // Prevent re-selection of the current plan


  console.log('Last Selected Plan ID:', this.lastSelectedPlanId);
  console.log('New Selected Plan ID:', this.newSelectedPlanId);

  // Update selection based on plan type
  if (planType === 'scheduled') {
    this.selectedScheduledPlanId = plan?.PlanId;
    this.selectedAvailablePlanId = null; // Deselect available plans
    this.handleScheduledPlan(plan, this.lastSelectedPlanId);
    this.stateService.setSelectedPlan(plan);
  } else if (planType === 'available') {
    this.selectedAvailablePlanId = plan?.PlanId;
    this.selectedScheduledPlanId = null; // Deselect scheduled plans
    this.handleAvailablePlan(plan, this.lastSelectedPlanId);
    this.stateService.setSelectedPlan(plan);
  } else {
    console.error('Unknown plan type:', planType);
  }
}


private updateLastSelectedPlanId(): void {
  // If a plan is currently selected, set it as the last selected one
  if (this.newSelectedPlanId !== null) {
    this.lastSelectedPlanId = this.newSelectedPlanId;
  }

  // Debugging: Log the last selected plan ID
  console.log('Updated Last Selected Plan ID:', this.lastSelectedPlanId);
}



/**
 * Check if the selected plan is the current plan.
 * @param plan The clicked plan object.
 * @returns A boolean indicating if the plan is the current plan.
 */
private isCurrentPlan(plan: any): boolean {
  if (this.currentPlan?.PlanId === plan?.PlanId) {
    this.commonService.showToast('info', 'Info', `can't generate ad-hoc invoice for current plan`);
    return true;
  }
  return false;
}

/**
 * Handle the selection of a scheduled plan.
 * @param plan The clicked plan object.
 */
private async handleScheduledPlan(plan: any, previousPlanId: number | null): Promise<void> {
  const customerId = this.customerData?.personalInfo?.customerId;

  // if (!customerId) {
  //   console.error('Customer ID is missing in handleScheduledPlan.');
  //   this.revertSelection(previousPlanId, 'scheduled');
  //   return;
  // }

  // if (plan?.PlanId !== 1) {
  //   // this.commonService.showToast('info', 'Info', `Can't generate ad-hoc invoice for future scheduled plans.`);
  //   this.revertSelection(previousPlanId, 'scheduled');
  //   return;
  // }else{

  // }

  try {
    if (plan?.PlanId == 1){
      // this.commonService.showToast('success', 'Scheduled Plan', 'Plan selected for generating invoice!');
      await this.sendBreakupDetails(plan, true, customerId);
    }

  } catch (error) {
    this.commonService.showToast('error','Latest Scheduled Plan',error?.message)
    this.revertSelection(previousPlanId, 'scheduled');
  }
}



/**
 * Handle the selection of an available plan.
 * @param plan The clicked plan object.
 */
private async handleAvailablePlan(plan: any, previousPlanId: number | null): Promise<void> {
  const customerId = this?.customerData?.personalInfo?.customerId;

  if (!customerId) {
    console.error('Customer ID is missing in handleAvailablePlan.');
    this.revertSelection(previousPlanId, 'available');
    return;
  }

  try {
    // this.commonService.showToast(
    //   'success',
    //   'Available Plan',
    //   'Plan selected for generating invoice!'
    // );
    if(plan?.isAdvanceplan){
      await this.sendBreakupDetails(plan, true, customerId);
      }else{
        await this.sendBreakupDetails(plan, false, customerId);
      }
  } catch (error) {
    console.error('Error in handleAvailablePlan:', error);
    this.revertSelection(previousPlanId, 'available');
  }
}

private revertSelection(previousPlanId: number | null, planType?: any): void {
  if (!previousPlanId) {
    console.error('No previous plan ID provided for reversion.');
    return;
  }

  // Function to determine if a plan is active
  const isPlanActive = (planId: number, planList: any[]): boolean => {
    const plan = planList.find(plan => plan.PlanId === planId);
    return plan?.isActive ?? false;
  };

  // Check if the previousPlanId exists in visibleScheduledPlans
  const isScheduled = this.visibleScheduledPlans.some(plan => plan.PlanId === previousPlanId);
  if (isScheduled) {
    // If PlanId is 1, always allow it to be active
    if (previousPlanId === 1) {
      this.selectedScheduledPlanId = previousPlanId;
      this.selectedAvailablePlanId = null; // Clear available selection
      console.log(`Allowed Scheduled Plan ID: ${previousPlanId} (always active).`);
      this.cdr.detectChanges();
      return; // Exit as PlanId 1 is always active
    }

    // Check if the scheduled plan is active
    const isActive = isPlanActive(previousPlanId, this.visibleScheduledPlans);

    if (!isActive) {
      this.commonService.showToast('info', 'Inactive Card', 'This scheduled plan is inactive and cannot be selected.');
      console.log(`Inactive Scheduled Plan ID: ${previousPlanId}`);
      return; // Exit if the plan is inactive
    }

    // Revert to the last selected available plan's ID
    if (this.selectedAvailablePlanId !== null) {
      this.selectedScheduledPlanId = this.selectedAvailablePlanId; // Reflect the last clicked available plan
      console.log(`Reverted to last clicked Available Plan ID: ${this.selectedAvailablePlanId} for scheduled plan.`);
    } else {
      this.selectedScheduledPlanId = 1; // Default to PlanId 1 if no available plan is selected
      console.log(`No previous available plan. Reverted to Scheduled Plan ID: ${this.selectedScheduledPlanId}`);
    }
    this.selectedAvailablePlanId = null; // Clear available selection
    return;
  }

  const isAvailable = this.visiblePlans.some(plan => plan.PlanId === previousPlanId);
  if (isAvailable) {
    const isActive = isPlanActive(previousPlanId, this.visiblePlans);
    this.selectedAvailablePlanId = previousPlanId;
    this.selectedScheduledPlanId = null; // Clear scheduled selection
  }
  this.cdr.detectChanges();
}

  /**
 * Check if the given plan is the latest scheduled.
 * Replace this logic with the appropriate condition for "Latest Scheduled".
 */
isLatestScheduled(plan: any): boolean {
  // Example: Compare with the latest scheduled plan ID or timestamp
  if(plan?.PlanId==1){
    return true;
  }
  return false;
}


/**
 * Method to send data to AdhocBreakupService for breakup calculation.
 * @param plan - The selected plan object.
 */
async sendBreakupDetails(plan: any, isScheduledPlan?: boolean, customerid?: any): Promise<void> {
  const adhocTypeDetails = plan;

  try {
    await this.adhocBreakupService.setAdhocDetailsForBreakupInputs(
      { adhocType: this.adhocType, adhocTypeDetails },
      isScheduledPlan,
      customerid
    );
  } catch (error) {
    console.error('Error in sendBreakupDetails:', error);
    throw error; // Propagate the error to handle it in the calling method
  }
}





}
