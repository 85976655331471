<div *ngIf="!isBarGraph() && !isPieChart() && shape != 'normal-card'" class="skeleton-loader" [ngStyle]="{
    'width': width,
    'height': height,
    'margin': margin
  }" [ngClass]="{'skeleton-rounded': isRounded, 'skeleton-animated': animated}">
</div>

<div *ngIf="isBarGraph()" class="skeleton-graph-container d-flex flex-column position-relative w-100 m-0 p-0">
  <!-- Horizontal Lines -->
  <div class="skeleton-horizontal-lines w-100 position-absolute top-0 h-100">
    <div *ngFor="let i of [0,1,2,3,4,5,6]" class="horizontal-line">
      <app-skeleton-loader width="100%" height="2px" margin="10px 0" [isRounded]="false"></app-skeleton-loader>
    </div>
  </div>

  <!-- Bars representing the graph -->
  <div class="skeleton-bars d-flex flex-row align-items-end gap-4 position-relative justify-content-center w-100">
    <app-skeleton-loader width="20px" height="40px" margin="5px"></app-skeleton-loader>
    <app-skeleton-loader width="20px" height="60px" margin="5px"></app-skeleton-loader>
    <app-skeleton-loader width="20px" height="80px" margin="5px"></app-skeleton-loader>
    <app-skeleton-loader width="20px" height="120px" margin="5px"></app-skeleton-loader>
    <app-skeleton-loader width="20px" height="160px" margin="5px"></app-skeleton-loader>
    <app-skeleton-loader width="20px" height="160px" margin="5px"></app-skeleton-loader>
    <app-skeleton-loader width="20px" height="140px" margin="5px"></app-skeleton-loader>
    <app-skeleton-loader width="20px" height="120px" margin="5px"></app-skeleton-loader>
    <app-skeleton-loader width="20px" height="100px" margin="5px"></app-skeleton-loader>
    <app-skeleton-loader width="20px" height="80px" margin="5px"></app-skeleton-loader>
    <app-skeleton-loader width="20px" height="60px" margin="5px"></app-skeleton-loader>
    <app-skeleton-loader width="20px" height="40px" margin="5px"></app-skeleton-loader>
  </div>
</div>


<div *ngIf="isPieChart()"
  class="skeleton-pie-container d-flex justify-content-center align-items-center position-relative w-100 h-100 m-0 p-0"
  [ngStyle]="{
    'width': '100%',
    'height': '100%'
  }">
  <div class="skeleton-subscription-chart w-100">
    <!-- Chart and Legend Container -->
    <div class="skeleton-chart-container">
      <!-- Pie Chart Skeleton -->
      <div class="skeleton-pie-chart"></div>

      <!-- Legend Skeleton -->
      <div class="skeleton-legend">
        <div class="skeleton-legend-item">
          <div class="skeleton-legend-circle"></div>
          <div class="skeleton-legend-rect"></div>
        </div>
        <div class="skeleton-legend-item">
          <div class="skeleton-legend-circle"></div>
          <div class="skeleton-legend-rect"></div>
        </div>
        <div class="skeleton-legend-item">
          <div class="skeleton-legend-circle"></div>
          <div class="skeleton-legend-rect"></div>
        </div>
        <div class="skeleton-legend-item">
          <div class="skeleton-legend-circle"></div>
          <div class="skeleton-legend-rect"></div>
        </div>
        <div class="skeleton-legend-item">
          <div class="skeleton-legend-circle"></div>
          <div class="skeleton-legend-rect"></div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- table -->
<div *ngIf="shape === 'table'" style="max-width: 100%;">
  <app-table-skeleton [tableColumn]="tableColumn" [tableRow]="tableRow"></app-table-skeleton>
</div>

<!-- credit-card skeleton -->
<div *ngIf="shape === 'credit-card'" class="credit-card-container">
  <app-skeleton-credit-card></app-skeleton-credit-card>
</div>

<!-- subscription-info skeleton -->

<div *ngIf="shape === 'subscription-info'" class="credit-card-container">
  <app-skeleton-subscription-info></app-skeleton-subscription-info>
</div>

<!-- address-info -->
<div *ngIf="shape === 'address-info'" class="credit-card-container">
  <app-skeleton-subscription-info></app-skeleton-subscription-info>
</div>

<!-- small-card -->
<div *ngIf="shape === 'small-card'" class="credit-card-container">
  <app-small-card-skeleton [alignment]="alignment" [cardCount]="cardCount"></app-small-card-skeleton>
</div>

<!-- medium-card -->
<div *ngIf="shape === 'medium-card'" style="max-width: 100%;">
  <app-medium-card-skeleton [alignment]="alignment" [cardCount]="cardCount"></app-medium-card-skeleton>
</div>

<!-- Timeline-card -->
<div *ngIf="shape === 'timeline-card'" style="max-width: 100%;">
  <app-timline-card-skeleton></app-timline-card-skeleton>
</div>

<!-- single-card -->
<div *ngIf="shape === 'single-column-form'" style="max-width: 100%;">
  <app-dynamic-column-form-skeleton [columnCount]="formColumnCount"
    [rowCount]="formRowCount"></app-dynamic-column-form-skeleton>
</div>

<!-- address-form -->
<div *ngIf="shape === 'address-form'" style="max-width: 100%;">
  <app-address-form-skeleton [isAddressSummary]="isAddressSummary"
    [isPaymentForm]="isPaymentForm"></app-address-form-skeleton>
</div>

<!-- button -->
<div *ngIf="shape === 'button'" style="max-width: 100%;">
  <app-button-skeleton [buttonCount]="buttonCount"></app-button-skeleton>
</div>
<!-- payment-form -->
<div *ngIf="shape === 'payment-form'" style="max-width: 100%;">
  <app-payment-form-skeleton></app-payment-form-skeleton>
</div>

<!-- plan-card -->
<div *ngIf="shape === 'plan-card'" style="max-width: 100%;">
  <app-plan-card-skeleton [numberOfCards]="numberOfCards"></app-plan-card-skeleton>
</div>

<!-- plan-detail -->
<div *ngIf="shape === 'plan-detail-card'" style="max-width: 100%;">
  <app-plan-detail-skeleton></app-plan-detail-skeleton>
</div>


<!-- normal-card -->
<div *ngIf="shape === 'normal-card'" style="max-width: 100%;">
  <app-normal-card-skeleton [cardCount]="cardCount" [width]="width" [height]="height"
    [alignment]="alignment"></app-normal-card-skeleton>
</div>

<!-- transaction -->
<div *ngIf="shape === 'transaction-card'" style="max-width: 100%;">
  <app-transaction-skeleton></app-transaction-skeleton>
</div>

<!-- ticket-system -->
<div *ngIf="shape === 'ticket-system'" style="max-width: 100%;">
  <app-ticket-system-skeleton></app-ticket-system-skeleton>
</div>
<!-- sidebar-navigation -->
<div *ngIf="shape === 'sidebar'" class="credit-card-container">
  <app-sidebar-navigation-skeleton></app-sidebar-navigation-skeleton>
</div>

<!-- animated-rows -->
<div *ngIf="shape === 'animated-rows'" class="credit-card-container">
  <app-animated-skeleton-rows [rowCount]="animatedRowCount"></app-animated-skeleton-rows>
</div>


<!-- data loading -->
<div *ngIf="shape === 'data-loading'" class="credit-card-container">
  <app-data-loading-skeleton [loaderCount]="loaderCount"></app-data-loading-skeleton>
</div>

<!-- animated-rows -->
<div *ngIf="shape === 'hollow-circle'" class="credit-card-container">
  <app-hollow-circle [radius]="radius" [borderWidth]="borderWidth"></app-hollow-circle>
</div>

<div *ngIf="shape === 'executive-dashboard'" class="credit-card-container">
  <app-executive-dashboard-skeleton-loader></app-executive-dashboard-skeleton-loader>
</div>
<!-- app-transsection-detail-skeleton -->
<div *ngIf="shape === 'transsection-detail'" class="credit-card-container">
  <app-transsection-detail-skeleton></app-transsection-detail-skeleton>
</div>

<!-- app-renewal-report-detail -->
<div *ngIf="shape === 'renewal-report-detail'" class="credit-card-container">
  <app-renewal-report-detail></app-renewal-report-detail>
</div>

<div *ngIf="shape === 'carousel'" class="credit-card-container">
  <app-carousel-skeleton></app-carousel-skeleton>
</div>