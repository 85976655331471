import { Component, Input, OnInit } from '@angular/core';
import { SubscriptionService } from '../service/subscription.service';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexYAxis,
  ApexTitleSubtitle,
  ApexNonAxisChartSeries,
  ApexResponsive,
} from 'ng-apexcharts';
import { FormBuilder, FormGroup } from '@angular/forms';
import { fadeInOut } from 'src/app/shared/services/app.animation';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  labels: string[];
  responsive: ApexResponsive[];
  title: ApexTitleSubtitle;
};

@Component({
  selector: 'app-subscription-piechart',
  templateUrl: './subscription-piechart.component.html',
  styleUrls: ['./subscription-piechart.component.scss'],
  animations: [fadeInOut]
})
export class SubscriptionPiechartComponent implements OnInit {
  piechartGraphList: any;
  startDate: string;
  endDate: string;
  dateForm: FormGroup;
  public chartOptions: Partial<ChartOptions>;
  selectedCard: boolean = false;
  bsRangeValue: Date[];
  @Input() interval: any
  isSkeletonLoader: boolean = true;
  constructor(private subscriptionService: SubscriptionService, private fb: FormBuilder
  ) {
    this.chartOptions = {
      series: [], // This will be updated dynamically
      chart: {
        type: 'pie',
        height: 230
      },
      labels: [],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300
            },
            legend: {
              position: 'bottom'
            }
          }
        }
      ]
    };
    this.setDates();

  }
  ngOnInit() {
    this.dateForm = this.fb.group({
      startDate: [this.startDate],
      endDate: [this.endDate]
    });
    this.dateForm.valueChanges.subscribe(values => {
 

    });
    this.getSummaryDetail();
   this.changeSelectedCardColor()

  }

  setDates() {
    const today = new Date();
    const fifteenDaysAgo = new Date();
    fifteenDaysAgo.setDate(fifteenDaysAgo.getDate() - 15);
    this.bsRangeValue = [fifteenDaysAgo, today];
  }

  onDateRangeChange() {
    this.getSummaryDetail();
  }
  ngOnChanges() {
    this.getSummaryDetail()
  }
  getSummaryDetail() {
    const data = {
      startDate: this.bsRangeValue[0].toISOString().split('T')[0],
      endDate: this.bsRangeValue[1].toISOString().split('T')[0],
      interval:this.interval
    };
    this.subscriptionService.getSummary(data).subscribe((res: any) => {
      console.log("Get Summary ::--", res);
      this.piechartGraphList = res?.data;
      this.updateChartOptions();

    })
  }

  updateChartOptions(): void {
    const pieChartData = this.piechartGraphList;
    const seriesData = Object.values(pieChartData).map(value => Number(value));
    const labels = Object.keys(pieChartData);

    this.chartOptions.series = seriesData as ApexNonAxisChartSeries;
    this.chartOptions.labels = labels;
    this.isSkeletonLoader = false
  }

  // pieChartCardClicked() {
  //   const data = {
  //     startDate: this.bsRangeValue[0].toISOString().split('T')[0],
  //     endDate: this.bsRangeValue[1].toISOString().split('T')[0],
  //   };
  //   this.subscriptionService.setSubscriptionChartDateSub(data);
  //   this.subscriptionService.setSelectedCard('PieChart');
  // }

  transectionDetail(event: Event) {
    event.stopPropagation();
    this.subscriptionService.setTransectionDetail(true);
  }
  // appplySelectedCardColor() {
  //   this.subscriptionService.getSelectedCard().subscribe((res: any) => {
  //     if (res === 'PieChart') this.selectedCard = true
  //     else this.selectedCard = false
  //   });
  // }

  applyGraphDates() {
    const selectedCard = this.subscriptionService.getSelectedCardValue(); // Now it works with BehaviorSubject
    const currentCard = 'PieChart'; // The card you're working with

    if (selectedCard === currentCard) {
      // Deselect the card if it's already selected
      const data = {
        startDate: null,
        endDate: null,
      };
      this.subscriptionService.setSelectedCard(null); 
      this.subscriptionService.setSubscriptionChartDateSub(data);
    } else {
      // Select the card
      const data = {
        startDate: this.bsRangeValue[0].toISOString().split('T')[0],
        endDate: this.bsRangeValue[1].toISOString().split('T')[0],
      };
      this.subscriptionService.setSubscriptionChartDateSub(data);
      this.subscriptionService.setSelectedCard(currentCard); // Set the selected card
    }
  }

  changeSelectedCardColor() {
    this.subscriptionService.getSelectedCard().subscribe((res: any) => {
      if (res === 'PieChart') {
        console.log("Card Selected: SubscriptionGraph");
        this.selectedCard = true;
      } else {
        this.selectedCard = false;
      }
    });
  }

  stop(event: Event) {
    event.stopPropagation();
  }
}
