import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-invoices',
  templateUrl: './add-invoices.component.html',
  styleUrls: ['./add-invoices.component.scss']
})
export class AddInvoicesComponent implements OnInit {
  ngOnInit(): void {
    console.log('currentCustomerId from AddInvoicesComponent: ',this.currentCustomerId )
  }

  @Input() currentCustomerId:any
  showHisotoryPage: boolean = false;
  showHistoryDetail: any;
  openHistoryPage(data: any) {
    this.showHisotoryPage = false;
    this.showHistoryDetail = data;
    setTimeout(() => {
      this.showHisotoryPage = true;
    }, 40);
  }
}
