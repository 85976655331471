<div class="container">
    <div class="d-flex justify-content-between align-items-start">

        <div class="d-flex align-items-start flex-column">
            <div class="d-flex gap-2 align-items-center">
                <button (click)="back()" class="custom-btn bg-transparent d-flex align-items-center">
                    <i class="icon-arrow-left fw-semibold"></i>
                </button>
                <span class="title heading fs-3">
                    ${{ paymentData?.due_amount || 0 }} USD
                </span>

                <span class="custom-badge-2 text-dark d-flex align-items-center" [ngClass]="{
                    'bg-success': renewal?.po_payment?.status === 'Captured' || renewal?.po_payment?.status === 'Draft',
                    'bg-danger': renewal?.po_payment?.status === 'Failed',
                    'bg-warning': renewal?.po_payment?.status === 'Created',
                    'bg-primary': renewal?.po_payment?.status === 'Settled',
                    'bg-dark text-white': renewal?.po_payment?.status === 'Cancelled'
                }">
                    {{ renewal?.po_payment?.status || 'Draft' }}
                    <i *ngIf="renewal?.po_payment?.status === 'Captured' " class="fa fa-check mx-2"></i>
                    <i *ngIf="renewal?.po_payment?.status === 'Failed'" class="fa fa-times mx-2"></i>
                    <i *ngIf="renewal?.po_payment?.status === 'Created'" class="fa fa-undo mx-2"></i>
                    <i *ngIf="renewal?.po_payment?.status === 'Settled'" class="fa fa-exclamation mx-2"></i>
                    <i *ngIf="renewal?.po_payment?.status === 'Cancelled'" class="fa fa-ban mx-2"></i>
                </span>
            </div>
            <span class="ms-5 mt-2">
                Charged to <span class="text-info cursor-pointer" (click)="sendToCustomerDashboard()">
                    {{renewal?.FirstName + " " + renewal?.LastName}} </span>
            </span>
        </div>
        <div class="d-flex gap-3">
            <app-filter-btn [title]="'Suspend Account'" [isBtn]="true"
                (click)="openConfirmationModal('Suspend',renewal)"
                *ngIf="renewal?.po_data && renewal?.CustomerStatus=='Active'"></app-filter-btn>
            <app-filter-btn [title]="'Renew Account'" [isBtn]="true" (click)="openConfirmationModal('Renewal' ,renewal)"
                *ngIf="renewal?.po_data && renewal?.CustomerStatus=='Active'"></app-filter-btn>
        </div>
    </div>

    <div class="row mx-0 mt-3" *ngIf="!isSkeletonLoader" @fadeInOut>
        <div class=" col-lg-9">
            <div class="custom-card p-3 mb-3">
                <!-- <div class="py-3">
                    <div class="d-flex justify-content-between info-wrapper ">
                        <h4 class="mb-2 ">Timeline</h4>
                        <app-filter-btn [title]="'Add note'" [isBtn]="true"></app-filter-btn>
                    </div>
                    <div class="info-wrapper p-3 pb-0">
                        <app-timeline [events]="timelineEvents"></app-timeline>
                    </div>
                </div> -->
                <div class="py-3">
                    <div class="d-flex justify-content-between  info-wrapper">
                        <h4 class="mb-2 ">Subscription</h4>
                        <span class="custom-badge">#{{renewal?.SubscriptionId}}</span>
                    </div>
                    <div class=" border-bottom-0 py-3">
                        <table class="w-100">
                            <thead>
                                <tr class="info-wrapper mb-2">
                                    <th>Product</th>
                                    <th>Frequency</th>
                                    <th>Next Invoice</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="info-wrapper mb-2">
                                    <td>{{customerDetails?.plan?.planName?.value }}
                                        <span [ngClass]="getStatusBadgeClass(renewal?.SubscriptionStatus)"
                                            class="custom-badge">{{renewal?.SubscriptionStatus}}</span>
                                    </td>
                                    <td>{{renewal?.BillingPeriod}} Days</td>
                                    <td>{{renewal?.NextBillingDate |date}} </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="py-3">
                    <div class="d-flex justify-content-between  info-wrapper">
                        <h4 class="mb-2 ">Payment breakdown</h4>
                    </div>
                    <div class=" border-bottom-0 py-3">
                        <div class="d-flex flex-column w-100 justify-content-start">
                            <div class="d-flex info-wrapper border-top-0 justify-content-between py-2">
                                <span class="text-muted">Payment amount</span>
                                <span class="text-muted">${{ paymentData?.subtotal || 0 }} USD</span>
                            </div>
                            <div class="d-flex info-wrapper border-top-0 justify-content-between py-2">
                                <span class="text-muted">Discount</span>
                                <span class="text-muted">${{ paymentData?.discount || 0}} USD</span>
                            </div>
                            <div class="d-flex info-wrapper border-top-0 justify-content-between py-2">
                                <span class="text-muted">Tax</span>
                                <span class="text-muted">${{ paymentData?.tax || 0}} USD</span>
                            </div>
                            <div class="d-flex info-wrapper border-top-0 border-bottom-0 justify-content-between py-2">
                                <span class="text-bold">Total</span>
                                <span class="">${{ paymentData?.total_amount || 0}} USD</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="custom-card p-2 mt-3">
                    <div class="d-flex justify-content-between  info-wrapper">
                        <h4 class="mb-2 ">Invoice</h4>
                    </div>
                    <app-dynamic-invoice-template [CustomerId]="customerDetails?.personalInfo?.customerId"></app-dynamic-invoice-template>
                </div>
                <div class="d-flex align-items-center justify-content-between mt-4 mx-3">
                    <h5 class="mb-0">Activity</h5>
                    <div class="tabs">
                        <button class="tab btn-xs px-3" *ngFor="let type of activityTypes"
                            [class.active]="selectedActivityType === type" (click)="filterTimeline(type)">
                            {{ type }}
                        </button>
                    </div>
                </div>
                <div class="timeline">
                    <div class="timeline-item align-items-center" *ngFor="let event of filteredTimelineData">
                        <div class="timeline-line">
                            <div class="timeline-icon">
                                <!-- Add a placeholder for icons/avatars -->
                                <i class="fa fa-file-invoice" *ngIf="event.activity_type=='PO'"></i>
                                <i class="fa fa-dollar-sign" *ngIf="event.activity_type=='Payment'"></i>
                                <!-- <img *ngIf="event.icon" [src]="event.icon" alt="icon" class="icon-img" /> -->
                                <!-- <div *ngIf="!event.icon" class="default-icon"></div> -->
                            </div>
                        </div>
                        <div class="timeline-content">
                            <div class="timeline-header">
                                <strong class="person-name text-nowrap">{{ event.created_by }}</strong>
                                <span class="action">{{ event.description }}</span>
                                <small class="person-name text-nowrap">{{ event.timestamp | date: 'mediumDate'
                                    }}</small>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="col-lg-3">
            <app-customer-information [customerDetails]="customerDetails"></app-customer-information>

        </div>
    </div>
    <div *ngIf="isSkeletonLoader" @fadeInOut class=" container-fluid ">
        <div class="div d-flex flex-column justify-content-between">
            <app-skeleton-loader shape="renewal-report-detail"></app-skeleton-loader>
        </div>
    </div>

</div>

<ng-template #renewConfirmationModal let-modal>
    <div class="modal-dialog modal-dialog-centered m-0 ">
        <div class="modal-content">
            <div class="modal-body text-center p-5">
                <img src="assets/svg/alarm.gif" style="width:130px;height:130px">
                <div class="mt-4 pt-4">
                    <h4>Important Notice</h4>
                    <p class="text-muted"> This action will override the existing bucket and renew the service
                        without verifying the payment status.</p>
                    <!-- Toogle to second dialog -->
                    <div class="d-flex gap-3 align-items-center mb-3">
                        <select class="form-select" (change)="onActionChange('renew',$event)">
                            <option value="onDate">On renewal date</option>
                            <option value="now">Renew now</option>
                            <option value="specificDate">Renew on specific date</option>
                        </select>
                        <input type="date" [(ngModel)]="dueDate" class="form-control" *ngIf="showDatePicker">
                        <button class="btn btn-warning" data-bs-target="#secondmodal" data-bs-toggle="modal"
                            data-bs-dismiss="modal" (click)="performAction('renew')">
                            Continue
                        </button>
                    </div>
                    <p class="text-muted">Note: Any add-ons on this account will be removed, if applicable! </p>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #suspensionConfirmationModal let-modal>
    <div class="modal-dialog modal-dialog-centered m-0 ">
        <div class="modal-content">
            <div class="modal-body text-center p-5">
                <img src="assets/svg/alarm.gif" style="width:130px;height:130px">
                <div class="mt-4 pt-4">
                    <h4>Important Notice</h4>
                    <p class="text-muted"> This action will suspend services. The end-user will still be able to
                        receive messages and calls while suspended..</p>
                    <!-- Toogle to second dialog -->
                    <div class="d-flex gap-3 align-items-center mb-3">
                        <select class="form-select" (change)="onActionChange('suspend',$event)">
                            <option value="onDate">Suspend on renewal date</option>
                            <option value="now">Suspend now</option>
                            <option value="specificDate">Suspend on specific date</option>
                        </select>
                        <input type="date" [(ngModel)]="dueDate" class="form-control" *ngIf="showDatePicker">
                        <button class="btn btn-warning" data-bs-target="#secondmodal" data-bs-toggle="modal"
                            data-bs-dismiss="modal" (click)="performAction('suspend')">
                            Continue
                        </button>
                    </div>
                    <p class="text-muted">This action will suspend services. The end-user will still be able to
                        receive messages and calls while suspended. </p>
                </div>
            </div>
        </div>
    </div>
</ng-template>