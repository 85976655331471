<div class="container-fluid custom-card">
    <div class="row">
        <div class="table-responsive signal-table">
            <ul ngbNav #nav="ngbNav" [(activeId)]="active1" class="nav nav-tabs">
                <li [ngbNavItem]="1" *ngIf="isHiddenData[0]?.isHidden == 1">
                    <a ngbNavLink>Add Ad Hoc- Invoice</a>
                    <ng-template ngbNavContent>
                        <!-- Ensure component is properly declared and referenced -->
                        <div class="div mt-3">
                            <app-add-invoices [currentCustomerId]="currentCustomerId"></app-add-invoices>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2">
                    <a ngbNavLink>Add Wallet</a>
                    <ng-template ngbNavContent>
                        <app-add-wallet></app-add-wallet>
                    </ng-template>
                </li>
                <li [ngbNavItem]="3">
                    <a ngbNavLink>E-Bill Setting</a>
                    <ng-template ngbNavContent>
                        <app-e-bill-setting></app-e-bill-setting>
                    </ng-template>
                </li>
                <li [ngbNavItem]="4">
                    <a ngbNavLink>Pay Invoice($)</a>
                    <ng-template ngbNavContent>
                        <app-pay-invoice></app-pay-invoice>
                    </ng-template>
                </li>
                <li [ngbNavItem]="5">
                    <a ngbNavLink>Adjust Wallet</a>
                    <ng-template ngbNavContent>
                        <app-adjust-wallet></app-adjust-wallet>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav"></div>
            
        </div>

    </div>



      


</div>
