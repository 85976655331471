<div class="container-fluid" *ngIf="!isSkeletonLoader" @fadeInOut>
    <div class="row" >
        <div class="col-12 text-center" style="height: 60vh;overflow-y: auto;"  >
          <div class="col-12">
            <div class=" custom-card text-start mb-4 sticky-heading">
                <h5 class=" fw-bold heading text-primary">Plans Available for Renewal</h5>
                <p class="text-muted sub-heading m-2">
                  Choose from a variety of plans that best suit your needs. Select a plan to view its details.
                </p>
            </div>
    
            <!-- <h3 class="text-secondary mb-4">Available Plans</h3>
            <p class="text-muted">
              Choose from a variety of plans that best suit your needs. Select a plan to view its details.
            </p>
       -->
            <div class="row p-2" style=" overflow: auto;"  *ngIf="planRenewalList?.length > 0" >
              
    
              <div class="col-md-12">
                    <!-- Green Cross Ribbon -->
                <div class="card-container" *ngFor="let card of planRenewalList" [attr.data-category]="card.category"
                 >
    
                  <div >
    
                    <div class="core-benefits-container mt-2 col-md-12 p-3" [ngClass]="{ 'selected-available-plan': selectedPlanId === card?.PlanId ,'unselected-available-plan': selectedPlanId != card?.PlanId}">
                      <div *ngIf="currentPlan?.PlanId === card?.PlanId" class="ribbon">
                        <span class="ribbonStyle">
                          Current Plan
                        </span>
                      </div>
                      <div *ngIf="advancePlan?.PlanId === card?.PlanId" class="ribbon">
                        <span class="ribbonStyleadvance">
                          Advance Plan
                        </span>
                      </div>
                      <div class="d-flex  m-0 p-0"  [ngClass]="{'unclickable': currentPlan?.PlanId === card?.PlanId,}" (click)="selectPlan(card,'available')">
    
                        <ng-container *ngIf="card?.ImapgePath">
                          <div class="image-container" style="max-width: 20%; overflow: hidden; border-radius: 10px; position: relative;">
              
                            <!-- Skeleton Placeholder -->
                            <div class="placeholder-image"></div>
                        
                            <!-- Actual Image -->
                             
                            <img 
                              [src]="card?.ImapgePath"
                              alt="Plan Image"
                              loading="lazy"
                              style="width: 100%; height: auto; object-fit: cover; border-radius: 10px; position: absolute; top: 0; left: 0;" />
                          </div>
                          
                        </ng-container>
                        <div class="d-flex flex-column w-100">
                          <div class="top-data d-flex justify-content-evenly w-100 mb-2">
                            <div class="benefit-list mb-0">
                              <label class=" mb-0 gap-1 d-flex align-items-start">
                                <span class="text-muted mt-2">$</span>
                                <span class="benefit-title">{{ card.Price }}</span>
                              </label>
                              <span class="text-muted mx-4">Total</span>
                            </div>
                            <div class="benefit-list mb-0 d-flex flex-column">
                              <label class="benefit-title mb-0">{{ card.PlanData }}</label>
                              <span class="text-muted">Data</span>
                            </div>
                            <div class="benefit-list mb-0">
                              <label class="d-flex align-items-center mb-0 gap-1">
                                <span class="benefit-title">{{ card.ExpirationDays }}</span>
                                <span class="text-muted mt-1">Days</span>
                              </label>
                              <span class="text-muted">Validity</span>
                            </div>
                            <div class="benefit-list mb-0" *ngIf="card.PlanData == 'Unlimited'">
                              <label class="d-flex align-items-center mb-0 gap-1">
                                <span class="text-muted mt-2">$</span>
                                <span class="benefit-title">{{ card?.monthlyAmount }}</span>
                              </label>
                              <span class="text-muted">Monthly</span>
                              <!-- <span class="text-muted">Validity</span> -->
                            </div>
                          </div>
                          <div class="bottom-data d-flex justify-content-evenly w-100 flex-column-mobile">
                            <div class="d-flex align-items-center">
                              <app-feather-icons [icon]="'phone-call'" class="mt-2"></app-feather-icons>
                              <span class="ms-2">{{ card?.VoiceText || "-" }} Minutes</span>
                            </div>
                            <div class="d-flex align-items-center no-hover-dot">
                                <app-feather-icons [icon]="'smartphone'"></app-feather-icons>
                                <span class="ms-2 ellipsis-tooltip no-hover-dot" matTooltip="{{ card?.Name || '-' }}" matTooltipPosition="above">
                                  {{ card?.Name || "-" }}
                                </span>
                              </div>
                            
                            
                            <div class="d-flex align-items-center ">
                              <app-feather-icons [icon]="'message-square'" class="mt-2"></app-feather-icons>
                              <span class="ms-2">{{ card?.VoiceText || "-" }} SMS</span>
                            </div>
                            <div class="d-flex align-items-center " *ngIf="card.PlanData == 'Unlimited'">
                              <app-feather-icons [icon]="'dollar-sign'" class="mt-2"></app-feather-icons>
                              <span class="ms-2">{{ card?.savingAmount }} Savings</span>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="d-flex">
                                          <button class="btn btn-primary " >Details</button>
                                      </div> -->
                      </div>
                    </div>
          
                  </div>
                </div>
          
              </div>
            </div>
          </div>
      
      
      
      
      
        </div>
        <!-- <div *ngIf="isPlanSkeleton" style="max-width: 100%;" @fadeInOut>
          <app-skeleton-loader [numberOfCards]="10" shape="plan-card"></app-skeleton-loader>
        </div> -->
      </div>
</div>


<div *ngIf="isSkeletonLoader" style="max-width: 100%;" @fadeInOut>
  <app-skeleton-loader [numberOfCards]="5" shape="plan-card"></app-skeleton-loader>
</div>