<div class="media profile-media">
  <img class="b-r-10" [src]= "profileimg ? profileimg : 'https://media.istockphoto.com/id/1393750072/vector/flat-white-icon-man-for-web-design-silhouette-flat-illustration-vector-illustration-stock.jpg?s=612x612&w=0&k=20&c=s9hO4SpyvrDIfELozPpiB_WtzQV9KhoMUP9R9gVohoU='" alt="" width="28px">
  <div class="media-body"><span>{{userData?.name}}</span>
    
    <p class="mb-0 font-roboto">{{userDetails?.userGroup[0]?.Name}} <i class="middle fa fa-angle-down"></i></p>
  </div>
  
</div>
<ul class="profile-dropdown onhover-show-div">
  <li routerLink="/new-profile/{{userDetails.userID}}"><a><app-feather-icons [icon]="'user'"></app-feather-icons><span>Account </span></a></li>
  <!-- <li><a href="email"><app-feather-icons [icon]="'mail'"></app-feather-icons><span>Inbox</span></a></li> -->
      
  <li><a href="@"><app-feather-icons [icon]="'settings'"></app-feather-icons><span>Settings</span></a></li>
  <li (click)="logoutFunc()"><app-feather-icons [icon]="'log-in'"></app-feather-icons><span>Log out</span></li>
</ul>
