<div class="modal-header">
    <h5 class="modal-title">Pay Invoice</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
  </div>
  <div class="modal-body">
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a
          class="nav-link"
          [class.active]="activeTab === 'cvv'"
          (click)="switchTab('cvv')"
          href="javascript:void(0)"
        >
          Existing Card
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          [class.active]="activeTab === 'creditCard'"
          (click)="switchTab('creditCard')"
          href="javascript:void(0)"
        >
          New Card
        </a>
      </li>
    </ul>
    <div class="custom-card mt-3">
      <div *ngIf="activeTab === 'cvv'" >
        <app-adhoc-cvv-payment (paymentSuccess)="handlePaymentSuccess()"></app-adhoc-cvv-payment>
      </div>
      <div *ngIf="activeTab === 'creditCard'" >
        <app-adhoc-credit-card-payment  (paymentSuccess)="handlePaymentSuccess()"></app-adhoc-credit-card-payment>
      </div>
    </div>
  </div>

  