import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpService } from 'src/app/shared/services/httpService.service';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionService {
    public subscrioptionSubject$ = new Subject();
    public isOpenTransectionDetailPage$ = new Subject();
    public selectedCardSubject$ = new BehaviorSubject<any>(null);

    constructor(
        private http: HttpService,
    ) { }

    setSubscriptionChartDateSub(data: any) {
        this.subscrioptionSubject$.next(data)
    }

    getSubscriptionChartDateSub(): Observable<any> {
        return this.subscrioptionSubject$.asObservable();
    }
    setTransectionDetail(data: any) {
        this.isOpenTransectionDetailPage$.next(data)
    }

    getTransectionDetail(): Observable<any> {
        return this.isOpenTransectionDetailPage$.asObservable();
    }

    setSelectedCard(data: any) {
        this.selectedCardSubject$.next(data)
    }

    getSelectedCard(): Observable<any> {
        return this.selectedCardSubject$.asObservable();
      }
    
      getSelectedCardValue(): any {
        return this.selectedCardSubject$.getValue(); // Now you can access the current value
      }
    getSubscriptionList(data: any) {
        try {
            let url = 'api/subscription/subscriptionsList'
            let body = {
                "userID": this.http.getUser()?.userID,
                "companyId": "UNITYCRM0021",
                ...data
            }
            return this.http.get(url, body)
        } catch (err) {
            throw Error(err)
        }
    }

    cancelSubscription(data: any, cancelReason?:any) {
        try {
            let url = 'api/subscription/cancelSubscription'
            let body = {
                "userID": this.http.getUser()?.userID,
                "companyId": "UNITYCRM0021",
                "CustomerId": this.http.getCustomerId(),
                "SubscriptionId": data?.SubscriptionId,
                "remarks": cancelReason
            }
            return this.http.post(url, body, true)
        } catch (err) {
            throw Error(err)
        }
    }

    getSummary(data) {
        try {
            let url = 'api/subscription/getSummary'
            let body = {
                "userID": this.http.getUser()?.userID,
                "companyId": "UNITYCRM0021",
                ...data
            }
            return this.http.get(url, body)
        } catch (err) {
            throw Error(err)
        }

    }

    getTrends(data: any) {
        try {
            let url = 'api/subscription/getTrends'
            let body = {
                "userID": this.http.getUser()?.userID,
                "companyId": "UNITYCRM0021",
                ...data
            }
            return this.http.get(url, body);
        } catch (err) {
            throw Error(err)
        }
    }

    getDetail(subscriptionId: any) {
        try {
            let url = `api/subscription/${subscriptionId}/detail`
            let body = {
                "userID": this.http.getUser()?.userID,
                "companyId": "UNITYCRM0021",
            }
            return this.http.get(url, body)
        } catch (err) {
            throw Error(err)
        }
    }

    editBillingInfo(subscription: any) {
        try {
            let url = `api/subscription/${subscription.subscriptionId}/edit`
            let body = {
                "userID": this.http.getUser()?.userID,
                "companyId": "UNITYCRM0021",
                ...subscription
            }
            return this.http.post(url, body)
        } catch (err) {
            throw Error(err)
        }
    }

    loadLiveSubscription(data) {
        try {
            let url = 'api/subscription/getAuthorizeNetSubscription'
            let body = {
                "userID": this.http.getUser()?.userID,
                "CustomerId": this.http.getUser()?.Customer?.id,
                "companyId": "UNITYCRM0021",
                ...data
            }
            return this.http.get(url, body)
        } catch (err) {
            throw Error(err)
        }
    }
    
    noSubscriptionData(data) {
        try {
            let url = 'api/subscription/customersWithNoSubscription'
            let body = {
                "userID": this.http.getUser()?.userID,
                "CustomerId": this.http.getUser()?.Customer?.id,
                "companyId": "UNITYCRM0021",
                ...data
            }
            return this.http.get(url, body)
        } catch (err) {
            throw Error(err)
        }
    }


    autoRenewSubscription(subscriptionId, autoRenew) {
        try {
            let url = `api/subscription/updateSubscriptionAutoRenewal/${subscriptionId}`
            let body = {
                "userID": this.http.getUser()?.userID,
                "companyId": "UNITYCRM0021",
                "autoRenew": autoRenew
            }
            return this.http.put(url, body)
        } catch (err) {
            throw Error(err)
        }
    }

    getTransactionByStatus(data) {
        try {
            let url = 'api/subscription/getTransactionsByStatus'
            let body = {
                "userID": this.http.getUser()?.userID,
                "companyId": "UNITYCRM0021",
                "limit": 500,
                "offset": 1,
                ...data
            }
            return this.http.get(url, body)
        } catch (err) {
            throw Error(err)
        }
    }

    getPaymentHistory(data: any) {
        try {
            let url = 'api/subscription/getPaymentHistory'
            let body = {
                "userID": this.http.getUser()?.userID,
                "companyId": "UNITYCRM0021",
                ...data
            }
            return this.http.get(url, body)
        } catch (err) {
            throw Error(err)
        }
    }

    getInvoices(data) {
        try {
            let url = 'api/subscription/getInvoices'
            let body = {
                "userID": this.http.getUser()?.userID,
                "companyId": "UNITYCRM0021",
                ...data
            }
            return this.http.get(url, body)
        } catch (err) {
            throw Error(err)
        }
    }

    getLastSubscription(data) {
        try {
            let url = 'api/subscription/getLastSubscription'
            let body = {
                "userID": this.http.getUser()?.userID,
                "companyId": "UNITYCRM0021",
                "CustomerId": data,
            }
            return this.http.get(url, body)
        } catch (err) {
            throw Error(err)
        }
    }

    createSubscription(data) {
        try {
            let url = 'api/subscription/createSubscription'
            let body = {
                "userID": this.http.getUser()?.userID,
                "companyId": "UNITYCRM0021",
                ...data
            }
            return this.http.post(url, body)
        } catch (err) {
            throw Error(err)
        }
    }

    renewSubscription(data, cardCode) {
        try {
            let url = 'api/subscription/renewSubscription '
            let body = {
                "userID": this.http.getUser()?.userID,
                "companyId": "UNITYCRM0021",
                "CustomerId": data,
                "cardCode": cardCode,
            }
            return this.http.post(url, body)
        } catch (err) {
            throw Error(err)
        }
    }
    updateCardInfo(subscriptionId, cardDetails) {
        try {
            let url = `api/subscription/${subscriptionId}/edit `
            return this.http.post(url, cardDetails)
        } catch (err) {
            throw Error(err)
        }
    }

    getTransectionDetailById(transectionId) {
        try {
            let url = `api/subscription/getTransactionDetails/${transectionId}`
            let body = {
                "userID": this.http.getUser()?.userID,
                "companyId": "UNITYCRM0021",
            }
            return this.http.get(url, body)
        } catch (err) {
            throw Error(err)
        }
    }

    getminmal(id) {
        try {
              let url = `api/Customer/getMinimalProfileData`
          
            let body = {
                "userID": this.http.getUser()?.userID,
                "companyId": "UNITYCRM0021",
                'CustomerId':id.CustomerId
            }
            return this.http.get(url, body)
        } catch (err) {
            throw Error(err)
        }
    }
    getKpiData(data) {
        try {
            let url = 'api/subscription/kpiSubscriptionCounts'
            return this.http.get(url,data)
        } catch (err) {
            throw Error(err)
        }
    }

  getTimelineData(data) {
    try {
      let url = "api/subscription/subscriptionActivityTimeline";
      return this.http.get(url, data, false, false).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  
  getSubcriptionHis(data){
    try {
      let url = "api/subscription/subscriptionHistory";
      return this.http.get(url, data, false, false).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

}
