import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth/services/auth.service';
import { fadeInOut } from 'src/app/shared/services/app.animation';
import { CommonService } from 'src/app/shared/services/common.service';
import { HttpService } from 'src/app/shared/services/httpService.service';
import { AdhocInvoiceService } from '../shared/adhoc-invoice.service';
import Swal from 'sweetalert2';

interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}
declare const google: any; // Declare
@Component({
  selector: 'app-adhoc-credit-card-payment',
  templateUrl: './adhoc-credit-card-payment.component.html',
  styleUrls: ['./adhoc-credit-card-payment.component.scss'],
  animations:[fadeInOut]
})
export class AdhocCreditCardPaymentComponent implements OnInit{
  @Output() paymentSuccess = new EventEmitter<void>();
  validCardTypes = {
    Amex: false,
    Visa: false,
    MasterCard: false,
  };
  cardTypeDetected = false;
  formData: FormGroup;
  toupTypeData: string = 'Y'; // To toggle the form sections
  expireMonthDropdown = Array.from({ length: 12 }, (_, i) => i + 1);
  currentYear: number = new Date().getFullYear();
  futureYear: number = 2050;
  expireYearDropdown = Array.from(
    { length: this.futureYear - this.currentYear + 1 },
    (_, i) => this.currentYear + i
  );
  isValidCardType = { MasterCard: true, Amex: true, Visa: true }; // For card type validation
  useExistingAddress: boolean = true; 
  companyName:any
  invoiceDataSubscription: any;
  invoiceEmittedData: any;
  constructor(private fb: FormBuilder, private authService:AuthService, private httpService:HttpService, private commonService:CommonService, private adhocInvoiceService:AdhocInvoiceService) {}

  ngOnInit(): void {
    // Initialize form
    if (!this.useExistingAddress) {
      this.getGooleFunctionalityStart();
    }
    this.getCompanyName();
    this.initializeForm();
    this.invoiceDataSubscription =  this.adhocInvoiceService.invoiceObservable$.subscribe((data) => {
      if (data) {
        console.log('Received invoice data:', data);
        this.invoiceEmittedData = data;
        console.log('invoiceEmittedData: ', this.invoiceEmittedData);
      } else {
        console.log('No invoice data received yet.');
      }
    });
    // Populate dropdowns

  }

  destroyGoogleFunctionality(): void {
    // Logic to clean up Google functionality
    this.formData.patchValue({
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipCode: '',
    });
  }
  

    get planId() {
      return this.formData.get("planID");
    }
    get cardCode() {
      return this.formData.get("cardCode");
    }
    get cardNumber() {
      return this.formData.get("cardNumber");
    }
    get nameOnCard() {
      return this.formData.get("nameOnCard");
    }
    get expireMonth() {
      return this.formData.get("expireMonth");
    }
    get expireYear() {
      return this.formData.get("expireYear");
    }
  getGooleFunctionalityStart(){
    setTimeout(() => {
      const addressInput = document.getElementById(
        "address1"
      ) as HTMLInputElement;
      console.log('addressInpu:', addressInput);
      if (addressInput) {
        this.initAutocomplete(addressInput);
      } else {
        console.error("addressInput is undefined after modal is shown");
      }
    }, 0);
  }

  initAutocomplete(addressInput: HTMLInputElement): void {
    if (typeof google !== "undefined" && google.maps) {
      const autocomplete = new google.maps.places.Autocomplete(addressInput, {
        types: ["geocode"],
        componentRestrictions: { country: "us" },
      });

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place && place.address_components) {
          const addressComponents = place.address_components.map(
            (component: any) => ({
              long_name: component.long_name,
              short_name: component.short_name,
              types: component.types,
            })
          );
          this.bindAddressComponentsToForm(addressComponents);
        }
      });
    } else {
      console.error("Google Maps API not loaded");
    }
  }
  bindAddressComponentsToForm(components: AddressComponent[]): void {
    let address1 = "";
    let address2 = "";
    let country = "";
    let state = "";
    let city = "";
    let zipCode = "";

    components.forEach((component) => {
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number":
          address1 = component.long_name;
          break;
        case "route":
          address1 += " " + component.long_name;
          break;
        case "sublocality":
        case "locality":
          city = component.long_name;
          break;
        case "country":
          country = component.long_name;
          break;
        case "administrative_area_level_1":
          state = component.short_name;
          break;
        case "postal_code":
          zipCode = component.long_name;
          break;
        default:
          break;
      }
    });

    if (this.formData){
      this.formData.patchValue({
        address1: address1.trim(),
        address2: address2.trim(),
        country: country.trim(),
        state: state.trim(),
        city: city.trim(),
        zipCode: zipCode.trim(),
      });
    }

    if(this.formData){
      this.formData.patchValue({
        address1: address1.trim(),
        address2: address2.trim(),
        country: country.trim(),
        state: state.trim(),
        city: city.trim(),
        zipCode: zipCode.trim(),
      });
    }

  }
  initializeForm() {
    this.formData = this.fb.group({
      cardCode: ["", Validators.required],
      cardNumber:['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      nameOnCard: ["", Validators.required],
      expireMonth: ["", Validators.required],
      expireYear: ["", Validators.required],
      isUpdateCard: [false],

      // Added fields for address details
      address1: [""],
      address2: [""], // Optional field
      city: [""],
      state: [""],
      zipCode: ["", Validators.pattern('^[0-9]{5}$')],
    });

    this.formData.get('address1')?.valueChanges.subscribe((address1Value) => {
      if (address1Value) {
        // Address1 is filled, require city, state, and zipCode
        this.formData.get('city')?.setValidators(Validators.required);
        this.formData.get('state')?.setValidators(Validators.required);
        this.formData.get('zipCode')?.setValidators([
          Validators.required,
          Validators.pattern('^[0-9]{5}$'),
        ]);
      } else {
        // Address1 is empty, clear validators for city, state, and zipCode
        this.formData.get('city')?.clearValidators();
        this.formData.get('state')?.clearValidators();
        this.formData.get('zipCode')?.clearValidators();
      }
      // Update value and validation status
      this.formData.get('city')?.updateValueAndValidity();
      this.formData.get('state')?.updateValueAndValidity();
      this.formData.get('zipCode')?.updateValueAndValidity();
    });
  }
  onCardNumberChange(event: any) {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;

    // if (/[^0-9]/.test(value)) {
    //   inputElement.value = value.replace(/[^0-9]/g, '');
    //   alert('Only numeric characters are allowed.');
    // }

    this.formData.get('cardNumber')?.setValue(inputElement.value);
    const cardNumberStr = event.target.value;

    if (cardNumberStr.length === 1 || cardNumberStr.length === 16) {
      const cardType = this.getCardType(cardNumberStr);
      
      for (const key in this.validCardTypes) {
        if (this.validCardTypes.hasOwnProperty(key)) {
          this.validCardTypes[key] = key === cardType;
        }
      }

      this.cardTypeDetected = true;
    } else if (!cardNumberStr) {
      this.cardTypeDetected = false;
      this.resetCardType();
    }
  }

  resetCardType() {
    for (const key in this.validCardTypes) {
      if (this.validCardTypes.hasOwnProperty(key)) {
        this.validCardTypes[key] = false;
      }
    }
  }

  getCardType(cardNumber: string): string | null {
    if (!cardNumber || cardNumber.length === 0) {
      return null; 
    }

    const firstDigit = cardNumber.charAt(0);

    if (firstDigit === "4") {
      return "Visa";
    } else if (firstDigit === "5") {
      return "MasterCard";
    } else if (firstDigit === "6") {
      return "Amex";
    } else {
      return null; 
    }
  }

  isCardTypeValid(cardType: string): boolean {
    return this.validCardTypes[cardType];
  }
  markAllAsTouched(): boolean {
    Object.keys(this.formData.controls).forEach(field => {
      const control = this.formData.get(field);
      if (control) {
        control.markAsTouched({ onlySelf: true });
      }
    });
    return this.formData.valid;
  }
  getFullBillingAddress(): string {
    const address1 = this.invoiceEmittedData?.customerData?.personalInfo?.mailingAddress?.value?.address1 || '';
    const address2 = this.invoiceEmittedData?.customerData?.personalInfo?.mailingAddress?.value?.address2 || '';
    const city = this.invoiceEmittedData?.customerData?.personalInfo?.mailingAddress?.value?.city || '';
    const state = this.invoiceEmittedData?.customerData?.personalInfo?.mailingAddress?.value?.state || '';
    const zipCode = this.invoiceEmittedData?.customerData?.personalInfo?.mailingAddress?.value?.zipCode || '';
  
    return [address1, address2, city, state, zipCode]
      .filter(Boolean) // Filter out any empty or undefined values
      .join(', '); // Concatenate with a comma and space
  }
  
  async onSubmitWithCreditCard() {
    try {
      if(this.markAllAsTouched()){
        const formattedMonth = this.formatMonth(this.expireMonth.value);
        const payload = {
          customerId: this.invoiceEmittedData?.data?.customer_id,
          invoiceId: this.invoiceEmittedData?.data?.id || null, // Assuming invoiceId is available
          invoiceType: this.invoiceEmittedData?.invoiceType === 'Plan Change' 
            ? 'planChange' 
            : this.invoiceEmittedData?.invoiceType === 'Plan Renewal' 
            ? 'renewal' 
            : this.invoiceEmittedData?.invoiceType || 'renewal',
          chargeFrom: "card",
          creditCard: {
            cardNumber: this.cardNumber.value,
            expirationDate: `${this.expireYear.value}-${formattedMonth}`,
            cardCode: this.cardCode.value,
            nameOnCard: this.nameOnCard.value,
            cardZipCode: this.invoiceEmittedData?.customerData?.personalInfo?.mailingAddress?.value?.zipCode || '',
            cardBillingAddress: this.getFullBillingAddress(), // Concatenated address
            cardState: this.invoiceEmittedData?.customerData?.personalInfo?.mailingAddress?.value?.state || '',
            cardCity: this.invoiceEmittedData?.customerData?.personalInfo?.mailingAddress?.value?.city || '',
          },
        };
        console.log('billing address: ', this.getFullBillingAddress());

         // Check if `address1` exists in the form, then update the billing address
         if (this.formData.get('address1')?.value) {
          const address1 = this.formData.get('address1')?.value;
          const address2 = this.formData.get('address2')?.value || '';
          const city = this.formData.get('city')?.value || '';
          const state = this.formData.get('state')?.value || '';
          const zipCode = this.formData.get('zipCode')?.value || '';
        
          payload["creditCard"] = {
            ...payload["creditCard"], // Ensure existing creditCard fields remain intact
            cardBillingAddress: [address1, address2].filter(Boolean).join(', '), // Concatenate address fields
            cardCity: city,
            cardState: state,
            cardZipCode: zipCode,
          };
        }
        const response:any = await this.adhocInvoiceService.doAdhocInvoicePayment(payload);
        if(response){
          this.paymentSuccess.emit(); 
          this.onPaymentSuccess(response);
        }
        console.log('payload: ', payload);
        
      }else{
        this.commonService.showToast('error','Error','Fill all the required fields')
      }
    } catch (error) {
      if(this.markAllAsTouched()){
      if (typeof error?.error?.error === "string") {
        this.commonService.showToast("error", "Error", error?.error?.error);
      } else {
        this.commonService.showToast(
          "error",
          "Error",
          error?.error?.message || "An Unexpected error occured."
        );
        console.log(error?.message);
      }
      }
    } finally {
    }
  }

  onPaymentSuccess(response:any){
    if (response?.Status) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Adhoc Payment Status",
        html: `Adhoc Number ${this.invoiceEmittedData?.data?.number} paid successfully`,
        timer: 10000, 
        showConfirmButton: false,
        timerProgressBar: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading(); 
          const closeButton = document.createElement("button");
          closeButton.innerHTML = "&times;";
          closeButton.classList.add("swal2-close");
          closeButton.style.position = "absolute";
          closeButton.style.top = "10px";
          closeButton.style.right = "10px";
          closeButton.style.fontSize = "1.5rem";
          closeButton.style.background = "none";
          closeButton.style.border = "none";
          closeButton.style.cursor = "pointer";

          closeButton.onclick = () => {
            Swal.close();
          };

          Swal.getHtmlContainer()?.appendChild(closeButton);
          const swalTimer = document.getElementById("swal-timer");
          let timerInterval = setInterval(() => {
            let currentTime = parseInt(swalTimer?.innerText || "10");
            if (currentTime > 1) {
              swalTimer!.innerText = (currentTime - 1).toString();
            } else {
              clearInterval(timerInterval);
            }
          }, 1000);
        },
      });
    } else if (!response?.Status || response?.exception) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Adhoc Payment Status",
        html:`Adhoc Number ${this.invoiceEmittedData?.data?.number} payment failed`,
        timer: 5000, 
        showConfirmButton: false,
        timerProgressBar: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading(); 
          const closeButton = document.createElement("button");
          closeButton.innerHTML = "&times;";
          closeButton.classList.add("swal2-close");
          closeButton.style.position = "absolute";
          closeButton.style.top = "10px";
          closeButton.style.right = "10px";
          closeButton.style.fontSize = "1.5rem";
          closeButton.style.background = "none";
          closeButton.style.border = "none";
          closeButton.style.cursor = "pointer";

          closeButton.onclick = () => {
            Swal.close();
          };

          Swal.getHtmlContainer()?.appendChild(closeButton);
          const swalTimer = document.getElementById("swal-timer");
          let timerInterval = setInterval(() => {
            let currentTime = parseInt(swalTimer?.innerText || "10");
            if (currentTime > 1) {
              swalTimer!.innerText = (currentTime - 1).toString();
            } else {
              clearInterval(timerInterval);
            }
          }, 1000);
        },
      });
    }
  }

  private formatMonth(month: number): string {
    return month < 10 ? `0${month}` : `${month}`;
  }
  getSubmitButtonClass(): string {
    return this.companyName === 'mingle' ? 'submit-button-mingle' : 'submit-button-unity';
  }
  getBorderClass(){
    return this.companyName === 'mingle' ? 'custom-card-mingle' : 'custom-card-unity';
  }
  getCompanyName(){
    this.companyName = this.authService.getCompanyName();
  }
  isChecked(): boolean {
    return this.useExistingAddress;
  }

  // Update value based on checkbox state
  toggleUseExistingAddress(event: Event): void {
    this.useExistingAddress = (event.target as HTMLInputElement).checked;
    console.log('Updated value of useExistingAddress:', this.useExistingAddress);
  
    if (!this.useExistingAddress) {
      this.getGooleFunctionalityStart(); // Initialize Google functionality
    } else {
      this.destroyGoogleFunctionality(); // Destroy Google functionality
    }
  }
  getPlaceholder(field: string): string {
    const placeholders = {
      address1: 'Enter your address',
      city: 'Enter your city',
      state: 'Enter your state',
      zipCode: 'Enter your zip code',
      nameOnCard: 'Name on card',
      cardNumber: 'Card number',
      cardCode: 'Security code',
    };
    return placeholders[field] || 'Enter value';
  }

  getExpireMonthPlaceholder(): string {
    return 'Select Month';
  }

  getExpireYearPlaceholder(): string {
    return 'Select Year';
  }





  onSubmit(): void {
    if (this.formData.valid) {
      console.log('Form Submitted:', this.formData.value);
    } else {
      console.log('Form is invalid');
      this.formData.markAllAsTouched();
    }
  }

  private getMonths(): string[] {
    return Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, '0'));
  }

  private getYears(): string[] {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: 10 }, (_, i) => (currentYear + i).toString());
  }
}
