import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customDate',
  pure: true 
})
export class CustomDatePipe implements PipeTransform {

  transform(value: Date | string): string {
    if (!value) {
      return '';
    }

    const date = new Date(value);

    // Format the date as "Sep 10"
    const options: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }
}
