import { Location } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, of } from 'rxjs';
import { ProfileService } from 'src/app/add-member-group/services/profile.service';
import { fadeInOut, fadeInOutSlow } from 'src/app/shared/services/app.animation';
import { CommonService } from 'src/app/shared/services/common.service';


@Component({
  selector: 'app-new-profile',
  templateUrl: './new-profile.component.html',
  styleUrls: ['./new-profile.component.scss'],
  animations:[fadeInOut]
})
export class NewProfileComponent {
  public id:any
  public showContact=false;
  public showContactCard=true;
  public changePass=true;
  profileImg:any
  public addChangeForm:FormGroup
  isUploading: boolean = false;
  public showEmployee=false;
  public showEmployeeCard=true;
  isEditing:boolean = false
  public showProfile=false;
  public showProfileCard=true
  profileData: any;
  showBackButton: boolean = false;
  public loadUser:boolean=false
  @Input() loadAgent:boolean;
  editForm:FormGroup
  empinfoForm:FormGroup
  passwordForm:FormGroup
  addProMainCard:FormGroup
  showOldPassword: boolean = false;
  showNewPassword: boolean = false;
  showConfirmPassword: boolean = false;
  @Output() loaderCHanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  isSkeletonLoader: boolean=true;
  constructor(private fb: UntypedFormBuilder,private router:Router,private profileService:ProfileService,private route:ActivatedRoute,private commonService:CommonService,    public location: Location,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.id=this.route.snapshot.params['id'];

    this.getProfileData()
    this.route.queryParams.subscribe(params => {
      this.showBackButton = params['showBack'] === 'true';
    
  
    });

    this.editForm = this.fb.group({
      firstName: [''],
      lastName: [''],
      email: [''],
      phone: [''],
      address: ['']
    });
    this.empinfoForm = this.fb.group({
      empID:[],
      job_title:[''],
      typeOfemployee:[''],
      department:[''],
      groups:['']

    })
    this.passwordForm = this.fb.group({
      old_password: ['', [Validators.required, Validators.minLength(8)]],
      new_password: ['', [Validators.required, Validators.minLength(8)]],
      confirm_password: ['', [Validators.required]]
    });
    this.populateForm()
  }
  back(){
    this.router.navigateByUrl(`/manage-user`)
  }
  getProfileData() {
  
    this.profileService.getProfileData(this.id).pipe(
      catchError((error: HttpErrorResponse) => {
        return of(null); 
      })
    ).subscribe((res: any) => {
      if (res) {
        this.profileData = res.data;
        this.profileImg = res.data?.profile_image
        if (this.profileImg) {
          localStorage.setItem('profileImg', this.profileImg);
      }
        this.isSkeletonLoader=false;
   
      }
 
    });
    
  }

  backToOrderList(){
    this.location.back();
  }
// new code 
  onProfileImageChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      const formData = new FormData();
      formData.append('userID', this.id); // Convert userID to string if necessary
      formData.append('image', file);
     this.isUploading = true;
      this.profileService.profileUpload(formData).subscribe({

        next: (response:any) => {
          this.isUploading = false;
          this.getProfileData()
          this.commonService.showToast("success", "Success", 'Profile uploaded Sucessfully');
          if (response?.image_url) {
            this.profileData.profile_image = response?.image_url;
          }
        },
        error: (error) => {
          this.isUploading = false;
          this.commonService.showToast("error", "Error", 'Something went wrong');
        }
      });
    }
  }
  onEdit(): void {
    this.isEditing = true;
    this.populateForm()
  }
  onCancel(): void {
    this.isEditing = false;
    this.populateForm(); // Reset form values to original data
  }
  populateForm(): void {
    this.editForm.patchValue({
      firstName: this.profileData.name,
      lastName: this.profileData.name,
      email: this.profileData.email,
      phone: this.profileData.agent.PhoneNumber,
      address: `${this.profileData.agent.AddressLine1}, ${this.profileData.agent.AddressLine2}`
    });
    this.empinfoForm.patchValue({
      empID:this.profileData?.id,
      job_title:this.profileData?.jobtitile,
      typeOfemployee:this.profileData?.typeofEmp,
      department:this.profileData?.groups,
      groups: this.profileData.usergroup
      ?.map((item: any) => item.Name)
      .join(', ') || ''

    })
  }
  onSave(): void {
    this.profileData = {
      ...this.profileData,
      firstName: this.editForm.value.firstName,
      lastName: this.editForm.value.lastName,
      email: this.editForm.value.email,
      agent: {
        PhoneNumber: this.editForm.value.phone,
        AddressLine1: this.editForm.value.address.split(',')[0]?.trim(),
        AddressLine2: this.editForm.value.address.split(',')[1]?.trim()
      }
    };
    console.log("form value",this.profileData,this.empinfoForm.value)
    this.isEditing = false; 
  }
  onSubmit(): void {
    if (this.passwordForm.valid) {
      const formData = this.passwordForm.value;
       
      console.log('Form Data:', formData);

      // Handle password change logic here
      if (formData.new_password === formData.confirm_password) {
        this.profileService.updatePassword(formData,this.id).subscribe((res:any)=>{
          if(res.Status){
            this.changePass=true;
            this.commonService.showToast("success", "Success", 'Password Updated  Successfully');
          }else{
            this.commonService.showToast('error', "Error", (res?.message ?? "Some Error Occurred"))

          }
    
        }, (err) => {
          this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
          console.log("error",err)
      
        })
      } else {
        this.commonService.showToast("error", "Error", 'New password and confirm password do not match');
        
      }
    } 
  }
  togglePasswordVisibility(field: string): void {
    if (field === 'old') {
      this.showOldPassword = !this.showOldPassword;
    } else if (field === 'new') {
      this.showNewPassword = !this.showNewPassword;
    } else if (field === 'confirm') {
      this.showConfirmPassword = !this.showConfirmPassword;
    }
  }
}
