import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpService } from 'src/app/shared/services/httpService.service';

@Injectable({
  providedIn: 'root'
})
export class AwaitingShipmentService {

  private filterSubject = new Subject<any>();
  filterSubject$: Observable<any> = this.filterSubject.asObservable();
  constructor(private http: HttpService) { }
  emitFilterType(selectedItem: any, type: any): void {
    this.filterSubject.next({ selectedItem, type });
  }
  resetOrderType(): void {
    this.filterSubject.next({ selectedItem: null, type: 'reset' });
  }
  getAwaitingShipmentOrders(searchterm: number): Observable<any[]> {
    const shipmentOrderurl = `api/orders/shipmentOrders`
    let params = new HttpParams()
      .set('searchTerm', searchterm.toString())
      .set('fromDate', '2024-06-23')
      .set('toDate', '2024-06-30');
    return this.http.get<any[]>(shipmentOrderurl, { params })

  }
  // GET api/orders/paymentStatusesList
  // GET api/orders/shipmentStatusesList
  // GET api/orders/status-list

  getOrderHeader() {
    try {
      let url = "api/orders/showTableHeader"
      let body = {
        "companyId": "UNITYCRM0021",
        "userID": this.http.getUser()?.userID,
        'type': "ShipmentOrders"
      }
      return this.http.get(url, body).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
  getOrderStatusLIst() {
    try {
      let url = "api/orders/status-list"
      let body = {
        "companyId": "UNITYCRM0021",
        "userID": this.http.getUser()?.userID,
      }
      return this.http.get(url, body).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
  getPaymentStatusList() {
    try {
      let url = "api/orders/paymentStatusesList"
      let body = {
        "companyId": "UNITYCRM0021",
        "userID": this.http.getUser()?.userID,
      }
      return this.http.get(url, body).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
  getshipmentStatusesList() {
    try {
      let url = "api/orders/shipmentStatusesList"
      let body = {
        "companyId": "UNITYCRM0021",
        "userID": this.http.getUser()?.userID,
      }
      return this.http.get(url, body).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  saveTableHeaders(data, type) {
    try {
      let url = "api/orders/saveTableHeader"
      let body = {
        "userID": this.http.getUser()?.userID,
        "companyId": "UNITYCRM0021",
        "fields": data,
        "type": type
      }
      return this.http.post(url, body, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  getCardStatData(data) {
    try {
      let url = "api/orders/getOrderMetrics"
      let body = {
        "userID": this.http.getUser()?.userID,
        "companyId": "UNITYCRM0021",
        ...data
      }
      return this.http.get(url, body, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  addCommentClick(data) {
    try {
      let url = "api/orders/addOrderActvity"

      return this.http.post(url, data, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
  cancelOrder(data) {
    try {
      let url = "api/orders/cancelOrder"

      return this.http.post(url, data, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  convertOrder(data) {
    try {
      let url = "api/Customer/ConvertOrderType"

      return this.http.post(url, data, false).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  //  /api/orders/saveTableHeader

  getAgents() {
    try {
      let url = "api/system/agents-list"
      let body = {
        "companyId": "UNITYCRM0021",
      }
      return this.http.get(url, body, true).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
}
