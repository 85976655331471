import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { NotificationService } from '../../services/notification.service';
import { Notification } from 'src/app/store/notifications/notification.model';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';
// import { faCoffee, faUser } from '@fortawesome/free-solid-svg-icons';
// import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { selectAllNotifications } from 'src/app/store/notifications/notification.selectors';
import { Store } from '@ngrx/store';
@Component({
  selector: 'app-global-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class GlobalNotificationComponent implements OnInit {
  notifications$ = this.store.select(selectAllNotifications); // This can be used with async pipe
  unreadCount$: Observable<number>;
  doNotDisturb = false;
  notifications: Notification[] = [];
  total:any
  isUserLoggedIn:boolean = false
  icon:string
  @Input() isOpen :boolean=false
  Company:any
  // faCoffee = faCoffee;
  // faUser = faUser;

  private notificationsSubscription: Subscription;


  constructor(private notificationService: NotificationService, private store: Store, private router: Router,private authService:AuthService ) {
    // this.requestNotificationPermission()
    // library.addIcons(faUser);
  }

  ngOnInit(): void {
    // Subscribe to notifications manually if you need to process them in code
    this.notificationsSubscription = this.notifications$.subscribe((notifications) => {
      this.notifications = notifications; // Store notifications in the component state
      console.log(this.notifications); // For debugging purposes
    });

    // Fetch unread notifications count
    this.unreadCount$ = this.notificationService.getUnreadCount();

    // Get company name from auth service
    this.Company = this.authService.getCompanyName();
  }

  ngOnDestroy(): void {
    // Unsubscribe to avoid memory leaks
    if (this.notificationsSubscription) {
      this.notificationsSubscription.unsubscribe();
    }
  }

  markAsRead(id: any): void {
    this.notificationService.markAsRead(id);
  }

  markAllAsRead(): void {
    this.notificationService.markAllAsRead();
  }
  handleClick(notification: Notification): void {
    this.notificationService.getmarAsRead(notification.id)
    const regex = /#(\d+)/; // Regular expression to extract the ID
    const match = notification?.Name?.match(regex);
    const ticketId = match ? match[1] : null; // Extract the ticket ID
    if (ticketId) {
      // Navigate with ticket ID as a parameter
      this.router.navigate(['/ticket-system'], {
        queryParams: { ticketId: ticketId, showMsg: true },
      }).then(() => {
        console.log(`Navigated to ticket with ID: ${ticketId}`);
      });
    } else {
      console.log('No ticket ID found in the notification name.');
    }
  }


  /**
   * Show a desktop notification.
   * @param notification The notification to display.
   */
  showDesktopNotification(notification: Notification): void {
      if (this.Company == "unity") {
        console.log("application name",this.Company)
         this.icon = '/assets/images/logo/Frame 728.png'
      } else if(this.Company == "mingle") {
        console.log("application name",this.Company)
        this.icon= 'https://www.minglemobile.com/cdn/shop/t/57/assets/favicon.svg?v=178931131784370144781732655519'
      }
    if ('Notification' in window && Notification.permission === 'granted') {
      const desktopNotification = new Notification(notification.Name, {
        body: notification.Message || 'You have a new notification.',
        icon: this.icon,
      });
      desktopNotification.onclick = () => {
        console.log('Notification clicked!');
        // this.handleClick(notification);
        window.focus();
    
      };
    }
  }
  

  /**
   * Toggle Do Not Disturb mode.
   */
  toggleDoNotDisturb(): void {
    this.doNotDisturb = !this.doNotDisturb;
    console.log(`Do Not Disturb: ${this.doNotDisturb}`);
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: any) {
    if (this.doNotDisturb) {
      event.preventDefault();
      event.returnValue = 'You have Do Not Disturb mode enabled. Are you sure you want to leave this page?';
    }
  }
}
