<div
  class="card px-3 py-2 " 
  [ngClass]="{ active: activeCardId === data?.title }"
  [ngStyle]="{ 'background-color': color }"
  *ngIf="!isSkeletonLoader"
  @fadeInOut
>
  <div class="d-flex flex-column align-items-start justify-content-center">
    <h1>
      {{ data?.count }}
    </h1>
    <h6 class="mb-0" [ngClass]="{ 'text-muted': activeCardId !== data?.title }">
      {{ data?.title }}
    </h6>
  </div>
</div>
<div *ngIf="isSkeletonLoader" @fadeInOut style="max-width: 100%">
  <app-skeleton-loader
    [cardCount]="1"
    [width]="'100%'"
    [height]="'100%'"
    [alignment]="'row'"
    shape="normal-card"
  ></app-skeleton-loader>
</div>
