<div class="card p-2" (mouseenter)="showDropdown = true" (mouseleave)="showDropdown = false">
  <div class="card-header card-no-border py-2">
    <div class="d-flex justify-content-between mb-2" style="position: relative">
      <h5>Subscription Chart</h5>
    </div>
    <div class="select" style="position: absolute; display: none;
      top: 1px;
      right: 16rem;">
      <select class="button1 primary mx-xl-4" (change)="onChartTypeChange($event)">
        <option value="line">Line</option>
        <option value="bar">Bar</option>
        <option value="area">Area</option>
      </select>
    </div>

  </div>
  <div class="card-body p-1">
    <div class="row m-0 overall-card overview-card">
      <div class="col-xl-12 col-md-8 col-sm-7 p-0 box-col-7">
        <div class="chart-right">
          <div class="row">
            <div class="col-xl-12">
              <div class="card-body p-0">
                <!-- <ul class="balance-data">
                   <li><span class="circle bg-secondary"></span><span class="f-light ms-1">Refunds</span></li>
                   <li><span class="circle bg-primary"> </span><span class="f-light ms-1">Earning</span></li>
                   <li><span class="circle bg-success"> </span><span class="f-light ms-1">Order</span></li>
                 </ul> -->
                <div class="current-sale-container order-container">
                  <div class="overview-wrapper" id="orderoverview">
                    <apx-chart (click)="onBarClick($event)" [series]="orderOverview?.series"
                      [chart]="orderOverview?.chart" [stroke]="orderOverview?.stroke" [grid]="orderOverview?.grid"
                      [plotOptions]="orderOverview?.plotOptions" [colors]="orderOverview?.colors"
                      [fill]="orderOverview?.fill" [labels]="orderOverview?.labels" [xaxis]="orderOverview?.xaxis"
                      [legend]="orderOverview?.legend" [yaxis]="orderOverview?.yaxis" [tooltip]="orderOverview?.tooltip"
                      [responsive]="orderOverview?.responsive"></apx-chart>
                  </div>
                  <!-- <div class="back-bar-container">
                     <div id="order-bar">
                      <apx-chart
                      [series]="orderBar.series"
                      [chart]="orderBar.chart"
                      [plotOptions]="orderBar.plotOptions"
                      [colors]="orderBar.colors"
                      [grid]="orderBar.grid"
                      [stroke]="orderBar.stroke"
                      [dataLabels]="orderBar.dataLabels"
                      [labels]="orderBar.labels"
                      [markers]="orderBar.markers"
                      [xaxis]="orderBar.xaxis"
                      [tooltip]="orderBar.tooltip"
                      [yaxis]="orderBar.yaxis"
                      [fill]="orderBar.fill"
                      [states]="orderBar.fill"
                      [responsive]="orderBar.responsive"
                      ></apx-chart>
                     </div>
                   </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-xl-3 col-md-4 col-sm-5 p-0 box-col-5">
         <app-right-chart-data></app-right-chart-data>
       </div> -->
    </div>
  </div>
</div>