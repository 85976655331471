import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdhocInvoiceComponent } from './adhoc-invoice.component';
import { AdhocFormComponent } from './adhoc-form/adhoc-form.component';
import { AdhocPreviewComponent } from './adhoc-preview/adhoc-preview.component';
import { CampaignEmailComponent } from './campaign-email/campaign-email.component';

const routes: Routes = [
  {
    path: '',
    component: AdhocInvoiceComponent, // Parent component
    children: [
      { path: '', redirectTo: 'form', pathMatch: 'full' }, // Default child route
      { path: 'form', component: AdhocFormComponent }, // Route for the form
      { path: 'adhoc-preview', component: AdhocPreviewComponent }, // Route for the preview
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdhocInvoiceRoutingModule { }
