import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdhocInvoiceRoutingModule } from './adhoc-invoice-routing.module';
import { AdhocInvoiceComponent } from './adhoc-invoice.component';
import { AdhocFormComponent } from './adhoc-form/adhoc-form.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdhocPreviewComponent } from './adhoc-preview/adhoc-preview.component';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatIconModule } from '@angular/material/icon';
import { AdhocPreviewTemplateComponent } from './adhoc-preview-template/adhoc-preview-template.component';
import { AdhocPlanChangeComponent } from './adhoc-plan-change/adhoc-plan-change.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from "../shared/shared.module";
import { AdhocBreakupTemplateComponent } from './adhoc-breakup-template/adhoc-breakup-template.component';
import { AdhocPlanRenewalComponent } from './adhoc-plan-renewal/adhoc-plan-renewal.component';
import { AdhocCustomComponent } from './adhoc-custom/adhoc-custom.component';
import { DownloadInvoiceComponent } from './download-invoice/download-invoice.component';
import { SendInvoiceComponent } from './send-invoice/send-invoice.component';
import { PayInvoiceComponent } from './pay-invoice/pay-invoice.component';
import { AdhocCreditCardPaymentComponent } from './adhoc-credit-card-payment/adhoc-credit-card-payment.component';
import { AdhocCvvPaymentComponent } from './adhoc-cvv-payment/adhoc-cvv-payment.component';
import { AdhocPaymentOptionsComponent } from './adhoc-payment-options/adhoc-payment-options.component';
import { AdhocGeneratedComponent } from './adhoc-generated/adhoc-generated.component';
import { CampaignEmailComponent } from './campaign-email/campaign-email.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AngularEditorModule } from '@kolkov/angular-editor';
@NgModule({
  declarations: [
    AdhocInvoiceComponent, // Your parent component
    CampaignEmailComponent,
    AdhocFormComponent, AdhocPreviewComponent, AdhocPreviewTemplateComponent, AdhocPlanChangeComponent, AdhocBreakupTemplateComponent, AdhocPlanRenewalComponent, AdhocCustomComponent, DownloadInvoiceComponent, SendInvoiceComponent, PayInvoiceComponent, AdhocCreditCardPaymentComponent, AdhocCvvPaymentComponent, AdhocPaymentOptionsComponent, AdhocGeneratedComponent,    // Your form component
  ],
  imports: [
    CommonModule,
    AdhocInvoiceRoutingModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatRadioModule, // For radio buttons
    ReactiveFormsModule, // For reactive forms,
    NgbModule,
    FormsModule,
    MatTooltipModule,
    SharedModule,
    BsDatepickerModule,
    AngularEditorModule 
],
providers: [NgbActiveModal],
exports:[AdhocFormComponent]
})
export class AdhocInvoiceModule {}
