import { SystemMonitorModule } from './system-monitor/system-monitor.module';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentComponent } from './shared/components/layout/content/content.component';
import { UserSettingsDashboard } from './user_settings/user_settings_dashboard/user_settings_dashboard.component';
import { LoginComponent } from './auth/login/login.component';
import { AuthGuardService } from './auth/services/auth-guard.service';
import { ManagePermissionComponent } from './user_settings/user_settings_dashboard/manage-permissions/manage-permissions.component';
import { CustomerProfileGuardService } from './shared/guard/customer-profile.guard';
import { ManageGroupPermissionComponent } from './user_settings/user_settings_dashboard/manage-group-permissions/manage-group-permissions.component';
import { NewManageUserComponent } from './user_settings/user_settings_dashboard/new-manage-user/new-manage-user.component';
import { EditManageUsersComponent } from './user_settings/user_settings_dashboard/edit-manage-users/edit-manage-users.component';
import { NewProfileComponent } from './user_settings/user_settings_dashboard/new-profile/new-profile.component';
import { NewManageAngentComponent } from './user_settings/user_settings_dashboard/new-manage-angent/new-manage-angent.component';
import { EditAddAgentComponent } from './user_settings/user_settings_dashboard/edit-add-agent/edit-add-agent.component';
import { GroupUserComponent } from './user_settings/user_settings_dashboard/group-user/group-user.component';
import { ManageCombosComponent } from './user_settings/user_settings_dashboard/manage-combos/manage-combos.component';
import { ManageComboItemsComponent } from './user_settings/user_settings_dashboard/manage-combo-items/manage-combo-items.component';
import { ManageMakeModelsComponent } from './user_settings/user_settings_dashboard/manage-make-models/manage-make-models.component';
import { ManageResourcesComponent } from './user_settings/user_settings_dashboard/manage-resources/manage-resources.component';
import { NewDashboardComponent } from './customer-dashboard/customer-pages/new-dashboard/new-dashboard.component';
import { TagTypeComponent } from './user_settings/user_settings_dashboard/tag-type/tag-type.component';
import { TicketTagsComponent } from './user_settings/user_settings_dashboard/ticket-tags/ticket-tags.component';
import { AllNotificationComponent } from './shared/components/all-notification/all-notification.component';
import { CampaignEmailComponent } from './adhoc-invoice/campaign-email/campaign-email.component';

const routes: Routes = [
  { path: 'fullfillment', redirectTo: 'fullfillment/provision-view', pathMatch: 'full', data: { breadcrumb: 'Fullfillment' } },
  {
    path: '',
    component: ContentComponent,
    data: { breadcrumb: 'Home' }, // Global breadcrumb for root path
    children: [
      { path: "", redirectTo: 'dashboard', pathMatch: 'full', data: { breadcrumb: 'Dashboard' } },
      {
        path: "dashboard",
        canActivate: [AuthGuardService],
        loadChildren: () => import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
        data: { breadcrumb: 'Dashboard' },
      },
      {
        path: "search-result",
        canActivate: [AuthGuardService],
        loadChildren: () => import("./search-result-page/search-result-page-routing.module").then((m) => m.SearchResultPageRoutingModule),
        data: { breadcrumb: 'Search Results' },
      },
      {
        path: 'review',
        canActivate: [AuthGuardService],
        loadChildren: () => import("./review/review.module").then((m) => m.ReviewModule),
        data: { breadcrumb: 'Review' },
      },
      {
        path: 'fullfillment',
        canActivate: [AuthGuardService],
        loadChildren: () => import("./fullfillment/fullfillment.module").then((m) => m.FullfillmentModule),
        data: { breadcrumb: 'Fullfillment' },
      },
      {
        path: 'awaiting-shipment',
        canActivate: [AuthGuardService],
        loadChildren: () => import("./awaiting-shipment/awaiting-shipment.module").then((m) => m.AwaitingShipmentModule),
        data: { breadcrumb: 'Order' },
      },
      {
        path: 'porting',
        canActivate: [AuthGuardService],
        loadChildren: () => import("./portIn-out/port.module").then((m) => m.PortModule),
        data: { breadcrumb: 'Porting' },
      },
      { 
        path: 'user-settings', 
        component: UserSettingsDashboard, 
        canActivate: [AuthGuardService], 
        data: { breadcrumb: 'User Settings' } 
      },
      { 
        path: 'manage-group', 
        component: GroupUserComponent, 
        canActivate: [AuthGuardService], 
        data: { breadcrumb: 'Manage Groups' } 
      },
      { 
        path: 'manage-user', 
        component: NewManageUserComponent, 
        canActivate: [AuthGuardService], 
        data: { breadcrumb: 'Manage Users' } 
      },
      { 
        path: 'update-agent', 
        component: EditAddAgentComponent, 
        canActivate: [AuthGuardService], 
        data: { breadcrumb: 'Update Agent' } 
      },
      { 
        path: 'group-user', 
        component: GroupUserComponent, 
        canActivate: [AuthGuardService], 
        data: { breadcrumb: 'Group user' } 
      },
      { 
        path: 'manage-combo', 
        component: ManageCombosComponent, 
        canActivate: [AuthGuardService], 
        data: { breadcrumb: 'Manage Combo'} 
      },
      { 
        path: 'combo-item', 
        component: ManageComboItemsComponent, 
        canActivate: [AuthGuardService], 
        data: { breadcrumb: ' Combo Item'} 
      },
      { 
        path: 'manage-make-model', 
        component: ManageMakeModelsComponent, 
        canActivate: [AuthGuardService], 
        data: { breadcrumb: 'Manage Make Model'} 
      },
      { 
        path: 'manage-resources', 
        component: ManageResourcesComponent, 
        canActivate: [AuthGuardService], 
        data: { breadcrumb: 'Manage Resources'} 
      },
      { 
        path: 'manage-agent', 
        component: NewManageAngentComponent, 
        canActivate: [AuthGuardService], 
        data: { breadcrumb: 'Manage Agent' } 
      },
      { 
        path: 'new-profile/:id', 
        component: NewProfileComponent, 
        canActivate: [AuthGuardService], 
        data: { breadcrumb: 'Profile' } 
      },
      { 
        path: 'edit-users', 
        component: EditManageUsersComponent, 
        canActivate: [AuthGuardService], 
        data: { breadcrumb: 'Manage Users' } 
      },
      { 
        path: 'tag-type', 
        component: TagTypeComponent, 
        canActivate: [AuthGuardService], 
        data: { breadcrumb: 'Tag type' } 
      },
      { 
        path: 'tag-list', 
        component: TicketTagsComponent, 
        canActivate: [AuthGuardService], 
        data: { breadcrumb: 'Tag List' } 
      },
      {
        path: "manage-permissions",
        component: ManagePermissionComponent,
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Manage Permissions' },
      },
      {
        path: "manage-group-permissions",
        component: ManageGroupPermissionComponent,
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Manage Group Permissions' },
      },
      {
        path: "customer-dashboard",
        canActivate: [AuthGuardService, CustomerProfileGuardService],
        loadChildren: () => import("./customer-dashboard/customer-dashboard.module").then((m) => m.CustomerDashboardModule),
        data: { breadcrumb: 'Customer Dashboard',isCustomerProfilePage:true },
      },
      {
        path: "ticket-system",
        canActivate: [AuthGuardService],
        loadChildren: () => import("./ticket-system/ticket-system.module").then((m) => m.TicketSystemModule),
        data: { breadcrumb: 'Ticket System' },
      },
      {
        path: "reports",
        canActivate: [AuthGuardService],
        loadChildren: () => import("./reports/reports.module").then((m) => m.ReportsModule),
        data: { breadcrumb: 'Reports' },
      },
      {
        path: "subscriptions",
        canActivate: [AuthGuardService],
        loadChildren: () => import("./subscriptions/subscriptions.module").then((m) => m.SubscriptionsModule),
        data: { breadcrumb: 'Subscriptions' },
      },
      {
        path: "system-monitor",
        // canActivate: [AuthGuardService],
        loadChildren: () => import("./system-monitor/system-monitor.module").then((m) => m.SystemMonitorModule),
        data: { breadcrumb: 'System Monitor' },
      },
      {
        path: "scheduled-downgrades",
        canActivate: [AuthGuardService],
        loadChildren: () => import("./scheduled-downgrades/scheduled-downgrades.module").then((m) => m.ScheduledDowngradeModule),
        data: { breadcrumb: 'Scheduled Downgrades' },
      },
      {
        path: "inventory",
        canActivate: [AuthGuardService],
        loadChildren: () => import("./inventory/inventory.module").then((m) => m.InventoryModule),
        data: { breadcrumb: 'Inventory' },
      },
      {
        path: "excutive-dashboard",
        canActivate: [AuthGuardService],
        loadChildren: () => import("./excutive-dashboard/my-dashboard.module").then((m) => m.MyDashboardModule),
      },
      {
        path: "excutive-dashboard",
        canActivate: [AuthGuardService],
        data: { breadcrumb: 'Executive Dashboard' },
        loadChildren: () => import("./excutive-dashboard/my-dashboard.module").then((m) => m.MyDashboardModule),
      },
      {
        path: "add-member-group",
        canActivate: [AuthGuardService],
        loadChildren: () => import("./add-member-group/add-member-group.module").then((m) => m.AddMemberGroupModule),
        data: { breadcrumb: 'Add Member Group' },
      },
      {
        path: "profile",
        canActivate: [AuthGuardService],
        loadChildren: () => import("./profile/profile.module").then((m) => m.ProfileModule),
        data: { breadcrumb: 'Profile' },
      },

      {
        path: "bulk-report",
        canActivate: [AuthGuardService],
        loadChildren: () => import("./bulk-reports/bulk-reports.module").then((m) => m.BulkReportsModule),
        data: { breadcrumb: 'Bulk Reports' },
      },
      {
        path: "bulk-process",
        canActivate: [AuthGuardService],
        loadChildren: () => import("./bulk-users/bulk-users.module").then((m) => m.BulkUsersModule),
        data: { breadcrumb: 'Bulk Process' },
      },
      {
        path: "recent-customer-searches",
        canActivate: [AuthGuardService],
        loadChildren: () => import("./recent-customer-searches/recent-customer-searches.module").then((m) => m.RecentCustomerSearchesModule),
        data: { breadcrumb: 'Recent Customer Searches' },
      },

      {
        path: "new-reports",
        canActivate: [AuthGuardService],
        loadChildren: () => import("./reports-new/reports.module").then((m) => m.ReportsNewModule),
        data: { breadcrumb: 'Analytics Reports' },
      },
      {
        path: "transaction",
        canActivate: [AuthGuardService],
        loadChildren: () => import("./transactions/transaction.module").then((m) => m.TransactionModule),
        data: { breadcrumb: 'Transaction' },
      },
      {
        path: "renewal-reports",
        canActivate: [AuthGuardService],
        loadChildren: () => import("./renewal-reports/renewal-reports.module").then((m) => m.RenewalReportsModule),
        data: { breadcrumb: 'Renewal Reports' },
      },
      // {
      //   path: "plan-config",
      //   canActivate: [AuthGuardService],
      //   loadChildren: () => import("./plan-config/plan-config.module").then((m) => m.PlanConfigModule),
      //   data: { breadcrumb: 'Plan Configuration' },
      // },
      {
        path: 'auth/login',
        component: LoginComponent,
        data: { breadcrumb: 'Login' },
      },
      {
        path: 'new-dashboard',
        component:NewDashboardComponent ,
        data: { breadcrumb: 'Login' },
      },
      {
        path: "customer-email",
        canActivate: [AuthGuardService],
        loadChildren: () => import("./customer-email/customer-email.module").then((m) => m.CustomerEmailModule),
        data: { breadcrumb: 'Customer Email' },
      },
      {
        path: "adhoc-invoice",
        canActivate: [AuthGuardService],
        loadChildren: () => import("./adhoc-invoice/adhoc-invoice.module").then((m) => m.AdhocInvoiceModule),
        data: { breadcrumb: 'Ad-hoc Invoice' },
      },
            {path:'campaign-email',component:CampaignEmailComponent,
              data: { breadcrumb: 'Campaign Email' },

            },
      {
        path: 'all-notification',
        component:AllNotificationComponent ,
        data: { breadcrumb: 'All Notification' },
      },
    ]
  },
  {
    path: '**',
    redirectTo: '/review',
    data: { breadcrumb: 'Review' }
  }
];



@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'disabled',
    scrollPositionRestoration: 'disabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
