import { Component } from '@angular/core';
import { UserAgentService } from '../../services/user-agent.service';
import { ManageAgentService } from '../../services/manage-agent.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { fadeInOut } from 'src/app/shared/services/app.animation';

@Component({
  selector: 'app-new-manage-angent',
  templateUrl: './new-manage-angent.component.html',
  styleUrls: ['./new-manage-angent.component.scss'],
    animations:[fadeInOut]
})
export class NewManageAngentComponent {
  agentList: any;
  public pageDropdownMenu: any = [10, 20, 30];
  public itemsPerPage: number = 50;
  public totalItems: number;
  currentPage: number = 1; 
  totalPages: number = 1;
  public first_page_url: any = "";
  public last_page_url: any = "";
  public next_page_url: any = "";
  isSkeletonLoader: boolean=true;

  constructor(private fb:FormBuilder,private userAgentService:UserAgentService,private agentService:ManageAgentService,private commonService:CommonService,
    private router:Router){

    }

    ngOnInit(): void {
    this.getAgentList('')
      
    }

  filterdData(event:any){
    let data=event.target.value
    this.getAgentList(data);
    // console.log('getUser data: ',this.getUserList(data))
  }

  getAgentList(search){
    let obj: any = {
   "per_page": this.itemsPerPage,
   "page": this.currentPage,
   "searchTerm":search
 }
 this.agentService.getAgentList1(obj).subscribe((res:any)=>{
   this.agentList=res?.data.data;
   this.totalItems = res.data.total
   this.currentPage = res.data.current_page
   this.first_page_url = res.data.first_page_url
   this.last_page_url = res.data.last_page_url
   this.next_page_url = res.data.next_page_url
   
   // console.log('cities:::',this.cities)
   setTimeout(()=>{
    this.isSkeletonLoader=false
  },1500)
   
 })
}
onChangeNumberOfPage() {
  this.getAgentList('')
}
pageChange(event) {
  this.currentPage = event
  this.getAgentList('')
}
navigateForPermission(id:any,userID:any){
  this.router.navigate(["/manage-permissions"], {
    queryParams: { id: id,userID:userID}
  })
}
back(){
  this.router.navigateByUrl(`/user-settings`)
}
viewProfile(id) {
  this.router.navigate(["/new-profile/"], {
    queryParams: { id: id, showBack: true}
  })
  this.router.navigate([`/new-profile/${id}`]);
}
editRouter(user:any){
  this.router.navigate([`/update-agent`],{state:{user:user}})
}
}
