<div class="form-container">
    <div class="section-heading text-start mb-4">
        <h5 class="fw-bold heading text-primary">Custom Invoice</h5>
        <p class="text-muted sub-heading mt-2">
          Provide the required details to generate a custom invoice.
        </p>
      </div>
      
    <form [formGroup]="invoiceForm" >
      <!-- Amount to Generate -->
      <div class="form-group">
        <label for="amount">Amount to Generate</label>
        <div class="input-group mb-3">
            
            <span class="input-group-text" id="inputGroup-sizing-sm">$</span>
            <input
              type="number"
              id="amount"
              class="form-control"
              formControlName="amount"
              placeholder="Enter amount"
            />
          </div>                   
        <small *ngIf="invoiceForm.get('amount')?.invalid && invoiceForm.get('amount')?.touched" class="text-danger">
          Amount is required and must be greater than 0.
        </small>
      </div>
  
      <!-- Description -->
      <div class="form-group custom-card">
        <label for="description">Description</label>
        <angular-editor
          formControlName="description"
          [config]="editorConfig"
        ></angular-editor>
        <small *ngIf="invoiceForm.get('description')?.invalid && invoiceForm.get('description')?.touched" class="text-danger">
          Description is required.
        </small>
      </div>

    </form>
  </div>
  