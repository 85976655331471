<div class=" p-4">
    <h3>Payment</h3>
    <p class="text-muted">All transactions are secure and encrypted.</p>
  
    <div class="bg-light p-0 pb-2 rounded">
      <div class="d-flex justify-content-between align-items-center rounded px-4 py-2 form-background">
        <h5 class="m-0 text-dark">Credit Card</h5>
        <div class="credit-card-icons d-flex">
          <img
            src="../../../../../assets/images/credit-cards/card.png"
            alt="master-card"
            class="credit-card-icon"
            [ngClass]="{ 'disabled': !isExistingCardType('MasterCard') }"
          />
          <img
            src="../../../../../assets/images/credit-cards/american-express.png"
            alt="amex"
            class="credit-card-icon"
            [ngClass]="{ 'disabled': !isExistingCardType('Amex') }"
          />
          <img
            src="../../../../../assets/images/credit-cards/visa.png"
            alt="visa"
            class="credit-card-icon"
            [ngClass]="{ 'disabled': !isExistingCardType('Visa') }"
          />
        </div>
      </div>
  
      <form
        [formGroup]="formDataWithCVV"
        class="mt-3 custom-form-width-cvv"
        (ngSubmit)="onSubmitWithCVV()"
      >
        <div class="form-group mb-3">
          <h5 class="text-dark" style="white-space: nowrap;">
            Pay with Card Number:
            <span style="display: inline;" class="text-danger">
              {{creditCarddetail?.cardNumber }}
            </span>
          </h5>
        </div>
        <div class="form-group mb-3 custom-cvv-width">
          <input
            type="password"
            maxlength="4"
            formControlName="cardCode"
            class="form-control"
            placeholder="CVV"
          />
        </div>
        <div
          *ngIf="
            formDataWithCVV.get('cardCode')?.touched &&
            formDataWithCVV.get('cardCode')?.hasError('required')
          "
          class="text-danger"
        >
          CVV is required.
        </div>
      </form>
    </div>
  
    <button
      type="submit"
      class="btn submit-button w-100 mt-3 d-flex align-items-center justify-content-center"
      (click)="onSubmitWithCVV()"
    >
      <h5>Pay Now</h5>
    </button>
  </div>
  