import { Component, OnDestroy } from '@angular/core';
import { TicketSystemService } from '../services/ticket-system.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { Subscription } from 'rxjs';

import { FilterService } from 'src/app/shared/services/common-filter.service';
import { PermissionService } from 'src/app/shared/services/permission-service.service';

@Component({
  selector: '',
  templateUrl: './ticket-dashboard.component.html',
  styleUrls: ['./ticket-dashboard.component.scss']
})
export class TicketDashboardComponent implements OnDestroy {
  isDisabledMyTicket = false;
  private subscription: Subscription;
  filterConfig = [
    { type: 'text', key: 'searchText', label: 'Ticket', placeholder: 'Enter ticket name , id ...' },
    { type: 'text', key: 'CustomerId', label: 'Customer Id', placeholder: 'Enter Customer Id...' },
    { type: 'dateRange', key: 'dateRange', label: 'Date Range' },
    { type: 'select', key: 'actions', label: 'Actions', options: [], bindValue: "Name", bindLabel: "DisplayName" },
    // { type: 'buttonGroup', key: 'ticket', label: 'Ticket Status', options: [], bindValue: "id", bindLabel: "DisplayName" }
  ];
  appliedFilters: any;
  sourceType: any = [];
  buttons: any;
  changeView: boolean;
  showViewChangeIcon: boolean;


  constructor(
    public commonService: CommonService,
    public ticketSystemService: TicketSystemService,
    private filterService: FilterService,
    private permissionService :PermissionService
  ) { }

  ngOnInit() {
    this.setBreadcrumb();
    this.subscription = this.commonService.myTicketsSubject$.subscribe((value) => {
      this.isDisabledMyTicket = value;
    });
    this.getSource()
    this.getButton()
  }

  setBreadcrumb() {
    this.commonService.setbreadCrumb({
      "title": 'Ticket System',
      // "items": ['#'],
      "active_item": 'Ticket System'
    })
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onFiltersChanged(filters: any) {
    this.filterService.updateFilters(filters)
  }

  async getSource() {

    try {
      const res: any = await this.permissionService.getResourceCategoryById('TABS')
      if (res) {
        this.sourceType = res?.resource_types[0]?.resources
        const ticketBoardView = this.sourceType.find(source => source.Name === 'TICKET_BOARD_VIEW');
        if (ticketBoardView?.resource_permission[0]?.CanView === 1) {
          this.showViewChangeIcon = true; 
          this.changeView= false
        } else {
          this.showViewChangeIcon = false; 
          this.changeView= true
        }
        this.filterConfig = this.filterConfig.map((filter: any) => {
          if (filter.key === 'ticket') {
            const filteredOptions = this.sourceType
              .filter(source => source.resource_permission[0]?.CanView === 1)
            return { ...filter, options: filteredOptions };
          }
          return filter;
        });

      }
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }

  }

  async getButton() {
    try {
      const res: any = await this.permissionService.getResourceCategoryById('ACTION_BUTTONS')
      if (res.Status) {
        this.buttons = res?.resource_types?.filter(resource => resource.Name === 'ACTION_BUTTON_TICKETS')?.resources;
        this.filterConfig = this.filterConfig.map((filter: any) => {
          if (filter.key === 'actions') {
            const filteredOptions = this.buttons
              .filter(button => button.resource_permission[0]?.CanView == 1)
            return { ...filter, options: filteredOptions };
          }
          return filter;
        })
      }
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }

  }

  onChangeViewClick() {
    this.changeView = !this.changeView
  }


}
