import { Component, Input, OnInit } from '@angular/core';
import { PlanChangeService } from 'src/app/customer-dashboard/quick-links/plan-change/services/plan-change.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { HttpService } from 'src/app/shared/services/httpService.service';
import { AdhocInvoiceService } from '../shared/adhoc-invoice.service';
import { fadeInOut } from 'src/app/shared/services/app.animation';
import { AdhocStateService } from '../shared/adhoc-state.service';
import { AdhocBreakupService } from '../shared/adhoc-breakup.service';

@Component({
  selector: 'app-adhoc-plan-renewal',
  templateUrl: './adhoc-plan-renewal.component.html',
  styleUrls: ['./adhoc-plan-renewal.component.scss'],
  animations:[fadeInOut]
})
export class AdhocPlanRenewalComponent implements OnInit{
@Input() customerData:any;
  adhocType:string='Plan Renewal'
planRenewalList:any[] = [];
  plans: any;
  currentPlan: any;
  curentPlanIdDetailsInSystem: any;
  advan
isPlanSkeleton: any;
selectedPlanId: any;
isSkeletonLoader: boolean=true;
advancePlanId:any
advancePlan:any
  advancePlanDetails: any;
constructor(
  private httpService:HttpService, 
  private planChangeService:PlanChangeService,
  private commonService:CommonService,
  private adhocInvoiceService:AdhocInvoiceService,
private stateService:AdhocStateService,
private adhocBreakupService: AdhocBreakupService){

}
async ngOnInit(){
  const customerId = this?.customerData?.personalInfo?.customerId;
  if (customerId) {
    await this.getCustomerAdvancePlan(customerId);
    await this.getCustomerOtherInfo(customerId);
    console.log('customerId: ', customerId);
    console.log('customerId: ', customerId);


    this.updatePlanRenewalList();

    // Automatically set the current plan as the selected plan
    if (this.currentPlan?.PlanId) {
      this.selectedPlanId = this.currentPlan.PlanId;
    }
    this.setBreakupDetailsForCurrentPlan(this.currentPlan);
  }
}

async getCustomerAdvancePlan(customerId:any){
  try {
    console.log('customerId: ', customerId);

    const response:any = await this.adhocInvoiceService.getCustomerAdvancePlan(customerId);
    if(response?.Status){
      this.advancePlanId = response?.data?.data[0]?.PlanId;
      if(this.advancePlanId){
        const params = {
          planId: this.advancePlanId,
          CustomerId: customerId,
        };
    
        console.log('params: ', params);
    
        const planDetailsResponse: any = await this.planChangeService.getPlansDetails(params);
      this.advancePlanDetails = planDetailsResponse?.data;

        if(planDetailsResponse?.Status){
          if(response?.data?.data?.length > 0){
            console.log('length: ',response?.data?.data?.length );
            const advancePlanDetail = {
              PlanId: this.advancePlanId,
              Name: this.advancePlanDetails?.plan?.DisplayName || "N/A",
              Price: this.advancePlanDetails?.planDetail?.MonthlyPaymentAmount || "0.00",
              PlanData: this.advancePlanDetails?.planDetail?.PlanData || "N/A",
              ExpirationDays: this.advancePlanDetails?.planDetail?.ExpirationDays || 0,
              VoiceText: this.advancePlanDetails?.planDetail?.VoiceText || "N/A",
              savingAmount: '', // Default as empty
              ImapgePath: this.advancePlanDetails?.planDetail?.ImapgePath || "N/A",
              isImageLoaded: true,
              isAdvanceplan:true,
            };
            this.advancePlan = advancePlanDetail;
            console.log(
              'advance plan: ', this.advancePlan
            );
          }
          
        }
    this.isSkeletonLoader=false
      }
    this.isSkeletonLoader=false

    }else{
    this.isSkeletonLoader=false


    }
  } catch (error) {
    // this.commonService.showToast('error','error','api called successfully')
    
  }
}
setBreakupDetailsForCurrentPlan(plan: any): void {
  console.log('Setting breakup details for current plan:', plan);
  const customerId = this?.customerData?.personalInfo?.customerId;
  if (!customerId) {
    console.error('Customer ID is missing in setBreakupDetailsForCurrentPlan.');
    return;
  }
  this.sendBreakupDetails(plan, false, customerId); // Send breakup details for the current plan
}
async getCustomerOtherInfo(customerId){
  try {
    const customerdetails:any = await  this.adhocInvoiceService.getCustomerOtherInfo(customerId);
    if(customerdetails?.Status && customerdetails?.data){
      const planId = customerdetails?.data[0]?.PlanID;
      console.log('planId: ', planId);
      await this.getCurentPlanIdDetailsInSystem(planId,customerId);
    }else{
      return
    }

  } catch (error) {
    
  }
}
async getCurentPlanIdDetailsInSystem(planId: string, customerId:any) {
  try {


    // Plan does not exist, fetch its details
    const params = {
      planId: planId,
      CustomerId: customerId
    };

    console.log('params: ', params);

    const planDetailsResponse: any = await this.planChangeService.getPlansDetails(params);

    if (planDetailsResponse?.Status) {
      this.curentPlanIdDetailsInSystem = planDetailsResponse?.data;
      console.log('curentPlanIdDetailsInSystem: ', this.curentPlanIdDetailsInSystem);

      const currentPlanDetail = {
        PlanId: planId,
        Name: this.curentPlanIdDetailsInSystem?.plan?.DisplayName || "N/A",
        Price: this.curentPlanIdDetailsInSystem?.planDetail?.MonthlyPaymentAmount || "0.00",
        PlanData: this.curentPlanIdDetailsInSystem?.planDetail?.PlanData || "N/A",
        ExpirationDays: this.curentPlanIdDetailsInSystem?.planDetail?.ExpirationDays || 0,
        VoiceText: this.curentPlanIdDetailsInSystem?.planDetail?.VoiceText || "N/A",
        savingAmount: '', // Default as empty
        ImapgePath: this.curentPlanIdDetailsInSystem?.planDetail?.ImapgePath || "N/A",
        isImageLoaded: true
      };

      // Add the fetched plan to the 0th index
      // this.plans.unshift(currentPlanDetail);
      this.currentPlan = currentPlanDetail; // Set it as the current plan
    } else {
      this.commonService.showToast('error', 'Error', planDetailsResponse?.message);
    }
  } catch (error) {
    this.commonService.showToast('error', 'Error', error?.message);
  }
}
updatePlanRenewalList(): void {
  this.planRenewalList = []; // Initialize the list

  // Add the current plan if it exists
  if (this.currentPlan && this.currentPlan.PlanId) {
    this.planRenewalList.push({ type: 'Current Plan', ...this.currentPlan });
  }

  // Add the advance plan if it exists and has a valid PlanId
  if (this.advancePlan && this.advancePlan.PlanId) {
    this.planRenewalList.push({ type: 'Advance Plan', ...this.advancePlan });
  }

  // Handle the scenario where neither plan is available
  if (this.planRenewalList.length === 0) {
    this.commonService.showToast(
      'info',
      'No Plans Available',
      'No valid plans are available for renewal.'
    );
  }

  console.log('Updated planRenewalList:', this.planRenewalList);
}


private isCurrentPlan(plan: any): boolean {
  if (this.currentPlan?.PlanId === plan?.PlanId) {
    this.commonService.showToast('info', 'Info', `can't generate ad-hoc invoice for current plan`);
    return true;
  }
  return false;
}
selectPlan(plan: any, planType: string): void {
  // Check if the plan is inactive

  this.stateService.setSelectedPlan(plan);
  // this.stateService.setValidationState(true)
  this.selectedPlanId = plan.PlanId; 
  console.log('selectedplanId: ', this.selectedPlanId);
  this.handleAvailablePlan(plan, plan?.PlanId);


}

private async handleAvailablePlan(plan: any, previousPlanId: number | null): Promise<void> {
  const customerId = this?.customerData?.personalInfo?.customerId;

  if (!customerId) {
    console.error('Customer ID is missing in handleAvailablePlan.');
    return;
  }

  try {

    console.log('sendBreakupDetails: ',plan);
    if(plan?.isAdvanceplan){
    await this.sendBreakupDetails(plan, true, customerId);
    }else{
      await this.sendBreakupDetails(plan, false, customerId);
    }
  } catch (error) {
    console.error('Error in handleAvailablePlan:', error);
  }
}


async sendBreakupDetails(plan: any, isAdvancePlan?: boolean, customerid?: any): Promise<void> {
  const adhocTypeDetails = plan;

  try {
    await this.adhocBreakupService.setAdhocDetailsForBreakupInputs(
      { adhocType: this.adhocType, adhocTypeDetails },
      isAdvancePlan,
      customerid
    );
  } catch (error) {
    console.error('Error in sendBreakupDetails:', error);
    throw error; // Propagate the error to handle it in the calling method
  }
}
}
