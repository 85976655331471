import { Component, HostListener } from '@angular/core';
import { SubscriptionService } from '../service/subscription.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss']
})
export class SubscriptionsComponent {
  selectedInterval: any = 'quarterly'; // Default to "Quarterly (3 Month)"
  public startDate: Date;
  public endDate: Date;
  public dataArray = [];
  bsRangeValue:Date[] = [new Date(), new Date()];
  advanceSearchValues1:any = { fromDate: '', toDate: '' };
constructor(private subscriptionService: SubscriptionService,private route:Router){
  const endDate = new Date();
  const startDate = new Date();
  startDate.setDate(startDate.getDate() - 30);
  this.bsRangeValue = [startDate, endDate];
}
isDatePickerVisible:boolean=false;
ngOnInit(): void {
  const endDate = new Date();
  const startDate = new Date();
  startDate.setDate(startDate.getDate() - 30); 
  this.advanceSearchValues1.fromDate = this.formatDate(startDate);
  this.advanceSearchValues1.toDate = this.formatDate(endDate);
  this.getkpiData()
}
  onDropdownChange(event: any) {
    const selectedValue = event.target.value;
    const currentDate = new Date();
    switch (selectedValue) {
      case 'quarterly':
        this.isDatePickerVisible=false
        this.selectedInterval = 'quarterly';
        this.setDateRange(currentDate, 3);
        break;
      case 'semi-annually':
        this.isDatePickerVisible=false
        this.selectedInterval = 'semi-annually';
        this.setDateRange(currentDate, 6);
        break;
      case 'annually':
        this.isDatePickerVisible=false
        this.selectedInterval = 'annually';
        this.setDateRange(currentDate, 12);
        break;
      case 'custom':
        this.isDatePickerVisible=true
        this.selectedInterval = 'custom';
        break;
      default:
        this.selectedInterval = 3; // Set default as Quarterly if needed
        this.setDateRange(currentDate, 3);
    }
  this.getkpiData()
  }

  toggleDatePicker(): void {
    this.isDatePickerVisible = !this.isDatePickerVisible;
  }
  
  /** Utility method to set date range */
setDateRange(currentDate: Date, months: number): void {
  const startDate = new Date(currentDate);
  startDate.setMonth(startDate.getMonth() - months); // Subtract months

  this.advanceSearchValues1 = {
    fromDate: this.formatDate(startDate),
    toDate: this.formatDate(currentDate)
  };
}
    // Close the date picker when clicking outside
    @HostListener('document:click', ['$event'])
    onClickOutside(event: Event): void {
      const target = event.target as HTMLElement;
      if (!target.closest('.date-picker')) {
        this.isDatePickerVisible = false; // Close the date picker
      }
    }
  transectionDetail(event: Event) {
    event.stopPropagation();
    this.subscriptionService.setTransectionDetail(true);
    this.route.navigate(['/subscriptions/transection-details']);
  }
  dataChanged() {
    if (this.bsRangeValue && this.bsRangeValue.length === 2) {
      const [start, end] = this.bsRangeValue;
      this.startDate = start;
      this.endDate = end;
      this.updateDateRange();
      this.getkpiData()  
    }
  }
  stop(event: Event) {
    event.stopPropagation();
  }
  updateDateRange() {
    this.advanceSearchValues1 = {
      fromDate: this.formatDate(this.startDate),
      toDate: this.formatDate(this.endDate)
    };
  }
  formatDate(date: Date): string {
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear().toString().slice(-2);
    return `${year}-${month}-${day}`;
  }

  async getkpiData() {
    let data={
      interval:this.selectedInterval,
      endDate:this.advanceSearchValues1?.toDate,
      startDate:this.advanceSearchValues1?.fromDate
    }
    this.subscriptionService.getKpiData(data).subscribe(
      (res: any) => {
        if (res.Status) {
          this.dataArray = res?.data
          if (res?.data?.data?.length === 0) {
            // this.showmsg = true
            // this.commonService.showToast('success', "Success", 'No Record Found');
          }
          // this.isInvoiceSkeletonLoader=false;
          // this.isHistorySkeletonLoader=false

        }
        
      },
      (error: any) => {
        console.error("Error fetching payment history:", error);
        // this.commonService.showToast('error', "Error", 'Failed to fetch payment history');
        // this.isInvoiceSkeletonLoader=false
        // this.isHistorySkeletonLoader=false
        
      }
    );
  }
}
