<div class="table-responsive table-hover  table-card p-0 " *ngIf="!showInvoice" style="max-width: 100% !important;">
  <table class="table table-nowrap mb-0">
    
    <tbody>
      <tr *ngFor="let row of visibleRows; let i = index">
        <td data-label="Due Date" class="text-nowrap">
          {{ row?.CreatedDate | date: 'MMM d, y' }}
        </td>
        <td data-label="Description">
          {{ row?.Description || "-" }}
        </td>
        <td data-label="Status">
          {{ row?.Status || "-" }}
        </td>
        <td data-label="Total Amount">
          ${{ row?.Amount | number: '1.2-2' }}
        </td>
        <td data-label="Actions" class="text-center">
          <div class="d-flex gap-2 justify-content-center">
            <button class="btn btn-xs btn-primary" (click)="viewInvoice(row)">View</button>
            <button class="btn btn-xs btn-secondary" (click)="sendInvoice(row)">Send</button>
          </div>
        </td>
      </tr>

      <tr *ngIf="invoiceList.length === 0">
        <td colspan="5" class="text-center text-muted fw-bold">
          No invoices found.
        </td>
      </tr>
    </tbody>
  </table>

  <div class="text-center " *ngIf="invoiceList.length > maxRowsToShow" @fadeInOut>
    <button class="btn btn-link text-decoration-none" (click)="toggleView()">
      {{ isExpanded ? "View Less" : "View More" }}
    </button>
  </div>
</div>

<div *ngIf="showInvoice">
  <button class="custom-btn bg-transparent d-flex align-items-center " (click)="goBack()">
    <i class="icon-arrow-left fw-semibold "></i>
</button>
  <app-invoice-template [invoiceData]="invoiceData" [customerId]="CustomerId"></app-invoice-template>
</div>