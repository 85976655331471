<p>carousel-skeleton works!</p>
<div class="carousel-skeleton">
    <!-- Skeleton for individual card -->
    <div class="skeleton-card" *ngFor="let item of skeletonItems">
      <div class="skeleton-image shimmer"></div>
      <div class="skeleton-content">
        <div class="skeleton-title shimmer"></div>
        <div class="skeleton-line shimmer"></div>
        <div class="skeleton-line shimmer"></div>
      </div>
    </div>
  </div>
  