<div class="" >

  <div class="p-0 pb-2 rounded d-flex flex-column justify-content-center">

    <form [formGroup]="formData" class="mt-3 custom-form-width d-flex flex-md-column flex-sm-column ">

      <!-- Payment Details Form -->
      <div class="col-md-12">
        <h3>Payment</h3>
        <p class="text-muted">All transactions are secure and encrypted.</p>

        <ng-container>
          <div class="d-flex justify-content-between align-items-center custom-card rounded py-1  mb-3" [ngClass]="getBorderClass()" >
            <h5 class="m-0 " style="letter-spacing: 2px;">Credit card</h5>
            <div class="credit-card-icons d-flex">
              <img src="../../../../../assets/images/credit-cards/card.png" alt="master-card"
                class="credit-card-icon" [ngClass]="{'disabled': !isCardTypeValid('MasterCard')}">
              <img src="../../../../../assets/images/credit-cards/american-express.png" alt="amex"
                class="credit-card-icon" [ngClass]="{'disabled': !isCardTypeValid('Amex')}">
              <img src="../../../../../assets/images/credit-cards/visa.png" alt="visa"
                class="credit-card-icon" [ngClass]="{'disabled': !isCardTypeValid('Visa')}">
            </div>
          </div>
          <div class="form-group mb-3">
            <input type="text" formControlName="nameOnCard" class="form-control"
              [placeholder]="getPlaceholder('nameOnCard')" [ngClass]="{
                                'is-invalid': formData.get('nameOnCard')?.touched && formData.get('nameOnCard')?.invalid, 
                                'error-placeholder': formData.get('nameOnCard')?.touched && formData.get('nameOnCard')?.invalid
                              }">
          </div>
          <div class="form-group position-relative">
            <input type="text" formControlName="cardNumber" maxlength="16" class="form-control pe-5"
              [placeholder]="getPlaceholder('cardNumber')" [ngClass]="{
                                  'is-invalid': formData.get('cardNumber')?.touched && formData.get('cardNumber')?.invalid, 
                                  'error-placeholder': formData.get('cardNumber')?.touched && formData.get('cardNumber')?.invalid
                                }" (input)="onCardNumberChange($event)" style="height: 45px;">
          </div>
          <div class="form-group row mb-3">
            <div class="col-md-6">
              <select formControlName="expireMonth" class="form-control form-select" [ngClass]="{
                                        'is-invalid': formData.get('expireMonth')?.touched && formData.get('expireMonth')?.invalid,
                                      'error-placeholder': formData.get('expireMonth')?.touched && formData.get('expireMonth')?.invalid
                                      }">
                <option [value]="''">{{ getExpireMonthPlaceholder() }}</option>
                <option *ngFor="let month of expireMonthDropdown" [value]="month">{{ month }}</option>
              </select>
            </div>
            <div class="col-md-6">
              <select formControlName="expireYear" class="form-control form-select" [ngClass]="{
                                        'is-invalid': formData.get('expireYear')?.touched && formData.get('expireYear')?.invalid,
                                      'error-placeholder': formData.get('expireYear')?.touched && formData.get('expireYear')?.invalid
                                      }">
                <option [value]="''">{{ getExpireYearPlaceholder() }}</option>
                <option *ngFor="let year of expireYearDropdown" [value]="year">{{ year }}</option>
              </select>
            </div>
          </div>
          <div class="form-group mb-3">
            <input type="password" maxlength="4" formControlName="cardCode" class="form-control"
              [placeholder]="getPlaceholder('cardCode')" [ngClass]="{
                                  'is-invalid': formData.get('cardCode')?.touched && formData.get('cardCode')?.invalid, 
                                  'error-placeholder': formData.get('cardCode')?.touched && formData.get('cardCode')?.invalid
                                  }">
          </div>
        </ng-container>

      </div>

      <!-- Address Details Form -->
      <div class="col-md-12" *ngIf="!useExistingAddress" @fadeInOut>
        <div class="d-flex justify-content-between align-items-center custom-card rounded py-3  mb-3" [ngClass]="getBorderClass()">
          <h5 class="m-0 " style="letter-spacing: 2px;">Address Details</h5>
          <div class="credit-card-icons d-flex">
            <!-- <img src="../../../../../assets/images/credit-cards/card.png" alt="master-card" class="credit-card-icon" [ngClass]="{'disabled': !isCardTypeValid('MasterCard')}">
                                        <img src="../../../../../assets/images/credit-cards/american-express.png" alt="amex" class="credit-card-icon" [ngClass]="{'disabled': !isCardTypeValid('Amex')}">
                                        <img src="../../../../../assets/images/credit-cards/visa.png" alt="visa" class="credit-card-icon" [ngClass]="{'disabled': !isCardTypeValid('Visa')}"> -->
          </div>
        </div>
        <div class="form-group">
          <input id="address1" formControlName="address1" class="form-control"
            [placeholder]="getPlaceholder('address1')" [ngClass]="{
                                  'is-invalid': formData.get('address1')?.touched && formData.get('address1')?.invalid,
                                  'error-placeholder': formData.get('address1')?.touched && formData.get('address1')?.invalid
                                }">
        </div>
        <div class="form-group">
          <input id="address2" formControlName="address2" class="form-control"
            placeholder="Apartment, suite, etc. (optional)">
        </div>
        <div class="form-group row">
          <div class="col-md-6">
            <input id="city" formControlName="city" class="form-control"
              [placeholder]="getPlaceholder('city')" [ngClass]="{
                                  'is-invalid': formData.get('city')?.touched && formData.get('city')?.invalid,
                                  'error-placeholder': formData.get('city')?.touched && formData.get('city')?.invalid
                                }">
          </div>
          <div class="col-md-6">
            <input id="state" formControlName="state" class="form-control"
              [placeholder]="getPlaceholder('state')" [ngClass]="{
                                  'is-invalid': formData.get('state')?.touched && formData.get('state')?.invalid,
                                  'error-placeholder': formData.get('state')?.touched && formData.get('state')?.invalid
                                }">
          </div>
        </div>
        <div class="form-group">
          <input id="zip" formControlName="zipCode" class="form-control"
            [placeholder]="getPlaceholder('zipCode')" [ngClass]="{
                                  'is-invalid': formData.get('zipCode')?.touched && formData.get('zipCode')?.invalid,
                                  'error-placeholder': formData.get('zipCode')?.touched && formData.get('zipCode')?.invalid
                                }">
        </div>
      </div>
      <div class="form-check mt-3">
        <input 
          class="form-check-input" 
          type="checkbox" 
          id="useExistingAddress" 
          [checked]="isChecked()" 
          (change)="toggleUseExistingAddress($event)"
          style="width: 1rem; height: 1rem;">
        <label class="form-check-label" for="useExistingAddress" style="font-size: 1rem;">
          Use Existing Address
        </label>
        <p class="text-muted" *ngIf="useExistingAddress" @fadeInOut>{{
          (invoiceEmittedData?.customerData?.personalInfo?.mailingAddress?.value?.address1 || '') +
          (invoiceEmittedData?.customerData?.personalInfo?.mailingAddress?.value?.address2 ? ', ' + invoiceEmittedData?.customerData?.personalInfo?.mailingAddress?.value?.address2 : '') +
          (invoiceEmittedData?.customerData?.personalInfo?.mailingAddress?.value?.city ? ', ' + invoiceEmittedData?.customerData?.personalInfo?.mailingAddress?.value?.city : '') +
          (invoiceEmittedData?.customerData?.personalInfo?.mailingAddress?.value?.state ? ', ' + invoiceEmittedData?.customerData?.personalInfo?.mailingAddress?.value?.state : '') +
          (invoiceEmittedData?.customerData?.personalInfo?.mailingAddress?.value?.zipCode ? ', ' + invoiceEmittedData?.customerData?.personalInfo?.mailingAddress?.value?.zipCode : '')
        }}</p>
      </div>
      
      

      <!-- Submit Button -->
      <div class="col-md-12">
        <button type="submit"
          class="btn custom-form-width submit-button w-100 mt-3 d-flex align-items-center justify-content-center" [ngClass]="getSubmitButtonClass()"
          (click)="onSubmitWithCreditCard()">
          <h5>Pay Now</h5>
        </button>
      </div>
    </form>

  </div>
</div>





