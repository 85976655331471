<div class="container-fluid">
  <div class="row">
    <!-- Sidebar -->
    <div class="col-md-3 sidebar">
      <div class="mail-sidebar ">
        <div class="profile-section">
          <img src="https://media.istockphoto.com/id/1393750072/vector/flat-white-icon-man-for-web-design-silhouette-flat-illustration-vector-illustration-stock.jpg?s=612x612&w=0&k=20&c=s9hO4SpyvrDIfELozPpiB_WtzQV9KhoMUP9R9gVohoU=" alt="Profile" class="profile-img">
          <div class="profile-name">{{userData?.name}}</div>
          <div class="profile-email">{{userData?.userGroup[0]?.Code}}</div>
        </div>
        <button class="btn-new-mail">New Notifications</button>
        <ul class="menu-section">
          <li class="menu-item">
        
            <span><i class="fas fa-inbox menu-icon"></i>Inbox</span>
            <span class="badge-count">10</span>
          </li>
          <li class="menu-item">
            <span><i class="fas fa-bell menu-icon"></i>All Notifications</span>
          </li>
          <li class="menu-item">
            <span><i class="fas fa-paper-plane menu-icon"></i>Sent</span>
            <span class="badge-count">6</span>
          </li>
          <li class="menu-item">
            <span><i class="fas fa-file-alt menu-icon"></i>Draft</span>
            <span class="badge-count">4</span>
          </li>
          <li class="menu-item">
            <span><i class="fas fa-trash menu-icon"></i>Trash</span>
            <span class="badge-count">3</span>
          </li>
          <li class="menu-item">
           <span> <i class="fas fa-star menu-icon"></i>Important</span>
          </li>
          <li class="menu-item">
            <span><i class="fas fa-star menu-icon"></i>Starred</span>
          </li>
        </ul>
        <div class="section-divider"></div>
        <ul class="menu-section">
          <li class="menu-item">
            <span>
            <i class="fas fa-envelope-open menu-icon"></i>Unread
            </span>
          </li>
          <li class="menu-item">
            <span>
            <i class="fas fa-exclamation-triangle menu-icon"></i>Spam
            </span>
          </li>
          <li class="menu-item">
            <span>
            <i class="fas fa-paper-plane menu-icon"></i>Outbox
            </span>
          </li>
          <li class="menu-item">
            <span>
            <i class="fas fa-edit menu-icon"></i>Update
            </span>
          </li>
          <li class="menu-item">
            <span>
            <i class="fas fa-bell menu-icon"></i>Alert
            </span>
          </li>
          <li class="menu-item">
            <span><i class="fas fa-sticky-note menu-icon"></i>
              Notes</span>
            <span class="badge-count">20</span>
          </li>
        </ul>
      </div>
    </div>

    <!-- Main Content -->
    <div class="col-md-9 main-content">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <h5 class="m-0"> <i class="fa-regular fa-bell mx-2"></i>{{total}} Notifications</h5>
        <input type="text" class="form-control w-25" placeholder="Search...">
      </div>
  
       <div class="overflow-auto scrollbar" style="height: 738px; "  (scroll)="onScroll($event)">
      <div class="notification-card d-flex align-items-start cursor-pointer" [ngClass]="{'gray': !notification?.IsRead}" *ngFor="let notification of Allnotifications" (click)="handleClick(notification)">
        <div _ngcontent-ng-c423475472="" class="avatar mr-2 d-flex align-items-center justify-content-center text-dark"><i _ngcontent-ng-c423475472="" class="fa-regular fa-user"></i></div>
        <div class="w-100">
          <div class="d-flex justify-content-between">
            <div>
              <span class="dot" *ngIf="!notification?.IsRead"></span>
              <span class="notification-header">{{ notification?.Name }} </span>
            </div>
            <span class="notification-time">{{ notification?.UpdatedDate | date}}</span>
          </div>
          <p class="notification-text m-0">
            {{ notification?.Message }}
          </p>
        </div>
      </div>
      </div>
    </div>
  </div>
</div>

