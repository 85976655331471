// auth-cleanup.service.ts
import { Injectable, Injector } from "@angular/core";
import { environment } from "src/environments/environment";
import { SearchHistoryService } from "./search-history.service";
import { CommonService } from "./common.service";
import { CustomerProfileService } from "./customer-profile.service";
import { AuthService } from "src/app/auth/services/auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthCleanupService {
  private _authService!: AuthService;

  constructor(private searchHistoryService: SearchHistoryService,private commonService: CommonService,  private injector: Injector ) {}

  private get authService(): AuthService {
    if (!this._authService) {
      this._authService = this.injector.get(AuthService);
    }
    return this._authService;
  }
  // Handle login and cleanup based on policy
  handleLogin(): void {
    this.cleanupOnPolicy();
    // Additional login actions if needed
  }

  // Handle logout and cleanup based on policy
  async handleLogout(): Promise<void> {
    const stores = ["unity_searches", "mingle_searches"]; // List all stores to be cleared
  
    try {
      const unityRes = await this.authService.getCompanyTokens('unity');
      const mingleRes = await this.authService.getCompanyTokens('mingle');

      const unityUserID = unityRes?.userId;
      const mingleUserID = mingleRes?.userId;

      // Wait for search history to be processed before clearing the stores
      await this.getSearchHistory(unityUserID,mingleUserID);
      // await this.getSearchHistory(mingleUserID);

  
      if (environment.searchHistoryStorageBasis === "login") {
        // Clear each store after search history is processed
        stores.forEach((storeName) => {
          this.searchHistoryService.clearStore(storeName);
        });
      }
    } catch (error) {
    }
  }
  

  // Check cleanup policy and execute accordingly
  private cleanupOnPolicy(): void {
    if (environment.searchHistoryStorageBasis === "login") {
      this.searchHistoryService.clearAllSearchHistory();
    } else if (environment.searchHistoryStorageBasis === "days") {
      this.searchHistoryService.cleanupOldRecords(
        environment.searchHistoryDaysToKeep
      );
    }
  }

  // Initial application load cleanup check for day-based policies
  initializeAppCleanup(): void {
    if (environment.searchHistoryStorageBasis === "days") {
      this.searchHistoryService.initializeSearchHistoryCleanup();
    }
  }

  async getSearchHistory(unityUserId: any, mingleUserId?: any) {
    try {
      // Fetch all records for both Unity and Mingle
      const allRecords = await this.searchHistoryService.getAllRecords();
  
      // Extract Unity and Mingle records
      const unityRecords = allRecords[0]?.data || [];
      const mingleRecords = allRecords[1]?.data || [];
  
      // Check and send Unity records
      if (unityRecords.length > 0) {
        try {
          await this.searchHistoryService.sendRecentSearchData({
            company: 'unity',
            data: unityRecords,
            userID: unityUserId, // Always use unityUserId for Unity
          });
        } catch (error) {
          this.commonService.showToast('error', 'Unity Error', error.message);
        }
      }
  
      // Check and send Mingle records
      if (mingleRecords.length > 0) {
        try {
          await this.searchHistoryService.sendRecentSearchData({
            company: 'mingle',
            data: mingleRecords,
            userID: mingleUserId || unityUserId, // Use mingleUserId if exists, otherwise unityUserId
          });
        } catch (error) {
          this.commonService.showToast('error', 'Mingle Error', error.message);
        }
      }
    } catch (error) {
      this.commonService.showToast(
        'error',
        'Error',
        error?.message || 'Unknown error occurred while fetching search history.'
      );
    }
  }
  
  
}
