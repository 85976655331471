import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PortRequestsService } from 'src/app/portIn-out/services/porting-requests.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { SubscriptionService } from '../service/subscription.service';

@Component({
  selector: 'app-credit-card-update',
  templateUrl: './credit-card-update.component.html',
  styleUrls: ['./credit-card-update.component.scss']
})
export class CreditCardUpdateComponent implements OnInit {
  model5: any;
  subscriptionInfo: FormGroup;
  @Input() subscriptionDetail: any;
  @Input() justUpdateAddress: boolean = false
  states: any[] = [];
  cities: any[] = [];
  postalCodes: any[] = [];
  constructor(private fb: FormBuilder,
    private modalService: NgbModal,
    private subscriptionService: SubscriptionService,
    private commonService: CommonService,
    private portRequestsService:PortRequestsService
  ) { }

  ngOnInit() {
    this.subscriptionInfoForm();
    this.patchAddress()
  }

  subscriptionInfoForm() {
    this.subscriptionInfo = this.fb.group({
      address: [''],
      address2: [''],
      state: [''],
      city: [''],
      zip: [''],
      country: [''],
      cardNumber: [''],
      cardName: [''],
      cvcNumber: [''],
    });

    if (this.justUpdateAddress !== true) {
      this.subscriptionInfo.get('cardNumber')?.setValidators(Validators.required);
      this.subscriptionInfo.get('cardName')?.setValidators(Validators.required);
      this.subscriptionInfo.get('cvcNumber')?.setValidators(Validators.required);
    } else {
      this.subscriptionInfo.get('address')?.setValidators(Validators.required);
      this.subscriptionInfo.get('state')?.setValidators(Validators.required);
      this.subscriptionInfo.get('city')?.setValidators(Validators.required);
      this.subscriptionInfo.get('zip')?.setValidators(Validators.required);
      this.subscriptionInfo.get('country')?.setValidators(Validators.required);
    }

    this.subscriptionInfo.updateValueAndValidity();

    console.log("subscriptionDetail :-",this.subscriptionDetail);
    
  }

  patchAddress() {
    this.subscriptionInfo.patchValue({
      address: this.subscriptionDetail?.authorizeSubscriptionDetail?.profile?.paymentProfile?.billTo?.address,
      address2: this.subscriptionDetail?.authorizeSubscriptionDetail?.profile?.paymentProfile?.billTo?.address2,
      city: this.subscriptionDetail?.authorizeSubscriptionDetail?.profile?.paymentProfile?.billTo?.city,
      state: this.subscriptionDetail?.authorizeSubscriptionDetail?.profile?.paymentProfile?.billTo?.state,
      zip: this.subscriptionDetail?.authorizeSubscriptionDetail?.profile?.paymentProfile?.billTo?.zip,
      country: this.subscriptionDetail?.authorizeSubscriptionDetail?.profile?.paymentProfile?.billTo?.country,
    });
    console.log("justUpdateAddress :-", this.justUpdateAddress)
    this.patchProfileData();
  }

  async patchProfileData() {
    const StateResponse = await this.portRequestsService.getStates().toPromise();
    this.states = StateResponse?.data;   

    const state = this.states.find(state => state.StateCode ===  this.subscriptionDetail?.authorizeSubscriptionDetail?.profile?.paymentProfile?.billTo?.state || state.StateName=== this.subscriptionDetail?.authorizeSubscriptionDetail?.profile?.paymentProfile?.billTo?.state);


    const cityResponse = await this.portRequestsService.getCities(state.id).toPromise();
    this.cities = cityResponse?.data;

    const city = this.cities.find(city => city.CityName === this.subscriptionDetail?.authorizeSubscriptionDetail?.profile?.paymentProfile?.billTo?.city);

    const postalCodeResponse = await this.portRequestsService.getPostalCodes(city?.id).toPromise();
    this.postalCodes = postalCodeResponse?.data;

  }

  hideStepper() {
    this.modalService.dismissAll();
  }

  updatepayment() {
    let firstName = this.subscriptionDetail?.subscriptionDetail?.customer?.FirstName;
    let lastName = this.subscriptionDetail?.subscriptionDetail?.customer?.LastName;

    if (!this.justUpdateAddress) {
      const cardName = this.subscriptionInfo.get('cardName')?.value || '';
      const nameParts = cardName.split(' ');

      firstName = nameParts[0];
      lastName = nameParts.length > 1 ? nameParts.slice(1).join(' ') : '';
    }
    const userPaymentDetails: any = {
      ...(this.justUpdateAddress ? {} : {
        cardNumber: this.subscriptionInfo.get('cardNumber')?.value,
        cardExpiryDate: this.convertToExpiryDate(this.model5),
        cardCode: this.subscriptionInfo.get('cvcNumber')?.value,
      }),
      subscriptionId: this.subscriptionDetail?.subscriptionDetail?.id,
      CustomerId : this.subscriptionDetail?.subscriptionDetail?.customer?.id,
      billingInfo: {
        firstName: firstName,
        lastName: lastName,
        phone: this.subscriptionDetail?.subscriptionDetail?.customer?.personal_info?.PrimaryPhone,
        address: this.subscriptionInfo.get('address')?.value,
        address2: this.subscriptionInfo.get('address2')?.value,
        city: this.subscriptionInfo.get('city')?.value,
        state: this.subscriptionInfo.get('state')?.value,
        zip: this.subscriptionInfo.get('zip')?.value,
        country: this.subscriptionInfo.get('country')?.value,
      }
    };
    console.log("userPaymentDetails :-", this.justUpdateAddress);
    try {
      
      this.subscriptionService.editBillingInfo(userPaymentDetails).subscribe(
        (res: any) => {
          if (res?.data == 'Subscriptions that are canceled cannot be updated.') {
            this.commonService.showToast('error', "Error", (res?.data));
          } else {
            this.commonService.showToast('success', "Success", res?.data);
          }
          this.hideStepper()
          
        },
        (error: any) => {
          console.error("Error updating billing info:", error);
          
        }
      );
    } catch (error) {
      console.error("Exception caught:", error);
      
    }

  }
  convertToExpiryDate(dateObj: { year: number; month: number; day: number }): string {
    const year = dateObj.year.toString();
    const month = dateObj.month.toString().padStart(2, '0'); // Ensure month is two digits
    return `${year}-${month}`;
  }

   //Load Postal Code
   onCityChange(event): void {
    const selectedCityName = event?.trim();
    try {
      
      if (!!selectedCityName && selectedCityName?.length) {
        const selectedCity = this.cities.find(state => state.CityName === selectedCityName);
        if (selectedCity?.id) {
          this.portRequestsService.getPostalCodes(selectedCity.id).subscribe((response: any) => {
            this.postalCodes = response?.data;
            
          });
        }
        else {
          
        }
      }
    } catch (error) {
      
    }
  }

  // Load Cities
  onStateChange(event: any): void {
    const selectedStateName = event?.trim();
    try {
      
      if (!!selectedStateName && selectedStateName?.length) {
        const selectedState = this.states.find(state => state.StateCode === selectedStateName);
        if (selectedState?.id) {
          this.portRequestsService.getCities(selectedState.id).subscribe((response: any) => {

            this.cities = response?.data;
            
          });
        } else {
          
        }
      }
    } catch {
      
    }
  }

  // Load States
  async loadStates() {
    
    try {
      await this.portRequestsService.getStates().subscribe((response: any) => {
        this.states = response?.data;
        
      });
    } catch (err) {
      
    }
  }

}
