import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdhocInvoiceService } from '../shared/adhoc-invoice.service';
import { AdhocStateService } from '../shared/adhoc-state.service';
import { HttpService } from 'src/app/shared/services/httpService.service';
import { CustomerProfileService } from 'src/app/shared/services/customer-profile.service';
import { CommonService } from 'src/app/shared/services/common.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-adhoc-cvv-payment',
  templateUrl: './adhoc-cvv-payment.component.html',
  styleUrls: ['./adhoc-cvv-payment.component.scss']
})
export class AdhocCvvPaymentComponent implements OnInit {
  formDataWithCVV: FormGroup;
  expiryDate:any;
  cardDetails:any;
    @Output() paymentSuccess = new EventEmitter<void>();
  
  creditCarddetail = {
    cardNumber: '',
    cardType: ''
    };
    profileId:any;
    paymentProfileId:any;
  invoiceDataSubscription: any;
  invoiceEmittedData: any;
  constructor(
    private fb: FormBuilder ,
    private adhocInvoiceService:AdhocInvoiceService,
    private adhocStateService:AdhocStateService,
    private httpService: HttpService,
    private customerProfileService:CustomerProfileService,
    private commonService:CommonService) {}

 async ngOnInit() {
    this.initializeForm();
    await this.getEmittedData();
    this.getCustomerSubscription();

  }
  getEmittedData(){
    this.invoiceDataSubscription =  this.adhocInvoiceService.invoiceObservable$.subscribe((data) => {
      if (data) {
        console.log('Received invoice data:', data);
        this.invoiceEmittedData = data;
        console.log('invoiceEmittedData: ', this.invoiceEmittedData);
      } else {
        console.log('No invoice data received yet.');
      }
    });
  }
  isExistingCardType(cardType: string): boolean {
    return this.creditCarddetail?.cardType === cardType;
  }
  // Initialize the form
  private initializeForm(): void {
    this.formDataWithCVV = this.fb.group({
      cardCode: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]],
    });
  }
  get cardCode(): string {
    return this.formDataWithCVV?.get('cardCode')?.value;
  }

  // Mock method to provide a dummy card number
  private mockCreditCardDetails(): void {
    // this.creditCarddetail = {
    //   cardNumber: '**** **** **** 1234', // Masked card number for security
    // };
  }

  // Check if the given card type is valid
  // isExistingCardType(cardType: string): boolean {
  //   const validCardTypes = ['MasterCard', 'Amex', 'Visa'];
  //   return validCardTypes.includes(cardType);
  // }

  async getCustomerSubscription(){
    try {
      let data={
        customerId: this.invoiceEmittedData?.data?.customer_id,
        single:true
      }
      const response:any= await this.customerProfileService.getCustomerSubscription(data)
      if(response?.Status){
        this.profileId=response?.data?.data[0]?.ProfileId
        this.paymentProfileId=response?.data?.data[0]?.PaymentProfileId
        this.getCustomerPaymentSubscription()
      }else{
        this.commonService.showToast('error','Error',response?.message)
      }
    } catch (error) {
      console.log(error);
      this.commonService.showToast('error','Error',error?.message)
    }
  }

  async getCustomerPaymentSubscription(){
    try {
      let data={
        profileId:this.profileId,
        paymentProfileId:this.paymentProfileId,
        customerId: this.invoiceEmittedData?.data?.customer_id
      }
      const res:any = await this.adhocInvoiceService.getCustomerPaymentProfile(data)
      if(res?.Status){
        this.creditCarddetail.cardNumber=res?.data?.payment?.creditCard?.cardNumber;
        this.creditCarddetail.cardType=res?.data?.payment?.creditCard?.cardType;
        this.expiryDate = res?.data?.payment?.creditCard?.expirationDate
        this.commonService.loader=false;
        this.cardDetails= res;
      }else{
        this.commonService.showToast('error','Error',res?.message)
        this.commonService.loader=false
      }
    } catch (error) {
      console.log(error);
      this.commonService.showToast('error','Error',error?.message)
      this.commonService.loader=false
    }
  }

  markAllAsTouched(): boolean {
    Object.keys(this.formDataWithCVV.controls).forEach(field => {
      const control = this.formDataWithCVV.get(field);
      if (control) {
        control.markAsTouched({ onlySelf: true });
      }
    });
    return this.formDataWithCVV.valid;
  }
  getFullBillingAddress(): string {
    const address1 = this.invoiceEmittedData?.customerData?.personalInfo?.mailingAddress?.value?.address1 || '';
    const address2 = this.invoiceEmittedData?.customerData?.personalInfo?.mailingAddress?.value?.address2 || '';
    const city = this.invoiceEmittedData?.customerData?.personalInfo?.mailingAddress?.value?.city || '';
    const state = this.invoiceEmittedData?.customerData?.personalInfo?.mailingAddress?.value?.state || '';
    const zipCode = this.invoiceEmittedData?.customerData?.personalInfo?.mailingAddress?.value?.zipCode || '';
  
    return [address1, address2, city, state, zipCode]
      .filter(Boolean) // Filter out any empty or undefined values
      .join(', '); // Concatenate with a comma and space
  }
  private formatMonth(month: number): string {
    return month < 10 ? `0${month}` : `${month}`;
  }
  async onSubmitWithCVV() {
    try {
      if(this.markAllAsTouched()){
        const formattedMonth = this.formatMonth(this.expiryDate.value);
        const payload = {
          customerId: this.invoiceEmittedData?.data?.customer_id,
          invoiceId: this.invoiceEmittedData?.data?.id || null, // Assuming invoiceId is available
          invoiceType: this.invoiceEmittedData?.invoiceType === 'Plan Change' 
            ? 'planChange' 
            : this.invoiceEmittedData?.invoiceType === 'Plan Renewal' 
            ? 'renewal' 
            : this.invoiceEmittedData?.invoiceType || 'renewal',
          chargeFrom: "profile",
          // creditCard: {
          //   cardNumber: this.creditCarddetail.cardNumber,
          //   expirationDate: this.expiryDate,
          //   cardCode: this.cardCode,
          //   nameOnCard: this.cardDetails?.data?.billTo?.firstName + ' ' + this.cardDetails?.data?.billTo?.lastName,
          //   cardZipCode: this.invoiceEmittedData?.customerData?.personalInfo?.mailingAddress?.value?.zipCode || '',
          //   cardBillingAddress: this.getFullBillingAddress(), // Concatenated address
          //   cardState: this.invoiceEmittedData?.customerData?.personalInfo?.mailingAddress?.value?.state || '',
          //   cardCity: this.invoiceEmittedData?.customerData?.personalInfo?.mailingAddress?.value?.city || '',
          // },
        };
        console.log('billing address: ', this.getFullBillingAddress());

         // Check if `address1` exists in the form, then update the billing address
  
        const response:any = await this.adhocInvoiceService.doAdhocInvoicePayment(payload);
        if(response){
          this.paymentSuccess.emit(); 
          this.onPaymentSuccess(response);
        }
        console.log('payload: ', payload);
        
      }else{
        this.commonService.showToast('error','Error','Fill all the required fields')
      }
    } catch (error) {
      if(this.markAllAsTouched()){
      if (typeof error?.error?.error === "string") {
        this.commonService.showToast("error", "Error", error?.error?.error);
      } else {
        this.commonService.showToast(
          "error",
          "Error",
          error?.error?.message || "An Unexpected error occured."
        );
        console.log(error?.message);
      }
      }
    } finally {
    }
  }

  onPaymentSuccess(response:any){
    if (response?.Status) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Adhoc Payment Status",
        html: `Adhoc Number ${this.invoiceEmittedData?.data?.number} paid successfully`,
        timer: 10000, 
        showConfirmButton: false,
        timerProgressBar: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading(); 
          const closeButton = document.createElement("button");
          closeButton.innerHTML = "&times;";
          closeButton.classList.add("swal2-close");
          closeButton.style.position = "absolute";
          closeButton.style.top = "10px";
          closeButton.style.right = "10px";
          closeButton.style.fontSize = "1.5rem";
          closeButton.style.background = "none";
          closeButton.style.border = "none";
          closeButton.style.cursor = "pointer";

          closeButton.onclick = () => {
            Swal.close();
          };

          Swal.getHtmlContainer()?.appendChild(closeButton);
          const swalTimer = document.getElementById("swal-timer");
          let timerInterval = setInterval(() => {
            let currentTime = parseInt(swalTimer?.innerText || "10");
            if (currentTime > 1) {
              swalTimer!.innerText = (currentTime - 1).toString();
            } else {
              clearInterval(timerInterval);
            }
          }, 1000);
        },
      });
    } else if (!response?.Status || response?.exception) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Adhoc Payment Status",
        html:`Adhoc Number ${this.invoiceEmittedData?.data?.number} payment failed`,
        timer: 5000, 
        showConfirmButton: false,
        timerProgressBar: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading(); 
          const closeButton = document.createElement("button");
          closeButton.innerHTML = "&times;";
          closeButton.classList.add("swal2-close");
          closeButton.style.position = "absolute";
          closeButton.style.top = "10px";
          closeButton.style.right = "10px";
          closeButton.style.fontSize = "1.5rem";
          closeButton.style.background = "none";
          closeButton.style.border = "none";
          closeButton.style.cursor = "pointer";

          closeButton.onclick = () => {
            Swal.close();
          };

          Swal.getHtmlContainer()?.appendChild(closeButton);
          const swalTimer = document.getElementById("swal-timer");
          let timerInterval = setInterval(() => {
            let currentTime = parseInt(swalTimer?.innerText || "10");
            if (currentTime > 1) {
              swalTimer!.innerText = (currentTime - 1).toString();
            } else {
              clearInterval(timerInterval);
            }
          }, 1000);
        },
      });
    }
  }
}
