<ng-container *ngIf="isSkeletonLoader" @fadeInOutSlow>
  <app-skeleton-loader shape="transsection-detail"></app-skeleton-loader>
</ng-container>

<div class="container-fluid p-t-10 mx-4" style="overflow-y: hidden;" *ngIf="!isSkeletonLoader" @fadeInOutSlow>
  <!-- <div class="tab-content" id="c-pills-tabContentAgent"> -->
  <div class="row">
    <div class="col-5 d-flex justify-content-start">
      <span class="px-4 p-2" (click)="backRouting()" style="font-size: 18px; cursor: pointer;background: #f0f0f0;
    border-radius: 16px;"> <i class="fa-solid fa-arrow-left mx-2" ></i>Back</span>
    </div>
  </div>
  <div class="row">
    <!-- Left Column -->
    <div
      class="col-12 col-md-3 d-flex flex-column scrollable-column  justify-content-center align-items-start  px-2 m-0 py-2">
      <!-- Left content here -->

      <div class="scrollable-content">
        <div class="card ">
          <div class="card-header row  px-1 py-2 d-flex justify-content-between align-items-center">
            <h5 class="mb-0 col-md-6">Transaction Breakdown</h5>
            <div class="col-md-6">
              <div class="  round-border d-flex" style="justify-content: end;">
                <div class="f-row d-inline-block position-relative ">
                  <button class="f-row f-a-center button1  py-0 date-button">
                    <i class="fa fa-calendar" (click)="drp.toggle()" [attr.aria-expanded]="drp.isOpen"></i>
                    <input [(ngModel)]="bsRangeValue" (ngModelChange)="onDateRangeChange()"
                      [bsConfig]="{ rangeInputFormat: 'MMM DD, YYYY', adaptivePosition: true, showWeekNumbers: false }"
                      bsDaterangepicker class="pl-2 border-0 bg-transparent cursor-pointer"
                      placeholder="Daterangepicker" type="text" readonly #drp="bsDaterangepicker"
                      style="min-width: 200px !important;" />
                    <i class="fa fa-caret-square-o-down" (click)="drp.toggle()" [attr.aria-expanded]="drp.isOpen"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="date-pickers">

              </div>
            </div>
            <div id="chart">
              <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart" [labels]="chartOptions.labels"
                [responsive]="chartOptions.responsive" [title]="chartOptions.title" [legend]="chartOptions.legend"
                [dataLabels]="chartOptions.dataLabels" [colors]="chartOptions.colors"></apx-chart>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Right Column -->
    <div
      class="col-12 col-md-9 d-flex flex-column scrollable-column  justify-content-start align-items-center  px-1 py-2">
      <!-- Right content here -->

      <div class="card-cstm-style mx-3 info-card non-scrollable">
        <!-- Header with toggle button and search bar -->
        <div class="d-flex justify-content-between non-scrollable align-items-center mb-3">
          <h5 class="mb-0">Transaction List</h5>
          <div class="d-flex align-items-center">
            <!-- Search input -->
            <input type="text" class="form-control form-control-sm me-2" placeholder="Search transactions..."
              [(ngModel)]="searchText" (ngModelChange)="filterTransactions()" style="width: 18rem;" />
            <!-- Toggle button -->
            <app-feather-icons (click)="toggleCustomerEventAsList()" class="text-primary cursor-pointer"
              [icon]="'list'"></app-feather-icons>
          </div>
        </div>

        <!-- Tabs for each category -->
        <ul class="nav nav-tabs mb-3 d-flex  align-items-center">
          <ng-container *ngFor="let group of transactionList; let i = index">
            <li class="nav-item">
              <a class="nav-link" [class.active]="activeTab === i" [class.disabled]="group.values.totalCount === 0"
                (click)="group.values.totalCount > 0 && selectTab(i)">
                {{ group.key === 'all' ? 'All' : (group.key | capitalizeWords) }} ({{ group.values.totalCount }})
              </a>
            </li>
          </ng-container>
        </ul>

        <!-- Message for tabs with zero transactions -->
        <div *ngIf="showNoTransactionMessage" class="alert alert-info text-center">
          No {{ activeGroup?.key | capitalizeWords }} transactions
        </div>

        <!-- Transactions Display -->
        <div class="scrollable-column">
          <ng-container *ngIf="filteredTransactions?.length > 0">
            <ng-container *ngIf="showTransactionEventAsList">
              <div class="card-cstm-style m-3 mb-1 info-card ">
                <div class="table-responsive">
                  <table class="table table-hover">
                    <thead
                      style="font-size: medium; font-weight: bolder; letter-spacing: 2px; background-color: lightgrey;">
                      <tr>
                        <th style="letter-spacing: 2px;">Transaction Id</th>
                        <th>Name</th>
                        <th>Product</th>
                        <th>Market Type</th>
                        <th>Status</th>
                        <th>Settle Amount</th>
                        <th>Account Type</th>
                      </tr>
                    </thead>
                    <tbody class="scrollable-column">
                      <tr *ngFor="let transaction of filteredTransactions">
                        <td [innerHTML]="transaction?.transId | highlightPipe:searchText"></td>
                        <td
                          [innerHTML]="(transaction?.firstName + ' ' + transaction?.lastName) | highlightPipe:searchText">
                        </td>
                        <td [innerHTML]="transaction?.product | highlightPipe:searchText"></td>
                        <td [innerHTML]="transaction?.marketType | highlightPipe:searchText"></td>
                        <td [innerHTML]="transaction?.transactionStatus | highlightPipe:searchText"></td>
                        <td [innerHTML]="'$' + (transaction?.settleAmount | highlightPipe:searchText)"></td>
                        <td [innerHTML]="transaction?.accountType | highlightPipe:searchText"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="showTransactionEventAsCard">
             
              <div class="row g-3">
              <div class="  info-card col-md-4 col-sm-6 col-12" *ngFor="let transaction of filteredTransactions">
                <div class="custom-card rounded p-3 ">
                  <div class="d-flex justify-content-between align-items-start">
                    <div>
                      <h6 class="transaction-name d-flex align-items-center">
                        <span
                          [innerHTML]="(transaction?.firstName + ' ' + transaction?.lastName) | highlightPipe:searchText"></span>
                        <app-feather-icons *ngIf="transaction?.customer!= null" class="ms-2 text-primary cursor-pointer"
                          [icon]="'user'" (click)="toggleCustomerDetails(transaction)"
                          (mouseenter)="onHover(transaction)"
                          (mouseleave)="hideTooltipWithDelay(transaction)"></app-feather-icons>
                      </h6>
                      <div class="tooltip-container" *ngIf="showTooltip && hoveredTransaction === transaction"
                        (mouseenter)="cancelHideTooltip()" (mouseleave)="hideTooltipWithDelay(transaction)"
                        (click)="customerInfoClick(transaction.customer?.CustomerId)"
                        style="position: absolute; z-index: 10; background: #fff; border: 1px solid #ccc; padding: 10px; border-radius: 5px; max-width: 250px; box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); cursor: pointer;">
                        <p class="m-0">
                          <strong>Customer ID: </strong>{{ transaction.customer?.CustomerId }}
                        </p>
                        <p class="m-0">
                          <strong>Customer Name: </strong>{{ transaction.customer?.FirstName + ' ' +
                          (transaction.customer?.MiddleName || '') + ' ' + transaction.customer?.LastName }}
                        </p>
                      </div>

                      <small class="text-muted"
                        [innerHTML]="'Transc ID: ' + transaction?.transId | highlightPipe:searchText"></small>

                    </div>


                    <span class="badge" [ngClass]="{
                          'badge-success': transaction.transactionStatus === 'settledSuccessfully',
                          'badge-warning': transaction.transactionStatus !== 'settledSuccessfully'
                        }" [innerHTML]="transaction.transactionStatus | capitalizeWords"></span>
                  </div>
                  <hr class="my-2" />

                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <p class="m-0 pb-1"><strong>Settle Amount: </strong> <span
                          [innerHTML]="'$' + (transaction?.settleAmount | highlightPipe:searchText)"></span></p>
                      <p class="m-0 pb-1"><strong>Market Type: </strong> <span
                          [innerHTML]="transaction?.marketType | highlightPipe:searchText"></span></p>
                      <p class="m-0"><strong>Account Type: </strong> <span
                          [innerHTML]="transaction?.accountType | highlightPipe:searchText"></span></p>
                    </div>
                    <div class="d-flex">
                      <ng-container *ngIf="transaction?.accountType; else defaultCard">
                        <ng-container *ngIf="getCardImage(transaction?.accountType) as cardImage">
                          <img [src]="cardImage.src" [alt]="cardImage.alt" class="credit-card-icon" />
                        </ng-container>
                      </ng-container>
                      <ng-template #defaultCard>
                        <img src="../../../assets/images/credit-cards/default-card.png" alt="Default Card"
                          class="credit-card-icon" />
                      </ng-template>
                    </div>
                  </div>
                  <hr class="my-2" />
                  <div *ngIf="transaction.subscription">
                    <p class="m-0"><strong>Subscription ID:</strong> <span
                        [innerHTML]="transaction.subscription.id | highlightPipe:searchText"></span></p>
                    <p class="m-0"><strong>Payment Count:</strong> <span
                        [innerHTML]="transaction.subscription.payNum | highlightPipe:searchText"></span></p>
                  </div>
                </div>
              </div>
         
            </div>
            </ng-container>
          </ng-container>
        </div>

        <div *ngIf="filteredTransactions?.length === 0">
          <p class="text-center text-muted">No transactions match your search.</p>
        </div>
      </div>
    </div>
  </div>








  <!-- </div> -->

  <div *ngIf="showTransectionPage && transectionId">
    <app-payment-history [transectionId]="transectionId"></app-payment-history>
  </div>
</div>