import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { SubscriptionsRoutingModule } from './subscriptions-routing.module';
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { SubscriptionsListComponent } from './subscriptions-list/subscriptions-list.component';
import { SubscriptionsGraphComponent } from './subscriptions-graph/subscriptions-graph.component';
import { SharedModule } from '../shared/shared.module';

import { NgApexchartsModule } from 'ng-apexcharts';
import { DetailedSubscriptionComponent } from './detailed-subscription/detailed-subscription.component';
import { SubscriptionPiechartComponent } from './subscription-piechart/subscription-piechart.component';
import { CreditCardUpdateComponent } from './credit-card-update/credit-card-update.component';
import { AgGridModule } from 'ag-grid-angular';
import { TransectionDetailComponent } from './transection-details/transection-details.component';
import { PaymentHistoryComponent } from './payment-history/payment-history.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CapitalizeWordsPipe } from './pipes/CapitalizeWordsPipe';
import { QuickLinksModule } from '../customer-dashboard/quick-links/quick-links.module';
import { OrderOverviewComponent } from '../excutive-dashboard/component/order-overview/order-overview.component';
import { TodayProgressComponent } from '../excutive-dashboard/component/today-progress/today-progress.component';
import { SubscriptionDetailsComponent } from './subscription-details/subscription-details.component';
import { HighlightPipe } from './pipes/highlight.pipe';
import { UsageInfoComponent } from '../customer-dashboard/customer-pages/customer-profile/usage-info/usage-info.component';
import { CustomerPagesModule } from '../customer-dashboard/customer-pages/customer-pages.module';
import { CustomerInformationComponent } from '../renewal-reports/customer-information/customer-information.component';
import { RenewalReportsModule } from '../renewal-reports/renewal-reports.module';
import { InvoicesHistoryComponent } from './invoices-history/invoices-history.component';
import { PaymentHisTwoComponent } from './payment-his-two/payment-his-two.component';
import { PaymentHisCardComponent } from './payment-his-card/payment-his-card.component';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
  declarations: [
    SubscriptionsComponent,
    SubscriptionsListComponent,
    SubscriptionsGraphComponent,
    DetailedSubscriptionComponent,
    SubscriptionPiechartComponent,
    CreditCardUpdateComponent,
    TransectionDetailComponent,
    PaymentHistoryComponent,
    OrderOverviewComponent ,
    TodayProgressComponent,
    SubscriptionDetailsComponent,
    InvoicesHistoryComponent,
    PaymentHisTwoComponent,
    PaymentHisCardComponent,    

  ],
  imports: [
    CommonModule,
    SubscriptionsRoutingModule,
    SharedModule,
    AgGridModule,
    NgApexchartsModule,
    BsDatepickerModule,
    QuickLinksModule,
    RenewalReportsModule,
    MatTooltipModule
    
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA],
  providers: [DatePipe],
  exports:[PaymentHistoryComponent,OrderOverviewComponent,TodayProgressComponent,PaymentHisCardComponent]
})
export class SubscriptionsModule { }
