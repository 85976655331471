import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonService } from 'src/app/shared/services/common.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorage } from 'ngx-webstorage';
import { CustomerProfileService } from 'src/app/shared/services/customer-profile.service';
import { Subscription } from 'rxjs';
import { formatDate } from '@angular/common';
import { NotesService } from 'src/app/customer-dashboard/customer-footer/customer-notes/services/notes.service';
declare global {
  interface String {
    hashCode(): number;
  }
}

String.prototype.hashCode = function () {
  let hash = 0, i, chr;
  for (i = 0; i < this.length; i++) {
    chr = this.charCodeAt(i);
    hash = (hash << 5) - hash + chr; // hash * 31 + chr
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};
@Component({
  selector: 'app-latest-activity',
  templateUrl: './latest-activity.component.html',
  styleUrls: ['./latest-activity.component.scss']
})
export class LatestActivityComponent {
  @Input() isCalledFromCustomerProfile: boolean = false;
  @Input() CustomerId: any;
  activeTab: string = 'customer-notes'; // Default tab
  @Input('isFooter') isFooter: boolean = false;
  allNotesType: any;
  allNotes: any;
  formData: FormGroup;
  hasMoreData = true;
  isVisible: boolean = true
  public totalItems: number;
  public currentPage: number = 1;
  public itemsPerPage: number = 50;
  public first_page_url: any = "";
  public last_page_url: any = "";
  public next_page_url: any = "";
  public pageDropdownMenu: any = [10, 20, 30];
  public loader: boolean = false;
  selectedNoteTypeId: any; // Holds the selected id
  @LocalStorage()
  private userDetails
  activeNavItem: string = 'customerNotes';
  customerNameSubscription: Subscription
  customerName: string;
  firstLettersCustomerName: string;
  noNotesEventSubscription: Subscription;
  isNoNotes: boolean;
  setActive(navItem: string) {
    this.activeNavItem = navItem;
  }
  constructor(
    public fb: FormBuilder,
    public notesService: NotesService,
    public commonService: CommonService,
    private modalService: NgbModal, public customerProfileService: CustomerProfileService
  ) {
  }
  ngOnDestroy(): void {
    if(this.noNotesEventSubscription){
      this.noNotesEventSubscription.unsubscribe()
    }
  }

  async ngOnInit() {
    await this.customerProfileService.setCustomerId(this.CustomerId)
    this.getData()
  }


  async getData() {
    // 
    this.loader = true;
    try {
      let data = {
        "perPage": this.itemsPerPage,
        "page": this.currentPage,
        'CustomerId':this.CustomerId
      }
      const res: any = await this.notesService.getNotes(data);
      if (res.Status) {
        const newNotes = res.data.data || [];
        this.allNotes = [...(this.allNotes || []), ...newNotes];
        this.totalItems = res.data.total
        this.currentPage = res.data.current_page+1
        this.first_page_url = res.data.first_page_url
        this.last_page_url = res.data.last_page_url
        this.next_page_url = res.data.next_page_url
        this.hasMoreData = !!res.data.next_page_url;
        this.notesService.notesAvailable()
        this.allNotes.forEach(item => {
          item.CreatedDate = new Date(item.CreatedDate);
          console.log(item.CreatedDate);
        });


        this.groupTimelineItems();
      } else {
        this.commonService.showToast('error', "Error", res.message);
        this.notesService.noNotes();
        this.allNotes = []
      }
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
      this.allNotes = []
    }
    // 
    this.loader = false;
  }

  groupTimelineItems(): void {
    const groupedItems:any = [];
    let currentGroup = '';
    this.allNotes.sort((a, b) => new Date(b.CreatedDate).getTime() - new Date(a.CreatedDate).getTime());
    this.allNotes.forEach(item => {
      const itemGroup = this.getTimeGrouping(item.CreatedDate);
      item.group=""
      if (itemGroup !== currentGroup) {
        // Insert a group marker when the group changes
        groupedItems.push({ id: itemGroup?.hashCode(), ...item,group:itemGroup });
        currentGroup = itemGroup;
      }
      groupedItems.push(item);
    });

    this.allNotes = groupedItems;
    console.log(groupedItems);
  }

  // Helper method to create a group marker
  createGroupMarker(group: string,item) {
    return {
      id: 0,
      CustomerID: 0,
      AgentID: 0,
      Text: group,
      CreatedDate: new Date(), // Set to now or a constant date
      type: 'group',
      customer: { id: 0, FirstName: '', LastName: '' },
      ...item // Mock customer data
    };
  }

  getTimeGrouping(date: Date): string {
    const today = new Date();
    const dayDiff = Math.floor((today.getTime() - date.getTime()) / (1000 * 3600 * 24));

    if (dayDiff === 0) {
      return 'Today';
    } else if (dayDiff <= 7) {
      return 'This Week';
    } else if (formatDate(today, 'MMMM y', 'en-US') === formatDate(date, 'MMMM y', 'en-US')) {
      return formatDate(date, 'MMMM y', 'en-US');
    } else if (today.getFullYear() === date.getFullYear()) {
      return formatDate(date, 'y', 'en-US');
    } else {
      return formatDate(date, 'y', 'en-US');
    }
  }

  onRefereshNotes() {
    this.getData()
  }

  onSelectNoteType() {
    const notetypeid = this.formData.get('NoteTypeID').value
    const notetype = this.allNotesType.find((it) => it.id == notetypeid)
    this.formData.get('Text').setValue(notetype?.Description)
  }

  onScroll(event: Event) {
    const element = event.target as HTMLElement;
    const scrollPosition = element.scrollTop;
    const totalHeight = element.scrollHeight;
    const visibleHeight = element.clientHeight;
    if (scrollPosition + visibleHeight >= totalHeight - 100 && !this.loader && this.hasMoreData) {
      if(this.hasMoreData){
        this.getData();
      }
    }
  }

}
