import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment.dev';
import { CommonService } from '../shared/services/common.service';
import { AwaitingShipmentService } from './services/awaiting-shipment.service';
import { AuthService } from '../auth/services/auth.service';

@Component({
  selector: 'app-awaiting-shipment',
  templateUrl: './awaiting-shipment.component.html',
  styleUrls: ['./awaiting-shipment.component.scss']
})
export class AwaitingShipmentComponent {
  public dataArray = [];
  @Input() isHideCard: any
  @Input() isDropshipOrder: any
  bsRangeValue:Date[] = [new Date(), new Date()];
  advanceSearchValues1:any = { fromDate: '', toDate: '' };
  public startDate: Date;
  public endDate: Date;
  constructor(private commonService: CommonService, private awaitingShipmentService: AwaitingShipmentService, private authService:AuthService) { }

  ngOnInit(): void {
    this.getCardStatsData()
  }

  orderEmittedData: any
  handleOrderedEmitted(data: any) {
    this.orderEmittedData = data
    console.log(data,"data");
    // console.log('Received ordersData from child:', data);
  }

  openUnitywireless() {
    this.commonService.openBuyFlow();
  }

  getCardStatsData() {
    let data={
      endDate:this.advanceSearchValues1?.toDate,
      startDate:this.advanceSearchValues1?.fromDate
    }
    this.awaitingShipmentService.getCardStatData(data).then((res: any) => {
      if (res.Status)
        this.dataArray = res.data
      this.dataArray.map(val=>{
        if(val.orders){
          val.count= val.orders
        }
      })
      console.log("data",this.dataArray)
    })
  }


  exportOrder() {
    const data: any = {
      per_page: 100,
      page: 1,
      IsExport: 1,
    };

    this.commonService.getAwaitingShipmentOrder(data).subscribe((res: any) => {
      if (res.Status && res.data){
        const anchor = document.createElement("a");
        anchor.href = res.data;
        anchor.target = "_blank";
        anchor.download = "";
        anchor.click();
        anchor.remove();
        this.commonService.showToast('success', "Data download successfully ", "")
      }
    })
  }
  dataChanged() {
    if (this.bsRangeValue && this.bsRangeValue.length === 2) {
      const [start, end] = this.bsRangeValue;
      this.startDate = start;
      this.endDate = end;
      this.updateDateRange();
      this.getCardStatsData()
    }
  }
  stop(event: Event) {
    event.stopPropagation();
  }
  updateDateRange() {
    this.advanceSearchValues1 = {
      fromDate: this.formatDate(this.startDate),
      toDate: this.formatDate(this.endDate)
    };
  }
  formatDate(date: Date): string {
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear().toString().slice(-2);
    return `${year}-${month}-${day}`;
  }
}
