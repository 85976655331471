import { Component, OnInit } from '@angular/core';
import { PurchaseHistoryService } from './services/purchase-history.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { fadeInOut } from 'src/app/shared/services/app.animation';
import { HttpService } from 'src/app/shared/services/httpService.service';

@Component({
  selector: 'app-purchase-history',
  templateUrl: './purchase-history.component.html',
  styleUrls: ['./purchase-history.component.scss'],
  animations:[fadeInOut]
})
export class PurchaseHistoryComponent implements OnInit {

 currentCustomerId:any
  constructor(
    private purchaseService: PurchaseHistoryService,
    private commonService: CommonService, private httpService: HttpService
  ) {
  }

  ngOnInit() {
    this.currentCustomerId = this.httpService.getCustomerId();
  }
  
}
