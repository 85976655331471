import { Component } from '@angular/core';

@Component({
  selector: 'app-carousel-skeleton',
  templateUrl: './carousel-skeleton.component.html',
  styleUrls: ['./carousel-skeleton.component.scss']
})
export class CarouselSkeletonComponent {
  skeletonItems: Array<any> = Array(5); // Number of skeleton cards to display
}
