import { Router } from '@angular/router';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, tap, catchError, timeout } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';

// import { CommonService } from './common.service';

import { environment } from 'src/environments/environment';
import { LocalStorage } from 'ngx-webstorage';
// import { Http } from '../shared/interfaces';
// import { TokenService } from './token.service';

@Injectable({
    providedIn: 'root'
})
export class HttpService implements OnDestroy{
    private apiBaseUrl: String = environment.BASE_API;
    authExpired$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    authExpired$$ = this.authExpired$.asObservable();
    


    
    @LocalStorage()
    private userDetails;

    @LocalStorage()
    private currentCompany;
    
    @LocalStorage()
    private CustomerId;


    constructor(private httpClient: HttpClient, private router: Router) {
        // this.getBaseUrl() = localStorage.getItem('BaseUrl') ??  environment.BASE_API
     }
     ngOnDestroy(): void {
        // this.getBaseUrl()=environment.BASE_API
    }

    getBaseUrl(): string {
        return localStorage.getItem('BaseUrl') ?? environment.BASE_API;
      }
    // constructor(private httpClient: HttpClient, private router: Router, private _cs: CommonService, private tokenService: TokenService) { }
    get = <T>(endpoint: string, body: any = null, isOnlyCustomBodySend: boolean = false, isCustomerId: boolean = false, headers: boolean = false, customUrl: boolean = false, options: any = {}): Observable<T> => {
    
        // Setting up the default body
        if (!isOnlyCustomBodySend) {
            const tempBody = {
                "companyId": "UNITYCRM0021",
                "userID": this.getUser()?.userID,
            };
            body = body ? { ...tempBody, ...body } : tempBody;
        }
    
        // Adding CustomerId if required
        if (isCustomerId) {
            body.CustomerId = this.getCustomerId();
        }
    
        // Building query parameters
        let params: HttpParams = new HttpParams();
        if (body) {
            for (const key in body) {
                if (body.hasOwnProperty(key)) {
                    params = params.set(key, body[key]);
                }
            }
        }
    
        // Constructing the full URL
        if (!customUrl) {
            endpoint = this.getBaseUrl() + endpoint;
        }
    
        // Setting up headers if provided
        if (headers) {
            options.headers = new HttpHeaders({ 'X-Hide-Loader': 'true' });
        }
    
        // Making the GET request with headers and other options
        return this.httpClient.get<T>(endpoint, { ...options, params }).pipe(
            map((res) => res as T),  // Explicitly cast the result to `T`
            catchError(this.handleError)
        );
    }

    post = <T>(
        endpoint: string,
        body: any = null,
        isOnlyCustomBodySend: boolean = false,
        isRemoveCustomerId: boolean = false,
        customURL: boolean = false,
        headers: boolean = false,
        options: any = {}): Observable<T | never> => {


        if (!customURL) {
            endpoint = this.getBaseUrl() + endpoint;
        }

        if (isOnlyCustomBodySend == false) {
            let tempBody = {
                "CustomerId": this.getCustomerId(),
                "companyId": "UNITYCRM0021",
                "userID": this.getUser()?.userID
            }
            if (body == null) {
                body = tempBody
            } else {
                body = {
                    "CustomerId": this.getCustomerId(),
                    "companyId": "UNITYCRM0021",
                    "userID": this.getUser()?.userID,
                    ...body
                }
            }
            if(isRemoveCustomerId) {
                delete body.CustomerId
            }
        }

        if (headers) {
            var httpHeaders = new HttpHeaders({ 'X-Hide-Loader': 'true' });
        }
        return this.httpClient.post<T>(endpoint, body,{ headers: httpHeaders }).pipe(
            map((res) => res),
            catchError(this.handleError)
        );
    }

    postFile = <T>(
        endpoint: string,
        body: any = null, isOnlyCustomBodySend): Observable<T | HttpEvent<T>> => {
        endpoint = this.getBaseUrl() + endpoint;

        if (isOnlyCustomBodySend == false) {
            let tempBody = {
                "CustomerId": this.getCustomerId(),
                "companyId": "UNITYCRM0021",
                "userID": this.getUser()?.userID
            }
            if (body == null) {
                body = tempBody
            } else {
                body = {
                    "CustomerId": this.getCustomerId(),
                    "companyId": "UNITYCRM0021",
                    "userID": this.getUser()?.userID,
                    ...body
                }
            }
        }
        
        return this.httpClient.post<T>(endpoint, body, {
            reportProgress: true,
            observe: 'events'
          }).pipe(
            map((res) => res),
            catchError(this.handleError)
        );
    }

    put = <T>(endpoint: string, body: any = null, isOnlyCustomBodySend: boolean = false, customUrl: boolean = false, options: any = {}): Observable<T | never> => {

        if (!customUrl) {
            endpoint = this.getBaseUrl() + endpoint;
        }

        if (isOnlyCustomBodySend == false) {
            let tempBody = {
                "CustomerId": this.getCustomerId(),
                "companyId": "UNITYCRM0021",
                "userID": this.getUser()?.userID
            }
            if (body == null) {
                body = tempBody
            } else {
                body = {
                    "CustomerId": this.getCustomerId(),
                    "companyId": "UNITYCRM0021",
                    "userID": this.getUser()?.userID,
                    ...body
                }
            }
        }

        return this.httpClient.put<T>(endpoint, body).pipe(
            map((res) => res),
            catchError(this.handleError)
        );
    }

    delete = <T>(endpoint: string, body: any = null, isOnlyCustomBodySend: boolean = false, customUrl: boolean = false, options: any = {}): Observable<T | never> => {

        if (isOnlyCustomBodySend == false) {
            let tempBody = {
                "CustomerId": this.getCustomerId(),
                "companyId": "UNITYCRM0021",
                "userID": this.getUser()?.userID
            }
            if (body == null) {
                body = tempBody
            } else {
                body = {
                    "CustomerId": this.getCustomerId(),
                    "companyId": "UNITYCRM0021",
                    "userID": this.getUser()?.userID,
                    ...body
                }
            }
        }
        let params: any = '';
        if (body) {
            params = new URLSearchParams();
            for (const key in body) {
                if (body.hasOwnProperty(key)) {
                    params.set(key, body[key]);
                }
            }
            params = params.toString() && params.toString() !== '' ? '?' + params.toString() : '';
        }

        if (!customUrl) {
            endpoint = this.getBaseUrl() + endpoint + params;
        } else {
            endpoint = endpoint + params;
        }

        return this.httpClient.delete<T>(endpoint).pipe(
            map((res) => res),
            catchError(this.handleError)
        );
    }

    private handleError(error: HttpErrorResponse) {
        // Todo -> Send the error to remote logging infrastructure
        return throwError(error);
    }

    // handleCommonError = (err: HttpErrorResponse, options: Http.ApiCallOptions) => {
    //     let errorObj;
    //     if (err && err.error && err.error.hasOwnProperty('message')) {
    //         errorObj = {
    //             data: err.message,
    //             message: err.error.message,
    //             statusCode: err.error.StatusCode
    //         };
    //     }
    //     if (err.status === 500) {
    //         if (err.error.hasOwnProperty('message')) {
    //             errorObj = {
    //                 data: err.message,
    //                 message: err.error.message,
    //                 statusCode: err.error.StatusCode
    //             };
    //         } else {
    //             errorObj = {
    //                 data: 'There was an error. Please contact administrator',
    //                 message: 'There was an error. Please contact administrator',
    //                 statusCode: '500'
    //             };
    //         }
    //     }
    // }

    // openErrorAlert(errorObj: HttpErrorResponse) {
    //     this._cs.showHideLoader();
    //     let errorMsg = '';
    //     if (errorObj.hasOwnProperty('error') && errorObj.error.hasOwnProperty('error') && errorObj.error.error.hasOwnProperty('message')) {
    //         errorMsg = errorObj?.error?.error?.message;
    //     } else if (errorObj.hasOwnProperty('error') && errorObj.error.hasOwnProperty('message')) {
    //         errorMsg = errorObj?.error?.message;
    //     } else {
    //         errorMsg = 'Something went wrong. Please contact administrator.';
    //     }
    //     this._cs.showAlertDialog({ heading: 'Error', content: errorMsg }).afterClosed().pipe().subscribe((dialogClose: any) => {
    //         ;
    //     });
    // }

 

    getCustomerId() {
        return sessionStorage.getItem('CustomerId') ?? this.CustomerId 
    }

    getUser() {
        return  this.userDetails 
    }
}