<div class="invoice-preview">
    <header class="invoice-header">
      <div class="company-info">
        <h3>Your Company Name</h3>
        <p>[Address Line 1]</p>
        <p>[Address Line 2]</p>
        <p>Phone: [Your Phone]</p>
      </div>
      <div class="invoice-info">
        <h2>INVOICE</h2>
        <p>Date: {{ adhocData?.invoiceDetails?.date || 'N/A' }}</p>
        <p>Invoice ID: {{ adhocData?.invoiceDetails?.id || 'N/A' }}</p>
        <p>Customer ID: {{ adhocData?.customerId }}</p>
      </div>
    </header>
  
    <section class="billing-shipping">
      <div class="billing-info">
        <h4>Billing Information</h4>
        <p>{{ adhocData?.address?.address1 }}</p>
        <p>{{ adhocData?.address?.address2 }}</p>
        <p>{{ adhocData?.address?.city }}, {{ adhocData?.address?.state }}</p>
        <p>{{ adhocData?.address?.country }} - {{ adhocData?.address?.zip }}</p>
      </div>
      <div class="shipping-info">
        <h4>Shipping Information</h4>
        <p>{{ adhocData?.address?.address1 }}</p>
        <p>{{ adhocData?.address?.address2 }}</p>
        <p>{{ adhocData?.address?.city }}, {{ adhocData?.address?.state }}</p>
        <p>{{ adhocData?.address?.country }} - {{ adhocData?.address?.zip }}</p>
      </div>
    </section>
  
    <section class="invoice-details">
      <table>
        <thead>
          <tr>
            <th>Item #</th>
            <th>Description</th>
            <th>Qty</th>
            <th>Unit Price</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>{{ adhocData?.invoiceDetails?.invoiceType }}</td>
            <td>1</td>
            <td>{{ adhocData?.invoiceDetails?.amount | currency }}</td>
            <td>{{ adhocData?.invoiceDetails?.amount | currency }}</td>
          </tr>
        </tbody>
      </table>
    </section>
  
    <section class="totals">
      <p><strong>Tax Amount:</strong> {{ adhocData?.invoiceDetails?.taxDetails?.taxAmount | currency }}</p>
      <p><strong>Total Amount:</strong> {{ adhocData?.invoiceDetails?.totalAmount | currency }}</p>
    </section>
  
    <footer class="invoice-footer">
      <p>Thank you for your business!</p>
      <div class="actions">
        <button mat-raised-button color="primary" (click)="onConfirm()">Confirm</button>
        <button mat-raised-button color="accent" (click)="onEdit()">Edit</button>
      </div>
    </footer>
  </div>
  