import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpService } from 'src/app/shared/services/httpService.service';
import { PermissionService } from 'src/app/shared/services/permission-service.service';


@Injectable({
  providedIn: 'root'
})
export class ExeDashboardService {
    executivePermission:any
    public sideMenubarSub = new BehaviorSubject<any[]>([]);
    constructor(private http:HttpService , private permissionService :PermissionService) { }

 

  async sideMenuInitilize() {
    try {
      const res = await this.permissionService.getResourceCategoryById("EXECUTIVE_DASHBOARD") as any;
      const data = res;
        const resourceTypes = data?.resource_types;
        if (Array.isArray(resourceTypes) && resourceTypes.length > 0) {
          this.executivePermission = resourceTypes[0]?.resources || [];
          const mappedMenuItems = this.mapedMenuItems();
          this.sideMenubarSub.next(mappedMenuItems);
        } else {
          console.error("Resource types array is empty or not defined");
        }
    
    } catch (error) {
      console.error("Error occurred while fetching menu types:", error);
    }
  }
  

mapedMenuItems() {
  return this.executivePermission.map(el => {
    let tempData = {}
    let lastIndex = el.resource_permission.length - 1;
    let isHidden = el.resource_permission[0]?.CanView || 0;
    if (el.children.length == 0) {
      tempData = {
        title: el.DisplayName,

        //isHidden: this.getPermission(el),
        // isHidden: convertToBoolean(this.getPermission(el)),

        isHidden,
        // isHidden: convertToBoolean(el.HideMenu),

        icon: el.NavIconClass,
        type: "link",
        badgeType: "light-success",
        path: el.UrlRoute
      }


    } else {
      tempData = {
        title: el.DisplayName,

        //isHidden: this.getPermission(el),

        isHidden,

        icon: el.NavIconClass,
        type: "sub",
        badgeType: "light-success",
        path: el.UrlRoute,
        badgeValue: el.children?.length,
        children: el.children.map(el2 => {
          return {
            path: el2.UrlRoute, title: el2.DisplayName, type: "link"
          }
        })
      }
    }

    return tempData
  })
}

getSideMenuBar() {
  return this.sideMenubarSub.asObservable();
}

getSubcriptionTrends(data) {
  try {
      let url = 'api/subscription/getSummary'
      let body = {
          "userID": this.http.getUser()?.userID,
          "companyId": "UNITYCRM0021",
          ...data
      }
      return this.http.get(url, body)
  } catch (err) {
      throw Error(err)
  }

}


getChart(data: any) {
  try {
      let url = 'api/subscription/getTrends'
      let body = {
          "userID": this.http.getUser()?.userID,
          "companyId": "UNITYCRM0021",
          ...data
      }
      return this.http.get(url, body);
  } catch (err) {
      throw Error(err)
  }
}
}
