import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonService } from 'src/app/shared/services/common.service';
import { SubscriptionService } from '../service/subscription.service';
import {
  ApexChart,
  ApexTitleSubtitle,
  ApexNonAxisChartSeries,
  ApexResponsive,
} from 'ng-apexcharts';
import { SearchService } from 'src/app/shared/services/search.service';
import { CustomerProfileService } from 'src/app/shared/services/customer-profile.service';
import { Router } from '@angular/router';
import { fadeInOutSlow } from 'src/app/shared/services/app.animation';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  labels: string[];
  responsive: ApexResponsive[];
  title: ApexTitleSubtitle;
  legend?: any;
  dataLabels?: any;
  colors?: string[];
};

@Component({
  selector: 'app-transection-details',
  templateUrl: './transection-details.component.html',
  styleUrls: ['./transection-details.component.scss'],
  animations:[fadeInOutSlow]
})
export class TransectionDetailComponent implements OnInit {
  transectionDetails: any;
  transectionsList: any;
  transactionList: any = []; // Global object for structured data
  showStepper: boolean = true;
  showLoader: boolean = false;
  startDate: string;
  endDate: string;
  dateForm: FormGroup;
  public chartOptions:any;
  showTransactionEventAsList: boolean = false;
  showTransactionEventAsCard: boolean = true;
  bsRangeValue: Date[];
  activeTab: number = 0; // Active tab index
  activeGroup: any; // Active group of transactions
  showNoTransactionMessage: boolean = false;
  filteredTransactions: any[] = []; // Filtered transactions for display
  searchText: string = ''; // Search input text
  isSkeletonLoader:boolean=true;
  constructor(
    private subscriptionService: SubscriptionService,
    private commonService: CommonService,
    public router: Router,
    private fb: FormBuilder, private searchService:SearchService, private customerProfileService:CustomerProfileService,
  ) {
    this.chartOptions = {

    };
    
  }
  selectTab(index: number) {
    const group = this.transactionList[index];

    if (group.values.totalCount === 0) {
      this.showNoTransactionMessage = true;
      setTimeout(() => {
        this.showNoTransactionMessage = false; // Automatically hide message after a few seconds
      }, 3000);
    } else {
      this.activeTab = index;
      this.activeGroup = group;
      this.filterTransactions(); // Apply filter when switching tabs
      this.showNoTransactionMessage = false;
    }
  }
  filterTransactions() {
    const allTransactions = this.activeGroup?.values?.data || [];
    this.filteredTransactions = allTransactions.filter((transaction: any) =>
      Object.values(transaction)
        .join(' ')
        .toLowerCase()
        .includes(this.searchText.toLowerCase())
    );
  }
  setDates() {
    const today = new Date();
    const fifteenDaysAgo = new Date();
    fifteenDaysAgo.setDate(fifteenDaysAgo.getDate() - 15); 
    this.bsRangeValue = [fifteenDaysAgo, today];
  }

  ngOnInit() {
    this.setDates();
    this.getTransectionDetail();
  }

  toggleCustomerEventAsList() {
    this.showTransactionEventAsList = !this.showTransactionEventAsList;
    this.showTransactionEventAsCard = !this.showTransactionEventAsCard;
  }

  toggleCustomerEventAsCard() {
    this.showTransactionEventAsCard = !this.showTransactionEventAsCard;
  }

  async getTransectionDetail() {
    try {
      const data = {
        startDate: this.bsRangeValue[0].toISOString().split('T')[0],
        endDate: this.bsRangeValue[1].toISOString().split('T')[0],
      };
      
      this.showLoader = true;
      await this.subscriptionService.getTransactionByStatus(data).subscribe((res: any) => {
        if (res.Status) {
          this.transectionDetails = res?.data;
          this.transectionsList = res?.data?.transactions?.settledSuccessfully;
          this.populateTransactionList(); // Populate the structured object
          this.updateChartOptions();
          this.showLoader = false;
          this.isSkeletonLoader=false
        }else{
          
        }
      },
      (error: any) => {
        console.log('error: ',error);

        this.commonService.showToast('error','Error',error?.error?.error?.messages?.message[0]?.text)
  
      });
    } catch (error) {
      console.log('error: ',error);

      this.commonService.showToast('error','Error',error?.message)

    }
  }

  populateTransactionList() {
    const counts = this.transectionDetails?.transactionsCounts as Record<string, number>;
    const data = this.transectionDetails?.transactions;
  
    // Populate individual transaction groups
    this.transactionList = Object.keys(counts).map((key) => ({
      key: key,
      values: {
        totalCount: counts[key],
        data: data[key]
      }
    }));
  
    // Add the "All" tab
    const allTransactions = Object.values(data).flat(); // Combine all transactions
    const totalTransactionCount = Object.values(counts).reduce((acc, count) => acc + (count as number), 0); // Sum counts
  
    this.transactionList.unshift({
      key: 'all',
      values: {
        totalCount: totalTransactionCount,
        data: allTransactions
      }
    });
  
    // Default tab: Select 'All' tab if it exists
    this.activeTab = 0; // 'All' tab index
    this.activeGroup = this.transactionList[this.activeTab];
    this.filterTransactions();
  }
  
  

  updateChartOptions() {
    const pieChartData = this.transectionDetails?.transactionsCounts || {};
    
    if (Object.keys(pieChartData).length > 0) {
      const seriesData = Object.values(pieChartData).map(value => Number(value));
      const labels = Object.keys(pieChartData).map(this.capitalizeWords);
  
      this.chartOptions = {
        ...this.chartOptions, // Preserve existing settings
        series: seriesData as ApexNonAxisChartSeries,
        labels: labels,
        toolbar: {
          show: false,
        },
        chart: {
          ...this.chartOptions.chart,
          type: 'pie',
          height: 800,
          background: 'transparent',
          dropShadow: {
            enabled: false,
            top: 5,
            left: 0,
            blur: 5,
            color: '#000',
            opacity: 0.2,
          },
          toolbar: {
            show: true,
          },
        },
        colors: [
          '#4CAF50', // Green (Success)
          '#FFC107', // Amber (Warning)
          '#2196F3', // Blue (Primary)
          '#E91E63', // Pink (Accent)
          '#FF5722', // Deep Orange (Error/Highlight)
          '#9C27B0', // Purple (Secondary)
          '#00BCD4', // Cyan (Information)
          '#8BC34A', // Light Green (Calm/Success)
          '#FF9800', // Orange (Caution/Highlight)
          '#607D8B', // Blue Gray (Neutral)
        ]
        ,
        legend: {
          show: true,
          position: 'bottom',
          horizontalAlign: 'center',
          width: '100%', // Adjust width dynamically
          height: 300, // Height of the legend area
          labels: {
            colors: ['#333'], // Neutral text color
            useSeriesColors: false, // Keep a consistent text color
          },
          fontSize: '14px', // Slightly larger font for better readability
          fontWeight: '500', // Slightly bolder for emphasis
          markers: {
            width: 16, // Larger marker for better visibility
            height: 16,
            radius: 8, // Circular markers
            strokeWidth: 1, // Subtle border for markers
            strokeColor: '#ddd', // Light gray border around markers
          },
          itemMargin: {
            horizontal: 15, // Increased spacing between items
            vertical: 10, // Increased vertical spacing for better layout
          },
          containerMargin: {
            top: 10, // Add spacing at the top
            left: 20,
            right: 20,
          },
        },
        
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '12px',
            fontWeight: '400',
            // colors: ['#333'],
          },
          dropShadow: {
            enabled: true,
            top: 1,
            left: 1,
            blur: 1,
            opacity: 0.3,
          },
          formatter: (val: number) => `${val.toFixed(1)}%`,
        },
        responsive: [
          {
            breakpoint: 1200,
            options: {
              chart: {
                width: '100%',
              },
              legend: {
                position: 'bottom',
              },
            },
          },
          {
            breakpoint: 480,
            options: {
              chart: {
                width: '100%',
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
      };
    } else {
      console.warn('No transaction data available for chart.');
      this.chartOptions.series = [];
      this.chartOptions.labels = [];
    }
  }
  capitalizeWords(label: string): string {
    if (!label) return label;
    return label
      .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space between camelCase words
      .split(' ') // Split by spaces
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
      .join(' '); // Join back into a single string
  }
  

  hideStepper() {
    this.showStepper = false;
  }

  onDateRangeChange() {
    this.getTransectionDetail();
  }

  showTransectionPage: boolean = false;
  transectionId: any;
  
  showTransectionDetail(tranId: any) {
    this.showTransectionPage = false;
    setTimeout(() => {
      this.showTransectionPage = true;
    }, 20);
    this.transectionId = tranId;
  }

  getCardImage(accountType: string): { src: string; alt: string } | null {
    const cardImages = {
      Visa: {
        src: '../../../assets/images/credit-cards/visa.png',
        alt: 'Visa Card',
      },
      MasterCard: {
        src: '../../../assets/images/credit-cards/card.png',
        alt: 'MasterCard',
      },
      Amex: {
        src: '../../../assets/images/credit-cards/american-express.png',
        alt: 'American Express',
      },
    };
  
    return cardImages[accountType] || null; // Return the matching card image or null if no match is found
  }
  hoveredTransaction: any = null; // To track the hovered transaction
  showTooltip: boolean = false; // To determine tooltip visibility
  
  hideTooltipTimeout: any = null; // To store the timeout for hiding the tooltip
  
  hideTooltipWithDelay(transaction: any): void {
    this.hideTooltipTimeout = setTimeout(() => {
      if (this.hoveredTransaction === transaction) {
        this.showTooltip = false;
        this.hoveredTransaction = null;
      }
    }, 500); // Delay to ensure smooth behavior
  }
  
  cancelHideTooltip(): void {
    if (this.hideTooltipTimeout) {
      clearTimeout(this.hideTooltipTimeout); // Cancel the timeout to prevent tooltip from hiding
      this.hideTooltipTimeout = null;
    }
  }
  
  toggleCustomerDetails(transaction: any): void {
    transaction.showDetails = !transaction.showDetails; // Toggle customer details visibility
    this.showTooltip = false; // Hide the tooltip when clicked
    this.hoveredTransaction = null;
  }
  
  onHover(transaction: any): void {
    this.hoveredTransaction = transaction; // Set the hovered transaction
    this.showTooltip = true; // Show the tooltip
  }
  
   async customerInfoClick(id: number) {
    try {
      const customerData = { search: id, page: "1" };
      console.log("customerData:", customerData);
      const customerResponse: any =
        await this.searchService.searchCustomer(customerData);

      if (customerResponse?.Status) {
        this.searchService.setSearchData(customerResponse.data);
        await this.customerProfileService.setCustomerId(
          customerResponse.data.data[0]?.CustomerID
        );
        this.customerProfileService.getMinimalProfileData();
        this.router.navigateByUrl("/customer-dashboard/customer-profile");
        // this.closeGlobalSearch.emit();
      } else {
        this.commonService.showToast(
          "error",
          `Error `,
          customerResponse.message
        );
      }
    } catch (error) {
      this.commonService.showToast(
        "error",
        `Error `,
        "Failed to load customer details"
      );
    }
  }

  backRouting(){
    this.router.navigateByUrl('/subscriptions')
  }
}
