import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AdhocStateService } from '../shared/adhoc-state.service';
import { AdhocInvoiceService } from '../shared/adhoc-invoice.service';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-adhoc-preview-template',
  templateUrl: './adhoc-preview-template.component.html',
  styleUrls: ['./adhoc-preview-template.component.scss']
})
export class AdhocPreviewTemplateComponent implements OnInit, OnDestroy {
  // @Input() invoiceData: any;
    // Dummy Data for Invoice
  @Input() invoiceType: any
  selectedCompanyLogo:any
  selectedCompanyInfo: any;
  @Input() adhocDetails :any
  selectedPlan: any;
  // To hold invoice data
  invoiceEmittedData: any;
  invoiceDataSubscription:Subscription;
  constructor(private stateService: AdhocStateService, private adhocInvoiceService: AdhocInvoiceService, public commonService:CommonService){
    this.getLogoSrc()
  }
  ngOnDestroy(): void {
    this.invoiceDataSubscription.unsubscribe()
  }

  invoiceData = {
    invoiceDate: '10/30/2024',
    accountNumber: 'WUT0423',
    invoiceNumber: 'WUT0423',
    totalAmountDue: 9,
    billingAddress: {
      name: 'Kacie Harris',
      address: '109-1 Penny Dr Kings Mountain, NC 28086',
    },
    shippingAddress: {
      name: 'Kacie Harris',
      address: '109-1 Penny Dr Kings Mountain, NC 28086',
    },
    summary: {
      itemDescription: '1 Gig Plan (Standard Offer)',
      itemPrice: 8.93,
      taxAmount: 0.07,
      totalAmount: 9,
    },
    details: {
      itemDescription: '1 Gig Plan (Standard Offer)',
      quantity: 1,
      unitPrice: 8.93,
    },
    taxes: [
      { name: 'FEDERAL COST RECOVERY FEE', amount: 0.01 },
      { name: 'FEDERAL UNIVERSAL SERVICE FUND', amount: 0.06 },
      { name: 'FEDERAL NANPA FUND', amount: 0.00 },
    ],
    contact: {
      phone: '',
      email: '',
      mail: '',
      online: '',
    },
  };

  ngOnInit(): void {
    this.initializeCompanyInfo();
    console.log('adhocDetailss: ',this.adhocDetails);
    this.stateService.selectedPlan$.subscribe((plan) => {
      this.selectedPlan = plan;
      console.log('Selected Plan: ', this.selectedPlan);
    });
    this.invoiceDataSubscription =  this.adhocInvoiceService.invoiceObservable$.subscribe((data) => {
      if (data) {
        console.log('Received invoice data:', data);
        this.invoiceEmittedData = data;
        console.log('invoiceEmittedData: ', this.invoiceEmittedData);
      } else {
        console.log('No invoice data received yet.');
      }
    });

  }
    getLogoSrc(){
      const currentCompany = localStorage.getItem('currentCompany');
      currentCompany === 'mingle' ?  this.selectedCompanyLogo = 'Mingle' : 'Aqsatel';
    }

    initializeCompanyInfo(): void {
      let currentCompany = localStorage.getItem('currentCompany');
      console.log('current company before check: ',currentCompany);
       currentCompany = currentCompany === 'mingle' ? 'mingleMobile' : 'unityWireless';
      console.log('current company after check: ',currentCompany);

      const companyInfo = environment?.COMPANY_INFO[currentCompany];
      console.log('companyInfo: ',companyInfo);
      if (companyInfo) {
        // Set selected company information
        this.selectedCompanyInfo = companyInfo;
    
        // Populate invoice contact details from the company info
        this.invoiceData.contact.phone = companyInfo?.phoneNo;
        this.invoiceData.contact.email = companyInfo?.supportEmail;
        this.invoiceData.contact.mail = companyInfo?.companyAddress;
        this.invoiceData.contact.online = currentCompany === 'mingleMobile' ? 'www.minglemobile.com' : 'www.unitywireless.com';
    
        // Set logo dynamically based on the current company
      } else {
        console.error('Invalid company key or missing company information');
      }
    }
    
}
