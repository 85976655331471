import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CommonService } from "src/app/shared/services/common.service";
import Swal from "sweetalert2";
import { CreditCardUpdateComponent } from "../credit-card-update/credit-card-update.component";
import { AddressDTO } from "../interfaces/subscription";
import { SubscriptionService } from "../service/subscription.service";
import { Subscription } from "rxjs";
import { PlanChangePaymentComponent } from "src/app/customer-dashboard/quick-links/plan-change/plan-change-payment/plan-change-payment.component";
import { CustomerProfileService } from "src/app/shared/services/customer-profile.service";
import { fadeInOut } from "src/app/shared/services/app.animation";
import { RenewalServiceService } from "src/app/renewal-reports/service/renewal-service.service";
import { CustomizerComponent } from "src/app/customer-dashboard/customizer/customizer.component";
import { ActivatedRoute, Router } from "@angular/router";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

@Component({
  selector: "app-subscription-details",
  templateUrl: "./subscription-details.component.html",
  styleUrls: ["./subscription-details.component.scss"],
  animations: [fadeInOut],
})
export class SubscriptionDetailsComponent {
  subscriptionDetail: any;
  showStepper: boolean = true;
  addressData: AddressDTO;
  @Input() existingData: any;
  AutoRenew: boolean = false;
  isCancelled: boolean = false;
  showHisotoryPage: boolean = false;
  showHistoryDetail: any;
  justUpdateAddress: boolean = false;
  sticyFooter: boolean = true;
  lastSubscription: any;
  isOperationPerformed: boolean;
  autoRenewTransactionStatus: boolean;
  id: any;
  @ViewChild(CustomizerComponent) popup!: CustomizerComponent;
  customerDetails: any;
  timelineData: any = [];
  selectedActivityType: any= 'Activity'
  isAutoRenewModelOpen: boolean;
  filteredTimelineData: any = [];
  renewal: any;
  activity:boolean = true
  data: any;
  subcriptionHistoryData:any
  CustomerId: any;
  CustomerId2:any
  subscriptionID:any
  activityTypes = ["Activity", "Subscription History"];
  private timeoutSubscription: Subscription | undefined;
  @ViewChild("childComponent", { static: false })
  planChangePaymentComponent!: PlanChangePaymentComponent;
  @Output() closeAutoRenewModal = new EventEmitter<void>();
  isSkeletonLoader: boolean = true;

  constructor(
    private subscriptionService: SubscriptionService,
    public commonService: CommonService,
    private modalService: NgbModal,
    private cd: ChangeDetectorRef,
    private customerProfileService: CustomerProfileService,
    private router: Router,
    private renewalServiceService: RenewalServiceService,
    private route: ActivatedRoute
  ) {}
  ngOnInit() {
    this.data = history?.state?.item;
    console.log('data: ', this.data);
    this.route.queryParams.subscribe(params => {
      this.CustomerId2 = params['customerId']; // Access subscriptionID
      this.subscriptionID = params['subcriptionID']
      // Log it to the console for testing
    });
    this.route.queryParams.forEach((param: any) => {
      if (param.id) {
        this.id = param.id ? param.id : this.CustomerId2;
      }
      if (param.CustomerId) {
        this.CustomerId = param.CustomerId;
      }
      // this.getSubscriptionDetail();
    });

    this.id = history?.state?.item?.SubscriptionId ? history?.state?.item.SubscriptionId : this.subscriptionID;

    this.CustomerId = history?.state?.item?.CustomerId ? history?.state?.item?.CustomerId : this.CustomerId2;
    // console.log("Cutooo",this.CustomerId,this.subscriptionID);
    this.getSubscriptionDetail();

    this.getTimelineData();
    this.getsubscriptionHistory()
  }
  onUpgradePlanClicked() {
    this.customerProfileService.setCustomerId(this.CustomerId);
    this.customerProfileService.getMinimalProfileData();
    this.router.navigate(["/customer-dashboard/customer-profile"], {
      queryParams: { openmodal: true, showBack: true },
    }); // Open Plan Change tab when the button is clicked
  }

  ngOnChanges(changes: SimpleChanges) {
    this.cd.detectChanges();
  }
  async getSubscriptionDetail() {
    console.log("state id ", this.id);
    try {
      await this.subscriptionService.getDetail(this.id).subscribe(
        (res: any) => {
          this.subscriptionDetail = res?.data;
          this.customerDetails = res?.data?.subscriptionDetail?.customer;
          console.log("customer details 2", this.customerDetails);
          this.patchSubscriptionValue();
          this.isSkeletonLoader = false;
        },
        (error: any) => {
          this.isSkeletonLoader = false;
        }
      );
    } catch (error) {
      console.error("Exception caught:", error);
    }
  }

  hideStepper() {
    this.showStepper = false;
    this.sticyFooter = false;
  }
  patchSubscriptionValue() {
    this.addressData = new AddressDTO();
    this.addressData.address =
      this.subscriptionDetail?.authorizeSubscriptionDetail?.profile
        ?.paymentProfile?.billTo?.address;
    this.addressData.city =
      this.subscriptionDetail?.authorizeSubscriptionDetail?.profile
        ?.paymentProfile?.billTo?.city;
    this.addressData.country =
      this.subscriptionDetail?.authorizeSubscriptionDetail?.profile
        ?.paymentProfile?.billTo?.country;
    this.addressData.state =
      this.subscriptionDetail?.authorizeSubscriptionDetail?.profile
        ?.paymentProfile?.billTo?.state;
    this.addressData.zip =
      this.subscriptionDetail?.authorizeSubscriptionDetail?.profile
        ?.paymentProfile?.billTo?.zip;
    this.AutoRenew =
      this.subscriptionDetail?.subscriptionDetail?.Status === "Cancelled"
        ? false
        : true;
    console.log("AutoRenew ::-", this.AutoRenew);
  }

  updatePaymentMethod(justUpdateAddress?: boolean) {
    const modalRef = this.modalService.open(CreditCardUpdateComponent, {
      backdropClass: "dark-modal",
      centered: true,
    });
    modalRef.componentInstance.subscriptionDetail = this.subscriptionDetail;
    modalRef.componentInstance.justUpdateAddress = justUpdateAddress;
  }

  renewSubscription(cardCode: any) {
    try {
      this.subscriptionService
        .renewSubscription(this.CustomerId, cardCode)
        .subscribe(
          (res: any) => {
            if (res?.Status) {
              this.commonService.showToast("success", "Success", res?.data);
              this.AutoRenew = true;
              this.isOperationPerformed = true;
              this.autoRenewTransactionStatus = true;
              this.notifyChildPaymentSuccess(res);
              this.getSubscriptionDetail();
            } else {
              this.AutoRenew = false;
              this.autoRenewTransactionStatus = false;
              this.notifyChildPaymentSuccess(res);
              this.commonService.showToast("error", "Error", res?.data);
            }
          },
          (error: any) => {
            this.AutoRenew = false;
            this.autoRenewTransactionStatus = false;
            console.error("Exception caught:", error);
            this.commonService.showToast("error", "Error", error?.error.error);
          }
        );
    } catch (error) {
      console.error("Exception caught:", error);
    }
  }

  async autoRenew(event: any) {
    console.log("event value :-", event.target.checked);
    if (event.target.checked) {
      this.isAutoRenewModelOpen = true;
      console.log("event.target.checked: true");
    } else {
      this.withConfirmation();
      console.log("event.target.checked: false");
    }
  }

  updateAutoRenewCardDetails(cardDetails: any) {
    this.commonService.startLoader();
    try {
      const subscriptionId = sessionStorage.getItem("SubscriptionId");
      this.subscriptionService
        .updateCardInfo(subscriptionId, cardDetails)
        .subscribe(
          (res: any) => {
            if (res?.Status) {
              this.renewSubscription(cardDetails?.cardCode);
            } else {
              this.commonService.showToast("error", "Error", res?.message);
            }
            this.commonService.stopLoader();
          },
          (error: any) => {
            console.error("Exception caught:", error);
            this.commonService.showToast("error", "Error", error?.error.error);
            this.commonService.stopLoader();
          }
        );
    } catch (error) {}
  }
  notifyChildPaymentSuccess(response: any) {
    if (this.planChangePaymentComponent) {
      this.planChangePaymentComponent.autorenewStatusTracker(response);
    }
  }
  getsubmitCVVautoRenew(cvv: any) {
    this.renewSubscription(cvv);
  }
  getsubmitCreditCardAutoRenew(cardDetails: any) {
    this.updateAutoRenewCardDetails(cardDetails);
  }
  onIsAutoRenewModelOpenChange(newValue: any) {
    this.isAutoRenewModelOpen = newValue;
    this.AutoRenew = this.AutoRenew;
  }
  handleModalClosedByX(event: any) {
    if (event || !this.autoRenewTransactionStatus) {
      this.AutoRenew = false;
    }
  }
  withConfirmation() {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to cancel this subscription?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, cancel it!",
      customClass: {
        popup: "swal2-custom-width",
      },
    }).then((result) => {
      if (result.isConfirmed && result.value) {
        this.cancelsubscription();
      } else {
        this.AutoRenew = true;
      }
    });
  }

  cancelsubscription() {
    try {
      this.subscriptionService
        .cancelSubscription(
          this.id,
          "Cancel Subscription through the Subscription Details page."
        )
        .subscribe(
          (res: any) => {
            if (res?.Status) {
              this.commonService.showToast("success", "sucess", res?.data);
              this.getSubscriptionDetail();
            }
          },
          (error: any) => {
            this.commonService.showToast(
              "error",
              "Error",
              "An error occurred while cancelling the subscription."
            );
            console.error("Error:", error);
          }
        );
    } catch (err) {
      this.commonService.showToast(
        "error",
        "Error",
        "An unexpected error occurred."
      );
      console.error("Unexpected Error:", err);
    }
  }

  openHistoryPage(data: any) {
    this.showHisotoryPage = false;
    this.showHistoryDetail = data;
    setTimeout(() => {
      this.showHisotoryPage = true;
    }, 40);
  }

  showCreditCardAlert() {
    type CardCodeResult = {
      cardCode: string;
    };

    let cardCodeInput: HTMLInputElement;

    Swal.fire<CardCodeResult>({
      title: "Enter Card Code",
      html: `
        <input type="text" id="cardCode" class="swal2-input" placeholder="Card Code" style="width:250px" maxlength="4" >
      `,
      confirmButtonText: "Submit",
      cancelButtonText: "Cancel",
      showCancelButton: true,
      focusConfirm: false,
      width: "400px",
      didOpen: () => {
        const popup = Swal.getPopup()!;
        cardCodeInput = popup.querySelector("#cardCode") as HTMLInputElement;
        cardCodeInput.onkeyup = (event) =>
          event.key === "Enter" && Swal.clickConfirm();
      },
      preConfirm: () => {
        const cardCode = cardCodeInput.value;
        if (!cardCode) {
          Swal.showValidationMessage("Please enter a card code");
          return null; // Return a value here
        } else {
          return { cardCode };
        }
      },
    }).then((result) => {
      console.log("result:", result); // Debug log
      if (result.isConfirmed) {
        const cardCode = result.value?.cardCode;
        console.log("Card Code:", cardCode);
        this.renewSubscription(cardCode);
      } else {
        this.AutoRenew = true;
        console.log("Operation cancelled");
      }
    });
  }

  back() {
    window.history.back();
  }

  getStatusBadgeClass(status: string): any {
    switch (status) {
      case "Active":
        return "badge-success";
      case "Success":
        return "badge-primary";
      case "Pending":
        return "badge-warning";
      case "Cancelled":
        return "badge-danger";
      case "Pending":
        return "badge-secondary";
      default:
        return "";
    }
  }

  getTimelineData() {
    let data = {
      CustomerId: this.CustomerId,
      // SubscriptionId:this.id.SubscriptionId
    };
    this.subscriptionService
      .getTimelineData(data)
      .then((res: any) => {
        if (res.Status) {
          this.timelineData = res?.data?.data;
        }
      })
      .catch((res: any) => {
        this.commonService.showToast("error", res?.message, "");
      });
  }
  async sendToCustomerDashboard() {
    await this.customerProfileService.setCustomerId(this.CustomerId);
    this.customerProfileService.getMinimalProfileData();
    this.router.navigate(["/customer-dashboard/customer-profile"], {
      queryParams: { showBack: true },
    });
  }
  downloadPDF(): void {
    const element = document.getElementById('content');
    element.classList.add('pdf-export');
  
    html2canvas(element, { scale: 1, useCORS: true, logging: false }).then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg', 0.8); // JPEG is faster and smaller than PNG
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pdfWidth = 190; // A4 width in mm
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
  
      pdf.addImage(imgData, 'JPEG', 10, 10, pdfWidth, pdfHeight);
      pdf.save('SubscriptionDetails.pdf');
  
      element.classList.remove('pdf-export');
    });
  }
  async getsubscriptionHistory() {
    let obj: any = {
      "CustomerId": this.CustomerId
    };
  
    this.subscriptionService.getSubcriptionHis(obj).then(
      (res: any) => {
        if (res.Status) {
          this.subcriptionHistoryData= res?.data
          // this.invoiceList = res?.data?.data
          console.log("renewal data",this.subcriptionHistoryData)
          if (res?.data?.data?.length === 0) {
            this.commonService.showToast('success', "Success", 'No Record Found');
          }
          // this.isInvoiceSkeletonLoader=false;
          // this.isHistorySkeletonLoader=false

        }
        
      },
      (error: any) => {
        console.error("Error fetching payment history:", error);
        this.commonService.showToast('error', "Error", 'Failed to fetch payment history');
        // this.isInvoiceSkeletonLoader=false
        // this.isHistorySkeletonLoader=false
        
      }
    );
  }
  filterTimeline(type: string): void {
    this.selectedActivityType = type;
    if (type === 'Activity') {
           this.activity = true // Show all if 'All' is selected
        this.getTimelineData()
    } else if(type ==='Subscription History') {
      this.activity = false 
      this.getsubscriptionHistory()
    }
  }
}
