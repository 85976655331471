import { Component, Input, OnInit } from '@angular/core';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { CommonService } from 'src/app/shared/services/common.service';
import * as html2pdf from 'html2pdf.js';

@Component({
  selector: 'app-download-invoice',
  templateUrl: './download-invoice.component.html',
  styleUrls: ['./download-invoice.component.scss']
})
export class DownloadInvoiceComponent implements OnInit {

  @Input() invoiceType:any
  isDownloading: boolean = false; 
  constructor(private commonService: CommonService){

  }
  ngOnInit(): void {
    // this.downloadInvoice()
}
downloadInvoice() {
  this.isDownloading = true;
  const invoiceElement = document.querySelector('.invoice-container');

  if (!invoiceElement) {
    this.isDownloading = false;
    console.error('Invoice container not found');
    return;
  }

  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = invoiceElement.innerHTML;

  // Remove the buttons section
  const buttonsSection = tempDiv.querySelector('.buttons-section');
  if (buttonsSection) {
    buttonsSection.remove();
  }

  // Apply padding and styling
  tempDiv.style.padding = '3rem';
  tempDiv.style.background = '#fff';
  tempDiv.style.boxSizing = 'border-box';
  tempDiv.style.fontFamily = 'Arial, sans-serif';
  tempDiv.style.color = '#000';

  // Attach the tempDiv to the body
  document.body.appendChild(tempDiv);

  // Generate the file name
  const timestamp = new Date().toISOString()
    .replace('T', '_')
    .replace(/:/g, '-')
    .split('.')[0];
  const formattedInvoiceType = this.invoiceType.toLowerCase().replace(/ /g, '_');
  const fileName = `${formattedInvoiceType}_adhoc_${timestamp}.pdf`;

  // HTML2PDF options
  const opt = {
    margin: 1,
    filename: fileName,
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2, useCORS: true },
    jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' },
  };

  // Generate and save the PDF
  html2pdf()
    .from(tempDiv)
    .set(opt)
    .save()
    .then(() => {
      document.body.removeChild(tempDiv); // Cleanup
      this.isDownloading = false;
    })
    .catch((error) => {
      console.error('Error generating the PDF:', error);
      document.body.removeChild(tempDiv);
      this.isDownloading = false;
    });
}






}
