import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-adhoc-preview',
  templateUrl: './adhoc-preview.component.html',
  styleUrls: ['./adhoc-preview.component.scss'],
})
export class AdhocPreviewComponent implements OnInit {
  adhocData: any;

  constructor(private router: Router, private route: ActivatedRoute) {
    const navigation = this.router.getCurrentNavigation();
    this.adhocData = navigation?.extras.state?.['data'];
  }

  ngOnInit(): void {
    if (!this.adhocData) {
      this.router.navigate(['form'], { relativeTo: this.route.parent });
    }

  }

  onConfirm(): void {
    console.log('Invoice confirmed:', this.adhocData);
    this.router.navigate(['/adhoc-invoice']);
  }

  onEdit(): void {
    console.log('Adhoc Data:', this.adhocData);
    if (this.adhocData) {
      this.router.navigate(['form'], {
        relativeTo: this.route.parent,
        queryParams: { data: JSON.stringify(this.adhocData) }, // Pass data as JSON string
      });
    } else {
      console.error('No data to pass to the form');
    }
  }
  
  
  
  
}
