<div class="container-fluid">
  <!-- Row for Scheduled Plans -->
  <div class="row" *ngIf="scheduledPlans && scheduledPlans.length > 0" class=" mb-3">
    <!-- Carousel for Scheduled Plans -->
    <div class="custom-card col-12 pb-3 mb-3">
      <div class="section-heading text-start mb-4">
        <h5 class=" fw-bold heading text-primary">Scheduled Plans</h5>
        <p class="text-muted sub-heading mt-2">
          Choose from the available plans to quickly generate invoices
        </p>
      </div>
      <div id="scheduledPlansCarousel" class="carousel slide custom-carousel" *ngIf="!isSkeletonLoader" @fadeInOut >
        <!-- Carousel Items -->
        <div class="carousel-inner d-flex">
          <div
            *ngFor="let plan of visibleScheduledPlans"
            class="adhoc-plan-container text-center"
            [ngClass]="{
              'selected-plan': plan?.PlanId === selectedScheduledPlanId && plan?.PlanId === 1,
              'dim-card': !isLatestScheduled(plan),
              'inactive-card': plan?.PlanId !== 1
            }"
            (mouseenter)="highlightPlan($event, true)"
            (mouseleave)="highlightPlan($event, false)"
            (click)="selectPlan(plan, 'scheduled')"
          >
          <div *ngIf="isLatestScheduled(plan)" class="scheduled-plan-ribbon">
            <span class="scheduled-plan-text">Latest Scheduled</span>
          </div>
            <img [src]="plan.ImapgePath" class="adhoc-plan-image" alt="Plan Image" />
            <div class="adhoc-plan-details">
              <h5 class="adhoc-plan-title">{{ plan.Name }}</h5>
              <p class="adhoc-plan-text">
                <strong>Price:</strong> ${{ plan.Price }}<br />
                <strong>Data:</strong> {{ plan.PlanData }}<br />
                <strong>Validity:</strong> {{ plan.ExpirationDays }} days
              </p>
            </div>
          </div>
        </div>
    
        <!-- Controls -->
        <button
        class="carousel-control-prev custom-carousel-btn"
        type="button"
        (click)="scrollPrev()"
        aria-label="Previous"
      >
        <i class="fas fa-chevron-left"></i>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next custom-carousel-btn"
        type="button"
        (click)="scrollNext()"
        aria-label="Next"
      >
        <i class="fas fa-chevron-right"></i>
        <span class="visually-hidden">Next</span>
      </button>
      
      
      </div>
      <div *ngIf="isSkeletonLoader" @fadeInOut style="max-width: 100%;">
        <app-skeleton-loader shape="carousel"></app-skeleton-loader>
      </div>
    </div>
    
    
    <!-- Carousel for Available Plans -->
    <div class="custom-card col-12 pb-3 mb-3">
      <div class="section-heading text-start mb-4">
        <h5 class=" heading fw-bold text-primary">Available Plans</h5>
        <p class="text-muted sub-heading mt-2">Choose from the available plans to quickly generate invoices</p>
      </div>
      <div id="plansCarousel" class="carousel slide custom-carousel" *ngIf="!isSkeletonLoader" @fadeInOut>
        <!-- Carousel Items -->
        <div class="carousel-inner d-flex">
          <div
            *ngFor="let plan of visiblePlans"
            class="adhoc-plan-container text-center"
            [ngClass]="{ 'selected-plan': plan?.PlanId === selectedAvailablePlanId }"
            (mouseenter)="highlightPlan($event, true)"
            (mouseleave)="highlightPlan($event, false)"
            (click)="selectPlan(plan,'available')"
          >
          <div *ngIf="currentPlan?.PlanId === plan?.PlanId" class="current-plan-ribbon">
            <span class="current-plan-text">Current Plan</span>
          </div>
            <img [src]="plan.ImapgePath" class="adhoc-plan-image" alt="Plan Image" />
            <div class="adhoc-plan-details">
              <h5 class="adhoc-plan-title">{{ plan.Name }}</h5>
              <p class="adhoc-plan-text">
                <strong>Price:</strong> ${{ plan.Price }}<br />
                <strong>Data:</strong> {{ plan.PlanData }}<br />
                <strong>Validity:</strong> {{ plan.ExpirationDays }} days
              </p>
            </div>
          </div>
        </div>
    
        <!-- Controls -->
        <button
          class="carousel-control-prev custom-carousel-btn"
          type="button"
          (click)="scrollPlansNext()"
          aria-label="Previous"
        >
          <i class="fas fa-chevron-left"></i>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next custom-carousel-btn"
          type="button"
          (click)="scrollPlansPrev()"
          aria-label="Next"
        >
          <i class="fas fa-chevron-right"></i>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
      <div *ngIf="isSkeletonLoader" @fadeInOut style="max-width: 100%;">
        <app-skeleton-loader shape="carousel"></app-skeleton-loader>
      </div>
    </div>
  </div>

  <!-- Row for Non-Scheduled Plans -->
  <div class="row" *ngIf="!(scheduledPlans && scheduledPlans.length > 0)">
    <div class="col-12 text-center" style="max-height: 60vh;overflow-y: auto;" *ngIf="!isPlanSkeleton" @fadeInOut >
      
      <div class="section-heading custom-card text-start mb-4 sticky-heading">
        <h5 class=" fw-bold heading text-primary">Available Plans</h5>
        <p class="text-muted sub-heading m-2">
          Choose from a variety of plans that best suit your needs. Select a plan to view its details.
        </p>
        <div class="wt-tabs-header-sticky mt-2" *ngIf="tabs?.length>0">
          <button class="tab-nav-btn prev" (click)="goToPrevTab()" [disabled]="activeTabIndex === 0">
            <i class="fas fa-chevron-left"></i>
          </button>
          <div id="tabsHeaderContainer" class="wt-tabs-header-container" #tabsContainer>
            <ng-container *ngFor="let tab of tabs; let i = index">
              <div class="wt-tabs-header-scroll-section" (click)="sendplanId(tab.id,tab.Name )" *ngIf="tab.TagType=='Plan'">
                <button [ngClass]="{'active': activeTabIndex === i}" class="wt-tab " (click)="setActiveTab(i)">
                  <label class="wt-typography wt-tab-label" data-type="body-single-line-sm " style="cursor: pointer;">
                    {{ tab.Name }}
                  </label>
                </button>
              </div>
              <span class="wt-tabs-active-indicator" [style.left.px]="activeTabIndicatorLeft"
                (click)="sendplanId(tab.id,tab.Name)" *ngIf="tab.TagType=='Plan'"></span>
            </ng-container>
          </div>
          <button class="tab-nav-btn next" (click)="goToNextTab()" [disabled]="activeTabIndex === tabs.length - 1">
            <i class="fas fa-chevron-right"></i>
          </button>
        </div>
      </div>
      
      <div class="col-12">
        

        <!-- <h3 class="text-secondary mb-4">Available Plans</h3>
        <p class="text-muted">
          Choose from a variety of plans that best suit your needs. Select a plan to view its details.
        </p>
   -->
        <div class="row p-2" style=" overflow: auto;"  *ngIf="plans?.length > 0" >
          

          <div class="col-md-12">
                <!-- Green Cross Ribbon -->
            <div class="card-container" *ngFor="let card of plans" [attr.data-category]="card.category" style="cursor: pointer;"
             >

              <div >

                <div class="core-benefits-container mt-2 col-md-12 p-3" [ngClass]="{ 'selected-available-plan': selectedPlanId === card?.PlanId ,'unselected-available-plan': selectedPlanId != card?.PlanId}">
                  <div *ngIf="currentPlan?.PlanId === card?.PlanId" class="ribbon">
                    <span class="ribbonStyle">
                      Current Plan
                    </span>
                  </div>
                  <div *ngIf="advancePlan?.PlanId === card?.PlanId" class="ribbon">
                    <span class="ribbonStyleadvance">
                      Advance Plan
                    </span>
                  </div>
                  <div class="d-flex  m-0 p-0"  [ngClass]="{'unclickable': currentPlan?.PlanId === card?.PlanId,}" (click)="selectPlan(card,'available')">

                    <ng-container *ngIf="card?.ImapgePath">
                      <div class="image-container" style="max-width: 20%; overflow: hidden; border-radius: 10px; position: relative;">
          
                        <!-- Skeleton Placeholder -->
                        <div class="placeholder-image"></div>
                    
                        <!-- Actual Image -->
                        <img 
                          [src]="card?.ImapgePath"
                          alt="Plan Image"
                          loading="lazy"
                          style="width: 100%; height: auto; object-fit: cover; border-radius: 10px; position: absolute; top: 0; left: 0;" />
                      </div>
                      
                    </ng-container>
                    <div class="d-flex flex-column w-100">
                      <div class="top-data d-flex justify-content-evenly w-100 mb-2">
                        <div class="benefit-list mb-0">
                          <label class=" mb-0 gap-1 d-flex align-items-start">
                            <span class="text-muted mt-2">$</span>
                            <span class="benefit-title">{{ card.Price }}</span>
                          </label>
                          <span class="text-muted mx-4">Total</span>
                        </div>
                        <div class="benefit-list mb-0 d-flex flex-column">
                          <label class="benefit-title mb-0">{{ card.PlanData }}</label>
                          <span class="text-muted">Data</span>
                        </div>
                        <div class="benefit-list mb-0">
                          <label class="d-flex align-items-center mb-0 gap-1">
                            <span class="benefit-title">{{ card.ExpirationDays }}</span>
                            <span class="text-muted mt-1">Days</span>
                          </label>
                          <span class="text-muted">Validity</span>
                        </div>
                        <div class="benefit-list mb-0" *ngIf="card.PlanData == 'Unlimited'">
                          <label class="d-flex align-items-center mb-0 gap-1">
                            <span class="text-muted mt-2">$</span>
                            <span class="benefit-title">{{ card?.monthlyAmount }}</span>
                          </label>
                          <span class="text-muted">Monthly</span>
                          <!-- <span class="text-muted">Validity</span> -->
                        </div>
                      </div>
                      <div class="bottom-data d-flex justify-content-evenly w-100 flex-column-mobile">
                        <div class="d-flex align-items-center">
                          <app-feather-icons [icon]="'phone-call'" class="mt-2"></app-feather-icons>
                          <span class="ms-2">{{ card?.VoiceText || "-" }} Minutes</span>
                        </div>
                        <div class="d-flex align-items-center no-hover-dot">
                          <app-feather-icons [icon]="'smartphone'"></app-feather-icons>
                          <span class="ms-2 ellipsis-tooltip no-hover-dot" matTooltip="{{ card?.Name || '-' }}" matTooltipPosition="above">
                            {{ card?.Name || "-" }}
                          </span>
                        </div>
                        
                        
                        <div class="d-flex align-items-center ">
                          <app-feather-icons [icon]="'message-square'" class="mt-2"></app-feather-icons>
                          <span class="ms-2">{{ card?.VoiceText || "-" }} SMS</span>
                        </div>
                        <div class="d-flex align-items-center " *ngIf="card.PlanData == 'Unlimited'">
                          <app-feather-icons [icon]="'dollar-sign'" class="mt-2"></app-feather-icons>
                          <span class="ms-2">{{ card?.savingAmount }} Savings</span>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="d-flex">
                                      <button class="btn btn-primary " >Details</button>
                                  </div> -->
                  </div>
                </div>
      
              </div>
            </div>
      
          </div>
        </div>
      </div>
  
  
  
  
  
    </div>
    <div *ngIf="isPlanSkeleton" style="max-width: 100%;" @fadeInOut>
      <app-skeleton-loader [numberOfCards]="10" shape="plan-card"></app-skeleton-loader>
    </div>
  </div>
</div>
