<div class="container-fluid position-sticky" [ngClass]="{'p ms-5': !this.navServices.collapseSidebar}">
  <!-- for desktop screen -->
  <div class="page-title d-none d-md-block">
    <div class="row">

      <div class="col-3 d-flex position-relative" [ngClass]="{'col-4': isCustomerProfilePage}">
        <h4 class="mb-0" *ngIf="!isCustomerProfilePage" style="position:absolute;left:120px;top:-10px">{{ active_item }}
        </h4>
        <h4 class="mb-0" *ngIf="isCustomerProfilePage" style="position:absolute;left:120px;top:-10px">
          {{customerProfileService.minimalProfileData?.personalInfo?.name?.value}}

          ({{customerProfileService.minimalProfileData?.personalInfo?.customerId}})</h4>
      </div>
      <div class="col-5">
        <div class="badge-container d-flex align-items-center d-none d-md-block" *ngIf="isCustomerProfilePage">
          <span class="badge badge-dark tag-pills-sm-mb"
            *ngIf="customerProfileService.minimalProfileData?.lineInfo?.MDN?.value">
            <div class="align-equal">
              <app-feather-icons [icon]="'user'"></app-feather-icons> MDN
              {{customerProfileService.minimalProfileData?.lineInfo?.MDN?.value}}
            </div>
          </span>

          <span class="badge badge-dark tag-pills-sm-mb me-2">
            <div class="align-equal">
              <app-feather-icons [icon]="'circle'"></app-feather-icons>
              {{customerProfileService.minimalProfileData?.personalInfo?.customerType?.value}}
            </div>
          </span>
          <span
            [ngClass]="{'orange-badge':customerProfileService.minimalProfileData?.personalInfo?.accountStatus?.value=='Suspend','red-badge':customerProfileService.minimalProfileData?.personalInfo?.accountStatus?.value=='Deactivated','green-badge':customerProfileService.minimalProfileData?.personalInfo?.accountStatus?.value=='Active'}"
            class="badge yellow-badge tag-pills-sm-mb me-2 cursor-pointer" (click)="openModal(updateStatusModal)">
            <div class="align-equal">
              <app-feather-icons [icon]="'circle'"></app-feather-icons>
              {{customerProfileService.minimalProfileData?.personalInfo?.accountStatus?.value}}
            </div>
          </span>
          <app-feather-icons [icon]="'user'" style="position: relative;top: 6px;" class="cursor-pointer hover-primary"
            (click)="toggleCustomerProfile()"></app-feather-icons>
          <app-feather-icons (click)="getMinimalProfileData()" [icon]="'refresh-ccw'"
            style="position: relative;top: 6px;right: -8px;" class="cursor-pointer hover-primary"></app-feather-icons>
        </div>
      </div>
      <div class="col-3">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a (click)="routeDefault()">
              <svg class="stroke-icon">
                <use href="assets/svg/icon-sprite.svg#stroke-home"></use>
              </svg>
            </a>
          </li>
          <li class="breadcrumb-item" *ngFor="let item of items; let last = last" [ngClass]="{'active': last}">
            <a *ngIf="!last" [routerLink]="item.url">{{ item.label }}</a>
            <span *ngIf="last">{{ item.label }}</span>
          </li>

        </ol>
      </div>
    </div>
  </div>


  <div class="card-overflow d-block d-md-none p-2 my-2 card" *ngIf="isCustomerProfilePage">
    <div class="badge-container d-flex align-items-center ps-md-5 py-3" style="overflow: auto;">
      <span class="badge badge-dark tag-pills-sm-mb ms-2">
        <div class="align-equal">
          <app-feather-icons [icon]="'user'"></app-feather-icons>
          {{customerProfileService?.minimalProfileData?.personalInfo?.name?.value}}
          ({{customerProfileService?.minimalProfileData?.personalInfo?.customerId}})
        </div>
      </span>
      <span class="badge badge-dark tag-pills-sm-mb">
        <div class="align-equal">
          <app-feather-icons [icon]="'user'"></app-feather-icons> MDN
          {{customerProfileService?.minimalProfileData?.lineInfo?.MDN?.value}}
        </div>
      </span>
      <span class="badge badge-dark tag-pills-sm-mb me-2">
        <div class="align-equal">
          <app-feather-icons [icon]="'circle'"></app-feather-icons>
          {{customerProfileService.minimalProfileData?.personalInfo?.customerType?.value}}
        </div>
      </span>

      <span
        [ngClass]="{'orange-badge':customerProfileService.minimalProfileData?.personalInfo?.accountStatus?.value=='Suspend','red-badge':customerProfileService.minimalProfileData?.personalInfo?.accountStatus?.value=='Deactivated','green-badge':customerProfileService.minimalProfileData?.personalInfo?.accountStatus?.value=='Active'}"
        class="badge yellow-badge tag-pills-sm-mb me-2 p-2 d-none d-md-block">
        <div class="align-equal">

        </div>
      </span>
      <app-feather-icons [icon]="'user'" style="position: relative;top: 6px;"
        class="cursor-pointer hover-primary d-none d-md-block" (click)="toggleCustomerProfile()"></app-feather-icons>
      <app-feather-icons [icon]="'refresh-ccw'" style="position: relative;top: 6px;right: -8px;"
        class="cursor-pointer hover-primary d-none d-md-block"></app-feather-icons>
    </div>
    <!-- For mobile -->
    <div class="badge-container d-flex align-items-center d-block d-md-none my-2">
      <span
        [ngClass]="{'orange-badge':customerProfileService?.minimalProfileData?.personalInfo?.accountStatus?.value=='Suspend','red-badge':customerProfileService?.minimalProfileData?.personalInfo?.accountStatus?.value=='Deactivated','green-badge':customerProfileService?.minimalProfileData?.personalInfo?.accountStatus?.value=='Active'}"
        class="badge yellow-badge tag-pills-sm-mb me-2 p-2" (click)="openModal(updateStatusModal)">
        <div class="align-equal"> {{customerProfileService.minimalProfileData?.personalInfo?.accountStatus?.value}}
        </div>
      </span>
      <app-feather-icons [icon]="'user'" style="position: relative;top: 6px;" class="cursor-pointer hover-primary"
        (click)="toggleCustomerProfile()"></app-feather-icons>
      <app-feather-icons [icon]="'refresh-ccw'" style="position: relative;top: 6px;right: -8px;"
        class="cursor-pointer hover-primary"></app-feather-icons>
    </div>
  </div>

  <!-- for mobile screen -->
  <div class="page-title d-block d-md-none">
    <div class="row">
      <div class="col-4">
        <h5 class="mb-0" *ngIf="!isCustomerProfilePage">{{ active_item }}</h5>
        <h6 class="mb-0" *ngIf="isCustomerProfilePage">
          {{customerProfileService?.minimalProfileData?.personalInfo?.name?.value}}
          ({{customerProfileService?.minimalProfileData?.personalInfo?.customerId}})</h6>
      </div>
      <div class="col-8">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="'/dashboard/default'">
              <svg class="stroke-icon">
                <use href="assets/svg/icon-sprite.svg#stroke-home"></use>
              </svg>
            </a>
          </li>
          <!-- <li class="breadcrumb-item" *ngFor="let item of items">
            {{ item }}
          </li> -->
          <li class="breadcrumb-item active">{{ active_item }}</li>
        </ol>
      </div>
    </div>
  </div>

</div>

<ng-template #updateStatusModal let-modal>
  <div class="modal-header modal-copy-header">
    <h5 class="headerTitle mb-0">Update Status</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <table class="tab-table">
      <thead>
        <tr>
          <th [width]="'50%'" (click)="toggleHead('electronic')" [ngClass]="{'active': showTab === 'electronic'}">
            Electronics</th>
          <th [width]="'50%'" [ngClass]="{'active': showTab === 'non-electronic'}"
            (click)="toggleHead('non-electronic')">Non Electronics</th>
        </tr>
      </thead>
    </table>
    <form [formGroup]="statusFormData">
      <div class="row">
        <div class="col-6 my-2">
          <label>Select Status</label>
          <select class="form-control" (change)="onStatusChange()" formControlName="status">
            <option value="">Select Status</option>
            <option *ngFor="let status of statusOptions" [value]="status.Name">{{status.DisplayName}}</option>
          </select>
        </div>
        <ng-container *ngIf="showTab !== 'non-electronic'">
          <ng-container *ngIf="status.value == 'Deactivated'">
            <div class="col-6 my-2">
              <label>Select Rejection Reason</label>
              <select class="form-control" formControlName="rejectionReason">
                <option value="">Select Rejection Reason</option>
                <option *ngFor="let reason of rejectionReasonOptions" [value]="reason.id">{{reason.Name}}</option>
              </select>
            </div>
          </ng-container>
          <div class="col-6 my-2" *ngIf="status.value == 'Suspend' || status.value == 'Deactivated'">
            <label>Select De Enroll Code</label>
            <select class="form-control" formControlName="deEnrollCode">
              <option value="">Select De Enroll Code</option>
              <option *ngFor="let option of deEnrollCodeOptions" [value]="option.id">{{option.Name}}</option>
            </select>
          </div>
          <ng-container
            *ngIf="this.status.value == 'Active' && customerProfileService?.minimalProfileData?.personalInfo?.accountStatus?.value!='Active'">
            <div class="col-6 my-2">
              <label>Select Trans Exception</label>
              <select class="form-control" formControlName="transException">
                <option value="">Select Trans Exception</option>
                <option *ngFor="let option of transExceptionOptions" [value]="option.id">{{option.Name}}</option>
              </select>
            </div>
          </ng-container>
        </ng-container>
      </div>

      <div class="row my-2">
        <div class="col-6">
          <button type="button" (click)="cancel()" class="btn btn-warning">
            Cancel</button>
        </div>
        <div class="col-6 text-end">
          <button type="button" [disabled]="statusFormData.invalid" (click)="onSubmit()" class="btn btn-primary">
            Submit</button>
        </div>
      </div>

    </form>

    <ng-container *ngIf="loader">
      <app-loader [isChildLoader]="true"></app-loader>
    </ng-container>
  </div>
</ng-template>