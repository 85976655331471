<div class="container-fluid my-4">
    <div class="header">
        <div class="d-flex justify-content-end">
            <select  class="form-select col-md-2 mb-3 py-1" (change)="selectAgent($event)" >
                <option *ngFor="let item of agentList" [value]="item.user_id">{{item.name}}</option>
            </select>
            <div class="d-flex  justify-content-end">
              <div class="date-picker" (click)="stop($event)">
                  <div class="date-picker hiringCal round-border d-flex mx-2" style="justify-content: end;"
                      >
                      <div class="f-row d-inline-block position-relative reports-datePicker">
                          <button class="f-row f-a-center px-3 filter-btn round hover-light py-1 date-button">
                              <i class="fa fa-calendar" (click)="drp.toggle()" [attr.aria-expanded]="drp.isOpen"></i>
                              <input [(ngModel)]="bsRangeValue" (ngModelChange)="dataChanged()"
                                  [bsConfig]="{ rangeInputFormat: 'MMM DD, YYYY', adaptivePosition: true, showWeekNumbers: false }"
                                  bsDaterangepicker class="p-2 py-0 text border-0 bg-transparent cursor-pointer"
                                  placeholder="Daterangepicker" type="text" readonly #drp="bsDaterangepicker" />
                              <i class="fa fa-chevron-down" (click)="drp.toggle()"
                                  [attr.aria-expanded]="drp.isOpen"></i>
                          </button>
                      </div>
                  </div>
              </div>
          </div>
        </div>

    </div>
    <div class="row">
      <div class="col-md-3" *ngFor="let card of kpiCards">
        <div class="card text-white  mb-3" [ngClass]="card?.color">
          <div class="card-body  text-center">
            <!-- <div class="icon-box">
              <i class="{{ card?.iconClass }} text-dark"></i>
            </div> -->
           <div class="content">
            <h5 class="card-title">{{ card?.title }}</h5>
            <h2 class="card-text">{{ card?.count }}</h2>
            <p class="mb-2">Task count</p>
           </div>
    
            <!-- <button class="btn btn-sm {{ card.buttonClass }}">{{ card.filterLabel }}</button> -->
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex gap-3 mr-3 mb-4">
        <div class="col-6 custom-card">
            <h5>Incomplete Tasks by Months</h5>
          <apx-chart
            [series]="barChartOptions?.series"
            [chart]="barChartOptions?.chart"
            [xaxis]="barChartOptions?.xaxis"
            [yaxis]="barChartOptions?.yaxis"
            [plotOptions]="barChartOptions?.plotOptions"
            [tooltip]="barChartOptions?.tooltip"
            [grid]="barChartOptions?.grid"
            [fill]="barChartOptions?.fill"
          ></apx-chart>
        </div>
        <!-- Doughnut Chart -->
        <div class="col-6 custom-card">
            <h5>Tasks by Completion Status</h5>
          <apx-chart
            [series]="doughnutChartOptions?.series"
            [chart]="doughnutChartOptions?.chart"
            [labels]="doughnutChartOptions?.labels"
            [colors]="doughnutChartOptions?.colors"
            [legend]="doughnutChartOptions?.legend"
            [tooltip]="doughnutChartOptions?.tooltip"
          ></apx-chart>
        </div>
      </div>
    
      <!-- Horizontal Bar Chart -->
      <div class="d-flex gap-3 mr-3 mb-4">
        <div class="col-6 custom-card">
            <h5>Upcoming Tasks by Assignee </h5>
          <apx-chart
            [series]="horizontalBarChartOptions?.series"
            [chart]="horizontalBarChartOptions?.chart"
            [xaxis]="horizontalBarChartOptions?.xaxis"
            [yaxis]="horizontalBarChartOptions?.yaxis"
            [plotOptions]="horizontalBarChartOptions?.plotOptions"
            [tooltip]="horizontalBarChartOptions?.tooltip"
            [grid]="horizontalBarChartOptions?.grid"
            [fill]="horizontalBarChartOptions?.fill"
          ></apx-chart>
        </div>
        <!-- Area Chart -->
        <div class="col-6 custom-card">
          <h5>Projects by Project Status</h5>
          <apx-chart
          [series]="statusDataChart?.series"
          [chart]="statusDataChart?.chart"
          [labels]="statusDataChart?.labels"
          [colors]="statusDataChart?.colors"
          [legend]="statusDataChart?.legend"
          [tooltip]="statusDataChart?.tooltip"
        ></apx-chart>
          <!-- <apx-chart
            [series]="areaChartOptions.series"
            [chart]="areaChartOptions.chart"
            [xaxis]="areaChartOptions.xaxis"
            [yaxis]="areaChartOptions.yaxis"
            [stroke]="areaChartOptions.stroke"
            [tooltip]="areaChartOptions.tooltip"
            [fill]="areaChartOptions.fill"
            [grid]="areaChartOptions.grid"
          ></apx-chart> -->
        </div>
      </div>
  </div>
  
