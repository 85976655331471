import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/shared/services/httpService.service';

@Injectable({
  providedIn: 'root'
})
export class CampaignEmailService {

  @LocalStorage()
  private userDetails;
  URL:any
  constructor(
    private http: HttpService,
    private https:HttpClient
  ) { 
    this.URL = localStorage.getItem('BaseUrl');
  }


  uploadCSV(formData: FormData): Observable<any> {
    let url = `${this.URL}api/Customer/bulk-email-upload`;;
    return  this.https.post(url,formData)
  }

  getBulkEmailData(data) {
    try {
      let url = "api/Customer/bulkEmailList";
      return this.http.get(url,data,  false, false).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
  getEmailTemplate() {
    try {
      let url = "api/system/file-template";
      return this.http.get(url,  false, false).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
 sentStatus(data) {
    try {
      let url = "api/Customer/reset";
      return this.http.post(url,data,  false, false).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  sendEmail(data) {
    try {
      let url = "api/Customer/send-email";
      return this.http.post(url,data,  false, false).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
}