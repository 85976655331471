import { Component, HostListener } from '@angular/core';
import { NotificationService } from '../../services/notification.service';
import { Router } from '@angular/router';
import { MyProfileService } from 'src/app/profile/services/my-profile.service';

@Component({
  selector: 'app-all-notification',
  templateUrl: './all-notification.component.html',
  styleUrls: ['./all-notification.component.scss']
})
export class AllNotificationComponent {
  notifications:any = []
  Allnotifications:any = []
  currentPage: number = 1;
  totalPages: number = 0;
  isLoading: boolean = false;
  userData:any
  total:any
constructor(    private notificationService: NotificationService,private router: Router, private myProfileService: MyProfileService){
  
}
 async ngOnInit() {

  this.fetchNotifications()
// this.userData = localStorage.getItem('tibss.userDetails');
this.userData = this.myProfileService.getUser();
console.log("user details ",this.userData

)
  }
  async fetchNotifications(): Promise<void> {
    if (this.isLoading || (this.totalPages && this.currentPage > this.totalPages)) {
      return;
    }

    this.isLoading = true;

    try {
      const res: any = await this.notificationService.fetchNotification2(this.currentPage);

      if (res.Status) {
        this.notifications = res;
        this.Allnotifications = [...this.Allnotifications, ...res?.data?.data];
        this.currentPage = res.data.current_page;
        this.totalPages = res.data.last_page;
        this.total = res?.data?.total

      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
    } finally {
      this.isLoading = false;
    }
  }
  @HostListener('scroll', ['$event'])
  onScroll(event: any): void {
    const { scrollTop, scrollHeight, clientHeight } = event.target;

    if (scrollHeight - scrollTop <= clientHeight + 50) {
      // Load the next page when scrolled near the bottom
      this.currentPage++;
      this.fetchNotifications();
    }
  }
  handleClick(notification: any): void {
      this.notificationService.getmarAsRead(notification.id)
      this.markAsRead(notification?.id)
      const regex = /#(\d+)/; // Regular expression to extract the ID
      const match = notification?.Name?.match(regex);
      const ticketId = match ? match[1] : null; // Extract the ticket ID
      if (ticketId) {
        // Navigate with ticket ID as a parameter
        this.router.navigate(['/ticket-system'], {
          queryParams: { ticketId: ticketId, showMsg: true },
        }).then(() => {
          console.log(`Navigated to ticket with ID: ${ticketId}`);
        });
      } else {
        console.log('No ticket ID found in the notification name.');
      }
    }
    markAsRead(id: any): void {
      this.notificationService.markAsRead(id);
    }
}
