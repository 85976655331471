<div class="customizer-contain" [ngClass]="{ open: showStepper === true }">
  <div class="tab-content" id="c-pills-tabContentAgent">
    <div class="customizer-header sticky-header">
      <h5 class="mb-0">
        {{ transectionId ? 'Transaction Detail' : (displayPaymentHistory === true ? 'Payment History' : 'Invoice
        History') }}
      </h5>
      <i class="icofont-close icon-close" (click)="hideStepper()"></i>
    </div>
    <div style="margin-top: 2px ;">
      <div class="hr" style="width: 100%; border: 1px solid lightgray;"></div>
    </div>
    <div class="scrollable-content">
      <!-- Payment History Details -->
      <div class="container-fluid" style="margin-top: 1rem;" *ngIf="displayPaymentHistory  && !transectionId">
        <!-- <ag-grid-angular style="height:600px;" class="ag-theme-alpine" [rowClass]="gridRowClass"
                    [rowData]="paymentHistoryList" [defaultColDef]="defaultColDef" [suppressRowHoverHighlight]="true"
                    [animateRows]="true" [pagination]="false" [rowHeight]="55" [columnDefs]="columnDefs">
                </ag-grid-angular> -->
        <div class="table-responsive table-hover table-striped table-card card">
          <table class="table table-nowrap">
            <thead clas="table-light">
              <tr>
                <th *ngFor="let col of columnDefs">
                  {{ col.headerName }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of paymentHistoryList" >
                <td *ngFor="let col of columnDefs">
                  <ng-container [ngSwitch]="col.field">
                    <span *ngSwitchCase="'CreatedDate'">{{ row[col.field] | date: 'MMM d, y, HH:mm' }}</span>

                    <span *ngSwitchCase="'Address'" class="text-ellipsis" [title]="row[col.field]">
                      {{ row[col.field] }}
                    </span>

                    <span *ngSwitchCase="'Amount'" class="text-nowrap">
                      ${{ row[col.field]}}
                    </span>
                    <span *ngSwitchDefault>
                      {{ row[col.field] || '-' }}
                    </span>
                  </ng-container>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="pull-right p-20 d-flex justify-content-end">
          <select class="form-control me-2 page-dropdown" style="width: auto;" [(ngModel)]="itemsPerPage"
            (change)="onPaymentChangeNumberOfPage()">
            <option *ngFor="let page of pageDropdownMenu" [ngValue]="page">{{page}}</option>
          </select>
          <ngb-pagination [maxSize]="1" [directionLinks]="true" [boundaryLinks]="true" [collectionSize]="totalItems"
            [(page)]="currentPage" (pageChange)="pageChange($event)" [pageSize]="itemsPerPage">
            <ng-template ngbPaginationPrevious>Previous</ng-template>
            <ng-template ngbPaginationNext>Next</ng-template>
          </ngb-pagination>
        </div>
      </div>

      <!-- Invoice History Details -->
      <div class="container-fluid" style="margin-top: 1rem;" *ngIf="!displayPaymentHistory && !transectionId">
        <!-- <ag-grid-angular style="height:600px;" class="ag-theme-alpine" [rowClass]="gridRowClass"
                    [rowData]="invoiceList" [defaultColDef]="defaultColDef" [suppressRowHoverHighlight]="true"
                    [animateRows]="true" [pagination]="false" [rowHeight]="55" [columnDefs]="invoiceColumnDefs">
                </ag-grid-angular> -->
         
                <app-dynamic-invoice-template [CustomerId]="CustomerId"></app-dynamic-invoice-template>
                    
                <!--  <div class="table-responsive table-hover table-striped table-card card">
          <table class="table table-nowrap">
          
            <thead class="table-light">
              <tr class="text-center">
                <th *ngFor="let col of invoiceColumnDefs">
                  {{ col.headerName }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of invoiceList" class="text-center">
                <td *ngFor="let col of invoiceColumnDefs">
                  <ng-container [ngSwitch]="col.field">
                    <ng-container *ngSwitchCase="'Code'">
                      <span class="custom-badge" [ngClass]="{
            'badge-success': row[col.field] === 'RECEIVED',
            'badge-danger': row[col.field] === 'FAILED',
            'badge-warning': row[col.field] === 'PENDING'
          }">
                        {{ row[col.field] || '-' }}
                      </span>

                    </ng-container>
                    <span *ngSwitchCase="'PaymentDate'" class="text-nowrap">
                      {{ row[col.field] | date: 'MMM d, y, HH:mm' }}
                    </span>
                    <span *ngSwitchCase="'DueDate'" class="text-nowrap">
                      {{ row[col.field] | date: 'MMM d, y, HH:mm' }}
                    </span>
                    <span *ngSwitchCase="'TotalAmount'" class="text-nowrap">
                      ${{ row[col.field] | number:'1.0-0' }}
                    </span>
                    <span *ngSwitchDefault>
                      {{ row[col.field] || '-' }}
                    </span>
                  </ng-container>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="pull-right p-20 d-flex justify-content-end">
          <select class="form-control me-2 page-dropdown" style="width: auto;" [(ngModel)]="itemsPerPage"
            (change)="onInvoiceChangeNumberOfPage()">
            <option *ngFor="let page of pageDropdownMenu" [ngValue]="page">{{page}}</option>
          </select>
          <ngb-pagination [maxSize]="3" [directionLinks]="true" [boundaryLinks]="true"
            [collectionSize]="invoiceTotalItems" [(page)]="invoiceCurrentPage" (pageChange)="invoicePageChange($event)"
            [pageSize]="itemsPerPage">
            <ng-template ngbPaginationPrevious>Previous</ng-template>
            <ng-template ngbPaginationNext>Next</ng-template>
          </ngb-pagination>
        </div> -->
      </div>

      <!-- Transection Detail History Details -->


      <div *ngIf="!displayPaymentHistory && transectionId && transectionData">
        <!-- <label>{{transectionData?.transId}}</label>
                <label>{{transectionData?.transactionStatus}}</label> -->
        <div class="transaction-info" style="margin: 1rem;">
          <table class="table table-bordered container" style="width: 80%; white-space: nowrap;">
            <thead style="font-size: medium; font-weight: bolder; letter-spacing: 2px; background-color: lightgrey;">
              <tr>
                <th>Customer Name</th>
                <th>Transaction ID</th>
                <th>Transaction Time</th>
                <th>Transaction Type</th>
                <th>Transaction Status</th>
                <th>Settle Amount</th>
                <th>Card Number</th>
                <th>Card Type</th>
                <th>Expiration Date</th>

              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let transaction of transectionData">
                <td>
                  {{transaction?.billTo?.firstName + ' ' + transaction?.billTo?.lastName}}
                </td>
                <td>
                  {{transaction?.transId}}
                </td>
                <td>
                  {{transaction?.submitTimeUTC | date:'MMM d, y h:mm a'}}
                </td>
                <td>
                  {{formatTransactionType(transaction?.transactionType)}}
                </td>
                <td>
                  {{formatTransactionType(transaction?.transactionStatus)}}
                </td>
                <td>
                  {{transaction?.settleAmount}}$
                </td>
                <td>
                  {{transaction?.payment?.creditCard?.cardNumber}}
                </td>
                <td>
                  {{transaction?.payment?.creditCard?.cardType}}
                </td>
                <td>
                  {{ (transaction?.payment?.creditCard?.expirationDate | date ) ?
                  (transaction?.payment?.creditCard?.expirationDate | date : '-') : '-' }}
                </td>

              </tr>
            </tbody>
          </table>
        </div>
      </div>





    </div>
  </div>