<div class="card m-t-10 widget-hover" (click)="applyGraphDates()" *ngIf="!isSkeletonLoader;" @fadeInOut>
  <div class="card-body" [ngClass]="{'selectedCard': selectedCard }">
    <div class="row">
      <div class="col-md-6">
        <h6>Subscription Chart</h6>
      </div>
      <div class="col-md-6">
        <div class="date-picker" (click)="stop($event)">
          <div class="date-picker hiringCal round-border d-flex" style="justify-content: end;">
            <div class="f-row d-inline-block position-relative reports-datePicker">
              <button class="f-row f-a-center button1 text-dark round hover-light py-0 date-button">
                <i class="fa fa-calendar" (click)="drp.toggle()" [attr.aria-expanded]="drp.isOpen"></i>
                <input [(ngModel)]="bsRangeValue" (ngModelChange)="onDateRangeChange()"
                  [bsConfig]="{ rangeInputFormat: 'MMM DD, YYYY', adaptivePosition: true, showWeekNumbers: false }"
                  bsDaterangepicker class="pl-2 border-0 bg-transparent cursor-pointer" placeholder="Daterangepicker"
                  type="text" readonly #drp="bsDaterangepicker" style="min-width: 200px !important; color: #11100e;" />
                <i class="fa fa-caret-square-o-down" (click)="drp.toggle()" [attr.aria-expanded]="drp.isOpen"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
  
    </div>
   
    <div id="chart" style="max-height: 210px;">
      <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart" [labels]="chartOptions.labels"
        [responsive]="chartOptions.responsive" [title]="chartOptions.title"></apx-chart>
    </div>
  </div>
</div>

<div class="card">
  <div *ngIf="isSkeletonLoader;" @fadeInOut style="max-height: 100%;max-width:100%;">
    <app-skeleton-loader  shape="pieChart"></app-skeleton-loader>
  </div>
</div>