import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-adhoc-custom',
  templateUrl: './adhoc-custom.component.html',
  styleUrls: ['./adhoc-custom.component.scss']
})
export class AdhocCustomComponent implements OnInit {

  @Input() customerData:any
  @Output() updateInvoiceSummary = new EventEmitter<any>();
  invoiceForm: FormGroup;

  // Editor configuration
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '200px',
    minHeight: '200px',
    placeholder: 'Enter description here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [[], []], // Do not hide any buttons

  };

  constructor(private fb: FormBuilder) {
    this.invoiceForm = this.fb.group({
      amount: [
        '', 
        [Validators.required, Validators.min(1)] // Amount is required and must be > 0
      ],
      description: [
        '', 
        [Validators.required] // Description is required
      ]
    });
  }

  ngOnInit(): void {
    this.emitInvoiceSummary();
    this.invoiceForm.valueChanges.subscribe(() => {
      this.emitInvoiceSummary();
    });
  }

  emitInvoiceSummary(): void {
    this.updateInvoiceSummary.emit({
      amount: this.invoiceForm.get('amount')?.value || 0,
      description: this.invoiceForm.get('description')?.value || 'No description provided.',
      isValid: this.invoiceForm.valid
    });
  }
  onSubmit(): void {
    if (this.invoiceForm.valid) {
      const formData = this.invoiceForm.value;
      console.log('Form Data:', formData);

      // API call or other logic to handle the form submission
    } else {
      console.log('Form is invalid');
    }
  }
}
