import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerProfileService } from '../shared/services/customer-profile.service';
import { CommonService } from '../shared/services/common.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-customer-dashboard',
  templateUrl: './customer-dashboard.component.html',
  styleUrls: ['./customer-dashboard.component.scss']
})
export class CustomerDashboardComponent implements OnInit {
  listTabs: any = [
    { id: 'customer-profile', title: 'Customer Profile' },
    { id: 'billing-invoice', title: 'Billing and Invoice' },
    { id: 'order-history', title: 'Order History' },
    { id: 'purchase', title: 'Purchase History' },
    { id: 'tickets', title: 'Tickets' },
    { id: 'customer-history', title: 'Customer History' },
    { id: 'activity-log', title: 'Activity Log' },
    { id: 'manage-card', title: 'Manage Card' },
    {id: 'files', title: 'Files'}
  ]
  currentTab: any;
  breadcrumbTitle: string = '';
  breadcrumbItems: any = ['Dashboard']
  toggleUserProfile: boolean = false;
  showBackButton: boolean = false;
  SETTINGSItems:any = []
  isHiddenData:any
  showModal:boolean ;
  isAllHidden
  constructor(
    public location: Location,
    private router :Router,
    public customerProfileService: CustomerProfileService,
    public commonService: CommonService,
    private route :ActivatedRoute,
    private cd:ChangeDetectorRef
  ) { }

  ngOnInit() {

    // this.currentTab = storedTab || 'customer-profile';
    const redirectAfterRefresh = sessionStorage.getItem('redirectAfterRefresh');
    const storedTab = sessionStorage.getItem('currentTab');
    if (redirectAfterRefresh === 'true') {
      console.log('Redirecting to the current tab after refresh');
      sessionStorage.removeItem('redirectAfterRefresh'); // Clear the flag
      
      // Retrieve the last active tab from sessionStorage, default to 'customer-profile'
      const lastActiveTab = sessionStorage.getItem('currentTab');
      
      // Navigate to the stored tab
      console.log('storeda: ', storedTab);
      this.router.navigate([`/customer-dashboard/${storedTab}`]);
      this.customerProfileService.getMinimalProfileData();
    }
    
    this.customerProfileService.sideMenuInitilize();
    this.customerProfileService.getSideMenuBar().subscribe((SETTINGSItems: any) => {
      this.isHiddenData = SETTINGSItems
          console.log("Item at index 5:", this.isHiddenData);
          this.isAllHidden = this.isHiddenData.every((item: any) => item.isHidden == 0);

          // Set the variable to true if all values are 0, else false if any value is 1
          // const isAllHidden = allZero ? true : this.isHiddenData.includes(1) ? false : true;
        
          console.log("isAllHidden:", this.isAllHidden);
      this.cd.detectChanges();
        this.SETTINGSItems = SETTINGSItems.filter(item => !item.isHidden);
          // console.log("Item at index 6:", this.SETTINGSItems);
          // this.cd.detectChanges();// Trigger change detection after the assignment
    });
    this.setCurrent()
    this.route.queryParams.subscribe(params => {
      this.showBackButton = params['showBack'] === 'true';
      this.showModal = params['openmodal'] === 'true';
      console.log("showmodal",this.showModal)
  
    });
  }



  clearAlerts(index) {
    this.customerProfileService.minimalProfileData.alerts.splice(this.customerProfileService.minimalProfileData.alerts.indexOf(index), 1);
   }

  setCurrent() {
    var currentUrl = window.location.href;
    var parts = currentUrl.split('/');
    var lastPart = parts[parts.length - 1];
    this.currentTab = lastPart;
    console.log('current tab: ', currentUrl);
    sessionStorage.setItem('currentTab', lastPart);
    this.setBreadcrumb()
  }

  changeTabRight() {
    let result = this.listTabs.map(e => e.id).indexOf(this.currentTab);
    if (result < 7) {
      let newLink = this.listTabs[result + 1].id;
      this.router.navigateByUrl(`/customer-dashboard/${newLink}`)
      this.currentTab = newLink;
      this.setBreadcrumb()
    }
  }

  changeTabLeft() {
    let result = this.listTabs.map(e => e.id).indexOf(this.currentTab);
    if (result > 0) {
      let newLink = this.listTabs[result - 1].id;
      this.router.navigateByUrl(`/customer/${newLink}`)
      this.currentTab = newLink;
      this.setBreadcrumb()
    }
  }
  @HostListener('window:beforeunload', ['$event'])
  handleBeforeUnload(event: Event): void {
    console.log('Browser refresh detected!');
    sessionStorage.setItem('redirectAfterRefresh', 'true'); // Mark the need for redirection
  }
  changeTabs(link: any) {
    this.currentTab = link;
    sessionStorage.setItem('currentTab', link);
    this.setBreadcrumb()
  }

  setBreadcrumb() {
    this.breadcrumbTitle = this.listTabs.find(el => el.id == this.currentTab)?.title
    this.commonService.setbreadCrumb({
      "title": this.breadcrumbTitle,
      "items": this.breadcrumbItems,
      "active_item": this.breadcrumbTitle
    })
  }

  toggleCustomerProfile() {
    this.toggleUserProfile = !this.toggleUserProfile
    this.customerProfileService.setProfileQuicklink(this.toggleUserProfile)
    this.toggleUserProfile = !this.toggleUserProfile
  }

  backToOrderList(){
    this.location.back();
  }
}