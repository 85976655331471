
<div class="row mt-4 mx-1">
    <app-adhoc-form [currentCustomerId]="currentCustomerId"></app-adhoc-form>
</div>
<div class="row mt-4 mx-1">
    <div class="col-lg-6 col-md-4 col-sm-6 col-xs-12">
      <div class="custom-card m-1">
        <h5 class="mb-3">Invoices</h5>
      <app-dynamic-invoice-template [CustomerId]="currentCustomerId"></app-dynamic-invoice-template>
      </div>
    </div>

    <div class="col-lg-6 col-md-4 col-sm-6 col-xs-12">
      <div class="custom-card m-1">
        <div class="d-flex justify-content-between align-items-center">
            <h4 class="mb-3" style="font-size: 1rem;">Payment History</h4>
            <a (click)="openHistoryPage('Payment')" class="mx-3 cursor-pointer fs-11 text-bg fw-bold text-nowrap">View more</a>
          </div>
          <div>
            <!-- <small class="text-dark fw-bold ms-1" style="font-size: 8px;">LATEST PAYMENT</small> -->
            <app-payment-his-card [id]="currentCustomerId"></app-payment-his-card>
          </div>
      </div>
    </div>
</div>