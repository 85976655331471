<!-- <app-breadcrumb [title]="'User'" [items]="['Apps', 'User']" [active_item]="'Edit Profile'"></app-breadcrumb> -->
<div class="container-fluid mx-3" *ngIf="!isSkeletonLoader" @fadeInOut>
  <div class="edit-profile">
    <div class="card-header d-flex justify-content-between">
      <h4 class="card-title mb-0" (click)="backToOrderList()" style="font-size: 20px; cursor: pointer">
        <i class="fa-solid fa-arrow-left"></i>Back
      </h4>
      <div class="d-flex gap-2">
        <div class="" *ngIf="isEditing">
          <button type="button" class="btn btn-outline-success me-2" (click)="onSave()">
            Save
          </button>
          <button type="button" class="btn btn-outline-secondary" (click)="onCancel()">
            Cancel
          </button>
        </div>
        <button class="btn btn-outline-dark btn-sm" (click)="onEdit()" *ngIf="isEditing">
          Edit <i class="fa-regular fa-pen-to-square mx-1"></i>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-xxl-3">
        <div class="card mt-4 mb-3">
          <div class="card-body p-4">
            <div class="text-center">
              <div class="profile-user position-relative d-inline-block mx-auto mb-4">
                <div class="loader-overlay" *ngIf="isUploading">
                  <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                <img [src]="
                    profileData?.profile_image
                      ? profileData?.profile_image
                      : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ6qbvcQ5rRZf7SG-GZ5cQipaC7qTdpc2UZiNtt6cuTlyp4OkV-cST3VAx_2S_KW_ehyak&usqp=CAU'
                  " class="rounded-circle avatar-xl img-thumbnail user-profile-image material-shadow"
                  alt="user-profile-image" />
                <div class="avatar-xs p-0 rounded-circle profile-photo-edit">
                  <input id="profile-img-file-input" type="file" class="profile-img-file-input"
                    (change)="onProfileImageChange($event)" />
                  <label for="profile-img-file-input"
                    class="profile-photo-edit avatar-xs">
                    <span class="avatar-title rounded-circle bg-light text-body material-shadow">
                      <i class="fa-solid text-dark fa-camera"></i>
                    </span>
                  </label>
                </div>
              </div>
              <h5 class="fs-16 mb-1">{{ profileData?.name }}</h5>
              <p class="text-muted mb-0">
                {{ profileData?.roles[0]?.name }} /
                {{ this.profileData?.status?.Status }}
              </p>
            </div>
          </div>
        </div>
        <div class="custom-card mt-2">
          <h5>Change Password</h5>
          <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
            <!-- Old Password -->
            <div class="mb-3 position-relative">
              <label for="oldPassword" class="form-label">Old Password</label>
              <input
                [type]="showOldPassword ? 'text' : 'password'"
                id="oldPassword"
                class="form-control"
                formControlName="old_password"
                placeholder="Enter your old password"
              />
              <i
                class="fa fa-eye mt-2 position-absolute top-50 end-0 translate-middle-y me-3 cursor-pointer"
                (click)="togglePasswordVisibility('old')"
                [class.fa-eye-slash]="showOldPassword"
                [class.fa-eye]="!showOldPassword"
              ></i>
              <div *ngIf="passwordForm.get('old_password')?.invalid && passwordForm.get('old_password')?.touched" class="text-danger">
                Old password is required and must be at least 8 characters.
              </div>
            </div>
          
            <!-- New Password -->
            <div class="mb-3 position-relative">
              <label for="newPassword" class="form-label">New Password</label>
              <input
                [type]="showNewPassword ? 'text' : 'password'"
                id="newPassword"
                class="form-control"
                formControlName="new_password"
                placeholder="Enter your new password"
              />
              <i
                class="fa fa-eye mt-2 position-absolute top-50 end-0 translate-middle-y me-3 cursor-pointer"
                (click)="togglePasswordVisibility('new')"
                [class.fa-eye-slash]="showNewPassword"
                [class.fa-eye]="!showNewPassword"
              ></i>
              <div *ngIf="passwordForm.get('new_password')?.invalid && passwordForm.get('new_password')?.touched" class="text-danger">
                New password is required and must be at least 8 characters.
              </div>
            </div>
          
            <!-- Confirm Password -->
            <div class="mb-3 position-relative">
              <label for="confirmPassword" class="form-label">Confirm Password</label>
              <input
                [type]="showConfirmPassword ? 'text' : 'password'"
                id="confirmPassword"
                class="form-control"
                formControlName="confirm_password"
                placeholder="Confirm your new password"
              />
              <i
                class="fa fa-eye mt-2 position-absolute top-50 end-0 translate-middle-y me-3 cursor-pointer"
                (click)="togglePasswordVisibility('confirm')"
                [class.fa-eye-slash]="showConfirmPassword"
                [class.fa-eye]="!showConfirmPassword"
              ></i>
              <div *ngIf="passwordForm.get('confirm_password')?.touched && passwordForm.get('confirm_password')?.value !== passwordForm.get('new_password')?.value" class="text-danger">
                Passwords do not match.
              </div>
            </div>
          
            <!-- Submit Button -->
            <button type="submit" class="btn btn-outline-success">Change Password</button>
          </form>
          
        </div>
      </div>
      <div class="col-xxl-9 mt-4">
        <div class="custom-card">
          <div class="card-body">
            <!-- Display Personal Information -->
            <div *ngIf="!isEditing">
              <div class="d-flex justify-content-between align-items-center mb-3">
                <h5 class="card-title mb-0">Personal Information</h5>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <p class="mb-1"><strong>Full Name</strong></p>
                  <p class="text-muted">{{ profileData?.name }}</p>
                </div>
              
                <div class="col-md-6">
                  <p class="mb-1"><strong>Email Address</strong></p>
                  <p class="text-muted">{{ profileData?.email }}</p>
                </div>
                <div class="col-md-6">
                  <p class="mb-1"><strong>Phone</strong></p>
                  <p class="text-muted">
                    {{ profileData?.agent?.PhoneNumber }}
                  </p>
                </div>
                <div class="col-6">
                  <p class="mb-1"><strong>Address</strong></p>
                  <p class="text-muted">
                    {{ profileData?.agent?.AddressLine1 }},
                    {{ profileData?.agent?.AddressLine2 }}
                  </p>
                </div>
              </div>
            </div>

            <!-- Edit Form -->
            <div *ngIf="isEditing">
              <div class="d-flex justify-content-between align-items-center mb-3">
                <h5 class="card-title mb-0">Edit Personal Information</h5>
              </div>
              <form [formGroup]="editForm">
                <div class="row">
                  <div class="col-md-6">
                    <label for="firstName" class="form-label">Full Name</label>
                    <input id="firstName" class="form-control" formControlName="firstName" />
                  </div>
              
                  <div class="col-md-6 mt-2">
                    <label for="email" class="form-label">Email Address</label>
                    <input id="email" type="email" class="form-control" formControlName="email" />
                  </div>
                  <div class="col-md-6 mt-2">
                    <label for="phone" class="form-label">Phone</label>
                    <input id="phone" class="form-control" formControlName="phone" />
                  </div>
                  <div class="col-12 mt-2">
                    <label for="address" class="form-label">Address</label>
                    <textarea id="address" class="form-control" formControlName="address"></textarea>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="custom-card mt-3">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center mb-3">
              <h5 class="card-title mb-0">Employee Information</h5>
            </div>
            <div class="row" *ngIf="!isEditing">
              <div class="col-md-6">
                <p class="mb-1"><strong>Employee ID</strong></p>
                <p class="text-muted">{{ this.profileData?.id }}</p>
              </div>
              <div class="col-md-6">
                <p class="mb-1"><strong>Job title</strong></p>
                <p class="text-muted">Not Availble</p>
              </div>
              <div class="col-md-6">
                <p class="mb-1"><strong>Types of employee</strong></p>
                <p class="text-muted">-</p>
              </div>
              <div class="col-md-6">
                <p class="mb-1"><strong>Department</strong></p>
                <p class="text-muted">-</p>
              </div>
              <div class="col-12">
                <p class="mb-1"><strong>Groups</strong></p>
                <p class="text-muted d-flex" *ngFor="let item of profileData?.usergroup">
                  <span> {{ item?.Name }},</span>
                </p>
              </div>
            </div>
            <div *ngIf="isEditing">
              <form [formGroup]="empinfoForm">
                <div class="row mt-3">
                  <div class="col-md-6">
                    <label for="firstName" class="form-label">Employee ID</label>
                    <input id="firstName" class="form-control" formControlName="empID" />
                  </div>
                  <div class="col-md-6">
                    <label for="lastName" class="form-label">Job title</label>
                    <input id="lastName" class="form-control" formControlName="job_title" />
                  </div>
                  <div class="col-md-6 mt-2">
                    <label for="email" class="form-label">Types of employee</label>
                    <input id="email" type="email" class="form-control" formControlName="typeOfemployee" />
                  </div>
                  <div class="col-md-6 mt-2">
                    <label for="phone" class="form-label">Department</label>
                    <input id="phone" class="form-control" formControlName="department" />
                  </div>
                  <div class="col-12 mt-2">
                    <label for="address" class="form-label">Groups</label>
                    <textarea id="address" class="form-control" formControlName="groups"></textarea>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

 
    </div>
  </div>
</div>

<div *ngIf="isSkeletonLoader" @fadeInOut class="container-fluid">
  <div class="div d-flex flex-row justify-content-between">
    <app-skeleton-loader [cardCount]="1" [width]="'35vw'" [height]="'60vh'" [alignment]="'column'"
      shape="normal-card"></app-skeleton-loader>
    <app-skeleton-loader [cardCount]="1" [width]="'55vw'" [height]="'60vh'" [alignment]="'row'"
      shape="normal-card"></app-skeleton-loader>
  </div>
</div>