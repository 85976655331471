<div class="card get-card" (mouseenter)="showDropdown = true" (mouseleave)="showDropdown = false">
  <div class="card-header card-no-border py-3">
    <div class="d-flex justify-content-between mb-2" style="position: relative">
      <div class="div">
      <h5>Subscription Trend</h5>
      <div class="select" style="position: absolute; display: none;
      top: 1px;
      right: 17rem;">
        <select *ngIf="showDropdown"  value="" class=" primary mx-xl-2 button1 "
          (change)="onChartTypeChange($event)">
          <option value="bar">Bar</option>
          <option value="radialBar">Radial Bar</option>
          <option value="line">Line</option>
     
        </select>
      </div>
      </div>
      <!-- <div class="div">
        <div class="col-md-12">
          <div class="date-picker" (click)="stop($event)">
            <div class="date-picker hiringCal round-border d-flex" style="justify-content: end;">
              <div class="f-row d-inline-block position-relative reports-datePicker">
                <button class="f-row f-a-center button1 text-dark round hover-light py-0 date-button">
                  <i class="fa fa-calendar" (click)="drp.toggle()" [attr.aria-expanded]="drp.isOpen"></i>
                  <input [(ngModel)]="bsRangeValue" (ngModelChange)="onDateRangeChange()"
                    [bsConfig]="{ rangeInputFormat: 'MMM DD, YYYY', adaptivePosition: true, showWeekNumbers: false }"
                    bsDaterangepicker class="pl-2 border-0 bg-transparent cursor-pointer" placeholder="Daterangepicker"
                    type="text" readonly #drp="bsDaterangepicker" style="min-width: 200px !important; color: #11100e;" />
                  <i class="fa fa-caret-square-o-down" (click)="drp.toggle()" [attr.aria-expanded]="drp.isOpen"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
  <div class="card-body p-2    ">
    <div class="progress-chart-wrap">
      <div>
        <apx-chart [series]="todayProgress?.series" [chart]="todayProgress?.chart"
          [plotOptions]="todayProgress?.plotOptions" [colors]="todayProgress?.colors" [labels]="todayProgress?.labels"
          [stroke]="todayProgress?.stroke" [responsive]="todayProgress?.responsive"
          [legend]="todayProgress?.legend"></apx-chart>
      </div>
    </div>
  </div>
</div>