import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/shared/services/common.service';
import { AdhocPaymentOptionsComponent } from '../adhoc-payment-options/adhoc-payment-options.component';

@Component({
  selector: 'app-pay-invoice',
  templateUrl: './pay-invoice.component.html',
  styleUrls: ['./pay-invoice.component.scss']
})
export class PayInvoiceComponent implements OnInit {

    constructor(private commonService: CommonService,private modalService: NgbModal){
  
    }
    
    ngOnInit(): void {
        // this.payInvoice()
    }
    payInvoice() {
      const modalRef = this.modalService.open(AdhocPaymentOptionsComponent, {
        centered: true,
        size: 'lg', // Options: 'sm', 'lg', 'xl' for different sizes
        backdrop: 'static', // Prevent closing modal on backdrop click
        keyboard: false, // Disable closing modal with keyboard (ESC key)
      });
  
      modalRef.componentInstance.someInput = 'Your Data'; // Pass data to modal if needed
      modalRef.result
        .then((result) => {
          console.log('Modal closed with:', result);
        })
        .catch((reason) => {
          console.log('Modal dismissed with:', reason);
        });
    }
}
