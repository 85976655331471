import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';
import { HttpService } from 'src/app/shared/services/httpService.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  @LocalStorage()
  private userDetails;

  constructor(
    private http: HttpService,
    private https:HttpClient
  ) { }


  getUser() {
    return this.userDetails;
  }


  getProfileData(id){
    let url="api/unity/viewAccountDetail/"+id
    let body={
      companyId:'UNITYCRM0021'
    }
    return this.http.get(url,body)
  }


  changePassword(obj,id){
    let url="api/unity/update-password/"+id
    let body={
      companyId:'UNITYCRM0021',
      userID:this.getUser().userID,
      ...obj
    }
    console.log(body,"bodyyyyyyyyyyyyyyyy")
    return this.http.put(url,body)
  }
  profileUpload(formData: FormData) {
    const url = "https://manage-api.aqsatel.com/api/system/profile-image-upload";
    return this.https.post(url, formData);
  }
  updatePassword(obj,id){
    let url="api/unity/change-password"
    let body={
      companyId:'UNITYCRM0021',
      userID:id,
      ...obj
    }
    console.log(body,"bodyyyyyyyyyyyyyyyy")
    return this.http.post(url,body)
  }
}
