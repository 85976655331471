import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpService } from 'src/app/shared/services/httpService.service';

@Injectable({
  providedIn: 'root'
})
export class AdhocInvoiceService {

  constructor(private http: HttpService) { }
  private state: { showBack?: boolean; activeTab?: number } = {};
  private paymentStatusSubject = new BehaviorSubject<boolean>(false); // Initial status: false
  paymentStatus$ = this.paymentStatusSubject.asObservable();

  private adhocDataSubject = new BehaviorSubject<any>(null); // Subject to emit data
  adhocData$ = this.adhocDataSubject.asObservable(); // Observable for subscribers

  private invoiceSubject = new BehaviorSubject<any>(null);
  invoiceObservable$ = this.invoiceSubject.asObservable();
  

   // Method to emit data
   emitInvoiceData(data: any) {
    this.invoiceSubject.next(data);
  }

    /**
   * Emit data to subscribers and transform it as required
   * @param inputData - Input data in any format
   * @param requiredFields - Fields to extract
   */
    emitAdhocData(inputData: any): void {
      if (!inputData) {
        console.error('No input data provided');
        return;
      }
  
      const filteredData = this.filterRequiredFields(inputData);
      this.adhocDataSubject.next(filteredData); // Emit transformed data
    }
    /**
   * Filter required fields from input data
   * @param inputData - Input data object
   * @param requiredFields - List of required fields
   * @returns - Filtered data with only required fields
   */
  private filterRequiredFields(inputData: any): any {
    return inputData;
  }
  updatePaymentStatus(status: boolean): void {
    this.paymentStatusSubject.next(status); // Emit new status
  }
  setState(state: { showBack?: boolean; activeTab?: number; customerId?:any }): void {
    this.state = state;
  }

  getState(): { showBack?: boolean; activeTab?: number; customerId?:any  } {
    return this.state;
  }
  getMinimalProfileData(customerId: any): Promise<any> {
    const numericCustomerId = Number(customerId); 
    if (isNaN(numericCustomerId)) {
      throw new Error('Invalid customerId: must be a number');
    }
  
    const data = {
      CustomerId: numericCustomerId,
    };
    return this.http
      .get("api/Customer/getMinimalProfileData", data)
      .toPromise();
  }

  getCustomerOtherInfo(customerId:any) {
    try {
      const data={
        CustomerId:customerId
      }
      return this.http.get('api/Customer/getCustomerOtherInfo', data).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  async createInvoice(data: any): Promise<any> {
    // Map fields from `data`
    const payload = {

      customerId: data.CustomerId,
      invoiceType: data.invoiceType,
      ...(data.advancePlanId 
        ? { advancePlanId: data.advancePlanId } 
        : data.planId 
        ? { planId: data.planId } 
        : {}),
    };
    console.log('payload: ',payload);
    if ( !payload.customerId || !payload.invoiceType) {
      throw new Error('Missing required fields in the data object');
    }

    // Make POST request and return a promise
    return this.http.post('api/Customer/adhoc/invoices', payload).toPromise();
  }
  
  async doAdhocInvoicePayment(data: any): Promise<any>{
    return this.http.post('api/Customer/adhoc/invoices/payNow', data).toPromise();
  }
  getCustomerPaymentProfile(data: any) {
    try {
      let params: any = {
        profileId: data?.profileId,
        paymentProfileId: data?.paymentProfileId,
        CustomerId:data?.customerId
      };

      return this.http
        .get("api/subscription/getCustomerPaymentProfile", params, false, true)
        .toPromise();
    } catch (err) {
      throw Error(err);
    }
  }

  getCustomerAdvancePlan(customerId:any) {
    try {
      let params: any = {
        CustomerId:customerId
      };

      return this.http
        .get("api/Customer/AdvancePlan", params)
        .toPromise();
    } catch (err) {
      throw Error(err);
    }
  }
}
