import { data } from './../../excutive-dashboard/dashboard/data';
import { Component } from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexLegend,
  ApexPlotOptions,
  ApexResponsive,
  ApexTitleSubtitle,
  ApexXAxis,
  ApexYAxis,
  ApexTooltip,
  ApexStroke,
} from "ng-apexcharts";
import { TicketSystemService } from '../services/ticket-system.service';
import { CommonService } from 'src/app/shared/services/common.service';
@Component({
  selector: 'app-ticket-reporting-dash',
  templateUrl: './ticket-reporting-dash.component.html',
  styleUrls: ['./ticket-reporting-dash.component.scss']
})
export class TicketReportingDashComponent {
  public startDate: Date;
  public endDate: Date;
  bsRangeValue:any
  kpiCards:any;
  agentList:any
  completionStatusData:any
  doughnutChartOptions:any
  horizontalBarChartOptions:any
  barChartOptions:any
  statusDataChart:any;
  assigneeId:any
  monthsCate:any
  monthsData:any
  upcomingAsinee:any
  upcomingAsineeCounts:any
 // {
    //   title: 'Completed Tasks',
    //   value: 100,
    //   filterLabel: '1 Filter',
    //   buttonClass: 'btn-outline-primary',
    //   color: 'success',
    //   iconClass:'fa-solid fa-check-to-slot'
    // },
    // {
    //   title: 'Incompleted Tasks',
    //   value: 12,
    //   filterLabel: '1 Filter',
    //   buttonClass: 'btn-outline-info',
    //   color: 'warn',
    //   iconClass:'fa-solid fa-hourglass-start'

    // },
    // {
    //   title: 'Overdue Tasks',
    //   value: 4,
    //   filterLabel: '1 Filter',
    //   buttonClass: 'btn-outline-success',
    //   color: 'danger',
    //   iconClass:'fa-solid fa-triangle-exclamation'

    // },
    // {
    //   title: 'Total Tasks',
    //   value: 118,
    //   filterLabel: '1 Filter',
    //   buttonClass: 'btn-outline-primary',
    //   color: 'info',
    //   iconClass:'fa-solid fa-list-check'
    // },
constructor(    public commonService: CommonService,
    public ticketSystemService: TicketSystemService,){
  
}
ngOnInit() {
this.getKpiCard()
this.getAgentList()
}

monthsByData(){
this.barChartOptions= {
    series: [
      {
        name: "Task count",
        data: this.monthsData,
      }
    ],
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: this.monthsCate,
      labels: { style: { colors: '#252933' } }
    },
    yaxis: {
      labels: { style: { colors: '#252933' } }
    },
    tooltip: { theme: 'dark' },
    fill: {
      colors: ['#09bad5']
    },
    grid: { borderColor: 'rgba(255,255,255,0.2)' }
  };
}

  // Doughnut chart configuration


 upcomingByAisignee(){
this.horizontalBarChartOptions = {
    series: [
      {
        name: "Tasks",
        data: this.upcomingAsineeCounts,
      }
    ],
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      }
    },
    xaxis: {
      categories: this.upcomingAsinee,
      labels: { style: { colors: '#252933' } }
    },
    yaxis: {
      labels: { style: { colors: '#252933' } }
    },
    tooltip: { theme: 'dark' },
    fill: {
      colors: ['#E91E63', '#4CAF50', '#FF9800']
    },
    grid: { borderColor: 'rgba(255,255,255,0.2)' }
  };
}

  // Area chart configuration
  public areaChartOptions: any = {
    series: [
      {
        name: "Completed",
        data: [10, 20, 15, 25, 30],
      },
      {
        name: "Incomplete",
        data: [5, 10, 8, 18, 22],
      }
    ],
    chart: {
      type: "area",
      height: 350,
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May"],
      labels: { style: { colors: '#252933' } }
    },
    yaxis: {
      labels: { style: { colors: '#252933' } }
    },
    tooltip: { theme: 'dark' },
    stroke: { curve: 'smooth' },
    fill: {
      opacity: 0.5,
      colors: ['#7E57C2', '#F06292']
    },
    grid: { borderColor: 'rgba(255,255,255,0.2)' }
  };
  dataChanged() {
    if (this.bsRangeValue && this.bsRangeValue.length === 2) {
      const [start, end] = this.bsRangeValue;
      this.startDate = start;
      this.endDate = end;

      // this.updateDateRange();
      this.getKpiCard()  
    }
  }
  stop(event: Event) {
    event.stopPropagation();
  }

  formatDate(date: Date): string {
    const month = (date?.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear().toString().slice(-2);
    return `${year}-${month}-${day}`;
  }
  selectAgent(event: Event) {
    const selectedUserId = (event.target as HTMLSelectElement).value;
    this.assigneeId = selectedUserId
    this.getKpiCard()
  }
  async getAgentList() {
    
    try {
      const res: any = await this.ticketSystemService.getAgentList()
      if (res) {
       this.agentList = res?.data;

      }
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }

  }
  async getKpiCard() {
    let data: any = {
      assigneeId:this.assigneeId || ''
    };

    if (this.startDate) {
      data.startDate = this.formatDate(this.startDate);
    }
    
    if (this.endDate) {
      data.endDate = this.formatDate(this.endDate);
    }
    try {
      const res: any = await this.ticketSystemService.getReportingDashKpi(data)
      if (res) {
       this.kpiCards = res?.data?.counts;
       this.completionStatusData = res?.data?.pieChart
       this.statusDataChart = res?.data?.statusData
       this.monthsCate= res?.data?.incompleteTicketsByMonth.map(item => item.month);
      this.monthsData = res?.data?.incompleteTicketsByMonth.map(item => item.count);
      this.upcomingAsinee = res?.data?.upcomingByAssignee.map(item => item.assignee)
      this.upcomingAsineeCounts = res?.data?.upcomingByAssignee.map(item => item.count)
       console.log("kpi cards",this.kpiCards)
        // console.log(this.sourceType);
        this.setStatusDataChart()
        this.setProjectStatusDataChart()
        this.monthsByData();
        this.upcomingByAisignee()
      }
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }

  }
  setStatusDataChart() {
   this.doughnutChartOptions = {
    series: this.completionStatusData?.count,
    chart: {
      type: 'donut',
      height: 350,
    },
    labels:this.completionStatusData?.title,
    colors: ['#ff6185','#7E57C2', '#09bad5'],
    legend: {
      position: 'bottom',
      labels: { colors: '#252933' }
    },
    tooltip: { theme: 'dark' }
  };
}
setProjectStatusDataChart() {
  this.statusDataChart= {
   series: this.statusDataChart?.count,
   chart: {
     type: 'donut',
     height: 350,
   },
   labels:this.statusDataChart?.title,
   colors: [
    "#3498db",  // In Progress
    "#2ecc71",  // Open
    "#f1c40f",  // Resolved
    "#e67e22",  // On Hold
    "#e74c3c",  // Closed
    "#9b59b6",  // My Tickets
    "#34495e"   // Escalated
  ],
   legend: {
     position: 'bottom',
     labels: { colors: '#252933' }
   },
   tooltip: { theme: 'dark' }
 };
}
}
