import { Component, Input } from '@angular/core';
import { ExeDashboardService } from '../exe-dashboard.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-today-progress',
  templateUrl: './today-progress.component.html',
  styleUrls: ['./today-progress.component.scss']
})
export class TodayProgressComponent {
  showDropdown:boolean =false
  public radialChartData = [75, 50, 65, 80, 90]; // Base data used for all chart types
  public categories = ["Active", "Scheduled", "Pending", "Cancelled", "Suspended"]; // Common labels
  barchartGraphList:any = []
  barChartdata:any = []
  todayProgress:any
  bsRangeValue: Date[];
  @Input() interval: any;
  @Input() advanceSearchValues1: { fromDate: string; toDate: string };
 // Initial radial bar chart configuration
  constructor(private exedashboarservice:ExeDashboardService,private router: Router){
    this.setDates()
  }
  ngOnInit() {
  this.getSubscriptionTrands(this.advanceSearchValues1)
  this.getBarChartConfig()

  }

  onChartTypeChange(event: Event) {
    const selectedType = (event.target as HTMLSelectElement).value;
    if (selectedType === 'radialBar') {
      this.todayProgress = this.getRadialBarChartConfig();
    } else if (selectedType === 'line') {
      this.todayProgress = this.getLineChartConfig();
    } else if (selectedType === 'bar') {
      this.todayProgress = this.getBarChartConfig();
      console.log("category",this.todayProgress)
    }
  }

  // Function to get radialBar chart configuration
  getRadialBarChartConfig() {
    return {
      series: this.barChartdata, // Use the same data for radial bar
      chart: {
        height: 370,
        type: "radialBar",
        toolbar: {
          show: false
        },
        events: {
          dataPointSelection: (event: any, chartContext: any, config: any) => {
            this.onBarClick(config.dataPointIndex); 
          },
        },
      },
      plotOptions: {
        radialBar: {
          offsetX: 0,
          offsetY: 0,
          hollow: {
            size: "50%"
          },
          track: {
            strokeWidth: "50%",
            margin: 5,
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35
            }
          },
          dataLabels: {
            show: true,
            name: {
              offsetY: -10,
              show: true,
              color: "#888",
              fontSize: "17px"
            },
            value: {
              formatter: function (val: number) {
                return parseInt(val.toString(), 10).toString();
              },
              color: "#111",
              fontSize: "36px",
              show: true
            },
            total: {
              show: true,
              label: "Active",
              fontSize: "12px",
              formatter: () => "89%"
            }
          }
        }
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: ["#ABE5A1"],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100]
        }
      },
      stroke: {
        lineCap: "round"
      },
      labels: this.categories,
      colors: ['#00E396', '#FEB019', '#FF4560', '#775DD0', '#546E7A']
    };
  }

  // Function to get line chart configuration using radial chart data
  getLineChartConfig() {
    return {
      series: [{
        name: 'Progress',
        data:this.barChartdata // Same radial data used for line chart
      }],
      chart: {
        height: 300,
        type: 'line',
        toolbar: {
          show: false
        },
        events: {
          dataPointSelection: (event: any, chartContext: any, config: any) => {
            const index = config.dataPointIndex; // Get the index of the clicked data point
            this.onBarClick(index); // Call your click handler
          },
        },
        zoom: {
          enabled: false
        }
      },
      stroke: {
        width: 2,
        curve: 'straight'
      },
      xaxis: {
        // labels: this.categories 
      },
      colors: ['#00E396'] ,// Set line color
      labels: ["Active", "Scheduled", "Pending", "Cancelled", "Suspended",'Expired'] 
    };
    
  }

  // Function to get bar chart configuration using radial chart data
  getBarChartConfig() {
    return {
      series: [{
        data: this.barChartdata // Same radial data used for bar chart
      }],
      chart: {
        height: 250,
        type: 'bar',
        toolbar: {
          show: false
        },
        events: {
          dataPointSelection: (event: any, chartContext: any, config: any) => {
            this.onBarClick(config.dataPointIndex); 
          },
        },
      },
      plotOptions: {
        bar: {
          columnWidth: '30%',
          dataLabels: {
            enabled: true, // Enable data labels for the bar
            formatter: (value) => {
              // Assuming value is the raw data value; you can calculate the percentage based on your total
              const total = this.radialChartData.reduce((sum, data) => sum + data, 0);
              const percentage = ((value / total) * 100).toFixed(2); // Calculate percentage
              return `${percentage}%`; // Format the label to show percentage
            },
          },
        },
      },
      dataLabels: {
        enabled: true, // Enable data labels globally
      },
      xaxis: {
        labels: {
          style: {
            fontSize: '12px'
          },
        },
      },
      colors: ['#7064F5','#00E396', '#FEB019', '#FEB019', '#775DD0', '#546E7A'],
      grid: {
        show: false, // Ensure grid lines are hidden
      },
      legend: {
        show: false 
      },
      labels: ["Active", "Scheduled", "Pending", "Cancelled", "Suspended",'Expired'],
    };

  }
  
  ngOnChanges() {
    if (this.advanceSearchValues1) {
    this.getSubscriptionTrands(this.advanceSearchValues1)
    }
  }
  getSubscriptionTrands(date) {
    const data = {
      "startDate": date?.fromDate,
      "endDate": date?.toDate,
      interval:this.interval
    };
    this.exedashboarservice.getSubcriptionTrends(data).subscribe((res: any) => {
      this.barchartGraphList = res?.data;
     this.barChartdata = [
        this.barchartGraphList.Active ,
        this.barchartGraphList.Scheduled,
        this.barchartGraphList.Pending,
        this.barchartGraphList.Cancelled,
        this.barchartGraphList.Suspended,
        this.barchartGraphList.Expired
      ];
      this.todayProgress = this.getBarChartConfig();
      console.log(this.barChartdata);
  
    });
  }
  onBarClick(dataPointIndex: number) {
    this.router.navigateByUrl("/subscriptions")
  }
  // onDateRangeChange() {
  //   console.log('evevnt',this.bsRangeValue)
  //   this.getSubscriptionTrands()
  // }
  stop(event:Event){
    event.stopPropagation();
  }
  setDates() {
    const today = new Date();
    const fifteenDaysAgo = new Date();
    fifteenDaysAgo.setDate(fifteenDaysAgo.getDate() - 15); 
    this.bsRangeValue = [fifteenDaysAgo, today];
  }
}
