import { Injectable } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/shared/services/httpService.service';

@Injectable({
  providedIn: 'root'
})

export class ManagePermissionService {
  userId: string;
  companyId:string;

  @LocalStorage()
  private userDetails;


  constructor(
    private http: HttpService,
  ) {}


getUser() {
    return this.userDetails;
}

  getAllPermissions(id:any,isIgnoreGrp?){
    let obj :any ={
        companyId:"UNITYCRM0021",
        userID:id
    }
    if(isIgnoreGrp){
      obj.isIgnoreGrp =isIgnoreGrp
    }
    let url="api/system/resource-permission-detail"
    return this.http.get(url,obj)
  }


  updatePermission(id:any,body:any){
  let url=`api/system/resource-permissions/update/${id}`
  return this.http.post(url,body,true)

  }
  createPermission(body:any){
    let url=`api/system/resource-permissions/create`
    return this.http.post(url,body)
  
    }
}
