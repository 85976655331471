import { Component, Input } from '@angular/core';
import { DynamicInvoiceTemplateService } from './dynamic-invoice-template.service';
import { CommonService } from '../../services/common.service';
import { fadeInOut } from '../../services/app.animation';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dynamic-invoice-template',
  templateUrl: './dynamic-invoice-template.component.html',
  styleUrls: ['./dynamic-invoice-template.component.scss'],
  animations: [fadeInOut]
})
export class DynamicInvoiceTemplateComponent {
  @Input() CustomerId: any
  invoiceList: any = []
  dropdownVisible: any;
  invoiceData: any;
  showInvoice: any;
  constructor(private dynamicInvoiceTemplateService: DynamicInvoiceTemplateService, private modalService: NgbModal, private commonService: CommonService) {

  }
  visibleRows: any[] = [];
  isExpanded: boolean = false;
  maxRowsToShow: number = 5;
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.getInvoiceList();
  }

  getInvoiceList(): void {
    try {
      const data = {
        CustomerId: this.CustomerId,
      };

      this.dynamicInvoiceTemplateService.getInvoiceList(data)
        .then((res: any) => {
          if (res.Status) {
            this.invoiceList = res.data || [];
            this.updateVisibleRows(); // Update visible rows after fetching data
          } else {
            this.invoiceList = []; // Ensure the list is empty if no data
            this.updateVisibleRows(); // Clear visible rows
          }
        })
        .catch((err) => {
          console.error('Error fetching invoice list:', err);
          this.invoiceList = [];
          this.updateVisibleRows();
        });
    } catch (error) {
      console.error('Exception during fetching invoices:', error);
      this.invoiceList = [];
      this.updateVisibleRows();
    }
  }

  updateVisibleRows(): void {
    if (this.invoiceList.length === 0) {
      this.visibleRows = []; // No records available
    } else {
      this.visibleRows = this.isExpanded
        ? this.invoiceList // Show all rows if expanded
        : this.invoiceList.slice(0, this.maxRowsToShow); // Show only the first 5 rows
    }
  }

  toggleView(): void {
    this.isExpanded = !this.isExpanded; // Toggle the state
    this.updateVisibleRows(); // Update the rows based on the state
  }

  viewInvoice(item) {
    try {
      const data ={
        source:item?.Source
      }
      this.dynamicInvoiceTemplateService.viewInvoice(item.id,data).then((res: any) => {
        if (res.Status) {
          this.invoiceData = res?.data
          this.showInvoice = true
          console.log('invoice data: ', this.invoiceData);
        }
      }).catch(err => {
        console.log(err)
        this.commonService.showToast('error', 'Error', err?.message);
      })
    } catch (error) {
      this.commonService.showToast('error', 'Error', error?.message);
    }
  }

  sendInvoice(item) {
    try {
      const data ={
        source:item?.Source
      }
      this.dynamicInvoiceTemplateService.sendInvoice(item.id,data).then((res: any) => {
        if (res.Status) {
          this.commonService.showToast('success', 'success', res?.message);
        }
      }).catch(err => {
        this.commonService.showToast('error', 'Error', err?.message);
      })
    } catch (error) {
      this.commonService.showToast('error', 'Error', error?.message);
    }
  }
  // updateVisibleRows(): void {
  //   if (this.isExpanded) {
  //     this.visibleRows = this.invoiceList; // Show all rows
  //   } else {
  //     this.visibleRows = this.invoiceList.slice(0, this.maxRowsToShow); // Show only the first 5 rows
  //   }
  // }

  // toggleView(): void {
  //   this.isExpanded = !this.isExpanded;
  //   this.updateVisibleRows();
  // }

  async toggleDropdown(index: number) {
    if (this.dropdownVisible.has(index)) {
      this.dropdownVisible.delete(index); // Hide if already visible
    } else {
      this.dropdownVisible.clear();
      this.dropdownVisible.add(index); // Show if not visible
    }
  }

  goBack() {
this.showInvoice = false
  }
}
