import { DatePipe } from "@angular/common";
import { Component, ElementRef, HostListener, Input, ViewChild } from "@angular/core";
import { ColDef, RowModelType } from "ag-grid-community";
import { CommonService } from "src/app/shared/services/common.service";
import { SearchFilter } from "../interfaces/subscription";
import { SubscriptionService } from "../service/subscription.service";
import { fadeInOut } from "src/app/shared/services/app.animation";
import { HttpService } from "src/app/shared/services/httpService.service";
import { CustomerProfileService } from "src/app/shared/services/customer-profile.service";
import { Router } from "@angular/router";
import { ActivatedRoute } from '@angular/router';
declare var require;
const Swal = require("sweetalert2");
@Component({
  selector: "app-subscriptions-list",
  templateUrl: "./subscriptions-list.component.html",
  styleUrls: ["./subscriptions-list.component.scss"],
  animations: [fadeInOut],
})
export class SubscriptionsListComponent {
  globalSearchResult: any = null;
  isGlobalLoader: boolean = false;
  subscriptionList: any;
  dropdownVisible = new Set<number>();
  dropdownVisible1 = new Set<number>();
  pageDropdownMenu: any = [10, 20, 30];
  itemsPerPage: number = 50;
  itemsPerPage1: number = 3;
  totalItems: number;
  currentPage: number = 1;
  isCsv: boolean = false;
  totalPages: number = 1;
  total:any
  currentlyOpenDropdown: HTMLDivElement | null = null;
  selectedFilter: string = "All";
  searchQuery: any;
  isloadLiveSubscription: boolean = false;
  isSubscription: boolean = false;
  loadLiveSubscriptionList: any;
  isDisplayTrasection: boolean = false;
  nosubscriptionList: any = [];
  IsExport: any;
  fileUrl: any;
  maxPaginationSize: number = 7;
  customerDetails:any=[]
  @Input() interval: any;
  @ViewChild("searchInput") searchInput!: ElementRef;

  searchFilter: any;
  selectedSearchFilter: SearchFilter | null = null;
  selectedTypeFilter: any;
  totalDataCount:any;
  headers: any[] = [
    { key: "SubscriptionId", display: "Subscription ID" },
    { key: "CustomerId", display: "Customer", width: 150 },
    { key: "plan.Name", display: "Plan Name" },
    { key: "plan.Price", display: "Price" },
    { key: "StartDate", display: "Start Date" },
    { key: "SubscriptionType", display: "Subscription Type" },
    { key: "Status", display: "Status" },
    { key: "action", display: "Action" },
  ];

  subscriptionTypeFilter = [
    { DisplayName: "All", value: "All" },
    { DisplayName: "Scheduled", value: "Active" },
    { DisplayName: 'Pending', value: 'Pending' },
    { DisplayName: "Cancelled", value: "Cancelled" },
    { DisplayName: "Load Live Subscriptions", value: "LoadLive" },
    { DisplayName: "No Subscriptions", value: "Plan" },
  ];
  isCurrentNoSubscriptions: boolean;
  isSkeletonLoader: boolean = true;
  defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    cellClass: "cell-wrap-text",
    // autoHeight: true,
    flex: 1,
    minWidth: 150,
    maxWidth: 500,
  };
  rowHeight: number = 55;
  public liveLoadcolumnDefs: ColDef[] = [
    {
      headerName: "Id",
      field: "id",
      width: 180,
    },
    { headerName: "First Name", field: "firstName", width: 150 },
    { headerName: "Last Name", field: "lastName", width: 150 },
    { headerName: "Plan name", field: "name", width: 230 },
    {
      headerName: "Price",
      field: "amount",
      width: 100,
    },

    {
      headerName: "Currency Code",
      field: "currencyCode",
      width: 120,
    },
    {
      headerName: "Created Time",
      field: "createTimeStampUTC",
      cellRenderer: this.dateCellRenderer.bind(this),
    },
    { headerName: "Payment Method", field: "paymentMethod" },
    {
      headerName: "Status",
      field: "status",
      cellRenderer: this.statusCellRenderer,
    },
  ];

  public columnDefs2: ColDef[] = [
    {
      headerName: "Customer Id",
      field: "CustomerId",
      width: 180,
    },
    { headerName: "First Name", field: "FirstName", width: 150 },
    { headerName: "Last Name", field: "LastName", width: 150 },
    { headerName: "Email", field: "Email", width: 230 },
    {
      headerName: "Plan Id ",
      field: "Plan",
      width: 250,
    },
    { headerName: "Order Type", field: "OrderType", width: 230 },
    { headerName: "Activation Code", field: "ActivationCode", width: 230 },
    { headerName: "Billing Period", field: "BillingPeriod", width: 230 },
    { headerName: "MDN", field: "MDN", width: 230 },
    {
      headerName: "Customer Status",
      field: "CustomerStatus",
      width: 230,
      cellRenderer: this.statusRenderer,
    },
    {
      headerName: "Created Date",
      field: "CreatedDate",
      width: 120,
      cellRenderer: this.dateCellRenderer.bind(this),
    },
  ];
  statusRenderer(params: any): string {
    const status = params.value;
    let badgeClass = "";

    if (status === "Deactivated") {
      badgeClass = "badge badge-light-danger";
    } else if (status === "Active") {
      badgeClass = "badge badge-light-success";
    } else {
      badgeClass = ""; // Default badge class for unknown status
    }

    return `<span class="${badgeClass}">${status}</span>`;
  }
  public gridRowClass = "my-green-class";

  constructor(
    private subscriptionService: SubscriptionService,
    private datePipe: DatePipe,
    private commonService: CommonService,
    private http: HttpService,
    private customerProfileService: CustomerProfileService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  async ngOnInit() {
    this.fetchTotalsForAllFilters();

    this.route.queryParams.subscribe(async (params) => {
      const subscriptionId = params['subscriptionId']; // Retrieve subscriptionId from queryParams
      this.isGlobalLoader = params['isGlobalLoader'] === 'true'; // Check for globalLoader flag
  
      console.log('subscriptionId: ',subscriptionId);
      if (!this.isGlobalLoader) {
        // Fetch subscriptions list with the subscriptionId if available
        await this.getSubscriptionsList(subscriptionId);
      }
    });
    // this.checkGlobalLoader();

    this.appplyChartDateFilter();
    this.loadSearchFilters();
    this.adjustPaginationSize(); 
  }

    // Adjust maxSize based on screen size
    adjustPaginationSize() {
      if (window.innerWidth <= 480) {
        this.maxPaginationSize = 1; // Minimal pages on small screens
      } else {
        this.maxPaginationSize = 7; // Default for larger screens
      }
    }
  
    // Listen for window resize events
    @HostListener('window:resize', [])
    onResize() {
      this.adjustPaginationSize();
    }

  getItemValue(item: any, key: string): any {
    if (key.includes('.')) {
      const keys = key.split('.');
      let value = item;
      for (let k of keys) {
        value = value ? value[k] : null;
      }
      return value;
    }
    return item[key];
  }

  
  

  ngOnChanges() {
    this.fetchTotalsForAllFilters();
    this.getSubscriptionsList();
  }
  downloadcsv(item) {
    this.IsExport = item;
    this.isCsv = true;
    this.getSubscriptionsList();
  }
  async getSubscriptionsList(Status?: any, search?: any, date?: any) {
    this.subscriptionList;
    this.isloadLiveSubscription = false;
    this.isSubscription = false;
    let obj: any = {
      per_page: this.itemsPerPage,
      page: this.currentPage,
      interval: this.interval,
      IsExport: this.IsExport || "",
    };
  

    if (Status !== undefined && Status !== null) {
      obj.status = Status;
    }
    if (
      this.selectedFilter !== "All" ||
      (search !== undefined && search !== null)
    ) {
      obj.searchTerm = search ?? this.selectedFilter;
    
    }
    if (
      date !== undefined &&
      date.startDate !== null &&
      date.endDate !== null
    ) {
      obj.startDate = date.startDate;
      obj.endDate = date.endDate;
      obj.interval = this.interval;
    }
 
    if (this.selectedFilter == "All") {
      let obj = {
        per_page: this.itemsPerPage,
        page: this.currentPage,
        interval: this.interval,
        IsExport: this.IsExport || "",
      };
      if (search !== "All" && !isNaN(search)) {
        obj["searchTerm"] = search;
      }
      
      obj = await this.ensureInterval(obj);
      await this.subscriptionService
        .getSubscriptionList(obj)
        .subscribe((res: any) => {
          if (res.Status) {
            if (res?.data?.data) {
              this.subscriptionList = res?.data?.data;
              this.total  = res?.data?.total
            }

            if (this.isGlobalLoader && this.subscriptionList.length > 0) {
              const subscriptionId = Number(this.route.snapshot.queryParams['subscriptionId']); // Convert to number
              console.log('subscriptionId: ', subscriptionId);
              this.globalSearchResult = res?.data?.data.find(subscription => subscription?.SubscriptionId === subscriptionId);
              console.log('results: ', res?.data?.data);
              if (this.globalSearchResult) {
                this.detail(this.globalSearchResult);
                console.log('globalSearchResult: ', this.globalSearchResult);
              } else {
                console.warn('No subscription found with the given subscriptionId.');
                this.commonService.showToast('error','Error','No subscription found with the given subscriptionId')
              }
            }

            this.fileUrl = res.data;
            if (this.fileUrl && this.IsExport == 1 && this.isCsv) {
              const anchor = document.createElement("a");
              anchor.href = this.fileUrl;
              anchor.target = "_blank";
              anchor.download = "";
              anchor.click();
              anchor.remove();
            }
            if (res?.data?.data?.length === 0) {
              // this.commonService.showToast(
              //   "success",
              //   "sucess",
              //   "No Record Found"
              // );
            }
          }
          this.isSkeletonLoader = false;
        });
    } else if (this.selectedFilter == "Active") {
     
      let obj1 = {
        per_page: this.itemsPerPage,
        page: this.currentPage,
        searchTerm: search ?? this.selectedFilter,
        IsExport: this.IsExport || "",
        interval: this.interval,
      };
      await this.subscriptionService
        .getSubscriptionList(obj1)
        .subscribe((res: any) => {
          if (res.Status) {
            if (res?.data?.data) {
              this.subscriptionList = res?.data?.data;
            }

            this.fileUrl = res.data;
            if (this.fileUrl && this.IsExport == 1 && this.isCsv) {
              const anchor = document.createElement("a");
              anchor.href = this.fileUrl;
              anchor.target = "_blank";
              anchor.download = "";
              anchor.click();
              anchor.remove();
            }
            this.totalItems = res?.data?.total;
            if (res?.data?.data?.length === 0) {
              // this.commonService.showToast(
              //   "success",
              //   "sucess",
              //   "No Record Found"
              // );
            }
          }
        });
    } else {
      await this.subscriptionService
        .getSubscriptionList(obj)
        .subscribe((res: any) => {
          if (res.Status) {
            if (res?.data?.data) {
              this.subscriptionList = res?.data?.data;
            }
            this.totalItems = res?.data?.total;
            this.fileUrl = res.data;
            if (this.fileUrl && this.IsExport == 1 && this.isCsv) {
              const anchor = document.createElement("a");
              anchor.href = this.fileUrl;
              anchor.target = "_blank";
              anchor.download = "";
              anchor.click();
              anchor.remove();
            }
            if (res?.data?.data?.length === 0) {
              // this.commonService.showToast(
              //   "success",
              //   "sucess",
              //   "No Record Found"
              // );
            }
          }
        });
    }
  }

  pageChange(event: any) {
    this.isCurrentNoSubscriptions = false;
    this.currentPage = event;
    if (this.isSubscription) {
      this.noSubscriptions("Plan");
    } else {
      this.getSubscriptionsList();
    }
  }

  onChangeNumberOfPage() {
    this.getSubscriptionsList();
  }

  showManageUser: boolean = false;
  editData: any;
  detail(item) {
    console.log('item: ',item);
    this.router.navigate([`/subscriptions/subscription-details`],{state:{item:item}})
    this.editData = item;
  }

  withConfirmation(item: any) {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to cancel this subscription?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, cancel it!",
      customClass: {
        popup: "swal2-custom-width",
      },
    }).then((result) => {
      if (result.isConfirmed && result.value) {
        this.cancelsubscription(item);
      }
    });
  }

  cancelsubscription(data: any) {
    try {
      this.subscriptionService
        .cancelSubscription(data?.data?.SubscriptionId)
        .subscribe(
          (res: any) => {
            if (res?.Status) {
              this.commonService.showToast("success", "sucess", res?.data);
              this.getSubscriptionsList();
            }
          },
          (error: any) => {
            this.commonService.showToast(
              "error",
              "Error",
              "An error occurred while cancelling the subscription."
            );
            console.error("Error:", error);
          }
        );
    } catch (err) {
      this.commonService.showToast(
        "error",
        "Error",
        "An unexpected error occurred."
      );
      console.error("Unexpected Error:", err);
    }
  }

  async selectFilter(filter: any) {
  // Check if total for the filter is 0
  if (filter !== 'Plan') {
    const totalForFilter = await this.getTotalForFilter(filter);
    if (totalForFilter === 0) {
      const displayName = filter === 'Active' ? 'Scheduled' : filter; // Handle "Active" as "Scheduled"
      this.commonService.showToast(
        'info',
        'Subscription Record Information',
        `No data available for the filter: ${displayName}`
      );
      return; // Return early if no data
    }
  }
    this.selectedFilter = filter;
    this.currentPage = 1;

    this.isloadLiveSubscription = false;
    this.isSubscription = false;
    this.IsExport = "";
    if (this.selectedFilter == "All") {
      this.isCsv = false;
      // this.IsExport = ''
      this.getSubscriptionsList();
    } else if (this.selectedFilter === "LoadLive") {
      setTimeout(() => {
        const loadLiveButton = document.querySelector('.loadLive');
        if (loadLiveButton) {
          loadLiveButton.classList.add('load-live-active');
        }
      }, 0);
      this.isloadLiveSubscription = true;
      this.isSubscription = false;
      this.loadLiveSubscriptions();
    } else if (this.selectedFilter == "Plan") {
      this.isSubscription = true;
      this.isloadLiveSubscription = false;
      this.currentPage = 1;
      this.noSubscriptions(filter);
    } else {
      this.getSubscriptionsList(filter.value);
    }
    this.updateTotalRecords();
  }
  updateTotalRecords() {
    switch (this.selectedFilter) {
      case "All":
        this.total = this.getTotalForFilter("All");
        break;
      case "Active":
        this.total = this.getTotalForFilter("Active");
        break;
      case "Pending":
        this.total = this.getTotalForFilter("Pending");
        break;
      case "Cancelled":
        this.total = this.getTotalForFilter("Cancelled");
        break;
      case "Plan":
        this.total = this.getTotalForFilter("Plan");
        break;
      case "LoadLive":
        this.total = this.getTotalForFilter("LoadLive");
        break;
      default:
        this.total = 0;
    }
  }
  searchSubscrition(search: any) {
    search = search.toLowerCase().trim();
    if (this.selectedFilter !== "LoadLive") {
      this.getSubscriptionsList("", search);
    } else {
      this.loadLiveSubscriptions(search);
    }
  }

  filterData(event: any) {
    let searchValue = event.target.value;
    searchValue = searchValue.toLowerCase().trim();
    if (this.selectedFilter !== "LoadLive" && searchValue === "") {
      this.getSubscriptionsList();
    } else if (this.selectedFilter === "LoadLive" && searchValue === "") {
      this.loadLiveSubscriptions();
    } else if (this.selectedFilter == "noSubscription" && searchValue === "") {
      this.noSubscriptions("Plan");
    }
  }

  isNoSubscription() {
    this.isCurrentNoSubscriptions = true;
  }

  noSubscriptions(search: any) {
    if (this.isCurrentNoSubscriptions) {
      this.currentPage = 1;
    }
    this.selectedFilter = "noSubscription";
    this.isSubscription = true;
    this.isloadLiveSubscription = false;

    let obj: any = {
      per_page: this.itemsPerPage,
      page: this.currentPage,
      interval: this.interval,
    };

    if (search !== undefined && search !== null) {
      obj.searchType = search;
    }
    obj = this.ensureInterval(obj);
    this.subscriptionService.noSubscriptionData(obj).subscribe(
      (res: any) => {
        if (res.Status) {
          this.totalItems = res?.data?.total;
          this.nosubscriptionList = res?.data?.data;
        } else {
          // Handle the case where the response status is not successful
          console.error("Failed to load subscriptions", res);
        }
      },
      (error: any) => {
        // Handle any errors that occur during the API call
        console.error("Error loading live subscriptions", error);
      }
    );
  }

  loadLiveSubscriptions(search?: any) {
    this.selectedFilter = "LoadLive";
    this.isloadLiveSubscription = true;
    this.isSubscription = false;

    let obj: any = {
      limit: 1000,
      offset: 1,
      interval: this.interval,
    };

    if (search !== undefined && search !== null) {
      obj.searchType = search;
    }
    obj = this.ensureInterval(obj);
    this.subscriptionService.loadLiveSubscription(obj).subscribe(
      (res: any) => {
        if (res.Status) {
          this.loadLiveSubscriptionList = res?.data?.subscriptions;
        } else {
          // Handle the case where the response status is not successful
          console.error("Failed to load subscriptions", res);
        }
      },
      (error: any) => {
        // Handle any errors that occur during the API call
        console.error("Error loading live subscriptions", error);
      }
    );
  }

  async DisplayTrasectionDetail() {
    this.isDisplayTrasection = false;
    setTimeout(() => {
      this.isDisplayTrasection = true;
    }, 40);
  }

  appplyChartDateFilter() {
    this.subscriptionService
      .getSubscriptionChartDateSub()
      .subscribe((date: any) => {
        if (date) {
          this.getSubscriptionsList(null, null, date);
        }
      });

    this.subscriptionService.getTransectionDetail().subscribe((data: any) => {
      if (data) {
        this.DisplayTrasectionDetail();
      }
    });
  }

  openSubscriptionDetailPage(params) {
    const element = document.createElement("span");
    element.innerHTML = params.value;
    element.style.color = "#98d292";
    element.style.cursor = "pointer";
    element.addEventListener("click", () => this.detail(params));

    return element;
  }

  async toggleDropdown(index: number) {
    if (this.dropdownVisible.has(index)) {
      this.dropdownVisible.delete(index); // Hide if already visible
    } else {
      this.dropdownVisible.clear();
      this.dropdownVisible.add(index); // Show if not visible
    }
  }

  dateCellRenderer(params) {
    return params.value ? new Date(params.value).toLocaleDateString() : "-";
  }

  statusCellRenderer(params: any): string {
    const statusBadgeClasses = {
      active: "badge badge-light-info",
      cancelled: "badge badge-light-danger",
      pending: 'badge badge-light-warning',
      scheduled: "badge badge-light-secondary",
    };

    const status = params.value; // assuming 'value' contains the status

    return statusBadgeClasses[status] || ""; // return badge class or empty if not found
  }

  loadSearchFilters() {
    this.searchFilter = [
      {
        DisplayName: "Expiring This Month",
        Value: "subscriptionExpiringThisMonth",
      },
      { DisplayName: "Active", Value: "subscriptionActive" },
      {
        DisplayName: "Expiring Next Month",
        Value: "subscriptionExpiringNextMonth",
      },
      { DisplayName: "On Hold", Value: "subscriptionOnHold" },
      { DisplayName: "Past Due", Value: "subscriptionPastDue" },
    ];
  }

  filterReportMapping() {
    if (this.selectedSearchFilter) {
      this.loadLiveSubscriptions(this.selectedSearchFilter.Value);
    }
  }

  isSearchOpen: boolean = false;

  toggleSearch(): void {
    if (!this.isSearchOpen) {
      this.isSearchOpen = true;
    } else {
      if (this.searchQuery?.length > 0) {
        if (this.selectedFilter == "LoadLive") {

          this.isloadLiveSubscription = true;
          this.loadLiveSubscriptions(this.searchQuery);
        } else {
          this.getSubscriptionsList(undefined, this.searchQuery);
        }
      } else {
        this.isSearchOpen = false;
      }
    }
  }
  onFocusOut(): void {
    this.isSearchOpen = false;
  }

  onSearchKeyPress(event: any): void {
    if (event.key === "Enter") {
      if (this.selectedFilter === "LoadLive") {
        this.isloadLiveSubscription = true;
        this.loadLiveSubscriptions(event.target.value);
      } else {
        this.getSubscriptionsList(undefined, event.target.value);
        this.isloadLiveSubscription = false;
      }
    }
  }
  async toggleDropdown1(index: number, item) {
    const data = {
      CustomerId: item.CustomerId
    }

    const res: any = await this.http.get('api/Customer/getCustomerPersonalInfo', data, false).toPromise();
    if (res.Status) {
      this.customerDetails = res.data
    }
    if (this.dropdownVisible1.has(index)) {
      this.dropdownVisible1.delete(index); 
    } else {
      this.dropdownVisible1.clear();
      this.dropdownVisible1.add(index); 
    }
  }
  closeDropdown(index: number, dropdown: string): void {
    if (this[dropdown]?.has(index)) {
      this[dropdown].delete(index); // Hide the dropdown
    }
  }
  
  async sendToCustomerDashboard(CustomerID) {
    await this.customerProfileService.setCustomerId(CustomerID)
    this.customerProfileService.getMinimalProfileData()
    this.router.navigate(['/customer-dashboard/customer-profile'], { queryParams: { showBack: true } });
  }

  async fetchTotalsForAllFilters() {
    const filters = ["All", "Active", "Pending", "Cancelled", "LoadLive", "Plan"];
    const totals: any[] = [];
  
    const apiCalls = filters.map((filter) => {
      let obj: any;
  
      switch (filter) {
        case "All":
          obj = {
            per_page: this.itemsPerPage,
            page: this.currentPage,
          };
          break;
  
        case "Active":
          obj = {
            per_page: this.itemsPerPage,
            page: this.currentPage,
            searchTerm: "Active",
          };
          break;
  
        case "Pending":
          obj = {
            per_page: this.itemsPerPage,
            page: this.currentPage,
            searchTerm: "Pending",
          };
          break;
  
        case "Cancelled":
          obj = {
            per_page: this.itemsPerPage,
            page: this.currentPage,
            searchTerm: "Cancelled",
          };
          break;
  
        case "LoadLive":
          obj = {
            limit: 1000,
            offset: 1,
          };
          obj = this.ensureInterval(obj); // Ensure interval is included
          return this.subscriptionService.loadLiveSubscription(obj).toPromise();
  
        case "Plan":
          obj = {
            per_page: this.itemsPerPage,
            page: this.currentPage,
          };
          obj = this.ensureInterval(obj); // Ensure interval is included
          return this.subscriptionService.noSubscriptionData(obj).toPromise();
  
        default:
          obj = {
            per_page: this.itemsPerPage,
            page: this.currentPage,
            searchTerm: filter,
          };
      }
  
      obj = this.ensureInterval(obj); // Ensure interval is included for all payloads
      return this.subscriptionService.getSubscriptionList(obj).toPromise();
    });
  
    try {
      const results: any = await Promise.allSettled(apiCalls);
  
      results.forEach((result, index) => {
        if (result.value?.Status) {
          totals.push({
            filter: filters[index],
            total: result.value?.data?.total || 0,
          });
        } else {
          totals.push({
            filter: filters[index],
            total: 0,
          });
        }
      });
  
      console.log("Totals for all filters:", totals);
      this.totalDataCount = totals;
  
      return totals;
    } catch (error) {
      console.error("Unexpected error:", error);
      return [];
    }
  }
  

  

  get getTotalForFilter() {
    return (filter: string): number => {
      const filterData = this.totalDataCount?.find((item) => item.filter === filter);
      return filterData ? filterData.total : 0;
    };
  }
  
  private ensureInterval(payload: any): any {
    if (!payload.hasOwnProperty('interval') || payload.interval === undefined) {
      payload.interval = this.interval; // Default to the component's `interval`
    }
    return payload;
  }
  
  
}
