import { Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';

import { BillingAndInvoiceService } from './services/billing-and-invoice.service';
import { HttpService } from 'src/app/shared/services/httpService.service';

@Component({
  selector: 'app-billing-and-invoice',
  templateUrl: './billing-and-invoice.component.html',
  styleUrls: ['./billing-and-invoice.component.scss']
})
export class BillingAndInvoiceComponent implements OnInit,OnChanges {

  public active1 = 1;
  public active2 = 1;
  SETTINGSItems:any
  isHiddenData:any
  constructor(public billingservice: BillingAndInvoiceService,private httpService:HttpService){
  
  }

  currentCustomerId:any;


  ngOnChanges(changes: SimpleChanges): void {
    const customerId= this.httpService.getCustomerId();
    this.currentCustomerId =customerId;
    console.log('cutsomer id ngOnChanges: ', this.currentCustomerId)
  }
 async ngOnInit() {
  //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.billingservice.sideMenuInitilize();
    this.billingservice.getSideMenuBar().subscribe((SETTINGSItems: any) => {
      this.isHiddenData = SETTINGSItems
          console.log("Billing invoices index 5:", this.isHiddenData);
      // this.cd.detectChanges();
        this.SETTINGSItems = SETTINGSItems.filter(item => !item.isHidden);
          // console.log("Item at index 6:", this.SETTINGSItems);
          // this.cd.detectChanges();// Trigger change detection after the assignment
    });
  const customerId= this.httpService.getCustomerId();
  this.currentCustomerId =customerId;
  console.log('cutsomer id BillingAndInvoiceComponent: ', this.currentCustomerId)
  }
  showHisotoryPage: boolean = false;
  showHistoryDetail: any;
  openHistoryPage(data: any) {
    this.showHisotoryPage = false;
    this.showHistoryDetail = data;
    setTimeout(() => {
      this.showHisotoryPage = true;
    }, 40);
  }
}
