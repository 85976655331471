import { Injectable, Input } from '@angular/core';
import { HttpService } from '../../services/httpService.service';

@Injectable({
  providedIn: 'root'
})
export class DynamicInvoiceTemplateService {
  @Input() customerDetails :any
  constructor(private http :HttpService) { }

  getInvoiceList(data) {
    try {
      let url = "api/transactions/getInvoicesByCustomer";
      return this.http.get(url, data, false, false).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  viewInvoice(id,data) {
    try {
      let url = `api/transactions/viewInvoice/${id}`;
      return this.http.get(url, data, false, false).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }

  sendInvoice(id,data) {
    try {
      let url = `api/transactions/sendInvoice/${id}`;
      return this.http.get(url, data, false, false).toPromise()
    } catch (err) {
      throw Error(err)
    }
  }
}
