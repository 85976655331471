<div class="translate_wrapper" [class.active]="navServices.changeCompany" *ngIf="displayDropdowns">
  <div class="current_lang">
    <div class="lang d-flex align-items-center gap-1" (click)="changeCompany(selectedCompany)">
      <img width="20" height="20" class="rounded" [src]="selectedCompany.imgIcon">
      <span class="lang-txt" matTooltip=" Switch to {{selectedCompany.name}}" matTooltipPosition="below"
       >{{selectedCompany.name}}</span>

    </div>
  </div>
  <!-- <div class="more_lang" [class.active]="navServices.changeCompany">
    <div class="lang selected" data-value="en" style="width: 150px;" (click)="changeCompany(change)"
      *ngFor="let change of Company">
      <img width="20" height="20" class="rounded" [src]="change.imgIcon">
      <span class="lang-txt text-capitalize">{{change.name}}</span>
    </div>
  </div> -->
</div>