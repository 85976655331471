import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CommonService } from './common.service';
import Swal from 'sweetalert2';
import { RequestStateService } from './request-state.service';

@Injectable({
  providedIn: 'root'
})
export class PostRequestBlockerInterceptorService implements HttpInterceptor {
  private postRequestInProgress = false;

  // Object to store exempt API names
  private exemptApis: Record<string, boolean> = {
    '/api/orders/globalSearchLog': true,
    '/api/dashboard/executiveDashboard': true, // Ensure keys include the leading '/'
    '/api/system/resource-permissions/create':true,
    '/api/system/resource-permissions/update':true,
  };

  constructor(
    private requestStateService: RequestStateService,
    private commonService: CommonService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.method === 'POST') {
      // Extract the full path of the API
      const apiUrl = new URL(req.url, window.location.origin).pathname;
      console.log('Intercepted API URL:', apiUrl);

      // Check if the API matches an exempt path or allows additional segments
      const isExempt = Object.keys(this.exemptApis).some(exemptPath => {
          if (this.exemptApis[exemptPath]) {
          // Check for exact match or any additional segments after the base path
          return apiUrl === exemptPath || apiUrl.startsWith(`${exemptPath}/`);
          }
          return false;
      });
      // Check if the API is exempt from blocking
      if (!isExempt) {
        if (this.postRequestInProgress) {
          console.warn(
            'A POST request is already in progress. Blocking new POST request.'
          );
          Swal.fire({
            icon: 'info',
            title: '<span style="color: white;font-size: 1.5em;">Info</span>',
            html: '<span style="color: white;font-size: 1.5em;">Your request is already in progress. Please wait.</span>',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            background: 'rgba(0, 0, 0, 0)',
            customClass: {
              popup: 'light-gradient-swal',
              title: 'swal-white-text',
              htmlContainer: 'swal-white-text',
              confirmButton: 'swal-white-button',
            },
            backdrop: `rgba(0, 0, 0, 0.4)`,
          });

          return throwError(() => new Error('A POST request is already in progress. Please wait.'));
        }

        // Mark as in progress
        this.postRequestInProgress = true;
        this.requestStateService.setRequestInProgress(true);
      }
    }

    // Proceed with the request and reset the flag on completion
    return next.handle(req).pipe(
      finalize(() => {
        if (req.method === 'POST') {
          const apiUrl = new URL(req.url, window.location.origin).pathname;
          console.log('Completed API URL:', apiUrl);

                    // Reset the flag only for non-exempt APIs
                    const isExempt = Object.keys(this.exemptApis).some(exemptPath => {
                      if (this.exemptApis[exemptPath]) {
                        // Check for exact match or any additional segments after the base path
                        return apiUrl === exemptPath || apiUrl.startsWith(`${exemptPath}/`);
                      }
                      return false;
                    });
          // Reset the flag only for non-exempt APIs
          if (!isExempt) {
            this.postRequestInProgress = false;
            this.requestStateService.setRequestInProgress(false);
            Swal.close();
          }
        }
      })
    );
  }
}
