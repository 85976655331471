<div class="invoice-container" id="content">
    <!-- Invoice Header -->

    <div class="invoice-header">
        <div class="logo">
            <a href="javascript:void(0)">
                <img class="img-fluid"
                    [src]="selectedCompanyLogo==='Mingle' ? 'assets/images/logo/mingle.png' : 'assets/images/logo/unity_logo.png'"
                    width="100px" alt="" />
            </a>
        </div>
        <div class="invoice-title">
            <h4>Invoice</h4>
            <p><strong>Invoice No:</strong> #{{ invoiceData.id }}</p>
        </div>
    </div>

    <!-- Invoice Date -->
    <div class="invoice-date">
        <p><strong>Date:</strong> {{ invoiceData.CreatedDate | date }}</p>
    </div>

    <!-- Billing Info -->
    <div class="billing-info">
        <div class="invoiced-to">
            <p><strong>Invoiced To:</strong></p>
            <p>{{
                customerDetails?.personalInfo?.firstName?.value + " "+ customerDetails?.personalInfo?.lastName?.value}}
            </p>
            <p>{{
                customerDetails?.personalInfo?.serviceAddress?.value?.address1 }}</p>
            <p>{{ customerDetails?.personalInfo?.serviceAddress?.value?.city +
                ", " + customerDetails?.personalInfo?.serviceAddress?.value?.state + ", " +
                customerDetails?.personalInfo?.serviceAddress?.value?.zipCode }}</p>
        </div>
        <div class="pay-to">
            <p><strong>Pay To:</strong></p>
            <!-- <p>{{
                customerDetails?.personalInfo?.firstName?.value + " "+ customerDetails?.personalInfo?.lastName?.value}}
            </p>
            <p>{{
                customerDetails?.personalInfo?.billingAddress?.value?.address1 }}</p>
            <p>{{ customerDetails?.personalInfo?.billingAddress?.value?.city +
                ", " + customerDetails?.personalInfo?.billingAddress?.value?.state + ", " +
                customerDetails?.personalInfo?.billingAddress?.value?.zipCode }}</p> -->
            <div *ngIf="selectedCompanyLogo==='Mingle'">
                <p>Mingle Mobile</p>
                <p>21113 Johnson st, ste 101 </p>
                <p>Pembroke Pines FL 33029</p>
            </div>
            <div *ngIf="selectedCompanyLogo!=='Mingle'">
                <p>Unity Wireless</p>
                <p>21113 Johnson st, ste 101 </p>
                <p>Pembroke Pines FL 33029</p>
            </div>
        </div>
    </div>

    <!-- Invoice Items -->
    <table class="invoice-items">
        <thead>
            <tr>
                <th>Description</th>
                <th>Rate</th>
                <th>QTY</th>
                <th>Amount</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngIf="invoiceData.invoice_items.length === 0">
              <td>(Subscription/Renewal)</td>
              <td>$ {{ invoiceData.TotalAmount | number: '1.2-2' }}</td>
              <td>1</td>
              <td>$ {{ invoiceData.TotalAmount | number: '1.2-2' }}</td>
            </tr>
            <tr *ngFor="let item of invoiceData.invoice_items">
              <td>{{ item.Description }}</td>
              <td>${{ item.Amount | number: '1.2-2' }}</td>
              <td>{{ item.Quantity || 1 }}</td>
              <td>${{ (item.Amount * (item.Quantity || 1)) | number: '1.2-2' }}</td>
            </tr>
          </tbody>
          
    </table>

    <!-- Totals -->
    <div class="invoice-totals">
        <table>
            <tr>
                <td>Sub Total:</td>
                <td>$ {{ invoiceData.TotalAmount | number: '1.2-2' }}</td>
            </tr>
            <tr>
                <td>Tax:</td>
                <td>$ {{ (invoiceData.tax || 0.00) | number: '1.2-2' }}</td>
            </tr>
            <tr class="total-row">
                <td><strong>Total:</strong></td>
                <td><strong>$ {{ invoiceData.TotalAmount | number: '1.2-2' }}</strong></td>
            </tr>
        </table>
    </div>

    <!-- Footer -->
    <div class="invoice-footer mt-3 d-flex flex-column justify-content-center align-items-center">
        <p class="mb-2"><strong>NOTE:</strong> This is a computer-generated receipt and does not require a physical signature.</p>
        <!-- Send Button -->
         <div class="d-flex gap-2" id="btn-wrapper">
             <button class="btn btn-primary px-4" (click)="sendInvoice(customerId)" >Send</button>
             <button class="btn btn-danger px-4" (click)="downloadPDF()" >Download</button>
         </div>
    </div>
</div>
