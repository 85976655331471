import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { HttpService } from "src/app/shared/services/httpService.service";
import { PlanChangeService } from "../services/plan-change.service";
import { CommonService } from "src/app/shared/services/common.service";
import Swal from "sweetalert2";
import { CustomerProfileService } from "src/app/shared/services/customer-profile.service";
import { AuthService } from "src/app/auth/services/auth.service";
import { fadeInOut } from "src/app/shared/services/app.animation";

interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}
declare const google: any; // Declare
@Component({
  selector: "app-plan-change-payment",
  templateUrl: "./plan-change-payment.component.html",
  styleUrls: ["./plan-change-payment.component.scss"],
  animations:[fadeInOut]
})
export class PlanChangePaymentComponent implements OnInit {
  @ViewChild("content", { static: false }) content: TemplateRef<any>;

  validCardTypes = {
    Amex: false,
    Visa: false,
    MasterCard: false,
  };  
  
  @Input() closeAutoRenewModal = new EventEmitter<void>();
  @Input() proratedDetails: any;
  @Input() isPaymentModelOpen:boolean
  @Input() newPlanDetails:any
  @Input() isAutoRenewModelOpen: any;
  @Input() selectedPlanId: any;
  @Input()  selectedPlanName: any;
  isSubmitButtonDisabled: boolean;
  isScheduledDateToday: boolean;
  private cardTypeDetected = false;
  active = 1;
  activeTab: string = "newCard";
  formData: FormGroup;
  formDataWithCVV:FormGroup
  formDataAutoRenew:FormGroup

  expireMonthDropdown = Array.from({ length: 12 }, (_, i) => i + 1);
  currentYear: number = new Date().getFullYear();
  futureYear: number = 2050;
  activeNav: string = "oldPlan";
  expireYearDropdown = Array.from(
    { length: this.futureYear - this.currentYear + 1 },
    (_, i) => this.currentYear + i
  );
  paymentType: any;
  loader: boolean;

  billingAddress: any;
  paymentStatus: boolean;
  isSubmitButtonDisabled_cr: boolean;
  currentPlan: any;
  zipCode: any;
  customerZipCodeId: any;
  modalClosedByX: boolean;
  autoRenewFailedTransactionStatus:boolean
  paymentProfileId:any
profileId:any
creditCarddetail = {
  cardNumber: '',
  cardType: ''
  };
  @Output() modalClosed: EventEmitter<string> = new EventEmitter<string>();
  @Output() submitCVVautoRenew = new EventEmitter<string>();
  @Output() submitCreditCardAutoRenew = new EventEmitter<any>();
  @Output() transactionStatus = new EventEmitter<boolean>();
  @Output() isPaymentModelOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isAutoRenewModelOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();
isSkeletonLoader: boolean=true;
isPaymentSkeletonLoader: boolean=true;
isRenewSkeletonLoader: boolean=true;
useExistingAddress: boolean = true; 
existingAddress:any;
  constructor(
    private fb: FormBuilder,
    private httpService: HttpService,
    private planChangeService: PlanChangeService,
    private commonService: CommonService,private modalService: NgbModal,private customerProfileService:CustomerProfileService,
    private authService:AuthService
  ) {}  

  async ngOnInit(): Promise<void> {
    this.modalOpenStatus()
    this.initializeForm();
    this.initializeFormWithCvv();
    let data = {
      CustomerId: this.httpService.getCustomerId(),
    };
    console.log('customerId: ',data);
    const resposne: any =
      await this.planChangeService.getMinimalprofileData(data);
    if (resposne?.Status) {
      this.billingAddress = resposne?.data?.personalInfo?.mailingAddress?.value;
      this.currentPlan=resposne?.data?.plan?.planDetail?.value
      this.zipCode=resposne?.data?.personalInfo?.mailingAddress?.value?.zipCode;
      const address = resposne?.data?.personalInfo?.mailingAddress?.value;
      if (address) {
        this.existingAddress = `${address.address1 || ''}${address.address2 ? ', ' + address.address2 : ''}, ${address.city || ''}, ${address.state || ''}, ${address.zipCode || ''}`.trim();
      }
    }
    if(this.isPaymentModelOpen){
    await this.getCustomerZipCodeId();
    await this.getPlansDetails();
    this.getPaymentMethod();
    this.cardSelected();
    }
    
    this.closeAutoRenewModal.subscribe(()=>{
      this.onCloseButtonClick()
    })
   
  }
  getGooleFunctionalityStart(){
    setTimeout(() => {
      const addressInput = document.getElementById(
        "address1"
      ) as HTMLInputElement;
      console.log('addressInpu:', addressInput);
      if (addressInput) {
        this.initAutocomplete(addressInput);
      } else {
        console.error("addressInput is undefined after modal is shown");
      }
    }, 0);
  }
  isChecked(): boolean {
    return this.useExistingAddress;
  }

  toggleUseExistingAddress(event: Event): void {
    this.useExistingAddress = (event.target as HTMLInputElement).checked;
    console.log('Updated value of useExistingAddress:', this.useExistingAddress);
  
    if (!this.useExistingAddress) {
      this.getGooleFunctionalityStart(); // Initialize Google functionality
    } else {
      this.destroyGoogleFunctionality(); // Destroy Google functionality
    }
  }
  destroyGoogleFunctionality(): void {
    // Utility function to reset address fields
    const resetAddressFields = (formGroup: FormGroup) => {
      formGroup.patchValue({
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipCode: '',
      });
    };
  
    // Check and reset for formData
    if (this.formData) {
      resetAddressFields(this.formData);
    }
  
    // Check and reset for formDataAutoRenew
    if (this.formDataAutoRenew) {
      resetAddressFields(this.formDataAutoRenew);
    }
  }
  
  initAutocomplete(addressInput: HTMLInputElement): void {
    if (typeof google !== "undefined" && google.maps) {
      const autocomplete = new google.maps.places.Autocomplete(addressInput, {
        types: ["geocode"],
        componentRestrictions: { country: "us" },
      });

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place && place.address_components) {
          const addressComponents = place.address_components.map(
            (component: any) => ({
              long_name: component.long_name,
              short_name: component.short_name,
              types: component.types,
            })
          );
          this.bindAddressComponentsToForm(addressComponents);
        }
      });
    } else {
      console.error("Google Maps API not loaded");
    }
  }
  bindAddressComponentsToForm(components: AddressComponent[]): void {
    let address1 = "";
    let address2 = "";
    let country = "";
    let state = "";
    let city = "";
    let zipCode = "";

    components.forEach((component) => {
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number":
          address1 = component.long_name;
          break;
        case "route":
          address1 += " " + component.long_name;
          break;
        case "sublocality":
        case "locality":
          city = component.long_name;
          break;
        case "country":
          country = component.long_name;
          break;
        case "administrative_area_level_1":
          state = component.short_name;
          break;
        case "postal_code":
          zipCode = component.long_name;
          break;
        default:
          break;
      }
    });

    if (this.formDataAutoRenew){
      this.formDataAutoRenew.patchValue({
        address1: address1.trim(),
        address2: address2.trim(),
        country: country.trim(),
        state: state.trim(),
        city: city.trim(),
        zipCode: zipCode.trim(),
      });
    }

    if(this.formData){
      this.formData.patchValue({
        address1: address1.trim(),
        address2: address2.trim(),
        country: country.trim(),
        state: state.trim(),
        city: city.trim(),
        zipCode: zipCode.trim(),
      });
    }

  }
  async getCustomerZipCodeId(){
    try {
      let data={
        zipCode:this.zipCode
      }
      const response:any=await this.commonService.getZipCodeId(data);
      if(response?.Status){
        this.customerZipCodeId=response?.data?.id
      }else{
        this.commonService.showToast('error','Error','ZipCode Id not found')
      }
    } catch (error) {
      this.commonService.showToast('error','Error',error?.message)
    }
  }

  async getPlansDetails() {
    this.loader = true;  
    try {
      const data = {
        planType: "Prepaid",
        stateAbbr: this.billingAddress?.state,
        'planAreas[]':["CSR"]
      };
      const companyName = this.authService.getCompanyName();
      // if (companyName === "unity") {
      //   delete data['planAreas[]'];
      // }
      const response:any = await this.planChangeService.getPrepaidPlans(data);
      if (response?.Status) {
        this.loader=false

      } else {
        this.commonService.showToast("error", "Error", response?.message);
        this.loader=false

      }
    } catch (error) {
      this.commonService.showToast("error", "Error", error?.message);
    } finally {
      this.loader = false;
    }
  }

  async modalOpenStatus(){
    if(this.isPaymentModelOpen){
      setTimeout(() => {
        this.openXl(this.content);
      }, 0);
      await  this.getGooleFunctionalityStart();
    }else if(this.isAutoRenewModelOpen){
      console.log('isAutoRenewModelOpen true');
      this.initializeFormAutoRenew()
      setTimeout(() => {
        this.openLG(this.content)
      console.log('isAutoRenewModelOpen true');

      }, 0);
     await  this.getGooleFunctionalityStart();

    }
  }
  openXl(content: TemplateRef<any>) {
    this.modalRef = this.modalService.open(content, {
      size: 'xl',        
      backdrop: 'static', 
      keyboard: false     
    });
  }
  openLG(content: TemplateRef<any>) {
    this.modalRef = this.modalService.open(content, {
      size: 'lg',        
      backdrop: 'static', 
      keyboard: false     
    });
  }

 async  setActiveTab(tabName: string) {
    this.activeTab = tabName;
    this.newPlanDetails
    this.proratedDetails
    this.initializeForm()
    this.initializeFormWithCvv()
  }
  initializeForm() {
    this.formData = this.fb.group({
      cardCode: ["", Validators.required],
      cardNumber:['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      nameOnCard: ["", Validators.required],
      expireMonth: ["", Validators.required],
      expireYear: ["", Validators.required],
      isUpdateCard: [false],

      // Added fields for address details
      address1: [""],
      address2: [""], // Optional field
      city: [""],
      state: [""],
      zipCode: ["", Validators.pattern('^[0-9]{5}$')],
    });

    this.formData.get('address1')?.valueChanges.subscribe((address1Value) => {
      if (address1Value) {
        // Address1 is filled, require city, state, and zipCode
        this.formData.get('city')?.setValidators(Validators.required);
        this.formData.get('state')?.setValidators(Validators.required);
        this.formData.get('zipCode')?.setValidators([
          Validators.required,
          Validators.pattern('^[0-9]{5}$'),
        ]);
      } else {
        // Address1 is empty, clear validators for city, state, and zipCode
        this.formData.get('city')?.clearValidators();
        this.formData.get('state')?.clearValidators();
        this.formData.get('zipCode')?.clearValidators();
      }
      // Update value and validation status
      this.formData.get('city')?.updateValueAndValidity();
      this.formData.get('state')?.updateValueAndValidity();
      this.formData.get('zipCode')?.updateValueAndValidity();
    });
    this.planId?.valueChanges.subscribe((value) => {});
  }
  initializeFormAutoRenew() {
    this.formDataAutoRenew = this.fb.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      cardCode: ["", Validators.required],
      cardNumber: ["", [Validators.required, Validators.pattern('^[0-9]*$')]],
      expireMonth: ["", Validators.required],
      expireYear: ["", Validators.required],
  
      // Added fields for address details
      address1: [""],
      address2: [""], // Optional field
      city: [""],
      state: [""],
      zipCode: ["", Validators.pattern('^[0-9]{5}$')],
    });
     // Add conditional validation logic
  this.formDataAutoRenew.get('address1')?.valueChanges.subscribe((address1Value) => {
    if (address1Value) {
      // Address1 is filled, require city, state, and zipCode
      this.formDataAutoRenew.get('city')?.setValidators(Validators.required);
      this.formDataAutoRenew.get('state')?.setValidators(Validators.required);
      this.formDataAutoRenew.get('zipCode')?.setValidators([
        Validators.required,
        Validators.pattern('^[0-9]{5}$'),
      ]);
    } else {
      // Address1 is empty, clear validators for city, state, and zipCode
      this.formDataAutoRenew.get('city')?.clearValidators();
      this.formDataAutoRenew.get('state')?.clearValidators();
      this.formDataAutoRenew.get('zipCode')?.clearValidators();
    }
    // Update value and validation status
    this.formDataAutoRenew.get('city')?.updateValueAndValidity();
    this.formDataAutoRenew.get('state')?.updateValueAndValidity();
    this.formDataAutoRenew.get('zipCode')?.updateValueAndValidity();
  });
  }
 initializeFormWithCvv(){
  this.formDataWithCVV = this.fb.group({
    cardCode: ["", Validators.required],
  });
 }
 get cardCodeWithCVV() {
  return this.formDataWithCVV.get("cardCode");
}
  get isUpdateCard(): FormControl | null {
    return this.formData.get("isUpdateCard") as FormControl | null;
  }
  get planId() {
    return this.formData.get("planID");
  }
  get cardCode() {
    return this.formData.get("cardCode");
  }
  get cardNumber() {
    return this.formData.get("cardNumber");
  }
  get nameOnCard() {
    return this.formData.get("nameOnCard");
  }
  get expireMonth() {
    return this.formData.get("expireMonth");
  }
  get expireYear() {
    return this.formData.get("expireYear");
  }

  onCloseButtonClick() {
    if(this.isPaymentModelOpen){
      this.closePaymentModel()
    }else if(this.isAutoRenewModelOpen){
      this.modalClosedByX=true
      if(this.autoRenewFailedTransactionStatus==true){
        this.isModalClosedByX.emit(false)
      }else{
        this.isModalClosedByX.emit(true)
      }
      this.closeAutoRenewModel()
    }
    this.modalService.dismissAll('Cross click');    
  }
  closePaymentModel() {
    this.isPaymentModelOpenChange.emit(false);
  }
  closeAutoRenewModel(){
    this.isAutoRenewModelOpenChange.emit(false)
  }
  
  markAllAsTouched(): boolean {
    Object.keys(this.formData.controls).forEach(field => {
      const control = this.formData.get(field);
      if (control) {
        control.markAsTouched({ onlySelf: true });
      }
    });
    return this.formData.valid;
  }
  markAllAsTouchedWithCVV(): boolean {
    Object.keys(this.formDataWithCVV.controls).forEach(field => {
      const control = this.cardCodeWithCVV.get(field);
      if (control) {
        control.markAsTouched({ onlySelf: true });
      }
    });
    return this.formDataWithCVV.valid;
  }

  cardSelected() {
    if (this.creditCarddetail?.cardType) {
      for (const key in this.validCardTypes) {
        if (this.validCardTypes.hasOwnProperty(key)) {
          this.validCardTypes[key] = key === this.creditCarddetail.cardType;
        }
      }
      this.cardTypeDetected = true;
    } else {
      this.cardTypeDetected = false;
      this.resetCardType();
    }
  }
  isExistingCardType(cardType: string): boolean {
    return this.creditCarddetail?.cardType === cardType;
  }
  
  onCardNumberChange(event: any) {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;

    // if (/[^0-9]/.test(value)) {
    //   inputElement.value = value.replace(/[^0-9]/g, '');
    //   alert('Only numeric characters are allowed.');
    // }

    this.formData.get('cardNumber')?.setValue(inputElement.value);
    const cardNumberStr = event.target.value;

    if (cardNumberStr.length === 1 || cardNumberStr.length === 16) {
      const cardType = this.getCardType(cardNumberStr);
      
      for (const key in this.validCardTypes) {
        if (this.validCardTypes.hasOwnProperty(key)) {
          this.validCardTypes[key] = key === cardType;
        }
      }

      this.cardTypeDetected = true;
    } else if (!cardNumberStr) {
      this.cardTypeDetected = false;
      this.resetCardType();
    }
  }

  resetCardType() {
    for (const key in this.validCardTypes) {
      if (this.validCardTypes.hasOwnProperty(key)) {
        this.validCardTypes[key] = false;
      }
    }
  }

  getCardType(cardNumber: string): string | null {
    if (!cardNumber || cardNumber.length === 0) {
      return null; 
    }

    const firstDigit = cardNumber.charAt(0);

    if (firstDigit === "4") {
      return "Visa";
    } else if (firstDigit === "5") {
      return "MasterCard";
    } else if (firstDigit === "6") {
      return "Amex";
    } else {
      return null; 
    }
  }

  isCardTypeValid(cardType: string): boolean {
    return this.validCardTypes[cardType];
  }

  async getCustomerSubscription(){
    try {
      let data={
        customerId:this.httpService.getCustomerId(),
        single:true
      }
      const response:any= await this.customerProfileService.getCustomerSubscription(data)
      if(response?.Status){
        this.profileId=response?.data?.data[0]?.ProfileId
        this.paymentProfileId=response?.data?.data[0]?.PaymentProfileId
        this.getCustomerPaymentSubscription()
      }else{
        this.commonService.showToast('error','Error',response?.message)
      }
    } catch (error) {
      console.log(error);
      this.commonService.showToast('error','Error',error?.message)
    }
  }

  async getCustomerPaymentSubscription(){
    try {
      let data={
        profileId:this.profileId,
        paymentProfileId:this.paymentProfileId
      }
      const res:any = await this.customerProfileService.getCustomerPaymentProfile(data)
      if(res?.Status){
        this.creditCarddetail.cardNumber=res?.data?.payment?.creditCard?.cardNumber;
        this.creditCarddetail.cardType=res?.data?.payment?.creditCard?.cardType;
        this.isRenewSkeletonLoader=false
        this.isPaymentSkeletonLoader=false
        this.commonService.loader=false
      }else{
        this.commonService.showToast('error','Error',res?.message)
        this.commonService.loader=false
        this.isRenewSkeletonLoader=false
        this.isPaymentSkeletonLoader=false
      }
    } catch (error) {
      console.log(error);
      this.commonService.showToast('error','Error',error?.message)
      this.commonService.loader=false
      this.isRenewSkeletonLoader=false
      this.isPaymentSkeletonLoader=false
    }
  }

  isEffectiveDateToday(): boolean {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (this.proratedDetails?.effectiveDate) {
      const effectiveDate = new Date(this.proratedDetails.effectiveDate);
      effectiveDate.setHours(0, 0, 0, 0);
      this.isScheduledDateToday = effectiveDate.getTime() === today.getTime();
      return this.isScheduledDateToday;
    }
    this.isScheduledDateToday = false;
    return this.isScheduledDateToday;
  }

  async getPaymentMethod(){
    await this.isEffectiveDateToday()
    if(this.proratedDetails?.changeType=='Upgrade' || this.proratedDetails?.changeType=="SamePlan" && this.isEffectiveDateToday){
      this.paymentType='Immediate'
    }else{
      this.paymentType ='Scheduled'
    }
    return ''
  }
  onUpdateCard(event: Event) {
    const checkbox = event.target as HTMLInputElement;
    this.isUpdateCard?.setValue(checkbox.checked);
  }

  async isTransactionStatus(){
    this.transactionStatus.emit(true)
  }

  async onSubmitWithCVV() {
    this.loader = true;
    try {
      if(this.markAllAsTouchedWithCVV()){
        const cvv = this.cardCode.value;
      let data = {
        CustomerId: this.httpService.getCustomerId(),
        planId: this.selectedPlanId,
        planName: this.selectedPlanName,
        cvv: this.cardCodeWithCVV.value,
        amount: this.proratedDetails?.proratedPrice,
        billingAddress: {
          address1: this.billingAddress?.address1,
          address2: this.billingAddress?.address2,
          zipCode: this.customerZipCodeId,
        },
      };
      console.log('zipcode id:',this.customerZipCodeId);

      const response: any = await this.planChangeService.getPlanProfile(data);
      if (response?.Status) {
        this.paymentStatusTracker(response);
         this.isTransactionStatus();

      } else {
        this.paymentStatusTracker(response);
        this.commonService.showToast('error','Error',response?.error)
      }
      }else{
        this.commonService.showToast('error','Error','Fill all the required fields')
      }
    } catch (error) {
      this.paymentStatusTracker(error);
      if (typeof error?.error?.error === "string") {
        this.commonService.showToast("error", "Error", error?.error?.error);
      } else {
        this.commonService.showToast(
          "error",
          "Error",
          error?.error?.message || "An Unexpected error occured."
        );
      }

      throw error;
    } finally {
      this.loader = false;
    }
  }

  paymentStatusTracker(response: any) {
    if (response?.Status) {
      this.onCloseButtonClick();
      this.paymentStatus = true;
      this.customerProfileService.getMinimalProfileData()
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Plan Changed Successfully.",
        html: 'Plan Changed Successfully.<br><strong id="swal-timer">10</strong> seconds remaining.',
        timer: 10000, 
        showConfirmButton: false,
        timerProgressBar: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading(); 
          const closeButton = document.createElement("button");
          closeButton.innerHTML = "&times;";
          closeButton.classList.add("swal2-close");
          closeButton.style.position = "absolute";
          closeButton.style.top = "10px";
          closeButton.style.right = "10px";
          closeButton.style.fontSize = "1.5rem";
          closeButton.style.background = "none";
          closeButton.style.border = "none";
          closeButton.style.cursor = "pointer";

          closeButton.onclick = () => {
            Swal.close();
            this.loader = false;
            this.isSubmitButtonDisabled_cr = false;
          };

          Swal.getHtmlContainer()?.appendChild(closeButton);
          const swalTimer = document.getElementById("swal-timer");
          let timerInterval = setInterval(() => {
            let currentTime = parseInt(swalTimer?.innerText || "10");
            if (currentTime > 1) {
              swalTimer!.innerText = (currentTime - 1).toString();
            } else {
              clearInterval(timerInterval);
            }
          }, 1000);
        },
      }).then(() => {
        this.loader = false;
        this.isSubmitButtonDisabled_cr = false;
      });
    } else {
      this.paymentStatus = true;
      this.onCloseButtonClick();
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Error.",
        html: 'Transaction Fails.<br><strong id="swal-timer">10</strong> seconds remaining.',
        timer: 5000, 
        showConfirmButton: false,
        timerProgressBar: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading(); 
          const closeButton = document.createElement("button");
          closeButton.innerHTML = "&times;";
          closeButton.classList.add("swal2-close");
          closeButton.style.position = "absolute";
          closeButton.style.top = "10px";
          closeButton.style.right = "10px";
          closeButton.style.fontSize = "1.5rem";
          closeButton.style.background = "none";
          closeButton.style.border = "none";
          closeButton.style.cursor = "pointer";

          closeButton.onclick = () => {
            Swal.close();
            this.loader = false;
            this.isSubmitButtonDisabled_cr = false;
          };

          Swal.getHtmlContainer()?.appendChild(closeButton);
          const swalTimer = document.getElementById("swal-timer");
          let timerInterval = setInterval(() => {
            let currentTime = parseInt(swalTimer?.innerText || "10");
            if (currentTime > 1) {
              swalTimer!.innerText = (currentTime - 1).toString();
            } else {
              clearInterval(timerInterval);
            }
          }, 1000);
        },
      }).then(() => {
        this.loader = false;
        this.isSubmitButtonDisabled_cr = false;
      });
    }
  }
  @Output() isModalClosedByX = new EventEmitter<boolean>();
  autorenewStatusTracker(response: any) {
    if (response?.Status) {
      this.modalClosedByX=false
      this.autoRenewFailedTransactionStatus=true
      this.onCloseButtonClick();
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Auto-Renewed Successfully.",
        html: 'Auto Renewed Successfully.<br><strong id="swal-timer">10</strong> seconds remaining.',
        timer: 10000, 
        showConfirmButton: false,
        timerProgressBar: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading(); 
          const closeButton = document.createElement("button");
          closeButton.innerHTML = "&times;";
          closeButton.classList.add("swal2-close");
          closeButton.style.position = "absolute";
          closeButton.style.top = "10px";
          closeButton.style.right = "10px";
          closeButton.style.fontSize = "1.5rem";
          closeButton.style.background = "none";
          closeButton.style.border = "none";
          closeButton.style.cursor = "pointer";

          closeButton.onclick = () => {
            Swal.close();
            this.loader = false;
            this.isSubmitButtonDisabled_cr = false;
          };

          Swal.getHtmlContainer()?.appendChild(closeButton);
          const swalTimer = document.getElementById("swal-timer");
          let timerInterval = setInterval(() => {
            let currentTime = parseInt(swalTimer?.innerText || "10");
            if (currentTime > 1) {
              swalTimer!.innerText = (currentTime - 1).toString();
            } else {
              clearInterval(timerInterval);
            }
          }, 1000);
        },
      }).then(() => {
        this.loader = false;
        this.isSubmitButtonDisabled_cr = false;
      });
    } else {
    }
  }
  modalRef: NgbModalRef;

  closeModal() {
    if (this.modalRef) {
      this.modalRef.close(); 
      this.modalRef = null; 
    }
  }

  async onSubmitWithCreditCard() {
    this.loader = true;
    try {
      if(this.markAllAsTouched()){
        const formattedMonth = this.formatMonth(this.expireMonth.value);
        let datas = {
          CustomerId: this.httpService.getCustomerId(),
          planId: this.selectedPlanId,
          planName: this.selectedPlanName,
          chargeCreditCard: "Y",
          taxAmount: 0,
          taxIncluded: true,
        };
        datas["createTransactionRequest"] = {
          refId: "123456",
          transactionRequest: {
            amount: this.proratedDetails?.proratedPrice,
            quantity: 1,
            payment: {
              creditCard: {
                cardNumber: this.cardNumber.value,
                expirationDate:
                  `${this.expireYear.value}-${formattedMonth}`,
                cardCode: this.cardCode.value,
                nameOnCard: this.nameOnCard.value,
              },
            },
          },
        };
        datas["billingAddress"] = {
          address1: this.billingAddress.address1,
          address2: this.billingAddress.address2,
          zipCode: this.customerZipCodeId,
        };

         // Check if `address1` exists in the form, then update the billing address
      if (this.formData.get('address1')?.value) {
        datas["billingAddress"] = {
          address1: this.formData.get('address1')?.value,
          address2: this.formData.get('address2')?.value || '',
          city: this.formData.get('city')?.value || '',
          state: this.formData.get('state')?.value || '',
          zipCode: this.formData.get('zipCode')?.value || '',
        };
      }

  
        if (this.paymentType == "Immediate") {
          datas["isUpdateCard"] = this.isUpdateCard?.value;
        }
        if (this.paymentType == "Scheduled") {
          let data = {
            CustomerId: this.httpService.getCustomerId(),
            planId: this.selectedPlanId,
            amount: this.proratedDetails?.proratedPrice,
            planName: this.selectedPlanName,
            creditCard: {
              cardNumber: this.cardNumber.value,
              expirationDate:
                `${this.expireYear.value}-${formattedMonth}`,
              cardCode: this.cardCode.value,
              nameOnCard: this.nameOnCard.value,
            },
          };
          data["billingAddress"] = {
            address1: this.billingAddress.address1,
            address2: this.billingAddress.address2,
            zipCode: this.customerZipCodeId,
          };
          if (this.formData.get('address1')?.value) {
            datas["billingAddress"] = {
              address1: this.formData.get('address1')?.value,
              address2: this.formData.get('address2')?.value || '',
              city: this.formData.get('city')?.value || '',
              state: this.formData.get('state')?.value || '',
              zipCode: this.formData.get('zipCode')?.value || '',
            };
          }
          const response: any = await this.planChangeService.getPlanProfile(data);
          if (response?.Status) {
            this.loader = false;
            this.paymentStatusTracker(response);
           this.isTransactionStatus();
          } else {
            this.loader = false;
            this.paymentStatusTracker(response);
          }
        } else {
          const response: any =
            await this.planChangeService.changeCustomerPlan(datas);
          if (response?.Status) {
            this.loader = false;
            this.paymentStatusTracker(response);
            this.commonService.showToast(
              "success",
              "Success",
              "Plan Changed Successfully."
            );
          } else {
            this.commonService.showToast("error", "Error", response?.message);
            this.loader = false;
            this.paymentStatusTracker(response);
          }
        }
      }else{
        this.commonService.showToast('error','Error','Fill all the required fields')
      }
    } catch (error) {
      if(this.markAllAsTouched()){
        this.loader = false;
      this.paymentStatusTracker(error);
      if (typeof error?.error?.error === "string") {
        this.commonService.showToast("error", "Error", error?.error?.error);
      } else {
        this.commonService.showToast(
          "error",
          "Error",
          error?.error?.message || "An Unexpected error occured."
        );
        console.log(error?.message);
      }
      }
    } finally {
      this.loader = false;
    }
  }
  private formatMonth(month: number): string {
    return month < 10 ? `0${month}` : `${month}`;
  }
  
  onSubmitWithCVVAutoRenew(){
    try {
      if(this.formDataWithCVV.valid){
        const cvv= this.cardCodeWithCVV.value
        this.submitCVVautoRenew.emit(cvv)
      }else{
        console.log('form not validate');
      }
    } catch (error) {
      this.isModalClosedByX.emit(true)
      console.error('Error submitting CVV:', error?.message);
    }
  }
  onSubmitWithCreditCardAutoRenew() {
    try {
      const formattedMonth = this.formatMonth(this.formDataAutoRenew.get('expireMonth')?.value);
  
      if (this.formDataAutoRenew.valid) {
        console.log('Form is valid');
  
        // Check if address1 exists in the form
        const address1Value = this.formDataAutoRenew.get('address1')?.value;
  
        const billingInfo = {
          firstName: this.formDataAutoRenew.get('firstName')?.value,
          lastName: this.formDataAutoRenew.get('lastName')?.value,
          address: address1Value || this.billingAddress.address1, // Update if address1 exists
          address2: address1Value ? this.formDataAutoRenew.get('address2')?.value : this.billingAddress.address2,
          city: address1Value ? this.formDataAutoRenew.get('city')?.value : this.billingAddress.city,
          state: address1Value ? this.formDataAutoRenew.get('state')?.value : this.billingAddress.state,
          zip: address1Value ? this.formDataAutoRenew.get('zipCode')?.value : this.billingAddress.zipCode,
          country: "US",
        };
  
        const cardDetails: any = {
          cardNumber: this.formDataAutoRenew.get('cardNumber')?.value,
          cardExpiryDate: `${this.formDataAutoRenew.get('expireYear')?.value}-${formattedMonth}`,
          cardCode: this.formDataAutoRenew.get('cardCode')?.value,
          billingInfo,
        };
  
        console.log('Submitting card details:', cardDetails);
        this.submitCreditCardAutoRenew.emit(cardDetails);
      } else {
        Object.keys(this.formDataAutoRenew.controls).forEach((field) => {
        const control = this.formDataAutoRenew.get(field);
        if (control) {
          control.markAsTouched({ onlySelf: true });
        }
      });
        this.commonService.showToast('error', 'Validation Error', 'Fill all required fields before proceeding.');
      }
    } catch (error) {
      
      console.error('Error submitting CVV:', error?.message);
    }
  }
  
  
 
}
