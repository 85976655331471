import { HttpClient, HttpEventType } from "@angular/common/http";
import { Component, HostListener } from "@angular/core";
import { CampaignEmailService } from "../shared/campaign.service";
import { CommonService } from "src/app/shared/services/common.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as bootstrap from 'bootstrap';
import { fadeInOut } from "src/app/shared/services/app.animation";
import { CustomerProfileService } from "src/app/shared/services/customer-profile.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-campaign-email",
  templateUrl: "./campaign-email.component.html",
  styleUrls: ["./campaign-email.component.scss"],
  animations: [fadeInOut]
})
export class CampaignEmailComponent {
  public itemsPerPage: number = 10;
  public totalItems: number = 0;
  currentPage: number = 1;
  selectedFile: File | null = null;
  uploadProgress: number | null = null;
  rows: any;
  file: File | null = null;
  isDragging = false;
  dropdownVisible = new Set<number>();
  startDate?: string;
  endDate?: string;
  bsRangeValue: Date[];
  searchTerm: any;
  OpenCalender: boolean = false;
  openSearchBox: boolean = false;
  summaryData:any[]=[];
  counts:any;
  isCollapsed = true;
  errorDetails: string = '';
  isSkeletonLoader: boolean = true;
  headers: any[] = [
    { key: "CustomerId", display: "Customer Id" },
    { key: "EmailStatus", display: "Email Status" },
    { key: "EmailTemplateName", display: "Email Template Name" },
    { key: "ErrorDetails", display: "Error Details" },
    { key: "TemplateVariables", display: "Template Variables" },
    { key: "TemplateSlug", display: "Template Slug" },
    { key: "CreatedAT", display: "Created Date" },
    { key: "UpdatedAt", display: "Updated Date" },
    { key: "CreatedByName", display: "Created By" },
    // { key: "CreatedBy", display: "Created By" },

    { key: "action", display: "Actions" },
  ];
  statusFilter = "";
  selectedTemplate: any = null;
  dynamicValues: any = {};
  constructor(
    private http: HttpClient,
    private CampaignEmailService: CampaignEmailService,
    private commonService: CommonService,
    private modalService: NgbModal,
    private customerProfileService :CustomerProfileService,
    private router :Router
  ) {}
  ngOnInit(): void {
    this.getBulkList();
    // this.getEmailTemplate()
  }
  onFileSelect(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.file = input.files[0];
    }
  }
  stop(event: Event) {
    event.stopPropagation();
  }
  onFileDrop(event: DragEvent): void {
    event.preventDefault();
    this.isDragging = false;
    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      this.file = event.dataTransfer.files[0];
    }
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    this.isDragging = true;
  }

  onDragLeave(): void {
    this.isDragging = false;
  }

  browseFile(): void {
    const fileInput = document.querySelector(
      'input[type="file"]'
    ) as HTMLElement;
    fileInput.click();
  }

  removeFile(): void {
    this.file = null;
    const fileInput = document.querySelector(
      'input[type="file"]'
    ) as HTMLInputElement;
    if (fileInput) {
      fileInput.value = ""; // Reset the input value
    }
  }
  uploadCSV(): void {
    if (!this.file) {
      this.commonService.showToast(
        "error",
        "Error",
        "Please select a file to upload"
      );
      return;
    }

    const formData = new FormData();
    formData.append("file", this.file);

    // this.isUploading = true;
    this.CampaignEmailService.uploadCSV(formData).subscribe({
      next: (response: any) => {
        // this.isUploading = false;
        this.uploadProgress = null;
        this.commonService.showToast(
          "success",
          "Success",
          "CSV file uploaded successfully"
        );
      },
      error: (error) => {
        // this.isUploading = false;
        console.log("errror", error?.error?.error?.file);
        this.uploadProgress = null;
        this.commonService.showToast(
          "error",
          "Error",
          error?.error?.error?.file
            ? error?.error?.error?.file
            : "Something went wrong during CSV upload"
        );
      },
      complete: () => {
        // this.isUploading = false;
        this.uploadProgress = null;
      },
    });
  }
  onTemplateChange() {
    this.dynamicValues = {};
  }

  submitTemplate() {
    console.log("Selected Template:", this.selectedTemplate);
    console.log("Dynamic Values:", this.dynamicValues);
  }
  onDateRangeSelected(dateRange: Date[]): void {
    this.startDate = this.formattedDate(dateRange[0]);
    this.endDate = this.formattedDate(dateRange[1]);
    // this.dateRange=dateRange;
    this.getBulkList();
  }
  formattedDate(inputDate: Date): string {
    const month = ("0" + (inputDate.getMonth() + 1)).slice(-2);
    const day = ("0" + inputDate.getDate()).slice(-2);
    const year = inputDate.getFullYear();
    return `${year}-${month}-${day}`;
  }



  getBadgeClass(status: string) {
    return {
      "badge-success": status === "Sent",
      "badge-warning": status === "Pending",
      "badge-danger": status === "Failed",
    };
  }

  retryEmail(email: any) {
    console.log("Retrying email ID:", email.id);
    // Implement retry logic here
  }

  toggleErrorDetails(log: any) {
    log.showDetails = !log.showDetails;
  }

  searchChange(e) {
    this.searchTerm = e.target.value;

    this.getBulkList();
  }
  onPaginationChanged(params: any) {
    this.getBulkList()
  }
  getBulkList() {
    let obj = {
      perPage: this.itemsPerPage,
      page: this.currentPage,
      searchTerm: this.searchTerm || "",
    } as any;

    if (this.startDate) {
      obj.startDate = this.startDate;
    }
    if (this.endDate) {
      obj.endDate = this.endDate;
    }
    this.CampaignEmailService.getBulkEmailData(obj).then(
      (res: any) => {
        this.rows = res?.data?.data?.data;
        this.totalItems = res.data?.data?.total;
        this.summaryData = res.data?.counts.map((item: any) => {
          return {
            title: item.EmailStatus,
            count: item.count
          };
        });
        setTimeout(() => {
          this.isSkeletonLoader = false;
        }, 1500);
      },
      (error: any) => {}
    );
  }
  downloadTemplate() {
    this.CampaignEmailService.getEmailTemplate().then(
      (res: any) => {
        if (res && res.data && res.data.length > 0) {
          const template = res.data[0]; // Assuming you want the first item in the array
          if (template.downloadLink) {
            // Trigger the download
            const link = document.createElement('a');
            link.href = template.downloadLink;
            link.download = template.FileName || 'template.csv'; // Use the FileName attribute or a default name
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.commonService.showToast(
              "success",
              "Success",
              "Downloaded successfully"
            );
          } else {
            this.commonService.showToast(
              "error",
              "Error",
          
               "Something went wrong during CSV Download"
            );
          }
        } else {
          console.error('No data found in the response');
        }
      },
      (error: any) => {
        console.error('Error fetching the template:', error);
      }
    );
  }
  toggleDropdown(index: number) {
    if (this.dropdownVisible.has(index)) {
      this.dropdownVisible.delete(index);
    } else {
      this.dropdownVisible.clear();
      this.dropdownVisible.add(index);
    }
  }
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (this.dropdownVisible.size === 0) return;

    const index = this.dropdownVisible.values().next().value;
    const triggerEl = document.getElementById(`trigger-${index}`);
    const dropdownEl = document.getElementById(`dropdown-${index}`);
    const target = event.target as HTMLElement;

    if (triggerEl && dropdownEl) {
      const clickInside = triggerEl.contains(target) || dropdownEl.contains(target);
      if (!clickInside) {
        this.dropdownVisible.clear();
      }
    }
  }
  openCalender() {
    this.OpenCalender = !this.OpenCalender;
  }
  openSearch() {
    this.openSearchBox = !this.openSearchBox;
  }
  sendStatus(item, value) {
    let obj = {
      id: item.id,
      status: value,
    } as any;

    this.CampaignEmailService.sentStatus(obj).then(
      (res: any) => {
        this.commonService.showToast(
          "success",
          "Success",
          "Updated successfully"
        );
        this.getBulkList();
      },
      (error: any) => {
        this.commonService.showToast(
          "error",
          "Error",
          error?.error?.error?.file
            ? error?.error?.error?.file
            : "Something went wrong "
        );
      }
    );
  }
  sendEmail(item) {
    let obj = {
      id: item.id,
      "CustomerId":item.CustomerId,
      "emailTemplateName":item.EmailTemplateName
    } as any;

    this.CampaignEmailService.sendEmail(obj).then(
      (res: any) => {
        this.commonService.showToast(
          "success",
          "Success",
          "Email Sent successfully"
        );
        this.getBulkList();
      },
      (error: any) => {
        this.commonService.showToast(
          "error",
          "Error",
          error?.error?.error?.file
            ? error?.error?.error?.file
            : "Something went wrong "
        );
      }
    );
  }
  openErrorModal(errorDetails: string) {
    this.errorDetails = errorDetails; // Set error details
    const modal = document.getElementById('errorDetailsModal'); // Get modal element
    if (modal) {
      const bootstrapModal = new bootstrap.Modal(modal); // Initialize Bootstrap modal
      bootstrapModal.show(); // Show the modal
    }
  }
  viewDetails(CustomerID){
   this.customerProfileService.setCustomerId(CustomerID);
    this.customerProfileService.getMinimalProfileData();
    this.router.navigate(["/customer-dashboard/customer-profile"], {
      queryParams: { showBack: true },
    });
  }
  
  refresh(){
    this.startDate = ''
    this.endDate = ''
    this.getBulkList()
  }
}
