<!-- <div class="p-3" style="margin-top: -10px;">
    <div class="row">
        <div class="col-12">
            <app-breadcrumb></app-breadcrumb>
        </div>
    </div>
</div> -->

<div class="container card p-3">
    <div class="row">
        <div class="col-md-6">
            <h5>Search Results Page</h5>
        </div>
        <div class="col-md-6" style="text-align: end;"  *ngIf="!isAdvanceSearched">
            <h5>Search Count : {{     searchCount}}</h5>
        </div>
        <div class="col-md-6" style="text-align: end;"  *ngIf="isAdvanceSearched">
            <h5>Search Count : {{ totalItems}}</h5>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 mx-auto">
            <div class="custom-datatable table-responsive">
                <!-- Default Table -->
                <ng-container *ngIf="!isAdvanceSearched">
                  <table class="table table-hover" style="text-wrap: nowrap;">
                    <thead>
                      <tr>
                        <th scope="col">Cust Id</th>
                        <th scope="col">Enroll Id</th>
                        <th scope="col">Name</th>
                        <th scope="col">Address</th>
                        <th scope="col">SIM</th>
                        <th scope="col">MDN</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of data;let i = index" (click)="setUser(item, i)"   [class.highlight]="i === highlightedRowIndex"  >
                        <td scope="row">{{ item.CustomerID }}</td>
                        <td scope="row">{{ item.EnrollmentNo }}</td>
                        <td scope="row">{{ item.FirstName }} {{ item.LastName }}</td>
                        <td scope="row">
                          {{ item.ServiceAddress1 }}, {{ item.ServiceAddress2 }}, {{ item.CityName }}
                          {{ item.StateCode }}, {{ item.ZipCode }}
                        </td>
                        <td scope="row">{{ item.iccid }}</td>
                        <td scope="row">{{ item.TelephoneNumber }}</td>
                        <td scope="row">{{ item.AccountStatus }}</td>
                      </tr>
                    </tbody>
                  </table>
                </ng-container>
              
                <!-- Advanced Search Table -->
                <ng-container *ngIf="isAdvanceSearched">
                    <div class="table-responsive table-hover table-striped table-card" style="min-height: 500px; overflow: auto;">
                      <table class="table table-nowrap mb-0">
                        <thead class="table-light">
                          <tr class="text-nowrap">
                            <th scope="col" *ngFor="let col of column" style="font-weight: 600;">
                              {{ col.displayName }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of data; let i = index" (click)="setUser(item, i)" 
                          [class.highlight]="i === highlightedRowIndex" class="cursor-pointer text-nowrap">
                            <td *ngFor="let col of column">
                              <ng-container [ngSwitch]="col.value">
                                <!-- Address Column -->
                                <ng-container *ngSwitchCase="'serialNumber'">
                                  <a href="#" 
                                     class="text-primary font-weight-bold text-decoration-underline" 
                                     (click)="handleOrderClick({ id: item?.serialNumber, customerId: item?.customerId }, i); $event.stopPropagation(); $event.preventDefault()">
                                    {{ item[col.value] }}
                                  </a>
                                </ng-container>
                                
                                <ng-container *ngSwitchCase="'address'">
                                  {{ item.address }}, {{ item.CityName }}, <br> {{ item.StateName }}, {{ item.ZipCode || '-' }}
                                </ng-container>
                  
                                <!-- Amount Paid Column -->
                                <ng-container *ngSwitchCase="'amountPaid'">
                                  <span class="plan-price">
                                    ${{ item.amountPaid || '-' }}
                                  </span>
                                </ng-container>
                                <ng-container *ngSwitchCase="'orderDate'">
                                    {{ commonService.formatToLocalDateTime(item[col.value], 'date') }} <br>
                                    {{ commonService.formatToLocalDateTime(item[col.value], 'time') }}
                                  </ng-container>

                                <!-- Status Column with Badges -->
                                <ng-container *ngSwitchCase="'status'">
                                  <span [ngClass]="orderStatusMap[item[col.value]]?.badgeClass || 'badge-secondary'" class="badge">
                                    {{ item[col.value] }}
                                  </span>
                                  
                                </ng-container>
                  
                                <!-- Subscription Status -->
                                <ng-container *ngSwitchCase="'subscriptionStatus'">
                                  <span [ngClass]="getStatusBadgeClass(item[col.value])" class="custom-badge">
                                    {{ item[col.value] }}
                                  </span>
                                </ng-container>
                                
                                <!-- Default Rendering -->
                                <ng-container *ngSwitchDefault>
                                  {{ item[col.value] || '-' }}
                                </ng-container>
                              </ng-container>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                  
                      <!-- Empty State -->
                      <div class="d-flex justify-content-center p-3" *ngIf="data?.length === 0">
                        <span class="text-muted">No data found</span>
                      </div>
                    </div>
                  </ng-container>
                  
                  
                  
              </div>
              


            <!-- <div class="pull-right p-20 d-flex">
                <select class="form-control me-2 page-dropdown" [(ngModel)]="itemsPerPage"
                    (change)="onChangeNumberOfPage()">
                    <option *ngFor="let page of pageDropdownMenu" [value]="page">{{page}}</option>
                </select>
                <button class="btn btn-secondary me-3" [disabled]="!prev_page_url"
                    (click)="onPrevious()">Previous</button>
                <button class="btn btn-secondary" [disabled]="!next_page_url" (click)="onNext()">Next</button>
                
            </div> -->
            <div class="d-flex justify-content-end align-items-center m-3">
              <!-- <div class="d-flex align-items-center">
                <label for="itemsPerPage" class="me-2">Items per page:</label>
                <select
                  id="itemsPerPage"
                  class="form-select"
                  [(ngModel)]="itemsPerPage"
                  (change)="onItemsPerPageChange()"
                  [disabled]="loader"
                >
                  <option *ngFor="let option of pageDropdownMenu" [value]="option">{{ option }}</option>
                </select>
              </div> -->
              <ngb-pagination
                class="pagination-block"
                [collectionSize]="totalItems"
                [(page)]="currentPage"
                [boundaryLinks]="true"
                [maxSize]="5"
                [pageSize]="itemsPerPage"
                [ellipses]="true"
                (pageChange)="onPageChange($event)"
              >
                <ng-template ngbPaginationPrevious>Previous</ng-template>
                <ng-template ngbPaginationNext>Next</ng-template>
              </ngb-pagination>
            </div>
            
            
        </div>
    </div>
</div>

