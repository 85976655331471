<ng-template #content let-modal *ngIf="isPaymentModelOpen">
    <div class="modal-header">
      <h4 class="modal-title">Payment Option</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="onCloseButtonClick()"></button>
    </div>
  
    <div class="modal-body">
        <ul class="nav nav-tabs d-flex">
            <li class="nav-item flex-fill">
              <a class="nav-link text-center" [class.active]="activeTab === 'existingCard'" (click)="setActiveTab('existingCard');getCustomerSubscription()">
                <h5>Payment with Existing Card</h5>
              </a>
            </li>
            <li class="nav-item flex-fill">
              <a class="nav-link text-center" [class.active]="activeTab === 'newCard'" (click)="setActiveTab('newCard')">
                <h5>Payment with New Card</h5>
              </a>
            </li>
          </ul>
  
      <div class="tab-content mt-3">
        <div *ngIf="activeTab === 'existingCard'">
          <div class="container mt-4" *ngIf="!isPaymentSkeletonLoader">
            <div class="row">
              <div class="col-md-6">
                <div class="card p-4">
                  <h3>Payment</h3>
                  <p class="text-muted">All transactions are secure and encrypted.</p>
          
                  <div class="bg-light p-0 pb-2 rounded">
                  <div class="d-flex justify-content-between align-items-center  rounded px-4 py-2 form-background">
                    <h5 class="m-0 text-dark">Credit card</h5>
                    <div class="credit-card-icons d-flex">
                      <img src="../../../../../assets/images/credit-cards/card.png" alt="master-card" class="credit-card-icon" [ngClass]="{'disabled': !isExistingCardType('MasterCard')}">
                      <img src="../../../../../assets/images/credit-cards/american-express.png" alt="amex" class="credit-card-icon" [ngClass]="{'disabled': !isExistingCardType('Amex')}">
                      <img src="../../../../../assets/images/credit-cards/visa.png" alt="visa" class="credit-card-icon" [ngClass]="{'disabled': !isExistingCardType('Visa')}">
                    </div>
                  </div>
          
                  <form [formGroup]="formDataWithCVV" class="mt-3 custom-form-width-cvv "  (ngSubmit)="onSubmitWithCVV()">
                    <div class="form-group mb-3">
                      <h5 class="text-dark" style="white-space: nowrap;">
                        Pay with Card Number: <span style="display: inline;" class="text-danger">{{creditCarddetail?.cardNumber}}</span>
                      </h5>
                    </div>
                    <div class="form-group mb-3 custom-cvv-width">
                      <input type="password" maxlength="4" formControlName="cardCode" class="form-control" placeholder="CVV">
                    </div>
                    <div *ngIf="formDataWithCVV.get('cardCode')?.touched && formDataWithCVV.get('cardCode')?.hasError('required')" class="text-danger">CVV is required.</div>
                  </form>
                  </div>
                  
                  <button type="submit" class="btn submit-button w-100 mt-3 d-flex align-items-center justify-content-center" (click)="onSubmitWithCVV()" ><h5>Pay Now</h5></button>
                </div>
              </div>
          
              <div class="col-md-6">
                <div class="card p-4">
                  <h3>Summary</h3>
                  <p class="text-muted">Order Summary and Details</p>
          
                  <ul class="list-group">
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Plan
                      <span>{{ newPlanDetails?.plan?.DisplayName }}</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Data
                      <span>{{ newPlanDetails?.planDetail?.PlanData || "-" }} Data</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Amount
                      <span>$ {{ proratedDetails?.proratedPrice || "-" }}</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center total-summary">
                      <strong>Total Amount</strong>
                      <strong>$ {{ proratedDetails?.proratedPrice || "-" }}</strong>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> 
          <div *ngIf="isPaymentSkeletonLoader"  style="max-width: 100%;">
            <app-skeleton-loader shape="payment-form"></app-skeleton-loader>
        </div>
        </div>
  
        <div *ngIf="activeTab === 'newCard'">
          <div class="container mt-4" >
            <div class="row">
              <div class="col-md-6">
                <div class="card p-4">
                  <h3>Payment</h3>
                  <p class="text-muted">All transactions are secure and encrypted.</p>
          
                  <div class="bg-light p-0 pb-2 rounded">
                  

                  <form [formGroup]="formData" class="mt-3 custom-form-width "  (ngSubmit)="onSubmitWithCreditCard()">
                    <div class="d-flex justify-content-between align-items-center  rounded px-4 py-2 form-background mb-3">
                      <h5 class="m-0 text-dark">Credit card</h5>
                      <div class="credit-card-icons d-flex">
                        <img src="../../../../../assets/images/credit-cards/card.png" alt="master-card" class="credit-card-icon" [ngClass]="{'disabled': !isCardTypeValid('MasterCard')}">
                        <img src="../../../../../assets/images/credit-cards/american-express.png" alt="amex" class="credit-card-icon" [ngClass]="{'disabled': !isCardTypeValid('Amex')}">
                        <img src="../../../../../assets/images/credit-cards/visa.png" alt="visa" class="credit-card-icon" [ngClass]="{'disabled': !isCardTypeValid('Visa')}">
                      </div>
                    </div>
                    <div class="form-group mb-3">
                      <input type="text" formControlName="nameOnCard" class="form-control" placeholder="Name on card">
                    </div>
                    <div *ngIf="formData.get('nameOnCard')?.touched && formData.get('nameOnCard')?.hasError('required')" class="text-danger">Name on card is required.</div>
                    <div class="form-group mb-3">
                      <input type="text" formControlName="cardNumber" maxlength="16" class="form-control" placeholder="Card number" (input)="onCardNumberChange($event)">
                    </div>
                    <div *ngIf="formData.get('cardNumber')?.touched && formData.get('cardNumber')?.hasError('required')" class="text-danger">Card Number is required.</div>
                    <div *ngIf="formData.get('cardNumber')?.touched && formData.get('cardNumber')?.hasError('pattern')" class="text-danger">Card Number must be numeric.</div>
          
                    <div class="form-group row mb-3">
                      <div class="col-md-6">
                        <select formControlName="expireMonth" class="form-control form-select">
                          <option value="">Expire Month</option>
                          <option *ngFor="let month of expireMonthDropdown" [value]="month">{{ month }}</option>
                        </select>
                        <div *ngIf="formData.get('expireMonth')?.touched && formData.get('expireMonth')?.hasError('required')" class="text-danger">Expire Month is required.</div>
                      </div>
          
                      <div class="col-md-6">
                        <select formControlName="expireYear" class="form-control form-select">
                          <option value="">Expire Year</option>
                          <option *ngFor="let year of expireYearDropdown" [value]="year">{{ year }}</option>
                        </select>
                        <div *ngIf="formData.get('expireYear')?.touched && formData.get('expireYear')?.hasError('required')" class="text-danger">Expire Year is required.</div>
                      </div>
                    </div>
          
                    <div class="form-group mb-3">
                      <input type="password" maxlength="4" formControlName="cardCode" class="form-control" placeholder="CVV">
                    </div>
                    <div *ngIf="formData.get('cardCode')?.touched && formData.get('cardCode')?.hasError('required')" class="text-danger">CVV is required.</div>
          
                    
          

          
                   

                  </form>
                  <form [formGroup]="formData" class="mt-3 custom-form-width"  *ngIf="!useExistingAddress" @fadeInOut>
                    <div class="form-group">
                      <div class="d-flex justify-content-between align-items-center rounded p-3 mb-3 form-background">
                        <h5 class="m-0 text-dark" style="letter-spacing: 2px;">Billing Address </h5>
                      </div>
                      <input 
                        id="address1" 
                        formControlName="address1" 
                        class="form-control" 
                        placeholder="Address Line 1" 
                        [ngClass]="{
                          'is-invalid': formData.get('address1')?.touched && formData.get('address1')?.invalid
                        }"
                      >
                    </div>
                    <div class="form-group">
                      <input 
                        id="address2" 
                        formControlName="address2" 
                        class="form-control" 
                        placeholder="Apartment, suite, etc. (optional)" 
                      >
                    </div>
                    <div class="form-group row">
                      <div class="col-md-6">
                        <input 
                          id="city" 
                          formControlName="city" 
                          class="form-control" 
                          placeholder="City" 
                          [ngClass]="{
                            'is-invalid': formData.get('city')?.touched && formData.get('city')?.invalid
                          }"
                        >
                      </div>
                      <div class="col-md-6">
                        <input 
                          id="state" 
                          formControlName="state" 
                          class="form-control" 
                          placeholder="State" 
                          [ngClass]="{
                            'is-invalid': formData.get('state')?.touched && formData.get('state')?.invalid
                          }"
                        >
                      </div>
                    </div>
                    <div class="form-group">
                      <input 
                        id="zip" 
                        formControlName="zipCode" 
                        class="form-control" 
                        placeholder="ZIP Code" 
                        [ngClass]="{
                          'is-invalid': formData.get('zipCode')?.touched && formData.get('zipCode')?.invalid
                        }"
                      >
                    </div>
        
                    <!-- Extra Fields -->

        

                  </form>
                  <div
                  class="form-check mt-3 d-flex align-items-center justify-content-between px-4 custom-form-width"
                >
                  <!-- Use Existing Address Section -->
                  <div class="d-flex align-items-center">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="useExistingAddress"
                      [checked]="isChecked()"
                      (change)="toggleUseExistingAddress($event)"
                      style="width: 1.25rem; height: 1.25rem;"
                    />
                    <div class="ms-2 d-flex flex-column">
                      <label class="form-check-label" for="useExistingAddress" >
                        Use Existing Address
                      </label>
                      <p
                        class="text-muted mt-1 mb-0"
                        *ngIf="useExistingAddress"
                        style=" white-space: break-spaces; word-break: break-word;"
                      >
                        {{ existingAddress }}
                      </p>
                    </div>
                  </div>
                
                  <!-- Update Auto Pay Section -->
                  <div *ngIf="paymentType === 'Immediate'" class="d-flex align-items-center">
                    <input
                      type="checkbox"
                      id="saveCardCheckbox"
                      formControlName="isUpdateCard"
                      (change)="onUpdateCard($event)"
                      class="form-check-input me-2"
                      style="width: 1.25rem; height: 1.25rem;"
                    />
                    <label for="saveCardCheckbox" class="form-check-label mb-0" >
                      Update Auto Pay
                    </label>
                  </div>
                </div>
                
                  
                  
                  </div>
                  <button type="submit" class="btn submit-button w-100 mt-3 d-flex align-items-center justify-content-center" (click)="onSubmitWithCreditCard()" ><h5>Pay Now</h5></button>
                  
                </div>
              </div>
          
              <div class="col-md-6">
                <div class="card p-4">
                  <h3>Summary</h3>
                  <p class="text-muted">Order Summary and Details</p>
          
                  <ul class="list-group">
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Plan
                      <span>{{ newPlanDetails?.plan?.DisplayName }}</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Data
                      <span>{{ newPlanDetails?.planDetail?.PlanData || "-" }} Data</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Amount
                      <span>$ {{ proratedDetails?.proratedPrice || "-" }}</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center total-summary">
                      <strong>Total Amount</strong>
                      <strong>$ {{ proratedDetails?.proratedPrice || "-" }}</strong>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    
    </div>

  </ng-template>
  

  <!-- Auto-Renew -->
  <ng-template #content let-modal *ngIf="isAutoRenewModelOpen">
    <div class="modal-header">
      <h4 class="modal-title">Auto-Renew Option</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="onCloseButtonClick()"></button>
    </div>
  
    <div class="modal-body">
      <ul class="nav nav-tabs d-flex">
        <li class="nav-item flex-fill">
          <a class="nav-link text-center" [class.active]="activeTab === 'existingCard'" (click)="setActiveTab('existingCard'); getCustomerSubscription()">
            <h5>Existing Card</h5>
          </a>
        </li>
        <li class="nav-item flex-fill">
          <a class="nav-link text-center" [class.active]="activeTab === 'newCard'" (click)="setActiveTab('newCard')">
            <h5>New Card</h5>
          </a>
        </li>
      </ul>
  
      <div class="tab-content mt-3">
        <div *ngIf="activeTab === 'existingCard'">
          <div class="container mt-4" *ngIf="!isRenewSkeletonLoader">
            <div class="row">
              <div class="col-md-12">
                <div class="card p-4">
                  <h3>Auto-Renew</h3>
                  <p class="text-muted">All transactions are secure and encrypted.</p>
  
                  <div class="bg-light p-0 pb-2 rounded">
                    <div class="d-flex justify-content-between align-items-center  rounded px-4 py-2 form-background">
                      <h5 class="m-0 text-dark">Credit card</h5>
                      <div class="credit-card-icons d-flex">
                        <img src="../../../../../assets/images/credit-cards/card.png" alt="master-card" class="credit-card-icon" [ngClass]="{'disabled': !isExistingCardType('MasterCard')}">
                        <img src="../../../../../assets/images/credit-cards/american-express.png" alt="amex" class="credit-card-icon" [ngClass]="{'disabled': !isExistingCardType('Amex')}">
                        <img src="../../../../../assets/images/credit-cards/visa.png" alt="visa" class="credit-card-icon" [ngClass]="{'disabled': !isExistingCardType('Visa')}">
                      </div>
                    </div>
            
                    <form [formGroup]="formDataWithCVV" class="mt-3 custom-form-width-cvv" (ngSubmit)="onSubmitWithCVVAutoRenew()">
                      <!-- Card Code (CVV) Field -->
                      <div class="form-group mb-3">
                        <h5 class="text-dark" style="white-space: nowrap;">
                          Pay with Card Number: <span style="display: inline;" class="text-danger">{{creditCarddetail?.cardNumber}}</span>
                        </h5>
                      </div>
                      <div class="form-group mb-3">
                        <input type="password" maxlength="4" formControlName="cardCode" class="form-control cvv-field" placeholder="CVV">
                        <div *ngIf="formDataWithCVV.get('cardCode')?.touched && formDataWithCVV.get('cardCode')?.hasError('required')" class="text-danger">CVV is required.</div>
                      </div>
                    </form>
                  </div>
  
                  <button type="submit" class="btn submit-button w-100 mt-3 d-flex align-items-center justify-content-center" (click)="onSubmitWithCVVAutoRenew()">
                    <h5>Auto-Renew</h5>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="isRenewSkeletonLoader"  style="max-width: 100%;">
            <app-skeleton-loader shape="payment-form"></app-skeleton-loader>
        </div>
        </div>
  
        <div *ngIf="activeTab === 'newCard'">
          <div class="container mt-4">
            <div class="row">
              <div class="col-md-12">
                <div class="card p-4">
                  <h3>Auto-Renew</h3>
                  <p class="text-muted">All transactions are secure and encrypted.</p>
                  <div class="bg-light p-0 pb-2 rounded">
                    
                    

          
                    <!-- Payment Details -->
                    <form [formGroup]="formDataAutoRenew" class="mt-3 custom-form-width" (ngSubmit)="onSubmitWithCreditCardAutoRenew()">
                      <div class="d-flex justify-content-between align-items-center rounded px-4 py-2 form-background mb-3">
                        <h5 class="m-0 text-dark">Credit card</h5>
                        <div class="credit-card-icons d-flex">
                          <img src="../../../../../assets/images/credit-cards/card.png" alt="master-card" class="credit-card-icon" [ngClass]="{'disabled': !isCardTypeValid('MasterCard')}">
                          <img src="../../../../../assets/images/credit-cards/american-express.png" alt="amex" class="credit-card-icon" [ngClass]="{'disabled': !isCardTypeValid('Amex')}">
                          <img src="../../../../../assets/images/credit-cards/visa.png" alt="visa" class="credit-card-icon" [ngClass]="{'disabled': !isCardTypeValid('Visa')}">
                        </div>
                      </div>
                      <div class="form-group row mb-3">
                        <div class="col-md-6">
                          <input type="text" formControlName="firstName" class="form-control" placeholder="First Name ">
                          <div *ngIf="formDataAutoRenew.get('firstName')?.touched && formDataAutoRenew.get('firstName')?.hasError('required')" class="text-danger">First Name is required.</div>
                        </div>
                        <div class="col-md-6">
                          <input type="text" formControlName="lastName" class="form-control" placeholder="Last Name">
                          <div *ngIf="formDataAutoRenew.get('lastName')?.touched && formDataAutoRenew.get('lastName')?.hasError('required')" class="text-danger">Last Name is required.</div>
                        </div>
                      </div>
          
                      <div class="form-group mb-3">
                        <input type="text" formControlName="cardNumber" maxlength="16" class="form-control" placeholder="Card Number " (input)="onCardNumberChange($event)">
                        <div *ngIf="formDataAutoRenew.get('cardNumber')?.touched && formDataAutoRenew.get('cardNumber')?.hasError('required')" class="text-danger">Card Number is required.</div>
                        <div *ngIf="formDataAutoRenew.get('cardNumber')?.touched && formDataAutoRenew.get('cardNumber')?.hasError('pattern')" class="text-danger">Card Number must be numeric.</div>
                      </div>
          
                      <div class="form-group row mb-3">
                        <div class="col-md-6">
                          <select formControlName="expireMonth" class="form-control form-select">
                            <option value="">Expire Month</option>
                            <option *ngFor="let month of expireMonthDropdown" [value]="month">{{ month }}</option>
                          </select>
                          <div *ngIf="formDataAutoRenew.get('expireMonth')?.touched && formDataAutoRenew.get('expireMonth')?.hasError('required')" class="text-danger">Expire Month is required.</div>
                        </div>
                        <div class="col-md-6">
                          <select formControlName="expireYear" class="form-control form-select">
                            <option value="">Expire Year</option>
                            <option *ngFor="let year of expireYearDropdown" [value]="year">{{ year }}</option>
                          </select>
                          <div *ngIf="formDataAutoRenew.get('expireYear')?.touched && formDataAutoRenew.get('expireYear')?.hasError('required')" class="text-danger">Expire Year is required.</div>
                        </div>
                      </div>
          
                      <div class="form-group mb-3">
                        <input type="password" maxlength="4" formControlName="cardCode" class="form-control cvv-field" placeholder="CVV">
                        <div *ngIf="formDataAutoRenew.get('cardCode')?.touched && formDataAutoRenew.get('cardCode')?.hasError('required')" class="text-danger">CVV is required.</div>
                      </div>
                    </form>

                    <!-- Address Details -->
                    <form [formGroup]="formDataAutoRenew" class="mt-3 custom-form-width" (ngSubmit)="onSubmitWithCreditCardAutoRenew()" *ngIf="!useExistingAddress" @fadeInOut>
                      <div class="form-group">
                        <div class="d-flex justify-content-between align-items-center rounded p-3 mb-3 form-background">
                          <h5 class="m-0 text-dark" style="letter-spacing: 2px;">Address Details</h5>
                        </div>
                        <input 
                          id="address1" 
                          formControlName="address1" 
                          class="form-control" 
                          placeholder="Address Line 1" 
                          [ngClass]="{
                            'is-invalid': formDataAutoRenew.get('address1')?.touched && formDataAutoRenew.get('address1')?.invalid
                          }"
                        >
                      </div>
                      <div class="form-group">
                        <input 
                          id="address2" 
                          formControlName="address2" 
                          class="form-control" 
                          placeholder="Apartment, suite, etc. (optional)" 
                        >
                      </div>
                      <div class="form-group row">
                        <div class="col-md-6">
                          <input 
                            id="city" 
                            formControlName="city" 
                            class="form-control" 
                            placeholder="City" 
                            [ngClass]="{
                              'is-invalid': formDataAutoRenew.get('city')?.touched && formDataAutoRenew.get('city')?.invalid
                            }"
                          >
                        </div>
                        <div class="col-md-6">
                          <input 
                            id="state" 
                            formControlName="state" 
                            class="form-control" 
                            placeholder="State" 
                            [ngClass]="{
                              'is-invalid': formDataAutoRenew.get('state')?.touched && formDataAutoRenew.get('state')?.invalid
                            }"
                          >
                        </div>
                      </div>
                      <div class="form-group">
                        <input 
                          id="zip" 
                          formControlName="zipCode" 
                          class="form-control" 
                          placeholder="ZIP Code" 
                          [ngClass]="{
                            'is-invalid': formDataAutoRenew.get('zipCode')?.touched && formDataAutoRenew.get('zipCode')?.invalid
                          }"
                        >
                      </div>
          
                      <!-- Extra Fields -->

          

                    </form>
                    <div
                    class="form-check mt-3 d-flex align-items-center justify-content-between px-4 custom-form-width" @fadeInOut
                  >
                    <!-- Use Existing Address Section -->
                    <div class="d-flex align-items-center">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="useExistingAddress"
                        [checked]="isChecked()"
                        (change)="toggleUseExistingAddress($event)"
                        style="width: 1.25rem; height: 1.25rem;"
                      />
                      <div class="ms-2 d-flex flex-column">
                        <label class="form-check-label" for="useExistingAddress" >
                          Use Existing Address
                        </label>
                        <p
                          class="text-muted mt-1 mb-0"
                          *ngIf="useExistingAddress"
                          style=" white-space: break-spaces; word-break: break-word;"
                        >
                          {{ existingAddress }}
                        </p>
                      </div>
                    </div>
                  
                    
                  </div>
                  </div>
                  <button type="submit" class="btn submit-button w-100 mt-3 d-flex align-items-center justify-content-center" (click)="onSubmitWithCreditCardAutoRenew()">
                    <h5>Update Auto-Renewal Card</h5>
                  </button>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
    </div>

  </ng-template>
  