<div class="customizer-contain " [ngClass]="{ open: showStepper === true }">
  <div class="tab-content" id="c-pills-tabContentAgent">
    <div class="customizer-header sticky-header">
      <h5 class="mb-0 text-nowrap">
        {{ existingData?.id ? 'Subscription Details' : 'Add Subscription' }}
      </h5>
      <div class="d-flex flex-row col-md-9 justify-content-end gap-2 align-items-center m-2">
        <div>
          <button class="custom-btn" (click)="openHistoryPage('Payment')">Payment History</button>
        </div>
        <div>
          <button class="custom-btn" (click)="openHistoryPage('Invoice')">Invoice History</button>
        </div>
        <i class="fa fa-times text-success" style="font-size: 20px;" (click)="hideStepper()"></i>
      </div>

    </div>
    <div class="scrollable-content">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-12 px-4">
            <div class="job-description">

              <div class="card mt-3 info-card">
                <div class="card-header py-2 px-3">
                  <h5 class="mb-2">Payment Methods</h5>
                </div>
                <div class="card-body mt-0 p-2 info-card" *ngIf="!isSkeletonLoader;" @fadeInOut>
                  <div class="payment-card d-flex align-items-start flex-wrap">
                    <!-- <img src="../../../assets/images/payment-card.png" alt=""
                      *ngIf="subscriptionDetail?.subscriptionDetail?.AccountType=='American Express'">
                    <img src="../../../assets/images/credit-card-types/Discover.jpg" alt=""
                      *ngIf="subscriptionDetail?.subscriptionDetail?.AccountType=='Discover'">
                    <img src="../../../assets/images/credit-card-types/Visa-card.jpeg" alt=""
                      *ngIf="subscriptionDetail?.subscriptionDetail?.AccountType=='Visa'" style="margin-top: 1rem;">
                    <img src="../../../assets/images/credit-card-types/Mastercard.jpeg" alt=""
                      *ngIf="subscriptionDetail?.subscriptionDetail?.AccountType=='MasterCard'"
                      style="margin-top: 1rem;">
                    <img src="../../../assets/images/credit-card-types/echeck.png" alt=""
                      *ngIf="subscriptionDetail?.subscriptionDetail?.AccountType=='eCheck'">
                    <img src="../../../assets/images/payment-card.png" alt=""
                      *ngIf="!subscriptionDetail?.subscriptionDetail?.AccountType">

                    <div>
                      <p class="mb-0 font-weight-bold mt-1">{{ subscriptionDetail?.subscriptionDetail?.AccountType ??
                        'American Express' }}</p>
                      <span class="bottom-cardText">ending in
                        {{this.subscriptionDetail?.authorizeSubscriptionDetail?.profile?.paymentProfile?.payment?.creditCard?.cardNumber}}</span><br>
                      <span class="bottom-cardText">expire in
                        {{this.subscriptionDetail?.authorizeSubscriptionDetail?.profile?.paymentProfile?.payment?.creditCard?.expirationDate}}</span>

                    </div> -->
                            <div class="card-container">
                      <!-- Visa Card -->
                      <div *ngIf="subscriptionDetail?.subscriptionDetail?.AccountType === 'Visa'" class="cardvisa visa-card">
                        <img src="https://upload.wikimedia.org/wikipedia/commons/4/41/Visa_Logo.png" alt="Visa"
                          class="card-logo" />
                          <p class="mb-0 font-weight-bold mt-0">
                            {{ subscriptionDetail.subscriptionDetail.AccountType }}
                          </p>
                          <span class="bottom-cardText">
                            {{
                            subscriptionDetail.authorizeSubscriptionDetail.profile.paymentProfile.payment.creditCard.cardNumber
                            }}
                          </span>
                          <br />
                          <div class="d-flex justify-content-between align-items-center">
                            <small class="bottom-cardTet">
                              Name
                              <p class="my-0">
                                {{ subscriptionDetail?.subscriptionDetail?.customer?.FirstName }} {{
                                subscriptionDetail?.subscriptionDetail?.customer?.LastName }}
                              </p>
                            </small>
                            <small class="bottom-cardTet">
                              Expire Date
                              <p class="my-0"> {{
                                subscriptionDetail?.authorizeSubscriptionDetail?.profile?.paymentProfile?.payment?.creditCard?.expirationDate
                                }}</p>
                            </small>
                      </div>
                    </div>
                      <!-- MasterCard -->
                      <div *ngIf="subscriptionDetail?.subscriptionDetail?.AccountType=='MasterCard'"
                        class="cardvisa mastercard-card">
                        <img src="https://upload.wikimedia.org/wikipedia/commons/2/2a/Mastercard-logo.svg" alt="MasterCard"
                          class="card-logo" />
                        <p class="mb-0 font-weight-bold mt-0">
                          {{ subscriptionDetail.subscriptionDetail.AccountType }}
                        </p>
                        <span class="bottom-cardText">
                          {{
                          subscriptionDetail.authorizeSubscriptionDetail.profile.paymentProfile.payment.creditCard.cardNumber
                          }}
                        </span>
                        <br />
                        <div class="d-flex justify-content-between align-items-center">
                          <small class="bottom-cardTet">
                            Name
                            <p class="my-0">
                              {{ subscriptionDetail?.subscriptionDetail?.customer?.FirstName }} {{
                              subscriptionDetail?.subscriptionDetail?.customer?.LastName }}
                            </p>
                          </small>
                          <small class="bottom-cardTet">
                            Expire Date
                            <p class="my-0"> {{
                              subscriptionDetail?.authorizeSubscriptionDetail?.profile?.paymentProfile?.payment?.creditCard?.expirationDate
                              }}</p>
                          </small>
                        </div>
      
                      </div>
      
                      <!-- American Express -->
                      <div *ngIf="subscriptionDetail?.subscriptionDetail?.AccountType==''" class="cardvisa amex-card">
                        <img
                          src="https://e7.pngegg.com/pngimages/745/624/png-clipart-american-express-logo-credit-card-payment-credit-card-blue-text.png"
                          alt="American Express" class="card-logo" />
                        <p class="mb-0 font-weight-bold mt-0">
                          {{ subscriptionDetail.subscriptionDetail.AccountType }}
                        </p>
                        <div class="d-flex justify-content-between align-items-center">
                          <small class="bottom-cardTet">
                            Name
                            <p class="my-0">
                              {{ subscriptionDetail?.subscriptionDetail?.customer?.FirstName }} {{
                              subscriptionDetail?.subscriptionDetail?.customer?.LastName }}
                            </p>
                          </small>
                          <small class="bottom-cardTet">
                            Expire Date
                            <p class="my-0"> {{
                              subscriptionDetail?.authorizeSubscriptionDetail?.profile?.paymentProfile?.payment?.creditCard?.expirationDate
                              }}</p>
                          </small>
                        </div>
                      </div>
      
                      <!-- Discover -->
                      <div *ngIf="subscriptionDetail?.subscriptionDetail?.AccountType === 'Discover'"
                        class="cardvisa discover-card">
                        <img src="https://upload.wikimedia.org/wikipedia/commons/3/3f/Discover_Card_logo.svg" alt="Discover"
                          class="card-logo" />
                        <p class="mb-0 font-weight-bold mt-0">
                          {{ subscriptionDetail.subscriptionDetail.AccountType }}
                        </p>
                        <div class="d-flex justify-content-between align-items-center">
                          <small class="bottom-cardTet">
                            Name
                            <p class="my-0">
                              {{ subscriptionDetail?.subscriptionDetail?.customer?.FirstName }} {{
                              subscriptionDetail?.subscriptionDetail?.customer?.LastName }}
                            </p>
                          </small>
                          <small class="bottom-cardTet">
                            Expire Date
                            <p class="my-0"> {{
                              subscriptionDetail?.authorizeSubscriptionDetail?.profile?.paymentProfile?.payment?.creditCard?.expirationDate
                              }}</p>
                          </small>
                        </div>
                      </div>
      
                      <!-- eCheck -->
                      <div *ngIf="subscriptionDetail?.subscriptionDetail?.AccountType === 'eCheck'"
                        class="cardvisa echeck-card">
                        <img src="https://upload.wikimedia.org/wikipedia/commons/e/e8/Check_mark.svg" alt="eCheck"
                          class="card-logo" />
                        <p class="mb-0 font-weight-bold mt-0">
                          {{ subscriptionDetail.subscriptionDetail.AccountType }}
                        </p>
                        <div class="d-flex justify-content-between align-items-center">
                          <small class="bottom-cardTet">
                            Name
                            <p class="my-0">
                              {{ subscriptionDetail?.subscriptionDetail?.customer?.FirstName }} {{
                              subscriptionDetail?.subscriptionDetail?.customer?.LastName }}
                            </p>
                          </small>
                          <small class="bottom-cardTet">
                            Expire Date
                            <p class="my-0"> {{
                              subscriptionDetail?.authorizeSubscriptionDetail?.profile?.paymentProfile?.payment?.creditCard?.expirationDate
                              }}</p>
                          </small>
                        </div>
                      </div>
                    </div>
                    <span class="badge badge-light-primary mx-2 mt-1">Primary</span>
                    <div class="mx-2 mt-1">
                      <p style="margin-bottom: 2px;margin-left: 2rem;">Auto Renew</p>
                      <div class="media">
                        <div class="media-body text-end icon-state">
                          <label class="switch">
                            <input type="checkbox" [(ngModel)]="AutoRenew" (change)="autoRenew($event)">
                            <span class="switch-state" [ngClass]="AutoRenew ? 'bg-secondary' : 'bg-renew'"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="p-2 d-flex align-items-center cursor-pointer" (click)="updatePaymentMethod()">
                    <i class="fa fa-plus-circle"></i>
                    <p class="mb-0 mx-2">Add payment method</p>
                  </div>
                </div>
                <div *ngIf="isSkeletonLoader;" @fadeInOut style="max-width: 100%;">
                  <app-skeleton-loader shape="credit-card"></app-skeleton-loader>
                </div>
              </div>
        

              <!-- New Card UI -->
              <div class="card mt-3 p-3">
                <div class="card-header d-flex justify-content-between  py-1 px-0 border-bottom mb-2">
                  <h5 class="mb-0">Subscription Info</h5>
                  <div class="col-md-6 text-md-end"
                  *ngIf="this.subscriptionDetail?.subscriptionDetail?.Status !== 'Cancelled'">
                  <div class="d-flex align-items-start gap-2 justify-content-md-end">
                    <p class="fw-bold mb-0">Cancel Subscription</p>
                    <label class="switch">
                      <input type="checkbox" checked [(ngModel)]="isCancelled" (change)="withConfirmation()">
                      <span class="switch-state bg-secondary"></span>
                    </label>
                  </div>
                </div>
                </div>
                <div class="row align-items-center mb-3">
                  <!-- Plan Image and Details -->
                  <div class="col-md-6 d-flex gap-3">
                    <div class="plan-image">
                      <img [src]="this.subscriptionDetail?.subscriptionDetail?.plan?.ImagePath" class="img-100">
                      <h4 class="price text-nowrap mb-0 text-primary"
                        *ngIf="this.subscriptionDetail?.subscriptionDetail?.Status == 'Cancelled'"><span>Amount
                          :</span> ${{ this.subscriptionDetail?.authorizeSubscriptionDetail?.amount }}</h4>
                    </div>
                    <div class="plan-details">
                      <h2 class="text-nowrap">{{ this.subscriptionDetail?.subscriptionDetail?.plan?.DisplayName }}</h2>
                      <p class="price text-nowrap mb-0 text-primary"
                        *ngIf="this.subscriptionDetail?.subscriptionDetail?.Status !== 'Cancelled'">Amount : ${{
                        this.subscriptionDetail?.authorizeSubscriptionDetail?.amount }}</p>


                    </div>

                  </div>
                  <!-- Cancel Subscription Toggle -->
              
                </div>

                <!-- General and Financial Information -->
                <div class="row">
                  <!-- General Information -->
                  <div class="col-md-6 gernal border-md-end mb-3 mb-md-0">
                    <h4>General</h4>
                    <p><strong>First Name:</strong> {{ this.subscriptionDetail?.subscriptionDetail?.customer?.FirstName
                      }}</p>
                    <p><strong>Last Name:</strong> {{ this.subscriptionDetail?.subscriptionDetail?.customer?.LastName }}
                    </p>
                    <p><strong>Email:</strong> {{ this.subscriptionDetail?.subscriptionDetail?.Email }}</p>
                    <p><strong>Customer ID:</strong> {{ this.subscriptionDetail?.subscriptionDetail?.customer?.id }}</p>
                    <p><strong>Plan Description:</strong> {{
                      this.subscriptionDetail?.subscriptionDetail?.plan?.Description }}</p>
                  </div>
                  <!-- Financial Information -->
                  <div class="col-md-6 status-plan">
                    <h4>Plan Status</h4>
                    <p class="d-flex align-items-center">
                      <strong>Status:</strong>
                      <span class="ms-2">
                        <span *ngIf="this.subscriptionDetail?.subscriptionDetail?.Status === 'Active'"
                          class="badge bg-primary">
                          {{ this.subscriptionDetail?.subscriptionDetail?.Status }}
                        </span>
                        <span *ngIf="this.subscriptionDetail?.subscriptionDetail?.Status === 'Cancelled'"
                          class="badge bg-danger">
                          {{ this.subscriptionDetail?.subscriptionDetail?.Status }}
                        </span>
                        <span *ngIf="this.subscriptionDetail?.subscriptionDetail?.Status === 'Pending'"
                          class="badge bg-warning">
                          {{ this.subscriptionDetail?.subscriptionDetail?.Status }}
                        </span>
                        <span *ngIf="this.subscriptionDetail?.subscriptionDetail?.Status === 'Delete'"
                          class="badge bg-secondary">
                          {{ this.subscriptionDetail?.subscriptionDetail?.Status }}
                        </span>
                        <span *ngIf="this.subscriptionDetail?.subscriptionDetail?.Status === 'Success'"
                          class="badge bg-success">
                          {{ this.subscriptionDetail?.subscriptionDetail?.Status }}
                        </span>
                      </span>
                    </p>
                    <p><strong>Start Date:</strong> {{ this.subscriptionDetail?.subscriptionDetail?.StartDate | date:
                      'short' }}</p>
                    <p><strong>Renew Date:</strong> {{ this.subscriptionDetail?.subscriptionDetail?.NextBillingDate |
                      date: 'short' }}</p>
                    <p *ngIf="this.subscriptionDetail?.subscriptionDetail?.EndDate">
                      <strong>End Date:</strong> {{ this.subscriptionDetail?.subscriptionDetail?.EndDate | date: 'short'
                      }}
                    </p>
                    <p><strong>Updated Date:</strong> {{ this.subscriptionDetail?.subscriptionDetail?.UpdatedDate |
                      date: 'short' }}</p>
                  </div>
                </div>
              </div>

              <!-- <div class="card-cstm-style mt-3 info-card">
                <h5 class="mb-2 mt-2">Subscription Info</h5>
                <div class="subscription-info" *ngIf="!isSkeletonLoader;"  @fadeInOut>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="mb-3 avatar">
                        <label for="exampleFormControlInput5">Plan Image</label>
                        <div class="avatars">
                          <div class="avatar">
                            <img [src]="this.subscriptionDetail?.subscriptionDetail?.plan?.ImagePath" class="img-100">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3 d-flex gap-2">
                        <label for="exampleFormControlInput5">Plan Name :</label>
                        <p>{{this.subscriptionDetail?.subscriptionDetail?.plan?.DisplayName}}</p>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3 d-flex gap-2">
                        <label for="exampleFormControlInput5">Plan Description :</label>
                        <p>{{this.subscriptionDetail?.subscriptionDetail?.plan?.Description}}</p>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3 d-flex gap-2">
                        <label for="exampleFormControlInput5" class="p-r-10">Plan Status :</label><br>
                        <p>
                          <span class="badge badge-light-primary mx-2 mt-1"
                            *ngIf="this.subscriptionDetail?.subscriptionDetail?.Status=='Active'">{{this.subscriptionDetail?.subscriptionDetail?.Status}}</span>
                          <span class="badge badge-light-danger mx-2 mt-1"
                            *ngIf="this.subscriptionDetail?.subscriptionDetail?.Status=='Cancelled'">{{this.subscriptionDetail?.subscriptionDetail?.Status}}</span>
                          <span class="badge badge-light-warning mx-2 mt-1"
                            *ngIf="this.subscriptionDetail?.subscriptionDetail?.Status=='Pending'">{{this.subscriptionDetail?.subscriptionDetail?.Status}}</span>
                          <span class="badge badge-light-secondary mx-2 mt-1"
                            *ngIf="this.subscriptionDetail?.subscriptionDetail?.Status=='Delete'">{{this.subscriptionDetail?.subscriptionDetail?.Status}}</span>
                          <span class="badge badge-light-success mx-2 mt-1"
                            *ngIf="this.subscriptionDetail?.subscriptionDetail?.Status=='Success'">{{this.subscriptionDetail?.subscriptionDetail?.Status}}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="row">
  
                    <div class="col-md-6">
                      <div class="mb-3 d-flex gap-2">
                        <label for="exampleFormControlInput5">Price :</label>
                        <p>{{this.subscriptionDetail?.authorizeSubscriptionDetail?.amount}}</p>
                      </div>
                    </div>
  
                    <div class="col-md-6" *ngIf="this.subscriptionDetail?.subscriptionDetail?.Status !=='Cancelled'">
                      <div class="mb-3 d-flex gap-2">
                        <p style="margin-bottom: 2px;
                        ">Cancel Subscription</p>
                        <div class="media">
                          <div class="text-end icon-state">
                            <label class="switch">
                              <input type="checkbox" checked="" [(ngModel)]="isCancelled"
                                (change)="withConfirmation()"><span class="switch-state bg-secondary"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
  
                    <div class="col-md-6">
                      <div class="mb-3 d-flex gap-2">
                        <label for="exampleFormControlInput1">Customer Id :</label>
                        <p>{{this.subscriptionDetail?.subscriptionDetail?.customer?.id}}</p>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3 d-flex gap-2">
                        <label for="exampleFormControlInput1">First Name :</label>
                        <p>{{this.subscriptionDetail?.subscriptionDetail?.customer?.FirstName}}</p>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3 d-flex gap-2">
                        <label for="exampleFormControlInput1">Last Name :</label>
                        <p>{{this.subscriptionDetail?.subscriptionDetail?.customer?.LastName}}</p>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3 d-flex gap-2">
                        <label for="exampleFormControlInput3">Email :</label>
                        <p>{{this.subscriptionDetail?.subscriptionDetail?.Email}}</p>
                      </div>
                    </div>
  
                    <div class="col-md-6">
                      <div class="mb-3 d-flex gap-2">
                        <label for="exampleFormControlInput5">Start Date :</label>
                        <p>{{this.subscriptionDetail?.subscriptionDetail?.StartDate | date : 'short'}}</p>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3 d-flex gap-2">
                        <label for="exampleFormControlInput5">Renew Date:</label>
                        <p>{{this.subscriptionDetail?.subscriptionDetail?.NextBillingDate| date : 'short'}}</p>
                      </div>
                    </div>
                    <div class="col-md-6" *ngIf="this.subscriptionDetail?.subscriptionDetail?.EndDate">
                      <div class="mb-3 d-flex gap-2">
                        <label for="exampleFormControlInput5">End Date :</label>
                        <p>{{this.subscriptionDetail?.subscriptionDetail?.EndDate | date : 'short'}}</p>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3 d-flex gap-2">
                        <label for="exampleFormControlInput5">Updated Date :</label>
                        <p>{{this.subscriptionDetail?.subscriptionDetail?.UpdatedDate | date : 'short'}}</p>
                      </div>
                    </div>
  
                  </div>
                </div>
                <div *ngIf="isSkeletonLoader;"  @fadeInOut style="max-width: 100%;">
                  <app-skeleton-loader  shape="subscription-info"></app-skeleton-loader>
                </div>
              </div> -->
              <div class="card shadow-sm mt-3 mb-5" *ngIf="!isSkeletonLoader;" @fadeInOut>
                <div class="card-header d-flex justify-content-between py-2 border-bottom">
                  <h5>Address Info</h5>
                  <div>
                    <button class="custom-btn" (click)="updatePaymentMethod(true)">Update Address</button>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <!-- Address -->
                    <div class="col-md-6 mb-3" *ngIf="addressData?.address">
                      <div class="d-flex gap-2">
                        <label class="form-label fw-bold">Address:</label>
                        <p class="mb-0 mt-0">{{ addressData?.address }}</p>
                      </div>
                    </div>

                    <!-- City -->
                    <div class="col-md-6 mb-3" *ngIf="addressData?.city">
                      <div class="d-flex gap-2">
                        <label class="form-label fw-bold">City:</label>
                        <p class="mb-0 mt-0">{{ addressData?.city }}</p>
                      </div>
                    </div>

                    <!-- State -->
                    <div class="col-md-6 mb-3" *ngIf="addressData?.state">
                      <div class="d-flex gap-2">
                        <label class="form-label fw-bold">State:</label>
                        <p class="mb-0 mt-0">{{ addressData?.state }}</p>
                      </div>
                    </div>

                    <!-- Zip -->
                    <div class="col-md-6 mb-3" *ngIf="addressData?.zip">
                      <div class="d-flex gap-2">
                        <label class="form-label fw-bold">Zip:</label>
                        <p class="mb-0 mt-0">{{ addressData?.zip }}</p>
                      </div>
                    </div>

                    <!-- Country -->
                    <div class="col-md-6 mb-3" *ngIf="addressData?.country">
                      <div class="d-flex gap-2">
                        <label class="form-label fw-bold">Country:</label>
                        <p class="mb-0 mt-0">{{ addressData?.country }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="isSkeletonLoader;" @fadeInOut style="max-width: 100%;">
                <strong class="mb-2 mt-2">Address Info</strong>
                <app-skeleton-loader shape="address-info"></app-skeleton-loader>
              </div>
              <!-- <div class="fixed-buttons" *ngIf="sticyFooter">
                <div class="d-flex flex-row justify-content-evenly align-items-center m-2">
                  <div class="d-flex justify-content-center align-items-center" >
                    <button class="btn btn-success" (click)="openHistoryPage('Payment')">Payment History</button>
                  </div>
                    <div class="d-flex justify-content-center align-items-center ">
                        <button class="btn btn-secondary" (click)="openHistoryPage('Invoice')">Invoice History</button>
                     </div> 
                    
                </div>
                
              </div> -->
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

</div>


<div *ngIf="showHisotoryPage">
  <app-payment-history [subscriptionDetail]="subscriptionDetail"
    [displayPaymentHistory]="showHistoryDetail==='Payment'? true :false"></app-payment-history>
</div>
<ng-container *ngIf="isAutoRenewModelOpen">
  <app-plan-change-payment #childComponent [isAutoRenewModelOpen]="isAutoRenewModelOpen"
    (isAutoRenewModelOpenChange)="onIsAutoRenewModelOpenChange($event)"
    (submitCVVautoRenew)="getsubmitCVVautoRenew($event)"
    (submitCreditCardAutoRenew)="getsubmitCreditCardAutoRenew($event)"
    (isModalClosedByX)="handleModalClosedByX($event)">
  </app-plan-change-payment>
</ng-container>