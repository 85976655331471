
<div class="table-responsive table-hover table-striped table-card">
    <table class="table table-nowrap mb-0" >
<div class="table-responsive table-hover table-striped table-card">
    <table class="table table-nowrap mb-0">
        <thead class="table-light">
            <tr  class="header-row">
                <th *ngFor="let header of headers; let i = index" class="text-nowrap">
                    {{ header.display }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of data; let i = index" class="cursor-pointer">
                <td *ngFor="let header of headers">
                    <ng-container [ngSwitch]="header.key">
                        <!-- For 'Invoice_Created_Date' key, apply the date pipe -->
                        <span *ngSwitchCase="'Invoice_Created_Date'" class="text-nowrap">
                          {{ item[header.key] | date }}
                        </span>
                      
                        <!-- For other keys, display the value without the date pipe -->
                        <span *ngSwitchDefault class="text-nowrap">
                          {{ item[header.key] }}
                        </span>
                      </ng-container>
                </td>
            </tr>
        </tbody>
    </table>
    <div class="d-flex justify-content-center p-3" *ngIf="data?.length==0">
        <span class="text-muted">
            No data found
        </span>
    </div>
</div>

<div class="custom-datatable table-responsive" *ngIf="!isSkeletonLoader" @fadeInOut>

    <!-- <ag-grid-angular style="height:500px;" class="ag-theme-alpine" [rowClass]="'log-table-class'"
        (gridReady)="onGridLogReady($event)" [rowData]="data" [suppressRowHoverHighlight]="true"
        [columnHoverHighlight]="true" [animateRows]="true" [pagination]="true" [rowHeight]="55"
        [columnDefs]="invoiceTableColumn">
    </ag-grid-angular> -->


    <div class="pull-right p-20 d-flex">
        <select class="form-control me-2 page-dropdown" [(ngModel)]="itemsPerPage" (change)="onChangeNumberOfPage()">
            <option *ngFor="let page of pageDropdownMenu" [value]="page">{{page}}</option>
        </select>
        <ngb-pagination [maxSize]="10" [directionLinks]="true" [boundaryLinks]="true" [collectionSize]="totalItems"
            [(page)]="currentPage" (pageChange)="pageChange($event)" [pageSize]="itemsPerPage"><ng-template
                ngbPaginationPrevious>Previous</ng-template>
            <ng-template ngbPaginationNext>Next</ng-template></ngb-pagination>
    </div>
</div>
<div *ngIf="isSkeletonLoader" @fadeInOut style="max-width: 100%;">
    <app-skeleton-loader [tableRow]="8" [tableColumn]="6"  shape="table"></app-skeleton-loader>
</div>