import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdhocStateService {
  private selectedPlanSubject = new BehaviorSubject<any>(null);
  selectedPlan$ = this.selectedPlanSubject.asObservable();

  private isValidSubject = new BehaviorSubject<boolean>(false);
  isValid$ = this.isValidSubject.asObservable();

   isMailSentSubject = new BehaviorSubject<boolean>(false);
   isPaymentSuccessfulSubject = new BehaviorSubject<boolean>(false);

  isMailSent$ = this.isMailSentSubject.asObservable();
  isPaymentSuccessful$ = this.isPaymentSuccessfulSubject.asObservable();

  private activeTabSubject = new BehaviorSubject<number>(0);
  activeTab$ = this.activeTabSubject.asObservable();
  // Combined Observable
  isTransitionToFinish$ = combineLatest([this.isMailSent$, this.isPaymentSuccessful$]).pipe(
    map(([isMailSent, isPaymentSuccessful]) => isMailSent || isPaymentSuccessful)
  );

  setActiveTab(tab: number): void {
    this.activeTabSubject.next(tab); // Emit the new activeTab value
  }
  setMailSentStatus(status: boolean): void {
    this.isMailSentSubject.next(status);
  }

  setPaymentSuccessfulStatus(status: boolean): void {
    this.isPaymentSuccessfulSubject.next(status);
  }
  setSelectedPlan(plan: any): void {
    this.selectedPlanSubject.next(plan);
    this.setValidationState(!!plan); // Validation depends on plan selection
  }

   setValidationState(isValid: boolean): void {
    this.isValidSubject.next(isValid);
  }
}
