import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CustomerProfileService } from 'src/app/shared/services/customer-profile.service';
import { CustomerDetailedInformationService } from '../shared/customer-detailed-information.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { fadeInOut } from 'src/app/shared/services/app.animation';

@Component({
  selector: 'app-application-information',
  templateUrl: './application-information.component.html',
  styleUrls: ['./application-information.component.scss'],
  animations:[fadeInOut]
})
export class ApplicationInformationComponent implements OnInit {

@ViewChild('userDropdown') userDropdown: NgbDropdown | undefined;
hideMailButton: boolean = false;
  editingControl: string;
isEditable:boolean;

isLoading: any;
isSkeletonLoader: boolean=true;
sendMail() {
throw new Error('Method not implemented.');
}

  applicationInfoFormData:any
  applicationInfodata:any
  formFields: any[] = [];
  editFieldName: string;
  editingFields: Set<string> = new Set<string>();

  constructor(
    private customerProfileService:CustomerProfileService,
    private formBuilder:FormBuilder,
    private customerDetailedInformationService:CustomerDetailedInformationService,
    private commonService:CommonService
  ){
    this.initializeApplicationInfoForm()
  }
  ngOnInit(): void {
    this.getApplicationInfoData()
  }

 async  getApplicationInfoData(){
    try {
      this.isLoading=true
      const response: any = await this.customerProfileService.getCustomerInfoCardData();
      if(response?.Status){
        this.applicationInfodata=response?.data?.customerlInfo;
        await this.buildFormFields();
        this.patchFormData();
        this.isSkeletonLoader=false
      }else{
        console.log('error',response?.message);
      }
    } catch (error) {
      console.log('error',error?.message);
      
    } finally{
      this.isLoading=false
    }
  }

  initializeApplicationInfoForm() {
    this.applicationInfoFormData = this.formBuilder.group({
      userName: [{ value: '', disabled: false }, Validators.required],
      enrollmentId: [{ value: '', disabled: false }, Validators.required],
      orderType: [{ value: '', disabled: false }, Validators.required],
      approvaldate: [{ value: '', disabled: false }, [Validators.required, Validators.email]],
      disconnectionDate: [{ value: '', disabled: false }, Validators.required],
      approvalBy: [{ value: '', disabled: false }, Validators.required],
      acpStartDate: [{ value: '', disabled: false }, Validators.required],
      shipmentStatus: [{ value: '', disabled: false }, Validators.required],
      trackingNumber: [{ value: '', disabled: false }, Validators.required],
      program: [{ value: '', disabled: false }, Validators.required],
      source: [{ value: '', disabled: false }, Validators.required],

   
    })
  }

    
  patchFormData() {
    
    this.applicationInfoFormData.patchValue({
      userName: this.applicationInfodata.userName.value,
      enrollmentId:  this.applicationInfodata.enrollId.value ,
      orderType: this.applicationInfodata.orderType.value ,
      approvaldate: this.customerDetailedInformationService.formatDate(this.applicationInfodata.approvalDate.value)  ,
      disconnectionDate:this.customerDetailedInformationService.formatDate(this.applicationInfodata.disconnectionDate.value) ,
      approvalBy: this.applicationInfodata.approvedBy.value,
      acpStartDate: this.customerDetailedInformationService.formatDate(this.applicationInfodata.acpStartDate.value)  ,
      shipmentStatus: this.applicationInfodata.shipmentStatus.value,
      trackingNumber: this.applicationInfodata.shiprackingNo.value,
      program: this.applicationInfodata.programs.value[0]?.name,
      source: this.applicationInfodata.source.value,
    }); 
  
}
buildFormFields() {
  this.formFields = [
    { controlName: 'userName', label: 'User Name', editable: this.applicationInfodata?.userName?.editable, visible: this.applicationInfodata?.userName?.visible },
    { controlName: 'enrollmentId', label: 'Enrollment Id', editable: this.applicationInfodata?.enrollId?.editable, visible: this.applicationInfodata?.enrollId?.visible },
    { controlName: 'orderType', label: 'Order Type', editable: this.applicationInfodata?.orderType?.editable, visible: this.applicationInfodata?.orderType?.visible },
    { controlName: 'approvaldate', label: 'Approval date', editable: this.applicationInfodata?.approvalDate?.editable, visible: this.applicationInfodata?.approvalDate?.visible },
    { controlName: 'disconnectionDate', label: 'Disconnection Date', editable: this.applicationInfodata?.disconnectionDate?.editable, visible: this.applicationInfodata?.disconnectionDate?.visible },
    { controlName: 'approvalBy', label: 'Approved By', editable: this.applicationInfodata?.approvedBy?.editable, visible: this.applicationInfodata?.approvedBy?.visible },
    { controlName: 'acpStartDate', label: 'ACP Start Date', editable: this.applicationInfodata?.acpStartDate?.editable, visible: this.applicationInfodata?.acpStartDate?.visible },
    { controlName: 'shipmentStatus', label: 'Shipment Status', editable: this.applicationInfodata?.shipmentStatus?.editable, visible: this.applicationInfodata?.shipmentStatus?.visible },
    { controlName: 'trackingNumber', label: 'Tracking Number', editable: this.applicationInfodata?.shiprackingNo?.editable, visible: this.applicationInfodata?.shiprackingNo?.visible },
    { controlName: 'program', label: 'Program', editable: this.applicationInfodata?.programs?.editable, visible: this.applicationInfodata?.programs?.visible },
    { controlName: 'source', label: 'Source', editable: this.applicationInfodata?.source?.editable, visible: this.applicationInfodata?.source?.visible },
  ];
}

enableEdit(controlName: string) {
  const control = this.applicationInfoFormData.get(controlName);
  if (control) {
    control.enable(); 
  }
}

removeInputField(controlName: string) {
  const field = this.formFields.find(f => f.controlName === controlName);

  if (field) {
    this.editingFields.delete(controlName);
    const formatValue = (fieldName: string, value: any): any => {
      if (fieldName === 'approvaldate' && value) {
        return this.customerDetailedInformationService.formatDate(this.applicationInfodata?.approvalDate?.value);
      } else if (fieldName === 'disconnectionDate' && value) {
        return this.customerDetailedInformationService.formatDate(this.applicationInfodata?.disconnectionDate?.value);
      } else if (fieldName === 'acpStartDate' && value) {
        return this.customerDetailedInformationService.formatDate(this.applicationInfodata?.acpStartDate?.value);
      }
      return value || 'Not Available';
    };
    let originalValue: any;

    switch (controlName) {
      case 'program':
        originalValue = this.applicationInfodata.programs?.value[0]?.name;
        break;
      case 'enrollmentId':
        originalValue = this.applicationInfodata.enrollId?.value;
        break;
      case 'approvalBy':
        originalValue = this.applicationInfodata.approvedBy?.value;
        break;
      case 'trackingNumber':
        originalValue = this.applicationInfodata.shiprackingNo?.value;
        break;
      default:
        const fieldValue = this.applicationInfodata[controlName]?.value;
        originalValue = fieldValue !== undefined ? fieldValue : this.applicationInfoFormData?.get(controlName)?.value;
        break;
    }

    const formattedValue = formatValue(controlName, originalValue);
    this.applicationInfoFormData.get(controlName)?.setValue(formattedValue);
  }
}



async onEditIconClick(editFieldName: string) {
  this.editFieldName = editFieldName;
  
}

async customerProfileUpdate(editFieldName:string){
  try {
    if(editFieldName=='userName'){
      let data={
        userName:this.applicationInfoFormData.get(editFieldName).value
      }
      const response: any = await this.customerProfileService.updateCustomerProfile(data);
         if(response?.Status){
             this.editFieldName=""
         }else{
            this.commonService.showToast('error','Error','Unable to update the username')
        }
    }
  } catch (error) {
    
  }
}

updateCustomerProfile(controlName: string) {
  let isSuccess = false;  
  let errorMessage = '';  

  const data: any = {};
  data[controlName] = this.applicationInfoFormData.get(controlName)?.value;
  this.customerProfileService.updateCustomerProfile(data)
    .then((res: any) => {

      if (res?.Status) {
        isSuccess = true;
        this.editFieldName = '';
        this.customerProfileService.getMinimalProfileData();
        this.getApplicationInfoData();
        this.removeInputField(controlName)
      } else {
        errorMessage = res?.message || 'Failed to update profile';
      }
    })
    .finally(() => {
      if (isSuccess) {
        this.commonService.showToast('success', 'Success', `${this.customerDetailedInformationService.formatKeyLabel(controlName)} updated successfully`);
      } else {
        this.commonService.showToast('error', 'Error', errorMessage || `An error occurred while updating the ${controlName}`);
        this.removeInputField(controlName)
      }
    });
}

async onSendEmailClick() {
  const resminmal: any = this.customerProfileService?.minimalProfileData?.lineInfo?.MDN?.value;
  try {
      const data = {
        mdn: resminmal,
      };
      const res: any = await this.customerProfileService.sendToEmail(data);
      
      if (res.Status) {
          this.commonService.showToast('success', "Success", res.data);
      } else {
          this.commonService.showToast('error', "Error", res.message);
      }
  } catch (err) {
      console.log(err);
      this.commonService.showToast('error', "Error", err?.error?.message ?? "Some Error Occurred");
  } finally {
      
  }
}
onEditClick(controlName: string) {
  if (controlName === 'userName') {
    this.hideMailButton = true;
  }
  this.toggleEdit(controlName);
}

toggleEdit(controlName: string) {
  if (this.editingFields.has(controlName)) {
    this.editingFields.delete(controlName);
  } else {
    this.editingFields.add(controlName);
  }
  const field = this.formFields.find(f => f.controlName === controlName);
  if (field) {
    field.editable = !field.editable;
  }
}

isEditing(controlName: string): boolean {
  return this.editingFields.has(controlName);
}

withConfirmation() {
  Swal.fire({
    title: 'Are you sure?',
    text: "Do you want to send Email?",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Send Email',
    customClass: {
      popup: 'swal2-custom-width'
    },
  }).then((result) => {
    if (result.isConfirmed && result.value) {
      this.onSendEmailClick()
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      console.log('Cancelled');
    }

  })
}
async reset() {
  // Show confirmation modal
  const result = await Swal.fire({
    title: 'Are you sure?',
    text: "Do you want to reset the activation?",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, reset it!',
    cancelButtonText: 'Cancel'
  });

  // Proceed only if user confirmed
  if (result.isConfirmed) {
    try {
      const res5: any = await this.customerProfileService.resetActivation();
      
      if (res5.Status) {
        this.commonService.showToast('success', "Success", res5.message);
      } else {
        this.commonService.showToast('error', "Error", res5.message);
      }
    } catch (error) {
      this.commonService.showToast('error', "Error", "Something went wrong");
    }
  }
}

}
