import { Component, Input, OnInit } from '@angular/core';
import { SubscriptionService } from '../service/subscription.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import {
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexStroke,
  ApexTooltip,
  ApexYAxis,
  ApexGrid,
  ApexTitleSubtitle
} from 'ng-apexcharts';
import { fadeInOut } from 'src/app/shared/services/app.animation';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  dataLabels: ApexDataLabels;
  stroke: ApexStroke;
  tooltip: ApexTooltip;
  grid: ApexGrid;
  title: ApexTitleSubtitle;
};


@Component({
  selector: 'app-subscriptions-graph',
  templateUrl: './subscriptions-graph.component.html',
  styleUrls: ['./subscriptions-graph.component.scss'],
  animations: [fadeInOut]
})
export class SubscriptionsGraphComponent implements OnInit {
  startDate: string;
  endDate: string;
  graphDataList: any;
  public chartOptions: Partial<ChartOptions>;
  dateForm: FormGroup;
  selectedCard: boolean = false;
  myDaterangeValue: any;
  bsRangeValue: Date[];
  @Input() interval: any;
  isSkeletonLoader: boolean = true;

  constructor(
    private subscriptionService: SubscriptionService,
    private fb: FormBuilder
  ) {

  }

  setDates() {
    const today = new Date();
    const fifteenDaysAgo = new Date();
    fifteenDaysAgo.setDate(fifteenDaysAgo.getDate() - 15);
    this.bsRangeValue = [fifteenDaysAgo, today];
  }




  async ngOnInit() {
    this.setDates();
    this.dateForm = this.fb.group({
      startDate: [this.startDate],
      endDate: [this.endDate]
    });

    this.dateForm = this.fb.group({
      dateRange: [this.bsRangeValue]
    });

    this.dateForm.valueChanges.subscribe(() => {
      this.getTrends();
    });

    await this.getTrends();
    this.changeSelectedCardColor()
  }

  onDateRangeChange() {
    this.getTrends();
  }
  ngOnChanges() {
    this.getTrends()
  }
  async getTrends() {
    const data = {
      startDate: this.bsRangeValue[0].toISOString().split('T')[0],
      endDate: this.bsRangeValue[1].toISOString().split('T')[0],
      interval:this.interval
    };
    await this.subscriptionService.getTrends(data).subscribe((res: any) => {
      if (res?.Status) {
        this.graphDataList = res?.data;
        this.showGrpahData()
      }
    })
  }
  showGrpahData() {
    this.chartOptions = {
      series: [
        {
          name: "Count",
          data: this.graphDataList.map(item => [new Date(item.date).getTime(), item.count])
        }
      ],
      chart: {
        type: "line",
        height: 220,
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true
        },
        toolbar: {
          show: false
        }
      },
      xaxis: {
        type: 'datetime',
      },
      yaxis: {
        title: {
          text: 'Count'
        }
      },

      tooltip: {
        x: {
          format: 'dd MMM yyyy'
        }
      },
      grid: {
        borderColor: '#f1f1f1'
      }

    };
    this.isSkeletonLoader = false

  }

  applyGraphDates() {
    const selectedCard = this.subscriptionService.getSelectedCardValue(); // Now it works with BehaviorSubject
    const currentCard = 'SubscriptionGraph'; // The card you're working with

    if (selectedCard === currentCard) {
      const data = {
        startDate: null,
        endDate: null,
      };
      this.subscriptionService.setSelectedCard(null); 
      this.subscriptionService.setSubscriptionChartDateSub(data);
    } else {
      // Select the card
      const data = {
        startDate: this.bsRangeValue[0].toISOString().split('T')[0],
        endDate: this.bsRangeValue[1].toISOString().split('T')[0],
      };
      this.subscriptionService.setSubscriptionChartDateSub(data);
      this.subscriptionService.setSelectedCard(currentCard); // Set the selected card
    }
  }

  changeSelectedCardColor() {
    this.subscriptionService.getSelectedCard().subscribe((res: any) => {
      if (res === 'SubscriptionGraph') {
        console.log("Card Selected: SubscriptionGraph");
        this.selectedCard = true;
      } else {
        this.selectedCard = false;
      }
    });
  }
  stop(event: Event) {
    event.stopPropagation();
  }
}
