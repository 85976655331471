
<div class="subscription-card mb-2" *ngFor="let row of invoiceList">
    <div class="d-flex justify-content-between subscription-card-body">
        <span class="text-nowrap">{{row.CreatedDate | date:'MMM d, y, HH:mm'}}</span>
        <span>{{row?.NameOnCard}}</span>
        <span>{{row?.AccountNumber}}</span>
        <span>${{row?.Amount | number:'1.2-2'}}</span>
    </div>

</div>
<p *ngIf="showmsg" class="text-danger text-center fw-bold">
    No data found
</p>