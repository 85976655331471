import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'highlightPipe',
  pure: true,
})
export class HighlightPipe implements PipeTransform {


  constructor(private sanitizer: DomSanitizer) {}

  transform(value: any, searchText: string): SafeHtml {
    if (!searchText || !value || typeof value !== 'string') {
      return value;
    }
    const regex = new RegExp(`(${searchText})`, 'gi');
    const highlightedValue = value.replace(regex, `<span class="bg-primary">$1</span>`);
    return this.sanitizer.bypassSecurityTrustHtml(highlightedValue);
  }

}
