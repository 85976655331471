<!-- Start Breadcrumbs -->
<!-- <app-breadcrumbs title="Checkout" [breadcrumbItems]="breadCrumbItems"></app-breadcrumbs> -->
<!-- End Breadcrumbs -->

<div class="container-fluid custom-card mt-10 mx-2" >
  <div class="row p-2">
    <div class="col-12">
        <div class="ca">
            <div class="card-body checkout-tab">
                <div class="step-arrow-nav mt-n3 mx-n3 mb-3">
                  <ul ngbNav #customNav="ngbNav" [activeId]="activeTab" class="nav nav-pills custom-nav nav-justified" role="tablist">
                        <li [ngbNavItem]="0" class="nav-item" [formGroup]="invoiceForm" @fadeInOut>
                          <a ngbNavLink class="nav-link fs-15 p-3" data-bs-toggle="tab" role="tab">
                            <i class="ri-file-info-line fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle mx-2"></i>
                            Ad-hoc Types
                          </a>
                          <ng-template ngbNavContent>
                            <div>
                              <div class="row align-items-center">
                                <!-- Image Section -->
                                <div class="col-md-6 text-center" *ngIf="companyName=='mingle'">
                                  <img src="../../../assets/images/mingle-invoice.png" alt="Ad-hoc Invoice" class="img-fluid">
                                </div>
                                <div class="col-md-6 text-center" *ngIf="companyName=='unity'">
                                  <img src="../../../assets/images/unity-invoice.png" alt="Ad-hoc Invoice" class="img-fluid">
                                </div>
                                <!-- Button and Customer ID Section -->
                                <div class="col-md-6">
                                  <div *ngIf="isCustomerInfoVisible" class="customer-info-container" @fadeInOut>
                                    <!-- Header with Title and Close Icon -->
                                    <div class="d-flex justify-content-between align-items-center mb-2">
                                      <h6 class="fw-bold mb-0">Customer Information</h6>
                                      <span
                                        class="close-btn"
                                        (click)="toggleCustomerInfo(false)"
                                        matTooltip="Close details"
                                        matTooltipPosition="above"
                                      >
                                        <i class="fas fa-times text-danger"></i>
                                    </span>
                                    </div>
                                    <!-- Customer Details Content -->
                                    <p><strong>Name:</strong> {{ customerPersonalData?.firstName || '-' }} {{ customerPersonalData?.lastName || '-' }} </p>
                                    <p style="white-space: nowrap;"><strong>Email:</strong> {{ customerPersonalData?.email || '-' }}</p>
                                  </div>
                                  
                                  <div class="mb-4">
                                    <label for="customerId" class="form-label text-dark fw-semibold">Customer ID</label>
                                    <div class="input-group">
                                      <span 
                                        class="input-group-text bg-primary text-white border-0 d-flex align-items-center" 
                                        style="height: calc(2.5rem); border-top-left-radius: 0.375rem; border-bottom-left-radius: 0.375rem;">
                                        <i class="fas fa-user"></i>
                                      </span>
                                      <input
                                      type="text"
                                      id="customerId"
                                      class="form-control"
                                      formControlName="customerId"
                                      placeholder="Enter Customer ID"
                                      (input)="onCustomerIdChange($event)"
                                      style="border-left: 0; height: calc(2.5rem); box-shadow: none;"
                                    />
                                    <span
                                    *ngIf="isInfoIconVisible"
                                    class="input-group-text text-white border-0 d-flex align-items-center"
                                    (click)="toggleCustomerInfo(true)"
                                    matTooltip="Customer information"
                                    matTooltipPosition="above"
                                    style="height: calc(2.5rem); border-top-right-radius: 0.375rem; border-bottom-right-radius: 0.375rem; background-color: #7a9eda; cursor: pointer;"
                                  >
                                    <i class="fas fa-info-circle"></i>
                                  </span>
                                    <!-- <button
                                    *ngIf="!isCustomerInfoVisible"
                                    class="info-btn bg-transparent border-0"
                                    (click)="toggleCustomerInfo(true)"
                                    style="cursor: pointer;"
                                  >
                                    <i class="fas fa-info-circle text-primary"></i>
                                  </button> -->
                                    </div>

                                    <!-- <div *ngIf="invoiceForm.get('customerId')?.invalid && invoiceForm.get('customerId')?.touched" class="text-danger mt-2">
                                      Customer ID is required.
                                    </div> -->
                                  </div>
                                  
                                  
                                  <h6 class="mb-3 text-dark">Choose Invoice Type</h6>
                                  <!-- Customer ID Section -->

                                  <!-- Invoice Type Buttons -->
                                  <div class="d-flex flex-column gap-3">
                                    <button
                                      *ngFor="let type of invoiceTypes"
                                      type="button"
                                      class="btn btn-light d-flex align-items-center p-3 invoice-btn"
                                      [ngClass]="{'selected-border': selectedInvoiceType === type.id}"
                                      style="border-radius: 10px;"
                                      (click)="selectInvoiceType(type.id)"
                                    >
                                      <i [class]="type.icon + ' fs-20 text-primary me-3'"></i>
                                      <span class="fs-16">{{ type.label }}</span>
                                    </button>
                                  </div>
                                  
                                </div>
                              </div>
                              <div class="d-flex justify-content-end mt-3">
                                <!-- <button type="button" class="btn btn-primary" (click)="goToNextTab()">Next</button> -->
                                <button class="btn btn-primary next-button px-4 py-2 fw-semibold" (click)="goToNextTab()">
                                 Next
                                </button>
                              </div>
                            </div>
                          </ng-template>
                        </li>
                        
                        
                        
                        
                        <li [ngbNavItem]="1" class="nav-item" @fadeInOut>
                          <a ngbNavLink class="nav-link fs-15 p-3" data-bs-toggle="tab" role="tab">
                            Customer Info
                          </a>
                          <ng-template ngbNavContent>
                            <div class="container-fluid custom-card">
                              <!-- Unified Card Section -->
                              <div class="row">
                                <div class="col-md-12">
                                  <div class=" ">
                                    <div class="d-flex justify-content-between align-items-center p-3 mb-2 " style="border-bottom: 1px solid lightgray;">
                                      <h5 class="fw-bold text-dark mb-0">
                                        <i class="fas fa-info-circle text-primary me-2"></i>Customer Details
                                      </h5>
                                      <!-- <i
                                      class="fas fa-info-circle text-success fa-lg cursor-pointer"
                                      title="More Details"
                                      data-bs-toggle="modal"
                                      data-bs-target="#addAddressModal"
                                      (click)="openModal(content)"
                                    ></i> -->
                                    <!-- <button class="faq-button bg-primary" (click)="sendToCustomerDashboard(personalInfo?.customerId, activeTab)">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                          d="M224 256c70.7 0 128-57.31 128-128S294.7 0 224 0 96 57.31 96 128s57.3 128 128 128zm0 32c-88.4 0-224 44.9-224 133.3V464c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48v-42.67C448 332.9 312.4 288 224 288z"
                                        ></path>
                                      </svg>
                                      <span class="tooltip">More Details</span>
                                    </button> -->
                                    
                                    
                                    
                                    
                                    
                                    </div>
                                    <div class="card-body p-3">
                                      <!-- Personal Info Section -->
                                      <div class="row g-4 mb-4">
                                        <div class="col-md-3">
                                          <h6 class="fw-semibold">
                                            <i class="fas fa-user text-primary me-2"></i>First Name
                                          </h6>
                                          <p class="text-muted">{{ personalInfo?.firstName || '-' }}</p>
                                        </div>
                                        <div class="col-md-3">
                                          <h6 class="fw-semibold">
                                            <i class="fas fa-user text-primary me-2"></i>Last Name
                                          </h6>
                                          <p class="text-muted">{{ personalInfo?.lastName || '-' }}</p>
                                        </div>
                                        <div class="col-md-3">
                                          <h6 class="fw-semibold">
                                            <i class="fas fa-envelope text-primary me-2"></i>Email
                                          </h6>
                                          <p class="text-muted">{{ personalInfo?.email || '-' }}</p>
                                        </div>
                                        <div class="col-md-3">
                                          <h6 class="fw-semibold">
                                            <i class="fas fa-user-shield text-primary me-2"></i>Account Status
                                          </h6>
                                          <p class="text-muted">{{ personalInfo?.accountStatus || '-' }}</p>
                                        </div>
                                      </div>
                        
                                      <!-- Additional Info Section -->
                                      <div class="row g-4 mb-4">
                                        <div class="col-md-3">
                                          <h6 class="fw-semibold">
                                            <i class="fas fa-id-badge text-primary me-2"></i>Customer ID
                                          </h6>
                                          <p class="text-muted">{{ res?.data?.personalInfo?.customerId || '-' }}</p>
                                        </div>
                                        <div class="col-md-3">
                                          <h6 class="fw-semibold">
                                            <i class="fas fa-user-cog text-primary me-2"></i>Customer Type
                                          </h6>
                                          <p class="text-muted">{{ res?.data?.personalInfo?.customerType?.value || '-' }}</p>
                                        </div>
                                        <div class="col-md-3">
                                          <h6 class="fw-semibold">
                                            <i class="fas fa-barcode text-primary me-2"></i>Carrier Code
                                          </h6>
                                          <p class="text-muted">{{ res?.data?.plan?.carrierCode?.value || '-' }}</p>
                                        </div>
                                        <div class="col-md-3">
                                          <h6 class="fw-semibold">
                                            <i class="fas fa-mobile-alt text-primary me-2"></i>MDN
                                          </h6>
                                          <p class="text-muted">{{ res?.data?.lineInfo?.MDN?.value || '-' }}</p>
                                        </div>
                                      </div>
                        
                                      <!-- Shipping and Service Address Section -->
                                      <div class="row g-4">
                                        <!-- Shipping Address -->
                                        <div class="col-md-6">
                                          <div class="p-3 border rounded">
                                            <h6 class="fw-semibold">
                                              <i class="fas fa-truck text-primary me-2"></i>Shipping Address
                                            </h6>
                                            <p class="text-muted mb-0">{{ shippingAddress || '-' }}</p>
                                          </div>
                                        </div>
                        
                                        <!-- Service Address -->
                                        <div class="col-md-6">
                                          <div class="p-3 border rounded">
                                            <h6 class="fw-semibold">
                                              <i class="fas fa-map-marker-alt text-primary me-2"></i>Service Address
                                            </h6>
                                            <p class="text-muted mb-0">{{ ServiceAddress || '-' }}</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                        
                            <!-- Footer with Next Button -->
                            <div class="button-group d-flex justify-content-between mt-3">
                              <!-- Back Button -->
                              <button class="btn btn-secondary back-button" (click)="goToPreviousTab()">
                                <i class="fas fa-arrow-left"></i>
                              </button>
                            
                              <!-- Next Button -->
                              <button class="btn btn-primary next-button px-4 py-2 fw-semibold" (click)="goToNextTab()">
                               Next
                              </button>
                            </div>
                            
                          </ng-template>
                        </li>
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        <li [ngbNavItem]="2" class="nav-item" @fadeInOut>
                          <a
                            ngbNavLink
                            class="nav-link fs-15 p-3"
                            data-bs-toggle="tab"
                            role="tab"
                          >
                            <i
                              class="ri-shopping-cart-line fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle mx-2"
                            ></i>
                            Add Items
                          </a>
                          <ng-template ngbNavContent *ngIf="selectedInvoiceType=='Equipment'">
                            <div class="container-fluid">
                              <div class="row">
                                <!-- Payment Section -->
                                <div class="col-xl-8">
                                  <div class="custom-card border-0">
                                    <div class="card-header d-flex align-items-center justify-content-between">
                                      <div>
                                        <h5 class="mb-1">
                                          <i class="fas fa-list-alt me-2"></i>
                                          Ad-Hoc Invoice Items
                                        </h5>
                                        <p class="text-primary small">Add items and their quantities to generate the invoice.</p>
                                      </div>
                                      <i class="fas fa-info-circle text-light cursor-pointer" title="Help"></i>
                                    </div>
                                  
                                    <div class="card-body custom-card p-0">
                                      <div class="table-responsive">
                                        <table class="table ">
                                          <thead>
                                            <tr>
                                              <th>Item Name</th>
                                              <th>Description</th>
                                              <th>Price</th>
                                              <th class="text-center">Quantity</th> <!-- Centered -->
                                              <th class="text-center">Actions</th> <!-- Centered -->
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr *ngFor="let item of items; let i = index" >
                                              <td data-label="Item Name">{{ item.name }}</td>
                                              <td data-label="Description">{{ item.description }}</td>
                                              <td data-label="Price" class="text-primary">${{ item.price }}</td>
                                              <td data-label="Quantity">
                                                <div class="d-flex align-items-center">
                                                  <!-- Decrease Button -->
                                                  <button
                                                    class="btn btn-light border px-2 py-1"
                                                    [disabled]="item.quantity === 0"
                                                    (click)="decreaseQuantity(i)"
                                                    matTooltip="Decrease Quantity"
                                                    style="cursor: pointer;"
                                                  >
                                                    <i class="fas fa-minus text-danger"></i>
                                                  </button>
                                                
                                                  <!-- Quantity Display -->
                                                  <span class="mx-2 fw-bold">{{ item.quantity }}</span>
                                                
                                                  <!-- Increase Button -->
                                                  <button
                                                    class="btn btn-light border px-2 py-1"
                                                    (click)="increaseQuantity(i)"
                                                    matTooltip="Increase Quantity"
                                                    style="cursor: pointer;"
                                                  >
                                                    <i class="fas fa-plus text-success"></i>
                                                  </button>
                                                </div>
                                                
                                              </td>
                                              <td data-label="Actions">
                                                <div class="ms-3">
                                                  <!-- Delete Icon -->
                                                  
                                                  <!-- Delete (Second Confirmation) -->
                                                  <i
                                                    class="fas fa-trash-alt text-danger"
                                                    matTooltip="Permanently Delete Item"
                                                    (click)="removeItem(i)"
                                                    style="cursor: pointer; font-size: 1.2rem;"
                                                  ></i>
                                                </div>

                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    
                                  </div>
                                  
                                </div>
                                
                        
                                <!-- Order Summary Section -->
                                <div class="col-xl-4">
                                  <div class="custom-card">
                                    <div class="card-header bg-white border-bottom">
                                      <h5 class="card-title mb-3 fw-bold text-dark">Order Summary</h5>
                                    </div>
                                
                                    <div class="card-body p-3" *ngIf="summaryItems.length > 0">
                                      <!-- Item List -->
                                      <div *ngFor="let item of summaryItems; let i = index" class="mb-3 d-flex align-items-center">
                                        <!-- Product Image -->
                                        <div class="me-3" style="width: 80px;">
                                          <img
                                            [src]="item?.imagePath || 'assets/images/products/default.png'"
                                            alt="{{ item.name }}"
                                            class="img-fluid rounded"
                                            style="width: 80px; height: 80px;"
                                          />
                                        </div>
                                
                                        <!-- Product Details and Actions -->
                                        <div class="flex-grow-1 d-flex flex-column">
                                          <!-- Item Name -->
                                          <div class="mb-3">
                                            <h6 class="mb-1 fw-semibold" style="white-space: nowrap;">{{ item.name }}</h6>
                                          </div>
                                
                                          <!-- Item Quantity and Delete -->
                                          <div class="d-flex justify-content-between align-items-center">
                                            <!-- Quantity Adjustment -->
                                            <div class="d-flex align-items-center">
                                              <button
                                                class="btn btn-light border px-2 py-1"
                                                [disabled]="item.quantity === 0"
                                                (click)="decreaseQuantity(i)"
                                                style="cursor: pointer;"
                                              >
                                                <i class="fas fa-minus"></i>
                                              </button>
                                
                                              <span class="mx-2 fw-bold">{{ item.quantity }}</span>
                                
                                              <button
                                                class="btn btn-light border px-2 py-1"
                                                (click)="increaseQuantity(i)"
                                                style="cursor: pointer;"
                                              >
                                                <i class="fas fa-plus"></i>
                                              </button>
                                            </div>
                                
                                            <!-- Delete Icon -->
                                            <div class="ms-3">
                                              <i
                                                class="fas fa-trash-alt text-danger"
                                                (click)="removeItem(i)"
                                                style="cursor: pointer; font-size: 1.2rem;"
                                              ></i>
                                            </div>
                                          </div>
                                        </div>
                                
                                        <!-- Price -->
                                        <div class="text-end fw-bold ms-4" style="width: 100px;">
                                          ₹{{ item.price * item.quantity }}
                                        </div>
                                      </div>
                                
                                      <!-- Subtotal -->
                                      <div class="border-top pt-3 d-flex justify-content-between">
                                        <h6 class="fw-bold mb-0">Subtotal ({{ summaryItems.length }} item{{ summaryItems.length > 1 ? 's' : '' }})</h6>
                                        <h6 class="fw-bold mb-0">₹{{ calculateSubTotal() }}</h6>
                                      </div>
                                    </div>
                                
                                    <!-- Empty Cart -->
                                    <div class="card-body text-center" *ngIf="summaryItems.length === 0">
                                      <img
                                        src="assets/images/product/empty-cart.jpg"
                                        alt="Empty Cart"
                                        class="img-fluid"
                                        style="max-width: 300px;"
                                      />
                                      <p class="text-muted mt-3">Your cart is empty. Add some items to see them here!</p>
                                    </div>
                                  </div>
                                </div>
                                

                                
                              </div>
                        
                              <!-- Footer Buttons -->
                              <div class="d-flex align-items-center justify-content-between mt-5">
                                <button class="btn btn-secondary next-button px-4 py-2 fw-semibold" (click)="goToPreviousTab()">
                                  Back
                                 </button>
                                <!-- <button
                                  type="button"
                                  class="btn btn-primary btn-label"
                                  (click)="goToNextTab()"
                                  data-nexttab="pills-payment-tab"
                                >
                                  <i class="ri-bank-card-line label-icon align-middle fs-16 ms-2"></i>
                                  Add Items
                                </button> -->
                                <button class="btn btn-primary next-button px-4 py-2 fw-semibold" (click)="goToNextTab()">
                                  Next
                                 </button>
                              </div>
                            </div>
                          </ng-template>
                          <ng-template ngbNavContent *ngIf="selectedInvoiceType=='Plan Change'">
                            <div class="container-fluid">
                              <div class="row">
                                <!-- Payment Section -->
                                <div class="col-xl-8">
                                  <div class="custom-card border-0"> 
                                <app-adhoc-plan-change [customerData]="customerData"></app-adhoc-plan-change>

                                  </div>
                                </div>
                                <div class="col-xl-4">
                                  <div class="custom-card ">
                                    <div class="card-header bg-white border-bottom">
                                      <h5 class="text-primary mb-3 fw-bold ">Plan Summary</h5>
                                      
                                    </div>
                                    
                                    <app-adhoc-breakup-template (planDetailsChange)="handlePlanDetails($event)"></app-adhoc-breakup-template>
                                  </div> 
                                </div>
                              </div>
                              <div class="d-flex align-items-center justify-content-between mt-5">
                                <button class="btn btn-secondary back-button" (click)="goToPreviousTab()">
                                  <i class="fas fa-arrow-left"></i>
                                </button>

                                <button class="btn btn-primary next-button px-4 py-2 fw-semibold" (click)="generateInvoiceForPlanChangeOrPlanRenewal()">
                                  Next
                                 </button>
                              </div>
                            </div>
                          </ng-template>
                          <ng-template ngbNavContent *ngIf="selectedInvoiceType=='Plan Renewal'">
                            <div class="container-fluid">
                              <div class="row">
                                <!-- Payment Section -->
                                <div class="col-xl-8">
                                  <div class="custom-card border-0">
                                <app-adhoc-plan-renewal [customerData]="customerData"></app-adhoc-plan-renewal>

                                  </div>
                                </div>
                                <div class="col-xl-4">
                                  <div class="custom-card ">
                                    <div class="card-header bg-white border-bottom">
                                      <h5 class="text-primary mb-3 fw-bold ">Plan Summary</h5>
                                      
                                    </div>
                                    
                                    <app-adhoc-breakup-template></app-adhoc-breakup-template>
                                  </div>
                                </div>
                              </div>
                              <div class="d-flex align-items-center justify-content-between mt-5">
                                <button class="btn btn-secondary back-button" (click)="goToPreviousTab()">
                                  <i class="fas fa-arrow-left"></i>
                                </button>

                                <button class="btn btn-primary next-button px-4 py-2 fw-semibold" (click)="generateInvoiceForPlanChangeOrPlanRenewal()">
                                  Next
                                 </button>
                              </div>
                            </div>
                          </ng-template>
                          <ng-template ngbNavContent *ngIf="selectedInvoiceType=='Custom'">
                            <div class="container-fluid">
                              <div class="row">
                                <!-- Payment Section -->
                                <div class="col-xl-8">
                                  <div class="custom-card border-0">
                                <app-adhoc-custom [customerData]="customerData"  (updateInvoiceSummary)="updateSummary($event)"></app-adhoc-custom>

                                  </div>
                                </div>
                                <div class="col-xl-4">
                                  <div class="custom-card ">
                                    <div class="card-header bg-white border-bottom">
                                      <h5 class="card-title mb-3 fw-bold text-dark">Invoice Summary</h5>
                                    </div>
                                
                                    <!-- Invoice Details -->
                                    <div class="card-body p-4">
                                      <!-- Customer Details -->
                                      <div class="mb-4">
                                        <h5 class="fw-bold text-dark mb-3">
                                          <i class="fas fa-user text-primary me-2"></i>Customer Details
                                        </h5>
                                        <div class="row g-2">
                                          <div class="col-md-6">
                                            <h6 class="fw-semibold text-muted">
                                              <i class="fas fa-user text-primary me-2"></i>First Name
                                            </h6>
                                            <p class="text-dark mb-0">{{ personalInfo?.firstName || '-' }}</p>
                                          </div>
                                          <div class="col-md-6">
                                            <h6 class="fw-semibold text-muted">
                                              <i class="fas fa-user text-primary me-2"></i>Last Name
                                            </h6>
                                            <p class="text-dark mb-0">{{ personalInfo?.lastName || '-' }}</p>
                                          </div>
                                          <div class="col-md-6">
                                            <h6 class="fw-semibold text-muted">
                                              <i class="fas fa-envelope text-primary me-2"></i>Email
                                            </h6>
                                            <p class="text-dark mb-0" style=" word-break: break-word;">{{ personalInfo?.email || '-' }}</p>
                                          </div>
                                          <div class="col-md-6">
                                            <h6 class="fw-semibold text-muted">
                                              <i class="fas fa-user-shield text-primary me-2"></i>Account Status
                                            </h6>
                                            <p class="text-dark mb-0">{{ personalInfo?.accountStatus || '-' }}</p>
                                          </div>
                                        </div>
                                      </div>
                                    
                                      <!-- Invoice Details -->
                                      <div class="d-flex justify-content-between align-items-center mb-3">
                                        <span class="fw-bold text-muted">Amount:</span>
                                        <span class="fw-bold text-primary">₹{{ invoiceSummary.amount }}</span>
                                      </div>
                                    
                                      <div class="mb-3">
                                        <span class="fw-bold text-muted">Description:</span>
                                        <p class="mb-0 text-dark" style="white-space: pre-wrap;" [innerHTML]="invoiceSummary.description">
                                          {{ invoiceSummary.description }}
                                        </p>
                                      </div>
                                    </div>
                                    

                                  </div>
                                </div>
                              </div>
                              <div class="d-flex align-items-center justify-content-between mt-5">
                                <button class="btn btn-secondary back-button" (click)="goToPreviousTab()">
                                  <i class="fas fa-arrow-left"></i>
                                </button>

                                <button class="btn btn-primary next-button px-4 py-2 fw-semibold" (click)="customInvoiceGeneration()">
                                  Next
                                 </button>
                              </div>
                            </div>
                          </ng-template>
                        </li>
                        
                        
                        
                        
                        
                        
                        <li [ngbNavItem]="3" class="nav-item" >
                          <a ngbNavLink class="nav-link fs-15 p-3" data-bs-toggle="tab" role="tab">
                            <i class="ri-file-text-line fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle mx-2"></i>
                            Preview Invoice
                          </a>
                          <ng-template ngbNavContent>
                            <app-adhoc-preview-template [invoiceType]="selectedInvoiceType" [adhocDetails]="adhocDetails"></app-adhoc-preview-template>
                            <div class="d-flex align-items-center justify-content-between mt-5">
                              <button class="btn btn-secondary back-button" (click)="goToPreviousTab()">
                                <i class="fas fa-arrow-left"></i>
                              </button>
                              <button class="btn btn-primary back-button" (click)="goToNextTab()">
                                Home
                              </button>
                              <!-- <button
                                type="button"
                                class="btn btn-primary btn-label"
                                (click)="goToNextTab()"
                                data-nexttab="pills-payment-tab"
                              >
                                <i class="ri-bank-card-line label-icon align-middle fs-16 ms-2"></i>
                                Next
                              </button> -->
                            </div>
                          </ng-template>
                        </li>
                        
                        
                        
                        <!-- <li [ngbNavItem]="4" class="nav-item"  @fadeInOut>
                            <a ngbNavLink class="nav-link fs-15 p-3" data-bs-toggle="tab" role="tab">
                                <i
                                    class="ri-checkbox-circle-line fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle mx-2"></i>Finish
                            </a>
                            <ng-template ngbNavContent>

                                <app-adhoc-generated></app-adhoc-generated>
                            </ng-template>
                        </li> -->
                    </ul>
                </div>
                <!-- Tab panes -->
                <div class="tab-content">
                    <div [ngbNavOutlet]="customNav"></div>
                </div>

            </div>
            <!-- end card body -->
        </div>
        <!-- end card -->
    </div>
    <!-- end col -->


    
    
    <!-- end col -->
</div>
</div>
<!-- end row -->

<!-- editItemModal -->
<ng-template #content role="document" let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="addAddressModalLabel">Address</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
            (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <div>
            <div class="mb-3">
                <label for="addaddress-Name" class="form-label">Name</label>
                <input type="text" class="form-control" id="addaddress-Name" placeholder="Enter Name">
            </div>

            <div class="mb-3">
                <label for="addaddress-textarea" class="form-label">Address</label>
                <textarea class="form-control" id="addaddress-textarea" placeholder="Enter Address" rows="2"></textarea>
            </div>

            <div class="mb-3">
                <label for="addaddress-Name" class="form-label">Phone</label>
                <input type="text" class="form-control" id="addaddress-Name" placeholder="Enter Phone No.">
            </div>

            <div class="mb-3">
                <label for="state" class="form-label">Address Type</label>
                <select class="form-select" id="state" data-plugin="choices">
                    <option value="homeAddress">Home (7am to 10pm)</option>
                    <option value="officeAddress">Office (11am to 7pm)</option>
                </select>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" data-bs-dismiss="modal"
            (click)="modal.close('Close click')">Close</button>
        <button type="button" class="btn btn-success" data-bs-dismiss="modal"
            (click)="modal.dismiss('Cross click')">Save</button>
    </div>
</ng-template>

<!-- removeItemModal -->
<ng-template #deleteModel let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-close"
                (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div class="mt-2 text-center">
                <!-- <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop"
                    colors="primary:#f7b84b,secondary:#f06548" style="width:100px;height:100px"></lord-icon> -->
                <div class="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                    <h4>Are you sure ?</h4>
                    <p class="text-muted mx-4 mb-0">Are you sure you want to remove this address ?</p>
                </div>
            </div>
            <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
                <button type="button" class="btn w-sm btn-light" data-bs-dismiss="modal"
                    (click)="modal.close('Close click')">Close</button>
                <button type="button" class="btn w-sm btn-danger " id="delete-product">Yes, Delete It!</button>
            </div>
        </div>
    </div><!-- /.modal-content -->
</ng-template>

