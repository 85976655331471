<div class=" card " *ngIf="!isSkeletonLoader">
  <div class="card-header p-2">
    <div class="d-flex align-items-center justify-content-end gap-3 overflow-auto custom-row ">
      <div class=" d-flex align-items-center gap-2">
        <div class="date-picker" (click)="stop($event)" *ngIf="isShowDateRange">
          <div class="date-picker hiringCal round-border d-flex" style="justify-content: center;">
            <div class="f-row d-inline-block position-relative reports-datePicker">
              <button class="f-row f-a-center px-3 filter-btn round hover-light py-0 date-button">
                <i class="fa fa-calendar" (click)="drp.toggle()" [attr.aria-expanded]="drp.isOpen"></i>
                <input [(ngModel)]="bsRangeValue" (ngModelChange)="dataChanged()"
                  [bsConfig]="{ rangeInputFormat: 'MMM DD, YYYY', adaptivePosition: true, showWeekNumbers: false }"
                  bsDaterangepicker class="pl-2 py-0 text border-0 bg-transparent cursor-pointer"
                  placeholder="Daterangepicker" type="text" readonly #drp="bsDaterangepicker" />
                <i class="fa fa-chevron-down" (click)="drp.toggle()" [attr.aria-expanded]="drp.isOpen"></i>
              </button>
            </div>
          </div>
        </div>
        <input type="search" [(ngModel)]="searchQuery" (ngModelChange)="onSearchInput($event)" *ngIf="isShowSearch"
          placeholder="Ticket Name" class=" form-control form-control-sm" />
      </div>
      <div class="d-flex gap-2">
        <app-filter-btn [icon]="'fa fa-search'" [isBtn]="true" (click)="toggleSearch()"></app-filter-btn>
        <app-filter-btn [icon]="'fa fa-calendar-o'" [isBtn]="true" (click)="toggleDateRange()"></app-filter-btn>
        <app-filter-btn [icon]="'fa fa-plus'" [isBtn]="true" (click)="addTicket()"></app-filter-btn>
      </div>
    </div>
  </div>
  <div class="card-body p-0">
    <div class="table-responsive table-hover table-striped table-card">
      <table class="table table-nowrap mb-0">
        <thead class="table-light">
          <tr class="header-row">
            <th *ngFor="let header of headers; let i = index" class="text-nowrap">
              {{ header.display }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of data?.data; let i = index" class="cursor-pointer" (click)="openModal(item.id)">
            <td *ngFor="let header of headers">
              <ng-container [ngSwitch]="header.key">
                <span *ngSwitchDefault class="">
                  {{ item[header.key] }}   
                </span>

                <span *ngSwitchCase=" 'UpdatedDate' ">
                  {{ item[header.key] | date : 'mediumDate' }}
                </span>
                <span *ngSwitchCase="'id'">
                  {{ item[header.key] }}
                </span>
                <span *ngSwitchCase="'CreatedDate' ">
                  {{ item[header.key] | date : 'mediumDate' }}
                </span>
                <span *ngSwitchCase=" 'ClosedDate'">
                  {{ item[header.key] | date : 'mediumDate' }}
                </span>
                <span *ngSwitchCase="'status'" class="custom-badge bg-yellow">
                  {{ item.status || 'N/A'}}
                </span>
                <span *ngSwitchCase="'priority'" class="custom-badge">
                  {{ item.priority || 'N/A' }}
                </span>
               
              </ng-container>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="d-flex justify-content-center p-3" *ngIf="data?.data?.length==0">
        <span class="text-muted">
          No data found
        </span>
      </div>
    </div>
  </div>

</div>


<app-ticket-sidebar *ngIf="isSidebarOpen" [isEditing]="isEditing" [ticketId]="this.ticketId" (close)="closeSidebar()"
  (save)="getData()">
</app-ticket-sidebar>
<div *ngIf="isSkeletonLoader"  style="max-width: 100%;">
  <app-skeleton-loader [tableRow]="8" [tableColumn]="6"  shape="table"></app-skeleton-loader>
</div>