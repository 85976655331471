<div class="card-body pt-0 " *ngIf="isShowScheduleData">
    <div class="deposit-wrap">
        <div class="w-100">
            <div class="row" *ngIf="!isNonPrepaid">
                <div class="col-6">
                    <p class="f-w-500">Customer Id</p>
                </div>
                <div class="col-6 font-success">
                    <p class="f-w-500">
                        {{
                        customerProfileData?.schedulededPlan?.CustomerID
                        || '-' }}
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <p class="f-w-500">Current Plan</p>
                </div>
                <div class="col-6 font-success">
                    <p class="f-w-500">
                        {{ currentPlanName || '-' }}
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <p class="f-w-500">Upcoming Plans</p>
                </div>
                <div class="col-6 font-success">
                    <p class="f-w-500">
                        {{ schedulePlanName || '-' }}
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <p class="f-w-500">Schedule Date</p>
                </div>
                <div class="col-6 font-success">
                    <p class="f-w-500">
                        <!-- {{
                        commonService.formatDate(customerProfileData?.schedulededPlan.ScheduleDate)
                        }} -->
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <p class="f-w-500">Is Immediate</p>
                </div>
                <div class="col-6 font-success">
                    <p class="f-w-500">
                        {{
                        customerProfileData?.schedulededPlan.IsImmediate ?
                        'Yes' : 'No' }}
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <p class="f-w-500">Is Subscribed</p>
                </div>
                <div class="col-6 font-success">
                    <p class="f-w-500">
                        {{
                        customerProfileData?.schedulededPlan.IsSubscribed
                        ? 'Yes' : 'No' }}
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <p class="f-w-500">Status ID</p>
                </div>
                <div class="col-6 font-success">
                    <p class="f-w-500">
                        {{ customerProfileData?.schedulededPlan.StatusId
                        || '-' }}
                    </p>
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <p class="f-w-500">Status</p>
                </div>
                <div class="col-6 font-success">
                    <p class="f-w-500">
                        {{ customerProfileData?.schedulededPlan.Status ||
                        '-' }}
                    </p>
                </div>
            </div>
            <div class="row" *ngIf="!isNonPrepaid">
                <div class="col-6">
                    <p class="f-w-500">Created Date</p>
                </div>
                <div class="col-6 font-success">
                    <p class="f-w-500">
                        {{
                        commonService.formatDate(customerProfileData?.schedulededPlan?.CreatedDate
                        ) }}
                    </p>
                </div>
            </div>
            <div class="row" *ngIf="!isNonPrepaid">
                <div class="col-6">
                    <p class="f-w-500">Created By</p>
                </div>
                <div class="col-6 font-success">
                    <p class="f-w-500">
                        {{ customerProfileData?.schedulededPlan?.CreatedBy
                        || '-' }}
                    </p>
                </div>
            </div>
            <div class="row" *ngIf="!isNonPrepaid">
                <div class="col-6">
                    <p class="f-w-500">Cancelled By</p>
                </div>
                <div class="col-6 font-success">
                    <p class="f-w-500">
                        {{
                        customerProfileData?.schedulededPlan?.CancelledBy
                        || '-' }}
                    </p>
                </div>
            </div>
            <div class="row" *ngIf="!isNonPrepaid">
                <div class="col-6">
                    <p class="f-w-500">Is Subscription Sync</p>
                </div>
                <div class="col-6 font-success">
                    <p class="f-w-500">
                        {{
                        customerProfileData?.schedulededPlan?.IsSubscriptionSynced
                        ? 'Yes':'No' }}
                    </p>
                </div>
            </div>
            <div class="row" *ngIf="!isNonPrepaid">
                <div class="col-6">
                    <p class="f-w-500">Is Email Scheduled</p>
                </div>
                <div class="col-6 font-success">
                    <p class="f-w-500">
                        {{
                        customerProfileData?.schedulededPlan?.IsScheduledEmail
                        ? 'Yes':'No' }}
                    </p>
                </div>
            </div>
            <div class="row" *ngIf="!isNonPrepaid">
                <div class="col-6">
                    <p class="f-w-500">Cancel date</p>
                </div>
                <div class="col-6 font-success">
                    <p class="f-w-500">
                        {{commonService.formatDate(
                        customerProfileData?.schedulededPlan?.CancelDate)
                        }}
                    </p>
                </div>
            </div>




        </div>
    </div>
    <!-- <div class="text-center mt-3">
        <button class="btn btn-primary" (click)="toggleMoreInf()">More info</button>
    </div> -->
</div>
<div class="card-body d-flex justify-content-center pt-0 align-items-center"  *ngIf="!isShowScheduleData">
    <span class="text-danger f-w-500">No Plan Found</span>
</div>