<div class="cutom-card p-3 mb-3">
    <div class="d-flex flex-column mb-3 gap-1">
        <div class="d-flex justify-content-between align-items-center">
            <h4>Plan Detail</h4>
            <div class="d-flex gap-3 align-items-center ">
                <span class="custom-badge">${{customerDetails?.plan?.planPrice?.value}}</span>
            </div>
        </div>
        <span class="text-muted"> {{customerDetails?.plan?.planName?.value
            }}</span>

    </div>
    <div class="d-flex flex-column mb-3 gap-1">
        <div class="d-flex justify-content-between align-items-center">
            <h6>Plan Description</h6>
        </div>
        <span class="text-muted"> {{customerDetails?.plan?.planDetail?.value
            }}</span>
    </div>
    <div class="d-flex align-items-center justify-content-between">

        <div class="d-flex flex-column mb-3 gap-1">
            <div class="d-flex justify-content-between align-items-center">
                <h6>Current APS</h6>

            </div>
            <span class="text-muted"> {{
                customerDetails?.plan?.currentAps?.value }}</span>

        </div>
        <div class="d-flex flex-column mb-3 gap-1">
            <div class="d-flex justify-content-between align-items-center">
                <h6>Carrier Code </h6>
            </div>
            <span class="text-muted"> {{
                customerDetails?.plan?.carrierCode?.value }}</span>
        </div>
    </div>

</div>

<div class="cutom-card p-3 mb-3">
    <div class="d-flex flex-column mb-3 gap-1">
        <div class="d-flex justify-content-between align-items-center">
            <h4>Customer</h4>
            <div class="d-flex gap-3 align-items-center ">
                <span class="custom-badge">#{{customerDetails?.personalInfo?.customerId}}</span>
                <span class="custom-badge" [ngClass]="getStatusBadgeClass(customerDetails?.personalInfo?.accountStatus?.value)"
                >{{customerDetails?.personalInfo?.accountStatus?.value}}</span>
            </div>
        </div>
        <span class="text-info cursor-pointer" (click)="sendToCustomerDashboard()"> {{customerDetails?.personalInfo?.firstName?.value
            + " " + customerDetails?.personalInfo?.lastName?.value }}</span>
    </div>
    <div class="d-flex flex-column mb-3 gap-1">
        <div class="d-flex justify-content-between align-items-center">
            <h6>Contact information</h6>
        </div>
        <span class="text-muted"> {{ customerDetails?.personalInfo?.email?.value
            }}</span>
        <span class="text-muted"> {{ customerDetails?.personalInfo?.phone?.value
            }}</span>
    </div>

    <div class="d-flex flex-column mb-3 gap-1">
        <div class="d-flex justify-content-between align-items-center">
            <h6>Shipping address</h6>

        </div>
        <span class="w-50 text-muted"> {{
            customerDetails?.personalInfo?.serviceAddress?.value?.address1 }}</span>
        <span class="text-muted">{{ customerDetails?.personalInfo?.serviceAddress?.value?.city +
            ", " + customerDetails?.personalInfo?.serviceAddress?.value?.state + ", " +
            customerDetails?.personalInfo?.serviceAddress?.value?.zipCode }}</span>
        <span class="text-muted" (click)="openMap()">View map</span>
    </div>
    <div class="d-flex flex-column mb-3 gap-1">
        <div class="d-flex justify-content-between align-items-center">
            <h6>Billing address</h6>
        </div>
        <span class="w-50 text-muted"> {{
            customerDetails?.personalInfo?.billingAddress?.value?.address1 }}</span>
        <span class="text-muted">{{ customerDetails?.personalInfo?.billingAddress?.value?.city +
            ", " + customerDetails?.personalInfo?.billingAddress?.value?.state + ", " +
            customerDetails?.personalInfo?.billingAddress?.value?.zipCode }}</span>
        <span class="text-muted" (click)="openMap()">View map</span>
    </div>

</div>