import { Component } from '@angular/core';
import { TransactionService } from './transaction.service';
import { CommonService } from '../shared/services/common.service';

@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.scss']
})
export class TransactionComponent {
  cardStats: any = []
  selectedId: any = 'All'
  selectedCard: any;
  constructor(private transactionService: TransactionService,private commonService :CommonService) {

  }

  ngOnInit(): void {
    this.getTransactionStats()
  }

  onCardClick(item) {
    this.selectedId = item.title
    this.selectedCard = item

    console.log(item);
  }

  getTransactionStats() {
    this.transactionService.getTransactionStat().subscribe(
      (response: any) => {
        this.cardStats = []
        this.cardStats.push({
          title: "All",
          count: response?.data?.totalInvoices
        })
        response?.data?.statusCounts.map(val => {
          this.cardStats.push({ title: val.Status, count: val.total ,id:val.StatusID })
        })
      },
      (error) => {
        console.error('Error fetching data:', error);
      }
    );
  }

  exportData(){
    this.transactionService.getTransactionList({IsExport :1}).subscribe(
      (response: any) => {
        if (response.Status) {
          const fileName = this.extractFileNameFromUrl(response.data);
          const link = document.createElement('a');
          link.href = response.data;
          link.download = `${fileName}`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          this.commonService.showToast('error', "Error", response.message);
        }
      },
      (error) => {
        console.error('Error fetching data:', error);
      }
    );
  }


  extractFileNameFromUrl(url: string): string {
    const parsedUrl = new URL(url);
    const pathname = parsedUrl.pathname;
    const segments = pathname.split('/');
    return segments[segments.length - 1];
  }

}
