import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { CommonService } from 'src/app/shared/services/common.service';
import { SubscriptionService } from '../service/subscription.service';
import { CapitalizeWordsPipe } from '../pipes/CapitalizeWordsPipe';
import { fadeInOut } from 'src/app/shared/services/app.animation';

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.scss'],
  providers: [CapitalizeWordsPipe],
  animations:[fadeInOut]
})
export class PaymentHistoryComponent implements OnInit {
  @Output() closePaymentHistory: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  showStepper: boolean = true;
  @Input() displayPaymentHistory: boolean = false;
  @Input() subscriptionDetail:any;
  @Input() transectionId:any
  CustomerId:any
  invoiceList: any;
  paymentHistoryList: any;
  transectionData:any
  isHistorySkeletonLoader: boolean=true;
  isInvoiceSkeletonLoader: boolean=true;
  pageDropdownMenu: any = [10, 20, 30];
  itemsPerPage: number = 13;
  totalItems: number;
  currentPage: number = 1;

  invoiceTotalItems: number;
  invoiceCurrentPage: number = 1;
  loader:boolean

  public gridRowClass = "my-green-class";


  defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    cellClass: 'cell-wrap-text',
    autoHeight: true,
  };

  public columnDefs: ColDef[] = [
    {
      headerName: "Id", field: "id", width: 70
    },
    { headerName: "Name On Card", field: "NameOnCard"},
    { headerName: "Quantity", field: "Quantity"  },
    { headerName: "Amount", field: "Amount"},
    {
      headerName: "Account Number",
      field: 'AccountNumber'
    },
    {
      headerName: "Account Type",
      field: 'AccountType'
    },
    {
      headerName: "Created Date",
      field: 'CreatedDate',
      cellRenderer: this.dateCellRenderer.bind(this),
      width: 160
    },
    {
      headerName: "Transaction Id",
      field: 'TracID',
    },

    {
      headerName: "Address",
      field: 'Address',
      width: 250

    },
    {
      headerName: "Message Code",
      field: 'MessageCode',
      width: 250
    },


  ];

  public invoiceColumnDefs: ColDef[] = [
    {
      headerName: "Id", field: "id", width: 70
    },
    { headerName: "CustomerID", field: "CustomerID", },
   
    {
      headerName: "Description",
      field: 'Description',
      width: 150
    },
  
    {
      headerName: "Total Amount",
      field: 'TotalAmount',
      width: 150
    },

    {
      headerName: "Payment Date",
      field: 'PaymentDate',
      cellRenderer: this.dateCellRenderer.bind(this),
      width: 160
    },
    {
      headerName: "Due Date",
      field: 'DueDate',
      cellRenderer: this.dateCellRenderer.bind(this),
      width: 160
    },
    { headerName: "Created By", field: "CreatedByName", width: 100 },
    { headerName: "Status", field: "Code", width: 150 },

  ];

  public transactionColumnDefs:ColDef[]=[
    {
      headerName: "Id", field: "transId"
    },
    {
      headerName: "Customer Name",
      field: 'firstName',
      valueGetter: (params) => params.data.payment?.billTo?.firstName + ' ' +  params.data.payment?.billTo?.lastName  || 'N/A'
    },
    { headerName: "Transaction Type", field: "transactionType",valueFormatter: this.formatTransactionType.bind(this) },
    { headerName: "Transaction Status", field: "transactionStatus"},
    {
      headerName: "Description",
      field: 'responseReasonDescription',
    },
    {
      headerName: "Customer IP",
      field: 'customerIP',
    },
    {
      headerName: "Card Number",
      valueGetter: (params) => params.data.payment?.creditCard?.cardNumber || 'N/A'
    },
    {
      headerName: "Expiry Date",
      valueGetter: (params) => params.data.payment?.creditCard?.expirationDate || 'N/A'
    },
    {
      headerName: "Card Type",
      valueGetter: (params) => params.data.payment?.creditCard?.cardType || 'N/A'
    },
    {
      headerName: "Tax Exemption",
      field: 'taxExempt',
    },

  ]
  
  constructor(private subscriptionService: SubscriptionService, private commonService: CommonService, private datePipe: DatePipe,private capitaliseEachWord:CapitalizeWordsPipe
  ) {

  }
  ngOnInit() {
    if (this.displayPaymentHistory) {
      console.log('displayPaymentHistory: ',this.displayPaymentHistory);
      this.getPaymentHistory();
    }else if(this.transectionId){
      this.getTransecionDetails()
    }
    else {
      console.log('displayPaymentHistory: ',this.displayPaymentHistory);

      this.getInvoiceHistory()
    }

    this.CustomerId = this.subscriptionDetail?.subscriptionDetail?.customer?.id 
  }
  formatTransactionType(params: any): string {
    // Use the pipe to format the value
    return this.capitaliseEachWord.transform(params);
  }

  async getPaymentHistory() {
    // Start loader
    
  
    let obj: any = {
      "per_page": this.itemsPerPage,
      "page": this.currentPage,
      "CustomerId": this.subscriptionDetail?.subscriptionDetail?.customer?.id
    };
  console.log('subscriptionDetail: ',this.subscriptionDetail);
    this.subscriptionService.getPaymentHistory(obj).subscribe(
      (res: any) => {
        if (res.Status) {
          this.paymentHistoryList = res?.data?.data;
          this.totalItems = res?.data?.total;
          this.isHistorySkeletonLoader=false
          this.isInvoiceSkeletonLoader=false
          if (res?.data?.data?.length === 0) {
            this.commonService.showToast('success', "Success", 'No Record Found');
          }
        }
        
      },
      (error: any) => {
        console.error("Error fetching payment history:", error);
        this.commonService.showToast('error', "Error", 'Failed to fetch payment history');
        this.isHistorySkeletonLoader=false
        
      }
    );
  }

  async getInvoiceHistory() {
    
    let obj: any = {
      "per_page": this.itemsPerPage,
      "page": this.currentPage,
      "CustomerId": this.subscriptionDetail?.subscriptionDetail?.customer?.id
    };
  
    this.subscriptionService.getInvoices(obj).subscribe(
      (res: any) => {
        if (res.Status) {
          console.log("Invoice Details:-", res)
          this.invoiceList = res?.data?.data
          if (res?.data?.data?.length === 0) {
            this.commonService.showToast('success', "Success", 'No Record Found');
          }
          this.isInvoiceSkeletonLoader=false;
          this.isHistorySkeletonLoader=false

        }
        
      },
      (error: any) => {
        console.error("Error fetching payment history:", error);
        this.commonService.showToast('error', "Error", 'Failed to fetch payment history');
        this.isInvoiceSkeletonLoader=false
        this.isHistorySkeletonLoader=false
        
      }
    );
  }
  
  dateCellRenderer(params) {
    return this.datePipe.transform(params.value, 'short');
  }

  pageChange(event: any) {
    this.currentPage = event;
    if (this.displayPaymentHistory) {
      this.getPaymentHistory();
    } else {
      this.getInvoiceHistory()
    }
  }
 
  invoicePageChange(event: any) {
    this.currentPage = event;
    if (this.displayPaymentHistory) {
      this.getPaymentHistory();
    } else {
      this.getInvoiceHistory()
    }
  }

  onPaymentChangeNumberOfPage(){
    this.getPaymentHistory()
  }

  onInvoiceChangeNumberOfPage(){
    this.getInvoiceHistory()
  }

  getTransecionDetails(){
    this.loader=true
      
    this.subscriptionService.getTransectionDetailById(this.transectionId).subscribe(
      (res: any) => {
        if (res) {
          this.transectionData = [res]
          console.log("transectionList Details:-", res)
          this.loader=false
        }
        
        this.loader=false

      },
      (error: any) => {
        console.error("Error fetching payment history:", error);
        this.commonService.showToast('error', "Error", 'Failed to fetch payment history');
        
        this.loader=false

      }
    );
  }


  hideStepper() {
    this.showStepper = false;
    this.closePaymentHistory.emit(false);
  }
}
