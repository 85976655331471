import { Component, ElementRef, EventEmitter, HostListener, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SearchService } from '../../services/search.service';
import { CommonService } from '../../services/common.service';
import { OrderReviewService } from 'src/app/review/services/order-review.service';
import { Router } from '@angular/router';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-advance-search',
  templateUrl: './advance-search.component.html',
  styleUrls: ['./advance-search.component.scss']
})
export class AdvanceSearchComponent {

  advanceSearchForm: FormGroup;
  countryOptions = [];
  stateOptions = [];
  zipcodeOptions = [];
  advanceSearchValues: any;
  selectedStatus = [];
  cards: any;
  orderType: any;
  accountType: any;
  customerStatus: any
  isAdvanceSearchVisible:boolean=true;
  column = [];
  advanceSearchData = [];
  isHiddenData:any
  SETTINGSItems:any
  @Output() closeAdvanceSearch: EventEmitter<any> = new EventEmitter();
  @Output() submitAdvanceSearch: EventEmitter<any> = new EventEmitter();
  @ViewChild('accT') accordion: NgbAccordion;
  isShowtheAdvanceSearch: boolean =true;
  constructor(
    public searchService: SearchService,
    public commonService: CommonService,
    public fb: FormBuilder,
    public orderReviewService: OrderReviewService,
    public router: Router,
    private eRef: ElementRef
  ) {

  }

  ngOnInit() {
    this.commonService.sideMenuInitilize();
    this.commonService.getSideMenuBar().subscribe((SETTINGSItems: any) => {
      this.isHiddenData = SETTINGSItems
          console.log("Advance Search index 5:", this.isHiddenData);
      // this.cd.detectChanges();
        this.SETTINGSItems = SETTINGSItems.filter(item => !item.isHidden);
          // console.log("Item at index 6:", this.SETTINGSItems);
          // this.cd.detectChanges();// Trigger change detection after the assignment
    });
    this.initializeAdvanceSearch()
    this.getCountries()
    this.getOrderCards();
    this.getOrderType();
    this.getAccountType();
    this.getCustomerStatus();
    this.getStates();
  }

  // getOrderType
  async getOrderType() {
    
    try {
      const res: any = await this.searchService.getOrderType();
      if (res.Status) {
        this.orderType = res.data;
      } else {
        this.commonService.showToast('error', "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast("error", "Error", err)
    }
    
  }


  async getAccountType() {
    
    try {
      const res: any = await this.searchService.getAccountType();

      if (res.Status) {
        this.accountType = res.data;
        console.log("accountType :-", this.accountType)

      } else {
        this.commonService.showToast('error', "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast("error", "Error", err)
    }
    
  }


  async getCustomerStatus() {
    
    try {
      const res: any = await this.searchService.getCustomerStatus();
      if (res.Status) {
        this.customerStatus = res.data;
      } else {
        this.commonService.showToast('error', "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast("error", "Error", err)
    }
    
  }

  initializeAdvanceSearch() {
    this.advanceSearchForm = this.fb.group({
      nladApplicationId: [null, [Validators.pattern('^[0-9]+$')]], // Only numeric values
      states: [null, [Validators.pattern('^[a-zA-Z ]+$')]], // Only alphabets and spaces
      zipCode: [null, [Validators.pattern('^[0-9]{5,6}$')]], // 5-6 digit zip code
      firstName: [null, [Validators.pattern('^[a-zA-Z]+$')]], // Only alphabets
      lastName: [null, [Validators.pattern('^[a-zA-Z]+$')]], // Only alphabets
      country: [1], // Default value, no validation
      fromDate: [null], // Add date validation logic if needed
      toDate: [null], // Add date validation logic if needed
      alternateContactNumber: [null, [Validators.pattern('^[0-9]{10}$')]], // 10-digit phone number
      fullName: [null, [Validators.pattern('^[a-zA-Z ]+$')]], // Alphabets and spaces
      esnNumber: [null, [Validators.pattern('^[0-9]+$')]], // Only numeric values
      address: [null], // No validation
      OrderTypeID: [null], // No validation
      CustomerStatusID: [null], // No validation
      PlanTypeID: [null], // No validation
      nvSubscriberId: [null, [Validators.pattern('^[0-9]+$')]], // Only numeric values
    });
  }
  
  allowOnlyAlphabets(event: KeyboardEvent): void {
    const charCode = event.key;
    const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];
  
    // Allow alphabets and space
    if (!/^[a-zA-Z ]$/.test(charCode) && !allowedKeys.includes(charCode)) {
      event.preventDefault();
    }
  }
  
  allowOnlyNumbers(event: KeyboardEvent): void {
    const charCode = event.key;
    const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];
  
    // Allow only numeric characters
    if (!/^[0-9]$/.test(charCode) && !allowedKeys.includes(charCode)) {
      event.preventDefault();
    }
  }
  
  get states() {
    return this.advanceSearchForm.get("states")
  }

  resetAdvanceSearch() {
    this.advanceSearchForm.reset();
    this.advanceSearchData = [];
    this.selectedStatus = [];
    // this.accordion.collapse('toggle-1');
  }



  async getCountries() {
    
    try {
      const res: any = await this.commonService.getCountries();

      if (res.Status) {
        this.countryOptions = res.data
      } else {
        this.commonService.showToast('error', "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast("error", "Error", err)
    }
    
  }

  async getStates() {
    
    try {
      let data = {
        "CountryID": 1
      }
      const res: any = await this.commonService.getStates(data);

      if (res.Status) {
        this.stateOptions = res.data
      } else {
        this.commonService.showToast('error', "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast("error", "Error", err)
    }
    
  }

  async onSubmitAdvanceSearch() {
    
    try {
      let FormData: any = this.advanceSearchForm.value

      FormData = this.commonService.removeNullOrBlank(FormData)
      let data = {
        ...FormData,
        "status[]": this.selectedStatus ?? '',
        "states[]": FormData.states ?? '',
        perPage:10,
      
      }
      if (data['status[]'] == '') {
        delete data['status[]']
      }
      if (data['states[]'] == '') {
        delete data['states[]']
      }
      delete data.states
      const res: any = await this.orderReviewService.advanceOrderSearch(data);
      if (res.Status) {
        setTimeout(() => {
          this.searchService.setAdvanceSearchedData(res)
          this.searchService.setAdvanceSearchedPayloadData(data)
        }, 500);
        this.isAdvanceSearchVisible=false
        this.submitAdvanceSearch.emit(res.data)
        
        this.resetAdvanceSearch()
      } else {
        this.commonService.showToast('error', "Error", res.message)
        this.isAdvanceSearchVisible=false
      }

    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
      this.isAdvanceSearchVisible=false
    }
    
  }

  onClickStatus(event) {
    let index = this.selectedStatus.findIndex(el => el == event)
    if (index == -1) {
      this.selectedStatus.push(event)
    } else {
      this.selectedStatus.splice(index, 1)
    }
  }

  async getOrderCards() {
    
    try {
      const res: any = await this.orderReviewService.getOrderCards()
      if (res.Status) {
        this.cards = res.data.CARDS.RO_DASHBOARD_CARD
      } else {
        this.commonService.showToast("error", "Error", res.message)
      }
    } catch (err) {
      this.commonService.showToast('error', "Error", (err?.error?.message ?? "Some Error Occurred"))
    }
    
  }


  @HostListener('document:click', ['$event'])
  handleClickOutside(event: Event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      if(!this.isShowtheAdvanceSearch){
        this.closeAdvanceSearch.emit()
      }
      this.isShowtheAdvanceSearch =false
    }
  }
}
