import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdhocBreakupService } from '../shared/adhoc-breakup.service';
import { fadeInOut } from 'src/app/shared/services/app.animation';
import { AdhocInvoiceService } from '../shared/adhoc-invoice.service';
import { AdhocStateService } from '../shared/adhoc-state.service';

@Component({
  selector: 'app-adhoc-breakup-template',
  templateUrl: './adhoc-breakup-template.component.html',
  styleUrls: ['./adhoc-breakup-template.component.scss'],
  animations:[fadeInOut]
})
export class AdhocBreakupTemplateComponent implements OnInit {
  @Output() planDetailsChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  adhocDetails: any; // Holds the breakup details received from the service
  typeDetails: any = {}; // Parsed details for rendering
  planDetails: any[];
  priceBreakup: any[];
  showPlanDetails: boolean = false;
  isSkeletonLoader: boolean=true;
  selectedPlan: any;

  constructor(
    private adhocBreakupService: AdhocBreakupService,
    private adhocInvoiceService:AdhocInvoiceService, private stateService: AdhocStateService
  ) {this.isSkeletonLoader = true;}

  async ngOnInit() {
    // Show skeleton loader when initializing
    this.isSkeletonLoader = true;
    this.stateService.selectedPlan$.subscribe((plan) => {
      this.selectedPlan = plan;
    });
    // Subscribe to the observable to receive adhoc breakup details
    this.adhocBreakupService.getAdhocBreakupDetails$.subscribe({
      next: (details) => {
        if (details) {
          console.log('Received adhoc details:', details);
          this.adhocDetails = details;
          this.typeDetails = this.getTypeSpecificDetails(details);
          this.isSkeletonLoader = false;
          this.planDetailsChange.emit(this.adhocDetails);
        } else {
          console.warn('No details received from the service.');
        }
        // Data is loaded, stop the skeleton loader
        this.isSkeletonLoader = false;
      },
      error: (err) => {
        console.error('Error fetching adhoc details:', err);
        this.isSkeletonLoader = false; // Stop the loader even on error
      },
    });
  }
  toggleDetails(): void {
    this.showPlanDetails = !this.showPlanDetails;
  }


/**
 * Extracts plan details from the data object.
 */

  /**
   * Parses the details object to extract type-specific information.
   * @param details - The adhoc breakup details.
   * @returns Parsed details for rendering.
   */
  private getTypeSpecificDetails(details: any): any {
    if (!details || !details.adhocType) {
      console.warn('Invalid details object:', details);
      return { title: 'Invalid Details', fields: [] };
    }

    const { adhocType, planDetails, planTaxes, taxBreakup, ...otherDetails } =
      details || {};

    switch (adhocType) {
      case 'Equipment':
        return {
          title: 'Equipment Fee Details',
          fields: [
            { label: 'Total Amount', value: otherDetails?.totalAmount || 'N/A' },
            { label: 'Equipment Fee', value: otherDetails?.equipmentFee || 'N/A' },
            { label: 'Final Amount', value: otherDetails?.finalAmount || 'N/A' },
            { label: 'Description', value: otherDetails?.description || 'N/A' },
          ],
        };

      case 'Plan Change':
        return {
          title: 'Plan Change Details',
          fields: [
            { label: 'Total Amount', value: otherDetails?.totalAmount || 'N/A' },
            { label: 'Change Fee', value: otherDetails?.changeFee || 'N/A' },
            { label: 'Final Amount', value: otherDetails?.finalAmount || 'N/A' },
            { label: 'Description', value: otherDetails?.description || 'N/A' },
            ...(taxBreakup
              ? [
                  { label: 'Tax Rate', value: taxBreakup?.taxRate || 'N/A' },
                  { label: 'Tax Amount', value: taxBreakup?.taxAmount || 'N/A' },
                  { label: 'Total with Tax', value: taxBreakup?.totalWithTax || 'N/A' },
                ]
              : []),
            ...(planDetails?.plan
              ? [
                  { label: 'Plan Name', value: planDetails?.plan?.Name || 'N/A' },
                  { label: 'Price', value: planDetails?.plan?.Price || 'N/A' },
                  { label: 'Plan Data', value: planDetails?.planDetail?.PlanData || 'N/A' },
                  { label: 'Expiration Days', value: planDetails?.planDetail?.ExpirationDays || 'N/A' },
                ]
              : []),
            ...(planTaxes
              ? [
                  { label: 'Plan Taxes', value: JSON.stringify(planTaxes) },
                ]
              : []),
          ],
        };

      case 'Plan Renewal':
        return {
          title: 'Plan Renewal Details',
          fields: [
            { label: 'Total Amount', value: otherDetails?.totalAmount || 'N/A' },
            { label: 'Renewal Discount', value: otherDetails?.renewalDiscount || 'N/A' },
            { label: 'Final Amount', value: otherDetails?.finalAmount || 'N/A' },
            { label: 'Description', value: otherDetails?.description || 'N/A' },
          ],
        };

      case 'Custom':
        return {
          title: 'Custom Charges Details',
          fields: [
            { label: 'Total Amount', value: otherDetails?.totalAmount || 'N/A' },
            { label: 'Custom Charge', value: otherDetails?.customCharge || 'N/A' },
            { label: 'Final Amount', value: otherDetails?.finalAmount || 'N/A' },
            { label: 'Description', value: otherDetails?.description || 'N/A' },
          ],
        };

      default:
        return { title: 'Unknown Details', fields: [] };
    }
  }

}
